import React from 'react';
import PropTypes from 'prop-types';

import loadjs from 'loadjs';

 
class ThreatMetrixComponent extends React.Component { 
  constructor(props, context) {
    super(props, context);

    this.state = {     
      tmLoaded: false
    }
  }

  componentDidUpdate(){

    
    if(!this.state.tmLoaded){
      if(this.props.userIdentifier){ //&& process.env.REACT_APP_GCS_ENV != 'development'){
        var userId = this.props.userIdentifier;
        this.setState({tmLoaded: true}, () => loadjs("https://h.online-metrix.net/fp/tags.js?org_id=r7wrjrd9&session_id=" + userId + "&pageid=1") );
      }
    }
    

    // if(this.props.userIdentifier){ //&& process.env.REACT_APP_GCS_ENV != 'development'){
    //   loadjs("https://h.online-metrix.net/fp/tags.js?org_id=r7wrjrd9&session_id=" + this.props.userIdentifier + "&pageid=1");
    // }
  }

  render(){

    return (<div className="_tm"></div>);
    // let component = <div className="_tm"></div>;
  
    // if (this.props.userIdentifier){ //&& process.env.REACT_APP_GCS_ENV == 'production') {
    //   let url = "https://h.online-metrix.net/fp/tags.js?org_id=r7wrjrd9&session_id=" + this.props.userIdentifier + "&pageid=1";
    //   component = (
    //     <div>
    //       <script type="text/javascript" src={url}></script>
    //       <noscript>
    //         <iframe frameBorder="0" style={ {"width": "100px", "height": "100px", "border": 0, "position": "absolute", "top": "-5000px" } } src={url}></iframe>
    //       </noscript>
    //     </div>
    //   );
  //}

    //return (component);

    //return (<div className="_tm"></div>);
  }

//   let component = <div></div>;
 
//   if (userIdentifier){ //&& process.env.REACT_APP_GCS_ENV == 'production') {
//     let url = "https://h.online-metrix.net/fp/tags.js?org_id=r7wrjrd9&session_id=" + userIdentifier + "&pageid=1";
//     component = (
//       <div>
//         <script type="text/javascript" src={url}></script>
//         <noscript>
//           <iframe frameBorder="0" style={ {"width": "100px", "height": "100px", "border": 0, "position": "absolute", "top": "-5000px" } } src={url}></iframe>
//         </noscript>
//       </div>
//     );
//  }

//   return (component);
}

export default ThreatMetrixComponent;