import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import * as GlobalVariables from '../../config/configureGlobalVariables';

const LodgeacomplaintForm = ({request, onChange, onSave, errors, textVariants}) => {
  
    return (

      <section className="section-form-contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
            <h1>Your complaint matters!</h1>
               
            <p>If you're not satisfied with our products, services, staff or complaint handling process <br /> it&#39;s important we hear
about it so we can make things right. <br /> To find out more about our Complaints Handling Process, please see our
 <a href="https://www.getcreditscore.com.au/privacy-policy" target="_blank"> Privacy Policy</a><br/> 
If you have an enquiry regarding your account or credit information, please click 
 <a href="https://secure.getcreditscore.com.au/contact-us" target="_blank"> here</a> . 
<br/>Otherwise, please complete the form below</p>
              <div className="form-contact-wrapper">
                {/* <form action={GlobalVariables.CONTACTUS_BASE_URL} method="POST" onSubmit={onSave}> */}
                <form  onSubmit={onSave}>
                <input type="hidden" name="orgid" value={GlobalVariables.CONTACTUS_ORGID} />
                <input type="hidden" name="retURL" value={GlobalVariables.WEB_SPA_URL+"contact-us/confirmation"} />
                <input type="hidden" name="recordType" id="recordType" value={GlobalVariables.CONTACTUS_RECORDTYPE} />

                  <div className="row">                    
                    <div className="col-lg-6 col-12">
                      <TextInput
                        name="name"
                        id="name"
                        placeholder="First name"
                        value={request.name}
                        label="First name*"
                        onChange={onChange}
                        error={errors.name} 
                        />

                      <TextInput
                        name="lastname"
                        id="lastname"
                        placeholder="Last name"
                        value={request.lastname}
                        label="Last name*"
                        onChange={onChange}
                        error={errors.lastname} 
                        />

                      <TextInput
                        name="email"
                        id="email"
                        placeholder="Your e-mail address"
                        value={request.email}
                        label="Email*"
                        onChange={onChange}
                        error={errors.email} 
                        />

                      <TextInput
                        name="phone"
                        id="phone"
                        placeholder="Phone number"
                        value={request.phone}
                        label="Phone number*"
                        onChange={onChange}
                        error={errors.phone} 
                        />
                    </div>
                    <div className="col-lg-6 col-12">
                      <TextInput
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                          value={request.subject}
                          label="Subject*"
                          onChange={onChange}
                          error={errors.subject}
                          />                     
{/*                         
                      <div className="form-label-group form-label-group-textarea">
                        <textarea id="description" error={errors.description} name="description" className="form-control notes" placeholder="Description" maxLength="250" onChange={onChange} value={request.description}></textarea>
                        <label htmlFor="description">Message*</label>
                        <div id="textarea_feedback" className="textarea_feedback"></div>
                      </div> */}
                      {/* <div className="form-label-group form-label-group-textarea"> */}
                        {/* <textarea id="description" error={errors.description} name="description" className="form-control notes" placeholder="Description" maxLength="250" onChange={onChange} value={request.description}></textarea> */}
                        {/* <div className="col-lg-6 col-12"> */}
                        <TextInput
                          type="textarea"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={request.description}
                          label="Message*"
                          maxLength="250"
                          onChange={onChange}
                          error={errors.description}
                          />
                        {/* <label htmlFor="description">Message*</label> */}
                        {/* <div id="textarea_feedback" className="textarea_feedback"></div>
                      </div> */}

                      
                    </div>
                  </div>
                  
                  {errors.general && <div className="row" >
                    <div className="col-12">
                      <div className="alert alert-danger">
                        {errors.general}
                      </div>
                    </div>
                  </div>}

                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>


        )
      }

LodgeacomplaintForm.propTypes = {
    request: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default LodgeacomplaintForm;
