import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

const GoToPreviousPage = ({textVariants, containerCls}) => {
    let containerClassName = 'container-fluid ' + containerCls;
    const browserHistory = createBrowserHistory();

    return (
      <section className="go-to-page xs-pt-5 xs-pb-5">
            <div className={containerClassName}>
             <div className="row">
                <div className="col-xs-12 text-left cursor-hand">
                    <a onClick={browserHistory.goBack}>
                        <span className="glyphicon glyphicon-arrow-left"></span>
                        <h5>{textVariants["Back_to_previous_page"]}</h5>
                    </a>
                </div>
                </div>
            </div>
        </section>
    );
};

export default GoToPreviousPage;