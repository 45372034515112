import React from 'react';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

class FooterSection extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.year = new Date().getFullYear();
  }

  render() {
    return (
      <footer className="footer-home">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12 column-logo">
            <a href="#"><img src={ImagesPath.ImgLogoWhite} alt="" className="img-fluid"/></a>
            <p>ABN 96 169 886 891<br/>
            Australian Credit Licence 501455</p> 
          </div>
          <div className="col-lg-2 offset-lg-1 col-md-3 col-12 menu">
            <h5>Info</h5>
            <ul>
            <li><a href={RoutesVariables.RouteContactUs}>Enquiries and Feedback</a></li>
            <li><a href={RoutesVariables.RouteLodgeacomplaint}>Lodge a complaint</a></li>
            
            {/* <li><a href={RoutesVariables.RouteContactUsFix} target="_blank">Enquiries and Feedback</a></li>
            <li><a href={RoutesVariables.RouteLodgeacomplaintFix} target="_blank">Lodge a complaint</a></li> */}

            <li><a href={RoutesVariables.RouteExternalTermsOfUse} target="_blank">Terms of Use</a></li>
            <li><a href={RoutesVariables.RouteExternalPrivacyPolicy} target="_blank">Privacy Policy</a></li>
            </ul> 
          </div>
          <div className="col-lg-2 offset-lg-1 col-md-3 col-12 menu">
            <h5>Help center</h5>
            <ul>
            <li><a href={RoutesVariables.RouteGetMyCreditScore}>Get My Credit Score</a></li>
            <li><a href={RoutesVariables.RouteImproveMyCreditScore}>Improve My Credit Score</a></li>
            <li><a href={RoutesVariables.RouteUseMyCreditScore}>Use My Credit Score</a></li>
            <li><a href={RoutesVariables.RouteMyCreditReport}>My Credit Report</a></li>
            <li><a href={RoutesVariables.RouteMythsVsFacts}>Myths v Facts</a></li>
            <li><a href={RoutesVariables.RouteGeneralInformation}>General Information</a></li>
            </ul> 
          </div>
          <div className="col-lg-2 offset-lg-1 col-md-3 col-12 menu">
            <h5>General</h5>
            <ul>
              <li><a href={RoutesVariables.RouteExternalArticles}>Articles</a></li>
              <li><a href={RoutesVariables.RouteLogin}>Login</a></li>
              <li><a href={RoutesVariables.RouteCreditScore}>Get Score</a></li>
            </ul> 
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-12 first">
            <span>All Rights Reserved. ©{this.year} GetCreditScore Pty Ltd (GCS)</span>
          </div>
          <div className="col-xl-3 offset-xl-4 col-lg-3 offset-lg-4 col-md-6 d-none d-md-inline-block text-right second">
            <span><a href={RoutesVariables.RouteExternalTermsOfUse}>Terms of Use</a> and <a href={RoutesVariables.RouteExternalPrivacyPolicy}>Privacy Policy</a></span>
          </div>
        </div>
      </div>
    </footer>
    )
  }
}

export default FooterSection;