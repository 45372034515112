import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../config/GlobalFixedVariables';
import * as funcs from '../components/shared/functions';


class ReportApi {
    static getReportProfile(csrfToken, options = {}) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/creditreport';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }   
      let requestOptions = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(options)
         }

        return fetch(fullUrl, requestOptions).then(response =>
                {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
                }
          ).catch(function (error) {
            throw(error);
          });      
    }          
  
};

export default ReportApi;
