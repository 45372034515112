import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as funcs from '../../components/shared/functions';
import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import {tonikInitMenu} from '../../tonik/menu';

import MyAccountItem from './MyAccountItem';
import MenuMobile from './MenuMobile';

const $ = window.$;

function handleMenuClick(e) {
  let target = e.target;

  if (target) {
    if ($(target).hasClass("glyphicon-chevron-down")) {
      $(target).removeClass("glyphicon-chevron-down");
      $(target).toggleClass("glyphicon-chevron-up");
    }
    else {
      $(target).removeClass("glyphicon-chevron-up");
      $(target).toggleClass("glyphicon-chevron-down");
    }
  }

  e.preventDefault();
}

const MenuLinkItem = ({menuItem, useJustAnchors, activeItem}) => {
  let renderedComponent = null;
  if (menuItem.subMenuList && menuItem.subMenuList.length > 0) {
    var liClass = '';
    if(menuItem.subMenuListOnlyMobile){
      liClass += 'hidden-md hidden-sm hidden-lg';
    }
    else {
      liClass += 'dropdown';
    }

    renderedComponent = (
        <li className={liClass}>
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            {menuItem.name} <span onClick={(e) => handleMenuClick(e)} className="hidden-lg hidden-md hidden-sm pull-right glyphicon glyphicon-chevron-down top-menu-chevron-down"></span>
          </a>
          <ul className="dropdown-menu mobile-dropdown-menu">
              {menuItem.subMenuList.map( item => {
                  return(<MenuLinkItem menuItem={item} key={item.id} useJustAnchors={useJustAnchors} />)
              })}
          </ul>
        </li>
    )
   } else{

         let aClassName = menuItem.className;
         let linkComponent = null;
         if (menuItem.ref) {
             linkComponent = <a href={menuItem.ref} className={activeItem ? (aClassName + " " + menuItem.cssClass) : ''}>{menuItem.name}</a>;
         } else if (menuItem.link) {
             if(useJustAnchors){
               linkComponent = <a href={menuItem.link} className={activeItem ? aClassName: ''}>{menuItem.name}</a>;
             }
             else{
                  linkComponent = <Link to={menuItem.link} className={activeItem ? aClassName: ''}>{menuItem.name}</Link>
              }
         }

          let li_className = '';
          if(menuItem.className){
              var classes = menuItem.className.split(' ');
              for(var c in classes){
                if(classes[c] != null && classes[c].startsWith('hidden')){
                  li_className += ' ' + classes[c];
                }
              }
          }

           renderedComponent = (
               <li > 
                   {linkComponent}
               </li>
           )
}

    return (renderedComponent)

};

MenuLinkItem.propTypes = {
  mainItem: PropTypes.object,
  useJustAnchors: PropTypes.bool
};

const MenuItemLinkLogo = ({useJustAnchors, cssClass, isLogoImgBlue, urlPrefix = ''}) => {
  let headerLinkComponent = null;
  let logoImg = isLogoImgBlue ? ImagesPath.ImgLogoGcsBlue : ImagesPath.ImgLogoGcsWhite;

  if (isXmas()) {
    logoImg = isLogoImgBlue ? ImagesPath.ImgLogoGcsBlueXmas : ImagesPath.ImgLogoGcsWhiteXmas;
  }


  if(isHalloween()) {
    logoImg = ImagesPath.ImgLogoGcsHalloween;
  }

  if (useJustAnchors) {
    headerLinkComponent = <a href={urlPrefix + "/"} className={cssClass}><img src={logoImg} className="logo-img" /></a>;
  } else {
    headerLinkComponent = <Link to={urlPrefix + "/"} className={cssClass}><img src={logoImg} className="logo-img" /></Link>;
  }

  return (headerLinkComponent);
};

const isXmas = () => {
    const december = 12;
    const xmasLastDay = 26;

    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);

    const isDec = currentDate.getMonth() + 1 === december;
    const isBeforeXmasLastDay = currentDate.getDate() <= xmasLastDay;

    return (isDec && isBeforeXmasLastDay);
}

const isHalloween = () => {
  const october = 10;
  const halloweenStartDay = 24;
  const halloweenLastDay = 31;

  const curDate = new Date();
  curDate.setHours(0,0,0,0);

  const isOct = curDate.getMonth() + 1 === october;
  const isHalloween = curDate.getDate() >= halloweenStartDay;
  const isBeforeHalloweenLastDay = curDate.getDate() <= halloweenLastDay;

  return (isOct && isHalloween && isBeforeHalloweenLastDay);
}

class Menu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.clickButtonMobile = this.clickButtonMobile.bind(this);
  }

  componentDidMount() {
    tonikInitMenu();
  }

  componentDidUpdate(prevProps, prevState) {
  }

  clickButtonMobile(){
    tonikInitMenu();
  }

  render() 
  {
    const {mainClass = 'header-log', navClass= 'col-lg-6 offset-lg-4 col-md-8 offset-md-1', menuItemList, useJustAnchors, logoBlack=false, blueIcons = false, activeItem = true, user= null, renderMobile=false} = this.props;
    var logo = logoBlack ? ImagesPath.ImgLogoBlack : ImagesPath.ImgLogoWhite;
    var icon = logoBlack ? ImagesPath.ImgIconMobile : ImagesPath.ImgIconMobileWhite;
    let classMain = mainClass;
    let classNav = navClass;
    if(menuItemList && menuItemList.filter(item => item.id == 'my-account').length > 0){
      classNav = 'col-lg-8 menu menu-first';
      classMain = blueIcons ?  'header-dashboard header-about':'header-dashboard';
    }
    let componentToRender = null;
    var itemMyAccount = null;

    componentToRender = (
      <header className={classMain} style={{minHeight:'60px'}}>
        <div className="container">
          <div className="row">
          {!renderMobile && (<div className="menu-mobile-wrapper">
            <button type="button" id="button-mobile" onClick={this.clickButtonMobile}><img src={icon} className="img-fluid" alt="" /></button>
          </div>)}
            <div className="col-xl-2 col-lg-3 col-md-11 col-10 text-center logo d-none d-lg-inline-block">
               <Link to={RoutesVariables.RouteHome}><img src={logo} className="img-fluid" alt="" /></Link>
            </div>
            <div className={classNav}>
              <nav>
                <ul>
                  {
                    menuItemList && menuItemList.map(item => {
                      if(item.id == 'my-account'){
                        itemMyAccount = item;
                      }
                      else {
                        if(!item.id.includes('_m')) {
                          return(<MenuLinkItem useJustAnchors={useJustAnchors}  menuItem={item} key={item.id} activeItem={activeItem} />);
                        }
                      }
                      
                    })
                  }
                </ul>
              </nav>
            </div>
            { itemMyAccount && (
              <MyAccountItem menuItem={itemMyAccount} user={user} blueIcons={blueIcons}></MyAccountItem>
            )}
          </div>
        </div>
        <MenuMobile menuItemList={menuItemList} user={user} renderMobile={renderMobile}></MenuMobile>
      </header>
      
    );

    return componentToRender;
  }
}

Menu.propTypes = {
  mainClasses: PropTypes.string,
  menuItemList: PropTypes.array,
  useJustAnchors: PropTypes.bool
};

export default Menu;
