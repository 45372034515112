import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

const $ = window.$;


class RecentActivities extends React.Component {

  constructor(props, context){
      super(props, context);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState){
  }

render(){
  
    let {textVariants, accounts, enquiries, courtActions} = this.props;

    var account = accounts && accounts.length > 0 && accounts[0];
    var enquiry = enquiries && enquiries.length > 0 && enquiries[0];
    var court = courtActions && courtActions.length > 0 && courtActions[0];

    var accountDate = account && (account.accountOpenDate || account.latestUpdatedDate);

    return (

      <span>
        {account && (<div className="summary-box">
          <div className="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" className="img-fluid"/>
            <h2>{accountDate}</h2>
          </div>
          <div className="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{account.originalCreditProvider  ? account.originalCreditProvider : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{account.accountType}</dd>
            </dl>
          </div>
        </div>)}

        {enquiry && (<div className="summary-box">
          <div className="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" className="img-fluid"/>
            <h2>{ enquiry.enquiryDate ? enquiry.enquiryDate : '' }</h2>
          </div>
          <div className="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{enquiry.creditEnquirier  ? enquiry.creditEnquirier : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{enquiry.accountType}</dd>
            </dl>
          </div>
        </div>)}

        {court && (<div className="summary-box">
          <div className="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" className="img-fluid"/>
            <h2>{court.actionDate ? court.actionDate : '' }</h2>
          </div>
          <div className="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{court.creditor  ? court.creditor : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{court.courtType}</dd>
            </dl>
          </div>
        </div>)}
        
      </span>
        );
      }
 }

 RecentActivities.propTypes = {
    textVariants: PropTypes.object.isRequired,
};


export default RecentActivities;
