import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';

import ScoreMapRegionScoreBox from './ScoreMapRegionScoreBox';

const ScoreMapRegionScores= ({ stateTitle, areaRegionsScores, textVariants }) => {

  return (
    <div className="container-fluid left-container-padding right-container-padding scoremap-region-scores scoremap-page-section">
      <div className="scoremap-page-section-title">
        {areaRegionsScores && <p className="title scoremap-has-disclaimer">{stateTitle} {textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "areaScoresTitle"]}</p>}
      </div>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          {areaRegionsScores && 
            <ScoreMapRegionScoreBox
              key="LowestScoreRegions"
              title={textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "lowestScoreTitle"]}
              headerColor="header-lowest "
              regionsScoreList={areaRegionsScores.low} />}
        </div>
        <div className="col-sm-6 col-xs-12">
          {areaRegionsScores &&
            <ScoreMapRegionScoreBox 
              key="HighestScoreRegions"
              title={textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "highestScoreTitle"]}
              headerColor="header-highest"
              regionsScoreList={areaRegionsScores.high} /> }
        </div>        
      </div>
      <div className="scoremap-disclaimer-note">
        <p>{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "regionDisclaimer"]}</p>
      </div>
    </div>
  );
  
};

ScoreMapRegionScores.propTypes = {
  stateTitle: PropTypes.string.isRequired,
  areaRegionsScores: PropTypes.object,
  textVariants: PropTypes.object.isRequired
};

ScoreMapRegionScores.defaultProps = {
  areaRegionsScores: []
};

export default ScoreMapRegionScores;