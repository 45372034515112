import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';

import * as RoutesVariables from '../../config/routesVariables';

import {initTonikForms} from '../../tonik/shared/forms';
import * as ImagesPath from '../../config/imagesPath';
import { Link } from 'react-router-dom';


const ChangePasswordForm = ({ textVariants, onChange, error, customer, onChangePassword, saving, isOnlyForm=false }) => {
  let oldPwdInputCls =  'form-control password';
  let confirmPwdInputCls = 'form-control password';
  let newPwdInputCls = 'form-control password';

   if (error &&  error.oldP ) {
       oldPwdInputCls += ' is-invalid';
   }

   if (error && error.newP) {
     newPwdInputCls += ' is-invalid';
   }

   if (error &&  error.confirmP ) {
       confirmPwdInputCls += ' is-invalid';
   }

   let feqfx = "pas"
   let omg = "sword"

    return (
<div>
      <form onSubmit={onChangePassword}>
      <div className="">

          {!customer["hasPassword"] && <div className={!isOnlyForm ? 'form-label-group ':'form-label-group password-first col-12'}>
            <input type={feqfx+omg} id="oldP" name="oldP" value={customer.oldP} onChange={onChange} autoComplete="off" className={oldPwdInputCls}  placeholder="Old Password"/>
            <a className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt=""/></a>
            <label for="oldP">Old Password</label>
              {error.oldP && (                      
                        <div className="invalid-feedback">
                            {error.oldP}
                        </div>                      
              )}
          </div>
          }

          <div className={!isOnlyForm ? 'form-label-group ':'form-label-group col-12'}>
            <input type={feqfx+omg} id="newP" name="newP" value={customer.newP} onChange={onChange} autoComplete="off" className={newPwdInputCls} placeholder="New Password"/>
            <a className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt=""/></a>
            <label for="newP">New Password</label>
              {error.newP && (                      
                        <div className="invalid-feedback">
                            {error.newP}
                        </div>                      
              )}
          </div>


          <div className={!isOnlyForm ? 'form-label-group ':'form-label-group password-last col-12'}>
                <input type={feqfx+omg} id="confirmP"  name="confirmP"  value={customer.confirmP} onChange={onChange} autoComplete="off" className={confirmPwdInputCls} placeholder="Confirm New Password"/>
                <a className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt=""/></a>
                <label for="confirmP">Confirm New Password</label>
                 {error.confirmP && (                      
                        <div className="invalid-feedback">
                            {error.confirmP}
                        </div>                      
              )}

          </div>

            {!isOnlyForm &&(<>
              <button
                type="submit"
                className={saving ? 'btn btn-primary full-width disabled' : 'btn btn-primary full-width'}>
                {textVariants["Btn_ForgotPassword"]}
                {saving &&(<div class="spinner-border text-light float-right mt-2" role="status">
                  <span class="sr-only">Loading...</span>
                </div>)}
              </button>
              <p> <Link to={RoutesVariables.RouteDashboard}>{textVariants[pages.CHANGEPASSWORD + textVariants.textVariantSeparator + "goBackToDashboard"]}</Link></p>
            </>)}

          {error.generic &&(<div className="alert alert-danger">{error.generic}</div>)}

      </div>
    </form>
</div>
     )
};

ChangePasswordForm.propTypes = {
    textVariants: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    onChangePassword: PropTypes.func.isRequired,
    saving: PropTypes.bool
};


export default ChangePasswordForm;
