import React from 'react';
import PropTypes from 'prop-types';

import DateInputMask from './DateInputMask';
import DateInputSimple from './DateInputSimple';

import * as pages from '../../shared/pages';

const DateInputGeneral = ({customer, error, textVariants, onChange, shouldRender = true, groupClass = "form-group", toolTip=null}) => {

  var Component = <></>;
  if(shouldRender){
    Component = <DateInputSimple  
    id="dob"
    name="dob"
    value={customer.dob}
    label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "dob_label"]}
    onChange={onChange}
    error={error} 
    toolTip={toolTip}
    groupClass={groupClass}
     />;
  }

  return (Component);

};




export default DateInputGeneral;
