
import * as pages from '../../components/shared/pages';
import { GlobalThemes }  from '../../components/shared/themes';
import pagesVariants from '../../components/shared/textVariants';
import creditSummary from '../../components/shared/creditSummary';
import {
  menuItemList,
  menuItemsNamePerPage,
  keyFeatures,
  gcsProcessSteps,
  gcsHomeFaqs,
  dashboardtabs 
} from '../../components/shared/staticData';

import { products } from '../../components/shared/staticProducts';
import { creditCards } from '../../components/shared/staticProductsCreditCards';

import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as ImagesPath from '../../config/imagesPath';

export default {

  menu:
  {
    menuItemList: menuItemList,
    menuItemsNamesPerPage: menuItemsNamePerPage,
    menuItemsPerPage:{
      menuItemsAuthenticated:{},
      menuItems: {}
    }
  },

  textVariants: pagesVariants,

  creditSummary: creditSummary,

  homePage: {
    keyFeatureList: keyFeatures,
    processSteps: gcsProcessSteps,
    gcsHomeFaqs: gcsHomeFaqs,
    articles: [],
    articlesLoading: false,
    articlesLoaded: false
  },

  dashboardPage:
  {
    selectedDashboardTab: 0,
    callsToAction: [],
    dashboardTabs : dashboardtabs,
    articles: [],
    articlesLoading: false,
    articlesLoaded: false
  },

  productMetadata:
  {
    isAnonymous: null,
    userId : null
  },

  productResult: {
    isAnonymous: null,
    partnerId: null,
    userId : null,
    loading: null,
    loaded : null,
    products: [],
    hiddenProducts: [],
    lastProduct: null,
    productsCall2Action: [],
    call2Actions: [],
    productsByType: []
  },

  creditCardsResult: {
    isAnonymous: null,
    userId : null,
    loading: null,
    loaded : null,
    products: creditCards
  },

  scoreProfile:{
      apiFail: false,
      loading: true,
      //hasScoreInProgress: false,
      isTimeout: false,
    //  isNoRecords : false,
      tryingCGetScoreCount: 0,
      tryingGetScoreMax: GlobalVariables.SCORE_RETRY_MAX,
      scoreInfo:
      {
          score: null,
          bandDescription: null,
          bandRange: null,
          information: null,
          scoreDiff: null,
          scoreDiffSince: null,
          imgUrl: ImagesPath.ImgDashboard_Dial_Blank,

          noScore: false,
          isScoreBadRequest : false,
          isTryLater: false,
          isServiceNotAvailableNow: false,
          isNoFile: false,
          IsIdentityIssue: false,
          isContactUs: false
          // isScoreError: false
      },
      //scoreAdvice: { helping:[], hurting: []},
      scoreAdvice:{
        kcfs: []
      },
      scoreHistory: [],
      scoreCounter: {
        isEnabled: true,
        loaded: false,
        counter: 3532496,
        updateInterval: 10000,
        counterIncrement: 1,
        timerId: null
      }
  },

  reportProfile:{
    apiFail: false,
    loading: true,
    isTimeout: false,
    tryingGetReportCount: 0,
    tryingGetReportMax: GlobalVariables.REPORT_RETRY_MAX,            
    isServiceNotAvailableNow: false,
    reportInfo:
    {
      displayName: "",
      isError: false,
      isInProgress: false,      
      loaded: false,
      issue: {},
      scoreInfo: {
        "score": null,
        "bandDescription": null,
        "bandRange": null,
        "scoreBandMin": null,
        "scoreBandMax": null,
        "information": null
      },
      scoreAdvice:{
        kcfs: []
      },
      enquiries: [],
        // {
        //   "enquiryDate": "24/02/2020",
        //   "accountType": "Credit Card",
        //   "enquiryAmount": "145",
        //   "relationship": "Principal's Account (sole)",
        //   "creditEnquirier": "PREMIER BANK",
        //   "enquiryClientReference": "something"
        // },
        accounts: [],
        //   "hasDefaults": true,
        // "latestUpdatedDate": "05/07/2020",
        // "accountId": "123DEFANZ",
        // "originalCreditProvider": "ANZ RISK MANAGEMENT",
        // "latestCreditProvider": "ANZ RISK MANAGEMENT",
        // "accountType": "Credit Card",
        // "relationship": "Principal's Account (sole)",
        // "accountOpenDate": null,
        // "accountClosedDate": null,
        // "accountReopenDate": null,
        // "originalMaximumCredit": null,
        // "latestMaximumCredit": null,
        // "repaymentHistory": null
        courtActions: [],
        //actionDate
        //creditor
        //courtActionAmount
        //courtType
        //courtActionStatus        
    }  
},

  userProfile: {
    urlPrefix: '',
    isAuthenticated: false,
    identifier: null,
    redirect_uri: null,
    expires_at: null,
    csrfToken: null,
    roles: null,
    tm: null,
    rnd: null,
    isPromotionEnabled: null,
    isPromotionEnabledForUser: false,
    isPromotionEnabledLoaded: false,
    isPromotionNewUser: false,
    displayName: '',
    themes: {
       dashboard: "themeFollio"
    },
    isThemeLoaded: false,
    extras: {
      isBusinessOwner: false,
      state: null,
      age: null
    },
    hasAccount: false,
    registeringUsername: '',
    registeringRememberMe: null,
    username: null,
    rememberMeInfo: {
      rememberMe:false,
      username: null
    },    
    userDetails: null,
    preferences: {
      isActive: true,
      isSubscribed: true,
      categories: []

    },
    isTrustPilotEnabled: true,
    existingCustomer: {
      loaded: false,
      hasError: false,
      detail: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: ""
      }
    },
    homeShow: {
      loaded: false,
      hasError: false,
      code: null
    }
  },

  ajaxCallsInProgress: 0

};
