import React from 'react';
import * as funcs from '../../../shared/functions';

const AboutMeWizFinish = ({step, stepState, onUpdate, onCustomAction}) => {
  let component = <div></div>;  
  let stepDef = step["stepDefinition"];
  
  if (step && stepState) {
    component = <div className="container-fluid  wizard-step">
                  <div className="row wizard-step-title">
                    <div className="col-xs-12 text-center">{stepDef["stepsInstruction"]}</div>
                  </div>  
                  <div className="row wizard-action-btn-row">
                    <div className="col-xs-12 text-center wizard-finish-img-cont ml-auto mr-auto">
                      <img src={stepDef["imgUrl"]} />
                    </div>
                  </div>
                  <div className="row wizard-action-btn-row">
                    <div className="col-xs-12 text-center">
                      <span className="wiz-step-action-header mt-3" dangerouslySetInnerHTML={funcs.createMarkup(stepDef["subTitle"])}></span>
                    </div>
                  </div>
                </div>;
  }
  
  return ( component );
}

export default AboutMeWizFinish;