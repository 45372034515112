import React from 'react';

export const ErrorWrapper = ({header, text, children}) => {
  return (<div className="col-lg-4 col-md-4 column-nav d-md-block">
        <div id="accordion" className="accordion-reports cr-accordion-error-main">
                <div className="card">
                  <div className="card-header" id="heading-error">
                    <span className="mb-0">                      
                        {header}                      
                    </span>        
                    <h2>{text}</h2>            
                  </div>

                  <div id="collapse-error" className="collapse show pl-4 pr-4" aria-labelledby="heading-error" data-parent="#accordion">
                    <div className="card-body">
                      <span>{children}</span>
                    </div>
                  </div>
                </div>                     
        </div>
      </div>);
}