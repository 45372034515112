import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../config/GlobalFixedVariables';
import * as ImagesPath from '../config/imagesPath';

class PartnerApi {


    static getPartnerId(partnerName) {

      let partnerId = 0;

      if(partnerName == 'toyota'){
        partnerId = 12;
      }
      else if (partnerName == 'powertorque'){
        partnerId = 24;
      }

      return partnerId;
   }

   static getPartnerLogo(partnerName) {

     let logoUrl = "";

     if(partnerName == 'toyota'){
       logoUrl = ImagesPath.ImgLogoToyota;
     }
     else  if(partnerName == 'powertorque'){
      logoUrl = ImagesPath.ImgLogoPowerTorque;
    }

     return logoUrl;
  }

  static getUserIdentifier(partnerGcsId) {

      let id = "" + partnerGcsId;

      id = id.replace("p-","");
      return id;
   }

   static partnerConsent(partnerConsent) {

   // partnerConsent
   // UserIdentifier
   // PartnerId
   // Consent

     const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/partner/consent';

     let options = {
          method: 'POST',
          mode: 'cors',
          redirect: 'follow',
          headers: {
           'Content-Type': 'application/json'
         },
          body: JSON.stringify(partnerConsent)
        }

       return fetch(fullUrl, options).then(response =>
       {
               if (!response.ok) {
                 return Promise.reject(response);
               }
               else{
                 return response.json(); // it should redirect
               }
       }
         ).catch(function (error) {
           throw(error);
         });
   }

   static partnerStick(partnerId) {

     const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/partner/stick';

     let options = {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
           'Content-Type': 'application/json'
         },
          body: JSON.stringify({partnerId : partnerId})
        }

       return fetch(fullUrl, options).then(response =>
       {
               if (!response.ok) {
                 return Promise.reject(response);
               }
               else{
                 return response.json();
               }
       }
         ).catch(function (error) {
           throw(error);
         });
   }
//
//    static partnerRefer(csrfToken, req) {
//
//      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/partner/refer';
//
//        let options = {
//             method: 'POST',
//             mode: 'cors',
//             credentials: 'include',
//             headers: {
//              'Content-Type': 'application/json',
//              'x-gcs-xsrf': csrfToken
//             }
//             ,body: JSON.stringify(req)};
//
//
//        return fetch(fullUrl, options).then(response =>
//        {
//                if (!response.ok) {
//                  return Promise.reject(response);
//                }
//                else{
//                  return response.json(); // it should redirect
//                }
//        }).catch(function (error) {
//            throw(error);
//          });
//    }
 }

export default PartnerApi;
