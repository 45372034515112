import React from 'react';
import PropTypes from 'prop-types';

import CustomCheckBox from './CustomCheckBox.js';

export class CustomCheckBoxList extends React.Component {

  constructor(props, context) {
    super(props, context);

     this.toggleCheck = this.toggleCheck.bind(this);
  }

  toggleCheck(evt) {
      if (this.props.onItemSelect) {
        this.props.onItemSelect(evt);
      }
  }

  componentDidMount() {
  }

  render() {

    let items = this.props.items;
    return (
      <>
          {items.map((item) =>

              <CustomCheckBox
                   className={item.className}
                   name={item.name}
                   checked={item.selected}
                   onClick={(evt) => this.toggleCheck(evt)}
                   key={item.id}
                   id={item.id} 
                   box={this.props.box}/>

            )}
        </>
    )
  }
}


export default CustomCheckBoxList;
