import React from 'react';
import PropTypes from 'prop-types';
import * as ImagesPath from '../../../config/imagesPath';

const TextInput = ({name, label, onChange, placeholder, value, error, shouldRender = true, autocomplete="on", toolTip, maxLength=100, required=false, disabled=false, groupClass="form-label-group", type="text"}) => {

     let inputCls = 'form-control';

     if (type == "textarea") {
      inputCls = 'form-control notes';
      groupClass="form-label-group form-label-group-textarea";
    }

     //let groupClass = "form-label-group";
      if (error && error.length > 0) {
        inputCls += ' is-invalid';
      }

      let component =   <div ></div>;

      if(shouldRender){
        if (type == "textarea") {
          component =   
          <div className={groupClass}>
            <textarea
                  maxLength={maxLength}
                  name={name}
                  id={name}
                  className={inputCls}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  autoComplete={autocomplete}
                  required={required}
                  disabled={disabled} />
  
            {toolTip && (
              <a className="info-tooltip" data-toggle="tooltip" data-placement="bottom" title={toolTip["helpText"]}><img src={ImagesPath.ImgIconInfo} className="img-fluid info-icon" alt=""/></a>
            )}
  
            <label htmlFor={name}>{label}</label>
            <div id="textarea_feedback" style={{height: "35px"}} className="textarea_feedback"></div>
            {error && (
              <>
                <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                <div className="invalid-feedback">
                        {error}
                </div>
              </>
            )}
          </div>;
        }
        else
        {
          component =   
          <div className={groupClass}>
            <input
                  type={type}
                  maxLength={maxLength}
                  name={name}
                  id={name}
                  className={inputCls}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  autoComplete={autocomplete}
                  required={required}
                  disabled={disabled} />
  
            {toolTip && (
              <a className="info-tooltip" data-toggle="tooltip" data-placement="bottom" title={toolTip["helpText"]}><img src={ImagesPath.ImgIconInfo} className="img-fluid info-icon" alt=""/></a>
            )}
  
            <label htmlFor={name}>{label}</label>
            {error && (
              <>
                <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                <div className="invalid-feedback">
                        {error}
                </div>
              </>
            )}
          </div>;
        }

 
      }

      return (component);
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  shouldRender: PropTypes.bool,
  toolTip: PropTypes.object,
  error: PropTypes.string
};

export default TextInput;
