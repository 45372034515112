import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as reducerHelper from './reducerHelper';


export default function creditCardReducer(state = initialState.creditCardsResult, action) {

  switch (action.type) {

    case types.LOADING_CREDITCARDS:
      var newState = Object.assign({}, state);
      newState.loading = action.loading;

      if(!action.loading){
        newState.loaded = !action.isFailure;
      }

     return newState;

     case types.FORCE_LOADING_CREDITCARDS:
       var newState = Object.assign({}, state);
       newState.loaded = false;
     return newState;

    case types.LOAD_CREDITCARDS_SUCCESS:
       var newState = Object.assign({}, state);
       newState.creditCardsResult = Object.assign({},  action.creditCardsResult);
       return newState;

       if(action.creditCardsResult && action.creditCardsResult.userId && action.creditCardsResult.products){

          reducerHelper.updateProducts(action.creditCardsResult.products, action.creditCardsResult.isAnonymous, action.creditCardsResult.userId);

       }

      return action.creditCardsResult;

     default:
       return state;
     }
}
