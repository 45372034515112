import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';


class AboutMeApi {

    static getAboutMeProfile(csrfToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/about-me';

        let headers = {};        
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }        
        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
             headers: headers
        }

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return response.json();
                        }
                }
            ).catch(function (error) {
              throw(error);
            });
    }

    static saveAboutMeStep(csrfToken, step) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/about-me-step';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }     
      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(step)
         }

        return fetch(fullUrl, options).then(response =>
        {
                if (!response.ok) {
                  return Promise.reject(response);
                }
                else{
                  return response;
                }
        }
          ).catch(function (error) {
            throw(error);
          });
    }
};

export default AboutMeApi;
