
import * as ImagesPath from '../../config/imagesPath';

export const creditCards = [
          {
              id : 4,
              title : "Low Rate Platinum",
              imgUrl : ImagesPath.ImgProduct_CC_Offer_Anz,
              href: "https://ad.doubleclick.net/ddm/clk/312540156;138687108;e",
              features: [
                  { key:"1", title : "Balance Transfer<sup class='sup'>1</sup>", value: "0% for 16 months" },
                  { key:"2", title : "Purchase Rate", value: "11.49%"},
                  { key:"3", title : "Features<sup class='sup'>2</sup>",value: "<span>Up to 55 days interest free on purchases and Travel insurance</span>"}
              ],
              revertRate: '21.74%',
              partnerId: 2,
              productTypeId: 5,
			        productId: 4
          },

          {
              id : 5,
              title : "Platinum Credit Card",
              imgUrl : ImagesPath.ImgProduct_CC_Offer_Hsbc1,
              href: "https://hsbcconnect.go2cloud.org/aff_c?offer_id=4&aff_id=1016&url_id=16",
              features: [
                  { key:"1", title : "Balance Transfer<sup class='sup'>1</sup>", value: "0% for 22 months" },
                  { key:"2", title : "Purchase Rate", value: "19.99%"},
                  { key:"3", title : "Features<sup class='sup'>2</sup>",value: "<span>Airport lounge pass every year, complimentary insurances and earn reward points</span>"}
              ],
              revertRate: '19.99%',
              partnerId:  2,
              productTypeId: 5,
			        productId: 12
          },

          {
              id : 6,
              title : "Velocity Flyer Card",
              imgUrl : ImagesPath.ImgProduct_CC_Offer_Virgin,
                href: "http://ad.doubleclick.net/ddm/clk/287251806;114290141;x",
                features: [
                   { key:"1", title : "Balance Transfer<sup class='sup'>1</sup>", value: "0% for 18 months" },
                   { key:"2", title : "Purchase Rate", value: "20.74%"},
                   { key:"3", title : "Features<sup class='sup'>2</sup>",value: "<span>$129 Virgin Gift Vouncher, Rewards points each time you spend and Up to 44 interest free days</span>"}
               ],
              revertRate: '20.99%',
              partnerId: 2,
              productTypeId: 5,
			        productId: 6
          }
];

// export const creditCards = [
//           {
//               id : 1,
//               title : "AMEX Essential",
//               imgUrl : "/media/images/products/credit-card-offers/cc-amex.png",
//               href: "http://groupmconnect.go2cloud.org/aff_c?offer_id=2&aff_id=1022&aff_sub=gcspartners&url_id=276",
//               features: [
//                   { key:"1", title : "Balance Transfer", value: "0% (12mths)" },
//                   { key:"2", title : "Purchase Rate", value: "14.99% **"},
//                   { key:"3", title : "Features",value: "<span>Receive a $50 credit & Smartphone screen insurance</span>"}
//               ],
//               revertRate: '14.99%',
//               partnerId: 2,
//               productTypeId: 5,
// 			        productId: 1
//           },
//
//           {
//               id : 2,
//               title : "Virgin Low Rate",
//               imgUrl : "/media/images/products/credit-card-offers/cc-virgin.png",
//               href: "https://ad.doubleclick.net/ddm/clk/400176803;200185205;n",
//               features: [
//                   { key:"1", title : "Balance Transfer", value: "0% (12mths)" },
//                   { key:"2", title : "Purchase Rate", value: "11.99% **"},
//                   { key:"3", title : "Features",value: "<span>Up to 44 interest free days *</span>"}
//               ],
//               revertRate: '21.69%',
//               partnerId:  2,
//               productTypeId: 5,
// 			        productId: 2
//           },
//
//           {
//               id : 3,
//               title : "St George Vertigo",
//               imgUrl : "/media/images/products/credit-card-offers/cc-st-george.png",
//                 href: "http://prf.hn/click/camref:11ltZ3/pubref:GCS/destination:http://adsfac.net/link.asp?cc=CDS054.221712.0&clk=1&creativeID=314501&ord=636325991147629515",
//                 features: [
//                    { key:"1", title : "Balance Transfer", value: "0% (14mths)" },
//                    { key:"2", title : "Purchase Rate", value: "13.24% **"},
//                    { key:"3", title : "Features",value: "<span>$0 Annual Fee in 1st year & up to 55 Days interest free</span>"}
//                ],
//                revertRate: '13.24%',
//               partnerId: 2,
//               productTypeId: 5,
// 			        productId: 3
//           }
// ];
