import ScoreMapApi from '../../api/scoreMapApi';

export function getSA3Areas(searchTerm) {
  return (dispatch, getState) => {
    let state = getState();
    let csrfToken = (state && state.userProfile) ? state.userProfile.csrfToken : null; 

    return ScoreMapApi.getSA3Areas(csrfToken, searchTerm);
  };
}

export function getGeoJsonForArea(sA3Code) {
  return (dispatch, getState) => {
    let state = getState();
    let csrfToken = (state && state.userProfile) ? state.userProfile.csrfToken : null;     

    return ScoreMapApi.getGeoJsonForArea(csrfToken, sA3Code);
  };
}