import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as GlobalVariables from '../../config/configureGlobalVariables';




function defineImageUrlBasedOnScore(scoreInfo) {

  let imageUrl = GlobalVariables.IMAGES_URL + 'dial/';

  let currentScore = scoreInfo.score;

      if (currentScore < 150 && currentScore >= 0 && currentScore != null)
              imageUrl += "0-150.png";
      else if (currentScore >= 150 && currentScore < 250)
              imageUrl += "150-250.png";
      else if (currentScore >= 250 && currentScore < 350)
              imageUrl += "250-350.png";
      else if (currentScore >= 350 && currentScore < 450)
              imageUrl += "350-450.png";
      else if (currentScore >= 450 && currentScore < 550)
              imageUrl += "450-550.png";
      else if (currentScore >= 550 && currentScore < 650)
              imageUrl += "550-650.png";
      else if (currentScore >= 650 && currentScore < 750)
              imageUrl += "650-750.png";
      else if (currentScore >= 750 && currentScore < 850)
              imageUrl += "750-850.png";
      else if (currentScore >= 850 && currentScore < 950)
              imageUrl += "850-950.png";
      else if (currentScore >= 950 && currentScore < 1200)
              imageUrl += "950-1200.png";
      else
          imageUrl += "Dial-blank.png";

      scoreInfo.imgUrl = imageUrl;
}

function resetScoreProfileMetadata(state){

  state.apiFail = false;
  state.loading = false;
  //state.hasScoreInProgress = false;
  //state.isNoRecords = false;
  state.isTimeout = false;
  state.tryingCGetScoreCount = 0;
}

export default function scoreReducer(state = initialState.scoreProfile, action) {
  switch (action.type) {

     case types.AJAX_LOAD_SCORE:

         var newState = Object.assign({}, state);
         newState.loading = action.loading;

         if(newState.loading){
           newState.isTimeout = false;
           newState.tryingCGetScoreCount = newState.tryingCGetScoreCount + 1;
         }

        return newState;

     case types.API_FAIL + 'scoreProfile':

         var newState = Object.assign({}, state);
         resetScoreProfileMetadata(newState);
         newState.apiFail = true;
        return newState;

     case types.RESET_SCORE_PROFILE:

        var newState = Object.assign({}, initialState.scoreProfile);

            newState.scoreInfo = Object.assign({}, initialState.scoreProfile.scoreInfo);
            //newState.scoreAdvice = Object.assign({}, { helping:[], hurting: []});
            newState.scoreAdvice = Object.assign({}, { kcfs:[] });
            newState.scoreHistory = [];
        return newState;


      case types.LOAD_SCORE_PROFILE_SUCCESS:

        var newState = Object.assign({}, state);

        if(action.options && action.scoreProfile){

          // Reset
          resetScoreProfileMetadata(newState);
          newState.scoreInfo = Object.assign({}, initialState.scoreProfile.scoreInfo);
          newState.scoreAdvice = Object.assign({}, { kcfs:[] });
          newState.scoreHistory = [];

          if(action.scoreProfile.tryingCGetScoreCount > action.scoreProfile.tryingGetScoreMax){
            newState.isTimeout = true;
            newState.scoreInfo.noScore = true;
            newState.scoreInfo.isTryLater = true;

          }
          else {
            if(action.scoreProfile["displayName"]){
              newState.displayName = action.scoreProfile["displayName"];
            }

            if(action.options.includeScore){
               newState.scoreInfo = Object.assign({}, action.scoreProfile.scoreInfo);
               defineImageUrlBasedOnScore(newState.scoreInfo);
            }

            if(action.options.includeAdvices && action.scoreProfile.scoreAdvice){
              newState.scoreAdvice = Object.assign({}, action.scoreProfile.scoreAdvice);
            }
            if(action.options.includeHistory && action.scoreProfile.scoreHistory){
              newState.scoreHistory = [...action.scoreProfile.scoreHistory];
            }
          }

        }



        // newState.scoreInfo.noScore: false;
        // newState.scoreInfo.isScoreBadRequest : false;
        // newState.scoreInfo.isTryLater: false;
        // newState.scoreInfo.isServiceNotAvailableNow: false;
        // newState.scoreInfo.isNoFile: false;
        // newState.scoreInfo.isContactUs: false;

        return newState;

    case types.GET_SCORE_COUNTER_SUCCESS:
      if (state.scoreCounter.timerId && action.scoreCounter.timerId) {
        clearInterval(state.scoreCounter.timerId);
      }

      var newState = Object.assign({}, state);
      var newScoreCounter = Object.assign({}, newState.scoreCounter, action.scoreCounter);
      newState.scoreCounter = newScoreCounter;
      return newState;

    case types.UPDATE_SCORE_COUNTER:
      var newState = Object.assign({}, state);
      var newScoreCounter = Object.assign({}, newState.scoreCounter);
      newScoreCounter.counter = action.counter;
      newScoreCounter.loaded = action.loaded;
      newState.scoreCounter = newScoreCounter;
      return newState;

    default:
      return state;
  }
}
