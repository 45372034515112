import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DashboardKfcContent = (props) => {
    
    // get image url
   
    return (
      <>
        <div className="d-flex align-items-center p-4">                                             
            <img className="nv-kcf-img-progress img-fluid" src={props.imgUrl} ></img>
        </div>
        <div>
            <p>
            {props.text}            
            </p>
        </div>
        </>
    );
};

DashboardKfcContent.propTypes = {    
    imgUrl: PropTypes.string,
    text: PropTypes.string
};

const DashboardKcfCard = (props) => {

    var number = props.level + (props.isPositive ? 3 : 0);
    var imgUrl = `/images/picture/kcf-progress-${number}.png`;

return (
    <div className="card">
        <div className="card-header" id={"heading_" + props.index}>
            <h5 className="mb-0">
                <button className="btn btn-link"
                data-toggle="collapse"
                data-target={"#collapse_" + props.index}
                aria-expanded="true"
                aria-controls={"collapse_" + props.index}>
                {props.label}
                </button>
            </h5>
        </div>

        <div id={"collapse_" + props.index} 
           className={"collapse " + (props.show? "show": "")}
           aria-labelledby="headingOne"
           data-parent="#accordion">
                <div className="card-body px-4">
                    <DashboardKfcContent
                    imgUrl={imgUrl}
                    text={props.text}
                    />
                </div>
        </div>
    </div>

);
};

DashboardKcfCard.propTypes = {
    imgUrl: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    index: PropTypes.number,
    show: PropTypes.bool
};


const KcfTitleRow = () => {

return (<div className="row no-gutters pb-5">
                <div className="col-xs-12 col-sm-5 p-0 ">
                    <div className="d-flex">
                        <div className="nv-bg-lb-block"></div>
                        <div className="text-white font-weight-bold align-self-center pl-3">Factors affecting <br /> your score</div>
                    </div>    
                </div>
                <div className="d-none d-sm-block col-sm-7  pl-4 ">
                    <div className="d-flex">
                        <div className="nv-bg-lb-block"></div>
                        <div className="text-white font-weight-bold align-self-center  pl-3">Impact</div>
                    </div>
                </div>
        </div>);
};


const KcfTilesRow = ({kcfsArray, indexSelected}) => {

   const [currentSelection, setCurrentSelection] =  useState(indexSelected)

   var kcfSelected = kcfsArray[currentSelection];
   var imgUrl = '';
   var text = '';
    if(kcfSelected)    {
        var number = kcfSelected.level + (kcfSelected.isPositive ? 3 : 0);
        imgUrl = `/images/picture/kcf-progress-${number}.png`;
        text = kcfSelected.text;
    }
    
    return (
    <div className="row no-gutters d-none d-sm-flex">
            <div className="col-5 p-0">
                <aside className="nav-aside nv-kcf-nav">
                    <ul id="tabs">      
                        {kcfsArray && kcfsArray.map((item, i) => {
                            return (
                                <li key={i}
                                    className={"nv-kcf-li d-flex align-items-center " + ((i == currentSelection)? "nv-kcf-li-current":"")}
                                    onClick={(e) => {e.preventDefault(); setCurrentSelection(i);}}>
                                <a href="">{item.label}</a>
                                </li>
                            )
                        } )}                                        
                    <li className="d-none"></li>
                    </ul>
                </aside>
            </div>
            <div className="col-7 pl-4">
                {kcfsArray && <DashboardKfcContent
                imgUrl={imgUrl}
                text={text}                                      
                />
                }
            </div>
        </div>
    );
};

KcfTilesRow.propTypes = {
    kcfsArray: PropTypes.array,
    indexSelected: PropTypes.number
};


export const DashboardKcf = (props) =>{
    
    return (
    
    <div className="nv-kcf-wrapper nv-scroll-y nv-bg-color-white nv-dashboard-kcf-overlay">
        <div className="current-deal-item  p-0">
            <div> {/* it could scroll here */}
                <div className="container nv-kcf-v-gradient"> 
                    <KcfTitleRow />                
                    <KcfTilesRow kcfsArray={props.kcfsArray} indexSelected={0} />
    
                    <div className="row no-gutters d-xs-block d-sm-none">                  
                            <div id="accordion" className="nv-kcf-small-acc">
                                {props.kcfsArray && props.kcfsArray.map((item, i) => <DashboardKcfCard key={i} {...item} index={i} show={i == 0} />) }                                                                   
                            </div>
                    </div>
    
                </div>
            </div>
        </div>        
    </div>
    );
};

DashboardKcf.propTypes = {
    isInCohort: PropTypes.bool,
    kcfsArray: PropTypes.array  
};

