import React from 'react';
import PropTypes from 'prop-types';
import * as GlobalVariables from '../../../config/configureGlobalVariables';

let loadingImgUrl = GlobalVariables.IMAGES_URL + 'gcs/loading.gif';

const LoadingIndicatorBottomLayout = ({text, size, mobileSize}) => {
 let classForText = text ? '':'hidden';

  return (
    <div className="activity-indicator">
      <ImageLayout size={size} mobileSize={mobileSize} />
      <div className={classForText}>{text}</div>
    </div>
  )
}

const ImageLayout = ({ size, mobileSize}) => {
  let dimension = size;
  let mobileDimension = mobileSize;

  return (
    <span>
    <img src={loadingImgUrl} width={dimension}  height={dimension} className='hidden-xs' />
    <img src={loadingImgUrl} width={mobileDimension} height={mobileDimension} className='hidden-sm hidden-md hidden-lg' />
    </span>
  )
}

const LoadingIndicator = ({text, textPosition, size=100, mobileSize=50}) => {
  let classForText = text ? '' : 'hidden';

  return (
    <div className="container-fluid text-center p-4">
      <input type="hidden" id="pendingElement" value="1"/>
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
};

LoadingIndicator.propTypes = {
  text: PropTypes.string,
  textPosition: PropTypes.string
};

export default LoadingIndicator;
