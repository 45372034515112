import React from 'react';
import HelperApi from '../../../../api/HelperApi';
import * as funcs from '../../../shared/functions';

const HLAAmountPicker = ({step, stepState, wizardStepState, wizardStepIndex, wizardStepsDef, onUpdate, cssClass}) => {
  let component = <div />;
  let _key = 0;

  let updateText = function(event, stepId, stepNro, questionId, key) {
    const unmaskedValue = HelperApi.removeCommaSeparators(event.target.value);
    onUpdate(stepId, stepNro, questionId, null, false, [{inputKey: key, inputValue: unmaskedValue}]);
  };

  let moveForward = function(e) {
    e.preventDefault();
    onUpdate(step["id"], step["step"], 'keyvalue', null,  true, [{inputKey: _key, inputValue: stepState["selectedTexts"][_key]}]);

  };

  if (step && stepState) {
    let i = 0;
    const maskedValue = HelperApi.toThousandSeparatedFormat(stepState["selectedTexts"][_key]);
    let stepsInstruction = (typeof step["stepsInstruction"] === "function")
                            ? step.stepsInstruction(wizardStepState, wizardStepIndex, wizardStepsDef)
                            : step["stepsInstruction"];

    let extraComponent = <div />;

    extraComponent = (
      <div className="row wizard-action-btn-row">
        <div className="col-xs-12">
          <form onSubmit={(e) => moveForward(e)}>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className={"glyphicon " + step["glyphicon"]} />
                </span>
                <input
                  className="form-control input-lg wizard-action-input-txt"
                  type="tel"
                  value={maskedValue}
                  inputMode="numeric"
                  step="1"
                  pattern="[0-9,]*"
                  data-parsley-pattern="[0-9,]*"
                  onChange={(evt) => updateText(evt, step["id"], step["step"], 'keyvalue', _key)}
                />
              </div>
            </div>
          </form>
          { step["stepsTnCs"] && <span className="wizard-step-title" dangerouslySetInnerHTML={funcs.createMarkup(step["stepsTnCs"])} ></span>}
        </div>
      </div>
    );

    component = (
      <form onSubmit={(e) => moveForward(e)}>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <div className="input-group-text">$</div>
          </div>
          <input
                    className="form-control"
                    type="tel"
                    value={maskedValue}
                    inputMode="numeric"
                    step="1"
                    pattern="[0-9,]*"
                    data-parsley-pattern="[0-9,]*"
                    onChange={(evt) => updateText(evt, step["id"], step["step"], 'keyvalue', _key)}
                  />
                  
                  { step["stepsTnCs"] && <span className="wizard-step-title" dangerouslySetInnerHTML={funcs.createMarkup(step["stepsTnCs"])} ></span>}
        </div>
      </form>
    );
  }

  return ( component );
};

export default HLAAmountPicker;
