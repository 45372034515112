import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';

const DefaultsModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">
            
            <h4><span>1</span> Information shown for each default listing:</h4>              
                <ul>
                  <li>Name of current credit provider</li>
                  <li>Name of original credit provider</li>
                  <li>Type of account enquiried – e.g. personal loan, credit card &amp; telco</li>
                  <li>Date of default</li>
                  <li>Original amount</li>                  
                  <li>Current amount</li>                  
                </ul>  
            <h4><span>2</span> What is a default?</h4>             
              <p>A non-payment of a debt of $150 or more that has been overdue for at least 60 days can be listed as a ‘default’ on your credit report by a credit provider such as a bank or utility company.</p>              
              <p>Defaults will remain on your credit report for a period of five years. Once you have paid the default, the status of your default will be updated to ‘paid’.</p>
              <p>Defaults are looked upon unfavourably by credit providers, so, it’s a good idea to contact your credit provider early, if you are having trouble meeting your repayments on time.</p>              
             <h4>We have also added further details in relation to your defaults, in particular:</h4>             
              <p><b>New Arrangement</b> - refers to a new consumer credit provided that relates, wholly or in part, to this amount of credit.</p>       
              <p><b>New Arrangement (Varied)</b> - refers to a variation of the terms or conditions of the original consumer credit that relate to the repayment of the amount of the credit are varied.</p>                       
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default DefaultsModal;