import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../../shared/pages';
import { Link } from 'react-router-dom';

import * as GlobalVariables from '../../../config/configureGlobalVariables';
import LoadingIndicator from '../../common/controls/LoadingIndicator';
import * as funcs from '../../shared/functions';
import * as selectors from '../../../selectors/selectors';
import {noScoreReasons, noScoreReasonsActions} from '../../shared/staticNoScoreReasons';
import {ApiFailedToLoadComponentWithText} from '../../common/ApiFailedToLoadComponent';
import {NoScoreComponent} from './NoScoreComponent';
import {ArrowDown} from '../../common/controls/ArrowDown';
import * as ImagesPath from '../../../config/imagesPath';
import * as RoutesVariables from '../../../config/routesVariables';

const $ = window.$;

//export const DashboardScore = ({textVariants, scoreInfo, onHistoryClick}) => {
export class DashboardScore extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = {
        scoreToDisplay: 0,
        imageLoaded : false,
        showAnimation: true,
        animationClass: ""
      };

      this.handleScoreIncrement = this.handleScoreIncrement.bind(this);
      this.handleImageLoaded = this.handleImageLoaded.bind(this);
      this.refreshExternalHooks = this.refreshExternalHooks.bind(this);
      this.onPromotionClick = this.onPromotionClick.bind(this);
      this.getScoreWheel = this.getScoreWheel.bind(this);
      this.getScoreDisplay = this.getScoreDisplay.bind(this);
      this.getScoreMessagesOld = this.getScoreMessagesOld.bind(this);
      this.getScoreMessagesNew = this.getScoreMessagesNew.bind(this);
      this.trackArrowDown = this.trackArrowDown.bind(this);
  }

  handleScoreIncrement() {

    let currentScore = this.state.scoreToDisplay;

    currentScore += GlobalVariables.SCORE_INCREMENT_STEP;

    if (currentScore >= this.props.scoreProfile.scoreInfo.score) {

      currentScore = this.props.scoreProfile.scoreInfo.score;

      clearInterval(this.timerID);
      this.timerID = null;
    //  this.setState({animationClass: " tada animated " });
      this.props.onScoreShown(true);

    }

    this.setState ({ scoreToDisplay: currentScore});
  }

  handleImageLoaded(){
    this.setState({imageLoaded : true});
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps && !nextProps.loading){

        if(!nextProps.scoreProfile.scoreInfo.noScore && nextProps.scoreProfile.scoreInfo.score > 0){

          if (this.state.showAnimation) {// && this.state.imageLoaded) {

              this.setState({showAnimation:false});
              this.setState ({ scoreToDisplay: 0});
              if (this.timerID) {
                 clearInterval(this.timerID);
                 this.timerID = null;
               }
              this.timerID = setInterval(this.handleScoreIncrement, 20);
          }
          else if (!this.timerID){
            this.setState ({ scoreToDisplay: nextProps.scoreProfile.scoreInfo.score});
          }
        }
    }

  }

  componentWillUnmount() {
    if (this.timerID) {
       clearInterval(this.timerID);
       this.timerID = null;
     }
  }

  componentDidMount() {
    this.refreshExternalHooks();

 
  }

  componentDidUpdate(prevProps, prevState){
      this.refreshExternalHooks();
  }

  onPromotionClick(evt){

    let isPromotion = this.props.userProfile && this.props.userProfile["isPromotionEnabled"];
    let isNewUser = this.props.userProfile && this.props.userProfile["isPromotionNewUser"];

   if(isPromotion){
          if(isNewUser){
              this.props.actions.participatedInPromotion();
          }
          else{
              evt.preventDefault();
              this.props.history.push(RoutesVariables.AboutMe);
          }
     }
   }

  refreshExternalHooks(){

    let actions = this.props.actions;

  }


  getScoreWheel(userProfile, scoreInfo, scoreNumber, textVariants){
    var description;
    var scoreRotate;
    var scoreColor;
    var dashColor;
    var dashTop;
    var dashLeft;
    if(scoreInfo.score <= 509){
      scoreRotate = "rotate(0.1turn)";
      scoreColor = "linear-gradient(20deg, #F80000 0%, #ff3b3b 100%)";
      dashColor = "#F80000";
      dashTop= '70px';
      dashLeft= '10%';
      description = textVariants["DASHBOARD$$belowAverageDescription"]
    }
    else if(scoreInfo.score <= 621) {
      scoreRotate = "rotate(0.2turn)";
      scoreColor = "linear-gradient(30deg, #F89400 0%, #ffb74b 100%)";
      dashColor = "#F89400";
      dashTop= '2px';
      dashLeft= '35%';
      description = textVariants["DASHBOARD$$averageDescription"]
    }
    else if(scoreInfo.score <= 725) {
      scoreRotate = "rotate(0.3turn)";
      scoreColor = "linear-gradient(60deg, #F6DD37 0%, #ffeb68 100%)";
      dashColor = "#F6DD37";
      dashTop= '3px';
      dashLeft= '63%';
      description = textVariants["DASHBOARD$$goodDescription"]
    }
    else if(scoreInfo.score <= 832) {
      scoreRotate = "rotate(0.4turn)";
      scoreColor = "linear-gradient(100deg, #6CC938 0%, #85d458 100%)";
      dashColor = "#6CC938";
      dashTop= '72px';
      dashLeft= '86%';
      description = textVariants["DASHBOARD$$veryGoodDescription"]
    }
    else {
      scoreRotate = "rotate(0.5turn)";
      scoreColor = "linear-gradient(60deg, #3be687 0%, #3be687 100%)";
      dashColor = "#3be687";
      dashTop= '190px';
      dashLeft= '95.6%';
      description = textVariants["DASHBOARD$$excellentDescription"]
    }

    let result = 
        <section className="section-hero" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 text-wrapper">
                <h1>Hello, {userProfile.displayName}!</h1>
                <p className="d-none d-md-block">You’ve got great credit score!<br/>
                We recommend you a few deals below and if you want to<br/> learn more about your credit score click <a href={RoutesVariables.RouteDashboardScoreHistory}>here</a>.</p>
                <p className="d-md-none">Thanks to below tips, you will improve<br/> your credit score quickly!</p>
                <Link to={RoutesVariables.RouteDashboardScoreHistory} className="history d-none d-md-block"><img src={ImagesPath.ImgIconHistory} className="img-fluid" alt=""/> See all score history</Link>
              </div>

              <div className="col-xl-5 ml-auto col-lg-6 col-md-8 ml-md-auto mr-md-auto col-12 gauge-area active">
                <div className="guage-mask">
                <div className="guage-text">
                    <h4 className="score">My score</h4>
                    <h2 className="score-number">{scoreInfo.score}</h2>
                    <h3 className="good">{scoreInfo.bandDescription} <a href="#" className="info-tooltip" data-toggle="tooltip" data-placement="bottom" title={description} data-original-title={description}><img src={ImagesPath.ImgIconTooltip} className="img-fluid info-icon" alt=""/></a></h3>
                  </div>
                </div>
                <div className="gauge-score">
                  <div className="gauge-a"></div>
                  <div className="gauge-b"></div> 
                  <div className="gauge-c" style={{transform:scoreRotate, background: scoreColor}}></div>
                  <span style={{backgroundColor:dashColor, top:dashTop, left:dashLeft}}></span>
                </div>
                <Link to={RoutesVariables.RouteDashboardScoreHistory} className="history"><img src={ImagesPath.ImgIconHistory} className="img-fluid" alt=""/> See history</Link>
              </div>
            </div>
          </div>
        </section>
        ;
    return result;
  }

  getScoreMessagesOld(userProfile, scoreInfo){

    let textVariants = this.props.textVariants;
    let showCompleteScoreCls = this.timerID!=null ? 'hidden' : '';
    let showCompleteScoreInfoTextCls = showCompleteScoreCls + ' small';

    let result = <div className='your-score-content-messages'>
      <p className={showCompleteScoreCls}>

        <span className='different-score-tooltip rwd-line'>{textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "different_score"]}
          <span className="tooltipInfo"
            data-placement="top"
            data-toggle="tooltip"
            data-title={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "different_score_tooltip_message"]}
            data-original-title=""
            data-html="true"
            title="">
            <i className="tooltip-sign glyphicon glyphicon-info-sign"></i>
          </span>
        </span>

        <span className="bold-font rwd-line" dangerouslySetInnerHTML={funcs.createMarkup(scoreInfo.bandDescription)}>
        </span>

        <span className="xs-ml-10 rwd-line" dangerouslySetInnerHTML={funcs.createMarkup(scoreInfo.bandRange)}>
        </span>
      </p>

      <p className={showCompleteScoreInfoTextCls}>
        <span dangerouslySetInnerHTML={funcs.createMarkup(scoreInfo.information)}>
        </span>
      </p>

      <div className="hidden-xs">

        <a className="btn btn-lg btn-info sharp personal-loan-blue-btn " onClick={() =>this.props.onTabChange(1)}>
          {this.props.textVariants["Btn_GetScoreHistory"]}
        </a>
      </div>

      <div className="hidden-sm hidden-md hidden-lg">
          <div className="xs-mt-10">
            <a className="btn btn-lg btn-info sharp personal-loan-blue-btn " onClick={() =>this.props.onTabChange(1)}>
              {this.props.textVariants["Btn_GetScoreHistory"]}
            </a>
         </div>
      </div>

      <div className="xs-mt-10 hidden-sm hidden-md hidden-lg">
        <a className="btn btn-lg btn-info sharp get-score-btn md-mt-10 sm-mt-10 xs-mt-0" href="#dashboard-next-steps-outer">
          {this.props.textVariants["Btn_NextSteps"]}
        </a>
       </div>
   </div>;

   return result;
  }

  getScoreMessagesNew(userProfile, scoreInfo, onClick){
   
    let textVariants = this.props.textVariants;
    let showCompleteScoreCls = this.timerID!=null ? 'hidden' : '';
    let showCompleteScoreInfoTextCls = showCompleteScoreCls + ' small';

    let message = null;
    let displayArrowDown = true;
    if(scoreInfo.bandRange && scoreInfo.bandRange== '0-459'){
      displayArrowDown = false;
      message = this.props.textVariants["score_result_sell-" + scoreInfo.bandRange];
      if(message){
        message = message.replace('{displayName}', userProfile.displayName);
      }
    }


    let result = <div className='your-score-content-messages'>

      <p className={"score-result " + showCompleteScoreCls}>

        <span className="bold-font rwd-line" dangerouslySetInnerHTML={funcs.createMarkup(scoreInfo.bandDescription)}>
        </span>

        <span className="xs-ml-10 rwd-line" dangerouslySetInnerHTML={funcs.createMarkup(scoreInfo.bandRange)}>
        </span>
      </p>
      
      <span className=" terms-size">{this.props.textVariants["score_disclaimer"]}</span>

      <p className={"score-result-action " + showCompleteScoreInfoTextCls}>
        <span dangerouslySetInnerHTML={funcs.createMarkup(message)}>
        </span>
      </p>

      {displayArrowDown && <ArrowDown href="#product-section" onClick={onClick} cssClass={"hidden-md hidden-lg " + showCompleteScoreInfoTextCls} />}

   </div>;

   return result;
  }

  trackArrowDown(){
    this.props.actions.trackButtonClick(pages.DASHBOARD, 'prod-arrow-down');
  }


  getScoreDisplay(userProfile, scoreInfo, scoreNumber, animationClass, theme, textVariants){

    let scoreWheel = this.getScoreWheel(userProfile, scoreInfo, scoreNumber, textVariants);

    let scoreMessages = <div></div>;

    if(theme.showScoreMessagesOption == "new"){
       scoreMessages = this.getScoreMessagesNew(userProfile, scoreInfo, this.trackArrowDown);
    }
    else{
       scoreMessages = this.getScoreMessagesOld(userProfile, scoreInfo);
    }

    //let animationClass = " wow bounceIn animated ";
// data-wow-delay="2s" data-wow-duration="1.5s"
    let result =
     <div className={animationClass}  >
       {scoreWheel}
       {/* {scoreMessages} */}
    </div>;

    return result;

  }


  render () {

      let textVariants = this.props.textVariants;
      let scoreInfo = this.props.scoreProfile["scoreInfo"];
      let scoreNumber = this.state.scoreToDisplay > 0 ? this.state.scoreToDisplay :scoreInfo.score;

      let mainComponent = <div></div>;
      if(this.props.loading){
        mainComponent = <LoadingIndicator text={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "score_loading_text"]}   />;
      }
      else if (scoreInfo.noScore){
        mainComponent = <NoScoreComponent userProfile={this.props.userProfile}  scoreInfo={scoreInfo} actions={this.props.actions}   textVariants={textVariants} />;

        if(scoreInfo.isScoreBadRequest){
          //mainComponent = <div>isScoreBadRequest</div>;
        }
        else if(scoreInfo.isTryLater){
            mainComponent = <ApiFailedToLoadComponentWithText text={textVariants["Api_Equifax_TryLater"]} />;
        }
        else if(scoreInfo.isServiceNotAvailableNow){
            mainComponent = <ApiFailedToLoadComponentWithText text={textVariants["Api_Equifax_Down"]} />;
        }
        else if(scoreInfo.isNoFile){
            //mainComponent = <div>isNoFile</div>;
        }
        else if(scoreInfo.isContactUs){
            //mainComponent = <div>isContactUs</div>;
        }

      }
      else if(!this.state.imageLoaded) {
        mainComponent = <div>
                            <LoadingIndicator text={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "score_loading_text"]}   />
                             <img className="hidden" src={scoreInfo.imgUrl} onLoad={this.handleImageLoaded} />
                          </div>;
      }
      else {

        // let isPromotion = this.props.userProfile && this.props.userProfile["isPromotionEnabled"] && this.props.userProfile["isPromotionEnabledForUser"];
        // let isNewUser = this.props.userProfile && this.props.userProfile["isPromotionNewUser"];
        // let promoText = isNewUser ? <span>Are you an instant winner? Click here</span>:<span>Click here to complete your profile and WIN</span>;
        // var urlForPrize = isNewUser ? GlobalVariables.PEAZIEURL.replace('{USERID}', 'r-' + this.props.userProfile.identifier) : "";

        // let counterFinished =  scoreInfo.score == this.state.scoreToDisplay;
        // let animatedCssScore = counterFinished ? " tada animated ": "";

        let scoreDisplay = this.getScoreDisplay(this.props.userProfile, scoreInfo, scoreNumber, this.state.animationClass, this.props.theme, textVariants);

        mainComponent = scoreDisplay
        }

        return mainComponent;
    }
}

DashboardScore.contextTypes = {
  router: PropTypes.object
};