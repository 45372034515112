import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';

import * as pages from '../../shared/pages';
import * as RoutesVariables from '../../../config/routesVariables';
import * as ImagesPath from '../../../config/imagesPath';
import * as funcs from '../../shared/functions';
import {initTonikForms} from '../../../tonik/shared/forms';

import TextInput from '../../common/controls/TextInput';
import TextNumericInput from '../../common/controls/TextNumericInput';
import DateInputSimple from '../../common/controls/DateInputSimple';

import { medicareCardColour } from '../../../common/dvsMedicare';

import {DvsConsentCheckbox} from './DvsCommon';
import {initTonikFormsTooltip} from '../../../tonik/shared/forms';

//const DvsMedicareForm = ({ customerIds, errors, updateCustomerIdsState, changeCard, namesOptions, cardImg, saveCustomerIds, isInline = false, showButton=true }) => {
//const DvsMedicareForm = ({ identityRequest, onSave, onChange, errors, saving, textVariants, onBack, isInline = false}) => {

class DvsMedicareForm extends React.Component { 
    constructor(props, context) {
        super(props, context);

    }

    componentDidMount(){
      initTonikFormsTooltip();
    }
  
    render(){
      
      let { identityRequest, onSave, onChange, errors, saving, textVariants, onBack, isInline = false} = this.props;
      let dlImage = ImagesPath['ImgDvs_M_' + identityRequest['cardColour']];        
      return (
        <form>
          <div className="row">
            <div className={isInline ? "col-12" :"col-lg-6 col-12"}>

            <div className="col-12">
              {(errors && errors['global']) &&
                  (<div className="alert alert-danger" role="alert">
                          <span dangerouslySetInnerHTML={funcs.createMarkup(errors['global'])}></span>                    
                          {errors['type'] && <span> or <a onClick={onBack} style={{float: "none !important;", cursor: "pointer;"}}>click here</a> to choose another document</span>}
                  </div>)}
            </div>

              <div className="col-12 small-input">
                <div className="form-label-group">
                  <select className={"form-control filled " + ((errors && errors['cardColour']) ? "is-invalid" : "")}
                    id="medicareCardColour"
                    name="cardColour"
                    onChange={onChange}
                    value={identityRequest['cardColour']}>              
                    <option value={medicareCardColour.green}>Green</option>
                    <option value={medicareCardColour.blue}>Blue</option>
                    <option value={medicareCardColour.yellow}>Yellow</option>
                  </select>
                  <label htmlFor="medicareCardColour">Card colour</label>
                  <div className="invalid-feedback">{errors['cardColour']}</div>
                </div>
              </div>
              <div className={isInline ? "col-12 d-lg-none d-inline-block" : "col-lg-6 col-12 d-lg-none d-inline-block"}>
                <div className="">
                  <img className="img-fluid" src={dlImage} style={{maxWidth:'90%', margin:'auto !important'}} alt=""/>
                </div>
              </div>
              <div className="col-12 small-input">
                <TextInput
                  name="givenName"
                  id="givenName"
                  autocomplete="off"
                  placeholder={'Given Name as in Document'}
                  value={identityRequest['givenName']}
                  label={'Given Name'}
                  onChange={onChange}
                  error={errors['givenName']}
                  groupClass="form-label-group"  />
              </div>
              <div className="col-12 small-input">
                <TextInput
                  name="middleName"
                  id="middleName"
                  autocomplete="off"
                  placeholder={'Middle Name'}
                  value={identityRequest['middleName']}
                  label={'Middle Name'}
                  onChange={onChange}
                  error={errors['middleName']}
                  groupClass="form-label-group"  />
              </div>
              <div className="col-12 small-input">
                <TextInput
                  name="familyName"
                  id="familyName"
                  autocomplete="off"
                  placeholder={'Family Name'}
                  value={identityRequest['familyName']}
                  label={'Family Name'}
                  onChange={onChange}
                  error={errors['familyName']}
                  groupClass="form-label-group"  />
              </div>
              <div className="col-12 small-input">              
                <DateInputSimple  
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={identityRequest['dateOfBirth']}
                      label={'Date Of Birth'}
                      onChange={onChange}
                      error={errors['dateOfBirth']}
                      groupClass={"form-label-group"} 
                />                                          
              </div>
              <div className="col-12 small-input">
                <TextNumericInput
                        name="number"
                        id="medicareCardNumber"
                        placeholder={'Card number'}
                        value={identityRequest['number']}
                        label={'Card number'}
                        onChange={onChange}
                        error={errors['number']}
                        groupClass="form-label-group"/>
              </div>
              <div className="col-12 small-input">
                <TextNumericInput
                        name="referenceNumber"
                        id="medicareReferenceNumber"
                        placeholder={'Reference number next to your name'}
                        value={identityRequest['referenceNumber']}
                        label={'Reference number next to your name'}
                        onChange={onChange}
                        toolTip={ {helpText: "The number next to your name"} }
                        error={errors['referenceNumber']}
                        groupClass="form-label-group"/>
              </div>
              <div className="col-12 small-input">              
                <DateInputSimple  
                      id="dateOfExpiry"
                      name="dateOfExpiry"
                      value={identityRequest['dateOfExpiry']}
                      label={'Valid to'}
                      onChange={onChange}
                      error={errors['dateOfExpiry']}
                      groupClass={"form-label-group"} 
                      toolTip={ {helpText: "In some browsers it should be in this format: YYYY-MM (or YYYY-MM-DD depending on the card) where YYYY is 4 digit year, MM is 2 digits month and DD is 2 digits day separated by dash - "} }
                      notDay={identityRequest['cardColour'] == medicareCardColour.green} />                                          
              </div>
              <div className="col-12 small-input">
                <TextInput
                        name="middleNameOnCard"
                        id="middleNameOnCard"
                        placeholder={'Middle Name on Card'}
                        value={identityRequest['middleNameOnCard']}
                        label={'Middle Name on Card (Initial or Name)'}
                        onChange={onChange}
                        error={errors['middleNameOnCard']}                    
                        groupClass="form-label-group"/>
              </div>
                        
            </div>
            <div  className={isInline ? "col-12 d-none d-lg-block" :"col-lg-6 col-12 d-none d-lg-block"}>
              <div className="">
                <img className="img-fluid" src={dlImage} style={{maxWidth:'90%', margin:'auto !important'}} alt=""/>
              </div>
            </div>
          </div>

          <div className="col-12" >           
            
            <DvsConsentCheckbox
              identityRequest={identityRequest}          
              onChange={onChange}
              errors={errors}
            />
              
            <a onClick={onSave} className="btn btn-primary" disabled={saving}>
                    <span >{textVariants[pages.MYDETAILSVERIFICATION + textVariants.textVariantSeparator + "save_btn"]}</span>                                
                    {saving && <span class="spinner-border spinner-border-sm align-middle ml-2" role="status" aria-hidden="true"></span>}
            </a>
          </div>
        </form>
      );
    }
};


export default DvsMedicareForm;
