import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

import {initTonikForms} from '../../tonik/shared/forms';

class DeleteAccountPage extends React.Component { 
    constructor(props, context) {
        super(props, context);

        this.state = {
          errorPassword: null,
        };
    }

    componentDidMount() {

      this.props.actions.getTextVariants();

      this.props.actions.isAuthenticated().then(e =>
      {
              this.props.actions.getMenuForPage(pages.MYDETAILS);
              this.props.actions.loadUserDetails(true).catch(e =>
              {
                 if (e.status == 500) {
                    this.setState({ isServerError: true });
                 }
              });
      });

      initTonikForms();

    }

    componentWillReceiveProps(nextProps) {
    }

    validatePasswordDummy() {
      var pass = document.getElementById('password').value;
      if(pass == 'mipass') {
        this.setState({errorPassword: null});
        this.props.actions.logout();
        this.props.history.push('/delete-account-success');
        return;
      }
      var errorPassword = 'Incorrect Password';
      this.setState({errorPassword: errorPassword});
    }


    render() {
        return (
          <span>
            <div className="page-bg page-nottitle">
              <Menu menuItemList={this.props.menuItemList}  />
            </div>
            <section className="page-account-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12 box-account">
                    <img src={ImagesPath.ImgIconTrash} alt="" className="img-fluid d-none d-md-inline-block"/>
                    <img src={ImagesPath.ImgIconTrash2} alt="" className="img-fluid d-md-none d-sm-inline-block"/>
                    <h1>Delete my account</h1>
                    <p>If you are no longer interested in using the GetCredit<br/> portal, you can delete your account below. Cancel an <br/>account will permanently delete all data posted here.</p>
                    <form>
                      <div className="form-label-group">
                        <input type="password" id="password" className="form-control password" placeholder="Enter the Password" required/>
                        <a href="#" className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt=""/></a>
                        <label for="password">Enter the Password</label>
                        {this.state.errorPassword && <div className="error-message">{this.state.errorPassword}</div>}
                      </div>
                      <div className="button-fixed">
                        <a href={RoutesVariables.MyDetails} className="btn btn-secondary">Cancel</a>
                        <button type="button" className="btn btn-primary" onClick={()=> this.validatePasswordDummy()}>Delete Account</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            <div className="footer-account">
              <FooterSmallSection></FooterSmallSection>
            </div>
          </span>
        );
    }
    


}

DeleteAccountPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.MYDETAILS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.MYDETAILS];
     }
   }

   
   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountPage);
