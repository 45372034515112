import React from 'react';
import * as funcs from '../../../shared/functions';

const HLAOptionButton = ({item, showInCols, cssClass = "", isSelected, onClick, isSelectedExtraIndex = false,onSubmitExtraIndex =null, valueExtraText=null, onChangeExtraText=null}) => {
  let component = null;
  let cssClassBtn = showInCols > 1 ? "input-withborder" : "input-radio";
  let cssClassBtnExtraType = showInCols > 1 ? "other-checkbox" : "";
  let cssClassBtnExtra = cssClassBtnExtraType + (isSelected ? " focus": "");
  cssClassBtnExtra += ((isSelected && cssClassBtnExtraType == "") ? " filled": "");
  let type = showInCols > 1 ? "checkbox" : "radio";
  

  let isTextOutside = item["isTextOutside"];
  let selectedClass = isSelected ? " selected ":"";

  let closeOther = (tagName) => {
      if(tagName == 'INPUT') {
        return;
      }
      onClick()
  }

  if (item["imgUrl"]) {
    if (!isTextOutside) {
      component = <div className="form-check col-md-4 col-6" onChange={onClick}>
                    <input className="input-radio input-select square-input form-check-input" type="radio" id={'item' + item["id"]} name={'item' + item["id"]}  checked={isSelected}/>
                    <label className="form-check-label align-content-center" htmlFor={'item' + item["id"]} style={{lineHeight:'10px', display: 'grid'}}>
                       <img src={item["imgUrl"]} className="img-fluid mt-2" alt=""/> 
                       {item["value"]}
                    </label>
                  </div> 
    } else {
      component = 
                   
                   <div className="form-check col-md-4 col-6" onChange={onClick}>
                    <input className="input-radio input-select square-input form-check-input" type="radio" id={'item' + item["id"]} name={'item' + item["id"]}  checked={isSelected}/>
                    <label className="form-check-label" htmlFor={'item' + item["id"]} style={{lineHeight:'13px'}}>
                        <img src={item["imgUrl"]} className="img-fluid" alt=""/> 
                       {item["value"]}
                    </label>
                  </div> ;
    }
  } else {
    if(isSelectedExtraIndex){
      component = (
                      <form onSubmit={onSubmitExtraIndex}>
                        <div className={"form-check form-other "+cssClassBtnExtra} onClick={(e) => { closeOther(e.target.tagName) }} >
                          <label className="form-label" htmlFor={'item' + item["id"]}>{item["value"]}</label>
                          <input className="input-text" type="text" id={'item' + item["id"]} name={'item' + item["id"]} placeholder="Please complete" value={valueExtraText} onChange={onChangeExtraText} defaultChecked={isSelected} />
                        </div>
                      </form>
                    )
    }
    else {
      component = <div className="form-check">
                    <input className={"input-select form-check-input " + cssClassBtn} type={type} id={'item' + item["id"]} name={'item' + item["id"]}  checked={isSelected} onClick={onClick}/>
                    <label className="form-check-label" htmlFor={'item' + item["id"]}>{item["value"]}</label>
                  </div>;
    }
  }

  return (component);
}

export default HLAOptionButton;