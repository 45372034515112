import $ from 'jquery'


export const initTonikContactUs = () => {

// count number of signs in textarea - contact form

try{
  var textMax = 250
  $('#textarea_feedback').html(textMax + '/250')
  $('textarea').keyup(function () {
    var textLength = $('textarea').val().length
    var textRemaining = textMax - textLength
    $('#textarea_feedback').html(textRemaining + '/250')
  })
} catch(e){
  
}

return;

}