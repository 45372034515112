import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './app/App';
import * as serviceWorker from './serviceWorker';


import initialState from './redux/reducers/initialState';
import configureStore from './redux/store/configureStore';
import {GcsRoutes, GcsPrefix} from './routes';
import {loginRestoreFromLocalStorage, initUser, initApp} from './redux/actions/userActions';

import MenuCms from './components/menu/MenuCms';
import AppIndex from './app/AppIndex';

const $ = window.$;
const store = configureStore(initialState);

// let history = createBrowserHistory();
// history = syncHistoryWithStore(history, store);


store.dispatch(initApp(GcsPrefix));
store.dispatch(loginRestoreFromLocalStorage());
store.dispatch(initUser());


if (document.getElementById('app')) {
    ReactDOM.render(<AppIndex store={store} routes={<GcsRoutes />} />, document.getElementById('app'));
}

let menuCmsContainer = document.getElementById('cms-menu');
if (menuCmsContainer) {
  let pageName = menuCmsContainer.getAttribute('data-pageName');
  ReactDOM.render(<MenuCms store={store} useJustAnchors={true} pageName={pageName} />, document.getElementById('cms-menu'));
}

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
