import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as funcs from '../../../shared/functions';
import * as pages from '../../../shared/pages';

import {WizardCoreClass} from '../WizardCore';

import * as GlobalVariables from '../../../../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../../../../config/GlobalFixedVariables';

import { WizardHLAStepsDef, WizardHLAStepState, WizardHLAStepComponents, StepsIndexHLA, ThemePack, HlaUnoConfiguration, HlaExpertConfiguration, HlaFirstMacConfiguration } from './HomeLoanStepDef';

export class HomeLoanAssistant extends WizardCoreClass {

  constructor(props, context) {
    super(props, context);

  //  this.getGeneralRuleValidator = this.getGeneralRuleValidator.bind(this);
    this.onMoveStep = this.onMoveStep.bind(this);
  }

  _getTheme() {
    return ThemePack;
  }

  _getInitialState() {
    return WizardHLAStepState;
  }

  _getWizardComponents() {
    return WizardHLAStepComponents;
  }

  _getStepDefinition() {
    let extraConfig = this.props.initialProfile["extra"];
    if (extraConfig) {

      let stepOnlineFinal = WizardHLAStepsDef[StepsIndexHLA.stepIndexOnlineFinish];
      let stepExpertFinal = WizardHLAStepsDef[StepsIndexHLA.stepIndexBrokerFinish];

      if (stepOnlineFinal) {

        let onlineConfig = funcs.searchInList(extraConfig.partners, "path", "Online");
        let stepDef = stepOnlineFinal["stepDefinition"];

        stepDef["actionUrl"] = onlineConfig.partnerUrl;
        stepDef["productIdentifier"] = onlineConfig.productIdentifier;
        stepDef["shouldPushLead"] = onlineConfig.shouldPushLead;
        stepDef["partnerId"] = onlineConfig.partnerId;
        stepDef["displayWizard"] = onlineConfig.productDisplayWizard;

        stepDef["trackingInfo"] = {
          partnerId: onlineConfig.partnerId,
          productId: onlineConfig.productId,
          productTypeId: onlineConfig.productTypeId
        };


        if(onlineConfig.partnerId == GlobalFixedVariables.PARTNER_FIRSTMAC){
          stepDef["getStepData"] = HlaFirstMacConfiguration.getStepData; // update from config too

        }
        else{
          stepDef["getStepData"] = HlaUnoConfiguration.getStepData; // update from config too
        }

        WizardHLAStepsDef[StepsIndexHLA.stepIndexOnlineFinish] = stepOnlineFinal;

      }

      if (stepExpertFinal) {

        let brokerConfig = funcs.searchInList(extraConfig.partners, "path", "Broker");
        let stepDef = stepExpertFinal["stepDefinition"];

        stepDef["actionUrl"] = brokerConfig.partnerUrl;
        stepDef["productIdentifier"] = brokerConfig.productIdentifier;
        stepDef["shouldPushLead"] = brokerConfig.shouldPushLead;
        stepDef["partnerId"] = brokerConfig.partnerId;
        stepDef["displayWizard"] = brokerConfig.productDisplayWizard;

        stepDef["trackingInfo"] = {
          partnerId: brokerConfig.partnerId,
          productId: brokerConfig.productId,
          productTypeId: brokerConfig.productTypeId
        };

        stepDef["getStepData"] = HlaExpertConfiguration.getStepData; // update from config too

        WizardHLAStepsDef[StepsIndexHLA.stepIndexBrokerFinish] = stepExpertFinal;
      }
    }

    return WizardHLAStepsDef;
  }

  _getGeneralRuleValidator(stepsState){

    let validator = null;
    if (stepsState && stepsState.steps && stepsState.steps.length > 0) {
      const steps = stepsState.steps;
      const isCompare = steps[StepsIndexHLA.stepIndexChoosePath].selectedItems[0];
      const isBroker = steps[StepsIndexHLA.stepIndexChoosePath].selectedItems[1];

      if(isCompare){
          validator = HlaUnoConfiguration.getStepData;
      }
      else if(isBroker){
        validator = HlaExpertConfiguration.getStepData;
      }
    }

    return validator;

  }

  _getStepIndex () {
    return StepsIndexHLA;
  }

  // _setGlobalErrorList(list){

  // }

  _onSaveStep(savedStateStep, stepDefinition) {
    this.props.onSaveStep(savedStateStep, stepDefinition);
  }

  _onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex) {
      this.props.onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex);
  }

  _trackViewStep(actionStr, stepDefinition, currentStep) {
    //this.props.actions.trackAboutMe(actionStr, stepDefinition, currentStep);
  }

  _loadExtraConfiguration(wizardState, extraConfig) {

  }

  _refreshNavigationFlow(wizardStepIndex, stepsState) {
    let i = 0;


      // if (stepsState && stepsState.steps && stepsState.steps.length > 0) {
      //   let currentIndex = 0;
      //   for (currentIndex = 0; currentIndex < stepsState.steps.length; currentIndex++) {
      //     stepsState.steps[currentIndex].enabled = false;
      //   }
      //   stepsState.steps[wizardStepIndex.stepIndexOnlineFinish].enabled = true;
      // }

    if (stepsState && stepsState.steps && stepsState.steps.length > 0) {
      const steps = stepsState.steps;

      const isRefinance = steps[wizardStepIndex.stepIndexRefinanceOrBuy].selectedItems[0];
      const isBuy = steps[wizardStepIndex.stepIndexRefinanceOrBuy].selectedItems[1];
      const isRefinanceUseEquity = steps[wizardStepIndex.stepIndexRefinanceOrBuy].selectedItems[2];

      const isCompare = steps[wizardStepIndex.stepIndexChoosePath].selectedItems[0];
      const isBroker = steps[wizardStepIndex.stepIndexChoosePath].selectedItems[1];

      if (isCompare) {
        steps[wizardStepIndex.stepIndexOnlineFinish].enabled = true;

        // Cancel broker
        steps[wizardStepIndex.stepIndexBrokerRefinanceWhatToDo].enabled = false;
        steps[wizardStepIndex.stepIndexBrokerBuyWhatToDo].enabled = false;
        //steps[wizardStepIndex.stepIndexBrokerCitizenOrPR].enabled = false;
        steps[wizardStepIndex.stepIndexBrokerFinish].enabled = false;

        if (isRefinance || isRefinanceUseEquity) {
          // Cancel buy
          for (i = wizardStepIndex.stepIndexBuyPurchasePrice; i <= wizardStepIndex.stepIndexBuyIncome; i++) {
            steps[i].enabled = false;
          }

          //Enable refinance
          for (i = wizardStepIndex.stepIndexRefinancePropertyWorth; i <= wizardStepIndex.stepIndexRefinanceLenderInterestRate; i++) {
            steps[i].enabled = true;
          }

          // Fork Flow
          let ratio = 0;
          if(steps[wizardStepIndex.stepIndexRefinancePropertyWorth].isCompleted && steps[wizardStepIndex.stepIndexRefinanceOwe].isCompleted){

            let owe = Number(steps[wizardStepIndex.stepIndexRefinanceOwe].selectedTexts[0]);
            let worth = Number(steps[wizardStepIndex.stepIndexRefinancePropertyWorth].selectedTexts[0]);
            ratio = 1 - (owe / worth);


            //steps[wizardStepIndex.stepIndexRefinanceWantAddFunds].enabled = ratio < 0.75;
          }

          steps[wizardStepIndex.stepIndexRefinanceAddFunds].enabled = isRefinanceUseEquity && ratio < 0.75;
          //steps[wizardStepIndex.stepIndexRefinanceAddFunds].enabled = steps[wizardStepIndex.stepIndexRefinanceWantAddFunds].enabled && steps[wizardStepIndex.stepIndexRefinanceWantAddFunds].selectedItems[0];          


          // if lender is selected show lender interest rate
          let lender = null;
          if(steps[wizardStepIndex.stepIndexRefinanceLender].isCompleted){
            lender = steps[wizardStepIndex.stepIndexRefinanceLender].selectedTexts[0];
          }

          steps[wizardStepIndex.stepIndexRefinanceLenderInterestRate].enabled = !!lender;

        } else if (isBuy) {
          // Cancel refinance
          for (i = wizardStepIndex.stepIndexRefinancePropertyWorth; i <= wizardStepIndex.stepIndexRefinanceLenderInterestRate; i++) {
            steps[i].enabled = false;
          }

          //Enable buy
          for (i = wizardStepIndex.stepIndexBuyPurchasePrice; i <= wizardStepIndex.stepIndexBuyIncome; i++) {
            steps[i].enabled = true;
          }
        }
      }

      if (isBroker) {
        steps[wizardStepIndex.stepIndexBrokerFinish].enabled = true;
        //steps[wizardStepIndex.stepIndexBrokerCitizenOrPR].enabled = true;

        if (isRefinance || isRefinanceUseEquity) {
          steps[wizardStepIndex.stepIndexBrokerBuyWhatToDo].enabled = false;
          steps[wizardStepIndex.stepIndexBrokerRefinanceWhatToDo].enabled = true;
        } else if (isBuy) {
          steps[wizardStepIndex.stepIndexBrokerBuyWhatToDo].enabled = true;
          steps[wizardStepIndex.stepIndexBrokerRefinanceWhatToDo].enabled = false;
        }

        for (i = wizardStepIndex.stepIndexBuyPurchasePrice; i <= wizardStepIndex.stepIndexOnlineFinish; i++) {
          steps[i].enabled = false;
        }
      }
    }
  }

  // getGeneralRuleValidator(partnerId){
  //   return HlaUnoConfiguration.getStepData;
  // }
}

HomeLoanAssistant.contextTypes = {
  router: PropTypes.object
};