import * as types from './actionTypes';

export function beginAjaxCall() {
  return {type: types.BEGIN_AJAX_CALL};
}

export function ajaxCallError() {
  return {type: types.AJAX_CALL_ERROR};
}

export function apiFail(apiName, stateProperty){
  return {type: types.API_FAIL + stateProperty, api: {apiName : apiName, stateProperty: stateProperty}};
}
