import React from 'react';
import PropTypes from 'prop-types';
import * as funcs from '../../shared/functions';

export class CustomCheckBox extends React.Component {

  constructor(props, context) {
      super(props, context);

     this.toggleCheck = this.toggleCheck.bind(this);
  }

  toggleCheck() {

    let evt = {
      target: {
        type: 'checkbox',
        name: this.props.name,
        id: this.props.id,
        isChecked: !this.props.checked
      }
    };


    if (this.props.onClick) {
        this.props.onClick(evt);
    }
  }

  render() {
    
      let name, title, subtitle = null;
      if(this.props.box) {
        name = this.props.name.split(':');
        if(name.length == 1) {
          name = this.props.name.split('-');
        }
        if(name.length > 1) {
          title = name[0];
          subtitle = name[1];
        }
      }

      return (
        <div className={this.props.box ? "form-check w-100" : "form-check col-md-6"}>
            <input className={this.props.box ? "input-select form-check-input input-withborder" : "form-check-input input-withoutborder"} type="radio" id={this.props.id} name={this.props.id} checked={this.props.checked}/>
            <label className={this.props.box ? "form-check-label" : "form-check-label label-withoutborder"} style={{height:'auto'}} onClick={this.toggleCheck}  htmlFor={this.props.id}>
              {title ? title : this.props.name}
              {subtitle && (
                <>
                  <br/>
                  <span>{subtitle}</span>
                </>
              )}
            </label>
        </div>
      )
  }
}

export default CustomCheckBox;
