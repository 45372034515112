import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

const $ = window.$;


class RecentActivitiesDemo extends React.Component {

  constructor(props, context){
      super(props, context);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState){
  }

render(){
  
    let {textVariants, account, enquire, advers} = this.props;
    return (

      <span>
        {account && (<div class="summary-box">
          <div class="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" class="img-fluid"/>
            <h2>{account['account-open-date'] ? account['account-open-date']['__text'] : '' }</h2>
          </div>
          <div class="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{account['original-credit-provider']  ? account['original-credit-provider'].name.__text : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{account['account-type']['__text']}</dd>
            </dl>
          </div>
        </div>)}

        {enquire && (<div class="summary-box">
          <div class="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" class="img-fluid"/>
            <h2>{ enquire['_enquiry-date'] ? enquire['_enquiry-date'] : '' }</h2>
          </div>
          <div class="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{enquire['credit-enquirer']  ? enquire['credit-enquirer']['name']['__text'] : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{enquire['account-type']['__text']}</dd>
            </dl>
          </div>
        </div>)}

        {advers && (<div class="summary-box">
          <div class="title">
            <img src={ImagesPath.ImgIconCalendar} alt="" class="img-fluid"/>
            <h2>{advers['action-date'] ? advers['action-date']['__text'] : '' }</h2>
          </div>
          <div class="summary-list">
            <dl>
              <dt>Credit</dt>
              <dd>{advers['creditor']  ? advers['creditor']['__text'] : ''} </dd>
            </dl>
            <dl>
              <dt>Type</dt>
              <dd>{advers['court-type']['__text']}</dd>
            </dl>
          </div>
        </div>)}

        


      </span>
        );
      }
 }

 RecentActivitiesDemo.propTypes = {
    textVariants: PropTypes.object.isRequired,
};


export default RecentActivitiesDemo;
