class HelperApi {

  static setDateIntoCustomerFromIsoString(customer, isoString) {

          //isoString is 1981-09-10T00:00:00.
          if(isoString){
            customer.dateOfBirth = new Date(isoString);
            customer.dob = {
              yy: isoString.substr(0,4),
              mm: isoString.substr(5,2),
              dd: isoString.substr(8,2)
            };
          }
  }

  static setInnerDateOfBirthAndDobFromNumbers(customer, year, month, day) {

    var yStr = '' + year;
    var mStr = '' + month;
    var dStr = '' + day;

    if(mStr.length == 1){
      mStr = '0' + mStr;
    }

    if(dStr.length == 1){
      dStr = '0' + dStr;
    }

    customer.dateOfBirth = new Date('' + yStr + '-' + mStr + '-' + dStr + 'T00:00:00Z');
    customer.dob = {
      yy: yStr,
      mm: mStr,
      dd: dStr
    };
  }

  static getInnerDateOfBirthAndDobFromNumbers(year, month, day) {

    var yStr = '' + year;
    var mStr = '' + month;
    var dStr = '' + day;

    if(mStr.length == 1){
      mStr = '0' + mStr;
    }

    if(dStr.length == 1){
      dStr = '0' + dStr;
    }

    return {
      dateTypeObj: new Date('' + yStr + '-' + mStr + '-' + dStr + 'T00:00:00Z'),
      datePartsObj : {
        yy: yStr,
        mm: mStr,
        dd: dStr
      }
    };
  }

  static encodeData(data) {
      return Object.keys(data).map(function(key) {
          return [key, data[key]].map(encodeURIComponent).join("=");
      }).join("&");
  }
  
  static toCurrencyFormat(value, decimalPoints = 0, currencySymbols = "$") {
    value = Number(value);
    value = (!isNaN(value) && isFinite(value)) 
          ? value.toFixed(decimalPoints).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0";
    
    return currencySymbols + value;
  }

  static toThousandSeparatedFormat(value) {
    const intValue = parseInt(value, 10);
    return (!isNaN(intValue) && isFinite(intValue))
      ? intValue.toLocaleString("en-AU")
      : "";
  }

  static removeCommaSeparators(value) {
    if (value)
      value = value.replace(/,/g, "");

    return value;
  }

}

export default HelperApi;
