

export const imgRoot = process.env.REACT_APP_CDN_URL.substr(0, process.env.REACT_APP_CDN_URL.length - 1);

export const blueSectionImgUrl = imgRoot + '/images/activateaccount/computer-nominute.png';
export const ImgComputerShadow =  imgRoot + "/images/home/thumb-laptop-free-credit-score.png";

export const ImgPromoGiveAwayPricesWithText =  imgRoot + "/images/promo/textbot.png";
export const ImgPromoGiveAwayPricesWithTextTopMobile =  imgRoot + "/images/promo/texttopmobile.png";

export const ImgNoAccountWarning =  imgRoot + "/images/home/warningohno.png";

export const ImgLoadingSpinner1 = imgRoot + "/images/gcs/loading.gif";

export const ImgLogoGcsBlue =  imgRoot + "/images/logo/GCS-Logo-blue.png";
export const ImgLogoGcsWhite =  imgRoot + "/images/logo/GCS-Logo-white.png";
export const ImgLogoGcsBlack = imgRoot +  "/images/logo/GCS-Logo-black.png";
export const ImgLogoGcsDialSmall =  imgRoot + "/images/logo/Gcs_dial_small.png";
export const ImgLogoGcsBlueXmas = imgRoot + "/images/logo/GCS-Logo-blue-xmas.png";
export const ImgLogoGcsWhiteXmas = imgRoot + "/images/logo/GCS-Logo-white-xmas.png";
export const ImgLogoGcsHalloween = imgRoot + "/images/logo/Logo_GCS_Halloween.png";
export const ImgLogoToyota =  imgRoot + "/images/logo/toyota.png";
export const ImgLogoBudgetDirect =  imgRoot + "/images/logo/budget-direct-logo.svg";
export const ImgLogoJoust =  imgRoot + "/images/logo/joust-logo.png";
export const ImgLogoUno =  imgRoot + "/images/logo/uno-logo.svg";
export const ImgLogoUnoWin =  imgRoot + "/images/logo/uno-logo-win.png";
export const ImgLogoSocietyOne =  imgRoot + "/images/logo/soc1_logo.png";
export const ImgLogoToyotaFinance =  imgRoot + "/images/logo/toyota_finance_logo.svg";
export const ImgLogoToyotaFinance2 =  imgRoot + "/images/logo/toyota-logo-2.png";
export const ImgLogoPowerTorque =  imgRoot + "/images/logo/power-torque-logo.png";
export const ImgLogoFirstMac =  imgRoot + "/images/logo/loans-logo-image.png";
export const ImgLogoFirstMacHLA =  imgRoot + "/images/partner/hla_loans_dot_com.jpg";



// articles
export const ImgArticles_5waystoimproveyourcreditscore = imgRoot + "/images/articles/5-ways-to-improve-your-credit-score.jpg";
export const ImgArticles_mythsvsfacts = imgRoot + "/images/articles/myths-vs-facts.png";
export const ImgArticles_infographics = imgRoot + "/images/articles/cs_infographic.png";
export const ImgArticles_whenbuyinganewphone = imgRoot + "/images/articles/when-buying-a-new-phone.jpg";
export const ImgArticles_comprehensivecreditreporting = imgRoot + "/images/articles/comprehensive-credit-reporting1.jpg";
export const ImgArticles_areyoudatingacreditdude = imgRoot + "/images/articles/are-you-dating-a-credit-dud.jpg";
export const ImgArticles_generationbuy = imgRoot + "/images/articles/generation-buy.jpg";
export const ImgArticles_bonditobolivia = imgRoot + "/images/articles/from-bondi-to-bolivia.jpg";
export const ImgArticles_partnersbehaviour = imgRoot + "/images/articles/could-your-partners-fin-behaviour.jpg";
export const ImgArticles_discoveryourcreditscore = imgRoot + "/images/articles/credit-score.jpg";
export const ImgArticles_understandyourcreditscore = imgRoot + "/images/articles/understanding-your-credit-score.jpg";


export const ImgNextSteps_seewhatscontributingtoscore = imgRoot + "/images/nextsteps/see-whats-contributing-to-score.jpg";
export const ImgNextSteps_getbetterdealarms = imgRoot + "/images/nextsteps/get_better_deal-arms.jpg";
export const ImgNextSteps_requestcreditreport = imgRoot + "/images/nextsteps/request-credit-report.jpg";
export const ImgNextSteps_seehowyourscoreiscalculated = imgRoot + "/images/nextsteps/see-how-your-score-calculated.jpg";
export const ImgNextSteps_consolidateyourdebt = imgRoot + "/images/nextsteps/consolidate-your-debt.jpg";
export const ImgNextSteps_improveyourcreditscore = imgRoot + "/images/nextsteps/improve-your-credit-score.jpg";
export const ImgNextSteps_faqs = imgRoot + "/images/nextsteps/faqs.jpg";
export const ImgNextSteps_disputecreditreport = imgRoot + "/images/nextsteps/dispute-your-credit-report.jpg";
export const ImgNextSteps_protectyourscore = imgRoot + "/images/nextsteps/protect-your-score-identity.jpg";
export const ImgNextSteps_carinsurance = imgRoot + "/images/nextsteps/car_insurance.jpg";
export const ImgNextSteps_homeinsurance = imgRoot + "/images/nextsteps/home_insurance.jpg";
export const ImgNextSteps_creditcard = imgRoot + "/images/nextsteps/credit_card.jpg";
export const ImgNextSteps_homeloanuno = imgRoot + "/images/nextsteps/home_loan_uno.jpg";
export const ImgNextSteps_lifeinsurance = imgRoot + "/images/nextsteps/life_insurance.jpg";
export const ImgNextSteps_homeloanuno_1 = imgRoot + "/images/nextsteps/home_loan_uno_1.jpg";
export const ImgNextSteps_homeloanuno_2 = imgRoot + "/images/nextsteps/home_loan_uno_2.jpg";
export const ImgNextSteps_pepper = imgRoot + "/images/nextsteps/pepper_nextsteps.jpg";
export const ImgNextSteps_homeloanjoust = imgRoot + "/images/nextsteps/home_loan_joust.jpg";
export const ImgNextSteps_homeloanGeneric = imgRoot + "/images/nextsteps/hl_generic.jpg";
export const ImgNextSteps_homeloanUbank = imgRoot + "/images/nextsteps/home_loan_ubank_ns.png";
export const ImgNextSteps_homeloanUnoLoanScoreA = imgRoot + "/images/nextsteps/uno_loanScore_tile_292x195a.png";
export const ImgNextSteps_homeloanUnoLoanScoreB = imgRoot + "/images/nextsteps/uno_loanScore_tile_292x195b.png";
export const ImgNextSteps_homeloanUnoLoanScoreQ3 = imgRoot + "/images/nextsteps/uno_gcs_q3_19_offer292x195.png";
export const ImgNextSteps_macCredit_1 = imgRoot + "/images/nextsteps/maccredit_1.png";
export const ImgNextSteps_nowFinance_1 = imgRoot + "/images/nextsteps/nowfinance_1.png";

export const ImgNextSteps_ratesetter_hig = imgRoot + "/images/nextsteps/ratesetter_high.png";
export const ImgNextSteps_ratesetter_med = imgRoot + "/images/nextsteps/ratesetter_med.png";
export const ImgNextSteps_ratesetter_low = imgRoot + "/images/nextsteps/ratesetter_low.png";

export const ImgNextSteps_ondeck_ns_1 = imgRoot + "/images/nextsteps/ondeck_ns_1.png";
export const ImgNextSteps_gofinance_ns_1 = imgRoot + "/images/nextsteps/gofinance_ns_1.jpg";


export const ImgFeatures_protect = imgRoot + "/images/features/Protect.png";
export const ImgFeatures_free = imgRoot + "/images/features/Free.png";
export const ImgFeatures_save = imgRoot + "/images/features/Save.png";
export const ImgFeatures_quick = imgRoot + "/images/features/Quick.png";
export const ImgFeatures_safe = imgRoot + "/images/features/Safe.png";
export const ImgFeatures_detailed = imgRoot + "/images/features/Detailed.png";



export const ImgDashboard_score = imgRoot + "/images/dashboard/Score.png";
export const ImgDashboard_history = imgRoot + "/images/dashboard/History.png";
export const ImgDashboard_compare = imgRoot + "/images/dashboard/Compare.png";



export const ImgProduct_InsuranceCar = imgRoot + "/images/products/insurance/svg/car-insurance.svg";
export const ImgProduct_InsuranceHome = imgRoot + "/images/products/insurance/svg/home-insurance.svg";
export const ImgProduct_InsuranceLife = imgRoot + "/images/products/insurance/svg/life-insurance.svg";
export const ImgProduct_InsuranceIncome = imgRoot + "/images/products/insurance/svg/income-insurance.svg";

export const ImgProduct_InsuranceBag = imgRoot + "/images/products/insurance-health.png";



export const ImgProduct_CC = imgRoot + "/images/products/Credit-Card.png";
export const ImgProduct_CC_Sales = imgRoot + "/images/products/cc_4.png";

export const ImgProduct_PersonalLoan = imgRoot + "/images/products/Personal-Loan.png";
export const ImgProduct_PersonalLoan_Sales = imgRoot + "/images/products/personal_loan_2.png";

export const ImgProduct_HomeLoan = imgRoot + "/images/products/Home-Loan.png";
export const ImgProduct_HomeLoan_Sales = imgRoot + "/images/products/home_loan_3.png";
export const ImgProduct_HomeLoan_Wizard = imgRoot + "/images/logo/ratecity-logo.svg";

export const ImgProduct_AutoLoan = imgRoot + "/images/products/Auto-Loan.png";
export const ImgProduct_AutoLoan_Sales = imgRoot + "/images/products/car_loan_2.png";

export const ImgProduct_CarSubscription = imgRoot + "/images/products/Auto-Loan.png";
export const ImgProduct_CarSubscription_Sales = imgRoot + "/images/products/car_subscription_1.png";

export const ImgProduct_Utilities = imgRoot + "/images/products/Home-Loan.png";
export const ImgProduct_Utilities_Sales = imgRoot + "/images/products/utilities_1.png";

export const ImgProduct_Insurance = imgRoot + "/images/products/insurance.png";
export const ImgProduct_Insurance_Sales = imgRoot + "/images/products/insurance_3.png";

export const ImgProduct_BusinessLoan = imgRoot + "/images/products/Business-Loan.png";
export const ImgProduct_BusinessLoan_Sales = imgRoot + "/images/products/business_loan_3.png";


export const ImgProduct_CC_Offer_Anz =   imgRoot + "/images/products/credit-card-offers/cc-anz-low-rate-platinum.png";
export const ImgProduct_CC_Offer_Citi =   imgRoot + "/images/products/credit-card-offers/cc-citi-rewards-platinum.png";
export const ImgProduct_CC_Offer_Hsbc1 =   imgRoot + "/images/products/credit-card-offers/cc-hsbc-1.png";
export const ImgProduct_CC_Offer_Virgin =   imgRoot + "/images/products/credit-card-offers/cc-virgin-2.png";

export const ImgInput_GenderMale =   imgRoot + "/images/registration/male.png";
export const ImgInput_GenderFemale =   imgRoot + "/images/registration/male.png";

export const ImgWizard_Like =  imgRoot + '/images/about-me/like.png';
export const ImgWizard_HomeLoan_Signed =   imgRoot + "/images/wizard/signed.png";
export const ImgWizard_HomeLoan_ThumbUp =   imgRoot + "/images/wizard/thumbup.png";
export const ImgWizard_HomeLoan_Magnifier =   imgRoot + "/images/wizard/magnifier.png";
export const ImgWizard_HomeLoan_Info =   imgRoot + "/images/wizard/info.png";


export const ImgWizard_CreditCardPage_Banner =   imgRoot + "/images/credit-card-offers/credit-card.png";
export const ImgWizard_CreditCard_Banner =   imgRoot + "/images/credit-cards/credit-card.png";



export const ImgHome_Faq = imgRoot + "/images/home/home_faq.png";


export const ImgFeatured_Sunrise = imgRoot + "/images/featured/Sunrise.png";
export const ImgFeatured_News = imgRoot + "/images/featured/news.png";
export const ImgFeatured_Channel = imgRoot + "/images/featured/Channel-7.png";
export const ImgFeatured_Financial = imgRoot + "/images/featured/Financial-Review.png";
export const ImgFeatured_CRates = imgRoot + "/images/featured/cr_logo.png";
export const ImgFeatured_BadCredit = imgRoot + "/images/featured/bc-logo.png";



export const ImgActivateAccount_Computer = imgRoot + "/images/activateaccount/computer.png";

export const ImgEmailSent_NewEmail = imgRoot + "/images/email-sent-blue/new-email.jpg";

export const ImgRegistration_IconSafe = imgRoot + "/images/registration/icon-safe.png";
export const ImgRegistration_IconDiamond = imgRoot + "/images/registration/icon-diamond.png";
export const ImgRegistration_IconClock = imgRoot + "/images/registration/icon-clock.png";


export const ImgPromotion_Peazie_Prizes = imgRoot + "/images/promo/prizes.png";
export const ImgPromotion_Peazie_Headline = imgRoot + "/images/promo/headline.png";
export const ImgPromotion_Peazie_Texttop = imgRoot + "/images/promo/texttop.png";


export const ImgDashboard_NoScore_Person = imgRoot + '/images/dashboard/noscore/person.png';
export const ImgDashboard_NoScore_Paper = imgRoot + '/images/dashboard/noscore/paper.png';
export const ImgDashboard_NoScore_Phone = imgRoot + '/images/dashboard/noscore/phone.png';
export const ImgDashboard_NoScore_Card = imgRoot + '/images/dashboard/noscore/card.png';


export const ImgGcs_Preloader = imgRoot + '/images/gcs/preloader.gif';


export const ImgDashboard_Dial_Blank = imgRoot + '/images/dial/Dial-blank.png';

export const ImgScoreMapRoot = imgRoot + '/images/score-map/';
export const ImgScoreMap_GenerationY = imgRoot + '/images/score-map/avatar-18+round.png';
export const ImgScoreMap_GenerationX = imgRoot + '/images/score-map/avatar-35+round.png';
export const ImgScoreMap_GenerationBoomer = imgRoot + '/images/score-map/avatar-45+round.png';
export const ImgScoreMap_GenerationBuilder = imgRoot + '/images/score-map/avatar-65+round.png';
export const ImgScoreMap_IconEmail = imgRoot + '/images/score-map/icon-email.png';
export const ImgScoreMap_IconFB = imgRoot + '/images/score-map/icon-fb.png';
export const ImgScoreMap_IconG = imgRoot + '/images/score-map/icon-g+.png';
export const ImgScoreMap_IconTwitter = imgRoot + '/images/score-map/icon-twitter.png';
export const ImgScoreMap_Avatar = imgRoot + '/images/score-map/avatars-grouped.png';

// New Images
export const ImgLogo = imgRoot + '/images/new/logo/logo.png';
export const ImgLogoBlack = imgRoot + '/images/new/logo/logo-black.svg';
export const ImgLogoWhite = imgRoot + '/images/new/logo/logo-white.svg';


export const ImgIconMobile = imgRoot + '/images/new/icons/hamburger.svg';
export const ImgIconMobileWhite = imgRoot + '/images/new/icons/hamburger-white.svg';
export const ImgIconSee = imgRoot + '/images/new/icons/see.svg';


export const ImgPictureHome = imgRoot + '/images/new/picture/home-1.png';
export const ImgPictureHome_2x = imgRoot + '/images/new/picture/home-1@2x.png';
export const ImgPictureHome1 = imgRoot + '/images/new/picture/home-1.png';
export const ImgPictureHome2 = imgRoot + '/images/new/picture/home-2.png';
export const ImgPictureHome2_2x = imgRoot + '/images/new/picture/home-2@2x.png';
export const ImgPictureHome3 = imgRoot + '/images/new/picture/home-3.png';
export const ImgPictureHome4 = imgRoot + '/images/new/picture/home-4.png';
export const ImgPictureHome4_2x = imgRoot + '/images/new/picture/home-4@2x.png';
export const ImgPictureHome5_2x = imgRoot + '/images/new/picture/home-5@2x.png';

export const ImgIcon1a = imgRoot + '/images/new/icons/1a.svg';
export const ImgIcon2a = imgRoot + '/images/new/icons/2a.svg';
export const ImgIcon3a = imgRoot + '/images/new/icons/3a.svg';
export const ImgIcon4a = imgRoot + '/images/new/icons/4a.svg';

export const ImgLogoSeven2 = imgRoot + '/images/new/logo/seven2.png';
export const ImgLogoNews = imgRoot + '/images/new/logo/news.png';
export const ImgLogoCredrates = imgRoot + '/images/new/logo/credrates.png';
export const ImgLogoFinancial = imgRoot + '/images/new/logo/financial.png';
export const ImgLogoSunrise = imgRoot + '/images/new/logo/sunrise.png';
export const ImgLogoBadcredit2 = imgRoot + '/images/new/logo/badcredit2.png';

export const ImgIconSvgHome1 = imgRoot + '/images/new/icons/home-1.svg';
export const ImgIconSvgHome9 = imgRoot + '/images/new/icons/home-9.svg';
export const ImgIconSvgHome3 = imgRoot + '/images/new/icons/home-3.svg';
export const ImgIconSvgHome4 = imgRoot + '/images/new/icons/home-4.svg';
export const ImgIconSvgHome8 = imgRoot + '/images/new/icons/home-8.svg';
export const ImgIconSvgHome6 = imgRoot + '/images/new/icons/home-6.svg';
export const ImgIconSvgHome7 = imgRoot + '/images/new/icons/home-7.svg';
export const ImgIconSvgArrowLeftBlue = imgRoot + '/images/new/icons/arrow-left-blue.svg';

export const ImgLogoTrustPilot = imgRoot + '/images/new/logo/trustpilot.png';
export const ImgIconSvgTrustPilotStar = imgRoot + '/images/new/icons/trustpilot-star.svg';

export const ImgIconCreditCards = imgRoot + '/images/new/icons/credit-cards.png';
export const ImgIconPersonal = imgRoot + '/images/new/icons/personal.svg';
export const ImgIconHome = imgRoot + '/images/new/icons/home.svg';
export const ImgIconCar = imgRoot + '/images/new/icons/car.svg';
export const ImgIconInsurance = imgRoot + '/images/new/icons/insurance.png';
export const ImgIconBusiness = imgRoot + '/images/new/icons/business.svg';


export const ImgIconArrowLeftBlue = imgRoot + '/images/new/icons/arrow-left-blue.svg';
export const ImgPictureBlog6 = imgRoot + '/images/new/picture/blog-6.png';
export const ImgPictureBlog7 = imgRoot + '/images/new/picture/blog-7.png';
export const ImgPictureBlog8 = imgRoot + '/images/new/picture/blog-8.png';

export const ImgIconHistory = imgRoot + '/images/new/icons/history.svg';
export const ImgIconTooltip = imgRoot + '/images/new/icons/tooltip.svg';

export const ImgIconEnergy = imgRoot + '/images/new/icons/energy-icon.png';
export const ImgIcontech = imgRoot + '/images/new/icons/tech-icon.svg';
export const ImgIconOther = imgRoot + '/images/new/icons/other-icon.png';
export const ImgIconReduce = imgRoot + '/images/new/icons/reduce.svg';

export const ImgPictureReduce = imgRoot + '/images/new/picture/reduce.png';

export const ImgIconBell = imgRoot + '/images/new/icons/bell.svg';
export const ImgIconBellBlue = imgRoot + '/images/new/icons/bell-blue.svg';

export const ImgIconInfoBlue = imgRoot + '/images/new/icons/info-blue.svg';

export const ImgIconCloseBlack = imgRoot + '/images/new/icons/close-black.svg';

export const ImgIconInfo = imgRoot + '/images/new/icons/info.svg';

export const ImgIconTrash = imgRoot + '/images/new/icons/trash.svg';
export const ImgIconTrash2 = imgRoot + '/images/new/icons/trash2.svg';

export const ImgIconEye = imgRoot + '/images/new/icons/eye.svg';

export const ImgIconCloseBlue = imgRoot + '/images/new/icons/close-blue.svg';

export const Img2FAMobile =   imgRoot + "/images/new/icons/mobile.svg";

export const ImgWizard_AboutMe_Single =   imgRoot + "/images/new/icons/single@2x.png";
export const ImgWizard_AboutMe_SingleParent =   imgRoot + "/images/new/icons/parent@2x.png";
export const ImgWizard_AboutMe_CoupleNoKid =   imgRoot + "/images/new/icons/couple-nokids@2x.png";
export const ImgWizard_AboutMe_CoupleKids =   imgRoot + "/images/new/icons/couple-kids@2x.png";
export const ImgWizard_AboutMe_EmptyNester =   imgRoot + "/images/new/icons/empty@2x.png";

export const ImgIconSubmission = imgRoot + '/images/new/icons/submission.svg';

export const ImgIconPrev = imgRoot + '/images/new/icons/prev.svg';

export const ImgIconCalendar = imgRoot + '/images/new/icons/calendar.svg';

export const ImgIconScoreGauge = imgRoot + '/images/new/icons/help-get.svg'

export const ImgIconOk = imgRoot + '/images/new/icons/ok.png';
export const ImgIconQuestion = imgRoot + '/images/new/icons/question.png';
export const ImgIconAlert2 = imgRoot + '/images/new/icons/alert2.png';
export const ImgIconAlert = imgRoot + '/images/new/icons/alert.svg';

// New icons for Acrds 3.0
export const ImgIconOkNew = imgRoot + '/images/new/icons/circle-check-solid.png';
export const ImgIconQuestionNew = imgRoot + '/images/new/icons/circle-question-solid.png';
export const ImgIconAlertNew = imgRoot + '/images/new/icons/triangle-exclamation-solid.png';
export const ImgIconFHIA = imgRoot + '/images/new/icons/icon-FHI-A.png';
export const ImgIconFHIV = imgRoot + '/images/new/icons/icon-FHI-V.png';

//export const ImgPictureMedicare1 = imgRoot + '/images/new/picture/medicare1.png';
export const ImgIconPreferences = imgRoot + '/images/new/icons/preferences.svg';

export const ImgIconDl = imgRoot + '/images/new/icons/dl.svg';
export const ImgIconPassport = imgRoot + '/images/new/icons/passport.svg';
export const ImgIconMedicare = imgRoot + '/images/new/icons/medicare.svg';

export const ImgIconHelpInfo2x = imgRoot + '/images/new/icons/help-info@2x.png';

export const ImgIconEmail = imgRoot +  '/images/new/icons/email.svg';
export const ImgIconEmail2FA = imgRoot +  '/images/new/icons/email2FA.svg';


export const ImgDvs_M_B = imgRoot + '/images/new/identity/medicare/m_blue.png';
export const ImgDvs_M_G = imgRoot + '/images/new/identity/medicare/m_green.png';
export const ImgDvs_M_Y = imgRoot + '/images/new/identity/medicare/m_yellow.png';

//commented some paths due to DVS change new images
export const ImgDvs_DL_AAT = imgRoot + '/images/new/identity/dl/dl_AAT.jpg';
// export const ImgDvs_DL_ACT = imgRoot + '/images/new/identity/dl/dl_ACT.jpg';
// export const ImgDvs_DL_NSW =  imgRoot + '/images/new/identity/dl/dl_NSW.jpg';
// export const ImgDvs_DL_NT = imgRoot + '/images/new/identity/dl/dl_NT.jpg';
export const ImgDvs_DL_NZ = imgRoot + '/images/new/identity/dl/dl_NZ.jpg';
export const ImgDvs_DL_OS = imgRoot + '/images/new/identity/dl/dl_OS.jpg';
// export const ImgDvs_DL_QLD = imgRoot + '/images/new/identity/dl/dl_QLD.jpg';
// export const ImgDvs_DL_SA = imgRoot + '/images/new/identity/dl/dl_SA.jpg';
// export const ImgDvs_DL_TAS = imgRoot + '/images/new/identity/dl/dl_TAS.jpg';
// export const ImgDvs_DL_VIC = imgRoot + '/images/new/identity/dl/dl_VIC.jpg';
// export const ImgDvs_DL_WA = imgRoot + '/images/new/identity/dl/dl_WA.jpg';

//new images for DVS change
export const ImgDvs_DL_ACT = imgRoot + '/images/new/identity/dlnew/act_new.jpg';
export const ImgDvs_DL_NSW =  imgRoot + '/images/new/identity/dlnew/nsw_new.jpg';
export const ImgDvs_DL_NT = imgRoot + '/images/new/identity/dlnew/nt_new.jpg';
export const ImgDvs_DL_QLD = imgRoot + '/images/new/identity/dlnew/qld_new.jpg';
export const ImgDvs_DL_SA = imgRoot + '/images/new/identity/dlnew/sa_new.jpg';
export const ImgDvs_DL_TAS = imgRoot + '/images/new/identity/dlnew/tas_new.jpg';
//export const ImgDvs_DL_VIC = imgRoot + '/images/new/identity/dlnew/vic_new.jpg';
export const ImgDvs_DL_VIC = imgRoot + '/images/new/identity/dlnew/vic1_new.jpg';
export const ImgDvs_DL_WA = imgRoot + '/images/new/identity/dlnew/wa_new.jpg';


export const ImgKcfProgressBar_1 = imgRoot + '/images/picture/kcf-progress-1.png';
export const ImgKcfProgressBar_2 = imgRoot + '/images/picture/kcf-progress-2.png';
export const ImgKcfProgressBar_3 = imgRoot + '/images/picture/kcf-progress-3.png';
export const ImgKcfProgressBar_4 = imgRoot + '/images/picture/kcf-progress-4.png';
export const ImgKcfProgressBar_5 = imgRoot + '/images/picture/kcf-progress-5.png';
export const ImgKcfProgressBar_6 = imgRoot + '/images/picture/kcf-progress-6.png';

// export const ImgPictureBlue =  '/images/picture/blue.png';
// export const ImgPictureGreen =  '/images/picture/green.png';
// export const ImgPictureYellow =  '/images/picture/yellow.png';

// export const ImgPictureACT =  '/images/picture/ACT.jpg';
// export const ImgPictureNSW =  '/images/picture/NSW.jpg';
// export const ImgPictureNT =  '/images/picture/NT.jpg';
// export const ImgPictureQLD =  '/images/picture/QLD.jpg';
// export const ImgPictureSA =  '/images/picture/SA.jpg';
// export const ImgPictureTAS =  '/images/picture/TAS.jpg';
// export const ImgPictureVIC =  '/images/picture/VIC.jpg';
// export const ImgPictureWA =  '/images/picture/WA.jpg';




