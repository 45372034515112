import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Provider } from 'react-redux'
import Menu from './Menu'

import * as layoutActions from '../../redux/actions/layoutActions';

export class MenuCms  extends React.Component{   

    componentDidMount(){
        this.props.actions.getMenuForPage(this.props.pageName);
    }

    render(){
        return (
          <Provider store={this.props.store}>
            <div className="top-menu-background-color">
                  <Menu menuItemList={this.props.menuItemList} useJustAnchors={true}  />
            </div>
          </Provider>
        )
        }
};

MenuCms.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  let pageName = ownProps.pageName;

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pageName]){
        menuItems = menu.menuItemsPerPage.menuItems[pageName];
     }
   }

  return {
    menuItemList: menuItems,
    pageName: pageName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuCms);
