import $ from 'jquery'
import PerfectScrollbar from 'perfect-scrollbar'
import moment from 'moment'
import Chart from 'chart.js'


export const initTonikHistory = () => {


// PerfectScrollbar

if ($('.status-scale-wrapper').length > 0) { // history
  const ps = new PerfectScrollbar('.status-scale-wrapper')
}
// PrefectScrollbar end

// See plugin documentation: https://www.chartjs.org/docs/latest/
var timeFormat = 'MM/DD/YYYY HH:mm'

initChart(0, 365)
initChart(1, 30)
initChart(3, 90)

// function parameters:
// index - number of chart ID in HTML
// period - number of days which chart should show stats, ex. 365 = 1 year, 90 = 3 month, 30 = 1 month etc.
function initChart (index, period) {
  var elementChart = document.getElementById('chartjs-' + index)
  if (typeof (elementChart) != 'undefined' && elementChart != null) {
    // gradient settings
    let chart = elementChart.getContext('2d')
    let gradient = chart.createLinearGradient(0, 0, 0, 450)
    gradient.addColorStop(0, 'rgba(44,137,213,1)')
    gradient.addColorStop(0.5, 'rgba(35,100,204,0.1)')
    gradient.addColorStop(1, 'rgba(35,73,193,0.1)')

    // data settings
    var dataPoints = []
    // var dataLabels = []
    var limit = period
    var y = period

    for (var i = 0; i < limit; i++) {
      y += Math.round(10 + Math.random() * (-10 - 10))
      // var day = newDate(i + 1)
      var dayString = newDateString(i + 1)
      // dataLabels.push({ day })
      dataPoints.push({ y: y, x: dayString })
    }

    var myChart = new Chart(elementChart, {
      'type': 'line',
      'data': {
        // 'labels': dataLabels,
        'datasets': [{
          'label': '',
          'data': dataPoints,
          'fill': 'start',
          'borderColor': 'rgb(31,205,232)',
          'backgroundColor': gradient,
          'pointRadius': 0,
          'lineTension': 0.1
        }]
      },
      'options': {
        'responsive': true,
        'legend': {
          'display': false
        },
        'tooltips': {
          'position': 'average',
          'backgroundColor': 'transparent',
          'titleFontSize': 12,
          'titleFontFamily': 'Poppins',
          'titleFontStyle': 'normal',
          'bodyFontSize': 20,
          'bodyFontFamily': 'Poppins',
          'bodyFontStyle': 'bold',
          'displayColors': false
        },
        'scales': {
          'xAxes': [{
            'type': 'time',
            'time': {
              'tooltipFormat': 'll'
            },
            'ticks': {
              'display': false
            },
            'gridLines': {
              'display': false
            }
          }],
          'yAxes': [{
            'gridLines': {
              'color': 'rgba(35,123,214,1)',
              'display': true
            },
            'ticks': {
              'fontColor': 'rgba(255,255,255,0.6)',
              'stepSize': 50
            }
          }]
        }
      }
    })
  }
}

// Generate next day date
function newDate (days) {
  return moment([2017, 0, 1]).add(days, 'd').toDate()
}

function newDateString (days) {
  return moment([2017, 0, 1]).add(days, 'd').format(timeFormat)
}


return;
}
