import React from 'react';
import PropTypes from 'prop-types';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

class HomeProcessSectionItem extends React.Component {

  createMarkup(txt) {
      return {__html: txt};
  }

  render() {
    var item = this.props.item;
    var wrapClass = item.hideOnMobile ? "hidden-xs" : "";

    return (
      <li className={wrapClass}>
        <h5 dangerouslySetInnerHTML={this.createMarkup(item.title)}></h5>
        <p dangerouslySetInnerHTML={this.createMarkup(item.text)} ></p>
      </li>
    );
  }
}

HomeProcessSectionItem.propTypes = {
  item: PropTypes.object.isRequired
};


export const HomeProcessSection = ({steps, onGetScoreClick}) => {

        return (

    //   <section className="getscore-area ">
    //     <div className="xl-pt-40 lg-pt-40 md-pt-30 xl-pb-50 lg-pb-50 md-pb-30 xs-pt-20 xs-pb-20">
    //     <span id="how-it-works-section">
    //        <span className="anchorForFixedMenu hidden-xs" ></span>
    //     </span>

  	// 		<div className="container-fluid left-container-padding right-container-padding">
  	// 			<div className="row">
    //                     <div className="col-xs-12 col-sm-7">
    //                       <div className="features-text">

    //                             <ul className="clearfix">
    //                                {
    //                                    steps.steps.map( item => {
    //                                        return(<HomeProcessSectionItem key={item.key} item={item} />)
    //                                    })
    //                                }
    //                             </ul>
    //                       </div>
    //                     </div>


    //                     <div className="col-xs-12 col-sm-5 text-center">
    //                       <div className="getscore-img" onClick={onGetScoreClick}>
    //             							<img src={ImagesPath.ImgComputerShadow} alt="Get Score" className="img-responsive img-resp-center" />
    //             						</div>
    // 					          </div>

  	// 			</div>         
    //       </div>
  	// 		</div>
    //     <div className="terms left-container-padding right-container-padding">
    //            <span dangerouslySetInnerHTML={funcs.createMarkup(steps.disclaimer)}></span>
    //         </div>
    // </section>
    
<section className="section-how-work">
  <div className="container">
    <div className="how-work-box d-lg-block d-none">
      <div className="row">
        <div className="col-xl-6 offset-xl-1 col-lg-6 pic-work">
          <img className="img-fluid pic-active" id="collapse1_img" src={ImagesPath.ImgPictureHome1} alt=""/>
          <img className="img-fluid" id="collapse2_img" src={ImagesPath.ImgPictureHome2} alt=""/>
          <img className="img-fluid" id="collapse3_img" src={ImagesPath.ImgPictureHome3} alt=""/>
          <img className="img-fluid" id="collapse4_img" src={ImagesPath.ImgPictureHome4} alt=""/>

          <img className="img-fluid pic-active number-img" id="collapse1_number" src={ImagesPath.ImgIcon1a} alt=""/>
          <img className="img-fluid number-img" id="collapse2_number" src={ImagesPath.ImgIcon2a} alt=""/>
          <img className="img-fluid number-img" id="collapse3_number" src={ImagesPath.ImgIcon3a} alt=""/>
          <img className="img-fluid number-img" id="collapse4_number" src={ImagesPath.ImgIcon4a} alt=""/>
        </div>
        <div className="col-xl-5 col-lg-6 column-accordion">
          <h2>How it works?</h2>
          <div className="accordion" id="accordionWork">
            <div className="card card-active">
                <div className="card-header" id="heading1">
                  <h5 className="mb-0">
                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      Create an account 
                    </button>
                  </h5>
                </div>

                <div id="collapse1" className="collapse show" aria-labelledby="heading1" data-parent="#accordionWork">
                  <div className="card-body">
                    <p>Create a free membership in as litte as 60 seconds to see and start monitoring your credit health</p>
                  </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="heading2">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                    Verify your ID
                    </button>
                  </h5>
                </div>

                <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordionWork">
                  <div className="card-body">
                  <p>A verification message will be sent to your e-mail address and after confirmation you will get access to all functions of the portal.</p>
                  </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="heading3">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                    Access great offers
                    </button>
                  </h5>
                </div>

                <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordionWork">
                  <div className="card-body">                    
                    <p>See personalised and exclusive offers from some of the country's leading banks, leaders, insurers and more</p>
                  </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="heading4">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      Monitor Your Credit Score
                    </button>
                  </h5>
                </div>

                <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordionWork">
                  <div className="card-body">
                    <p>Check in monthly to see how your score changes and offers can be unlocked as it improves</p>
                  </div>
                </div>
            </div>
          </div>
          <a href={RoutesVariables.RouteLearnMore} className="btn btn-primary full-width">Learn more</a>
        </div>
      </div>
    </div>
    <div className="how-work-box d-lg-none d-md-block">
      <h2>How it works?</h2>
      <div className="how-work-slider">
        <div className="how-work-item">
          <div className="picture">
            <img className="img-fluid" src={ImagesPath.ImgPictureHome3} alt=""/>
            <span className="first"><img className="img-fluid" src={ImagesPath.ImgIcon1a} alt=""/></span>
          </div>
          <h6>Create an account using the registration tab</h6>
          <p>Create a free membership in as litte as 60 seconds to see and start monitoring your credit health</p>
        </div>
        <div className="how-work-item">
          <div className="picture">
            <img className="img-fluid" src={ImagesPath.ImgPictureHome2_2x} alt=""/>
            <span><img className="img-fluid" src={ImagesPath.ImgIcon2a} alt=""/></span>
          </div>
          <h6>Verify your ID</h6>
          <p>A verification message will be sent to your e-mail address and after confirmation you will get access to all functions of the portal.</p>
        </div>
        <div className="how-work-item">
          <div className="picture">
            <img className="img-fluid" src={ImagesPath.ImgPictureHome4_2x} alt=""/>
            <span><img className="img-fluid" src={ImagesPath.ImgIcon3a} alt=""/></span>
          </div>
          <h6>Access great offers perfectly matched to your needs</h6>
          <p>See personalised and exclusive offers from some of the country's leading banks, leaders, insurers and more</p>
        </div>
        <div className="how-work-item">
          <div className="picture">
            <img className="img-fluid" src={ImagesPath.ImgPictureHome5_2x} alt=""/>
            <span><img className="img-fluid" src={ImagesPath.ImgIcon4a} alt=""/></span>
          </div>
          <h6>Monitor Your Credit Score</h6>
          <p>Check in monthly to see how your score changes and offers can be unlocked as it improves</p>
        </div>
      </div>
    </div>
  </div>
</section>


        )
};


HomeProcessSection.propTypes = {
  steps: PropTypes.object.isRequired,
  onGetScoreClick: PropTypes.func.isRequired
};


export const HomeFaqs = ({steps}) => {
        return (
            <section className="section-faq">
              <div className="container">
                <div className="faq-wrapper">
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12">
                      <div className="row">
                        <div className="col-12 column-text">
                          <h2>Got questions?<br/> We've got answers</h2>
                          <p>Visit our easy to use Help Centre to quickly get the answers<br/> you need about all things credit score.</p>
                        </div>
                        <div className="col-12">
                          <div className="accordion-faq" id="accordionFaq">
                            {
                              steps.map( (item, index) => {
                                  return(
                                      <div className="card" key={index}>
                                        <div className="card-header" id={"heading-faq"+item.key}>
                                          <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#collapse-faq"+item.key} aria-expanded="false" aria-controls={"collapse-faq"+item.key}>
                                              {item.title}
                                            </button>
                                          </h5>
                                        </div>

                                        <div id={"collapse-faq"+item.key} className="collapse" aria-labelledby={"heading-faq"+item.key} data-parent="#accordionFaq">
                                          <div className="card-body">
                                              {item.text}
                                          </div>
                                        </div>
                                      </div>
                                  );
                              })
                            }
                          </div>
                          <a href={RoutesVariables.RouteExternalHelpCenter} className="btn btn-primary">Go to Help Center</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        )
};


HomeFaqs.propTypes = {
  steps: PropTypes.array.isRequired
};
