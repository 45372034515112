import $ from 'jquery'
import 'bootstrap'
import slick from 'slick-carousel-latest'

export const tonikInitiTruspilotSlider = () => {

  var mounted = false;
  try{
    // carousel page home
    $('.opinion-carousel').slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 4000
    });

    mounted = true;
    
    }
    catch(e){
      
    
    }

    return mounted;
}

export const tonikInitHomePage = () => {

  // try{
// carousel page home
// $('.opinion-carousel').slick({
//   dots: true,
//   arrows: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   autoplay: true,
//   autoplaySpeed: 4000
// })
// }
// catch(e){

// }

try{
$('.how-work-slider').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 4000
})
}
catch(e){
  
}

try{
$('.slider-logo').slick({
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick'
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
})
}
catch(e){
  
}


try{
$('.slider-post').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick'
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
  ]
})
}
catch(e){
  
}



// page private policy, accordion for mobile view
function privatePolicy () {
  if ($(window).width() < 992) {
    $('.page-content-text .text-content .text-wrapper .text-wrapper-item').hide()
    $('.page-content-text .text-content .text-wrapper h2').addClass('mobile').removeClass('active')
    $('.page-content-text .text-content .text-wrapper').off('click', 'h2.mobile').on('click', 'h2.mobile', function () {
      $(this).parent().find('.text-wrapper-item').slideToggle()
      $(this).toggleClass('active')
    })
  } else {
    $('.page-content-text .text-content .text-wrapper .text-wrapper-item').show()
    $('.page-content-text .text-content .text-wrapper h2').removeClass('mobile')
  }
}

mobileFooter()
privatePolicy()

$(window).on('resize', function () {
  mobileFooter()
  privatePolicy()
})

// footer menu-mobile for homepage
function mobileFooter () {
  if ($(window).width() < 767) {
    $('.footer-home .menu ul').hide()
    $('.footer-home .menu h5').addClass('mobile').removeClass('active')
    $('.footer-home .menu').off('click', 'h5.mobile').on('click', 'h5.mobile', function () {
      $(this).parent().find('ul').slideToggle()
      $(this).toggleClass('active')
    })
  } else {
    $('.footer-home .menu ul').show()
    $('.footer-home .menu h5').removeClass('mobile')
  }
}

// active class in the main nav (homepage, wireframes etc)
var pgurl = window.location.href.substr(window.location.href.lastIndexOf('/') + 1)
$('.header-home li').each(function () {
  var link = $('a', this).attr('href')
  if (link === pgurl) {
    $(this).find('a').addClass('active')
  }
})

// accordion at homepage - change images
$('.accordion button').on('click', function (e) {
  var target = $(this).data('target')
  $('.pic-work img').removeClass('pic-active')
  $(target + '_img').addClass('pic-active')
  $(target + '_number').addClass('pic-active')
})

// accordion slider at homepage
$('.accordion button').on('click', function (e, d) {
  $(this).parents('.card').addClass('card-active').siblings().removeClass('card-active')
})

var timer = setInterval(doClick, 5000)

function doClick () {
  var $n = $('.accordion .card-active').next()
  if ($n.length === 0) {
    $n = $('.accordion .card').siblings().first()
  }

  $n.find('button').trigger('click', [true])
}

// show more features - mobile homepage
$('.section-function').on('click', '.more-link', function (e) {
  $(this).parent().find('.box-function').removeClass('d-none')
  $(this).hide()
  e.preventDefault()
});



// kCounter


(function (b) {
  var boxWidth
  var boxHeight
  if ($(window).width() > 991) {
    boxWidth = 100
    boxHeight = 141
  } else if ($(window).width() > 767) {
    boxWidth = 90
    boxHeight = 105
  } else if ($(window).width() > 350) {
    boxWidth = 42
    boxHeight = 58
  } else {
    boxWidth = 35
    boxHeight = 58
  }

  var settings
  var a = {
    init: function (c) {
      settings = b.extend({
        height: boxHeight,
        width: boxWidth,
        initial: false,
        easing: 'swing',
        duration: 'fast'
      }, c)
      b(this).data('settings', settings)
      return this.each(function () {
        var g = b(this)
        var f = settings.initial ? settings.initial : g.html()
        var e = f.toString().split('')
        var d = ''
        b.each(e, function (h, k) {
          if (b.isNumeric(k)) {
            d += '<ul><li style="top:-' + (k * settings.height) + 'px">'
            for (var j = 0; j < 10; j++) {
              d += '<span>' + j + '</span>'
            }
            d += '</li></ul>'
          } else {
            d += '<ul><li><span>' + k + '</span></li></ul>'
          }
        })
        g.html(d)
        a.updateCss.call(g, settings)
      })
    },
    updateCss: function (c) {
      return this.each(function () {
        var d = b(this)
        d.css({
          overflow: 'hidden'
        })
        b('ul', d).css({
          position: 'relative',
          'float': 'left',
          // overflow: 'hidden',
          height: c.height + 'px',
          width: c.width + 'px',
          'line-height': c.height + 'px'
        })
        b('li', d).css({
          position: 'absolute',
          width: c.width + 'px'
        })
        b('span', d).css({
          display: 'block',
          'text-align': 'center',
          height: c.height + 'px',
          width: c.width + 'px'
        })
      })
    },
    update: function (c) {
      return this.each(function () {
        var k = b(this)
        var h = c.toString().split('')
        var f = b('ul', k).length
        var g = b(this).data('settings')
        var j
        var e
        if (f !== h.length) {
          j = h.length - f
          f = h.length
          if (j < 0) {
            b('ul', k).slice(j).remove()
          } else {
            e = ''
            while (j--) {
              e += '<ul><li>'
              for (var d = 0; d < 10; d++) {
                e += '<span>' + d + '</span>'
              }
              e += '</li></ul>'
            }
            k.prepend(e)
          }
        }
        b.each(h, function (l, o) {
          var n = ''
          if (g.duration === 0) {
            b('ul:nth-child(' + (l + 1) + ') li', k).html('<span>' + o + '</span>').css({
              top: 0
            })
            return
          }
          if (b.isNumeric(o)) {
            if (b('ul:nth-child(' + (l + 1) + ') li span', k).length === 1) {
              for (var m = 0; m < 10; m++) {
                n += '<span>' + m + '</span>'
              }
              b('ul:nth-child(' + (l + 1) + ') li', k).html(n)
            }
            b('ul:nth-child(' + (l + 1) + ') li', k).animate({
              top: -o * g.height
            }, g.duration, g.easing)
          } else {
            b('ul:nth-child(' + (l + 1) + ') li', k).html('<span>' + o + '</span>').animate({
              top: 0
            }, g.duration, g.easing)
          }
        })
        a.updateCss.call(k, g)
      })
    }
  }
  b.fn.kCounter = function (c) {
    if (a[c]) {
      return a[c].apply(this, Array.prototype.slice.call(arguments, 1))
    } else {
      if (typeof c === 'object' || !c) {
        return a.init.apply(this, arguments)
      } else {
        b.error('Method ' + c + ' does not exist on jQuery.kCounter')
      }
    }
  }
})($)

// counter for homepage
var num = $('#counter').data('counter')
$('#counter').kCounter({
  initial: num,
  duration: 500
  // easing: 'easeOutElastic'
})

setInterval(function () {
  $('#counter').kCounter('update', num++)
}, 1000)


return;
}