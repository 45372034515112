import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import AboutMeApi from '../../api/aboutMeApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import {AboutMeWizard} from '../common/Wizard/AboutMe/AboutMeWizard';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

class PrivacyPolicy extends React.Component { 
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAboutMeProfileLoaded : false,
      isAboutMeProfileLoading : true,
      isServerError: false,
      initialProfile: null,
    };


  }

  componentDidMount() {
    window['initAppJs'](true);
    this.props.actions.getTextVariants();
    this.props.actions.getMenuForPage(pages.ABOUTME);
    //this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
    this.props.actions.isAuthenticated().then(e => {
      AboutMeApi.getAboutMeProfile(this.props.csrfToken).then( resp => {
        this.setState({
          initialProfile: resp.profile, 
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: false});

      }).catch( error => {
        this.setState({
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: true});

      });
    });
  }

  componentWillReceiveProps(nextProps) {

  }








  render() {    
    let pageContent = (
    <div className="grid-section mt-5 mb-5">
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <div>
                            
    
            <p className="text-center"><strong>GETCREDITSCORE – PRIVACY POLICY</strong></p>
            <p><strong><br/>OUR COMMITMENT TO PROTECT YOUR PRIVACY</strong></p>
            <p>GetCreditScore Pty Ltd ACN 169 886 891, Australian Credit Licence 501455 and its related bodies corporate (collectively known as "GetCreditScore" and also referred to in this Policy as "us," "we" and "our") seeks to provide the best possible service to its customers ("you") by obtaining your credit score for you at no cost to you, and providing access to a range of financial products and services such as credit cards, personal loans, car loans, home loans, business loans and insurance..</p>
            <p>We understand how important it is to protect your personal information. This document sets out our Privacy Policy commitment in respect of personal information that you may provide to us.</p>
            <p>Any personal information we collect about you will only be used for the purposes indicated in our policy, where we have your consent to do so, or as otherwise required or authorised by law. It is important to us that you are confident that any personal information we collect from you or that is received by us will be treated with appropriate respect ensuring protection of your personal information.</p>
            <p>Our commitment in respect of personal information is to abide by the <em>Privacy Act 1988 (Cth)</em> and any other relevant law.</p>
            <p>Please ensure that you read this Privacy Policy and the associated GetCreditScore <span><a rel="noopener" data-id="1197" href="/terms-of-use/" target="_blank" title="Terms of use">Terms of Use</a></span>.&nbsp; By accessing the GetCreditScore website you agree to be bound by the Privacy Policy and the GetCreditScore Terms of Use.&nbsp;</p>
            <p>The personal information we collect about you is necessary to enable us to provide the services set out in this Privacy Policy.&nbsp; If you do not allow us to collect your personal information, we may be unable to assist you with your request.&nbsp;</p>
            <p><strong>CREDIT REPORTING AND CREDIT SCORES</strong></p>
            <p>A credit report contains information about your credit history which helps credit providers assess your credit applications, verify your identity and manage accounts you hold with them. Credit reporting bodies collect and exchange this information with credit providers and other service providers such as phone companies.</p>
            <p>The Privacy Act permits an individual to appoint another entity to authorise a credit reporting body to provide credit reporting information about an individual to that other entity.&nbsp; An entity appointed by an individual for this purpose is known as an ‘Access Seeker’.&nbsp; The law places strict prohibitions on the entities that may be appointed as an Access Seeker on an individual.&nbsp;</p>
            <p>By appointing GetCreditScore as your Access Seeker you authorise us to assist you to obtain your credit score.</p>
            <p>As an Access Seeker, the information we will provide to credit reporting bodies includes your identification details.&nbsp; However, we do not provide any credit reporting information to the credit reporting bodies.</p>
            <p>Currently, we can act as an Access Seeker to obtain information from Equifax Australia Information Services and Solutions Pty Limited (ABN 26 000 602 862) (Equifax)&nbsp;--&nbsp;<a rel="noopener" href="http://www.equifax.com.au/" target="_blank">www.equifax.com.au</a></p>
            <p>We use information from credit reporting bodies to provide you with your credit score. If authorised by you, we may also use information from credit reporting bodies to assist you to obtain a quote for a loan from a lender.&nbsp; <strong>We do not assess or advise you about your creditworthiness or pass your credit reporting information to any other person.&nbsp; We will only provide details of your credit score to a lender with your consent and at your direct request.</strong></p>
            <p>We will not sell, transfer or disclose your personal information to any credit provider, credit reporting or rating agency, advertiser or any person who is unrelated to the provision of the services set out in this Privacy Policy. &nbsp;</p>
            <p><strong>WHAT IS PERSONAL INFORMATION</strong></p>
            <p>When we refer to <strong><em>personal information </em></strong>we mean information or an opinion about you, from which you are, or may reasonably be, identified.</p>
            <p>This information may include (but is not limited to) your name, date of birth, driver's licence number, marital status, phone number, email address, address, nationality, employment history, income, assets and liabilities. We will not&nbsp;collect sensitive information about you (such as information about your health, religion, trade union membership, political opinion, sexual preference or criminal record).&nbsp;</p>
            <p>Personal information also includes credit information.&nbsp; Credit information is personal information or an opinion about you that has a bearing on credit that has been provided to you or that you have applied for.&nbsp; Credit information includes information about credit that was applied for or provided to you for domestic, household or personal purposes as well as information about you where you a guarantor of a loan.&nbsp; As part of our services to you, we may obtain credit information about you from Equifax. &nbsp;</p>
            <p>From time to time we may also collect and store automatic non-identifiable information relating to your use of our website (‘<strong>Clickstream Information’</strong>).&nbsp; For example, we may record information such as the areas of our website that you visit along with the time, date and URL of the pages you access, your IP address and / or the previous website that you visited before linking to the GetCreditScore website. &nbsp;&nbsp;</p>
            <p><strong>WHY WE COLLECT YOUR PERSONAL INFORMATION</strong></p>
            <p>We collect and receive personal information about you in order to</p>
            <ul>
            <li>act as your agent (‘<strong>Access Seeker</strong>’) to assist you to obtain your credit score (for more information about Access Seekers please refer to the section headed ‘Credit Reporting and Credit Scores’ below),</li>
            <li>assist you to deal with&nbsp;a lender we partner with;</li>
            <li>manage our relationship with you;</li>
            <li>contact you if we need to advise you of something;</li>
            <li>comply with our legal and regulatory obligations; and</li>
            <li>other customer support purposes.&nbsp;&nbsp;</li>
            </ul>
            <p>From time to time we may also use your personal information in relation to other products and services we may offer.</p>
            <p>In addition, we may use Clickstream Information to identify the most popular areas of our website and to tailor our website to improve our website to suit our clients’ needs.</p>
            <p><strong>HOW WE COLLECT YOUR PERSONAL INFORMATION</strong></p>
            <p>Personal information may be collected by us in a number of circumstances, including when an individual:</p>
            <ul>
            <li>appoints us as their Access Seeker to obtain their credit score from Equifax or other credit reporting bodies;</li>
            <li>lodges an enquiry with us by email or telephone;</li>
            <li>requests that we obtain a quote for a loan from a lender with whom we partner;</li>
            <li>requests that we refer them to a lender for a loan; or</li>
            <li>applies to work with us.</li>
            </ul>
            <p>Where reasonable and practical we will collect your personal information only directly from you. However, we will also collect information about you from third parties such as from our contractors who supply services to us, through our referrers (such as financial advisers), from a publicly maintained record or from other individuals or companies as authorised by you.&nbsp;</p>
            <p>If you provide personal information to us about someone else, you must ensure that you are entitled to disclose that information to us and that, without us taking any further steps required by privacy laws, we may collect, use and disclose such information for the purposes described in this Privacy Policy. For example, you should take reasonable steps to ensure the individual concerned is aware of the various matters detailed in this Privacy Policy. The individual must also provide the consents set out in this Privacy Policy in respect of how we will deal with their personal information.</p>
            <p><strong>HOW WE USE YOUR PERSONAL INFORMATION</strong></p>
            <p>We use your personal information (including credit information) for the purpose for which it has been provided, for reasonably related secondary purposes, any other purpose you have consented to and any other purpose permitted under the Privacy Act. This may include using your personal information for the following purposes:</p>
            <ul>
            <li>to provide you with the products or services you requested;</li>
            <li>to verify your identity;</li>
            <li>to assess, process and manage your application to be a customer;</li>
            <li>to assess, process and manage your application to work with us; or</li>
            <li>for complaints handling or data analytics purposes.</li>
            </ul>
            <p><strong>DISCLOSURE OF YOUR PERSONAL INFORMATION</strong></p>
            <p>To enable us to maintain a successful business relationship with you, we may disclose your personal information to:</p>
            <ul>
            <li>credit reporting bodies (including Equifax, formerly known as Veda).&nbsp; For more information about how Equifax may use and handle your personal information please refer to the Equifax Privacy Policy at <a rel="noopener" href="http://www.equifax.com.au/" target="_blank">www.equifax.com.au</a>;</li>
            <li>organisations that provide products or services used or marketed by us, including&nbsp;our partners, intermediaries (such as financial advisers), trustee companies, financial institutions and securitisers;</li>
            <li>companies and contractors who we retain to provide services for us, such as IT contractors, call centres, stationery printing houses, mail houses, storage facilities, lawyers, accountants and auditors, who will need to have access to your personal information to provide those services;</li>
            <li>people considering acquiring an interest in our business or assets; and</li>
            <li>other individuals or companies authorised by you.</li>
            </ul>
            <p>By providing us with your personal information, you consent to us disclosing your information to such entities without obtaining your consent on a case by case basis.</p>
            <p>Sometimes we are required or authorised by law to disclose your personal information. Circumstances in which we may disclose your personal information would be to a Court, Tribunal or law enforcement agency in response to a request or in response to a subpoena or to the Australian Taxation Office.</p>
            <p><strong>OVERSEAS DISCLOSURE<br/><br/></strong>Our IT servers and cloud storage services which hold your personal information are located in Australia. We may disclose your personal information overseas for technical support purposes.<br/><br/>By providing us with your personal information, you consent to us disclosing your information to entities located outside Australia on the basis that we will take reasonable steps to ensure that the overseas recipient complies with Australian privacy laws.</p>
            <p><strong>DIRECT MARKETING USE AND HOW TO OPT-OUT</strong></p>
            <p>From time to time we may use your personal information to provide you with current information about our services, special offers you may find of interest, changes to our organisation, or new products or services being offered by us or any company we are associated with. By providing us with your personal information, you consent to us using your information to contact you on an ongoing basis for this purpose, including by mail, email, SMS, social media and telephone.</p>
            <p>If you do not wish to receive marketing information from GetCreditScore, you may update your preferences within the member dashboard, alternatively you may at any time decline to receive such information by email: <strong>contact@getcreditscore.com.au</strong>.&nbsp;We will not charge you for giving effect to your request and will take all reasonable steps to meet your request at the earliest possible opportunity.</p>
            <p>You may have consented to sharing your personal information with <span><a rel="noopener" href="http://www.equifax.com.au/" target="_blank">Equifax</a></span> for direct marketing purposes. For more information on Equifax’s privacy policies and how to opt-out of direct marketing from these entities please contact the entities directly.</p>
            <p><strong>USE OF COOKIES</strong></p>
            <p>We use technology known as “cookies” to collect statistical information about you when using internet browsing, mobile or tablet applications on our website. A cookie is a packet of information placed on your computer's hard drive or in memory by a website for record keeping purposes. They can record information about your visit to the site, allowing it to remember you the next time you visit and provide a more meaningful experience.<br/><br/>If you disable the use of cookies on your web browser or remove or reject specific cookies from our website or linked sites then you may not be able to gain access to all of the content and facilities on those websites. &nbsp;&nbsp;</p>
            <p><strong>HOW WE KEEP YOUR PERSONAL INFORMATION THAT WE HOLD SAFE AND SECURE</strong></p>
            <p>We will take reasonable steps to protect your personal information, including your credit-related personal information, by storing it in a secure environment, and when the information is no longer needed for any purpose for which the information may be used or disclosed, it will be destroyed or permanently de-identified.</p>
            <p>We will also take reasonable steps to protect any personal information from misuse, loss and unauthorised access, modification or disclosure.</p>
            <p><strong>ACCESS TO AND&nbsp;UPDATING YOUR PERSONAL INFORMATION</strong></p>
            <p>It is important to our relationship that the personal information we hold about you is accurate and up to date. During the course of our relationship with you we will ask you to inform us if any of your personal information has changed.</p>
            <p>If you consider that any information we hold about you is incorrect, you should log in to your member dashboard to update it. &nbsp;Alternatively, you may contact us to have it updated. We will generally rely on you to assist us in informing us if the information we hold about you is inaccurate or incomplete.</p>
            <p>We will provide you with access to the personal information we hold about you, subject to limited exceptions in the Privacy Act as outlined below. You may request access to any of the personal information we hold about you, including any credit-related personal information we hold about you, at any time.</p>
            <p>To access personal information that we hold about you via email, send your request to: contact@getcreditscore.com.au. We may charge a fee for our reasonable costs in retrieving and supplying the information to you.</p>
            <p>There may be situations where we are not required to provide you with access to your personal information. For example, such a situation would be information relating to an existing or anticipated legal proceeding with you, or if your request is vexatious.</p>
            <p>An explanation will be provided to you if we deny you access to your personal information we hold.</p>
            <p><strong>BUSINESS WITHOUT IDENTIFYING YOU</strong></p>
            <p>In most circumstances it will be necessary for us to identify you in order to successfully do business with you, however, where it is lawful and practicable to do so, we will offer you the opportunity of doing business with us without providing us with personal information. Such a situation would be where you make general inquiries about interest rates or current promotional offers.</p>
            <p><strong>GET CREDIT SCORE PARTNER PRIVACY POLICIES</strong></p>
            <p>Below are the Privacy Policies of our partners:</p>
            <ul>
            <li><strong>Planwise</strong>&nbsp;<strong>Au Pty Ltd</strong> (Uno Home Loans, ABN 81 609 882 804). <span>Australian Credit Licence Number 483595</span>.&nbsp; <a href="https://unohomeloans.com.au/privacy-policy/">Click here</a> for their privacy policy.</li>
            <li><strong>Stratton Finance</strong> <strong>Pty Ltd</strong> (ACN 070 636 903 / Australian Credit Licence no. 364340).&nbsp; <a href="https://www.strattonfinance.com.au/contact-us/privacy-policy.aspx">Click here</a> for their privacy policy.</li>
            <li><strong>RateCity Pty Limited</strong> (ABN 12 122 743 542 &amp; AFSL/Australian Credit Licence 316710).&nbsp; <a href="http://www.ratecity.com.au/privacy-policy">Click here</a> for their privacy policy.</li>
            <li><strong>Valiant Finance Pty Ltd</strong> (ACN 606 560 150).&nbsp; <a href="https://valiant.finance/legal">Click here</a> for their privacy policy.</li>
            <li><strong>SocietyOne Australia Pty Ltd</strong> ACN 151 627 977 holds Australian Credit Licence No. 423660 and is an authorised representative of Ironbark Asset Management Pty Ltd ACN 136 679 420 AFSL 34 10 20. <a href="https://www.societyone.com.au/marketplace/legal">Click here</a> for their privacy policy.</li>
            <li><span><strong>Wingate Consumer Finance Pty Ltd</strong> (Now Finance,&nbsp;ACN 158 703 612). Australian Credit Licence number 425142.&nbsp;</span><a href="https://nowfinance.com.au/privacy-policy/">Click here</a> for their privacy policy.</li>
            <li><strong>Harmoney Services Australia Pty Ltd</strong> (ABN 49 608 364 272). Australian Credit License number&nbsp;<span>474726 and Australian Financial Services Licence Number 474726.</span>&nbsp;<a rel="noopener" href="https://www.harmoney.com.au/how-it-works/legal/privacy-policy" target="_blank">Click Here</a> for their privacy policy.&nbsp;</li>
            <li><span><strong>Pepper Group Limited</strong>&nbsp;(ABN 55 094 317 665)</span>. Australian Credit License Number &amp; AFSL 286655. <a href="https://www.pepper.com.au/privacy-policy">Click here</a> for their privacy policy.</li>
            <li><span><strong>Fair Go Finance Pty Ltd</strong> (ACN 134 369 574). Australian Credit License Number 387995. <a href="https://www.fairgofinance.com.au/legal-and-compliance/privacy-policy/">Click here&nbsp;</a>for their privacy policy.&nbsp;</span></li>
            <li><span><strong>RateSetter Australia RE Limited</strong> (ABN 57 166 646 635). Australian Credit License number &amp; AFSL 449176. <a href="https://www.ratesetter.com.au/privacy-policy/">Click here</a> for their privacy policy.</span></li>
            <li><span><strong>Firstmac Limited</strong> (ACN 094 145 963). ACL &amp; AFSL 290600. <a href="https://www.firstmac.com.au/privacy-policy">Click here</a> for their privacy policy.&nbsp;</span></li>
            <li><span><strong>Latitude Finance Australia</strong> (ABN 42 008 583 588). ACL 392145. <a href="https://www.latitudefinancial.com.au/privacy/">Click here</a> for their privacy policy.</span></li>
            <li><span><strong>On Deck Capital Australia Pty Ltd</strong> (ACN 603 753 215). <a href="https://www.ondeck.com.au/privacy-policy/">Click here</a> for their privacy policy.&nbsp;</span></li>
            <li><span><strong>Alternative Media Pty Ltd</strong> (ABN 17 149 089 716). <a href="https://alternativemedia.com.au/privacy-policy/">Click here</a> for their privacy policy.</span></li>
            <li><span><strong>AGL Energy Limited</strong> (ABN 74 115 061 375). <a href="https://www.agl.com.au/privacy-policy">Click here</a> for their privacy policy.&nbsp;</span></li>
            <li><span><strong>Ebroker.com.au Pty Ltd</strong> (ACN 606 329 800). <a href="https://www.ebroker.com.au/privacy-policy">Click here</a> for their privacy policy.&nbsp;</span></li>
            </ul>
            <p><strong>FURTHER INFORMATION AND COMPLAINTS</strong></p>
            <p>You may request further information about the way we manage your personal information or lodge a complaint by contacting our Privacy Officer(s) on the contact details below.</p>
            <p>We will deal with any complaint by investigating the complaint, and providing a response to the complainant generally within 15 business days, provided that we have all necessary information and have completed any investigation required. In cases where further information, assessment or investigation is required, we will seek to agree alternative time frames with you.</p>
            <p><strong>ESCALATING A COMPLAINT</strong></p>
            <p>If you are not satisfied with our response to your complaint, you may contact the External Dispute Resolution Scheme of which GetCreditScore is a member, the Australian Financial Complaints Authority (AFCA).<br/><em>&nbsp;<br/></em>How to contact the Australian Financial Complaints Authority (AFCA):&nbsp;<br/>Online: &nbsp;<a href="http://www.afca.org.au/">www.afca.org.au</a>&nbsp;&nbsp;<br/>Email: &nbsp;info@afca.org.au&nbsp;&nbsp;<br/>Phone: 1800 931 678 &nbsp;<br/>Post: Australian Financial Complaints Authority,&nbsp;GPO Box 3,&nbsp;Melbourne VIC 3001</p>
            <p>You may also make a complaint to the Office of the Australian Information Commissioner (OAIC).&nbsp;<br/>&nbsp;<br/>How to contact the Office of the Australian Information Commissioner (OAIC):&nbsp;<br/>Online:&nbsp;<a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>&nbsp;&nbsp;<br/>Email: &nbsp;enquiries@oaic.gov.au&nbsp;&nbsp;<br/>Fax: 1300 363 992 &nbsp;<br/>Mail: Office of the Australian Information Commissioner,&nbsp; GPO Box 5218,&nbsp;Sydney NSW 2001&nbsp;</p>
            <p><strong>CONTACT DETAILS</strong></p>
            <p>To contact us, please email us at: contact@getcreditscore.com.au.</p>
            <p><strong>CHANGE IN OUR PRIVACY POLICY</strong></p>
            <p>We are constantly reviewing all of our policies and attempt to keep up to date with market expectations. Technology is constantly changing, as is the law and market practices.</p>
            <p>As a consequence we may change this Privacy Policy from time to time or as the need arises.</p>
            <p className="text-left"><span>This Privacy Policy was last updated on 25 Nov 2019</span><strong>.</strong></p>

          </div>
        </div>        
      </div>
    </div>
  </div>
</div>
    );


    return (

      <div className="page-template-page-about bg-circle">
        {!this.props.userProfile.isAuthenticated && <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />}
        {this.props.userProfile.isAuthenticated && <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>}
        {pageContent}
        <FooterSmallSection></FooterSmallSection>
      </div>
    );
  }
}

PrivacyPolicy.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];

  if (state.menu) {
    var menu = state.menu;
    if ( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.ABOUTME]) {
        menuItems = menu.menuItemsPerPage.menuItems[pages.ABOUTME];
     }
  }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    csrfToken: state.userProfile.csrfToken,
    identifier: state.userProfile.identifier
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, trackingActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);