import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as productActions from '../../redux/actions/productActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as userActions from '../../redux/actions/userActions';
import * as scoreMapActions from '../../redux/actions/scoreMapActions';

import * as pages from '../shared/pages';
import ValidationApi from '../../api/validationApi';
import * as funcs from '../shared/functions';

import { generationsModel } from '../shared/scoreMapData18';
import * as GlobalVariables from '../../config/configureGlobalVariables';

import Menu from '../menu/Menu';
import ScoreMapSection from '../sections/ScoreMapSection';
import ScoreMapLocation from './ScoreMapLocation';
import ScoreMapSearch from './ScoreMapSearch';
import ScoreMapGenerations from './ScoreMapGenerations';
import ScoreMapRegionScores from './ScoreMapRegionScores';

class ScoreMapPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      inputPostCode: "",
      currentAreaStatistics: null,
      locationName: "",

      submittedPostCode: "",
      submittedSA3Code: "",

      scoreMapHasError: false,
      scoreMapSubmittedEmpty: false,
      findAreaByPostCodeFailed: false
    };

    this.scoreStatisticsData = null;

    this.onScoreMapInputChange = this.onScoreMapInputChange.bind(this);
    this.onScoreMapSubmit = this.onScoreMapSubmit.bind(this);
    this.onAreaSelected = this.onAreaSelected.bind(this);
    this.onGetScoreClick = this.onGetScoreClick.bind(this);
    this.findAreaByPostCode = this.findAreaByPostCode.bind(this);
    this.findAreaByPostCodeFailHandler = this.findAreaByPostCodeFailHandler.bind(this);
    this.redirect = this.redirect.bind(this);
    this.getStatisticsForSA3Area = this.getStatisticsForSA3Area.bind(this);
    this.convertStatisticsForCurrentSA3Area = this.convertStatisticsForCurrentSA3Area.bind(this);
  }

  componentDidMount() {
    this.props.actions.getMenuForPage(pages.SCOREMAP);
    this.props.actions.getTextVariants();

    this.props.actions.isAuthenticated().then(e => {
      if (this.props.isAuthenticated) {
        let postCode = this.props.match.params.postCode;
        if (ValidationApi.isValidPostCode(postCode)) {
          this.findAreaByPostCode(postCode);
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!nextProps.isAuthenticated) {
          this.props.history.push("/login?retUrl=" + this.props.location.pathname);
      }
    }
  }

  onScoreMapInputChange(event) {
    const value = event.target.value;

    this.setState({
      inputPostCode: value,
      scoreMapHasError: false,
      scoreMapSubmittedEmpty: false
    });
  }

  onScoreMapSubmit(event) {
    event.preventDefault();

    this.findAreaByPostCode(this.state.inputPostCode);

    return false;
  }

  onAreaSelected(sA3Code, sA3Name) {
    sA3Code = sA3Code || "";
    sA3Name = sA3Name || "";

    this.setState({
      submittedPostCode: "",
      submittedSA3Code: sA3Code,
      locationName: sA3Name,
      findAreaByPostCodeFailed: false
    });

    this.getStatisticsForSA3Area(sA3Code);
  }

  onGetScoreClick(event) {
    this.props.actions.trackGetScoreButtonClick("", "ScoreMapGcsBtn");

    if(this.props.isAuthenticated) {
      this.redirect("/dashboard");
    } else {
      this.redirect("/create-account");
    }
  }

  redirect(url) {
    this.props.history.push(url);
  }

  findAreaByPostCode(postCode) {

    let isPostCodeValid = ValidationApi.isValidPostCode(postCode);

    this.setState({
      scoreMapHasError: !isPostCodeValid,
      scoreMapSubmittedEmpty: isPostCodeValid ? postCode.length === 0 : false,
      submittedPostCode:  isPostCodeValid ? postCode : "",
      inputPostCode: isPostCodeValid ? postCode : this.state.inputPostCode
    });

    if(isPostCodeValid){
      this.props.actions.trackScoreMapSearch("ScoreMapPagePostCode", postCode);

      this.props.actions.getSA3Areas({ search: postCode }).then((regionResponse) => {

        if (regionResponse && regionResponse.regions && regionResponse.regions.length> 0) {

          let region = regionResponse.regions[0];
          this.onAreaSelected(region.sA3Code, region.sA3Name);


          funcs.scrollIntoView("div-scoremap-search");

        } else {
          this.findAreaByPostCodeFailHandler();
        }

      }).catch(error => {
        this.findAreaByPostCodeFailHandler();
      });
    }
  }

  findAreaByPostCodeFailHandler(){
    this.setState({
      currentAreaStatistics: null,
      locationName: "",
      submittedSA3Code: "",
      findAreaByPostCodeFailed: true
    });
  }

  getStatisticsForSA3Area(sA3Code) {
    if(sA3Code) {
      if (this.scoreStatisticsData) {
        this.convertStatisticsForCurrentSA3Area(this.scoreStatisticsData, sA3Code);
      } else {
        this.getAllScoreStatistics().then((response) => {
          this.scoreStatisticsData = response;
          this.convertStatisticsForCurrentSA3Area(this.scoreStatisticsData, sA3Code);
        });
      }
    } else {
      this.setState({currentAreaStatistics: null});
    }
  }

  getAllScoreStatistics() {
    let timerId = null;
    const fullUrl = GlobalVariables.SCRIPTS_BASE_URL + "scoreMapData18.js";
    const scriptElement = document.createElement("script");
    scriptElement.src = fullUrl;
    document.head.appendChild(scriptElement);

    return new Promise((resolve, reject) => {
      timerId = setInterval(() => {
        if(window["scoreMapStatsFile"]) {
          clearInterval(timerId);

          resolve(window["scoreMapStatsFile"]);
        }
      }, 1000);
    });
  }

  convertStatisticsForCurrentSA3Area(scoreStatistics, sA3Code) {
    this.setState({currentAreaStatistics: null});

    const sTCode = sA3Code.slice(0, 1);
    const currentState = scoreStatistics && scoreStatistics.states[sTCode];
    const currentSA4Area = scoreStatistics && scoreStatistics.sTCodes[sTCode];
    const currentSA3Area = scoreStatistics && scoreStatistics.sA3Codes[sA3Code];

    if (currentSA3Area) {
      currentSA3Area.gens.forEach(gen => { gen.spec = generationsModel[gen.g]; });

      this.setState({currentAreaStatistics: { currentState, currentSA4Area, currentSA3Area }});
    }
  }

  render() {
    let mainComponent = <div />;

    if (this.props.isAuthenticated){
      mainComponent = (
        <div className="scoremap-page" >
          <div className="scoremap-page-banner" >

            <Menu menuItemList={this.props.menuItemList} />

            <ScoreMapSection
              key="scoreMapSection"
              textVariants={this.props.textVariants}
              onGetScoreClick={this.onGetScoreClick}
              onScoreMapInputChange={this.onScoreMapInputChange}
              onScoreMapSubmit={this.onScoreMapSubmit}
              postCode={this.state.inputPostCode}
              scoreMapHasError={this.state.scoreMapHasError}
              scoreMapSubmittedEmpty={this.state.scoreMapSubmittedEmpty}
              findAreaByPostCodeFailed={this.state.findAreaByPostCodeFailed} />

          </div>

          <ScoreMapLocation
            key="scoreMapLocation"
            locationName={this.state.locationName}
            textVariants={this.props.textVariants} />

          <div id="div-scoremap-search">

            <ScoreMapSearch
              postCode={this.state.submittedPostCode}
              sA3Code={this.state.submittedSA3Code}
              onAreaSelectedFromList={this.onAreaSelected}
              textVariants={this.props.textVariants}
              actions={this.props.actions}
            />

          </div>

          {
            this.state.currentAreaStatistics && this.state.currentAreaStatistics.currentSA4Area &&
            <div>
              <ScoreMapRegionScores
                key="scoreMapRegionScores"
                stateTitle={this.state.currentAreaStatistics.currentState}
                areaRegionsScores={this.state.currentAreaStatistics.currentSA4Area["rg"]}
                textVariants={this.props.textVariants} />
            </div>
          }
          {
            this.state.currentAreaStatistics && this.state.currentAreaStatistics.currentSA3Area &&
            <div>
              <ScoreMapGenerations
                key="scoreMapGenerations"
                areaGenerations={this.state.currentAreaStatistics.currentSA3Area["gens"]}
                textVariants={this.props.textVariants} />
            </div>
          }
        </div>
      );
    }

    return (mainComponent);
  }

}

ScoreMapPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  let menuItems = [];

  if (state.menu) {
    var menu = state.menu;
    if (menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.SCOREMAP]) {
      menuItems = menu.menuItemsPerPage.menuItems[pages.SCOREMAP];
    }
  }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    isAuthenticated: state.userProfile.isAuthenticated
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, userActions, layoutActions, productActions, trackingActions, scoreMapActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreMapPage);
