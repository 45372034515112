import React from 'react';
import PropTypes from 'prop-types';
import * as funcs from '../shared/functions';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import LoginFrame from './LoginFrame';

const LoginBoxMessage = ({titleText, contentText, actionName, onActionClick, href, showTandCs = true}) => {

let clickable = <button type="submit" className="btn btn-primary full-width">{actionName}</button>;
if(href){
  clickable = <a type="button" target="_blank" rel="noopener noreferrer" href={href} onClick={onActionClick} className="btn btn-primary full-width float-none">{actionName}</a>;
}

    return (

      <LoginFrame
        disclaimerCtrl={showTandCs && <span className="text-form">By clicking 'Login', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia InformationServices and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's <a href="#">Privacy Policy</a> and <a href="#">Terms of Use.</a></span>}
        >
          <h2 className="text-center">{titleText}</h2>
                <p dangerouslySetInnerHTML={funcs.createMarkup(contentText)}></p>
                <form onSubmit={onActionClick}>
                        {actionName && clickable }
                </form>
      </LoginFrame>     
    );
  }

LoginBoxMessage.propTypes = {
   titleText: PropTypes.string.isRequired,
   contentText: PropTypes.string.isRequired,
   actionName: PropTypes.string,
   onActionClick: PropTypes.func
};

export default LoginBoxMessage;
