import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from '../login/LoginBoxMessage';
import * as funcs from '../shared/functions';
import * as RoutesVariables from '../../config/routesVariables';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as pages from '../shared/pages';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import VerificationAPI from '../../api/verificationApi';
import TextNumericInput from '../common/controls/TextNumericInput';
import GcsRecaptcha from "../common/GcsRecaptcha";
import FooterSmallSection from '../common/footerSmall';

import * as ImagesPath from '../../config/imagesPath';

import { USERROLES, hasOneRole } from '../../common/gcsLogic';


class RegistrationVerifyEmailPage extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = { 
        verificationCode: '',
        errorMessage: '',
        infoMessage: '',
        otcError: null,
        saving: false,
        resetRecaptcha: false,
        shouldResend: false
      };      

      this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.onResendEmail = this.onResendEmail.bind(this);
      this.redirectToAuth = this.redirectToAuth.bind(this);
      this.onBack = this.onBack.bind(this);
      this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
      this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  
      this.doSubmit = this.doSubmit.bind(this);
      this.doResendEmail = this.doResendEmail.bind(this);

      this.recaptchaRef = React.createRef();  
  }

  componentDidMount() {    
    this.props.actions.getMenuForPage(pages.REGISTER);
    this.props.actions.getTextVariants();  
    this.setState({saving: false});
    this.doResetRecaptcha();  
  }

  componentWillReceiveProps(nextProps) {      
    // if (nextProps.isAuthenticated) {
    //   // go back to the page if the r parameter is set
    //   this.props.history.push(RoutesVariables.RouteCreditScore);          
    // }  
    this.doResetRecaptcha();
  } 

  onSelectRecaptcha(token){
   
    if (token) {
      if(this.state.shouldResend){
        this.setState({shouldResend: false},
           () => this.doResendEmail(token) );        
      }
      else{
        this.doSubmit(token);
      }
    }      

    this.setState({resetRecaptcha: true, saving: false});
  }

  doResetRecaptcha(){
    if (this.state.resetRecaptcha) {
      try{
        this.recaptchaRef.current.reset();
        this.setState({resetRecaptcha: false});
      }
      catch(ex){
        console.log(ex);
      }      
    }
  }
  
  onChangeVerificationCode(event) {
    
    let verificationCode = this.state.verificationCode;
    verificationCode = event.target.value;

    return this.setState({ verificationCode: verificationCode, otcError: null });
  }

  onFormSubmit(event){

    event.preventDefault();
    // Validate    
    if (!this.state.verificationCode || (this.state.verificationCode.length < 5 && !this.state.verificationCode.match(/^[0-9]+$/g))){
      this.setState({otcError: 'The One Time Code is not Valid'});       
    }
    else{
      this.recaptchaRef.current.execute();
    }
  }

  doSubmit(token){

    this.setState({ errorMessage: null, infoMessage: null, saving: true });
    // Send Verification Code    
    VerificationAPI.verifyEmailOneTimeCodeRegistration(this.state.verificationCode, token).then( (verification) => {
      // Redirect to re-auth
      if(verification.isError){
        this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
      } else{
        this.setState({saving: false});
        this.redirectToAuth(verification);
      }
    }).catch( e => {
          if( e.status == 400){
            e.json().then( verification => {
                this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
            });
            } else {
                this.setState({ errorMessage: 'An unexpected error occurred. Please <a href="/contact-us" style="float:none !important;">contact us</a>.', infoMessage: null });
            }
            this.doResetRecaptcha();
        }
    ).finally( () => {
          this.setState({saving: false});
    });
  }

  redirectToAuth(verification){
    
    this.props.actions.refreshAuthenticationWithHash('E').then(r =>{

      if(r.roles && hasOneRole(r.roles, USERROLES.IdentityNotVerified)){    
        var navigateUrl = r.redirect_uri || RoutesVariables.CreateAccountMobile;
        this.props.history.push(navigateUrl);
      }
      else{        
        this.setState({errorMessage: 'We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another verification email with the link below'});
      }
    }).catch(e => {

      if(e.status == 400){
          e.json().then( resp => {
            this.setState({errorMessage: resp.errorMessage});
          });
      }
      else{
        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another email verification'});
      }
    });
  }

  onResendEmail(event){
    event.preventDefault();
    this.setState({shouldResend: true}, ()=> {
      this.recaptchaRef.current.execute();
    });
    
  }

  doResendEmail(token){

    VerificationAPI.sendEmailOneTimeCodeRegistration(token).then(() =>{
      this.setState({infoMessage: 'A new email has been sent. Please wait a few minutes and check your spam folder as well.'});
      var that = this;
        setTimeout(() => {
          that.setState({infoMessage: null, errorMessage: null, otcError: null});
        }, 4000);
    }).catch(e => {
      if(e.status == 400){
        e.json().then( resp => {
          this.setState({errorMessage: resp.errorMessage});
        }) 
      } else{

        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another email verification'});
      }
    });

  }

  onBack(event){
    event.preventDefault();
    this.props.history.push(RoutesVariables.CreateAccount + '?b=t');
  }

  render() {       
    return (        
        <div className="bg-circle n-expand">
          <GcsRecaptcha
              recaptchaRef={this.recaptchaRef}
              onSelectRecaptcha={this.onSelectRecaptcha}
            />
            <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} /> 
            <section className='section-form-log page-register n-100vh'>
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 form-log-wrapper">                      
                      <div className="nav-register n-nav-register-2"> {/*nav-register-2 */}
                         <a href="" onClick={this.onBack} className="prev-form"><img src={ImagesPath.ImgIconPrev} className="img-fluid" alt="" /></a>
                        <h2><span>2 <span className="separator" /> 6</span> Email Verification</h2>
                      </div>                                              
                      <div className="form-log-box form-log-box-n">
                        <div className="icon-register-verify">
                          <img src={ImagesPath.ImgIconEmail} className="" alt="img-fluid" />
                        </div>
                        {/* <img src={ImagesPath.ImgLogo} className="img-fluid logo" alt=""/> */}
                        <p>We sent you an email with a code.<br />Please enter it below to confirm your email address. <br />If not found, please also check your spam folder.</p>
                        <form  onSubmit={this.onFormSubmit} className="d-flex justify-content-center align-items-center flex-column">
                          <div className="col-lg-6 col-12 small-input">
                          <TextNumericInput
                              name="code"
                              id="code"
                              placeholder="One Time Code"
                              value={this.state.verificationCode}
                              label="One Time Code"
                              onChange={this.onChangeVerificationCode}
                              error={this.state.otcError} 
                              maxLength={6}
                              groupClass="form-label-group"/>                               
                          </div>
                          <div className="col-lg-6 col-12">
                          <button type="submit" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled': '')}>Verify</button>
                        </div>
                        {this.state.errorMessage && 
                          <div className="alert alert-danger">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.errorMessage)}></span>
                          </div>
                        }   
                         {this.state.infoMessage && 
                          <div className="alert alert-warning">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.infoMessage)}></span>
                          </div>
                        }          
                        <p>Did not arrive yet? <a href="" onClick={this.onResendEmail}>Resend</a></p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>   
              <FooterSmallSection />         
        </div>
    );
  }
}

RegistrationVerifyEmailPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.REGISTER]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.REGISTER];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,   
    userProfile:  state.userProfile, 
    isAuthenticated: state.userProfile.isAuthenticated,    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationVerifyEmailPage);
