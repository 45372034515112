import React from 'react';
import PropTypes from 'prop-types';
import * as ImagesPath from '../../../config/imagesPath';

const DateInputSimple = ({id, name, label, onChange, value, error, toolTip, groupClass = "form-group", notDay=false}) => {

  var fullControlName = name;

  var internalid = 'date_birth' + id;

  // var dayCtrlId = fullControlName + 'dd';
  // var monthCtrlId = fullControlName + 'mm';
  // var yearCtrlId = fullControlName + 'yy';

  var classDD = "form-control text-center";
  if (error && (error["dd"] || error.dateError)) {
    classDD = classDD + ' has-error';
  }

  var classMM = "form-control text-center";
  if (error && (error["mm"] || error.dateError)) {
    classMM = classMM + ' has-error';
  }

  var classYYYY = "form-control text-center yyyy";
  if (error && (error["yy"] || error.dateError)) {
    classYYYY = classYYYY + ' has-error';
  }

  var requiredError = '';
  let inputCls = 'form-control';
  //let groupClass = "form-group";

  if (error) {
    if(!notDay){
      requiredError = error['dd'] ?  error['dd'] + ' ' : "";
    }
    requiredError += error['mm'] ? error['mm'] + ' ' : "";
    requiredError += error['yy'] ? error['yy'] + ' ' : "";

     if(requiredError){
      inputCls+= ' is-invalid';
     }
     else if(error.dateError){
      inputCls+= ' is-invalid';
      requiredError = error && error.dateError;
     }
  }

    var sinchronizeWithParent = function sinchronizeWithParent() {
      
      var innerValue = document.getElementById(internalid).value;

      if(!innerValue){
        innerValue = notDay? "-": "--";
      }

      var date = innerValue.split('-');
      var value = {};
      if(notDay){
        value= {
          'mm':  date[1] || '',
          'yy':  date[0] || '',
        }
      }
      else {
        value= {
          'dd': date[2] || '',
          'mm':  date[1] || '',
          'yy':  date[0] || '',
        }
      }
      var obj = {
        target: {
          name: fullControlName,
          value: value
        }
      };

      onChange(obj);
    };

     return (
        <div className={groupClass}>
            <input type={notDay ? "month" : "date"} className={inputCls}
                 name={internalid}
                 id={internalid}
                 value={notDay ? (value["yy"]+'-'+value["mm"]): (value["yy"]+'-'+value["mm"]+'-'+value["dd"])}
                 onChange={()=>{sinchronizeWithParent()}} autoComplete="off"
                 pattern={notDay ?"[0-9]{4}-[0-9]{2}": "[0-9]{4}-[0-9]{2}-[0-9]{2}"}
                 title={toolTip ? toolTip["helpText"]:''}
                 />
            {toolTip && (
            <a class="info-tooltip" data-toggle="tooltip" data-placement="bottom" title={toolTip["helpText"]}><img src={ImagesPath.ImgIconInfo} class="img-fluid info-icon" alt=""/></a>
          )}
            <label htmlFor={name}><span>{label}</span></label>

            {requiredError && (
              <>
                <div className="invalid-feedback">
                        {requiredError}
                </div>
              </>
            )}
        </div>

     );
};

 DateInputSimple.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
  toolTip: PropTypes.object,
};

export default DateInputSimple;
