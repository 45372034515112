import React from 'react'
import * as ImagesPath from '../../../config/imagesPath';
import {documentSelections} from './dvsDocSelections'

export const DVSSelectionForm = ({onDocumentSelect, isDriverLicenceDone, isPassportDone, isMedicareDone}) => {
    return (
      <div className="select-wrapper">
        <h2>Select identity document:</h2>
        <form>
          {!isDriverLicenceDone && <div className="form-check" onClick={(event)=>{onDocumentSelect(event, documentSelections.driverLicence)}}>
            <input className="form-check-input d-none" type="radio" name="type_id" id="type_id1" value="option1"/>
            <label className="form-check-label" htmlFor="type_id1">
              <img src={ImagesPath.ImgIconDl} className="img-fluid" alt=""/>
              Driver's Licence
            </label>
          </div>}
          {!isPassportDone && <div className="form-check" onClick={(event)=>{onDocumentSelect(event, documentSelections.passport)}}>
            <input className="form-check-input d-none" type="radio" name="type_id" id="type_id2" value="option2"/>
            <label className="form-check-label" htmlFor="type_id2">
              <img src={ImagesPath.ImgIconPassport} className="img-fluid" alt=""/>
              Passport
            </label>
          </div>}
          {!isMedicareDone && <div className="form-check" onClick={(event)=>{onDocumentSelect(event, documentSelections.medicare)}}>
            <input className="form-check-input d-none" type="radio" name="type_id" id="type_id3" value="option3"/>
            <label className="form-check-label" htmlFor="type_id3">
              <img src={ImagesPath.ImgIconMedicare} className="img-fluid" alt=""/>
              Medicare
            </label>
          </div>}
        </form>
      </div>
    );
  };