import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';

const CreditEnquiriesModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">            
              <h4><span>1</span> Information shown for each enquiry listing:</h4>              
                <ul>
                  <li>Name of credit provider</li>
                  <li>Type of account enquiried – e.g. personal loan, credit card &amp; telco</li>
                  <li>Date of enquiry</li>
                  <li>Amount</li>                  
                </ul>   
              
              <h4><span>2</span> What is a credit enquiry?</h4>              
              <p>A credit enquiry is when a credit provider such as a bank performs a credit check on you as part of an application for credit. For example, this could be when you apply for a home loan or credit card. It may be added to your credit report and will be listed as a ‘credit enquiry’.</p>
              <p>Details of a credit check may include the date, the type of credit you have applied for, such as a mortgage, credit card, personal loan, utility and/or telco accounts as well as the amount you applied for. Your credit report doesn’t show the outcome of your application, only that an application has been made.</p>              
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default CreditEnquiriesModal;