import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';
import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';
import * as ImagesPath from '../../config/imagesPath';
import {AlreadyMember} from '../common/blocks/AlreadyMember.js';
import Menu from  '../menu/Menu';
import MenuTopFixed from '../menu/MenuTopFixed';
import CounterSection from './../sections/CounterSection';
import TrustPilotSection from './../sections/TrustPilotSection';
import * as RoutesVariables from '../../config/routesVariables';

export const HomeSplashFeaturedOnSection = ({textVariants}) =>{

        return (
          <section className="section-logo">
          <div className="container">
            <p>Featured on:</p>
            <div className="slider-logo row">
              <div className="col-lg-1 col-md-2 col-12 logo-wrapper">
                <a href="#">
                  <img className="img-fluid" src={ImagesPath.ImgLogoSeven2} alt=""/>
                </a>
              </div>
              <div className="col-md-2 col-12 logo-wrapper text-center">
                <a href="#">
                  <img className="img-fluid" src={ImagesPath.ImgLogoNews} alt=""/>
                </a>
              </div>
              <div className="col-md-2 col-12 logo-wrapper text-center">
                <a href="https://www.cardrates.com/news/getcreditscore-helps-australians-keep-track-of-their-credit-status/">
                  <img className="img-fluid" src={ImagesPath.ImgLogoCredrates} alt=""/>
                </a>
              </div>
              <div className="col-lg-3 col-md-2 col-12 logo-wrapper text-center">
                <a href="#">
                  <img className="img-fluid" src={ImagesPath.ImgLogoFinancial} alt=""/>
                </a>
              </div>
              <div className="col-md-2 col-12 logo-wrapper text-center">
                <a href="#">
                  <img className="img-fluid" src={ImagesPath.ImgLogoSunrise} alt=""/>
                </a>
              </div>
              {/* <div className="col-md-2 col-12 logo-wrapper text-center">
                <a href="https://www.badcredit.org/news/getcreditscore-prepares-aussies-for-success-with-free-credit-scores/">
                  <img className="img-fluid" src={ImagesPath.ImgLogoBadcredit2} alt=""/>
                </a>
              </div> */}
            </div>
          </div>
        </section>
)};



const GcsButtonType = {
  "ACTION":"ACTION",
  "SCROLL":"SCROLL"
};

const HomeCallToActionLeftBasic = ({userProfile, isAuthenticated, textVariants, onGetScoreClick, onLoginClick, originPage, gcsButtonType, showLearnMore = false, pageVariation=""}) => {

let gcsButton = <span></span>;
let learnMoreButton = <span></span>;
if(gcsButtonType){
  if(gcsButtonType == GcsButtonType.ACTION){
    gcsButton = <a className="get-score-btn get-score-btn-splash btn md-mt-20 sm-mt-20 xs-mt-20" id="freescore-banner-link" onClick={onGetScoreClick}>
                 <span>
                  {textVariants["Btn_GetMyScore"]}
                 </span>
               </a>;
  }
  else if(gcsButtonType == GcsButtonType.SCROLL){
    gcsButton = <a href='#registration-section' className="get-score-btn get-score-btn-splash btn md-mt-20 sm-mt-20 xs-mt-20" id="freescore-banner-link" >
                 <span>
                  {textVariants["Btn_GetMyScore"]}
                 </span>
               </a>;
  }
}

if(showLearnMore){
  learnMoreButton = <a className="learn-more-btn btn md-mt-20 sm-mt-20 xs-mt-20 hidden-xs xs-ml-10" href="#learn-articles-section" >
    <span>
        {textVariants["Btn_LearnMore"]}
    </span>
  </a>;
}

let mainText = textVariants[pages.HOME + textVariants.textVariantSeparator + "get_free_score_header" + pageVariation ] ;
if(!mainText){
  mainText = textVariants[pages.HOME + textVariants.textVariantSeparator + "get_free_score_header" ];
}

  return (
    <div>
        <h2 dangerouslySetInnerHTML={funcs.createMarkup(mainText)}></h2>
        <p className="hidden-xs" dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.HOME + textVariants.textVariantSeparator + "get_free_score" + pageVariation])} ></p>

        {gcsButton}
        {learnMoreButton}

        <AlreadyMember
          cssClass={pageVariation === "newmember" ? "invisible": ""}
          isAuthenticated={isAuthenticated}
          text=  {textVariants[pages.REGISTER + textVariants.textVariantSeparator + "header_create_free_account_already_member_part1"]}
          linkText={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "header_create_free_account_already_member_part2"]}
          onLinkClick={onLoginClick} />

    </div>
  );
}

const HomeCallToActionLeftNoAccount = ({textVariants, onGetScoreClick, isAuthenticated, onLoginClick, userProfile, location}) => {

        return (

                <div >
                  <h2 className="no_account_header_1" dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.HOMEOHNO + textVariants.textVariantSeparator + "no_account_header_1"])} ></h2>
                  <p><span  className="no_account_header_2" dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.HOMEOHNO + textVariants.textVariantSeparator + "no_account_header_2"])} ></span></p>
                  <p><span  className="no_account_header_3" dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.HOMEOHNO + textVariants.textVariantSeparator + "no_account_header_3"])} ></span></p>
                  <a href='#registration-section' className="get-score-btn get-score-btn-splash btn md-mt-20 sm-mt-20 xs-mt-20" id="freescore-banner-link" >
                     <span>
                      {textVariants["Btn_GetMyScore"]}
                     </span>
                   </a>
                </div>
            )
};



export const HomeSplashCallToActionPlaceholder = ({textVariants, onGetScoreClick, isAuthenticated, onLoginClick, userProfile, originPage, location, pageVariation=""}) => {


let imgComponent = null;

let callToActionLeft = <HomeCallToActionLeftBasic
  onGetScoreClick={onGetScoreClick}
  onLoginClick={onLoginClick}
  isAuthenticated={isAuthenticated}
  textVariants={textVariants}
  userProfile={userProfile}
  originPage={originPage}
  location={location}
  gcsButtonType={GcsButtonType.ACTION}
  showLearnMore={true}
  pageVariation={pageVariation}
/>;


if(originPage == pages.HOMEOHNO){

  callToActionLeft = <HomeCallToActionLeftNoAccount
    onGetScoreClick={onGetScoreClick}
    onLoginClick={onLoginClick}
    isAuthenticated={isAuthenticated}
    textVariants={textVariants}
    userProfile={userProfile}
    originPage={originPage}
    location={location}
  />;

  imgComponent = <div className="home-old-style-div-computer text-center">
    <img src={ImagesPath.ImgNoAccountWarning} className="home-old-style-img-computer img-responsive img-resp-center" />
  </div>;

}
else if(originPage == pages.FREECREDITSCORE){

  callToActionLeft = <HomeCallToActionLeftBasic
    onGetScoreClick={onGetScoreClick}
    onLoginClick={onLoginClick}
    isAuthenticated={isAuthenticated}
    textVariants={textVariants}
    userProfile={userProfile}
    originPage={originPage}
    location={location}
    gcsButtonType={GcsButtonType.SCROLL}
    showLearnMore={false}
    pageVariation={pageVariation}
  />;

  if(pageVariation == "toyota"){
    imgComponent = <div className={"home-old-style-div-computer text-center partner_logo_" + pageVariation}>
      <img src={ImagesPath.ImgLogoToyotaFinance2} className="home-old-style-img-computer" />
    </div>;
  }
  else if(pageVariation == "powertorque"){
    imgComponent = <div className={"home-old-style-div-computer text-center partner_logo_" + pageVariation}>
      <img src={ImagesPath.ImgLogoPowerTorque} className="home-old-style-img-computer" />
    </div>;
  }
  else{
    imgComponent = <div className="home-old-style-div-computer text-center">
    <img src={ImagesPath.ImgComputerShadow} className="home-old-style-img-computer img-responsive img-resp-center" />
    </div>;
 }
}

        return (

            <section name="call-to-action" className="banner-call-to-action xs-pb-40">
              <div className="container-fluid left-container-padding right-container-padding sm-pt-30">
                    <div className="row">
                        <div className={(imgComponent?  "col-xs-12 col-md-4 col-md-push-8 md-pb-50 top-margin" :  "" )}>
                          {imgComponent}
                        </div>

                        <div className={" md-pb-50 top-margin " +
                          (imgComponent?  "sm-pb-30 xs-pb-20 col-xs-12 col-md-8 col-md-pull-4 text-center-gcs-xs text-center-gcs-sm text-left-gcs-md text-left-gcs-lg" :
                                          "col-xs-12 text-center-gcs-xs text-left-gcs-sm text-left-gcs-md text-left-gcs-lg" )}>
                          {callToActionLeft}
                        </div>
                    </div>
              </div>
            </section>


            )
};

HomeSplashCallToActionPlaceholder.propTypes = {
  onGetScoreClick: PropTypes.func.isRequired,
  textVariants: PropTypes.object.isRequired
};

export const HomeSplashSection = ({children, menuItemList, textVariants, cssClass="homesplash", user = null}) => {
  return (
    <>
      
      <div className="background-hero">
      <span id="dashboard-menu"></span>
        <Menu menuItemList={menuItemList} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" mainClass="header-home" user={user} renderMobile={true}/>
        {/* {children} */}

        <section className="hero-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 text-wrapper">
                <h1>Get Your Credit Score {/*and Report */}<br />{/*Summary*/}It's FREE!</h1>
                <p>Being aware of your credit score may allow you to negotiate <br/>a better rate with some lenders.</p>
                <a href={RoutesVariables.RouteCreditScore} className="btn btn-primary">Get My Score</a>
                <span>or</span>
                <a href={RoutesVariables.RouteLearnMore} className="link">Learn more</a>
              </div>
              <div className="col-lg-6 col-12 picture-column">
                <div className="margin-pic">
                  <img className="img-fluid" src={ImagesPath.ImgPictureHome_2x} alt=""/>
                  <a href={RoutesVariables.RouteCreditScore} className="btn btn-primary">Get My Score</a>
                  <span>or</span>
                  <a href={RoutesVariables.RouteLearnMore} className="link">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </>
  );
};









HomeSplashSection.propTypes = {
  children: PropTypes.object.isRequired,
  textVariants: PropTypes.object.isRequired
};
