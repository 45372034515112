import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as productActions from '../../redux/actions/productActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import WizardApi from '../../api/WizardApi.js';

import {HomeLoanAssistant} from '../common/Wizard/HomeLoan/HomeLoanAssistant';
import { StepsIndexHLA } from '../common/Wizard/HomeLoan/HomeLoanStepDef';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

const HomeLoansTitle = () => {

  return (
    <section className="home-loans-title-bgnd hidden-xs">
      <div className="container-fluid left-container-padding right-container-padding product-section">
        <div className="row">
          <div className="col-xs-12 text-center ">
              <h1 className="hla-title">Your Home Loan Assistant</h1>
          </div>
         </div>
        </div>
    </section>
 )
 }

class HomeLoanAssistantPage extends React.Component {  
    constructor(props, context) {
        super(props, context);

        this.state = {
          isWizardProfileLoaded : false,
          isWizardProfileLoading : true,
          isServerError: false,
          initialProfile: null,
          nextPage: true
        };

        this.redirect = this.redirect.bind(this);

        this.onSaveStep = this.onSaveStep.bind(this);
        this.onCustomAction = this.onCustomAction.bind(this);
    }

    componentDidMount() {
       this.props.actions.getMenuForPage(pages.DEALS_HOMELOANS);
       
       this.props.actions.getTextVariants();

       this.props.actions.isAuthenticated().then(e => {

            this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
            
            WizardApi.getWizardProfile(this.props.csrfToken, "hla").then( resp => {
                  this.setState({initialProfile: resp.profile, isWizardProfileLoaded: true, isWizardProfileLoading: false ,isServerError: false});
            }).catch( error =>{
                this.setState({isWizardProfileLoaded: true, isWizardProfileLoading: false, isServerError: true});
            });
       });

    }

    componentWillReceiveProps(nextProps) {

      if (!nextProps.isAuthenticated) {
          // go back to login
          //this.props.history.push('/login?retUrl=' + encodeURI('/deals/home-loan/assistant'));
          this.props.history.push(RoutesVariables.RouteLogin);
      }
      else {
        // if(nextProps.userProfile.userDetails) {
        //      let newCustomer = Object.assign({},  nextProps.userProfile.userDetails);
        //       this.setState({customer: newCustomer});
        // }
      }
    }


    redirect(url) {
      this.props.history.push(url);
    }

    onSaveStep(stepState, stepDef){
        var step = Object.assign({}, stepState, {stepCode: stepDef.id});

        
        WizardApi.saveWizardStep(this.props.csrfToken, "hla", step).then(()=> {
            
        }).catch( error =>{

        });
    }

    onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex){
      //this.props.actions.participatedInPromotion();

      // track product click
      var req = Object.assign({}, fullStep.stepDefinition.trackingInfo);
      this.props.actions.trackGcsProductClick(
        req,
        pages.DEALS_HOMELOANS
      );

      if (fullStep.step === StepsIndexHLA.stepIndexBrokerFinish) {
        stepState.selectedItems[0] = true;
      }
    }

    renderFirstPage() {
      return (
        <section className="page-account-delete page-about">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                <div className="images">
                  <img src={ImagesPath.ImgIconEye} className="img-fluid" alt=""/>
                </div>
                <span>Hi</span>
                <h1>{this.props.userProfile ? this.props.userProfile.displayName : ''}</h1>
                <p>Answer for a few question to help us identify which offers <br/>are best to you.</p>
                <div className="button">
                  <a className="btn btn-primary full-width" onClick={()=>{this.nextPage()}}>Let's start</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

    nextPage() {
      this.setState({nextPage:true})
    }

    render() {

      let mainComponent = <div></div>;
      let pageContent = null;

      if (this.state.isServerError) {
        pageContent = <ApiFailedToLoadComponentWithText text="<h2>Sorry!</h2><p>We are not able to retrieve your profile at the moment. Please <a href='/contact-us'>contact us</a></p>" />;
      }
      else if(this.state.isWizardProfileLoading){
        pageContent =
                <LoadingIndicator
                  textPosition='bottom'
                  text={this.props.textVariants[pages.DEALS_HOMELOANS + this.props.textVariants.textVariantSeparator + "loading_text"]}
                  size='200' />;
      }
      else if(this.state.isWizardProfileLoaded) {
        if(this.state.nextPage){
        pageContent = <HomeLoanAssistant
            actions={this.props.actions}
            initialProfile={this.state.initialProfile}
            identifier={this.props.identifier}
            onCustomAction={this.onCustomAction}
            onSaveStep={this.onSaveStep}
         />;
        }
        else {
          pageContent = this.renderFirstPage();
        }
      }
      else{
        pageContent = <div>
            </div>;
      }

      if(this.props.textVariants){
         mainComponent = <div className="home-loan-assistant ">

         <Menu menuItemList={this.props.menuItemList} />

         <HomeLoansTitle textVariants={this.props.textVariants} />

         {/* <GoToPreviousPage textVariants={this.props.textVariants} /> */}
         <div className="top-shadow"></div>
         {pageContent}

       </div>
     }

        //return ( mainComponent );

        return (
          <div className="page-template-page-about bg-circle">
          <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>
          {pageContent}
          <FooterSmallSection></FooterSmallSection>
        </div>
        );
    }
}

HomeLoanAssistantPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.DEALS_HOMELOANS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.DEALS_HOMELOANS];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  //  let products = [];
  //  if(state.productResult && state.productResult.products){
   //
  //    let p = null;
  //    for(let i = 0; i < state.productResult.products.length; i++) {
  //        if(!(p = state.productResult.products[i]).id.startsWith("homeloan")){
  //          products.push(p);
  //       }
  //    }
  //  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    csrfToken: state.userProfile.csrfToken,
    identifier: state.userProfile.identifier
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, userActions, layoutActions, trackingActions, productActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoanAssistantPage);
