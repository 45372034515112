export function createMarkup(txt) {
    return {__html: txt};
};

export function hasLocalStorage(){

    var mod = 't';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch(e) {
        return false;
    }
}

export function scrollIntoView(elementId){
  let element = document.getElementById(elementId);
  if(element){ element.scrollIntoView({behavior: "smooth"})};
}

export function forceScrollFromUrl(){
  let i = 0;

  if((i = window.location.href.indexOf('#')) >= 0){
    i++;
    let scrollElementId = window.location.href.substr(i, window.location.href.length - i);
    scrollIntoView(scrollElementId);
  }
}

export function isMobileDevice() {

 return ( navigator.userAgent.match(/Android/i)
 || navigator.userAgent.match(/webOS/i)
 || navigator.userAgent.match(/iPhone/i)
 || navigator.userAgent.match(/iPad/i)
 || navigator.userAgent.match(/iPod/i)
 || navigator.userAgent.match(/BlackBerry/i)
 || navigator.userAgent.match(/Windows Phone/i)
 );

}

export function isAppleDevice() {
  return (
    navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
  );
}

export function searchInList(list, keyName, searchId){
  var item = null;
  if(list && list["length"]){
      for(var i = 0; i < list.length && item == null; i++){
        var searchItem = list[i];
        if(searchItem[keyName] == searchId){
            item= searchItem;
        }
      }
  }

  return item;
}
