import LayoutApi from '../../api/mockLayoutApi';
import ArticlesApi from '../../api/mockArticlesApi.js'
import ReportApi from '../../api/reportApi.js'
import * as types from './actionTypes';
import {beginAjaxCall, apiFail} from './ajaxStatusActions';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as trackingActions from './trackingActions';


export function resetReportProfile() {
  return {type: types.RESET_REPORT_PROFILE};
}

export function ajaxLoadReport(loading) {
  return {type: types.AJAX_LOAD_REPORT, loading};
}

// export function loadScoreProfileSuccess(scoreProfile, isNoRecords, isInProgress, isTryingCountReached, options) {
//   return {type: types.LOAD_SCORE_PROFILE_SUCCESS, scoreProfile, isNoRecords, isInProgress, isTryingCountReached, options};
// }


export function loadReportProfileSuccess(reportInfo, options) {
  return {type: types.LOAD_REPORT_PROFILE_SUCCESS, reportInfo, options};
}

export function updateDisplayName(displayName){
  return {type: types.USER_PROFILE_DISPLAYNAME, displayName};
}


export function loadReportProfile(options = {}, forced=false, retry = false) {
  return (dispatch, getState) => {

    let state = getState();
    let reportProfileState = state.reportProfile;

    if(!forced){

      if(shouldNotLoadReportProfile(reportProfileState, options) || (reportProfileState.tryingGetReportCount > reportProfileState.tryingGetReportMax)){

          if(reportProfileState.tryingGetReportCount > reportProfileState.tryingGetReportMax){              
              dispatch(loadReportProfileSuccess(reportProfileState.reportInfo, options));
          }

        dispatch(trackingActions.trackReport(false));
        return Promise.resolve(reportProfileState);
      }
    }


    dispatch(ajaxLoadReport(true));
    options.retry = retry;
    return ReportApi.getReportProfile(state.userProfile.csrfToken, options).then(reportInfo => {
            
        dispatch(loadReportProfileSuccess(reportInfo, options));
        dispatch(updateDisplayName(reportInfo["displayName"]));
        dispatch(trackingActions.trackReport(true));

        return Promise.resolve(reportInfo);
    }).catch(error => {

      if(error.status == 417){
        // keep trying
        //dispatch(loadScoreProfileSuccess(null, true, options));

        return new Promise((resolve, reject) => {
            setTimeout(() => {

                resolve(dispatch(loadReportProfile(options, false, true)));
            }, GlobalVariables.SCORE_RETRY_TIMEOUT);
        });
      }
      else if(error.status == 409) {                
          dispatch(apiFail("ReportApi", "EADown"));          
          return Promise.resolve();
      }
      else {        
          dispatch(apiFail("ReportApi", "reportProfile"));
          //dispatch(ajaxLoadScore(false));
          return Promise.resolve();
      }

    });
  };
}

function shouldNotLoadReportProfile(reportProfileState, options){

   var shouldLoad = true;
   var reportInfo = reportProfileState && reportProfileState.reportInfo
    if(reportInfo){    
    shouldLoad = !((reportInfo.enquiries && reportInfo.enquiries.length > 0) ||
    (reportInfo.accounts && reportInfo.accounts.length > 0) ||
    (reportInfo.courtActions && reportInfo.courtActions.length > 0));

  }
  return !shouldLoad;
}
