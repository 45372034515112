import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from '../login/LoginBoxMessage';
import GcsRecaptcha from "../common/GcsRecaptcha";

import * as pages from '../shared/pages';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import {RegistrationTitleSectionBlue} from './RegistrationTitleSection.js';
import {PromotionBanner} from '../sections/PromotionBanner';
import RegistrationForm from './RegistrationForm';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import TrustPilotSection from './../sections/TrustPilotSection';

import FooterSmallSection from '../common/footerSmall';

import * as RoutesVariables from '../../config/routesVariables';

import ValidationApi from '../../api/validationApi.js';
import UserApi from '../../api/mockUserApi.js';
import HelperApi from '../../api/HelperApi.js';

class RegistrationPage extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = {
          customer:  {  
            dob: { dd: '', mm: '', yy: '' },
            address:'',
            addressPrevious: '',
            hasLivedLessThan3Years: false,
            origin: '',
            eqfxmarketing: true
          },
          errors: {dob: { dd: '', mm: '', yy: '' }},          
          saving: false,
          moveToEmail: false,
          resetRecaptcha: false        
          //trustPilotSectionTheme: "trust-pilot-section-theme-white trust-pilot-section-theme-default"
      };

      this.onSaveCustomer = this.onSaveCustomer.bind(this);
      this.submitCustomer = this.submitCustomer.bind(this);
      this.updateCustomerState = this.updateCustomerState.bind(this);
      this.updateCustomerServiceAddressError = this.updateCustomerServiceAddressError.bind(this);
      this.saveCustomerFromState = this.saveCustomerFromState.bind(this);
      this.onRegistrationComplete = this.onRegistrationComplete.bind(this);
      this.didMonuntSetPreviousCustomer = this.didMonuntSetPreviousCustomer.bind(this);
      this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
      this.doResetRecaptcha = this.doResetRecaptcha.bind(this);      

      this.recaptchaRef = React.createRef();     
  }

  componentDidMount() {
    this.props.actions.logout();
    this.props.actions.getMenuForPage(pages.REGISTER);
    this.props.actions.getTextVariants();
    this.props.actions.initUser();
    
    this.doResetRecaptcha();

    let newCustomer = this.state.customer;    
    this.setState({customer: newCustomer});

    // Set the loaded user details    
    this.didMonuntSetPreviousCustomer();
  }  

  onSelectRecaptcha(token){
   
    if (token) {
      this.submitCustomer(token);
    }      

    this.setState({resetRecaptcha: true});
  }

  doResetRecaptcha(){
    if (this.state.resetRecaptcha) {
      try{
        this.recaptchaRef.current.reset();
      }
      catch(ex){
        console.log(ex);
      }
      this.setState({resetRecaptcha: false});
    }
  }

  didMonuntSetPreviousCustomer(){
    if( this.props.userProfile && this.props.userProfile.userDetails){
     
      var ud = this.props.userProfile.userDetails;
      ud.password = '';
      if(ud.gender != null ){
        ud.gender = ud.gender ? 'M':'F'
      } else {ud.gender = 'O'}

      var moveToEmail = parse(this.props.location.search)['b'] == 't'

      this.setState({customer: ud, moveToEmail});                             
    }
  }

  componentWillReceiveProps(nextProps) {   
     if (nextProps.isAuthenticated) {
         this.props.history.push(RoutesVariables.RouteCreditScore);          
     }
     this.doResetRecaptcha();  
  }  

  onRegistrationComplete(){
    this.props.history.push('/');
    return false;
  }  
  
  onSaveCustomer(event) {
    
    event.preventDefault();
    const that = this;

    this.setState({saving : true});

    let validationResult = ValidationApi.validateCustomer(this.state.customer);

      if(validationResult.isValid){
         
        this.recaptchaRef.current.execute();
         //this.submitCustomer();

      } else {
        let newCustomer = this.state.customer;
        newCustomer.isCurrentEmailValid = validationResult.isCurrentEmailValid;
        newCustomer.isCurrentMobileValid = validationResult.isCurrentMobileValid;

        this.setState({customer: newCustomer, errors: validationResult.errors, saving : false});
      }
  }

  submitCustomer(recaptchaToken){
    this.saveCustomerFromState(this.state.customer, recaptchaToken).then(r => {          
        this.props.history.push(RoutesVariables.RouteCreateAccountVerifyEmail);          
      }).catch(e => {
        
        let errors = this.state.errors;
        let msg =  null;

        if (e.status == 409){
          msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "409_startAgain"];

        } else if (e.status == 300) {
          msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "300_userNotVerified"];

        }
        else if (e.status == 302) {
          if(this.state.moveToEmail){
            this.props.history.push(RoutesVariables.RouteCreateAccountVerifyEmail);          
          } else{
            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "302_userExists"];
            var that = this;
              setTimeout(() => {
                that.props.history.push(RoutesVariables.RouteCreateAccountVerifyEmail);
              }, 10000);
          }

        } else if (e.status == 400) {
          e.json().then(function(response) {
            errors.hasError = true;

            response.forEach(error => {
              errors[error.field] = error.message;
            });

            if(response.length > 0){
              errors.firstOne = response[0].field;
            }

            if(response.errorMessage){
              msg = response.errorMessage;
            }

            that.setState({errors: errors, saving : false});
          });

        } else if (e.status == 500) {
          msg = this.props.textVariants["Api_Generic"];

        }

        if(!msg){
          msg = e.message
        }

        errors["global"] = msg;
        this.setState({errors: errors, saving : false});
      });
  }

  saveCustomerFromState(customer, recaptchaToken){
    var registeredCustomer = Object.assign({}, customer);
    
    HelperApi.setInnerDateOfBirthAndDobFromNumbers(registeredCustomer, customer.dob.yy, customer.dob.mm, customer.dob.dd);
    
    registeredCustomer.gender = null;
    if (customer.gender && customer.gender != 'O'){
      registeredCustomer.gender = customer.gender == 'M';
    }        
    
    return this.props.actions.registerFull(registeredCustomer, recaptchaToken);
  }

  updateCustomerState(event) {

      const field = event.target.name;

      let customer = this.state.customer;
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      customer[field] = value;

      let errors = this.state.errors;
      errors[field] = field == 'dob' ? {} : '';

      if(field == 'email'){
        customer.isCurrentEmailValid = false;
      }

      if(field == 'mobile'){
        customer.isCurrentMobileValid = false;
      }    

      return this.setState({ customer: customer, errors:errors });
  }

  updateCustomerServiceAddressError(event){

    const field = event.target.name;
    let errors = this.state.errors;
    if (event.target.status && event.target.status == 401) {
      //console.log("external service error code="+event.target.status);
    }
    errors[field] = this.props.textVariants["Error_cant_find_address"];

    return this.setState({ errors:errors });
  }

  render() {       
    return (        
      <div>
            <GcsRecaptcha
              recaptchaRef={this.recaptchaRef}
              onSelectRecaptcha={this.onSelectRecaptcha}
            />
            <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />           
            <RegistrationForm
              onSave={this.onSaveCustomer}
              onChange={this.updateCustomerState}
              errors={this.state.errors}
              saving={this.state.saving}
              customer={this.state.customer}
              onServiceError={this.updateCustomerServiceAddressError}
              textVariants={this.props.textVariants}
              saveText={this.props.textVariants["Btn_GetMyScoreNow"]}
            />    

           <FooterSmallSection />
        </div>
    );
  }
}

RegistrationPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.REGISTER]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.REGISTER];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,    
    isAuthenticated: state.userProfile.isAuthenticated,
    isTrustPilotEnabled: state.userProfile.isTrustPilotEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
