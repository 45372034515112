import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as funcs from '../../shared/functions';

import Menu from  '../../menu/Menu.js';
import * as pages from '../../shared/pages';

import * as layoutActions from '../../../redux/actions/layoutActions';
import * as userActions from '../../../redux/actions/userActions';

import ValidationApi from '../../../api/validationApi.js';
import UserApi from '../../../api/mockUserApi.js';
import HelperApi from '../../../api/HelperApi.js';

import LoadingIndicator from '../controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../ApiFailedToLoadComponent';
import LoginTermsAndConditions from '../../login/LoginTermsAndConditions';

import * as GlobalVariables from '../../../config/configureGlobalVariables';

import WizardBox from './WizardCoreComponents/WizardBox';
import * as RoutesVariables from '../../../config/routesVariables';

import {initTonikWizard,refreshTonikWizard} from '../../../tonik/wizard';

export class WizardCoreClass extends React.Component {

  searchItem (wizardStepsDef, searchField, searchValue) {
    let item = null;

    if (wizardStepsDef && searchField) {
      let i = 0;
      let found = false;

      for (i = 0; i < wizardStepsDef.length && !found; i++) {
        if (wizardStepsDef[i][searchField] == searchValue) {
          found = true;
          item = wizardStepsDef[i];
        }
      }
    }

    return item;
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      wizardStepState : this._getInitialState(),
      wizardStepIndex: this._getStepIndex(),
      wizardStepsDef: this._getStepDefinition(),
      wizardStepComponents: this._getWizardComponents(),
      wizardCurrentStep: 0,
      wizardPreviousStep: null,
      wizardErrorMessage: '',
      wizardWarningMessageList: null,

      innerErrorMessage: 'Please select an option to continue',
      initialProfileLoaded: false,
      isMobile: false
    };

    this.theme = this._getTheme();

    this.searchItem = this.searchItem.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onMoveStep = this.onMoveStep.bind(this);
    this.isStepCompleted = this.isStepCompleted.bind(this);
    this.onCustomAction = this.onCustomAction.bind(this);
    this.refreshNavigationFlow = this.refreshNavigationFlow.bind(this);
    this._trackViewStep = this._trackViewStep.bind(this);
    this._getInitialState = this._getInitialState.bind(this);
    this._refreshNavigationFlow = this._refreshNavigationFlow.bind(this);
    this._getStepDefinition = this._getStepDefinition.bind(this);
    this._getWizardComponents = this._getWizardComponents.bind(this);
    this._getStepIndex = this._getStepIndex.bind(this);
    this._getTheme = this._getTheme.bind(this);
    this._setGlobalErrorList = this._setGlobalErrorList.bind(this);
    this._onSaveStep = this._onSaveStep.bind(this);
    this._onCustomAction = this._onCustomAction.bind(this);
    this._loadExtraConfiguration = this._loadExtraConfiguration.bind(this);
    this._getGeneralRuleValidator = this._getGeneralRuleValidator.bind(this);
  }

  _getInitialState(){
    return null;
  }

  _getTheme(){
    return null;
  }

  _getWizardComponents(){
    return null;
  }

  _getStepIndex(){
    return null;
  }

  _getStepDefinition(){
    return null;
  }

  _setGlobalErrorList(list){
    this.setState({wizardWarningMessageList: list});
  }

  _onSaveStep(savedStateStep, stepDefinition){
  }

  _onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex){
  }

  _trackViewStep(actionStr, stepDefinition, currentStep){
  }

  _refreshNavigationFlow(wizardStepIndex, stepsState){
  }

  _loadExtraConfiguration(wizardState, extraConfig){
  }

  _getGeneralRuleValidator(stepsState){
    return null;
  }

  componentDidMount() {
      this.setInitialState();
      window.scrollTo(0,0);
      //window['initAppJs'](true);
      initTonikWizard();
  }

  componentWillReceiveProps(nextProps) {
  }

  componentDidUpdate() {
    // window.scrollTo(0,0);
    // if(this.state.isMobile){
    //   window.scrollTo(0,0);
    //   window.scrollBy(0,71);
    // }
  }



  render() {
      return (
        <div className="wizard-container ">
          <WizardBox
              wizardStepComponents={this.state.wizardStepComponents}
              wizardStepIndex={this.state.wizardStepIndex}
              wizardStepState={this.state.wizardStepState}
              wizardStepsDef={this.state.wizardStepsDef}
              wizardErrorMessage={this.state.wizardErrorMessage}
              wizardWarningMessageList={this.state.wizardWarningMessageList}
              onUpdate={this.onUpdate}
              onCustomAction={this.onCustomAction}
              onMoveStep={this.onMoveStep}
              currentStep={this.state.wizardCurrentStep}
              previousStep={this.state.wizardPreviousStep}
              identifier={this.props.identifier}
              theme={this.theme}
            />
        </div>
      );
  }

  onCustomAction(step, stepState) {
      // Save the participation
      this._onCustomAction(step, stepState, this.state.wizardStepsDef, this.state.wizardStepState, this.state.wizardCurrentStep);
      this._trackViewStep('View-Custom-Action', this.state.wizardStepsDef[this.state.wizardCurrentStep]["id"], step["step"]);

      // Change final step
      var newStepState = Object.assign({}, this.state.wizardStepState);
      //newStepState.promotionEnabled = false;
      this.refreshNavigationFlow(newStepState);// Is this necessary?
      this.setState({wizardStepState: newStepState});
      this.onMoveStep(true);
  }

  isStepCompleted(stepDef, stepState, stepIndex){
    let errorMessage = null;

    if(!stepDef["isOptionalStep"]){

      if (stepState["selectedTexts"] && stepDef["stepDefinition"] && stepDef["stepDefinition"]["inputs"] && stepDef["stepDefinition"]["inputs"].length > 0) {
        let isEmpty = false;
        var inputs = stepDef["stepDefinition"]["inputs"];

        for (let i = 0; i < inputs.length && !isEmpty; i++) {
          if(!stepState.selectedTexts[i]){
            isEmpty = true;
          }
        }

        if (isEmpty) {
          errorMessage = "Please fill the textbox";
        }
      } else if (stepState["selectedItems"] && stepState["selectedItems"].length > 0) { // it should be
        //let stepDef = wizardStepsDef[stepIndex];
        // needs to have at least one selected item and if it is the extra, the text not empty
        let selItemIndex = 0;
        let found = false;

        for (selItemIndex = 0; selItemIndex < stepState.selectedItems.length && !found; selItemIndex++) {
          if(stepState.selectedItems[selItemIndex]){
            found = true;
          }
        }

        if (!found) {
          errorMessage = "Please select an option to continue";
        } else {
          // check the text as Well
          if(!stepDef["stepExtraDisabled"]){
            if(stepState.selectedItems[stepDef["selectedExtraIndex"]]){
              // Must have text completed
              if(!stepState["selectedExtraText"]){
                errorMessage = "Please fill the textbox";
              }
            }
          }
        }
      }
    }

    return errorMessage;
  }

  refreshNavigationFlow(stepsState){
    let wizardStepIndex = this._getStepIndex();
    this._refreshNavigationFlow(wizardStepIndex, stepsState);
  }

  onUpdate(stepId, stepIndex, actionId, extraValue, isEnterPressed, inputKeyValueArray){ // inputKey, inputValue){
    
    var shouldMoveStep = false;
    var savedState = Object.assign({}, this.state.wizardStepState);
    var savedStateStep = savedState.steps[stepIndex];

    // savedState.steps = [...savedState.steps];
    // var savedStateStep = Object.assign({}, savedState.steps[stepIndex]);
    // savedState.steps[stepIndex] = savedStateStep;
    // savedStateStep["selectedItems"] = [...savedStateStep["selectedItems"]];
    // savedStateStep["selectedTexts"] = [...savedStateStep["selectedTexts"]];

    savedStateStep.errorMessage = "";

    var stepDef = this.state.wizardStepsDef[stepIndex];

    if (actionId == 'e' && extraValue != savedStateStep.selectedExtraText) {
        // Update text
        savedStateStep.selectedExtraText = extraValue;
        savedStateStep.isDirty = true;
    } else if (actionId == 'keyvalue' && inputKeyValueArray) {
      for (let kvIndex = 0; kvIndex < inputKeyValueArray.length; kvIndex++) {
        if (savedStateStep["selectedTexts"][inputKeyValueArray[kvIndex]["inputKey"]] != inputKeyValueArray[kvIndex]["inputValue"]) {
          savedStateStep["selectedTexts"][inputKeyValueArray[kvIndex]["inputKey"]] = inputKeyValueArray[kvIndex]["inputValue"];
          savedStateStep.isDirty = true;
        }
      }
      // }
      // else if(actionId == 'amount' && extraValue != savedStateStep.selectedExtraText){
      //    savedStateStep.selectedExtraText = extraValue;
    } else {
      savedStateStep.isDirty = true;
      if(stepDef["stepSelectionnUnique"]){
        // Clean other selections
        let i = 0;

        for (i = 0; i < savedStateStep.selectedItems.length; i++) {
          if ( i != actionId) {
            if (savedStateStep.selectedItems[i]) {
              // track unselect
              //this.props.actions.trackAboutMe('Unselect', stepId, actionId);
            }

            savedStateStep.selectedItems[i] = false;
          }
        }
      }

      // Select value
      if (savedStateStep.selectedItems.length > 0) {
        //this.props.actions.trackAboutMe(savedStateStep.selectedItems[actionId] ? 'Unselect':'Select', stepId, actionId);
        if (stepDef["stepShouldAlwaysSelect"]) {
          savedStateStep.selectedItems[actionId] = true;
        } else {
          savedStateStep.selectedItems[actionId] = !savedStateStep.selectedItems[actionId];
        }
      }

      
    }

    // Run validation and complete step
    let errorMessage =  this.isStepCompleted(stepDef, savedStateStep, stepIndex);

    if (!errorMessage) {
      savedStateStep.isCompleted = true;
      errorMessage = 'Please select an option to continue';

      if (stepDef["shouldMoveOnAction"]) {
        shouldMoveStep = true;
      }

      if (isEnterPressed) {
        shouldMoveStep = true;
      }
    } else {
      savedStateStep.isCompleted = false;
    }

    // Manage with states are enabled
    this.refreshNavigationFlow(savedState);

    this.setState({wizardStepState : savedState, wizardErrorMessage: '', innerErrorMessage: errorMessage}, () => {
      if(shouldMoveStep){
        this.onMoveStep(true);
      }
    });
  }

  onMoveStep(moveForward, actionText) {

    if (actionText == 'done') {
      //this.props.history.push('/'); // Action => go back home
      window.location.href = RoutesVariables.RouteHome;

    } else {
      const savedState = Object.assign({}, this.state.wizardStepState);
      const savedStateStep = savedState.steps[this.state.wizardCurrentStep];
      //const currentStepIndex = this.state.wizardCurrentStep;
      const currentStepState = this.state.wizardStepState.steps[this.state.wizardCurrentStep];

      const generalValidatorFunction = this._getGeneralRuleValidator(this.state.wizardStepState);

      if (this.state.wizardWarningMessageList) {
          let globalWarningList = null;
          if(this.state.wizardWarningMessageList.length > 0){
              if(generalValidatorFunction){
                  globalWarningList = generalValidatorFunction(currentStepState, this.state.wizardStepState.steps, this.state.wizardStepsDef).errorList;
              }
          }

          this._setGlobalErrorList(globalWarningList);
      }

      if (moveForward) {
        let stepDef = this.state.wizardStepsDef[this.state.wizardCurrentStep];
        let isStepValid = true;

        if (stepDef.validate) {
            const validationResult = stepDef.validate(currentStepState, this.state.wizardStepState.steps, this.state.wizardStepsDef);
            isStepValid = validationResult.isValid;
            currentStepState.errorMessage = isStepValid ? "" : validationResult.errorMessage;
        }

        const isStepValidated = !!this.state.wizardErrorMessage || isStepValid;

        if (isStepValidated) {
          this.setState({ wizardErrorMessage: "" });
          // Move to an enabled one
          if (savedStateStep.isCompleted || savedStateStep["isWhenShowCompleted"]) {
            let originalCurrent = this.state.wizardCurrentStep;
             let current = this.state.wizardCurrentStep + 1;
             while(current < savedState.steps.length && !savedState.steps[current].enabled){
               current++;
             }

             if(current >= savedState.steps.length){
                current = originalCurrent;
              }

            //  Save current selection
             if(savedStateStep.isDirty){
                 this._onSaveStep(savedStateStep, this.state.wizardStepsDef[originalCurrent]);
                 savedStateStep.isDirty = false;
                 this.setState({wizardStepState : savedState});
             }

            if(this.state.wizardStepsDef[current]["isFinalStep"]){ // this is the first time
              if(generalValidatorFunction){
                let globalWarningList = generalValidatorFunction(currentStepState, this.state.wizardStepState.steps, this.state.wizardStepsDef).errorList;
                this._setGlobalErrorList(globalWarningList);
              }
            }

            if(current != originalCurrent){
                this.setState({wizardCurrentStep : current, wizardPreviousStep: this.state.wizardCurrentStep, wizardErrorMessage: savedState.steps[current].errorMessage });

                // Track in GA
                this._trackViewStep('View Step', this.state.wizardStepsDef[current].id, current);
            }           

          } else {
            this.setState({wizardErrorMessage: this.state.innerErrorMessage});
          }

        } else {
          this.setState({ wizardErrorMessage: currentStepState.errorMessage });
        }
        

      }
      else {
      //   if (currentStepIndex === StepsIndexHLA.stepIndexBrokerFinish) {
      //     currentStepState.selectedItems[0] = false;
      //   }

        let currentIndex = parseInt(this.state.wizardCurrentStep, 10) - 1;

        while (currentIndex >= 0 && !savedState.steps[currentIndex].enabled) {
          currentIndex--;
        }

        if (!currentIndex ||  currentIndex < 0 ) {
          currentIndex = 0;
        }

        if (savedState.steps[currentIndex].enabled) {
          this.setState({
            wizardCurrentStep : currentIndex,
            wizardPreviousStep: this.state.wizardCurrentStep,
            wizardErrorMessage: savedState.steps[currentIndex].errorMessage
          });
          this._trackViewStep('View Step', this.state.wizardStepsDef[currentIndex].id, currentIndex);
        }
      }
    }

    refreshTonikWizard();
    //window['initAppJs'](true);
    
  }


    setInitialState(){
      let wizardState = this.state.wizardStepState;
      const wizardStepsDef = this.state.wizardStepsDef;
      let currentStepIndex = this.state.wizardCurrentStep;
      let onlyOnce = false;

      if(this.props.initialProfile && !this.state.initialProfileLoaded){
        var initProfile = this.props.initialProfile;
        //wizardStepState.promotionEnabled = initProfile.promotionEnabled;

        this.setState({initialProfileLoaded: true});

        if(initProfile.steps && initProfile.steps.length > 0){
          wizardState = Object.assign({}, this.state.wizardStepState);

          // if any extra visibility configuration came from server
          // if(var stepsConfig = initProfile["stepsConfiguration"]){
          //   for(var configIndex = 0; configIndex < stepsConfig.length; configIndex++){
          //     var stepConfigItem = stepsConfig[configIndex];
          //     wizardState.steps[stepConfigItem["stepIndex"]].enabled = stepConfigItem["enabled"];
          //   }
          // }

          // if(initProfile["extra"]){
          //   this._loadExtraConfiguration(wizardState, initProfile["extra"]);
          // }

          for(let i = 0; i < wizardState.steps.length; i++){
            var sStep =  wizardState.steps[i];
            var stepDef = this.state.wizardStepsDef[i];


            var initStep = this.searchItem(initProfile.steps, 'stepCode', stepDef.id);
            if(initStep){
              sStep.isCompleted = initStep.isCompleted;
              // Selected Items
              if(initStep["selectedItems"]){
                  for(let j = 0; j < initStep.selectedItems.length; j++){
                      if(initStep.selectedItems[j]){
                        sStep.selectedItems[j] = true;
                      }
                  }

                  if(initStep["selectedExtraText"]){
                    sStep.selectedExtraText = initStep["selectedExtraText"];
                  }
               }

               if(initStep["selectedTexts"] && sStep["selectedTexts"]){
                   for(let j = 0; j < initStep.selectedTexts.length; j++){
                       if(initStep.selectedTexts[j]){
                         sStep.selectedTexts[j] = initStep.selectedTexts[j];
                       }
                   }
                }
            }
          }
        }
      }

      this.refreshNavigationFlow(this.state.wizardStepState);

      // ensure the first step is enabled
      for(let i = 0; i < wizardState.steps.length && !onlyOnce; i++){
            var sStep2 =  wizardState.steps[i];
            if(!sStep2.enabled && !onlyOnce){
              currentStepIndex++;
            }
            else{
                onlyOnce = true;
            }
      }

      this._trackViewStep('View Step', this.state.wizardStepsDef[this.state.wizardCurrentStep], this.state.wizardCurrentStep);

      // run all def rules
      for (let i = 0; i < wizardState.steps.length; i++) {
        let stepState = wizardState.steps[i];
        let stepDef = wizardStepsDef[i];

        if (stepState.enabled && stepDef.validate) {
          const validationResult = stepDef.validate(stepState, wizardStepsDef);

          if (!validationResult.isValid) {
            stepState.errorMessage = validationResult.errorMessage;
          }
        }
      }

      this.setState ({wizardStepState: wizardState, wizardCurrentStep: currentStepIndex, isMobile: funcs.isMobileDevice()});

      return wizardState;
    }
}

WizardCoreClass.contextTypes = {
  router: PropTypes.object
};