import $ from 'jquery'
import 'bootstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import slick from 'slick-carousel-latest'
import {tonikInitScrollWithAnimation, tonikInitScrollWithAnimationToId} from './shared/scrollWithAnimation';


export const tonikInitCarousel = (selector, callBack) => {
  
  // slider dashboard
  var initCorrectly = true;
try{
  if(!selector){
    selector = '.slider-carousel';
  }  
  if($(selector).length > 0){

      $(selector).slick({
        dots: true,
        arrows: true,
        prevArrow: $('.next-step-prev'),
        nextArrow: $('.next-step-next'),
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000
      })

      $(selector).on('afterChange', function(event, slick, currentSlide){
        if(callBack){          
          callBack(event, currentSlide)
        }
        
      });
  }
}catch(e){
  initCorrectly = false;
  console.log('tonikInitCarousel', e)
  // this fails in the component did mount and breaks everything if not in a try
}

  return initCorrectly;
}


export const tonikInitDashboard = () => {


  tonikInitScrollWithAnimation();
  tonikInitScrollWithAnimationToId();

  
// bootstrap tooltip initialize
$('.info-tooltip').tooltip()

// Dashboard modal
$('.carousel').on('slide.bs.carousel', function (e) {
  $(this).find('.carousel-inner').animate({
    height: $(e.relatedTarget).height()
  }, 300)
})

// Alerts - dashboard
$('.alerts-sidebar.open').on('click', '.header-aside a.close', function (e) {
  e.preventDefault()
  $('.alerts-sidebar').removeClass('open')
})

$('.alerts-sidebar.open').on('click', '.header-aside a.close', function (e) {
  e.preventDefault()
  $('.sidebar-overlay').removeClass('open')
})

if ($('.content-aside').length > 0) {
  const ps = new PerfectScrollbar('.content-aside > div')
}

// Alerts - dashboard end

// dashboard gauge score
//$(window).on('load', function () {
  $('.gauge-area').addClass('active')
//})

//tonikInitCarousel('#product-carousel');

return;
}
