import $ from 'jquery'


export const refreshTonikWizard = () => {

    $('.form-other').on('click', function () {
        $(this).addClass('focus')
        if (!$(this).hasClass('other-checkbox')) {
            $('.form-check .input-radio').prop('checked', false)
        }
    })

    return;
}
    
export const initTonikWizard = () =>{

    $(document).on('click', function (e) {
        if (!$('.form-other').is(e.target) && $('.form-other').has(e.target).length === 0) {
          $('.form-other').removeClass('focus')
        }
      });

    refreshTonikWizard();

return;
}
