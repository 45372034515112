import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';

import {
products,
productTypes
} from '../components/shared/staticProducts';

import {
creditCards
} from '../components/shared/staticProductsCreditCards';

import * as ImagesPath from '../config/imagesPath';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.


////This would be performed on the server in a real app. Just stubbing in.
//const generateId = (author) => {
//    return author.firstName.toLowerCase() + '-' + author.lastName.toLowerCase();
//};

class ProductApi {

    static getProductTypes(userProfile, scoreProfile, requestProductType, initialCollection) {

          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/product/init';
          let csrfToken = userProfile.csrfToken;

          let headers = {};
          headers['Content-Type'] = 'application/json';
          if(csrfToken){
            headers['x-gcs-xsrf'] = csrfToken;
          }     
          
          let options = {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              headers: headers,
              body: JSON.stringify(requestProductType)
          };

      return fetch(fullUrl, options).then(response =>
        {          
                if (!response.ok) {
                  return Promise.reject(response);
                }
                else{
                    let productConfigJson = response.json();

                    return productConfigJson.then( productConfig => {    
                                            
                      // transform Products into previous format
                      let productResult = [];
                      let hiddenProductsResult = [];

                      for(var i = 0; i < productConfig.productConfiguration.length; i++){
                          var prodMaster = productConfig.productConfiguration[i];                                                   
                          //add to array
                          var prodItem = ProductApi.getProductFromApiProduct(prodMaster);
                          
                          if(prodItem.show){
                            productResult.push(prodItem);
                          }
                          else{
                            hiddenProductsResult.push(prodItem);
                          }
                      }
                      
                      // Copy the next steps in this one
                      let nextSteps = [];                      
                      var stepNumber = 1;
                      for(var j = 0; j < productConfig.call2Actions.length; j++) {
                        var callToAction = productConfig.call2Actions[j];
                        var nextStep = ProductApi.getNextStepFromApiNextStep(callToAction);                        
                        if(nextStep){
                          nextStep.step = stepNumber++;
                          nextSteps.push(nextStep);
                        }                        
                      }
                      
                      // add other products to provide other choices
                      var productsByType = [];
                      if(productConfig.productsByType){

                          for(var ind = 0; ind < productConfig.productsByType.length; ind++){
                            var typeList = productConfig.productsByType[ind];
                            if(typeList && typeList.products && typeList.products.length > 0){

                              var prodTypeKey = "pt_" + typeList.productTypeId;
                              productsByType[prodTypeKey] = []; // Initialize final object
                              for(var pi = 0; pi < typeList.products.length; pi++ ){

                                  var prodApi = typeList.products[pi];
                                                                    
                                  if(prodApi){                                    

                                    var prodExtraFinal = ProductApi.getProductFromApiProduct(prodApi);
                                    if(prodExtraFinal){
                                      productsByType[prodTypeKey].push(prodExtraFinal);
                                    }                                                                        
                                  }
                              }                             
                            }                                                            
                          }
                      }
                      
                      let responseObj =
                       {
                          isAnonymous: productConfig.isAnonymous,
                          userId: productConfig.userId,
                          products: productResult,
                          hiddenProducts: hiddenProductsResult,
                          partnerId: productConfig.partnerId,
                          productsCall2Action: productConfig.productConfigurationCall2Action,
                          call2Actions: nextSteps,
                          productsByType: productsByType
                       };                       
                        
                       return responseObj;

                    });
                }
        }
        ).catch(function (error) {

          throw(error);
        });
     }

      
     static getProductFromApiCommon(prodMaster){
      
        var prodItem = null;
        if(prodMaster){

          prodItem = {};
          // Main properties
          prodItem.show = prodMaster.show;
          if((prodMaster.productTypeId == productTypes.homeLoanAssistant) || (prodMaster.productTypeId == productTypes.homeLoanAssistantExpert)){
            prodItem.show = false;
          }                            
          prodItem.id = prodMaster.productIdentifier;
          prodItem.productId = prodMaster.productId;
          prodItem.productTypeId = prodMaster.productTypeId;
          prodItem.partnerId = prodMaster.partnerId;
          prodItem.linkRef = prodMaster.linkRef;
          prodItem.href = prodMaster.href;
          prodItem.actionRoute = prodMaster.actionRoute;
          prodItem.hasPushLead = prodMaster.hasPushLead;
          prodItem.hasHandshake = prodMaster.hasHandshake;
          prodItem.controlType = prodMaster.controlType;
          prodItem.altProductIdentifier = prodMaster.altProductIdentifier;                            
          
          // Display
          prodItem.productDisplayMain = prodMaster.productDisplayMain;
          prodItem.productDisplayNextStep = prodMaster.productDisplayNextStep;
          prodItem.productDisplayHandshake = prodMaster.productDisplayHandshake;
          prodItem.productDisplayWizard = prodMaster.productDisplayWizard;
          prodItem.productDisplayList = prodMaster.productDisplayList;
                                                  
        }

        return prodItem;
     }
     
     
     static getProductFromApiProduct(prodMaster){
        
        var prodItem = null;
        if(prodMaster){

          prodItem = ProductApi.getProductFromApiCommon(prodMaster);         
             
          if(prodItem){

                if(prodItem.productDisplayList){
                    
                  prodItem.partnerLogoImgUrl =  prodItem.productDisplayList.imgUrl;
                  prodItem.partnerTitle =  prodItem.productDisplayList.title;
                  prodItem.partnerDescription =  prodItem.productDisplayList.description;                                             
                }   

                if(prodItem.productDisplayMain){
                  
                  prodItem.imgUrl = prodMaster.productDisplayMain.imgUrl2 ? prodMaster.productDisplayMain.imgUrl2 : ProductApi.getDefaultBadImageForProd(prodMaster);
                  prodItem.imgUrl_Sales = prodMaster.productDisplayMain.imgUrl ? prodMaster.productDisplayMain.imgUrl : ProductApi.getDefaultImageForProd(prodMaster);
                  prodItem.title = prodItem.productDisplayMain.title ? prodItem.productDisplayMain.title : ProductApi.getDefaultBadActionBtnTextForProd(prodMaster);
                  prodItem.title2 = prodItem.productDisplayMain.title2 ? prodItem.productDisplayMain.title2 : ProductApi.getDefaultBadActionBtnTextForProd(prodMaster);

                  prodItem.callToAction = {};                              
                  prodItem.callToAction.salesTitle = prodItem.productDisplayMain.actionBtn ?
                    prodItem.productDisplayMain.actionBtn :
                    ProductApi.getDefaultActionBtnTextForProd(prodMaster);
                  prodItem.callToAction.title = prodItem.productDisplayMain.actionBtn2 ?
                    prodItem.productDisplayMain.actionBtn2 :
                    ProductApi.getDefaultBadActionBtnTextForProd(prodMaster);
                  
                  prodItem.features = [];
                  if(prodMaster.productDisplayMain.features){
                    let x = 0;                                
                    prodMaster.productDisplayMain.features.forEach( (item, index) => {
                      prodItem.features.push({ key: ++x, title : item.title, classToAppend: item.classToAppend });    
                    });
                  }                              
                }
                else{
                    let defaultText = ProductApi.getDefaultBadActionBtnTextForProd(prodMaster);
                    let salesText = ProductApi.getDefaultActionBtnTextForProd(prodMaster);
                    prodItem.imgUrl = ProductApi.getDefaultBadImageForProd(prodMaster);
                    prodItem.imgUrl_Sales = prodMaster.imgUrl ? prodMaster.imgUrl : ProductApi.getDefaultImageForProd(prodMaster);
                    prodItem.title = salesText;
                    prodItem.callToAction = { "title": defaultText, "salesTitle": salesText, "action": {} };
                    prodItem.features = ProductApi.getDefaultFeaturesTextForProd(prodMaster);
                }               
            }
         }

        return prodItem;
     }
    
     static getNextStepFromApiNextStep(callToAction){

        var nextStep = null;
        if(callToAction){
          nextStep = {};
          nextStep.id = callToAction.id;
          nextStep.text = callToAction.title;
          nextStep.description = callToAction.description;
          nextStep.actionText = callToAction.actionBtn;
          nextStep.imgUrl = callToAction.imgUrl;
          nextStep.href = callToAction.href;
          nextStep.actionRoute = callToAction.action;

          nextStep.linkTarget = callToAction.linkTarget ? callToAction.linkTarget : '_blank';
          nextStep.trackClick = callToAction.product != null;                        
          
          if(callToAction.product){
            callToAction.product.isNextStep = true;
            nextStep.product = callToAction.product;
            nextStep.product.id = nextStep.product.productIdentifier;
            
            if(nextStep.href){
              nextStep.product.href = nextStep.href;
            }
            else if(nextStep.actionRoute){
              nextStep.product.actionRoute = nextStep.actionRoute;
            }
            
            nextStep.productIdentifier = nextStep.product.productIdentifier;
            nextStep.partnerId = callToAction.product.partnerId;
            nextStep.productTypeId = callToAction.product.productTypeId;
            nextStep.productId = callToAction.product.productId;
          }   
        }
        
        return nextStep;
     }

     static getDefaultImageForProd(prodMaster){
        
      var prodTypeId = prodMaster.productTypeId;

        if(prodTypeId == productTypes.homeloan){
            return ImagesPath.ImgProduct_HomeLoan_Sales;
        }
        else if(prodTypeId == productTypes.carloan){
            return ImagesPath.ImgProduct_AutoLoan_Sales;
        }
        else if(prodTypeId == productTypes.personalloan){
            return ImagesPath.ImgProduct_PersonalLoan_Sales;
        }
        else if(prodTypeId == productTypes.businessloan){
            return ImagesPath.ImgProduct_BusinessLoan_Sales;
        }
        else if(prodTypeId == productTypes.creditcard){
          return ImagesPath.ImgProduct_CC_Sales;
        }      
        else if( (prodTypeId == productTypes.insurance) ||
        (prodTypeId == productTypes.insuranceCar) ||
        (prodTypeId == productTypes.insuranceHome) ||
        (prodTypeId == productTypes.insuranceLife) ||
        (prodTypeId == productTypes.insuranceProtection)    ){
          return ImagesPath.ImgProduct_Insurance_Sales;
        }
        else if(prodTypeId == productTypes.homeLoanAssistant){
          return ImagesPath.ImgProduct_HomeLoan_Sales;
        }    
     } 

     static getDefaultFeaturesTextForProd(prodMaster){
          
          let prodTypeId = prodMaster.productTypeId;
          if(prodTypeId == productTypes.homeloan){
              return [
                        {key:"1", title : "Online approval", classToAppend: "" },
                        {key:"2", title : "1600 loans from 30 lenders", classToAppend: ""},
                        {key:"3", title : "Online chat support", classToAppend: ""},
                        {key:"4", title : "Loan matched to your needs", classToAppend: "hidden-xs"},
                        {key:"5", title : "Loan approval assistance", classToAppend: "hidden-xs"},
                    ];
          }
          else if(prodTypeId == productTypes.carloan){
              return [
                { key:"1", title : "Instant quote", classToAppend: "" },
                { key:"2", title : "Online approval", classToAppend: ""},
                { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
            ];
          }
          else if(prodTypeId == productTypes.personalloan){
              return [
                      { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                      { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                      { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                      { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                      { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                    ];
          }
          else if(prodTypeId == productTypes.businessloan){
              return [
                    { key:"1", title : "A loan for every business type", classToAppend: "" },
                    { key:"2", title : "50+ loans from over 35 lenders", classToAppend: ""},
                    { key:"3", title : "Business credit cards", classToAppend: ""},
                    { key:"4", title : "Compare rates, terms & fees", classToAppend: "hidden-xs"},
                    { key:"5", title : "Dedicated credit specialists", classToAppend: "hidden-xs"},
                   ];
          }
          else if(prodTypeId == productTypes.creditcard){
            return [
                    { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                    { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                    { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                    { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                    { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                  ];
          }
          else if( (prodTypeId == productTypes.insurance) ||
          (prodTypeId == productTypes.insuranceCar) ||
          (prodTypeId == productTypes.insuranceHome) ||
          (prodTypeId == productTypes.insuranceLife) ||
          (prodTypeId == productTypes.insuranceProtection)    ){
            return [
              { key:"1", title : "Car, Home & Life Insurance ", classToAppend: "" },
              { key:"2", title : "Income protection", classToAppend: ""},
              { key:"3", title : "Up to 30% online discount (Home)", classToAppend: ""},
              { key:"4", title : "15% online discount (Car)", classToAppend: "hidden-xs"},
              { key:"5", title : "Get a quote in just minutes", classToAppend: "hidden-xs"},
          ];
          }
          else if(prodTypeId == productTypes.homeLoanAssistant){
            return ImagesPath.ImgProduct_HomeLoan;
          }
     }

     static getDefaultBadImageForProd(prodMaster){
        
         let prodTypeId = prodMaster.productTypeId;

        if(prodTypeId == productTypes.homeloan){
            return ImagesPath.ImgProduct_HomeLoan;
        }
        else if(prodTypeId == productTypes.carloan){
            return ImagesPath.ImgProduct_AutoLoan;
        }
        else if(prodTypeId == productTypes.personalloan){
            return ImagesPath.ImgProduct_PersonalLoan;
        }
        else if(prodTypeId == productTypes.businessloan){
            return ImagesPath.ImgProduct_BusinessLoan;
        }
        else if(prodTypeId == productTypes.creditcard){
          return ImagesPath.ImgProduct_CC;
        }      
        else if( (prodTypeId == productTypes.insurance) ||
        (prodTypeId == productTypes.insuranceCar) ||
        (prodTypeId == productTypes.insuranceHome) ||
        (prodTypeId == productTypes.insuranceLife) ||
        (prodTypeId == productTypes.insuranceProtection)    ){
          return ImagesPath.ImgProduct_Insurance;
        }
        else if(prodTypeId == productTypes.homeLoanAssistant){
          return ImagesPath.ImgProduct_HomeLoan;
        }    

     } 

     static getDefaultBadActionBtnTextForProd(prodMaster){
       return "GET MY DEAL";
     }

    static getDefaultActionBtnTextForProd(prodMaster){
      var prodTypeId = prodMaster.productTypeId;

      if(prodTypeId == productTypes.homeloan){
          return "Home Loans";
      }
      else if(prodTypeId == productTypes.carloan){
        return "Car Loans";
      }
      else if(prodTypeId == productTypes.personalloan){
        return "Personal Loans";
      }
      else if(prodTypeId == productTypes.businessloan){
        return "Business Loans";
      }
      else if(prodTypeId == productTypes.creditcard){
        return "Credit Cards";
      }      
      else if(
          (prodTypeId == productTypes.insurance) ||
          (prodTypeId == productTypes.insuranceCar) ||
          (prodTypeId == productTypes.insuranceHome) ||
          (prodTypeId == productTypes.insuranceLife) ||
          (prodTypeId == productTypes.insuranceProtection)             
      ){
        return "Insurance";
      }
      else if(prodTypeId == productTypes.homeLoanAssistant){
        return "Home Loans";
      }    
     }

    static getProductTypesOld(userProfile, scoreProfile, requestProductType, initialCollection) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/product/init';

      let csrfToken = userProfile.csrfToken;
      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }   
      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(requestProductType)
       };

        return fetch(fullUrl, options).then(response =>
          {
                  if (!response.ok) {
                    return Promise.reject(response);
                  }
                  else{

                      let productConfigJson = response.json();

                      return productConfigJson.then( productConfig => {

                        var productResult = [];

                        for(var i = 0; i < initialCollection.length; i++){
                            var productMasterItem = initialCollection[i];
                            var configItem = ProductApi.findConfigItemById(productConfig.productConfiguration, "productIdentifier", productMasterItem.id);

                            var productResultItem = Object.assign({}, productMasterItem, configItem);

                            if(productResultItem.show){
                                  if(productMasterItem.subProducts){
                                    var subProductResult = [];
                                    for(var j = 0; j < productMasterItem.subProducts.length; j++){
                                        var subProductItem = productMasterItem.subProducts[j];
                                        var subConfigItem = ProductApi.findConfigItemById(productConfig.productConfiguration, "productIdentifier", subProductItem.id);
                                        subProductResult.push( Object.assign({}, subProductItem, subConfigItem));
                                      }

                                      productResultItem.subProducts =   subProductResult;
                                  }

                                  productResult.push( productResultItem );
                          }
                        }

                        // Reset the items if a condition is not met.

                        for(var k = 0; k < productResult.length; k++){
                          var pr = productResult[k];
                          var altItem = '';
                          if(pr["isCustomRuleSatisfied"]){
                            let condition =  pr["isCustomRuleSatisfied"](userProfile, scoreProfile)
                            if(condition && !condition.isSatisfied){

                              var initialItem = ProductApi.findConfigItemById(initialCollection, "id", condition.altId);
                              var apiItem = ProductApi.findConfigItemById(productConfig.productConfiguration, "productIdentifier", condition.altId);
                              if(initialItem && apiItem){
                                    var productResultItem = Object.assign({}, initialItem, apiItem);
                                    productResultItem.show = true;
                                    productResult[k] = productResultItem;
                              }
                            }
                          }
                        }
                        
                        // add other products to provide other choices
                        var productsByType = [];
                        if(productConfig.productsByType){

                            for(var ind = 0; ind < productConfig.productsByType.length; ind++){
                              var typeList = productConfig.productsByType[ind];
                              if(typeList && typeList.products && typeList.products.length > 0){

                                var prodTypeKey = "pt_" + typeList.productTypeId;
                                productsByType[prodTypeKey] = []; // Initialize final object
                                for(var pi = 0; pi < typeList.products.length; pi++ ){

                                    var prodApi = typeList.products[pi];
                                    var prodStatic = ProductApi.findConfigItemById(initialCollection, "id", prodApi.productIdentifier);
                                    if(prodApi && prodStatic){
                                      var prodExtraFinal = Object.assign({}, prodStatic, prodApi);
                                      var addProduct = true;
                                      if(prodExtraFinal["isCustomRuleSatisfied"]){
                                        let condition =  prodExtraFinal["isCustomRuleSatisfied"](userProfile, scoreProfile);
                                        if(condition && !condition.isSatisfied){
                                          addProduct = false;
                                        }
                                      }
                                      
                                      if(addProduct){
                                        productsByType[prodTypeKey].push(prodExtraFinal);
                                      }
                                    }
                                } 
                              
                              }                                                            
                            }
                         }

                        let responseObj =
                         {
                            isAnonymous: productConfig.isAnonymous,
                            userId: productConfig.userId,
                            products: productResult,
                            partnerId: productConfig.partnerId,
                            productsCall2Action: productConfig.productConfigurationCall2Action,
                            productsByType: productsByType
                         };

                         return responseObj;

                      });                      

                  }
          }
          ).catch(function (error) {

            throw(error);
          });
    }



    static findConfigItemById(list, keyName, searchId){
      var item = null;
      for(var i = 0; i < list.length && item == null; i++){
        var searchItem = list[i];
        if(searchItem[keyName] == searchId){
            item= searchItem;
        }
      }

      return item;
    }


    static trackProductClick(trackProductRequest) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/tracking/product-click';

      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: {
            'Content-Type': 'application/json'
            // 'x-gcs-xsrf': csrfToken
          },
           body: JSON.stringify(trackProductRequest)
         }
        
        return fetch(fullUrl, options);
    }

    static trackReferralClick(trackReferrlRequest) {

      //trackReferrlRequest = {tid, userIdentifier}

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/tracking/referral';

      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: {
            'Content-Type': 'application/json'
            // 'x-gcs-xsrf': csrfToken
          },
           body: JSON.stringify(trackReferrlRequest)
         }

        return fetch(fullUrl, options).then(response =>
        {
              if(response.ok){
                return Promise.resolve(response.ok);
              }
              else{
                return Promise.reject(response.ok);
              }
        }).catch(function (error) {
          throw(error);
        });
    }

    static getCreditCards() {
        return Promise.resolve(creditCards);
    }

    static getProductRedirect(csrfToken, product){

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/product/redirect';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }   
      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify({productId: product.id, mode: product.mode})
         }

        return fetch(fullUrl, options).then(response =>
        {
              if(response.ok){
                return response.json();
              }
              else{
                return Promise.reject(response);
              }
        }).catch(function (error) {
          throw(error);
        });
    }

    // static trackCreditCardClick(trackCreditCardRequest) {
    //
    //   const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/tracking/product-click';
    //
    //   let options = {
    //        method: 'POST',
    //        mode: 'cors',
    //        credentials: 'include',
    //        headers: {
    //         'Content-Type': 'application/json'
    //         // 'x-gcs-xsrf': csrfToken
    //       },
    //        body: JSON.stringify(trackCreditCardRequest)
    //      }
    //
    //     return fetch(fullUrl, options);
    // }

    //static saveAuthor(author) {
    //    author = Object.assign({}, author); // to avoid manipulating object passed in.
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            // Simulate server-side validation
    //            const minAuthorNameLength = 3;
    //            if (author.firstName.length < minAuthorNameLength) {
    //                reject(`First Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.lastName.length < minAuthorNameLength) {
    //                reject(`Last Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.id) {
    //                const existingAuthorIndex = authors.findIndex(a => a.id == author.id);
    //                authors.splice(existingAuthorIndex, 1, author);
    //            } else {
    //                //Just simulating creation here.
    //                //The server would generate ids for new authors in a real app.
    //                //Cloning so copy returned is passed by value rather than by reference.
    //                author.id = generateId(author);
    //                authors.push(author);
    //            }

    //            resolve(author);
    //        }, delay);
    //    });
    //}

    //static deleteAuthor(authorId) {
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            const indexOfAuthorToDelete = authors.findIndex(author => {
    //                author.authorId == authorId;
    //            });
    //            authors.splice(indexOfAuthorToDelete, 1);
    //            resolve();
    //        }, delay);
    //    });
    //}
}

export default ProductApi;
