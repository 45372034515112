import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

import {initTonikForms} from '../../tonik/shared/forms';
import {tonikInitMonthSlider} from '../../tonik/report';
import ResolveIssueModal from './ResolveIssueModal'

const $ = window.$;

class AccountsDemo extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        modal: false,
      }

      this.onAccordionBtnClick = this.onAccordionBtnClick.bind(this);
  }

  componentDidMount() {
    //window['initAppJs'](true);
    initTonikForms();
    
  }

  componentDidUpdate(prevProps, prevState){
    //window['initAppJs'](true);
    initTonikForms();
  }

  openModal () {    
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

  onAccordionBtnClick(event) {
    tonikInitMonthSlider();
  }

render(){

    let {textVariants, data} = this.props;
    return (

      <span>
        <div id="accordion" className="accordion-reports">
          {
            data.account.map( (account, index) => {
              return (
                <div className="card">
                  <div className="card-header" id={"heading-" + account['account-id']['__text']}>
                    <h5 className="mb-0">
                      <button onClick={this.onAccordionBtnClick} className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse-" + account['account-id']['__text']} aria-expanded="true" aria-controls={"collapse-" + account['account-id']['__text']}>
                        {account['original-credit-provider']  ? account['original-credit-provider'].name.__text : ''} 
                        {account['account-open-date'] != null && (<span>Date of enquiry: <span className="date">{account['account-open-date'] ? account['account-open-date']['__text'] : '' }</span></span>)}
                      </button>
                    </h5>
                    <h2>{account['account-type']['__text']} <span>{account['account-id']['__text']}</span></h2>
                  </div>

                  <div id={"collapse-" + account['account-id']['__text']} className="collapse" aria-labelledby={"heading-" + account['account-id']['__text']} data-parent="#accordion">
                    <div className="card-body">
                      
                      {account['latest-maximum-amount-credit'] != null && (<> <p>Credit limit</p>
                      <span className="green"><sup>$</sup>{account['latest-maximum-amount-credit'] ? account['latest-maximum-amount-credit']['__text'] : ''}</span></>)}
                      
                      { account['repayment-history'] && account['repayment-history']['payment'] && account['repayment-history']['payment'].length > 0 && (
                        <div className="months-column">
                          <div className="months-slider">
                            {account['repayment-history']['payment'].map((item) => {
                              return (
                                <div className="months-item">
                                  <img src={item.status.__text == 'C' ? ImagesPath.ImgIconOk : (item.status.__text == 'R' ? ImagesPath.ImgIconAlert2 : ImagesPath.ImgIconQuestion)} className="img-fluid" alt=""/>
                                  <p>{item.period.__text}</p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}

                      <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>Resolve an issue on your credit report</a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {this.state.modal && (
          <ResolveIssueModal closeModal={()=>{this.closeModal()}}></ResolveIssueModal>
          )}
      </span>);
      }
 }

 AccountsDemo.propTypes = {
    textVariants: PropTypes.object.isRequired,
};


export default AccountsDemo;
