import React from 'react';
import * as ImagesPath from '../../../../config/imagesPath';
import * as RoutesVariables from '../../../../config/routesVariables';
import ProgressActionButtons from './ProgressActionButtons';

const WizardProgressBar = ({wizardStepState, wizardStepsDef, currentStep, title, step=null, onAction=null, theme=null}) => {
  
  let max = 1;
  let completed = 0;
  let i = 0;

  if (wizardStepState && wizardStepsDef) {
    let cssClass = wizardStepsDef[currentStep] ? wizardStepsDef[currentStep].cssClass : "";

    for (i = 0; i < wizardStepState.length; i++) {
      if (wizardStepState[i]["enabled"]) {
        if (wizardStepState[i]["isCompleted"] || (wizardStepState[i]["isWhenShowCompleted"] && currentStep == i)) {
          completed++;
        }
        max++;
      }
    }

    let porcentage = (100.00 / max);
    porcentage = porcentage.toFixed(0);

    let safe = 0;

    while ((porcentage * max > 100) && safe < 7) {
      porcentage--;
      safe++;
    }

    let divStyle = {
      width: porcentage + '%'
    };

    if (completed > 0) {
      completed++;
    }

    // if(completed == (max - 2)){
    //     completed++;
    // }

    let barItems = [];
    for (let j = 0; j < max; j++) {
      if (j < completed) {
        barItems.push(<div key={j} style={divStyle} className="bar-line-item bar-line-item-completed"></div>);
      }
      else {
        barItems.push(<div key={j} style={divStyle} className="bar-line-item"></div>);
      }
    }

  return (
    <div className="nav-belt first-form">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3 d-md-block d-none">
          { !step["hideNavigation"] &&
                    <ProgressActionButtons
                      step={step}
                      onAction={onAction}
                      stepsState={wizardStepState}
                      currentStepIndex={currentStep}
                      theme={theme}
                      isBack={true}
                    />
                  }
          </div>
          <div className="col-2 d-sm-block d-md-none">
            <a href="#" className="back"><img src={ImagesPath.ImgIconSvgArrowLeftBlue} className="img-fluid" alt=""/></a>
          </div>
          <div className="col-lg-8 col-md-7 col-8">
            <h2><span>{completed} <span className="separator"></span> {max}</span> {title}</h2>
          </div>
          <div className="col-lg-2 col-md-2 col-2">
            <a href={RoutesVariables.RouteCreditScore} className="close-link"><img src={ImagesPath.ImgIconCloseBlue} className="img-fluid" alt=""/></a>
          </div>
        </div>
      </div>
    </div>

    );
  }
}

export default WizardProgressBar;