import delay from './delay';
import * as pages from '../components/shared/pages.js';
import pagesVariants from '../components/shared/textVariants';

import {USERROLES, hasOneRole} from '../common/gcsLogic';

import {
menuItemList,
menuItemsNamePerPage,
keyFeatures,
gcsProcessSteps,
gcsHomeFaqs,
dashboardtabs} from '../components/shared/staticData';

/*
let dashboardTabComponents = [
  DashboardScore,
  DashboardScoreHistory,
  DashboardScoreCompare
];
*/

////This would be performed on the server in a real app. Just stubbing in.
//const generateId = (author) => {
//    return author.firstName.toLowerCase() + '-' + author.lastName.toLowerCase();
//};



class LayoutApi {


    static getMenuForPage(pageid, userProfile, menuItemList, menuItemsNamePerPage) {

        return new Promise((resolve, reject) => {
            let roles = userProfile.roles
            setTimeout(() => {
                // pick the right menu items
               var menuItemsAllowedKeys = menuItemsNamePerPage[pageid];
               if(!menuItemsAllowedKeys){
                  menuItemsAllowedKeys = menuItemsNamePerPage["DEFAULT"];
               }

              var menuItemsToReturn = [];
              for(var i = 0; i < menuItemsAllowedKeys.length; i++){

                //var miIndex = menuItemList.findIndex(a => a.id == menuItemsAllowedKeys[i]);                
                for(var index in menuItemList){
                  var menuItem = menuItemList[index];
                  if(menuItem.id == menuItemsAllowedKeys[i]){                  

                    if(!menuItem.hasOwnProperty('isAuthenticated') ||
                       (userProfile && (userProfile.isAuthenticated == menuItem.isAuthenticated) && (!menuItem.role || hasOneRole(roles, menuItem.role) )   ) ||
                       (!userProfile && !menuItem.isAuthenticated)){
                        menuItemsToReturn.push(menuItem);
                    }
                  }
                }
              }
                
              resolve(menuItemsToReturn);

            }, delay);
        });
    }


    static getKeyFeatures() {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(keyFeatures);
            }, delay);
        });
    }

    static getHomeFaq() {

        return Promise.resolve(gcsHomeFaqs);
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         //resolve(Object.assign([], products));
        //         resolve(keyFeatures);
        //     }, delay);
        // });
    }

    static getGcsProcessSteps() {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(gcsProcessSteps);
            }, delay);
        });
    }

    static getDashboardTabs(userContext, excludeTabIndex) {

        let dtabs = dashboardtabs;

        if(excludeTabIndex){
          dtabs = [...dashboardtabs];
          dtabs.splice(excludeTabIndex, 1);
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(dtabs);

            }, delay);
        });
    }


    static getTextVariants(userProfile, pageId) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(pagesVariants);

            }, delay);
        });
    }




    //static saveAuthor(author) {
    //    author = Object.assign({}, author); // to avoid manipulating object passed in.
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            // Simulate server-side validation
    //            const minAuthorNameLength = 3;
    //            if (author.firstName.length < minAuthorNameLength) {
    //                reject(`First Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.lastName.length < minAuthorNameLength) {
    //                reject(`Last Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.id) {
    //                const existingAuthorIndex = authors.findIndex(a => a.id == author.id);
    //                authors.splice(existingAuthorIndex, 1, author);
    //            } else {
    //                //Just simulating creation here.
    //                //The server would generate ids for new authors in a real app.
    //                //Cloning so copy returned is passed by value rather than by reference.
    //                author.id = generateId(author);
    //                authors.push(author);
    //            }

    //            resolve(author);
    //        }, delay);
    //    });
    //}

    //static deleteAuthor(authorId) {
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            const indexOfAuthorToDelete = authors.findIndex(author => {
    //                author.authorId == authorId;
    //            });
    //            authors.splice(indexOfAuthorToDelete, 1);
    //            resolve();
    //        }, delay);
    //    });
    //}
}

export default LayoutApi;
