import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import * as GlobalVariables from '../../config/configureGlobalVariables';

const GcsRecaptcha = ({ recaptchaRef, onSelectRecaptcha }) => {
  return (

    <ReCAPTCHA
      tabindex={-1}
      ref={recaptchaRef}
      size="invisible"
      sitekey={GlobalVariables.RECAPTCHA_SITE_KEY}
      onChange={onSelectRecaptcha}
      onExpired={onSelectRecaptcha}
      onErrored={onSelectRecaptcha}
      badge="bottomright"
    />   
  )
}

GcsRecaptcha.propTypes = {
  recaptchaRef: PropTypes.object,
  onSelectRecaptcha: PropTypes.func
}

export default GcsRecaptcha