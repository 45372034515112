
export const HOME = 'HOME';
export const HOMESHOW = 'HOMESHOW';
export const FREECREDITSCORE = 'FREECREDITSCORE';
export const FORGOTPASSWORD = 'FORGOTPASSWORD';
export const DASHBOARD = 'DASHBOARD';
export const DASHBOARDHISTORY = 'DASHBOARDHISTORY';
export const HELPCENTRE = 'HELPCENTRE';
export const LOGIN = 'LOGIN';
export const CREATEACCOUNT = 'CREATEACCOUNT';
export const REGISTER = 'REGISTER';
//export const PREFERENCES = 'PREFERENCES';
export const ARTICLES = 'ARTICLES';
export const ARTICLE = 'ARTICLE';
export const FAQS = 'FAQS';
export const CONTACTUS = 'CONTACTUS';
export const CREDITCARDLANDING = 'CREDITCARDLANDING';
export const PRODUCT = 'PRODUCT';
export const CHANGEPASSWORD = 'CHANGEPASSWORD';
export const VALIDATEEMAIL = 'VALIDATEEMAIL';
export const MYDETAILS = 'MYDETAILS';
export const MYDETAILSVERIFICATION = 'MYDETAILSVERIFICATION';
export const CREDITREPORTSUMMARY = 'CREDITREPORTSUMMARY';
export const CREDITCARDS = 'CREDITCARDS';
export const PERSONALLOANS = 'PERSONALLOANS';
export const DEALS_CARLOANS = 'DEALS_CARLOANS';
export const DEALS_INSURANCE = 'DEALS_INSURANCE';
export const DEALS_HOMELOANS = 'DEALS_HOMELOANS';
export const ACTIVATEACCOUNT = 'ACTIVATEACCOUNT';
export const PAGENOTFOUND = 'PAGENOTFOUND';
export const CREDITCARDSOFFERS = 'CREDITCARDSOFFERS';
export const EMAILSENTBLUE = 'EMAILSENTBLUE';
export const VALIDATEEMAILBLUE = 'VALIDATEEMAILBLUE';
export const HOMELOANCUSTOMERJOURNEY = 'HOMELOANCUSTOMERJOURNEY';
export const MYPREFERENCES = 'MYPREFERENCES';
export const ABOUTME = 'ABOUTME';
export const HOMEOHNO = 'HOMEOHNO';
export const PARTNERCONSENT = 'PARTNERCONSENT';
export const SCOREMAPSECTION = 'SCOREMAPSECTION';
export const SCOREMAP = 'SCOREMAP';
export const COUNTER = 'COUNTER';
export const TRUSTPILOTSECTION = 'TRUSTPILOTSECTION';
export const NEWMEMBERSMS = 'NEWMEMBERSMS';


export const COMMON_SECTION_PADDING = 'xl-pt-40 xl-pb-40 lg-pt-40 lg-pb-40 md-pt-30 md-pb-30 xs-pt-20 xs-pb-20';
