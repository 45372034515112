import React from 'react';
import { Link } from 'react-router-dom';
import * as funcs from '../../../shared/functions';

const SummaryListForHla = ({summaryList}) => {
  return (
    <div className="row">
      <div className="col-xs-offset-1">
        <table className="table" style={{lineHeight:'7px'}}>
          <tbody>     
            {summaryList.map((item, i) => <SummaryListItem key={i} item={item} /> )}
          </tbody>
        </table>          
      </div>
    </div>
  );
};

const SummaryListItem = ({item}) => {
  return (    
    <tr className="wizard-step-summary-tr">
      <td className="wizard-step-summary-td-title" style={{borderColor:'transparent'}}><div className="wizard-step-summary-property">{item.key + ":"}</div></td>
      <td style={{borderColor:'transparent', color:'blue'}}><div className="wizard-step-summary-value">{item.value}</div></td>
    </tr>
  );
};

const PartnerFeatureListItem = ({feature}) => {

return(  <li key={feature.key}>
    <span className="wizard-step-finish-list-text">{feature.title}</span>
  </li>);

};

const ErrorComponent = ({errorList}) => {

  const errors = errorList.map((error, i) =>
    <li key={i}>{error.message}</li>
  );

return (
  <div className="card text-white mb-3 mt-3">
    <div className="card-header bg-warning">Please update the following in order to proceed.</div>
    <div className="card-body bg-light text-muted">

    <ul className="card-text p-1">
        {errors}
      </ul>
      <h5 className="card-title">Click previous to go back and update your information.</h5>
    </div>
  </div>
  );

};

const PartnerComponentLink = ({step, stepState, stepDef, identifier, onCustomAction, cssClass, children }) => {

  const urlForHLA = (stepDef["actionUrl"] || "").replace('{USERID}', 'u-' + identifier);
  const productIdentifier = stepDef["productIdentifier"];
  const shouldPushLead = stepDef["shouldPushLead"];
  let partnerId = stepDef["partnerId"];

let linkComponent = <div />;

  if(shouldPushLead){
    linkComponent =   <Link className={cssClass} target="_blank" to={"/partner-refer?m=p&productId=" + productIdentifier + '&partnerId=' + partnerId} onClick={() => onCustomAction(step, stepState) }>
        {children}
      </Link>;
  }
  else{
    linkComponent =   <a className={cssClass} target="_blank" href={urlForHLA} onClick={() => onCustomAction(step, stepState) }>
        {children}
      </a>;
  }

return (linkComponent);

};

const PartnerComponent = ({partnerConfig, step, stepState, stepDef, identifier, theme, onCustomAction}) => {

  const partnerFeatures = partnerConfig.features ? partnerConfig.features.map( (feature, i) => <PartnerFeatureListItem feature={feature} key={i} />) : null;

  //const urlForHLA = stepDef["actionUrl"].replace('{USERID}', 'u-' + identifier);
  //const productIdentifier = stepDef["productIdentifier"];
  //const shouldPushLead = stepDef["shouldPushLead"];
  //let partnerId = stepDef["partnerId"];
  let txtButton = "";
  if(partnerConfig.txtButton){
    txtButton = partnerConfig.txtButton;
  }
  else if(stepDef["btnHLAText"]){
    txtButton = stepDef["btnHLAText"];
  }

return (
  <div>
    <div className="row wizard-step-summary-title">
      <div className="col- col-xs-12">{partnerConfig.matchedPartnerText}</div>
    </div>
    <div className="row wizard-step-partner-logo justify-content-center">
        <PartnerComponentLink step={step} stepState={stepState} stepDef={stepDef} identifier={identifier} onCustomAction={onCustomAction} cssClass="">
            <img src={partnerConfig.logo} alt="" className={"img-responsive animated zoomIn " + partnerConfig.logoCss} style={{maxWidth: '200px'}}/>
            <p className="wizard-step-partner-tagline">{partnerConfig.brandTagLine}</p>
        </PartnerComponentLink>
    </div>
    <div className={"text-center"}>
      <div className="">

          <PartnerComponentLink step={step} stepState={stepState} stepDef={stepDef} identifier={identifier} onCustomAction={onCustomAction} cssClass="wizard-action-btn finish">          
            <div className="btn btn-secondary mb-3 w-100" dangerouslySetInnerHTML={funcs.createMarkup(txtButton)} />
          </PartnerComponentLink>

      </div>
    </div>
    <div>
      <span className="mt-2 mb-3" dangerouslySetInnerHTML={funcs.createMarkup(partnerConfig.disclaimer)} />
    </div>
    {/* <div>
      <ol className="wizard-step-finish-list-bullet">
        {partnerFeatures}
      </ol>
    </div> */}
  </div>);

};

const HlaWizFinish = ({step, stepState, identifier, theme, wizardStepState, wizardStepIndex, wizardStepsDef, onCustomAction}) => {
  const stepDef = step["stepDefinition"];
  let component = <div />;

  if (step && stepState) {
    const stepData = step.stepDefinition.getStepData && step.stepDefinition.getStepData(stepState, wizardStepState, wizardStepsDef);
    let summaryList = [], errorList = [], partnerConfig = {};

    if (stepData) {
      summaryList = stepData.summaryList;
      errorList = stepData.errorList || [];
      
      if(stepDef.displayWizard && stepDef.displayWizard.matchedPartner){
        partnerConfig.logo = stepDef.displayWizard.imgUrl;  
        partnerConfig.features = stepDef.displayWizard.features; 
        partnerConfig.logoCss = stepDef.displayWizard.cssClass;  
        partnerConfig.matchedPartnerText = stepDef.displayWizard.matchedPartner;  
        partnerConfig.brandTagLine = stepDef.displayWizard.brandTagLine;  
        partnerConfig.disclaimer = stepDef.displayWizard.disclaimer;  
        partnerConfig.txtButton = stepDef.displayWizard.txtButton;  
      }
      else{
        partnerConfig = stepData.partnerConfig;
      }      
    }

    const summaryRecords = <SummaryListForHla summaryList={summaryList} />;
    let partnerComponent = errorList.length > 0 ?
              <ErrorComponent errorList={errorList} />:
              <PartnerComponent partnerConfig={partnerConfig}  theme={theme} step={step} stepState={stepState} stepDef={stepDef} identifier={identifier} onCustomAction={onCustomAction} />;

    component = (
      // <div className="container-fluid wizard-step wizard-hla-finish">
      //   <div className="row wizard-step-summary-title">
      //     <div className="col-xs-12">{stepDef["stepInstruction"]}</div>
      //   </div>
      //   <div className="wizard-step-summary">
      //     {summaryRecords}
      //   </div>
      //   {partnerComponent}
      // </div>
      <div className="container">
      <div className="row">
        <h2>{stepDef["stepInstruction"]}</h2>
      </div>

        {summaryRecords}

      {partnerComponent}
    </div>
      );
  }

  return component;
};

export default HlaWizFinish;
