import * as GlobalVariables from '../../config/configureGlobalVariables';

export const faqTopics =[];


export const faqs = [ ];


export const staticSectionFAQS = [
      {
          id: "0",
          title:"What is a credit score?",
          href: GlobalVariables.WEB_BASE_URL + 'faq/get-my-credit-score/#what-is-a-credit-score',
          text:"A credit score is a number which summarises the credit information from your credit report. Credit scores are one indicator of your credit risk and may be used by credit providers like banks and credit card companies to help understand how responsible you are to lend to. Generally, a higher credit score is considered better as it indicates lower risk. Your credit score from GetCreditScore is generated by Australia’s leading credit bureau, Equifax, and is a number between 0 and 1200."
 },

 {
          id: "1",
          title: "How is my credit score calculated?",
          href: GlobalVariables.WEB_BASE_URL + 'faq/improve-my-credit-score/#how-is-my-credit-score-calculated',
          text: "Simply, your credit score* is calculated from your credit report which is a record of your current situation and past behaviour, including:\
	            <br />\
	            <ul>\
                <li>Credit accounts and credit limits</li>\
                <li>Repayment history information</li>\
                <li>Bankruptcy, overdue debts, if you’ve avoided a creditor, and any court judgements against you</li>\
                <li>Your age, how long you’ve been employed, and if you move house a lot</li>\
                <li>How long you’ve had a credit report, and how often you’ve asked for credit</li>\
                <li>The kinds of loans or credit you’ve asked for, size, who it was with, and if you asked a lot of providers one after the other</li>\
                <li>Your business interests, and if you have a business, its location and how long it’s been there</li>\
              </ul>\
		<p>You can obtain your credit report, including a free option, directly from Equifax <a target='_blank' href='https://www.equifax.com/personal/'>here</a>.</p>\
 	  <p class='terms terms-size'>* Your GetCreditScore credit score is based on the Equifax Credit Score Model</p>"
},

{
          id: "2",
          href: GlobalVariables.WEB_BASE_URL + 'faq/general-information/#are-all-credit-scores-from-different-bureaus-the-same',
          title:"Are credit scores from different bureaus the same?",
          subTitle: '',
          text:"Not all credit scores are the same. Every credit score relies on different data, and credit providers don’t all use the same score, however\
		Equifax, where GetCreditScore receives its credit scores from, has credit information on 18 million Australians making it Australia’s leading\
		credit reporting organisation."
  }]
