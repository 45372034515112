

export const PRODUCTTYPE_HOMELOAN = 1;
export const PARTNER_RATECITY = 2;
export const PRODUCT_CREDITCARD = 5;
export const PARTNER_UNO = 7;
export const PARTNER_FIRSTMAC = 26;
export const PARTNER_TOYOTA = 12;
export const PARTNER_POWERTORQUE = 24;
export const PRODUCT_UNO_HLA = 8;
export const PARTNER_PEPPER = 13;
export const PRODUCT_PEPPER_HLA = 9;

export const RatecityHomeLoansUrl = "https://www.ratecity.com.au/whitelabels/getcreditscore/home-loans";
export const OnDeckBusinessLoansUrl = "https://www.ondeck.com.au/know-your-score/?utm_source=Get%20Credit%20Score%20Banner";
export const TrustPilotReviewUrl = {
  widget: "https://au.trustpilot.com/review/www.getcreditscore.com.au",
  widgetUrl: "https://au.trustpilot.com/review/www.getcreditscore.com.au",
  carouselWidgetLight: "//widget.trustpilot.com/trustboxes/53aa8912dec7e10d38f59f36/index.html?locale=en-AU&amp;templateId=53aa8912dec7e10d38f59f36&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=130px&amp;styleWidth=100%25&amp;theme=light&amp;stars=5&amp;schemaType=Organization",
  inlineWidgetLight: "//widget.trustpilot.com/trustboxes/5406e65db0d04a09e042d5fc/index.html?locale=en-AU&amp;templateId=5406e65db0d04a09e042d5fc&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=28px&amp;styleWidth=100%25&amp;theme=light",
  microWidgetDark: "//widget.trustpilot.com/trustboxes/5419b732fbfb950b10de65e5/index.html?locale=en-AU&amp;templateId=5419b732fbfb950b10de65e5&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=24px&amp;styleWidth=100%25&amp;theme=dark"
};
