import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';


import HomePage from './components/home/HomePage';
import DashboardPage from './components/dashboard/DashboardPage';
import LoginPage from './components/login/LoginPage';
import Login2FAPage from './components/login/Login2FAPage';
import RegistrationPage from './components/registration/RegistrationPage';
import RegistrationMobilePage from './components/registration/RegistrationMobilePage';
import RegistrationVerifyEmailPage from './components/registrationverify/RegistrationVerifyEmailPage';
import RegistrationVerifyMobilePage from './components/registrationverify/RegistrationVerifyMobilePage';
import LogoutPage from './components/login/LogoutPage';
import ForgotPasswordPage from './components/login/ForgotPasswordPage';
// import ValidateEmailPageBlue from './components/registration/ValidateEmailPageBlue';
import ContactUsPage from './components/contactus/ContactUsPage';
import LodgeacomplaintPage from './components/Lodge-a-complaint/LodgeacomplaintPage';
import ChangePasswordPage from './components/login/ChangePasswordPage';
import MyDetailsPage from './components/mydetails/MyDetailsPage';
import MyDetailsVerificationPage from './components/mydetails/MyDetailsVerificationPage';
import CreditCardsPage from './components/deals/CreditCardsPage';
import PersonalLoansPage from './components/deals/PersonalLoansPage';
import CarLoansPage from './components/deals/CarLoansPage';
import CreditCardOffers from './components/deals/CreditCardOffersPage';
import InsurancePage from './components/deals/InsurancePage';
import HomeLoanDealsPage from './components/deals/HomeLoanDealsPage';
import HomeLoanAssistantPage from './components/deals/HomeLoanAssistantPage';
//import ActivateAccountPage from './components/login/ActivateAccountPage';
//import EmailSentPageBlue from './components/registration/EmailSentPageBlue';
import MyPreferencesPage from './components/preferences/MyPreferencesPage';
import AboutMePage from './components/mydetails/AboutMePage';
import HomeOldStylePage from './components/home/HomeOldStylePage';
// import SmsPage from './components/login/SmsPage';
import HomeOhNoPage from './components/home/HomeOhNoPage';
// import PartnerConsentPage from './components/partner/PartnerConsentPage';
import PartnerReferPage from './components/partner/PartnerReferPage';
import ScoreMapPage from './components/scoreMap/scoreMapPage';
import DashboardScoreHistory from './components/dashboard/components/DashboardScoreHistory';
import PageNotFound from './components/pageNotFound/pageNotFound';
import DeleteAccountPage from './components/mydetails/DeleteAccountPage';
import DeleteAccountSuccessPage from './components/mydetails/DeleteAccountSuccessPage';
import ContactUsSuccessPage from './components/contactus/ContactUsSuccessPage';
import LodgeacomplaintSuccessPage from './components/Lodge-a-complaint/LodgeacomplaintSuccessPage';
import TermOfUse from './components/terms/TermOfUse'
import PrivacyPolicy from './components/terms/PrivacyPolicy';
import CreditReportSummaryPage from './components/creditSummary/CreditReportSummaryPage';
import CreditSummaryPageDemo from './components/creditSummaryDemo/CreditSummaryDemoPage';


const _GcsRoutes = () => (  
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/home" component={HomePage} />      
      <Route path="/logout" component={LogoutPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/login-2fa" component={Login2FAPage} />
      <Route path="/create-account" component={RegistrationPage} exact />
      <Route path="/create-account/mobile" component={RegistrationMobilePage} />      
      <Route path="/create-account/verify-email" component={RegistrationVerifyEmailPage} />
      <Route path="/create-account/verify-mobile" component={RegistrationVerifyMobilePage} />
      <Route path="/my-details/verify-mobile" component={RegistrationVerifyMobilePage} />
      <Route path="/my-details/verification" component={MyDetailsVerificationPage} exact />   
      <Route path="/my-details" component={MyDetailsPage} exact />   
      {/* <Route path="/register-id" component={RegistrationIdPage} /> */}
      
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      {/* <Route path="/verifyemail" component={ValidateEmailPageBlue} /> */}
      <Route path="/contact-us/:page?" component={ContactUsPage} />
      <Route path="/Lodge-a-complaint/:page?" component={LodgeacomplaintPage} />
      <Route path="/change-password" component={ChangePasswordPage} />

      {/* <Route path="/activate-account" component={ActivateAccountPage} /> */}
      {/* <Route path="/check-your-inbox" component={EmailSentPageBlue} /> */}
      <Route path="/check-your-inbox" component={RegistrationPage} exact />
      
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/dashboard-score-history" component={DashboardScoreHistory} />
      <Route path="/credit-report-overview" component={CreditReportSummaryPage} />
      <Route path="/credit-summary/demo" component={CreditSummaryPageDemo} exact />

      <Route path="/deals/credit-cards" component={CreditCardsPage} />
      <Route path="/deals/personal-loans" component={PersonalLoansPage} />
      <Route path="/deals/car-loans" component={CarLoansPage} />
      <Route path="/deals/credit-card-offers" component={CreditCardOffers} />
      <Route path="/deals/insurance" component={InsurancePage} />
      <Route path="/deals/home-loans" component={HomeLoanDealsPage} />
      <Route path="/deals/home-loan/assistant" component={HomeLoanAssistantPage} />
      <Route path="/partner-refer" component={PartnerReferPage} />
      
      <Route path="/my-preferences" component={MyPreferencesPage} />
      <Route path="/about-me" component={AboutMePage} />
      {/* <Route path="/toyota" component={(props) => <HomeOldStylePage pageVariation="toyota" {...props} />} /> */}
      {/* <Route path="/powertorque" component={(props) => <HomeOldStylePage pageVariation="powertorque" {...props} />} />       */}
      
      <Route path="/credit-score" component={(props) => <HomeOldStylePage pageVariation="full" {...props} />} exact />
      {/* <Route path="/free-credit-score" component={(props) => <HomeOldStylePage pageVariation="full" {...props} />} exact /> */}
      <Route path="/free-credit-score" component={RegistrationPage} exact />
      {/* <Route path="/free-credit-score/simple" component={(props) => <HomeOldStylePage pageVariation="clean" {...props} />} /> */}
      <Route path="/free-credit-score/simple" component={RegistrationPage} exact />
      {/* <Route path="/free-credit-score/savvy" component={(props) => <HomeOldStylePage pageVariation="savvy" {...props} />} /> */}
      <Route path="/free-credit-score/savvy" component={RegistrationPage} exact />
      {/* <Route path="/veda-credit-score" component={(props) => <HomeOldStylePage pageVariation="veda" {...props} />} /> */}
      <Route path="/veda-credit-score" component={RegistrationPage} exact />
      <Route path="/free-credit-score/newmember/:ci?/:ch?" component={(props) => <HomeOldStylePage pageVariation="newmember" {...props} />} />
      {/* <Route path="/free-credit-score/sms/:ch?" component={SmsPage} /> */}
      <Route path="/get-credit-smart" component={(props) => <HomeOldStylePage pageVariation="smart" {...props} />} />
      
      <Route path="/no-account" component={HomeOhNoPage} />
      
      {/* <Route path="/partner-consent/:partnerName/:gcsid" component={PartnerConsentPage} /> */}
            
      <Route path="/credit-score-map/:postCode?" component={ScoreMapPage} />
            
      <Route path="/delete-account" component={DeleteAccountPage} />
      <Route path="/delete-account-success" component={DeleteAccountSuccessPage} />
      
      <Route path="/contact-us-success" component={ContactUsSuccessPage} />
      <Route path="/Lodge-a-complaint-success" component={LodgeacomplaintSuccessPage} />
      
      <Route path="/terms-of-use" component={TermOfUse} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />      
      <Route component={PageNotFound} />
    </Switch>  
);

export const GcsRoutes = _GcsRoutes;
export const GcsPrefix = '';