import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';

const ResolveIssueModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">
              <h3>Something Doesn’t Look Right?</h3>              
              <h4><span>1</span> How can I fix an error on my credit report?</h4>
              <p>If you find any inaccuracies or incomplete information in your Credit Report Overview through GetCreditScore, the first step is to obtain your comprehensive credit report from Equifax where the information originated from. To get a copy of your credit report, just visit the  <a className="cr-modal-link" href="https://www.equifax.com.au/personal/products/credit-and-identity-products" target="_blank">Equifax website</a></p>
              <p>If you’re sure the data is incorrect or incomplete on your credit report, the best way to correct the information is to speak directly with the credit provider that the error relates to and ask that it be investigated and your credit report amended. A list of common creditor contacts can be found <a className="cr-modal-link" href="https://www.mycreditfile.com.au/creditor-contact-directory">here</a>. </p>
              <p>Alternatively you can lodge your correction request using the <a className="cr-modal-link" href="https://www.equifax.com.au/personal/resolution-centre">Equifax Corrections Portal</a>. </p>

              <h4><span>2</span> In what instances can information be removed from a credit report?</h4>
              <p>Generally, information can be removed from your credit report if it is found to be incorrect, or if circumstances have changed. To do this, you will need to contact the credit provider or the credit reporting body with whom you obtained the credit report from.</p>              
              <p>Also, depending on the type of information, this will automatically be removed from your credit report after a specified period.</p>              
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default ResolveIssueModal;