import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import AboutMeApi from '../../api/aboutMeApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import {AboutMeWizard} from '../common/Wizard/AboutMe/AboutMeWizard';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

class AboutMePage extends React.Component { 
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAboutMeProfileLoaded : false,
      isAboutMeProfileLoading : true,
      isServerError: false,
      initialProfile: null,
      nextPage: true
    };

    this.onSaveStep = this.onSaveStep.bind(this);
    this.onCustomAction = this.onCustomAction.bind(this);
  }

  componentDidMount() {
    // TODO: cancel Async/API call in ComponentWillUnmount
    // Warning: Can't call setState (or forceUpdate) on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
    this.props.actions.getTextVariants();
    this.props.actions.getMenuForPage(pages.ABOUTME);
    
    this.props.actions.isAuthenticated().then(e => {
      AboutMeApi.getAboutMeProfile(this.props.csrfToken).then( resp => {
        this.setState({
          initialProfile: resp.profile, 
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: false});

      }).catch( error => {
        this.setState({
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: true});

      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isAuthenticated) {
      // go back to login
      this.props.history.push('login/about-me');
    } else {
      // if(nextProps.userProfile.userDetails) {
      //      let newCustomer = Object.assign({},  nextProps.userProfile.userDetails);
      //       this.setState({customer: newCustomer});
      // }
    }
  }

  onSaveStep(stepState, stepDef) {
    var step = Object.assign({}, stepState, {stepCode: stepDef.id});

    AboutMeApi.saveAboutMeStep(this.props.csrfToken, step).then(() => {
      //  console.log('step saved');
    }).catch( error =>{
      //console.log(error);
      //this.setState({isAboutMeProfileLoaded: true, isAboutMeProfileLoading: false ,isServerError: true});
    });
  }

  onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex) {
    this.props.actions.participatedInPromotion();
    // PromotionApi.participatedInPromotion(this.props.csrfToken, 'GiveAway201708').then(()=> {
    //   //  console.log('step saved');
    //
    // }).catch( error =>{
    //   //console.log(error);
    //   //this.setState({isAboutMeProfileLoaded: true, isAboutMeProfileLoading: false ,isServerError: true});
    // });
  }

  renderFirstPage() {
    return (
      <section className="page-account-delete page-about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
              <div className="images">
                <img src={ImagesPath.ImgIconEye} className="img-fluid" alt=""/>
              </div>
              <span>Hi</span>
              <h1>{this.props.userProfile ? this.props.userProfile.displayName : ''}</h1>
              <p>Answer for a few question to help us identify which offers <br/>are best to you.</p>
              <div className="button">
                <a className="btn btn-primary full-width" onClick={()=>{this.nextPage()}}>Let's start</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  nextPage() {
    this.setState({nextPage:true})
  }

  render() {
    let pageContent = null;

    if (this.state.isServerError) {
      pageContent = (
        <ApiFailedToLoadComponentWithText 
          text="<h2>Sorry!</h2><p>We are not able to retrieve your profile at the moment. Please <a href='/contact-us'>contact us</a></p>" />
      );

    } else if (this.state.isAboutMeProfileLoading) {
      pageContent = (
        <LoadingIndicator
          textPosition='bottom'
          text={this.props.textVariants[pages.ABOUTME + this.props.textVariants.textVariantSeparator + "loading_text"]}
          size='200' />
      );

    } else if (this.state.isAboutMeProfileLoaded) {
      if(this.state.nextPage){
        pageContent = (
          <AboutMeWizard
            onSaveStep={this.onSaveStep}
            actions={this.props.actions}
            initialProfile={this.state.initialProfile}
            identifier={this.props.identifier}
            onCustomAction={this.onCustomAction}
          />
        );
      }
      else{
        pageContent = this.renderFirstPage();
      }

    } else {
      pageContent = <div />;
    }

    return (
      <div className="page-template-page-about bg-circle">
        {!this.state.nextPage && <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>}
        {pageContent}
        {!this.state.nextPage && <FooterSmallSection></FooterSmallSection>}
      </div>
    );
  }
}

AboutMePage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];

  if (state.menu) {
    var menu = state.menu;
    if ( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.ABOUTME]) {
        menuItems = menu.menuItemsPerPage.menuItems[pages.ABOUTME];
     }
  }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    csrfToken: state.userProfile.csrfToken,
    identifier: state.userProfile.identifier
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, trackingActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutMePage);