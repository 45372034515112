import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as RoutesVariables from '../../config/routesVariables';
import * as ImagesPath from '../../config/imagesPath';
import { Link } from 'react-router-dom';

import {tonikInitMenu} from '../../tonik/menu';

const MyAccountItem = ({ menuItem, user, blueIcons = false}) => {

    tonikInitMenu();

    var renderedComponent = (
        <div className="col-lg-2">
          <div className="dropdown dropdown-header">
            <button className="btn btn-header dropdown-toggle" type="button" id={menuItem.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {/* <i><img src={blueIcons ? ImagesPath.ImgIconBellBlue :ImagesPath.ImgIconBell} alt="" className="img-fluid"/><span className="number">2</span></i> */}
              <span className="subtitle">Your account </span>{user ? user.displayName: ''}
            </button>
            <div className="dropdown-menu" aria-labelledby={menuItem.id}>
              {
                menuItem.subMenuList.map( (item,i) => { if (item.id == "logout") {
                  return (<a key={"menu_a_" + i} className="dropdown-item blue" href={item.link}>{item.name}</a>)
                }
                else {
                  return (<a key={"menu_a_" + i} className="dropdown-item" href={item.link}>{item.name}</a>)
                }
              })
              }
            </div>
          </div>
        </div>
   );

    return renderedComponent;

};

MyAccountItem.propTypes = {
    menuItem: PropTypes.object.isRequired,
    user: PropTypes.object,

};


export default MyAccountItem;