import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function menuReducer(state = initialState.menu, action) {
  switch (action.type) {
    case types.LOAD_MENU_SUCCESS:

      var newState = Object.assign({}, state);

      newState.menuItemsPerPage = {};
      newState.menuItemsPerPage.menuItemsAuthenticated = Object.assign({}, state.menuItemsAuthenticated);
      newState.menuItemsPerPage.menuItemsAuthenticated[action.pageid] = action.isAuthenticated;

      newState.menuItemsPerPage.menuItems = Object.assign({}, state.menuItemsPerPage.menuItems);
      newState.menuItemsPerPage.menuItems[action.pageid] = [...action.menuForPage];

      return newState;

    default:
      return state;
  }
}