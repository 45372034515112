import React from 'react';
import PropTypes from 'prop-types';
import ScoreMapList from './ScoreMapList';

const ScoreMapRegionScoreBox = ({ title, headerColor, regionsScoreList }) => {

  const minRecords = 3;    
  const recordsToAdd = minRecords - regionsScoreList.length;
  for (let i = 0; i < recordsToAdd; i++) {
    regionsScoreList.push({});
  }

  return (
    <div className="scoremap-region-score">
      <div className={"scoremap-region-header " + headerColor}>
        <span className="">{title}</span>
      </div>
      <div className="scoremap-region-list">
        <ScoreMapList data={regionsScoreList}  keyName="r" valueName="s" />
      </div>
    </div>
  );
  
}

ScoreMapRegionScoreBox.propTypes = {    
  title: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  regionsScoreList: PropTypes.array.isRequired
};
  
export default ScoreMapRegionScoreBox;