import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../config/GlobalFixedVariables';
import * as funcs from '../components/shared/functions';


class VerificationApi {
    static verifyEmailOneTimeCodeRegistration(oneTimeCode, recaptchaToken) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register/verify-totp-email';

      let requestOptions = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: {
            'Content-Type': 'application/json',
            'x-recaptcha': recaptchaToken
            //'x-gcs-xsrf': csrfToken
          },
           body: JSON.stringify({               
               oneTimeCode
           })
        };

        return fetch(fullUrl, requestOptions).then(response =>
            {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                else{
                    return response.json();
                }
            }
        ).catch(function (error) {
        throw(error);
        });      
    }            

    static verifyEmailOneTimeCodeAuth(csrfToken, oneTimeCode) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/verify-totp-email';
  
        let headers = {};
        headers['Content-Type'] = 'application/json';
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }   
        let requestOptions = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: headers,
             body: JSON.stringify({                 
                 oneTimeCode
             })
          };
  
          return fetch(fullUrl, requestOptions).then(response =>
              {
                  if (!response.ok) {
                      return Promise.reject(response);
                  }
                  else{
                      return response.json();
                  }
              }
          ).catch(function (error) {
          throw(error);
          });      
      }            

      static sendEmailOneTimeCodeAuth(csrfToken, recaptchaToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/send-totp-email';
  
        let headers = {};
        headers['Content-Type'] = 'application/json';
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }   
        if(recaptchaToken){
            headers['x-recaptcha'] = recaptchaToken;
          }   
        let requestOptions = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: headers
            //  ,body: JSON.stringify({                 
            //      oneTimeCode
            //  })
          };
  
          return fetch(fullUrl, requestOptions).then(response =>
              {
                  if (!response.ok) {
                      return Promise.reject(response);
                  }
                  else{
                      return response.json();
                  }
              }
          ).catch(function (error) {
          throw(error);
          });      
      }            

      static sendEmailOneTimeCodeRegistration(recaptchaToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register/send-totp-email';
  
        let requestOptions = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: {
              'Content-Type': 'application/json',
              'x-recaptcha': recaptchaToken
              //'x-gcs-xsrf': csrfToken
            }
            // , body: JSON.stringify({                 
            //      oneTimeCode
            //  })
          };
  
          return fetch(fullUrl, requestOptions).then(response =>
              {
                  if (!response.ok) {
                      return Promise.reject(response);
                  }
                  else{
                      return response.json();
                  }
              }
          ).catch(function (error) {
          throw(error);
          });      
      }              

      static verifyMobileOneTimeCodeRegistration(csrfToken, oneTimeCode, recaptchaToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register/verify-totp-mobile';
        let headers = {};
        headers['Content-Type'] = 'application/json';
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }   
        if(recaptchaToken){
            headers['x-recaptcha'] = recaptchaToken;
          }   
        let requestOptions = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: headers,
             body: JSON.stringify({                 
                 oneTimeCode
             })
          };
  
          return fetch(fullUrl, requestOptions).then(response =>
              {
                  if (!response.ok) {
                      return Promise.reject(response);
                  }
                  else{
                      return response.json();
                  }
              }
          ).catch(function (error) {
          throw(error);
          });      
      }            
  
      static verifyMobileOneTimeCodeAuth(csrfToken, oneTimeCode) {
  
          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/verify-totp-mobile';
    
          let headers = {};
          headers['Content-Type'] = 'application/json';
          if(csrfToken){
            headers['x-gcs-xsrf'] = csrfToken;
          }   
         
          let requestOptions = {
               method: 'POST',
               mode: 'cors',
               credentials: 'include',
               headers: headers,
               body: JSON.stringify({                 
                   oneTimeCode
               })
            };
    
            return fetch(fullUrl, requestOptions).then(response =>
                {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }
                    else{
                        return response.json();
                    }
                }
            ).catch(function (error) {
            throw(error);
            });      
        }            
  
        static sendMobileOneTimeCodeAuth(csrfToken, recaptchaToken) {
  
          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/send-totp-mobile';
          let headers = {};
          headers['Content-Type'] = 'application/json';
          if(csrfToken){
            headers['x-gcs-xsrf'] = csrfToken;
          }   
          if(recaptchaToken){
              headers['x-recaptcha'] = recaptchaToken;
            }   
          let requestOptions = {
               method: 'POST',
               mode: 'cors',
               credentials: 'include',
               headers: headers,
               body: JSON.stringify({                                    
               })
            };
    
            return fetch(fullUrl, requestOptions).then(response =>
                {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }
                    else{
                        return response.json();
                    }
                }
            ).catch(function (error) {
            throw(error);
            });      
        }            
  
        static sendMobileOneTimeCodeRegistration(csrfToken, mobile, recaptchaToken) {
  
          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register/send-totp-mobile';
    
          let headers = {};
          headers['Content-Type'] = 'application/json';
          if(csrfToken){
            headers['x-gcs-xsrf'] = csrfToken;
          }   
          if(recaptchaToken){
              headers['x-recaptcha'] = recaptchaToken;
            }   
          let requestOptions = {
               method: 'POST',
               mode: 'cors',
               credentials: 'include',
               headers: headers
               ,body: JSON.stringify({                 
                   destination: mobile
               })
            };
    
            return fetch(fullUrl, requestOptions).then(response =>
                {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }
                    else{
                        return response.json();
                    }
                }
            ).catch(function (error) {
            throw(error);
            });      
        }                    
};

export default VerificationApi;