import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App.js';

const AppIndex = ({ store, routes }) => (
  <Provider store={store}>
    <BrowserRouter>
      <App store={store}>
        {routes}
      </App>
    </BrowserRouter>
  </Provider>
);

AppIndex.propTypes = {
  store: PropTypes.object.isRequired//,
  //history: PropTypes.object.isRequired
};

export default AppIndex;
