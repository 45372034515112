import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

const LoginFrame  = ({
  disclaimerCtrl,
  cssClass = '',
  children
  }) => {  

return (
    <section className={"section-form-log " + cssClass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 form-log-wrapper">
            <div className="form-log-box">
            <img src={ImagesPath.ImgLogo} className="img-fluid d-none d-sm-block" alt="" />
                {children}                                             
            </div>
             {disclaimerCtrl}
          </div>
        </div>
      </div>
    </section>);   
};

LoginFrame.propTypes = {
    disclaimerCtrl: PropTypes.object,  
};

export default LoginFrame;
