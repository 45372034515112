import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';

import Menu from  '../menu/Menu.js'
import GetStartedSection from '../sections/GetStartedSection';
import CreditCardsBoxes from '../sections/CreditCardsBoxes.js';
import LoadingIndicator from '../common/controls/LoadingIndicator';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as productActions from '../../redux/actions/productActions';
import * as trackingActions from '../../redux/actions/trackingActions';

import { createMarkup } from '../../components/shared/functions.js';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
const CreditCardOffersTitle = ({ textVariants, children}) => {

  return (
    <section className="credit-cards-offer-title">
    {children}
      <div className="container-fluid left-container-padding right-container-padding product-section">

        <div className="row">

          <div className="col-xs-12 col-md-12-text-center">
            <img alt="" src={ImagesPath.ImgWizard_CreditCardPage_Banner} className="hidden-xs title-img" />
            <div className='title-text'>
                <h1>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "header"]}</h1>
                <p>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "cc_text"]}</p>
                <p>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "betterdeal_text"]}</p>

              </div>
          </div>
         </div>
        </div>
    </section>
 )
 }

 const TermsConditions = ({disclaimer}) => {

    return (
      <section className="terms-conditions text-center xs-pt-20 xs-pb-20">
          <span dangerouslySetInnerHTML={createMarkup(disclaimer)}></span>
       </section>
 )
 }

 TermsConditions.propTypes = {
       disclaimer: PropTypes.string.isRequired
 };


class CreditCardOffers extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.onGetScoreClick = this.onGetScoreClick.bind(this);
        this.onProductClick = this.onProductClick.bind(this);
        this.onCreditCardComparisonTableClick = this.onCreditCardComparisonTableClick.bind(this);
        this.onGetScoreClickToFreeScore = this.onGetScoreClickToFreeScore.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);

    }

    componentDidMount() {
       this.props.actions.getMenuForPage(pages.CREDITCARDSOFFERS);
       this.props.actions.getTextVariants();
       this.props.actions.loadCreditCards();
    }

    onGetScoreClick(event){

      this.props.actions.trackGetScoreButtonClick(event.target.id);
       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.redirect(RoutesVariables.RouteCreateAccount);
       }
    }

    onGetScoreClickToFreeScore(event){
       this.props.actions.trackButtonClick(pages.CREDITCARDSOFFERS, 'gcsBtn_getStarted');
       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.redirect(RoutesVariables.RouteFreeCreditScore);
       }
    }

    onLoginClick(event){
         this.props.actions.trackButtonClick(pages.CREDITCARDSOFFERS, 'gcsBtn_getStarted');
         this.redirect(RoutesVariables.RouteLogin);
    }

    onCreditCardComparisonTableClick(){

      this.props.actions.trackGcsProductClick(
        {
          partnerId : GlobalFixedVariables.PARTNER_RATECITY,
          productTypeId: GlobalFixedVariables.PRODUCT_CREDITCARD,
          origin: pages.CREDITCARDSOFFERS
        }
      );
    }

    onProductClick(event, product) {
        this.props.actions.trackGcsProductClick(product, pages.CREDITCARDSOFFERS);
    }

    render() {

      let mainComponent = <div></div>;

      let menuComponent = (<Menu menuItemList={this.props.menuItemList}  />)

      if(this.props.textVariants){
         mainComponent = <div className="creditCardOffersPage">

         <CreditCardOffersTitle textVariants={this.props.textVariants} >
          {menuComponent}
         </CreditCardOffersTitle>

         <CreditCardsBoxes
            key="creditcardsboxes"
            //creditcards={creditCardsList}
            creditcards={this.props.creditCards}
            textVariants={this.props.textVariants}
            onProductClick={this.onProductClick}
            title={this.props.textVariants[pages.CREDITCARDSOFFERS + this.props.textVariants.textVariantSeparator + "product_header"]}
            onCreditCardComparisonTableClick={this.onCreditCardComparisonTableClick}
             />

          <TermsConditions
             disclaimer={this.props.textVariants[pages.CREDITCARDSOFFERS + this.props.textVariants.textVariantSeparator + "disclaimer"]} />

         <GetStartedSection key="getstarted"
            isAuthenticated={!this.props.isAnonymous}
            onLoginClick={this.onLoginClick}
            onGetScoreClick={this.onGetScoreClickToFreeScore}
            textVariants={this.props.textVariants}  />

       </div>
     }

        return ( mainComponent );
    }
}

CreditCardOffers.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.CREDITCARDSOFFERS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.CREDITCARDSOFFERS];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }


   let creditCards = [];
   let creditCardsLoading = null;
   if(state.creditCardsResult && state.creditCardsResult.products){
     creditCards = state.creditCardsResult.products;
     creditCardsLoading = state.creditCardsResult.loading;
   }

   let isAnonymous = true;
   if(state.productResult){
     isAnonymous = state.productMetadata.isAnonymous;
   }

  return {
    creditCards : creditCards,
    //productLoading: productLoading,
    isAnonymous: isAnonymous,
    menuItemList: menuItems,
    textVariants: txtVariants,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, productActions, trackingActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardOffers);
