import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';
import * as constants from '../shared/constants';
import * as funcs from '../shared/functions';
import { GlobalThemes } from '../shared/themes';

import Menu from  '../menu/Menu.js';
import ProductSection, {ProductAdditionalSection} from './../sections/ProductSection';
import ArticlesLearnSection from './ArticlesLearnSection';
import ArticlesSection from '../home/ArticlesSection';
import NextStepsSection from './../sections/NextStepsSection';
import { ApiFailedToLoadComponentWithText } from '../common/ApiFailedToLoadComponent';
//import DashboardScoreAdviceSection from './DashboardScoreAdvice';
import { DashboardScore } from './components/DashboardScore';
import { DashboardKcf } from './components/DashboardKcf';
import { DashboardFloatingLinks } from './components/DashboardFloatingLinks';
import { DashboardCallsToAction } from './components/DashboardCallsToAction';
import { DashboardCallsToActionSection } from './components/DashboardCallsToActionSection';
import { DashboardScoreHistory } from './components/DashboardScoreHistory';
import { DashboardScoreCompare } from './components/DashboardScoreCompare';
import {TabControl} from '../common/controls/TabControl';
import ScoreMapSection from './../sections/ScoreMapSection';
import {PromotionBanner} from '../sections/PromotionBanner';
import {BusinessLoanBanner} from '../sections/BusinessLoanBanner';
import NewFeaturesBanner from '../sections/NewFeaturesBanner';

import * as productActions from '../../redux/actions/productActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as dashboardPageActions from '../../redux/actions/dashboardPageActions';
import * as scoreActions from '../../redux/actions/scoreActions';
import * as userActions from '../../redux/actions/userActions';

import ProductApi from '../../api/mockProductApi.js';
import LayoutApi from '../../api/mockLayoutApi.js';
import ArticlesApi from '../../api/mockArticlesApi.js';
import ScoreApi from '../../api/mockScoreApi.js';
import ValidationApi from '../../api/validationApi';

import * as ImagesPath from '../../config/imagesPath';
import FooterSmallSection from '../common/footerSmall';

import {tonikInitDashboard, tonikInitCarousel} from '../../tonik/dashboard';
import {tonikInitModal} from '../../tonik/modal';
import * as RoutesVariables from '../../config/routesVariables';

import {USERROLES, hasOneRole} from '../../common/gcsLogic';

const $ = window.$;

const Dummy = () => <div />;

export class DashboardPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    

    let controlWidgets = [
      DashboardScore,
      DashboardScoreHistory,
      DashboardScoreCompare
    ];

    let controlWidgetsNames = [
      'DashboardScore',
      'DashboardScoreHistory',
      'DashboardScoreCompare'
    ];

    this.state = {
      dashboardTabsWidgets: controlWidgets,
      controlWidgetsNames: controlWidgetsNames,
      scoreShown: false,
      theme : GlobalThemes.dashboard_themeSun,
      postCode: '',
      scoreMapHasError: false,
      scoreMapSubmittedEmpty: false,
      scoreMapSectionBackground: "scoremap-section-background",
      partnerId: null,
      showAdditionalOffers: false,      
    };

    this.redirect = this.redirect.bind(this);
    this.onDashboardTabChange = this.onDashboardTabChange.bind(this);    
    this.onActionItemClick = this.onActionItemClick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onProductAdditionalClick = this.onProductAdditionalClick.bind(this);        
    this.getComponentsList = this.getComponentsList.bind(this);    
    this.getDashboardContentForTab = this.getDashboardContentForTab.bind(this);
    this.getScoreComponent = this.getScoreComponent.bind(this);
    this.onScoreShown = this.onScoreShown.bind(this);    

    this.getComponentsListForOneScore = this.getComponentsListForOneScore.bind(this);
    this.getComponentsListForOriginalDesign = this.getComponentsListForOriginalDesign.bind(this);    
  }

  componentDidMount() {
    
    this.props.actions.isAuthenticated(USERROLES.IdentityVerified).then(e =>
    {
      
      if(!hasOneRole(e.roles, USERROLES.IdentityVerified) ||
         !hasOneRole(e.roles, USERROLES.TwoFactor)){
         this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteDashboard);
         return;
      }

      this.props.actions.initUser(true).then((initUserResponse) => {        
        this.props.actions.getMenuForPage(pages.DASHBOARD);

        let theme = null;
        if (initUserResponse["themes"] && initUserResponse["themes"]["dashboard"]) {
          theme = GlobalThemes["dashboard_" + initUserResponse["themes"]["dashboard"]];
        }

        let shouldExcludeTab = false;
        if (theme) {
          this.setState({theme: theme});
          shouldExcludeTab = !theme.showNextStepsSection;
        }

        this.props.actions.loadDashboardTabs(shouldExcludeTab ? 2 : "", true);

        this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true}).then(e => {
              this.props.actions.loadProducts().then((productResult) => {
               
                 this.setState({ partnerId : productResult.partnerId});
                 this.props.actions.loadCallsToAction();

              }).catch(e => {
                  this.props.actions.loadCallsToAction();
              });

              this.props.actions.loadArticlesForDashboard();
          
          });
        });
    });

    this.props.actions.getTextVariants();

    funcs.forceScrollFromUrl();

    tonikInitDashboard();
    tonikInitModal();
  }  

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!nextProps.isAuthenticated) {
        // go back to login
        this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteDashboard);
      }
    }    
  }

  onDashboardTabChange(tabIndex, event) {
    const dashboardTab = this.props.dashboardTabs.find(item => item.tabIndex === tabIndex);
    const tabName = dashboardTab && dashboardTab.key;

    this.props.actions.updateSelectedDashboardTab(tabIndex);
    this.props.actions.trackDashboardTab(tabName);
  }

  onScoreShown(shown){
    //console.log("score shown " + shown);
    this.setState({scoreShown: shown});
  }

  redirect(url) {
      this.props.history.push(url);
  }

  onActionItemClick(evt, actionId) {
    if (this.props.callsToAction && this.props.callsToAction.length > 0) {
      var scoreAction = null;
      for (var actionIndex in this.props.callsToAction)  {
        if (this.props.callsToAction[actionIndex].id == actionId) {
          scoreAction = this.props.callsToAction[actionIndex];
          break;
        }
      }

      if(scoreAction && scoreAction.trackClick){
        this.props.actions.trackGcsProductClick(
          {
            partnerId : scoreAction.partnerId,
            productTypeId: scoreAction.productTypeId,
            productId:  scoreAction.productId
          },
          pages.DASHBOARD,
          'Action'
        );
      }

      this.props.actions.trackScoreActionClick(actionId);
    }

    this.props.actions.trackScoreActionClick(actionId);
    
    return false;
  }

  onProductClick(event, product){
    this.props.actions.trackGcsProductClick(product, pages.DASHBOARD);
  }

  onProductAdditionalClick(event, product){
    this.props.actions.trackGcsProductClick(product, pages.DASHBOARD);
  } 

  getComponentsList(){    

    return this.props.isInCohort ?
     this.getComponentsListForOneScore() :
     this.getComponentsListForOriginalDesign()
  }

  getComponentsListForOriginalDesign(){
    let i = 0;

    let messageProductTitle = this.props.textVariants[this.state.theme.productTitleKey];
    let userProfile = this.props.userProfile;    

    if (this.state.theme.key == 'DashboardThemeSun') {
      let scoreInfo = this.props.scoreProfile["scoreInfo"];

      if (scoreInfo && scoreInfo.bandRange && userProfile && scoreInfo.bandRange != '0-459') {
        messageProductTitle = this.props.textVariants["score_result_sell-" + scoreInfo.bandRange];
        if (messageProductTitle) {
          messageProductTitle = messageProductTitle.replace('{displayName}', userProfile.displayName);
        }
      }
    }    

    let callsToActionContent = <div></div>;
    if (this.state.theme && this.state.theme.showCallToActions() && this.state.partnerId != GlobalFixedVariables.PARTNER_TOYOTA) {
      let cssClassCallToAction = this.state.theme.hasAnimations ? " animated fadeInRight delay-1  " :"";
      cssClassCallToAction += this.state.theme.showNextStepsSection ? " hidden-xs hidden-sm ": "";

      callsToActionContent = (
        <DashboardCallsToAction
          loading={this.props.scoreProfile.loading}
          callsToAction={this.props.callsToAction}
          onActionItemClick={this.onActionItemClick}
          textVariants={this.props.textVariants}
          carouselId="win"
          cssClass={cssClassCallToAction}
        />
      );
    }

    let product_comp = (
        <section key={i++} className="section-current-deal">
          <div className="container">
            <div className="row">
              <div className="current-deal-wrapper">
                <div className="col-md-6 col-12 current-deal-item">
                  <h2 className="offers-link">Your featured offers</h2>
                  <ProductSection                    
                    textVariants={this.props.textVariants}
                    products={this.props.products}
                    productsByType={this.props.productsByType}
                    loading={this.props.productLoading && !this.state.scoreShown}                    
                    onProductClick={this.onProductClick}
                    displayMobileOption={this.state.theme.productDisplayOption}
                    cssClass="dashboard-products"
                    title={messageProductTitle}
                    isDashboard={true}
                  /> 
                  
                  {this.props.productsByType && Object.keys(this.props.productsByType).length > 0 &&
                   <>
                    <h2><a className="offers-link-additional" href="" onClick={(e) => {e.preventDefault(); this.setState({showAdditionalOffers: !this.state.showAdditionalOffers}); return false; }}>See all other available offers</a></h2>

                     {this.state.showAdditionalOffers && <ProductAdditionalSection                    
                        textVariants={this.props.textVariants}                        
                        productsByType={this.props.productsByType}
                        loading={this.props.productLoading && !this.state.scoreShown}                        
                        onProductClick={this.onProductClick}                                                
                        title={messageProductTitle}
                        isDashboard={true}
                      />}
                    </>
                    }
                </div>

                {callsToActionContent}

                <div className="link-fixed">
                  <a href={RoutesVariables.RouteHome} >Home</a>
                  {/* <a href={RoutesVariables.RouteCreditSummary} className="link-credit"><span>CREDIT REPORT OVERVIEW <span className="number">2</span></span> </a> */}
                  {this.props.isInCohort && <a href={RoutesVariables.RouteCreditSummary} ><span>CREDIT REPORT</span> </a>}
                  <a href="#next-step" className="scrollable">Next steps</a>
                </div>

              </div>
            </div>
          </div>
        </section>
    );

    let nextSteps_comp = <div key={i++}></div>;

    if (this.state.theme.showNextStepsSection && this.state.partnerId != GlobalFixedVariables.PARTNER_TOYOTA) {
      nextSteps_comp = ( <span key={i++}></span>
        // <NextStepsSection
        //   key={i++}
        //   loading={this.props.scoreProfile.loading}
        //   callsToAction={this.props.callsToAction}
        //   onActionItemClick={this.onActionItemClick}
        //   textVariants={this.props.textVariants}
        //   cssClass=" hidden-md hidden-lg"
        //   carouselId="section"
        // />
      );
    }


    let lowScoreComponentList = [
      product_comp,
      nextSteps_comp
    ];   

    let returnList = lowScoreComponentList; 

    return returnList;
  }

  getComponentsListForOneScore(){    
    let i = 0;
    let messageProductTitle = this.props.textVariants[this.state.theme.productTitleKey];
    let userProfile = this.props.userProfile;    

    if (this.state.theme.key == 'DashboardThemeSun') {
      let scoreInfo = this.props.scoreProfile["scoreInfo"];
      if (scoreInfo && scoreInfo.bandRange && userProfile && scoreInfo.bandRange != '0-459') {
        messageProductTitle = this.props.textVariants["score_result_sell-" + scoreInfo.bandRange];
        if (messageProductTitle) {
          messageProductTitle = messageProductTitle.replace('{displayName}', userProfile.displayName);
        }
      }
    }    
    
    var areProducts = !this.props.productLoading && this.state.scoreShown && this.props.products != null && this.props.products.length > 0;

    let callsToActionContent = <div></div>;
    if (this.state.theme && this.state.theme.showCallToActions() && this.state.partnerId != GlobalFixedVariables.PARTNER_TOYOTA) {
      let cssClassCallToAction = this.state.theme.hasAnimations ? " animated fadeInRight delay-1  " :"";
      cssClassCallToAction += this.state.theme.showNextStepsSection ? " hidden-xs hidden-sm ": "";

      callsToActionContent = (
        <DashboardCallsToAction
          loading={this.props.scoreProfile.loading}
          callsToAction={this.props.callsToAction}
          onActionItemClick={this.onActionItemClick}
          textVariants={this.props.textVariants}
          carouselId="win"
          cssClass={cssClassCallToAction}
        />
      );
    }    


    let product_comp = <div></div>;    

    let areProductClass = areProducts ? '':' d-none ';

    var areKcfs = this.props.scoreProfile && this.props.scoreProfile.scoreAdvice && this.props.scoreProfile.scoreAdvice.kcfs && this.props.scoreProfile.scoreAdvice.kcfs.length;

    var classForProducts= areKcfs ? "col-xs-12 col-sm-12 col-md-12 col-lg-6 order-2 order-xs-2 order-sm-2 order-md-2 order-lg-1 ":
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2 order-2 order-xs-2 order-sm-2 order-md-2 order-lg-1 ";

    var classForKcf = areKcfs ? "col-xs-12 col-sm-12 col-md-12 col-lg-6 order-1 order-xs-1 order-sm-1 order-md-1 order-lg-1 d-flex align-items-stretch":
                      "d-none";

    product_comp = (
      <section key={i++} className={areProductClass + "section-current-deal"}>
        <div className="container">
          <div className="row d-flex align-items-lg-stretch">
            <div className={classForProducts}>
              <div className="current-deal-wrapper nv-bg-color-white nv-dashboard-prd-overlay ">
                <div className="current-deal-item nv-deals-wrapper nv-deals-padding">
                    <div className="d-flex mb-4">
                        <span className="offers-link align-self-center ml-4 ">Your featured offers</span>
                        <button className="btn btn-primary ml-auto px-2 scroll-to-id" data-scroll-to-id="nv-next-steps-anchor">Unlock more deals</button>
                    </div>
                    
                      <ProductSection                    
                        textVariants={this.props.textVariants}
                        products={this.props.products}
                        productsByType={this.props.productsByType}
                        loading={this.props.productLoading && !this.state.scoreShown}                    
                        onProductClick={this.onProductClick}
                        displayMobileOption={this.state.theme.productDisplayOption}
                        cssClass="dashboard-products"
                        title={messageProductTitle}
                        isDashboard={true}
                      /> 
                      
                      {this.props.productsByType && Object.keys(this.props.productsByType).length > 0 &&
                      <>
                      <div className="row nv-deals-padding">
                          <span><a className="offers-link-additional pl-4 pb-4" href="" onClick={(e) => {e.preventDefault(); this.setState({showAdditionalOffers: !this.state.showAdditionalOffers}); return false; }}>See all other available offers</a></span>

                            {this.state.showAdditionalOffers && <ProductAdditionalSection                    
                                textVariants={this.props.textVariants}                        
                                productsByType={this.props.productsByType}
                                loading={this.props.productLoading && !this.state.scoreShown}                        
                                onProductClick={this.onProductClick}                                                
                                title={messageProductTitle}
                                isDashboard={true}
                                cssClass=" nv-deals-padding"                                
                              />}                        
                        </div>
                        </>
                        }
                    </div>
                </div>
              </div>

              <div className={classForKcf}>
                  <DashboardKcf
                    isInCohort={this.props.isInCohort}
                    kcfsArray={this.props.scoreProfile.scoreAdvice.kcfs}
                  />
              </div>
            </div>
                    
        </div>
      </section>
    );
    

    let nextSteps_comp = <div key={i++}></div>;

    if (this.state.theme.showNextStepsSection && this.state.partnerId != GlobalFixedVariables.PARTNER_TOYOTA) {
      nextSteps_comp = ( <span key={i++}></span>       
      );
    }   

    let hightScoreComponentList = [
      product_comp,
      nextSteps_comp,

      <DashboardCallsToActionSection    
          key={i++}      
          loading={this.props.scoreProfile.loading}
          callsToAction={this.props.callsToAction}
          onActionItemClick={this.onActionItemClick}
          textVariants={this.props.textVariants}
          carouselId="win2"
          cssClass=" hidden-xs hidden-sm "
          currentSlide={this.state.currentSlide}
        />,
        <DashboardFloatingLinks
           key={i++}
           isInCohort={this.props.isInCohort}
        />
    ];

    let returnList = hightScoreComponentList; 

    return returnList;
  }
 

  getDashboardContentForTab() {
    // load the properties in the widgets
    let DashComponent = Dummy;
  
    let dashContent = (
      <DashboardScore
        loading={this.props.scoreProfile.loading}
        textVariants={this.props.textVariants}
        scoreProfile={this.props.scoreProfile}
        actions={this.props.actions}
        userProfile={this.props.userProfile}
        onScoreShown={this.onScoreShown}
        theme={this.state.theme}
        onTabChange={this.onDashboardTabChange}
      />
    );

    let callsToActionContent = <div></div>;
    if (this.state.theme && this.state.theme.showCallToActions() && this.state.partnerId != GlobalFixedVariables.PARTNER_TOYOTA) {
      let cssClassCallToAction = this.state.theme.hasAnimations ? " animated fadeInRight delay-1  " :"";
      cssClassCallToAction += this.state.theme.showNextStepsSection ? " hidden-xs hidden-sm ": "";

      callsToActionContent = (
        <DashboardCallsToAction
          loading={this.props.scoreProfile.loading}
          callsToAction={this.props.callsToAction}
          onActionItemClick={this.onActionItemClick}
          textVariants={this.props.textVariants}
          carouselId="win"
          cssClass={cssClassCallToAction}
        />
      );
    }

    let hasNextSteps = !(this.props.callsToAction.length == 0);
    let columnsScore = hasNextSteps ? "col-md-8" : "";
    let columnsNextSteps = hasNextSteps ? "col-md-4" : "";

    let result = (
      <div className="row">
        <div className={columnsScore +  " col-xs-12 text-center score-info"}>
          {dashContent}
        </div>

        {/* <div className={columnsNextSteps + " col-xs-12 xs-pt-20 sm-pl-40 sm-pr-40 md-pt-0 sm-pt-0 lg-pt-0"} id="dashboard-next-steps-outer">
          {callsToActionContent}
        </div> */}
      </div>
    );

    //return result;
    return dashContent
  }

  getScoreComponent() {
    let scoreComponent = Dummy;

    if (this.props.scoreProfile.apiFail){
      scoreComponent = <ApiFailedToLoadComponentWithText  text={this.props.textVariants["Api_Equifax_Down"] } />;
    } else {
        scoreComponent = <DashboardScore
        loading={this.props.scoreProfile.loading}
        textVariants={this.props.textVariants}
        scoreProfile={this.props.scoreProfile}
        actions={this.props.actions}
        userProfile={this.props.userProfile}
        onScoreShown={this.onScoreShown}
        theme={this.state.theme}
        onTabChange={this.onDashboardTabChange}
      />
      }

      return scoreComponent;
    }

    render() {      

      let scoreComponent= this.getScoreComponent();
      let rerstOfTheSections = this.getComponentsList();

      return (
        <div className="dashboard">
          {this.props.isInCohort && <NewFeaturesBanner />}
          <span id="dashboard-menu"></span>
          <div className={this.props.isInCohort ? "page-bg nv-dashboard-bg": "page-bg"}>           

            <Menu menuItemList={this.props.menuItemList} user={this.props.userProfile} renderMobile={true} />
            {scoreComponent}
            </div>
            
            {rerstOfTheSections}

            <div className="footer-account">
            <FooterSmallSection />
            </div>
        </div>
      )
    }
};

DashboardPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.DASHBOARD]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.DASHBOARD];
     }
   }

  let txtVariants = {};
  if(state.textVariants){
    txtVariants = state.textVariants;
  }

  let products = [];
  let productLoading = null;
  if(state.productResult && state.productResult.products){
    products = state.productResult.products;
    productLoading = state.productResult.loading;
  }

  let productsByType = [];    
  if(state.productResult && state.productResult.productsByType){
    productsByType = state.productResult.productsByType;    
  }

  let callsToAction = [...state.dashboardPage.callsToAction];

  let isAnonymous = true;
  if(state.productResult){
    isAnonymous = state.productMetadata.isAnonymous;
  }
  
  return {
    menuItemList: menuItems,
    textVariants: txtVariants,

    products : products,
    productLoading: productLoading,
    productsByType: productsByType,

    isAnonymous: isAnonymous,
    isAuthenticated: state.userProfile.isAuthenticated,
    identifier: state.userProfile.identifier,

    dashboardTabs: state.dashboardPage.dashboardTabs,
    callsToAction: callsToAction,
    articles: state.dashboardPage.articles,
    articlesLoaded: state.dashboardPage.articlesLoaded,
    articlesLoading: state.dashboardPage.articlesLoading,
    selectedDashboardTab: state.dashboardPage.selectedDashboardTab,

    scoreProfile: state.scoreProfile,
    userProfile:  state.userProfile,
    isInCohort: !!hasOneRole(state.userProfile.roles, USERROLES.IsInCohort)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, trackingActions, userActions, layoutActions, dashboardPageActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
