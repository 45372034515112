import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as funcs from '../../../shared/functions';
import * as pages from '../../../shared/pages';
import * as GlobalVariables from '../../../../config/configureGlobalVariables';

import {WizardCoreClass} from '../WizardCore';
import {WizardAboutMeStepsDef, WizardAboutMeStepState, WizardAboutMeStepComponents, StepsIndexAboutMe, ThemePack} from './AboutMeStepDef';

export class AboutMeWizard extends WizardCoreClass {

    constructor(props, context) {
        super(props, context);
        //  this.props.actions.trackAboutMe('View Step', wizardStepsDef[this.state.wizardCurrentStep], this.state.wizardCurrentStep);
    }

    _getTheme(){
      return ThemePack;
    }

    _getInitialState(){
      return WizardAboutMeStepState;
    }

    _getWizardComponents(){
      return WizardAboutMeStepComponents;
    }

    _getStepIndex(){
      return StepsIndexAboutMe;
    }

    _getStepDefinition(){
      return WizardAboutMeStepsDef;
    }

    _onSaveStep(savedStateStep, stepDefinition){
      this.props.onSaveStep(savedStateStep, stepDefinition);
    }

    _onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex){
        this.props.onCustomAction(fullStep, stepState, stepsDefinition, stepsState, stepIndex);
    }

    _trackViewStep(actionStr, stepDefinition, currentStep){
       this.props.actions.trackAboutMe(actionStr, stepDefinition, currentStep);
    }

    // _loadExtraConfiguration(wizardState, extraConfig){
    // }

    _refreshNavigationFlow(wizardStepIndex, stepsState){
      if(stepsState && stepsState.steps && stepsState.steps.length > 0){
           var steps = stepsState.steps;
           //stepIndexWhyGetScore opt 1 and 2 open stepIndexHelpForBetterDeal.
           steps[wizardStepIndex.stepIndexHelpForBetterDeal].enabled = steps[wizardStepIndex.stepIndexWhyGetScore].selectedItems[0] || steps[wizardStepIndex.stepIndexWhyGetScore].selectedItems[1];

           // stepIndexEmploymentStatus opt 1 and 2 open stepIndexYourIncome
           steps[wizardStepIndex.stepIndexYourIncome].enabled = steps[wizardStepIndex.stepIndexEmploymentStatus].selectedItems[0] || steps[wizardStepIndex.stepIndexEmploymentStatus].selectedItems[1];
           
           steps[wizardStepIndex.stepIndexFinish].enabled = true;
      }
    }
}

AboutMeWizard.contextTypes = {
  router: PropTypes.object
};