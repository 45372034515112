
import * as GlobalVariables from './configureGlobalVariables';

export const RouteHome = '/home';
export const RouteCreateAccount = '/create-account';
export const RouteCreateAccountVerifyEmail = '/create-account/verify-email';
export const RouteLogin = '/login';
export const RouteLogin2FA = '/login-2fa';
export const RouteContactUs = '/contact-us';
export const RouteContactUsSuccess = '/contact-us-success';
export const RouteLodgeacomplaint = '/Lodge-a-complaint';
export const RouteLodgeacomplaintSuccess = '/Lodge-a-complaint-success';
export const RouteTermsOfUse = '/terms-of-use';
export const RoutePrivacyPolicy = '/privacy-policy';

export const RouteArticles = '/articles';
export const RouteCreditScore = '/dashboard';
export const RouteDashboard = '/dashboard';
export const RouteLearnMore = '#learn-articles-section';
export const RouteHelpCenter = '/helpcenter';
export const RouteRegisterId = '/register-id';
export const RouteCreditSummary = '/credit-report-overview';
export const RouteForgotPassword = '/forgot-password';
export const RouteFreeCreditScore = '/free-credit-score';
export const RouteDashboardScoreHistory = '/dashboard-score-history';
export const RouteCheckYourInbox = '/check-your-inbox';
export const RouteMyPreferences = '/my-preferences';

export const RouteChangePassword = '/change-password';
export const DeleteAccount = '/delete-account';
export const MyDetails = '/my-details';
export const MyDetailsVerification = '/my-details/verification';
export const CreateAccount = '/create-account';
export const CreateAccountMobile = '/create-account/mobile';
export const CreateAccountVerifyMobile = '/create-account/verify-mobile';

export const AboutMe = '/about-me';


export const RouteExternalHome = GlobalVariables.WEB_BASE_URL;
export const RouteExternalHomeSPA = GlobalVariables.WEB_SPA_URL;

export const RouteContactUsFix = GlobalVariables.WEB_BASE_URL + 'contact-us';
export const RouteLodgeacomplaintFix = GlobalVariables.WEB_BASE_URL + 'contact-us';

export const RouteExternalTermsOfUse = GlobalVariables.WEB_BASE_URL + 'terms-of-use';
export const RouteExternalPrivacyPolicy = GlobalVariables.WEB_BASE_URL + 'privacy-policy';
export const RouteExternalHelpCenter = GlobalVariables.WEB_BASE_URL + 'helpcentre';
export const RouteExternalArticles = GlobalVariables.WEB_BASE_URL + 'articles';

export const RouteGetMyCreditScore = GlobalVariables.WEB_BASE_URL + 'faq/get-my-credit-score';
export const RouteImproveMyCreditScore = GlobalVariables.WEB_BASE_URL + 'faq/improve-my-credit-score';
export const RouteUseMyCreditScore = GlobalVariables.WEB_BASE_URL + 'faq/use-my-credit-score';
export const RouteMyCreditReport = GlobalVariables.WEB_BASE_URL + 'faq/my-credit-report';
export const RouteMythsVsFacts = GlobalVariables.WEB_BASE_URL + 'faq/myths-vs-facts';
export const RouteGeneralInformation = GlobalVariables.WEB_BASE_URL + 'faq/general-information';