import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from '../login/LoginBoxMessage';
import * as funcs from '../shared/functions';
import * as RoutesVariables from '../../config/routesVariables';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as pages from '../shared/pages';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import FooterSmallSection from '../common/footerSmall';
import VerificationAPI from '../../api/verificationApi';
import TextNumericInput from '../common/controls/TextNumericInput';
import GcsRecaptcha from "../common/GcsRecaptcha";

import * as ImagesPath from '../../config/imagesPath';

import { USERROLES, hasOneRole } from '../../common/gcsLogic';


class RegistrationMobilePage extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = { 
        mobile: '',
        errorMessage: '',
        infoMessage: '',
        otcError: null,
        saving: false,
        resetRecaptcha: false,
        shouldResend: false
      };      

      this.onChangeMobile = this.onChangeMobile.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.onResendSMS = this.onResendSMS.bind(this);          
      this.redirectToVerifyMobile = this.redirectToVerifyMobile.bind(this);
      this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
      this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  
      this.doSubmit = this.doSubmit.bind(this);
      this.doResendSMS = this.doResendSMS.bind(this);

      this.recaptchaRef = React.createRef();  
  }

  componentDidMount() {    
    this.props.actions.getMenuForPage(pages.REGISTER);
    this.props.actions.getTextVariants();
    this.doResetRecaptcha();

    this.props.actions.isAuthenticated().then(e => {
    });
  }

  componentWillReceiveProps(nextProps) {      
    if (!nextProps.isAuthenticated) {    
      this.props.history.push(RoutesVariables.RouteLogin);          
    }  

    this.doResetRecaptcha();
  } 

  onSelectRecaptcha(token){
   
    if (token) {
      if(this.state.shouldResend){
        this.setState({shouldResend: false},
           () => this.doResendSMS(token) );        
      }
      else{
        this.doSubmit(token);
      }
    }      

    this.setState({resetRecaptcha: true});
  }

  doResetRecaptcha(){
    if (this.state.resetRecaptcha) {
      try{
        this.recaptchaRef.current.reset();
      }
      catch(ex){
        console.log(ex);
      }
      this.setState({resetRecaptcha: false});
    }
  }
  
  onChangeMobile(event) {
    
    //let mobile = this.state.mobile;
    let mobile = event.target.value;

    return this.setState({ mobile: mobile, otcError: null });
  }

  onFormSubmit(event){

    event.preventDefault();
    
    // Validate    
    if (!this.state.mobile || (this.state.mobile.length != 10 && !this.state.mobile.match(/^[0-9]+$/g))){
      this.setState({otcError: 'The One Time Code is not Valid'});       
    }
    else{
      this.recaptchaRef.current.execute();
    }
  }

  doSubmit(token){
    this.setState({ errorMessage: null, infoMessage: null, saving: true });
    // Send Verification Code
    VerificationAPI.sendMobileOneTimeCodeRegistration(this.props.userProfile.csrfToken, this.state.mobile, token).then( (verification) => {
      // Redirect to re-auth
      if(verification.isError){
        this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
      } else{
        this.redirectToVerifyMobile();
      }
    }).catch( e => {
      
      if( e.status == 400){
              e.json().then( verification => {
                  this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
               });
      } else {
          this.setState({ errorMessage: 'An unexpected error occurred. Please <a href="/contact-us" style="float:none !important;">contact us</a>.', infoMessage: null });
      }
      }).finally( () => {
          this.setState({saving: false});
      });

  }

  redirectToVerifyMobile(){
    this.props.history.push(RoutesVariables.CreateAccountVerifyMobile);
  } 

  onResendSMS(event){
    event.preventDefault();
    this.setState({shouldResend: true}, ()=> {
      this.recaptchaRef.current.execute();
    });    
  }

  doResendSMS(token){
    VerificationAPI.sendMobileOneTimeCodeRegistration(this.props.userProfile.csrfToken, this.state.mobile, token).then(() =>{
      this.setState({infoMessage: 'A new SMS has been sent.'});
      var that = this;
        setTimeout(() => {
          that.setState({infoMessage: null});
        }, 4000);
    }).catch(e => {
      if(e.status == 400){
        e.json().then( resp => {
          this.setState({errorMessage: resp.errorMessage});
        }) 
      } else{

        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another SMS verification'});
      }
    });

  }
  

  render() {       
    return (        
        <div className="bg-circle n-expand">
          <GcsRecaptcha
              recaptchaRef={this.recaptchaRef}
              onSelectRecaptcha={this.onSelectRecaptcha}
            />
            <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} /> 
            <section className='section-form-log page-register n-100vh'>
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 form-log-wrapper">                      
                      <div className="nav-register n-nav-register-3"> {/*nav-register-2 */}
                         {/* <a href="" onClick={this.onBack} className="prev-form"><img src={ImagesPath.ImgIconPrev} className="img-fluid" alt="" /></a> */}
                        <h2><span>3 <span className="separator" /> 6</span> Mobile Verification</h2>
                      </div>                                              
                      <div className="form-log-box form-log-box-n">
                        <div className="icon-register-verify">
                          <img src={ImagesPath.ImgIconSubmission} className="" alt="img-fluid" />
                        </div>
                        {/* <img src={ImagesPath.ImgLogo} className="img-fluid logo" alt=""/> */}
                        <p>Please enter your mobile number.<br />This is used to help us protect your account<br /> by providing a One Time Code.</p>
                        <form  onSubmit={this.onFormSubmit} className="d-flex justify-content-center align-items-center flex-column">
                          <div className="col-lg-6 col-12 small-input">
                          <TextNumericInput
                              name="mobile"
                              id="mobile"
                              placeholder="Mobile"
                              value={this.state.mobile}
                              label="Mobile"
                              onChange={this.onChangeMobile}
                              error={this.state.otcError} 
                              maxLength={10}
                              groupClass="form-label-group"/>                               
                          </div>
                          <div className="col-lg-6 col-12">
                          <button type="submit" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled': '')}>Send SMS</button>
                        </div>
                        {this.state.errorMessage && 
                          <div className="alert alert-danger">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.errorMessage)}></span>
                          </div>
                        }   
                         {this.state.infoMessage && 
                          <div className="alert alert-warning">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.infoMessage)}></span>
                          </div>
                        }          
                        {/* <p>Did not arrive yet? <a href="" onClick={this.onResendSMS}>Resend</a></p> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>  
              <FooterSmallSection />                  
        </div>
    );
  }
}

RegistrationMobilePage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.REGISTER]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.REGISTER];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,   
    userProfile:  state.userProfile, 
    isAuthenticated: state.userProfile.isAuthenticated,    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationMobilePage);
