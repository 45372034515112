import React from 'react';
import * as ImagesPath from '../../../../config/imagesPath';

const ProgressActionButtons = ({onAction, step, stepsState, currentStepIndex, theme, isBack=false, isFootButtons = false}) => {
  // Count Enabled steps only
  let len = currentStepIndex;

  for (let i = currentStepIndex; i < stepsState.length; i++) {
    if (stepsState[i]["enabled"]) {
      len++;
    }
  }

  // show back if there is at least one back
  let showBack = false;

  for (let i = currentStepIndex - 1; i >= 0 && !showBack; i--) {
    if(stepsState[i]["enabled"]){
      showBack = true;
    }
  }

  let isCompleted = stepsState[currentStepIndex]["isCompleted"];
  //let showBack = currentStepIndex > 0;
  let showNext = currentStepIndex < len - 1;
  let showDone = currentStepIndex == len - 1 && !step["hideNavigationDone"];
  let disabled = isCompleted ? "":"disabled";
  let linkStyle = " btn wizard-navigation-btn " + disabled;

  if (!isCompleted && disabled && step["stepSelectionnUnique"]) {
    showNext = false;
  }

  return (
    <>
    { isFootButtons && (
      <div className="d-inline-flex full-width">
        <div class="w-50 mr-1 w-50 mr-1 justify-content-end d-flex">
        { showBack && isFootButtons &&  <button className="btn btn-secondary full-width mr-2 ml-2 mt-2" style={{maxWidth:'180px'}} onClick={() => onAction(false)}>Back</button>}
        </div>
        <div class="w-50 ml-1">
        { showNext && !showDone && !isBack && <button type="button"  onClick={() => onAction(true)} className="btn btn-primary full-width mr-2 ml-2 mt-2" style={{maxWidth:'180px'}}>Next</button>}
        { !showNext && showDone && !isBack && <button type="button" onClick={() => onAction(true, 'done')} className="btn btn-primary full-width mr-2 ml-2 mt-2" style={{maxWidth:'180px'}}>Done</button> }
        </div>
      </div>
      )
    }
      { showBack && isBack &&  <a className="back" onClick={() => onAction(false)}><img src={ImagesPath.ImgIconSvgArrowLeftBlue} className="img-fluid" alt=""/> Back</a>}
    </>
  );
}

export default ProgressActionButtons;