import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as ImagesPath from '../../config/imagesPath';

const ScoreMapSection = ({postCode, onGetScoreClick, onScoreMapInputChange, onScoreMapSubmit, scoreMapHasError, scoreMapSubmittedEmpty, scoreMapSectionBackground, findAreaByPostCodeFailed, textVariants, hasShadow = false}) => {
  return (
    <section className={"scoremap-section " + scoreMapSectionBackground}>
      {hasShadow && <div className="top-shadow"></div>}
      <div className="section-wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="text-center">
              <div className="scoremap-title">
                <span className="scoremap-title-normal">
                  {textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "titleNormal"]}
                </span>
                <span className="scoremap-title-bold scoremap-has-disclaimer">
                  {textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "titleBoldWithDisclaimer"]}
                </span>
                <span className="scoremap-title-bold">
                  {textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "titleBold"]}
                </span>
              </div>
            <div>
              <span className="scoremap-subtitle">
                {textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "subtitle"]}
              </span>
            </div>
            <div className="scoremap-form">
              <form onSubmit={onScoreMapSubmit}>
                <div className="scoremap-input-group">
                  <div className={"scoremap-control-container" + (scoreMapHasError ? " has-error" : "")}>
                    <span className="scoremap-input-error-container">
                      <input
                        type="number"
                        pattern="\d*"
                        maxLength="4"
                        name="postCode"
                        id="scoremapPostCode"
                        className={"form-control input-lg scoremap-postcode-input " + (scoreMapHasError ? "scoremap-has-error" : "")}
                        value={postCode}
                        onChange={onScoreMapInputChange}
                        placeholder={textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "postCodePlaceHolder"]} />
                      <span className={(scoreMapSubmittedEmpty ? "" : (scoreMapHasError ? "glyphicon form-control-feedback glyphicon-remove" : ""))} />
                    </span>
                  </div>
                  <div className="scoremap-control-container">
                     <button
                       type="submit"
                       className="btn btn-default input-lg scoremap-compare-button">
                       {textVariants[pages.SCOREMAPSECTION + textVariants.textVariantSeparator + "compareNowButtonText"]}
                     </button>
                  </div>
                </div>
                  <div className="scoremap-postcode-noresult">
                    {findAreaByPostCodeFailed &&
                    <span>{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "errorPostCodeNoResult"]}</span>
                    }
                 </div>
              </form>
              </div>
              <div className="scoremap-avatars">
                <img src={ImagesPath.ImgScoreMap_Avatar} alt="Generations" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ScoreMapSection.propTypes = {
  postCode: PropTypes.string.isRequired,
  onGetScoreClick: PropTypes.func,
  onScoreMapInputChange: PropTypes.func.isRequired,
  onScoreMapSubmit: PropTypes.func.isRequired,
  scoreMapHasError: PropTypes.bool.isRequired,
  scoreMapSubmittedEmpty: PropTypes.bool.isRequired,
  scoreMapSectionBackground: PropTypes.string,
  textVariants: PropTypes.object.isRequired,
  hasShadow: PropTypes.bool
};

ScoreMapSection.defaultProps = {
  scoreMapSectionBackground: ""
};

export default ScoreMapSection;
