export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

export const AJAX_LOAD_SCORE = 'AJAX_LOAD_SCORE';

export const TRACK_PRODUCTS_SUCCESS = 'TRACK_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOADING_PRODUCTS = 'LOADING_PRODUCTS';
export const FORCE_LOADING_PRODUCTS = 'FORCE_LOADING_PRODUCTS';
export const SET_LASTCLICKEDPRODUCT = 'SET_LASTCLICKEDPRODUCT';

export const LOADING_CREDITCARDS = 'LOADING_CREDITCARDS';
export const LOAD_CREDITCARDS_SUCCESS = 'LOAD_CREDITCARDS_SUCCESS';
export const FORCE_LOADING_CREDITCARDS = 'FORCE_LOADING_CREDITCARDS';
export const TRACK_CREDITCARDS_SUCCESS = 'TRACK_CREDITCARDS_SUCCESS';

export const UPDATE_PRODUCT_METADATA = 'UPDATE_PRODUCT_METADATA';

export const LOAD_MENU_SUCCESS = 'LOAD_MENU_SUCCESS';

export const LOAD_KEYFEATURES_SUCCESS = 'LOAD_KEYFEATURES_SUCCESS';
export const LOAD_PROCESSSTEPS_SUCCESS = 'LOAD_PROCESSSTEPS_SUCCESS';
export const LOADING_HOMEPAGE_ARTICLES_SUCCESS = 'LOADING_HOMEPAGE_ARTICLES_SUCCESS';
export const LOAD_HOMEPAGE_ARTICLES_SUCCESS = 'LOAD_HOMEPAGE_ARTICLES_SUCCESS';

export const PROMOTION_PARTICIPATED = 'PROMOTION_PARTICIPATED';

export const API_FAIL = 'API_FAIL_';

export const REGISTERING_USERNAME = 'REGISTERING_USERNAME';

export const USER_PROFILE_DISPLAYNAME = 'USER_PROFILE_DISPLAYNAME';

export const IS_AUTHENTICATED_SUCCESS = 'IS_AUTHENTICATED_SUCCESS';

export const LOAD_DASHBOARD_TABS_SUCCESS = 'LOAD_DASHBOARD_TABS_SUCCESS';
export const LOAD_SCORE_CALLTOACTION_SUCCESS = 'LOAD_SCORE_CALLTOACTION_SUCCESS';
export const LOADING_DASHBOARD_ARTICLES_SUCCESS = 'LOADING_DASHBOARD_ARTICLES_SUCCESS';
export const LOAD_DASHBOARD_ARTICLES_SUCCESS = 'LOAD_DASHBOARD_ARTICLES_SUCCESS';
export const UPDATE_DASHBOARD_TABINDEX_SUCCESS = 'UPDATE_DASHBOARD_TABINDEX_SUCCESS';
export const LOAD_HOMEFAQS_SUCCESS = 'LOAD_HOMEFAQS_SUCCESS';

export const RESET_SCORE_PROFILE = 'RESET_SCORE_PROFILE';
export const LOAD_SCORE_PROFILE_SUCCESS = 'LOAD_SCORE_PROFILE_SUCCESS';

export const RESET_REPORT_PROFILE = 'RESET_REPORT_PROFILE'
export const AJAX_LOAD_REPORT = 'AJAX_LOAD_REPORT'
export const LOAD_REPORT_PROFILE_SUCCESS = 'LOAD_REPORT_PROFILE_SUCCESS'

export const LOAD_TEXT_VARIANTS_SUCCESS = 'LOAD_TEXT_VARIANTS_SUCCESS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_INITIALIZE_STORE = 'LOGIN_INITIALIZE_STORE';
export const LOGIN_TM_SUCCESS = 'LOGIN_TM_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const CREATEACCOUNT_SUCCESS = 'CREATEACCOUNT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const INIT_USER_SUCCESS = 'INIT_USER_SUCCESS';

export const PASSWORDCHANGE_SUCCESS = 'PASSWORDCHANGE_SUCCESS';
export const LOADUSERDETAILS_SUCCESS = 'LOADUSERDETAILS_SUCCESS';
export const UPDATEUSERDETAILS_SUCCESS = 'UPDATEUSERDETAILS_SUCCESS';

export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const SET_PREFERENCES_SUCCESS = 'SET_PREFERENCES_SUCCESS';

export const GET_SCORE_COUNTER_SUCCESS = 'GET_SCORE_COUNTER_SUCCESS';
export const UPDATE_SCORE_COUNTER = 'UPDATE_SCORE_COUNTER';
export const UPDATE_SCORE_COUNTER_TIMER = 'UPDATE_SCORE_COUNTER_TIMER';

export const GET_EXISTING_CUSTOMER_SUCCESS = 'GET_EXISTING_CUSTOMER_SUCCESS';
export const GET_EXISTING_CUSTOMER_ERROR = 'GET_EXISTING_CUSTOMER_ERROR';

export const GET_CUSTOMER_HOMESHOW_CODE_SUCCESS = 'GET_CUSTOMER_HOMESHOW_CODE_SUCCESS';
export const GET_CUSTOMER_HOMESHOW_CODE_ERROR = 'GET_CUSTOMER_HOMESHOW_CODE_ERROR';

export const GET_CREDIT_SUMMARY_SUCCESS = 'GET_CREDIT_SUMMARY_SUCCESS';

export const INITAPP = "INITAPP";

// export const LOAD_AUTHORS_SUCCESS = 'LOAD_AUTHORS_SUCCESS';
// export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
// export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
