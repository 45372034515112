import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';

const ScoreMapLocation = ({ locationName, textVariants }) => {

  return (
    <div className="text-center scoremap-location scoremap-page-section">
      {
        locationName
        ? <p className="location-text">
            {textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "showResultFor"]}
            <span className="location-text-emphasise"> {locationName} </span> 
          </p>                        
        : <p className="location-text">We've compared over two million credit scores across 326 regions</p>
      }
      <a href="#scoremap-search">{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "changeLocation"]}</a>
    </div>
  );

};

ScoreMapLocation.propTypes = {
    locationName: PropTypes.string.isRequired,
    textVariants: PropTypes.object.isRequired
};
  
export default ScoreMapLocation;