import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as pages from '../../shared/pages';

import * as GlobalVariables from '../../../config/configureGlobalVariables';
import LoadingIndicator from '../../common/controls/LoadingIndicator';
import * as funcs from '../../shared/functions';
import * as selectors from '../../../selectors/selectors';
import {noScoreReasons, noScoreReasonsActions} from '../../shared/staticNoScoreReasons';

import {ProductLink} from '../../sections/ProductSection';
import * as ImagesPath from '../../../config/imagesPath';
import * as RoutesVariables from '../../../config/routesVariables';
import { setTimeout } from 'core-js';

import {tonikInitCarousel} from '../../../tonik/dashboard';

const DashboardCallToActionItem = ({actionItem, isActive, onActionItemClick}) =>
{
    let linkElement = <div />;

    if(actionItem.product){
      linkElement = <ProductLink
          product={actionItem.product}
          classCss="btn btn-primary full-width "
          onProductClick={(evt, product) => onActionItemClick(evt, actionItem.id)}
       >
           <span >{actionItem.actionText}</span>
       </ProductLink>;
    }
    else if(actionItem.href){
        linkElement = <a className="btn btn-primary full-width "
             href={actionItem.href}
             target={actionItem.linkTarget}
             onClick={(evt)=>onActionItemClick(evt, actionItem.id)}>
              <span >{actionItem.actionText}</span>
          </a>;
    }
    else if(actionItem.actionRoute){
      linkElement = <Link className="btn btn-primary full-width "
           to={actionItem.actionRoute}
           target={actionItem.linkTarget}
           onClick={(evt)=> onActionItemClick(evt, actionItem.id)}>
            <span >{actionItem.actionText}</span>
        </Link>;
    }


    return (
      <div className="slider-item">
        <h2>{actionItem.text}</h2>
        <img src={actionItem.imgUrl} className="img-fluid img-picture" alt=""/>
        <h3>
          {/* <span className="blue">only today</span>  */}
          {/*' Extra hospital cover with antenatal and postnatal care ' */} 
          {/* <span className="green">Best offer</span> */}
        </h3>
        {linkElement}
      </div>
    )
};
DashboardCallToActionItem.propTypes = {
actionItem: PropTypes.object.isRequired,
isActive:PropTypes.bool.isRequired,
onActionItemClick: PropTypes.func.isRequired
};

export class DashboardCallsToAction extends React.Component {
    constructor(props, context) {
      super(props, context);

      this.state = {
        currentSlide: 1,
        carouselMounted: false
      };

      this.carouselSlideChange = this.carouselSlideChange.bind(this);
    }

    componentDidMount() {
      tonikInitCarousel('#product-carousel', this.carouselSlideChange);
    }

    componentDidUpdate() {
      if(!this.state.carouselMounted && this.props.callsToAction && this.props.callsToAction.length > 0){
        tonikInitCarousel('#product-carousel', this.carouselSlideChange);
        this.setState({carouselMounted: true});
      }
    }

    carouselSlideChange(e, slide){
      e.preventDefault()      
      this.setState({currentSlide: slide})       
    }
    
    render(){

        var {textVariants, callsToAction, onActionItemClick, loading, carouselId = "", cssClass= ""} = this.props
        var total = callsToAction.length;

        if(total == 0){
          cssClass += " d-none";
        }
        else {
          cssClass += " d-block";
        }

        let mainComponent = <div></div>;
        if(loading) {
          mainComponent =<LoadingIndicator text={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "actions_loading_text"]}  />;
        }
        else {
        mainComponent = (
          <>
            <div className="col-md-6 col-12" className={"col-md-6 col-12 "}>
              <div className="col-12" id="next-step">
                <button type="button" className="next-step-prev slick-prev"></button>
                <button type="button" className="next-step-next slick-next"></button>
                <div className="slider-carousel" id="product-carousel">
                  {
                    callsToAction.map( (action, i) => <DashboardCallToActionItem key={i} isActive={i == 0} actionItem={action} onActionItemClick={onActionItemClick}  />)
                  }
                </div>
              </div>
            </div>
            
          </>
          )
        }

        return mainComponent;
        };

  }

DashboardCallsToAction.propTypes = {
textVariants: PropTypes.object.isRequired,
callsToAction: PropTypes.array.isRequired,
onActionItemClick: PropTypes.func.isRequired,
loading : PropTypes.bool.isRequired
};


//export default DashboardCallsToAction;
