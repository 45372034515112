import React from 'react';
import * as funcs from '../../../shared/functions';
import HLAOptionButton from './HLAOptionButton';

const HLAOptionButtons = ({step, stepState, onUpdate, cssClass, theme}) => {
  let component = <div></div>;

  let updateText = function(event, stepId, stepNro, questionId) {
    onUpdate(stepId, stepNro, questionId, event.target.value);
  };

  let moveForward = function(e) {
    e.preventDefault();
    onUpdate(step["id"], step["step"], 'keyvalue', stepState.selectedExtraText,  true);
  }

  if (step && stepState) {
    let i = 0;
    var actions = step["stepDefinition"]["actions"];
    let len = actions["length"];
    let showInCols = step["stepDefinition"]["showInCols"];

    if (!showInCols) {
      showInCols = 2;
    }

    let colNumber = 12 / showInCols;
    let modCol = len % showInCols;
    let lastNumCols = 0;

    if (modCol != 0) {
      lastNumCols = showInCols - modCol;
    }

    let offsetCol = "";
    let rows = [];
    let buttonArray = [];
    let rowColCounter = 0;

    while (i < len) {
      offsetCol = "";
      let r1 = actions[i];
      let r1IsSelected = stepState.selectedItems[i];

      if (modCol != 0 && (i == len - modCol)) {
        let offsetNumber = parseInt((12 - (colNumber * modCol)) / 2);
        offsetCol = "col-xs-offset-" + offsetNumber;
      }

      var item = null;
      if(step["selectedExtraIndex"] == i && !step["stepExtraDisabled"]){
        item = <HLAOptionButton showInCols={showInCols}  onClick={() => onUpdate(step["id"], step["step"], r1["id"])} item={r1}  isSelected={r1IsSelected} isSelectedExtraIndex={true} onSubmitExtraIndex={(e) => moveForward(e)} valueExtraText={stepState.selectedExtraText} onChangeExtraText={(evt) => updateText(evt, step["id"], step["step"], 'e')}/>
      }
      else {
        item =<HLAOptionButton showInCols={showInCols}  onClick={() => onUpdate(step["id"], step["step"], r1["id"])} item={r1}  isSelected={r1IsSelected} />
      }
      buttonArray.push(
        item
      );

      rowColCounter = rowColCounter + colNumber;

      if (rowColCounter >= 12 || i == (len - 1)) {
        rows.push(
             buttonArray
        );

        rowColCounter = 0;
        buttonArray = [];
      }

      i++;
    }

    let extraComponent = <div></div>;

    if (stepState.selectedItems[step["selectedExtraIndex"]] && !step["stepExtraDisabled"]) {
      extraComponent = <div className={"row wizard-action-btn-row " + theme["buttons"]}>
                          <div className="col-xs-12">
                            <form onSubmit={(e) => moveForward(e)}>
                              <div className="form-group">
                                <label>If Other, please complete:</label>
                                <input className="form-control input-lg wizard-action-input-txt" type='text' value={stepState.selectedExtraText} onChange={(evt) => updateText(evt, step["id"], step["step"], 'e')} />
                              </div>
                            </form>
                          </div>
                        </div>;
    }

    component = rows
  }

  return (component);
}

export default HLAOptionButtons;
