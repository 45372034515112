import React from 'react';
import PropTypes from 'prop-types';
import * as RoutesVariables from '../../../config/routesVariables';

export const DashboardFloatingLinks = (props) => {

return (
    <section className="section-current-deal d-block d-sm-none">
    <div className="container">

        <div className="row "> {/* Remove this or fix, it is the bottom navigation for mobile */}
          <div className="current-deal-wrapper nv-no-border">
            <div className="col-md-6 col-12 current-deal-item">                           
              <div className="link-fixed">
                <a href={RoutesVariables.RouteHome} >Home</a>
                {/* <a href={RoutesVariables.RouteCreditSummary} className="link-credit"><span>CREDIT REPORT OVERVIEW <span className="number">2</span></span> </a> */}
                {props.isInCohort && <a href={RoutesVariables.RouteCreditSummary} ><span>CREDIT REPORT</span> </a>}
                <a href="#next-step" className="scrollable">Next steps</a>
              </div>
            </div>
          </div>
        </div>

    </div>

  </section>
);

};

DashboardFloatingLinks.propTypes = {
    isInCohort: PropTypes.bool    
};

//isInCohort