import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import Menu from  '../menu/Menu.js'

import * as funcs from '../../components/shared/functions.js';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as productActions from '../../redux/actions/productActions';

import WizardApi from '../../api/WizardApi.js';

import * as ImagesPath from '../../config/imagesPath';
import {partnerHandshakeConfig} from '../shared/staticProducts';
import FooterSection from  '../common/footer'



export const ProductHandshakeWithProduct = ({partnerId, partnerUrl = "", errorMessage, product}) =>  {

  let loaderComponent =  <p className="pm-loader-wrap"><img className="img-fluid w-25" src={ImagesPath.ImgLoadingSpinner1} /></p>;

  var partnerConfig = product.productDisplayHandshake;
  if(errorMessage){
      loaderComponent = <div className="row">
                           <div className="alert alert-danger">
                           <span dangerouslySetInnerHTML={funcs.createMarkup(errorMessage)} />
                         </div>
                       </div>;
    }

  return (
    <>
        <div className="text-center" >
                  <div className="m-2">
                      <h1 className="text-dark">We are connecting you to</h1>
                  </div>
                  <div className="pm-partner-img-wrap m2">
                    <div className={"pm-partner-img-c " + partnerConfig["cssClass"]} >
                        <img className="w-50" src={partnerConfig.imgUrl} alt="partner-logo" />
                    </div>
                  </div>

                  <div className="animated fadeIn duration-3 delay-1 m-2">
                      {partnerConfig.title && <div className="partner-desc" >
                        <h2 className="desc" dangerouslySetInnerHTML={funcs.createMarkup(partnerConfig.title)} ></h2>
                      </div>}

                      <div className="row m-3">
                          <div className="col mr-auto ml-auto">
                                <ul className="list-group-flush p-0">
                                  {
                                      partnerConfig && partnerConfig.features && partnerConfig.features.map( feature => {
                                          return (<li className="list-group-item border-0" key={feature.key}><i class="fa fa-check m-2 text-success"></i><span>{feature.title}</span></li>)
                                      })
                                  }
                                </ul>
                            </div>
                       </div>
                   </div>

                  {loaderComponent}

                  <div className="pm-gcs-img-wrap">
                     <img className="pm-gcs-img" src={ImagesPath.ImgLogoGcsBlack} alt="partner-logo" />
                  </div>

                  {partnerUrl && <div className="pm-gcs-click-product-wrap"><span className="pm-click-product-s">Not redirecting?&nbsp;</span>
                   <a href={partnerUrl} >click here</a>
                  </div>}
          </div>
        
    </>
  );

}


export const ProductHandshakeClassic = ({partnerId, partnerUrl = "", errorMessage, product}) =>  {

  var partnerConfig = partnerHandshakeConfig[partnerId];
  if(!partnerConfig){
    partnerConfig = {};
  }

  let loaderComponent =  <p className="pm-loader-wrap"><img className="img-fluid w-25" src={ImagesPath.ImgLoadingSpinner1} /></p>;

    if(errorMessage){
      loaderComponent = <div className="row">
                           <div className="alert alert-danger">
                           <span dangerouslySetInnerHTML={funcs.createMarkup(errorMessage)} />
                         </div>
                       </div>;
    }

  return (
      <>
        <div className="text-center" >
                  <div className="m-2">
                      <h2>We are connecting you to</h2>
                  </div>
                  <div className="pm-partner-img-wrap m-2">
                    <div className={"pm-partner-img-c " + partnerConfig["cssClassForImage"]} >
                        <img className="pm-partner-img animated zoomIn " src={partnerConfig.logoImgUrl} alt="partner-logo" />
                    </div>
                  </div>

                  <div className="animated fadeIn duration-3 delay-1  m-2">
                      {partnerConfig.description && <div className="partner-desc" >
                        <span className="desc" dangerouslySetInnerHTML={funcs.createMarkup(partnerConfig.description)} ></span>
                      </div>}

                      <div className="row">
                          <div className="col mr-auto ml-auto">
                                <ul className="list-group">
                                  {
                                      partnerConfig && partnerConfig.features && partnerConfig.features.map( feature => {
                                          return (<li className="list-group-item" key={feature.key}><i class="fa fa-check m-2 text-success"></i><span>{feature.title}</span></li>)
                                      })
                                  }
                                </ul>
                            </div>
                       </div>
                   </div>

                  {loaderComponent}


                  <div className="pm-gcs-img-wrap">
                     <img className="pm-gcs-img" src={ImagesPath.ImgLogoGcsBlack} alt="partner-logo" />
                  </div>

                  {partnerUrl && <div className="pm-gcs-click-product-wrap"><span className="pm-click-product-s">Not redirecting?&nbsp;</span>
                   <a href={partnerUrl} >click here</a>
                  </div>}


          </div>
      </>

  );
}

export const ProductHandshake = (props) =>  {

  let returnControl = null;
  if(props.product && props.product.productDisplayHandshake){
      returnControl = <ProductHandshakeWithProduct {...props} />;
  }
  else{
    returnControl = <ProductHandshakeClassic {...props} />;
  }

  return returnControl;
}

class PartnerReferPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
          partnerId: "",
          partnerUrl: "",
          productId: "",
          product: null,
          errorMessage: null,
          mode:"d"
        }

        this.setProductActionProduct = this.setProductActionProduct.bind(this);
        this.setProductActionPush = this.setProductActionPush.bind(this);
    }

    componentDidMount() {
      //this.props.actions.getMenuForPage(pages.PARTNERCONSENT);

      this.props.actions.getTextVariants();
      let that = this;

      let productId = parse(this.props.location.search).productId;
      let mode = parse(this.props.location.search).m;
      let isNs = parse(this.props.location.search).isn == '1';
       if(!mode){
         mode = 'd';
       }

       this.setState({mode:mode});

       this.props.actions.loadProducts().then( productResult => {

          // find the product by product id and then refer after 4
          let product = funcs.searchInList(productResult.products, "id", productId);

          if(mode == 'd'){
              
                  if(!product || isNs){
                      let nextStep = funcs.searchInList(productResult.call2Actions, "productIdentifier", productId);
                      product = nextStep.product;
                  }

                  if(product){
                    that.setState({partnerId: product.partnerId, partnerUrl: product.href, product: product}, () => that.setProductActionProduct());
                  }
            }
            else if (mode == 'p') {

              if(!product){
                // Call product get display and show it

                that.props.actions.isAuthenticated().then(e => {

                  WizardApi.getWizardProfile(that.props.csrfToken, "hla").then( resp => {
                      let partnersWizard = resp.profile.extra.partners;
                      if(partnersWizard){
                        product = funcs.searchInList(partnersWizard, "productIdentifier", productId);
                      }

                      that.setState({partnerId: parse(this.props.location.search).partnerId, productId: productId, product: product}, () => that.setProductActionPush());

                  }).catch( error =>{

                    let msg = that.props.textVariants["Api_Generic"];
                    that.setState({errorMessage: msg});
                  
                  });
         
                });
               
              }
              else{
                this.setState({partnerId: parse(this.props.location.search).partnerId, productId: productId, product: product}, () => this.setProductActionPush());
              }

              
            }

        });
    }

    setProductActionProduct(){

      let product = this.state.product;

      let that = this;
      if(product){

          if(product.hasPushLead){
              this.props.actions.getProductRedirect(product).then( (redirectResult) => {

                that.setState({partnerUrl: redirectResult.url}, () =>{
                  setTimeout(() => { window.location.href = redirectResult.url;}, 3000);
                });

              }).catch(e => {

                  let msg = this.props.textVariants["Api_Generic"];
                  that.setState({errorMessage: msg});
              });
          }
          else{
               setTimeout(() => { window.location.href = product.href;}, 4000);
          }
      }
    }

    setProductActionPush(){

      let productId = this.state.productId;

      let that = this;
  
      if(productId){
        this.props.actions.getProductRedirect({id: productId, mode: parse(this.props.location.search).m, partnerId: this.state.partnerId}).then( (redirectResult) => {
              that.setState({partnerUrl: redirectResult.url}, () =>{
            
                window.location.href = redirectResult.url;
              });

            }).catch(e => {

                let msg = this.props.textVariants["Api_Generic"];
                that.setState({errorMessage: msg});
            });
      }
    }

    render() {

      let mainComponent = <div className="partner-refer-page"><img className="img-fluid w-25" src={ImagesPath.ImgLoadingSpinner1} /> </div>;
      if(!(this.props.productLoading || this.props.products == null || this.props.products.length == 0)){
        mainComponent = <ProductHandshake
            partnerId={this.state.partnerId}
            partnerUrl={this.state.partnerUrl}
            errorMessage={this.state.errorMessage}
            product={this.state.product}
         />;
      }

      return (

        <div className="page-home page-contact">
          <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>
          <div className="section-form-log">
            <div className="container">
              <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 form-log-wrapper">
                <div className="form-log-box">
                  {mainComponent}
                </div>
              </div>
            </div>
          </div>
          <FooterSection/>
        </div>
          );
    }
}

PartnerReferPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

   let products = [];
   let productLoading = null;
   if(state.productResult && state.productResult.products){
     products = state.productResult.products;
     productLoading = state.productResult.loading;
   }

  return {
    textVariants: txtVariants,
    products : products,
    productLoading: productLoading,
    csrfToken: state.userProfile.csrfToken
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, userActions, layoutActions, trackingActions, productActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerReferPage);
