import creditSummaryApi from '../../api/creditSummaryApi';
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';
import * as trackingActions from './trackingActions';


export function getCreditSummarySuccess(creditSummary) {
    return {type: types.GET_CREDIT_SUMMARY_SUCCESS, creditSummary: creditSummary};
  }
  
  export function getCreditSummary() {
    return (dispatch, getState) => {
  
      var state = getState();

      return creditSummaryApi.getCreditSummaryData().then(creditSummary => {
        dispatch(getCreditSummarySuccess(creditSummary));
      }).catch(error => {
        throw(error);
      });
    };
  }
