import * as ImagesPath from '../../config/imagesPath';

const trackingConstants = {
  myprofile: 'myprofile',
  equifaxFreeCreditReport: 'equifaxFreeCreditReport',
  equifaxSolutionCenter: 'equifaxSolutionCenter',
  editProfileToRefreshScore: 'editProfileToRefreshScore',
  identity: 'identity'
}


export const noScoreReasons = [
  {
    id: 0,
    text: '<b>Click the icon</b> to check that the information you have provided to us is correct and up to date.',
    actionText: 'MY PROFILE',
    trackingLabel: trackingConstants.myprofile,
    imgUrl: ImagesPath.ImgDashboard_NoScore_Person,
    actionRoute: '/my-details',
    links: [
      {
        id: 'linktoreport',
        actionRoute: '/my-details',
        actionText: 'MY PROFILE',
        actionPlaceholderText: 'account profile information',
        trackingLabel: trackingConstants.myprofile,
      }
    ]
  },
  {
    id: 2,
    text: 'Unfortunately we are unable to retrieve your score from Equifax without verifying your identity. Please click here {linktoidentity} to verify your identity.',
    actionText: 'MY IDENTITY',
    trackingLabel: trackingConstants.identity,
    imgUrl: ImagesPath.ImgDashboard_NoScore_Person,
    actionRoute: '/my-details/verification',
    verifyIdentity: true,
    links: [
      {
        id: 'linktoidentity',
        actionRoute: '/my-details/verification',
        actionText: 'MY IDENTITY',
        actionPlaceholderText: 'identity information',
        trackingLabel: trackingConstants.identity,
      }
    ]
  },
  {
    id: 3,
    text: '<b>Click the icon</b> to check that we have successfully verified your identity.',
    actionText: 'MY IDENTITY',
    trackingLabel: trackingConstants.identity,
    imgUrl: ImagesPath.ImgDashboard_NoScore_Person,
    actionRoute: '/my-details/verification',
    verifyIdentity: false,
    links: [
      {
        id: 'linktoidentity',
        actionRoute: '/my-details/verification',
        actionText: 'MY IDENTITY',
        actionPlaceholderText: 'identity information',
        trackingLabel: trackingConstants.identity,
      }
    ]
  },
  {
    id: 4,
    text: "<b>Click the icon</b> to get your credit report from Equifax and review it to ensure your details on it are correct and that they match the information you provided to us at the time of registration.",
    actionText: 'GET REPORT',
    imgUrl: ImagesPath.ImgDashboard_NoScore_Paper,
    trackingLabel: trackingConstants.equifaxFreeCreditReport,
    href: 'https://www.equifax.com.au/personal/products/credit-and-identity-products',
    links: [
      {
        id: 'freecreditreport',
        href: 'https://www.equifax.com.au/personal/products/credit-and-identity-products',
        target: '_self',
        actionPlaceholderText: 'free credit report',
        trackingLabel: trackingConstants.equifaxFreeCreditReport,
      }
    ]
  },
  // {
  //   id: 5,
  //   text: "If you need to update your information on your credit report, please contact Equifax to do so here",
  //   actionText: 'UPDATE',
  //   imgUrl: ImagesPath.ImgDashboard_NoScore_Phone,
  //   href: 'https://www.equifax.com.au/personal/resolution-centre',
  //   trackingLabel: trackingConstants.equifaxSolutionCenter,
  //   links: [
  //     {
  //       id: 'updatecreditreport',
  //       href: 'http://www.equifax.com.au/personal/resolution-centre',
  //       target: '_self',
  //       actionPlaceholderText: 'here',
  //       trackingLabel: trackingConstants.equifaxSolutionCenter
  //     }
  //   ]
  // },
  // {
  //   id: 6,
  //   text: "To try to check your credit score again, in your GetCreditScore member dashboard, select <strong>My Account > Edit Profile</strong>, update any information required and click <b>Save</b>",
  //   actionText: 'MY ACCOUNT',
  //   imgUrl: ImagesPath.ImgDashboard_NoScore_Card,
  //   actionRoute: '/my-details',
  //   trackingLabel: trackingConstants.editProfileToRefreshScore
  // }

];
