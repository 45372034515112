import * as ImagesPath from '../../config/imagesPath';

export const productTypes = {
    homeloan: 1,
    carloan: 2,
    personalloan: 3,
    businessloan: 4,
    creditcard: 5,
    refinancemortgage: 6,
    insurance: 7,
    insuranceCar: 8,
    insuranceHome: 9,
    insuranceLife: 10,
    insuranceProtection: 11,
    homeLoanAssistant: 12,
    homeLoanAssistantExpert: 15,
};

export const insuranceProducts = [
           {
               id : "insurancecar",
               title : "Car Insurance",
               imgUrl : ImagesPath.ImgProduct_InsuranceCar,
               callToAction:
                   {
                       title: "GET QUOTE",
                       action: {}
                   },
               href: null,
               show: true,
               actionRoute: null,
               features: [
                   { key:"1", title : "15% online discount", classToAppend: "" },
                   { key:"2", title : "Quote in just 2 mins", classToAppend: ""},
                   { key:"3", title : "Lifetime guarantee on authorised repairs", classToAppend: "hidden-xs"},
                   { key:"4", title : "21 day money-back guarantee", classToAppend: "hidden-xs"},
               ]
           },
           {
               id : "insurancehome",
               title : "Home Insurance",
               imgUrl : ImagesPath.ImgProduct_InsuranceHome,
               callToAction:
                   {
                       title: "GET QUOTE",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Up to 30% online discount", classToAppend: "" },
                   {key:"2", title : "Home and/or Contents", classToAppend: ""},
                   {key:"3", title : "Quick & easy quote", classToAppend: "hidden-xs"},
                   {key:"4", title : "21 day money-back guarantee", classToAppend: "hidden-xs"},
               ]
           },{
               id : "insurancelife",
               title : "Life Insurance",
               imgUrl : ImagesPath.ImgProduct_InsuranceLife,
               callToAction:
                   {
                       title: "GET QUOTE",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Lump sum up to $1 million", classToAppend: "" },
                   { key:"2", title : "Advance funeral payment", classToAppend: ""},
                   { key:"3", title : "No medical required", classToAppend: "hidden-xs"},
                   { key:"4", title : "No extra to pay monthly", classToAppend: "hidden-xs"},
               ]
           },
           {
               id : "incomeprotection",
               title : "Income Protection",
               imgUrl : ImagesPath.ImgProduct_InsuranceIncome,
               callToAction:
                   {
                       title: "GET QUOTE",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Monthly benefit up to $10,000", classToAppend: "" },
                   { key:"2", title : "No premiums while you claim", classToAppend: ""},
                   { key:"3", title : "Benefit period of 1,2 or 5 years", classToAppend: "hidden-xs"},
                   { key:"4", title : "Choose 30 or 60 day waiting period", classToAppend: "hidden-xs"},
               ]
           }
];


// export const products = [
//            {
//               id : "creditcard_rc_table",
//               linkRef: "creditcard",
//                title : "Credit Card",
//                imgUrl : ImagesPath.ImgProduct_CC,
//                imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
//                show: false,
//                callToAction:
//                    {
//                        title: 'GET FEATURED DEAL',
//                        salesTitle: "Credit Cards",
//                        action: {}
//                    },
//                features: [
//                    { key:"1", title : "Comprehensive comparison", classToAppend: "" },
//                    { key:"2", title : "Approx. 200 different products", classToAppend: ""},
//                    { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
//                    { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
//                    { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
//                ]
//            }
//          ];
//
export const products = [
           {
              id : "creditcard_rc_table",
              linkRef: "creditcard",
               title : "Credit Card",
               imgUrl : ImagesPath.ImgProduct_CC,
               imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Credit Cards",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                   { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                   { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                   { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                   { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
               ]
           }
           , {
               id : "creditcard_rc_selectedCards",
               linkRef: "creditcard",
                title : "Credit Card",
                imgUrl : ImagesPath.ImgProduct_CC,
                imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Credit Cards",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                    { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                    { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                    { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                    { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                ]
            }
            , {
                id : "creditcard_rc_bestccs",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ]
             }
            ,{
                id : "creditcard_ccc_frequentflyer",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ]
             }
             ,{
                id : "creditcard_ccc_bt",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ],
                 isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 509;
                    isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.age >= 24 && userProfile.extras.age <= 54;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
             }
             ,{
                id : "creditcard_ccc_best",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ],
                 isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 509;
                    isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.age >= 24 && userProfile.extras.age <= 54;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
             }
             ,{
                id : "creditcard_westpac",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ],
                 isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
             }
             ,{
                id : "creditcard_hsbc",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ],
                 isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
             }
             ,{
                id : "creditcard_ccc_homepage",
                linkRef: "creditcard",
                 title : "Credit Card",
                 imgUrl : ImagesPath.ImgProduct_CC,
                 imgUrl_Sales : ImagesPath.ImgProduct_CC_Sales,
                 show: false,
                 callToAction:
                     {
                         title: 'GET FEATURED DEAL',
                         salesTitle: "Credit Cards",
                         action: {}
                     },
                 features: [
                     { key:"1", title : "Comprehensive comparison", classToAppend: "" },
                     { key:"2", title : "Approx. 200 different products", classToAppend: ""},
                     { key:"3", title : "0% Balance Transfer cards", classToAppend: ""},
                     { key:"4", title : "Reward program cards", classToAppend: "hidden-xs"},
                     { key:"5", title : "Frequent flyer cards", classToAppend: "hidden-xs"},
                 ]
             }
           ,{
               id : "personalloan_nowfinance",
               linkRef: "personalloan",
               title : "Personal Loan",
               imgUrl : ImagesPath.ImgProduct_PersonalLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Personal Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                   { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                   { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                   { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                   { key:"5", title : "100% online", classToAppend: "hidden-xs"},
               ],
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 600;
                 isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.age >= 21 && userProfile.extras.age <= 60;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
                id : "personalloan_harmoney",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 500;
                    return {
                        isSatisfied: isSatisfied,
                        altId: this.altProductIdentifier
                    };
                }
            }
           ,{
               id : "personalloan_societyone",
               linkRef: "personalloan",
               title : "Personal Loan",
               imgUrl : ImagesPath.ImgProduct_PersonalLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
               show: false,
               hasHandshake: true,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Personal Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                   { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                   { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                   { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                   { key:"5", title : "100% online", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 622;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
               id : "personalloan_ratecity",
               linkRef: "personalloan",
               title : "Personal Loan",
               imgUrl : ImagesPath.ImgProduct_PersonalLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Personal Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                   { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                   { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                   { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                   { key:"5", title : "100% online", classToAppend: "hidden-xs"},
               ]
           }
           ,{
            id : "personalloan_ratecity_best",
            linkRef: "personalloan",
            title : "Personal Loan",
            imgUrl : ImagesPath.ImgProduct_PersonalLoan,
            imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
            show: false,
            callToAction:
                {
                    title: 'GET FEATURED DEAL',
                    salesTitle: "Personal Loans",
                    action: {}
                },
            features: [
                { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                { key:"5", title : "100% online", classToAppend: "hidden-xs"},
            ]
        }
           ,{
            id : "personalloan_pepper",
            linkRef: "personalloan",
            title : "Personal Loan",
            imgUrl : ImagesPath.ImgProduct_PersonalLoan,
            imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
            show: false,
            callToAction:
                {
                    title: 'GET FEATURED DEAL',
                    salesTitle: "Personal Loans",
                    action: {}
                },
            features: [
                { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                { key:"5", title : "100% online", classToAppend: "hidden-xs"},
            ],
            isCustomRuleSatisfied: function(userProfile, scoreProfile){
               
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;               
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
              }
        }
            ,{
                id : "personalloan_p1",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ]
            }
            ,{
                id : "personalloan_p2",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ]
            }
            ,{
                id : "personalloan_maccredit",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){                    
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 480 && scoreProfile.scoreInfo.score <= 600;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
            ,{
                id : "MoneyPlace_pl",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 501 && scoreProfile.scoreInfo.score <= 900;
                  isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.age >= 20 && userProfile.extras.age <= 60;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
            ,{
                id : "RateSetter_pl",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 726;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
            ,{
                id : "personalloan_latitude",
                linkRef: "personalloan",
                title : "Personal Loan",
                imgUrl : ImagesPath.ImgProduct_PersonalLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_PersonalLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Personal Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Tailored to your credit score", classToAppend: "" },
                    { key:"2", title : "Borrow up to $50,000", classToAppend: ""},
                    { key:"3", title : "Get a quote before you apply", classToAppend: ""},
                    { key:"4", title : "Fast approval Process", classToAppend: "hidden-xs"},
                    { key:"5", title : "100% online", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 650;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
           ,{
               id : "homeloan",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Online approval", classToAppend: "" },
                   {key:"2", title : "1600 loans from 30 lenders", classToAppend: ""},
                   {key:"3", title : "Online chat support", classToAppend: ""},
                   {key:"4", title : "Loan matched to your needs", classToAppend: "hidden-xs"},
                   {key:"5", title : "Loan approval assistance", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_hashching",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Pre-negotiated savings $$$", classToAppend: "" },
                   {key:"2", title : "Broker does all your paperwork", classToAppend: ""},
                   {key:"3", title : "Deals not advertised by lenders", classToAppend: ""},
                   {key:"4", title : "25% cash back on home insurance", classToAppend: "hidden-xs"},
                   {key:"5", title : "Obligation & cost free", classToAppend: "hidden-xs"},
               ]
           }
		       ,{
               id : "homeloan_joust_A",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: true,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Banks bid live for your business", classToAppend: "" },
                   {key:"2", title : "Create competitive tension", classToAppend: ""},
                   {key:"3", title : "Reduce middleman costs ", classToAppend: ""},
                   {key:"4", title : "You choose the lender based on how hard they chase your business ", classToAppend: "hidden-xs"},
               ],
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 // return {
                 //  isSatisfied: true,
                 //  altId: this.altProductIdentifier
                 // };

                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;
                  isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.state != 'TAS';
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
               }
           }
           ,{
               id : "homeloan_joust_B",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: true,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Banks bid live for your business", classToAppend: "" },
                   {key:"2", title : "Create competitive tension", classToAppend: ""},
                   {key:"3", title : "Reduce middleman costs ", classToAppend: ""},
                   {key:"4", title : "You choose the lender based on how hard they chase your business ", classToAppend: "hidden-xs"},
               ],
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;
                 isSatisfied = isSatisfied && userProfile && userProfile.extras && userProfile.extras.state != 'TAS';
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
               id : "homeloan_ratecity",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easy online comparison", classToAppend: "" },
                   {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                   {key:"3", title : "See minimum deposit", classToAppend: ""},
                   {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                   {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
               ]
           }
            ,{
                id : "homeloan_ratecity_IMB_HL",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: true,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ]
            }
           ,{
               id : "homeloan_ratecity_virgin",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easy online comparison", classToAppend: "" },
                   {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                   {key:"3", title : "See minimum deposit", classToAppend: ""},
                   {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                   {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_ratecity_amp",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: false,
               hasPushLead: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easy online comparison", classToAppend: "" },
                   {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                   {key:"3", title : "See minimum deposit", classToAppend: ""},
                   {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                   {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_uno",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: true,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easily compare more options", classToAppend: "" },
                   {key:"2", title : "Online application & approval", classToAppend: ""},
                   {key:"3", title : "Fast personalised service 7 days", classToAppend: ""},
                   {key:"4", title : "If you find a better deal, they’ll pay the difference for a year", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_uno_betterrate",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: true,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easily compare more options", classToAppend: "" },
                   {key:"2", title : "Online application & approval", classToAppend: ""},
                   {key:"3", title : "Fast personalised service 7 days", classToAppend: ""},
                   {key:"4", title : "If you find a better deal, they’ll pay the difference for a year", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_pepper",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               hasHandshake: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easily compare more options", classToAppend: "" },
                   {key:"2", title : "Online application & approval", classToAppend: ""},
                   {key:"3", title : "Fast personalised service 7 days", classToAppend: ""},
                   {key:"4", title : "If you find a better deal, they’ll pay the difference for a year", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "homeloan_wizard",
               linkRef: "homeloan",
               title : "Home Loan",
               imgUrl : ImagesPath.ImgProduct_HomeLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Home Loans",
                       action: {}
                   },
               features: [
                   {key:"1", title : "Easily compare more options", classToAppend: "" },
                   {key:"2", title : "Online application & approval", classToAppend: ""},
                   {key:"3", title : "Fast personalised service 7 days", classToAppend: ""},
                   {key:"4", title : "If you find a better deal, they’ll pay the difference for a year", classToAppend: "hidden-xs"},
               ]
           }
            ,{
                id : "homeloan_ing",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ]
            }
            ,{
                id : "homeloan_ratecity_hls",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 621;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
            } 
            ,{
                id : "homeloan_h1",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 621;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
            } 
            ,{
                id : "homeloan_h2",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 621;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
            } 
            ,{
                id : "homeloan_ratecity_comp_table",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ]
            }
            ,{
                id : "homeloan_rams",
                linkRef: "homeloan",
                title : "Home Loan",
                imgUrl : ImagesPath.ImgProduct_HomeLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_HomeLoan_Sales,
                show: false,
                hasHandshake: false,
                hasPushLead: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Home Loans",
                        action: {}
                    },
                features: [
                    {key:"1", title : "Easy online comparison", classToAppend: "" },
                    {key:"2", title : "Sort by lowest rates", classToAppend: ""},
                    {key:"3", title : "See minimum deposit", classToAppend: ""},
                    {key:"4", title : "View repayment amount", classToAppend: "hidden-xs"},
                    {key:"5", title : "Simple to enquire", classToAppend: "hidden-xs"},
                ],
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                    let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 600;
                    return {
                      isSatisfied: isSatisfied,
                      altId: this.altProductIdentifier
                    };
                  }
            } 
           ,{
               id : "autoloan_stratton",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Instant quote", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "autoloan_ratecity",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Easy online comparison", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "autoloan_ratecity_imb",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Easy online comparison", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 650;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
               id : "autoloan_latitude",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Easy online comparison", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 700;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
               id : "autoloan_ratecity_loan",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Easy online comparison", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 600 && scoreProfile.scoreInfo.score <= 900;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
               id : "autoloan_ratecity_creditconcierge",
               linkRef: "autoloan",
               title : "Car Loan",
               imgUrl : ImagesPath.ImgProduct_AutoLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Car Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Easy online comparison", classToAppend: "" },
                   { key:"2", title : "Online approval", classToAppend: ""},
                   { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                   { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                   { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 450;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
           ,{
            id : "autoloan_firstmac",
            linkRef: "autoloan",
            title : "Car Loan",
            imgUrl : ImagesPath.ImgProduct_AutoLoan,
            imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
            show: false,
            callToAction:
                {
                    title: 'GET FEATURED DEAL',
                    salesTitle: "Car Loans",
                    action: {}
                },
            features: [
                { key:"1", title : "Easy online comparison", classToAppend: "" },
                { key:"2", title : "Online approval", classToAppend: ""},
                { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
            ]
            ,
            isCustomRuleSatisfied: function(userProfile, scoreProfile){
              let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 650;
              return {
                isSatisfied: isSatisfied,
                altId: this.altProductIdentifier
              };
              }
            }
            ,{
                id : "Approved_car",
                linkRef: "autoloan",
                title : "Car Loan",
                imgUrl : ImagesPath.ImgProduct_AutoLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Car Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Easy online comparison", classToAppend: "" },
                    { key:"2", title : "Online approval", classToAppend: ""},
                    { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                    { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                    { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
                ]
                ,
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 450;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
            ,{
                id : "RateSetter_car",
                linkRef: "autoloan",
                title : "Car Loan",
                imgUrl : ImagesPath.ImgProduct_AutoLoan,
                imgUrl_Sales : ImagesPath.ImgProduct_AutoLoan_Sales,
                show: false,
                callToAction:
                    {
                        title: 'GET FEATURED DEAL',
                        salesTitle: "Car Loans",
                        action: {}
                    },
                features: [
                    { key:"1", title : "Easy online comparison", classToAppend: "" },
                    { key:"2", title : "Online approval", classToAppend: ""},
                    { key:"3", title : "Car & Motorbike loans", classToAppend: ""},
                    { key:"4", title : "Boat & Caravan loans", classToAppend: "hidden-xs"},
                    { key:"5", title : "Novated leasing options", classToAppend: "hidden-xs"},
                ]
                ,
                isCustomRuleSatisfied: function(userProfile, scoreProfile){
                  let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 600;
                  return {
                    isSatisfied: isSatisfied,
                    altId: this.altProductIdentifier
                  };
                }
            }
           ,{
               id : "insurance",
               linkRef: "insurance",
               title : "Insurance",
               imgUrl : ImagesPath.ImgProduct_Insurance,
               imgUrl_Sales : ImagesPath.ImgProduct_Insurance_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Insurance",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Car, Home & Life Insurance ", classToAppend: "" },
                   { key:"2", title : "Income protection", classToAppend: ""},
                   { key:"3", title : "Up to 30% online discount (Home)", classToAppend: ""},
                   { key:"4", title : "15% online discount (Car)", classToAppend: "hidden-xs"},
                   { key:"5", title : "Get a quote in just minutes", classToAppend: "hidden-xs"},
               ],
               subProducts : insuranceProducts
           }
           ,{
               id : "insurance_altmedia",
               linkRef: "insurance",
               title : "Health Insurance",
               imgUrl : ImagesPath.ImgProduct_Insurance,
               imgUrl_Sales : ImagesPath.ImgProduct_InsuranceBag,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Insurance",
                       action: {}
                   },
               features: [
                   { key:"1", title : "Takes less than 2 mins ", classToAppend: "" },
                   { key:"2", title : "Compare policies & save", classToAppend: ""},
                   { key:"3", title : "10 instant quotes", classToAppend: ""},
                   { key:"4", title : "Apply online", classToAppend: "hidden-xs"},
                   { key:"5", title : "Avoid paying extra taxes", classToAppend: "hidden-xs"},
               ],
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = true;
                 //userProfile && userProfile.extras && userProfile.extras.age >= 30;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           }
		       ,{
               id : "businessloan_valiant",
               linkRef: "businessloan",
               title : "Business Loan",
               imgUrl : ImagesPath.ImgProduct_BusinessLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_BusinessLoan_Sales,
               show: false,
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Business Loans",
                       action: {}
                   },
               features: [
                   { key:"1", title : "A loan for every business type", classToAppend: "" },
                   { key:"2", title : "50+ loans from over 35 lenders", classToAppend: ""},
                   { key:"3", title : "Business credit cards", classToAppend: ""},
                   { key:"4", title : "Compare rates, terms & fees", classToAppend: "hidden-xs"},
                   { key:"5", title : "Dedicated credit specialists", classToAppend: "hidden-xs"},
               ]
           }
           ,{
               id : "businessloan_ondeck",
               linkRef: "businessloan",
               title : "Business Loan",
               imgUrl : ImagesPath.ImgProduct_BusinessLoan,
               imgUrl_Sales : ImagesPath.ImgProduct_BusinessLoan_Sales,
               show: false,
               
               callToAction:
                   {
                       title: 'GET FEATURED DEAL',
                       salesTitle: "Business Loans",
                       action: {}
                   },
               features: [
                 { key:"1", title : "$10K - $150K term loans", classToAppend: "" },
                 { key:"2", title : "10 minute online application", classToAppend: ""},
                 { key:"3", title : "Funds in as fast as 1 business day", classToAppend: ""},
                 { key:"4", title : "Minimum 1 year trading req.", classToAppend: "hidden-xs"},
                 { key:"5", title : "Minimum 100K turnover req.", classToAppend: "hidden-xs"},
               ]
               ,
               isCustomRuleSatisfied: function(userProfile, scoreProfile){
                 let isSatisfied = scoreProfile && scoreProfile.scoreInfo && scoreProfile.scoreInfo.score >= 500;
                 return {
                   isSatisfied: isSatisfied,
                   altId: this.altProductIdentifier
                 };
               }
           },
           {
            id : "utilities_one",
            linkRef: "utilities",
            title : "Utilities",
            imgUrl : ImagesPath.ImgProduct_Utilities,
            imgUrl_Sales : ImagesPath.ImgProduct_Utilities_Sales,
            show: false,
            callToAction:
                {
                    title: 'GET FEATURED DEAL',
                    salesTitle: "Utilities",
                    action: {}
                },
            features: [
                { key:"1", title : "Takes less than 2 mins ", classToAppend: "" },
                { key:"2", title : "Compare policies & save", classToAppend: ""},
                { key:"3", title : "instant quotes", classToAppend: ""},
                { key:"4", title : "Apply online", classToAppend: "hidden-xs"},
                { key:"5", title : "Avoid paying extra taxes", classToAppend: "hidden-xs"},
            ]
           },
           {
            id : "car_subscription_one",
            linkRef: "carsubscription",
            title : "Car Subscription",
            imgUrl : ImagesPath.ImgProduct_CarSubscription,
            imgUrl_Sales : ImagesPath.ImgProduct_CarSubscription_Sales,
            show: false,
            callToAction:
                {
                    title: 'GET FEATURED DEAL',
                    salesTitle: "Car Subscription",
                    action: {}
                },
            features: [
                { key:"1", title : "Takes less than 2 mins ", classToAppend: "" },
                { key:"2", title : "Compare policies & save", classToAppend: ""},
                { key:"3", title : "instant quotes", classToAppend: ""},
                { key:"4", title : "Apply online", classToAppend: "hidden-xs"},
                { key:"5", title : "Avoid paying extra taxes", classToAppend: "hidden-xs"},
            ]
           }
];

let _partnerHandshakeConfig = {};

_partnerHandshakeConfig["8"] = {
  logoImgUrl: ImagesPath.ImgLogoJoust,
  description: "Get a reverse home loan auction at <b>Joust Home Loans</b> and the baanks will bid on you",
  cssClassForImage: "pm-partner-joust",
  features: [
    { key:"1", title : "Set up your live Joust auction in minutes", classToAppend: "" },
    { key:"2", title : "Watch the banks battle for your home loan", classToAppend: ""},
    { key:"3", title : "Potentially save thousands with your better rate!", classToAppend: ""},
  ]
};

_partnerHandshakeConfig["2"] = {
  logoImgUrl: ImagesPath.ImgProduct_HomeLoan_Wizard,
  description: "",
  cssClassForImage: "pm-partner-rc",
  features: [
    { key:"1", title : "Speak to an expert", classToAppend: "" },
    { key:"2", title : "Free consultation by phone 7 days a week", classToAppend: ""}
  ]
};

_partnerHandshakeConfig["7"] = {
  logoImgUrl: ImagesPath.ImgLogoUnoWin,
  description: "",
  cssClassForImage: "pm-partner-uno",
  features: [
    { key:"1", title : "See your matched deals", classToAppend: "" },
    { key:"2", title : "Free consultation by phone 7 days a week", classToAppend: ""},
    { key:"3", title : "Option to apply online with support from experts", classToAppend: ""},
  ]
};


_partnerHandshakeConfig["4"] = {
  logoImgUrl: ImagesPath.ImgLogoSocietyOne,
  description: "Personal loans that reward your good credit",
  cssClassForImage: "",
  features: [
    { key:"1", title : "No monthly fees", classToAppend: "" },
    { key:"2", title : "No early repayment fees", classToAppend: ""},
    { key:"3", title : "Get a quote in 2 min.", classToAppend: ""}
  ]
};



export const partnerHandshakeConfig = _partnerHandshakeConfig;
