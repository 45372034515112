import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu';
import LoginBoxMessage from './LoginBoxMessage';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import TextInput from '../common/controls/TextInput';
import DateInputSimple from '../common/controls/DateInputSimple';

import UserApi from '../../api/mockUserApi.js';
import LayoutApi from '../../api/mockLayoutApi';
import ValidationApi from '../../api/validationApi';
import * as funcs from '../shared/functions';
import HelperApi from '../../api/HelperApi';
import FooterSmallSection from '../common/footerSmall';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import DateInputGeneral from '../common/controls/DateInputGeneral';

import LoginFrame from './LoginFrame';
import ForgotPasswordBox from './ForgotPasswordBox';

import GcsRecaptcha from "../common/GcsRecaptcha";

import {initTonikForms} from '../../tonik/shared/forms';
const $ = window.$;

export class ForgotPasswordPage extends React.Component { 
  constructor(props, context) {
    super(props, context);

    this.state = {
      menuItemList: [],
      customer:  {
        email: props.registeringUsername,
        dob: { dd: '', mm: '', yy: '' }  
      },
      errors: { dob: { dd: '', mm: '', yy: '' } },
      resetRecaptcha: false,
      currentPage: 'forgotPassword'
    };

    this.updateCustomerState = this.updateCustomerState.bind(this);
    this.onSendPasswordConfirmation =  this.onSendPasswordConfirmation.bind(this);
    this.onPasswordSubmit =  this.onPasswordSubmit.bind(this);
    this.doSendForgotPassword = this.doSendForgotPassword.bind(this);
    this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
    this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  

    this.recaptchaRef = React.createRef();  
  }

     componentDidMount() {      
       this.props.actions.getMenuForPage(pages.LOGIN);
      //  this.props.actions.getMenuForPage(pages.LOGIN);
       this.props.actions.getTextVariants();
       this.doResetRecaptcha();

       initTonikForms();
     }
  
    componentWillReceiveProps(nextProps) {
      if (nextProps.registeringUsername != this.props.registeringUsername) {
        let customer = Object.assign({}, this.state.customer);
        customer.email = nextProps.registeringUsername;
        this.setState({ customer });
      }
      this.doResetRecaptcha();
    }

    onSelectRecaptcha(token){
   
      if (token) {        
        this.doSendForgotPassword(token);
      }      
  
      this.setState({resetRecaptcha: true});
    }
  
    doResetRecaptcha(){
      if (this.state.resetRecaptcha) {
        try{
          this.recaptchaRef.current.reset();
        }
        catch(ex){
          console.log(ex);
        }
        this.setState({resetRecaptcha: false});
      }
    }
     

     updateCustomerState(event) {
       
         const field = event.target.name;

         let customer = this.state.customer;
         customer[field] = event.target.value;

         let errors = this.state.errors;
         errors[field] = '';

         return this.setState({ customer: customer, errors: errors });
     }

    onSendPasswordConfirmation(evt){
      this.props.history.push('/login');

      return false;
    }

    onPasswordSubmit(evt) {
      this.setState({ callbackWaiting: true });

      evt.preventDefault();
      if (!this.loginFormIsValid()) {
        this.setState({ callbackWaiting: false });
        return;
      }                  

      this.recaptchaRef.current.execute();      
          
      return false;
    }

    doSendForgotPassword(token){
      
      let customer = this.state.customer;
      HelperApi.setInnerDateOfBirthAndDobFromNumbers(customer, customer.dob.yy, customer.dob.mm, customer.dob.dd);
      let errors = {}
      UserApi.forgotPassword(customer, token).then( () => {
        this.setState({ callbackWaiting: false, currentPage: 'confirmationPassword' });
      }).catch( e => {
        if (e.status == 400) {
          errors.generic = this.props.textVariants[pages.FORGOTPASSWORD + this.props.textVariants.textVariantSeparator + "error_400"];
        } else {
          errors.generic = this.props.textVariants["Api_Generic"];
        }

        this.setState({errors: errors});
      });
    }

     loginFormIsValid() {

         let formIsValid = true;
         let errors = {};
         errors.dob = { dd: '', mm: '', yy: '' };

         var errorEmail = ValidationApi.validateEmail(this.state.customer.email);
         if (errorEmail) {
             errors.email = errorEmail;
             formIsValid = false;
         }


         var date = this.state.customer.dob;
         if(!date.dd){
          errors.dob.dd = 'Day required';
          formIsValid = false;
         }
         if(!date.mm){
          errors.dob.mm = 'Month required';
          formIsValid = false;
         }
         if(!date.yy){
          errors.dob.yy = 'Year required';
          formIsValid = false;
         }
         this.setState({errors: errors});

         return formIsValid;
       }

    render() {

      var pageContent = null;

      var currentPage = this.state.currentPage;
      //currentPage = 'confirmationPassword';
      switch(currentPage) {

        case 'forgotPassword':
            pageContent = <ForgotPasswordBox
             textVariants={this.props.textVariants}
             onChange={this.updateCustomerState}
             error={this.state.errors}
             customer={this.state.customer}
             onPasswordSubmit={this.onPasswordSubmit}
            callbackWaiting={this.state.callbackWaiting}
           />;
        break;

        case 'confirmationPassword':
            pageContent = <LoginBoxMessage
              titleText={this.props.textVariants[pages.FORGOTPASSWORD + this.props.textVariants.textVariantSeparator + "confirmationTitle"]}
              contentText={this.props.textVariants[pages.FORGOTPASSWORD + this.props.textVariants.textVariantSeparator + "confirmationText"]}
              actionName={this.props.textVariants["Btn_Ok"]}
              onActionClick={this.onSendPasswordConfirmation}
              showTandCs={false}
            />;

        break;
    }

        return (
            <div>
               <GcsRecaptcha
                  recaptchaRef={this.recaptchaRef}
                  onSelectRecaptcha={this.onSelectRecaptcha}
               />
              <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />
              {pageContent}
              <FooterSmallSection></FooterSmallSection>
            </div>
        )
   }
}

ForgotPasswordPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.LOGIN]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.LOGIN];
     }
   }

 let txtVariants = {};
 if(state.textVariants){
   txtVariants = state.textVariants;
 }

  return {
    registeringUsername: state.userProfile.registeringUsername,
    menuItemList: menuItems,
    textVariants: txtVariants
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
