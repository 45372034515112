import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as ImagesPath from '../../config/imagesPath';

const $ = window.$;
const L = window.L;

class ScoreMapSearch extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      map: {
        latitude: -27.9210555,
        longitude: 133.247866,
        zoom: 4,
      },
      polygonStyle: {
        color: "#4b4b4b",
        weight: 2,
        opacity: 0.8,
        fillOpacity: 0.8
      },
      creditScore: {
        highest: 671, // highest region score of the year
        lowest: 581, // lowest region score of the year
        mid: 629 // (729 + 583) / 2
      }
    };

    this.componentElements = {
      scoremapElementId: "scoremap-map",
      mapLoadingElement: ".scoremap-map .loading",
      autocomplete: ".autocomplete-input",
      suggestList: ".autocomplete-list",
    }

    this.errorTypes = {
      noResult: "noResult",
      unKnown: "unKnown"
    };

    this.map = null;
    this.currentlayer = null;
    this.currentmarker = null;

    this.componentDidMountDelayed = this.componentDidMountDelayed.bind(this);
    this.waitForResourcesToLoad = this.waitForResourcesToLoad.bind(this);
    this.drawMap = this.drawMap.bind(this);
    this.setupAutocomplete = this.setupAutocomplete.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.getAllAreasAndPopulateList = this.getAllAreasAndPopulateList.bind(this);
    this.getGeoJsonAndDisplayInMap = this.getGeoJsonAndDisplayInMap.bind(this);
    this.setAutocompleteValue = this.setAutocompleteValue.bind(this);
    this.populateRegionList = this.populateRegionList.bind(this);
    this.displayAreaInMap = this.displayAreaInMap.bind(this);
    this.stylePolygon = this.stylePolygon.bind(this);
    this.getCreditScoreColor = this.getCreditScoreColor.bind(this);
    this.handleNoResultFound = this.handleNoResultFound.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentWillMount() {
    const leafletStyle = document.createElement("link");

    leafletStyle.rel = "stylesheet";
    leafletStyle.href = "https://unpkg.com/leaflet@1.2.0/dist/leaflet.css";
    leafletStyle.integrity = "sha512-M2wvCLH6DSRazYeZRIm1JnYyh22purTM+FDB5CsyxtQJYeKq83arPe5wgbNmcFXGqiSH2XR8dT/fJISVA1r/zQ==";
    leafletStyle.crossOrigin = "anonymous";

    const leafletScript = document.createElement("script");

    leafletScript.src = "https://unpkg.com/leaflet@1.2.0/dist/leaflet.js";
    leafletScript.async = true;
    leafletScript.integrity = "sha512-lInM/apFSqyy1o6s89K4iQUKg6ppXEgsVxT35HbzUupEVRh2Eu9Wdl4tHj7dZO0s1uvplcYGmt3498TtHq+log==";
    leafletScript.crossOrigin = "anonymous";

    document.head.appendChild(leafletStyle);
    document.head.appendChild(leafletScript);
  }

  componentDidMount() {
    this.waitForResourcesToLoad().then(this.componentDidMountDelayed);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;

    if(this.props.postCode != nextProps.postCode){
      this.setAutocompleteValue(nextProps.postCode);
      shouldUpdate = true;
    }

    if (this.props.sA3Code != nextProps.sA3Code) {
      this.getGeoJsonAndDisplayInMap(nextProps.sA3Code);
      shouldUpdate = true;
    }

    return shouldUpdate;
  }

  componentWillUnmount() {
    this.map && this.map.remove();
  }

  componentDidMountDelayed(){
    this.setupAutocomplete();
    this.setAutocompleteValue(this.props.postCode);
    this.getGeoJsonAndDisplayInMap(this.props.sA3Code);
  }

  waitForResourcesToLoad(resolve) {
    let timerId = null;
    return new Promise((resolve, reject) => {
      timerId = setInterval(() => {
        if(window["L"]) {
          clearInterval(timerId);
          this.drawMap();
          resolve();
        }
      }, 1000);
    });
  }

  drawMap() {
    this.map = L.map(this.componentElements.scoremapElementId, {
      center: [this.state.map.latitude, this.state.map.longitude],
      zoom: this.state.map.zoom
    });

    //disable touch zoom and dragging
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) this.map.tap.disable();

    document.querySelector(".scoremap-map").style.cursor = "default";
    $(this.componentElements.mapLoadingElement).hide();

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(this.map);
    L.Icon.Default.imagePath = ImagesPath.ImgScoreMapRoot;
  }

  resetMap() {
    if(this.map){
      this.map.off();
      this.map.remove();
      this.drawMap();
    }
  }

  getAllAreasAndPopulateList(searchTerm) {
    this.props.actions.getSA3Areas({ search: searchTerm })
    .then((regionsResponse) => {
      if (regionsResponse.regions.length <= 0) {
        this.handleNoResultFound();
        return false;
      } else {
        this.populateRegionList(regionsResponse.regions);
      }
    }).catch(error => {
      this.showError(this.errorTypes.unKnown);
      //throw (error);
    });
  }

  getGeoJsonAndDisplayInMap(sA3Code) {
    if (!sA3Code){
      this.resetMap();
      return false;
    }

    this.props.actions.getGeoJsonForArea({sA3Code: sA3Code}).then((geoResponse) => {
      if (geoResponse && geoResponse.region && geoResponse.region.sA3GeoJSON.length > 0) {

        this.displayAreaInMap(geoResponse.region);

      } else {
        this.showError(this.errorTypes.unKnown);
        this.resetMap();
      }
    }).catch(error => {
      this.showError(this.errorTypes.unKnown);
      //throw (error);
    });
  }

  setupAutocomplete() {
    const $autocomplete = $(this.componentElements.autocomplete);

    $autocomplete.on("keydown", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        return false;
      }
    });

    $autocomplete.autocomplete({
      source: function (request, response) {
        this.getAllAreasAndPopulateList(request.term);
      }.bind(this),
      minLength: 3,
      delay: 500,
      search: function () {
        $autocomplete.addClass("searching");
      }
    });
  }

  setAutocompleteValue(postCode) {
    $(this.componentElements.suggestList).hide();
    $(this.componentElements.autocomplete).val(postCode);
  }

  populateRegionList(regions) {
    const that = this,
          $autocomplete = $(this.componentElements.autocomplete),
          $suggestList = $(this.componentElements.suggestList),
          $mapLoading = $(this.componentElements.mapLoadingElement);

    $autocomplete.removeClass("searching");
    $suggestList.html("");
    $suggestList.show();

    for (let i = 0; i < regions.length; i++) {
      $suggestList.append("<li><a href='#' data-sa3code='" + regions[i].sA3Code + "' data-sa3name='" + regions[i].sA3Name + "' >"
                          + regions[i].sA2Name + " <span>In " + regions[i].sA3Name + " Region</span></a></li>");
    }

    $suggestList.find("li").on("click", function (e) {
      e.preventDefault();
      const $item = $(this);
      const sA3Code = $item.find("a").attr("data-sa3code");
      const sA3Name = $item.find("a").attr("data-sa3name");
      if (!sA3Code) {
        return false;
      }

      const text = $item.find("a").html();
      $autocomplete.val(text.substring(0, text.indexOf(" <span>")));
      $suggestList.hide();
      $mapLoading.show();

      that.props.onAreaSelectedFromList(sA3Code, sA3Name);
      that.getGeoJsonAndDisplayInMap(sA3Code);

      return false;
    });
  }

  displayAreaInMap(region) {
    if (region === null) {
        this.showError(this.errorTypes.unKnown);
    } else {
      const that = this;
      $(this.componentElements.mapLoadingElement).hide();

      this.map.setView([this.state.map.latitude, this.state.map.longitude], this.state.map.zoom);

      const jsonSA3 = JSON.parse(region.sA3GeoJSON),
            sa3Name = region.sA3Name,
            sa3Score = region.sA3CreditScore ? region.sA3CreditScore : "N/A",
            popupContent = this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "popupContent"]
                          .replace("{SA3Name}", sa3Name)
                          .replace("{SA3Score}", sa3Score);

      if (this.currentlayer) {
        this.map.removeLayer(this.currentlayer);
      }

      this.currentlayer = L.geoJson(jsonSA3, {
        style: this.stylePolygon(sa3Score),
      }).addTo(this.map);

      const mapCenter = this.currentlayer.getBounds().getCenter();

      if (this.currentmarker) {
        this.map.removeLayer(this.currentmarker);
      }

      // fit the bounds to view
      this.map.fitBounds(this.currentlayer.getBounds());

      // sometimes fitBounds fails. setTimeout as failSafe.
      setTimeout(function () {
        that.map.fitBounds(that.currentlayer.getBounds());
        that.currentmarker = L.marker(mapCenter).bindPopup(popupContent).addTo(that.map).openPopup();
      }, 1000);
    }
  }

  stylePolygon(input) {
    const myStyle = {
      "fillColor": this.getCreditScoreColor(input),
      "color": this.state.polygonStyle.color,
      "weight": this.state.polygonStyle.weight,
      "opacity": this.state.polygonStyle.opacity,
      "fillOpacity": this.state.polygonStyle.fillOpacity
    };
    return myStyle;
  }

  getCreditScoreColor(creditscore) {
    const mid = this.state.creditScore.mid;
    const diff = creditscore - mid;
    const adj = Math.abs(diff) / 45; //(671-581) / 2 = 45

    let red, green;
    if (diff > 0) {
      red = Math.floor(255 * (1 - adj));
      green = 255;
    } else {
      green = Math.floor(255 * 1 - adj);
      red = 255;
    }

    return "#" + this.convertToHex(red) + this.convertToHex(green) + this.convertToHex(0);
  }

  convertToHex(integer) {
    const str = Number(integer).toString(16);
    return str.length == 1 ? "0" + str : str;
  }

  handleNoResultFound() {
    this.resetMap();
    this.showError(this.errorTypes.noResult);
  }

  showError(errorType) {
    let errorText = (errorType === this.errorTypes.noResult)
                    ? this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "errorTextNoResult"]
                    : this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "errorTextGeneral"];

    const $suggestList = $(this.componentElements.suggestList);

    $(this.componentElements.autocomplete).removeClass("searching");
    $(this.componentElements.mapLoadingElement).hide();
    $suggestList.html("");
    $suggestList.append("<li><p class='error-msg'>" + errorText + "</p></li>");
    $suggestList.show();
  }

  render() {
    const currentUrl = encodeURIComponent(window.location.href);
    const emailSubject = "Compare yourcredit score";

    return (
      <div id="scoremap-search" className="container-fluid left-container-padding right-container-padding scoremap-search scoremap-page-section">
        <div className="scoremap-page-section-title">
          {<p className="title">
            <span className="scoremap-has-disclaimer">{this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "mapSearchTitleWithDisclaimer"]}</span>
            <span>{this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "mapSearchTitle"]}</span>
          </p>}
        </div>
        <div className="scoremap-search-control form-group has-feedback">
          <input
            type="text"
            className="autocomplete-input form-control input-lg"
            placeholder={this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "mapSearch"]}
            autoComplete="off" />
          <span className="glyphicon glyphicon-search form-control-feedback icon" aria-hidden="true" />
          <ul className="autocomplete-list" />
        </div>
        <div id="scoremap-map" className="scoremap-map">
          <span className="loading" />
        </div>
        <div className="scoremap-social">
          <div>
            <a href={"https://www.facebook.com/sharer/sharer.php?u=" + currentUrl} target="_blank" rel="noopener noreferrer">
              <img src={ImagesPath.ImgScoreMap_IconFB} alt="Facebook" />
            </a>
          </div>
          <div>
            <a href={"https://twitter.com/home?status=" + currentUrl} target="_blank" rel="noopener noreferrer">
              <img src={ImagesPath.ImgScoreMap_IconTwitter} alt="Twitter" />
            </a>
          </div>
          <div>
            <a href={"https://plus.google.com/share?url=" + currentUrl} target="_blank" rel="noopener noreferrer">
              <img src={ImagesPath.ImgScoreMap_IconG} alt="Google+" />
            </a>
          </div>
          <div>
            <a href={"mailto:?&subject=" + emailSubject + "&body=" + currentUrl} target="_blank" rel="noopener noreferrer">
              <img src={ImagesPath.ImgScoreMap_IconEmail} alt="Email" />
            </a>
          </div>
        </div>
        <div className="scoremap-map-note">
          <span>Map data © <a href='http://openstreetmap.org'>OpenStreetMap</a> contributors. Regional data was obtained from the Australian Bureau of Statistics,
            <a href="http://stat.abs.gov.au/itt/r.jsp?databyregion" target="_blank" rel="noopener noreferrer"> ABS - Data by Region</a>
            , and used under ABS's existing <a href="http://creativecommons.org/licenses/by/2.5/au/" target="_blank" rel="noopener noreferrer">Creative Commons 2.5 Australia licence</a>.
          </span>
          <p>{this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "generalDisclaimer"]}</p>
        </div>
        <div className="gradient-container">
          <div className="gradient" />
          <div className="gradient-text">
            <span className="gradient-lowest">{this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "gradientLowestScore"]}</span>
            <span className="gradient-highest">{this.props.textVariants[pages.SCOREMAP + this.props.textVariants.textVariantSeparator + "gradientHighestScore"]}</span>
          </div>
        </div>
      </div>
    );;
  }
}

export default ScoreMapSearch;