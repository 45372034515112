import * as pages from './pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';


export const creditSummary = {
   "product-header": {
     "enquiry-id": {
       "__prefix": "vs",
       "__text": "141202-V1ND5-01FYB"
     },
     "datetime-requested": {
       "__prefix": "vs",
       "__text": "2014-12-02T15:51:03.371+11:00"
     },
     "datetime-generated": {
       "__prefix": "vs",
       "__text": "2014-12-02T15:51:05.519+11:00"
     },
     "client-reference": {
       "__prefix": "vs",
       "__text": "my-ref-101"
     },
     "operator-id": {
       "__prefix": "vs",
       "__text": "101"
     },
     "operator-name": {
       "__prefix": "vs",
       "__text": "John Doe"
     },
     "product-name": {
       "__prefix": "vs",
       "__text": "VedaScore Apply"
     },
     "permission-type": {
       "_code": "XY",
       "__prefix": "vs",
       "__text": "CONSUMER+COMMERCIAL"
     },
     "product-data-level": {
       "_code": "C",
       "__prefix": "vs",
       "__text": "Comprehensive"
     },
     "product-version": {
       "__prefix": "vs",
       "__text": "2.0"
     },
     "__prefix": "vs"
   },
   "product-data": {
     "enquiry-report": {
       "primary-match": {
         "bureau-reference": {
           "__prefix": "vs",
           "__text": "204027075"
         },
         "individual-name": {
           "family-name": {
             "__prefix": "vs",
             "__text": "PETERSON"
           },
           "first-given-name": {
             "__prefix": "vs",
             "__text": "LOUISE"
           },
           "other-given-name": {
             "__prefix": "vs",
             "__text": "JULIE"
           },
           "_first-reported-date": "2000-08-28",
           "_last-credit-enquiry-date": "2014-06-17",
           "_last-reported-date": "2014-10-31",
           "__prefix": "vs"
         },
         "gender": {
           "_code": "F",
           "__prefix": "vs",
           "__text": "Female"
         },
         "date-of-birth": {
           "_first-reported-date": "2014-12-02",
           "_last-reported-date": "2014-12-02",
           "__prefix": "vs",
           "__text": "1945-06-03"
         },
         "drivers-licence": {
           "number": {
             "__prefix": "vs",
             "__text": "A20402707"
           },
           "_last-reported-date": "2014-09-25",
           "__prefix": "vs"
         },
         "_id-index": "1",
         "_type": "strong",
         "__prefix": "vs"
       },
       "other-identities": {
         "other-identity": {
           "individual-name": {
             "family-name": {
               "__prefix": "vs",
               "__text": "HAAGEN"
             },
             "first-given-name": {
               "__prefix": "vs",
               "__text": "LOUISE"
             },
             "other-given-name": {
               "__prefix": "vs",
               "__text": "JULIE"
             },
             "_first-reported-date": "2014-12-02",
             "_last-credit-enquiry-date": "2014-11-02",
             "__prefix": "vs"
           },
           "gender": {
             "_code": "F",
             "__prefix": "vs",
             "__text": "Female"
           },
           "date-of-birth": {
             "_first-reported-date": "2014-12-02",
             "_last-reported-date": "2014-12-02",
             "__prefix": "vs",
             "__text": "1945-06-03"
           },
           "is-deceased": {
             "__prefix": "vs",
             "__text": "false"
           },
           "_id-index": "2",
           "__prefix": "vs"
         },
         "__prefix": "vs"
       },
       "addresses": {
         "address": [
           {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "2"
               },
               "__prefix": "vs"
             },
             "unit-number": {
               "__prefix": "vs",
               "__text": "41"
             },
             "street-number": {
               "__prefix": "vs",
               "__text": "121"
             },
             "street-name": {
               "__prefix": "vs",
               "__text": "HUNSTMANA"
             },
             "street-type": {
               "__prefix": "vs",
               "__text": "CRES"
             },
             "suburb": {
               "__prefix": "vs",
               "__text": "PYRMONT"
             },
             "state": {
               "__prefix": "vs",
               "__text": "NSW"
             },
             "postcode": {
               "__prefix": "vs",
               "__text": "2009"
             },
             "country-code": {
               "__prefix": "vs",
               "__text": "AUS"
             },
             "_first-reported-date": "2014-12-02",
             "_last-reported-date": "2014-12-02",
             "_type": "C",
             "__prefix": "vs"
           },
           {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "__prefix": "vs"
             },
             "unit-number": {
               "__prefix": "vs",
               "__text": "L"
             },
             "street-number": {
               "__prefix": "vs",
               "__text": "90"
             },
             "street-name": {
               "__prefix": "vs",
               "__text": "ARTHUR"
             },
             "street-type": {
               "__prefix": "vs",
               "__text": "ST"
             },
             "suburb": {
               "__prefix": "vs",
               "__text": "NORTH SYDNEY"
             },
             "state": {
               "__prefix": "vs",
               "__text": "NSW"
             },
             "postcode": {
               "__prefix": "vs",
               "__text": "2055"
             },
             "country-code": {
               "__prefix": "vs",
               "__text": "AUS"
             },
             "_first-reported-date": "2001-06-15",
             "_last-reported-date": "2014-09-25",
             "_type": "P",
             "__prefix": "vs"
           },
           {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "__prefix": "vs"
             },
             "unit-number": {
               "__prefix": "vs",
               "__text": "1"
             },
             "street-number": {
               "__prefix": "vs",
               "__text": "8"
             },
             "street-name": {
               "__prefix": "vs",
               "__text": "BIRCHGROVE"
             },
             "street-type": {
               "__prefix": "vs",
               "__text": "AVE"
             },
             "suburb": {
               "__prefix": "vs",
               "__text": "CANLEY HEIGHTS"
             },
             "state": {
               "__prefix": "vs",
               "__text": "NSW"
             },
             "postcode": {
               "__prefix": "vs",
               "__text": "2166"
             },
             "country-code": {
               "__prefix": "vs",
               "__text": "AUS"
             },
             "_first-reported-date": "2014-06-11",
             "_last-reported-date": "2014-08-29",
             "_type": "P",
             "__prefix": "vs"
           }
         ],
         "__prefix": "vs"
       },
       "employment": {
         "employer": {
           "id-references": {
             "id-reference": {
               "__prefix": "vs",
               "__text": "1"
             },
             "__prefix": "vs"
           },
           "name": {
             "__prefix": "vs",
             "__text": "DATA FISH PTY LTD"
           },
           "_first-reported-date": "2014-08-25",
           "_last-reported-date": "2014-08-25",
           "_type": "C",
           "__prefix": "vs"
         },
         "__prefix": "vs"
       },
       "consumer-credit-file": {
         "credit-enquiries": {
           "credit-enquiry": [
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "RM",
                 "__prefix": "vs",
                 "__text": "Real Property Mortgage"
               },
               "enquiry-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "560000"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "credit-enquirer": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "CRED - 010 ACTIVE BRANCH"
                 },
                 "__prefix": "vs"
               },
               "enquiry-client-reference": {
                 "__prefix": "vs",
                 "__text": "FD54545SS"
               },
               "_enquiry-date": "2012-09-02",
               "_type": "authorised-agent-enquiry",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "CC",
                 "__prefix": "vs",
                 "__text": "Credit Card"
               },
               "enquiry-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "5000"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "credit-enquirer": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "VXML 003 ACTIVE"
                 },
                 "__prefix": "vs"
               },
               "enquiry-client-reference": {
                 "__prefix": "vs",
                 "__text": "MY-REF-01"
               },
               "_enquiry-date": "2014-12-02",
               "_type": "credit-application",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "2"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "CA",
                 "__prefix": "vs",
                 "__text": "Charge Card"
               },
               "enquiry-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "8484"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "credit-enquirer": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "ITIG TCPIP 003 ACTIVE BRANCH"
                 },
                 "__prefix": "vs"
               },
               "enquiry-client-reference": {
                 "__prefix": "vs",
                 "__text": "8784"
               },
               "_enquiry-date": "2014-11-02",
               "_type": "credit-application",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "UA",
                 "__prefix": "vs",
                 "__text": "Utilities"
               },
               "enquiry-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "1"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "credit-enquirer": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "SOLUTIONS TEST"
                 },
                 "__prefix": "vs"
               },
               "_enquiry-date": "2014-06-17",
               "_type": "credit-application",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "accounts": {
           "account": [
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-id": {
                 "__prefix": "vs",
                 "__text": "487454DV454"
               },
               "original-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "CRED - 003 ACTIVE BRANCH"
                 },
                 "__prefix": "vs"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "AGC TEST P/L"
                 },
                 "transfer-date": {
                   "__prefix": "vs",
                   "__text": "2014-11-05"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "CC",
                 "__prefix": "vs",
                 "__text": "Credit Card"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "account-holder-count": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "account-open-date": {
                 "__prefix": "vs",
                 "__text": "2013-12-20"
               },
               "account-closed-date": {
                 "__prefix": "vs",
                 "__text": "2014-11-20"
               },
               "loan-payment-method": {
                 "_code": "P",
                 "__prefix": "vs",
                 "__text": "Principal and Interest in full"
               },
               "term-type": {
                 "_code": "R",
                 "__prefix": "vs",
                 "__text": "Revolving"
               },
               "secured-credit": {
                 "_code": "S",
                 "__prefix": "vs",
                 "__text": "Secured or Partially Secured"
               },
               "term-of-loan": {
                 "__prefix": "vs",
                 "__text": "120"
               },
               "original-maximum-amount-credit": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "5000"
               },
               "latest-maximum-amount-credit": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "5000"
               },
               "is-unlimited-credit": {
                 "__prefix": "vs",
                 "__text": "false"
               },
               "association-information": {
                 "latest-start-date": {
                   "__prefix": "vs",
                   "__text": "2013-12-20"
                 },
                 "is-serious-credit-infringement": {
                   "__prefix": "vs",
                   "__text": "false"
                 },
                 "__prefix": "vs"
               },
               "repayment-history": {
                 "payment": [
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-01"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "additional-data-fields":{
                        "data-field": {
                          "key": {
                            "__prefix": "vs"
                          },
                          "value": {
                            "__prefix": "vs"
                          },
                          "__prefix": "vs"
                        },
                      "__prefix": "vs"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-02"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-03"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-04"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-05"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-06"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-07"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-08"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-09"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-10"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-11"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2013-12"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-01"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "R"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-02"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "R"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-03"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "0"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-04"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "1"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-05"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "2"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-06"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "1"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-07"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "0"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-08"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "0"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-09"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "0"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-10"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "0"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-11"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   },
                   {
                     "period": {
                       "__prefix": "vs",
                       "__text": "2014-12"
                     },
                     "status": {
                       "__prefix": "vs",
                       "__text": "C"
                     },
                     "__prefix": "vs"
                   }
                 ],
                 "__prefix": "vs"
               },
               "_account-data-level": "5",
               "_latest-update-date": "2014-12-02",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-id": {
                 "__prefix": "vs",
                 "__text": "54854SF454"
               },
               "original-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "BLUEDIO"
                 },
                 "__prefix": "vs"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "BLUEDIO"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "PF",
                 "__prefix": "vs",
                 "__text": "Personal Loan (fixed term)"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "account-holder-count": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "account-open-date": {
                 "__prefix": "vs",
                 "__text": "2009-03-02"
               },
               "account-closed-date": {
                 "__prefix": "vs",
                 "__text": "2011-11-22"
               },
               "account-reopen-date": {
                 "__prefix": "vs",
                 "__text": "2012-01-20"
               },
               "loan-payment-method": {
                 "_code": "I",
                 "__prefix": "vs",
                 "__text": "Interest only"
               },
               "term-type": {
                 "_code": "F",
                 "__prefix": "vs",
                 "__text": "Fixed"
               },
               "secured-credit": {
                 "_code": "S",
                 "__prefix": "vs",
                 "__text": "Secured or Partially Secured"
               },
               "term-of-loan": {
                 "__prefix": "vs",
                 "__text": "060"
               },
               "original-maximum-amount-credit": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "12000"
               },
               "latest-maximum-amount-credit": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "12000"
               },
               "is-unlimited-credit": {
                 "__prefix": "vs",
                 "__text": "false"
               },
               "association-information": {
                 "latest-start-date": {
                   "__prefix": "vs",
                   "__text": "2009-03-02"
                 },
                 "is-serious-credit-infringement": {
                   "__prefix": "vs",
                   "__text": "false"
                 },
                 "__prefix": "vs"
               },
               "_account-data-level": "4",
               "_latest-update-date": "2014-12-02",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-id": {
                 "__prefix": "vs",
                 "__text": "025874WER45"
               },
               "original-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "AGC TEST P/L"
                 },
                 "__prefix": "vs"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "AGC TEST P/L"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "TC",
                 "__prefix": "vs",
                 "__text": "Telecommunications Services"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "association-information": {
                 "is-serious-credit-infringement": {
                   "__prefix": "vs",
                   "__text": "false"
                 },
                 "__prefix": "vs"
               },
               "defaults": {
                 "default": {
                   "status": {
                     "_code": "P",
                     "__prefix": "vs",
                     "__text": "Paid"
                   },
                   "status-date": {
                     "__prefix": "vs",
                     "__text": "2011-01-20"
                   },
                   "original-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2009-01-15"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "500"
                     },
                     "__prefix": "vs"
                   },
                   "current-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2011-01-20"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "500"
                     },
                     "__prefix": "vs"
                   },
                   "__prefix": "vs"
                 },
                 "__prefix": "vs"
               },
               "_account-data-level": "2",
               "_latest-update-date": "2014-12-02",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-id": {
                 "__prefix": "vs",
                 "__text": "4611151"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "ITIG TCPIP 003 ACTIVE BRANCH"
                 },
                 "is-licensee": {
                   "__prefix": "vs",
                   "__text": "true"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "AL",
                 "__prefix": "vs",
                 "__text": "Auto Loan"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "association-information": {
                 "is-serious-credit-infringement": {
                   "__prefix": "vs",
                   "__text": "false"
                 },
                 "__prefix": "vs"
               },
               "defaults": {
                 "default": {
                   "status": {
                     "_code": "O",
                     "__prefix": "vs",
                     "__text": "Outstanding"
                   },
                   "status-date": {
                     "__prefix": "vs",
                     "__text": "2014-03-01"
                   },
                   "original-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2014-03-01"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "35000"
                     },
                     "__prefix": "vs"
                   },
                   "current-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2014-03-01"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "45000"
                     },
                     "__prefix": "vs"
                   },
                   "__prefix": "vs"
                 },
                 "__prefix": "vs"
               },
               "_account-data-level": "2",
               "_latest-update-date": "2014-12-02",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "__prefix": "vs"
       },
       "commercial-credit-file": {
         "credit-enquiries": {
           "credit-enquiry": {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "__prefix": "vs"
             },
             "account-type": {
               "_code": "CH",
               "__prefix": "vs",
               "__text": "Cheque"
             },
             "enquiry-amount": {
               "_currency-code": "AUD",
               "__prefix": "vs",
               "__text": "1000"
             },
             "relationship": {
               "_code": "2",
               "__prefix": "vs",
               "__text": "Principal's Account (joint)"
             },
             "credit-enquirer": {
               "name": {
                 "__prefix": "vs",
                 "__text": "SOLUTIONS TEST"
               },
               "__prefix": "vs"
             },
             "_enquiry-date": "2014-02-20",
             "_type": "credit-enquiry",
             "__prefix": "vs"
           },
           "__prefix": "vs"
         },
         "accounts": {
           "account": [
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "account-id": {
                 "__prefix": "vs",
                 "__text": "525455525411"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "AGC TEST P/L"
                 },
                 "__prefix": "vs"
               },
               "account-type": {
                 "_code": "PL",
                 "__prefix": "vs",
                 "__text": "Personal Loan"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "association-information": {
                 "is-serious-credit-infringement": {
                   "__prefix": "vs",
                   "__text": "false"
                 },
                 "__prefix": "vs"
               },
               "defaults": {
                 "default": {
                   "status": {
                     "_code": "O",
                     "__prefix": "vs",
                     "__text": "Outstanding"
                   },
                   "original-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2014-03-01"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "1600"
                     },
                     "reason-to-report": {
                       "_code": "PD",
                       "__prefix": "vs",
                       "__text": "Payment Default"
                     },
                     "__prefix": "vs"
                   },
                   "current-default": {
                     "date-recorded": {
                       "__prefix": "vs",
                       "__text": "2014-03-01"
                     },
                     "default-amount": {
                       "_currency-code": "AUD",
                       "__prefix": "vs",
                       "__text": "1100"
                     },
                     "reason-to-report": {
                       "_code": "PD",
                       "__prefix": "vs",
                       "__text": "Payment Default"
                     },
                     "__prefix": "vs"
                   },
                   "__prefix": "vs"
                 },
                 "__prefix": "vs"
               },
               "_account-data-level": "2",
               "_latest-update-date": "2014-12-02",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "latest-credit-provider": {
                 "name": {
                   "__prefix": "vs",
                   "__text": "AGC LIMITED"
                 },
                 "__prefix": "vs"
               },
               "association-information": {
                 "latest-start-date": {
                   "__prefix": "vs",
                   "__text": "2004-06-15"
                 },
                 "__prefix": "vs"
               },
               "_account-data-level": "1",
               "_latest-update-date": "2008-04-29",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "__prefix": "vs"
       },
       "public-record-or-consumer-credit-information": {
         "court-actions": {
           "court-action": [
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "action-date": {
                 "__prefix": "vs",
                 "__text": "2014-12-02"
               },
               "creditor": {
                 "__prefix": "vs",
                 "__text": "ELISA FLOYD"
               },
               "court-action-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "1500"
               },
               "plaint-number": {
                 "__prefix": "vs",
                 "__text": "152487454/13/QGLN"
               },
               "court-type": {
                 "__prefix": "vs",
                 "__text": "District Court"
               },
               "relationship": {
                 "_code": "1",
                 "__prefix": "vs",
                 "__text": "Principal's Account (sole)"
               },
               "_type": "other-court-action",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "action-date": {
                 "__prefix": "vs",
                 "__text": "2011-12-02"
               },
               "creditor": {
                 "__prefix": "vs",
                 "__text": "SARAH CLARKSON"
               },
               "court-action-amount": {
                 "_currency-code": "AUD",
                 "__prefix": "vs",
                 "__text": "2500"
               },
               "plaint-number": {
                 "__prefix": "vs",
                 "__text": "15484511548/12/METN"
               },
               "court-type": {
                 "__prefix": "vs",
                 "__text": "Local Court"
               },
               "relationship": {
                 "_code": "2",
                 "__prefix": "vs",
                 "__text": "Principal's Account (joint)"
               },
               "co-borrower": {
                 "__prefix": "vs",
                 "__text": "COM"
               },
               "court-action-status": {
                 "_code": "S",
                 "_date": "2014-10-01",
                 "__prefix": "vs",
                 "__text": "Settled"
               },
               "_type": "default-judgement",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "disqualified-directorships": {
           "disqualified-directorship": {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "__prefix": "vs"
             },
             "date-disqualified": {
               "__prefix": "vs",
               "__text": "2008-02-21"
             },
             "date-disqualified-until": {
               "__prefix": "vs",
               "__text": "2010-02-21"
             },
             "__prefix": "vs"
           },
           "__prefix": "vs"
         },
         "directorships": {
           "directorship": [
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "date-appointed": {
                 "__prefix": "vs",
                 "__text": "1999-01-01"
               },
               "organisation": {
                 "bureau-reference": {
                   "__prefix": "vs",
                   "__text": "165968358"
                 },
                 "name": {
                   "__prefix": "vs",
                   "__text": "MELISSA'S SWEAT SHOP"
                 },
                 "type": {
                   "_code": "P/L",
                   "__prefix": "vs",
                   "__text": "Proprietary Limited"
                 },
                 "status": {
                   "_code": "REGD",
                   "__prefix": "vs",
                   "__text": "Registered"
                 },
                 "__prefix": "vs"
               },
               "_type": "C",
               "__prefix": "vs"
             },
             {
               "id-references": {
                 "id-reference": {
                   "__prefix": "vs",
                   "__text": "1"
                 },
                 "__prefix": "vs"
               },
               "date-appointed": {
                 "__prefix": "vs",
                 "__text": "2005-01-21"
               },
               "date-ceased": {
                 "__prefix": "vs",
                 "__text": "2007-03-21"
               },
               "date-last-known-as-director": {
                 "__prefix": "vs",
                 "__text": "2007-03-21"
               },
               "organisation": {
                 "bureau-reference": {
                   "__prefix": "vs",
                   "__text": "166368656"
                 },
                 "name": {
                   "__prefix": "vs",
                   "__text": "MULLIGANS FOOD"
                 },
                 "type": {
                   "_code": "P/L",
                   "__prefix": "vs",
                   "__text": "Proprietary Limited"
                 },
                 "organisation-number": {
                   "__prefix": "vs",
                   "__text": "000309786"
                 },
                 "__prefix": "vs"
               },
               "_type": "P",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "business-names": {
           "business-name": {
             "id-references": {
               "id-reference": {
                 "__prefix": "vs",
                 "__text": "1"
               },
               "__prefix": "vs"
             },
             "start-date": {
               "__prefix": "vs",
               "__text": "2001-03-16"
             },
             "business": {
               "bureau-reference": {
                 "__prefix": "vs",
                 "__text": "165289307"
               },
               "name": {
                 "__prefix": "vs",
                 "__text": "MARYS MAX SHOES"
               },
               "__prefix": "vs"
             },
             "__prefix": "vs"
           },
           "__prefix": "vs"
         },
         "__prefix": "vs"
       },
       "__prefix": "vs"
     },
     "summary-data": {
       "data-block": {
         "characteristic": [
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0001_ac_op_g_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open guarantor accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0001",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0002_rm_ac_op_g_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open guarantor mortgage accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0002",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0003_ac_op_g_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open guarantor accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0003",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0004_rm_ac_op_g_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open guarantor mortgage accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0004",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0005_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0005",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0006_tc_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open telco accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0006",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0007_ut_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open utility accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0007",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0008_gcc_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0008",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0009_gpl_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0009",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0010_rm_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0010",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0011_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0011",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0012_tc_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open telco accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0012",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0013_ut_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open utility accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0013",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0014_gcc_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0014",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0015_gpl_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0015",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0016_rm_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0016",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0077_re_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open rental accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0077",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0078_od_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open overdraft accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0078",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0079_ca_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open charge card accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0079",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0080_re_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open rental accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0080",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0081_od_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open overdraft accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0081",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0082_ca_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open charge card accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0082",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0401_al_ac_op_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0401",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0402_al_ac_op_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0402",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0203_lmt_rs1_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open account limits where repayment status was 1+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0203",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0204_lmt_rs2_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open account limits where repayment status was 2+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0204",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0205_lmt_rs3_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open account limits where repayment status was 3+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0205",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0206_lmt_rs4_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open account limits where repayment status was 4+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0206",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0391_nrm_lmt_rs1_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open non-mortgage account limits where repayment status was 1+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0391",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0392_nrm_lmt_rs2_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open non-mortgage account limits where repayment status was 2+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0392",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0393_nrm_lmt_rs3_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open non-mortgage account limits where repayment status was 3+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0393",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0394_nrm_lmt_rs4_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open non-mortgage account limits where repayment status was 4+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0394",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0207_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "36.8"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0207",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0208_tc_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open telco account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0208",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0209_ut_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open utility account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0209",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0210_gcc_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open credit card account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0210",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0211_gpl_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open personal loan account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "36.8"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0211",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0212_rm_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open mortgage account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0212",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0213_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "36.8"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0213",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0214_tc_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open telco account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0214",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0215_ut_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open utility account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0215",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0216_gcc_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open credit card account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0216",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0217_gpl_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open personal loan account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "36.8"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0217",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0218_rm_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open mortgage account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0218",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0425_al_op_ac_oldest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest open auto account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0425",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0426_al_op_ac_ygest_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of youngest open auto account"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0426",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0231_rs4_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0231",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0232_rs3_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0232",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0233_rs2_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0233",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0234_rs1_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0234",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0235_rs0_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "5"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0235",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0236_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0236",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0237_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0237",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0238_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0238",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0239_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0239",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0240_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "5"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0240",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0261_gcc_rs4_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open credit card account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0261",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0262_gcc_rs3_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open credit card account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0262",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0263_gcc_rs2_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open credit card account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0263",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0264_gcc_rs1_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open credit card account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0264",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0265_gcc_rs0_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open credit card account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "5"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0265",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0266_gcc_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open credit card account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0266",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0267_gcc_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open credit card account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0267",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0268_gcc_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open credit card account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0268",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0269_gcc_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open credit card account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0269",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0270_gcc_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open credit card account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "5"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0270",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0271_gpl_rs4_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open personal loan account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0271",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0272_gpl_rs3_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open personal loan account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0272",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0273_gpl_rs2_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open personal loan account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0273",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0274_gpl_rs1_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open personal loan account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0274",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0275_gpl_rs0_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open personal loan account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0275",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0276_gpl_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open personal loan account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0276",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0277_gpl_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open personal loan account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0277",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0278_gpl_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open personal loan account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0278",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0279_gpl_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open personal loan account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0279",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0280_gpl_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open personal loan account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0280",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0281_rm_rs4_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open mortgage account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0281",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0282_rm_rs3_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open mortgage account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0282",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0283_rm_rs2_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open mortgage account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0283",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0284_rm_rs1_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open mortgage account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0284",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0285_rm_rs0_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open mortgage account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0285",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0286_rm_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open mortgage account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0286",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0287_rm_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open mortgage account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0287",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0288_rm_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open mortgage account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0288",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0289_rm_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open mortgage account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0289",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0290_rm_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open mortgage account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0290",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0427_al_rs4_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open auto account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0427",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0428_al_rs3_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open auto account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0428",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0429_al_rs2_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open auto account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0429",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0430_al_rs1_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open auto account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0430",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0431_al_rs0_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where the worst open auto account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0431",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0432_al_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open auto account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0432",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0433_al_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open auto account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0433",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0434_al_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open auto account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0434",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0435_al_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open auto account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0435",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0436_al_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where the worst open auto account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0436",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2236_rs4_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2236",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2237_rs3_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2237",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2238_rs2_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2238",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2239_rs1_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2239",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2240_rs0_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "4"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2240",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2266_gcc_rs4_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open credit card account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2266",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2267_gcc_rs3_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open credit card account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2267",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2268_gcc_rs2_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open credit card account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2268",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2269_gcc_rs1_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open credit card account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2269",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2270_gcc_rs0_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open credit card account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "4"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2270",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2276_gpl_rs4_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open personal loan account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2276",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2277_gpl_rs3_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open personal loan account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2277",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2278_gpl_rs2_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open personal loan account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2278",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2279_gpl_rs1_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open personal loan account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2279",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2280_gpl_rs0_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open personal loan account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2280",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2281_al_rs4_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open auto account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2281",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2282_al_rs3_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open auto account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2282",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2283_al_rs2_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open auto account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2283",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2284_al_rs1_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open auto account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2284",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2285_al_rs0_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open auto account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2285",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2286_rm_rs4_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open mortgage account repayment status was 4+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2286",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2287_rm_rs3_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open mortgage account repayment status was 3+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2287",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2288_rm_rs2_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open mortgage account repayment status was 2+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2288",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2289_rm_rs1_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open mortgage account repayment status was 1+"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2289",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2290_rm_rs0_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 6 where the worst open mortgage account repayment status was current"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2290",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0339_time_worst_dlq_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since worst open account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0339",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0342_gcc_time_worst_dlq_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since worst open credit card account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0342",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0343_gpl_time_worst_dlq_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since worst open personal loan account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0343",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0344_rm_time_worst_dlq_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since worst open mortgage account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0344",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0437_al_time_worst_dlq_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since worst open auto account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0437",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0345_time_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last closed account in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "11.82"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0345",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0086_lmt_rs1_rate_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open account limits where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0086",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0087_lmt_rs1_rate_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open account limits where repayment status was 1+"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0087",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0088_lmt_rs2_rate_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open account limits where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0088",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0089_lmt_rs2_rate_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open account limits where repayment status was 2+"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0089",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0090_nrm_lmt_rs1_rate_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open non-mortgage account limits where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0090",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0091_nrm_lmt_rs1_rate_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open non-mortgage account limits where repayment status was 1+"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0091",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0092_nrm_lmt_rs2_rate_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open non-mortgage account limits where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0092",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0093_nrm_lmt_rs2_rate_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open non-mortgage account limits where repayment status was 2+"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0093",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0017_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0017",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0018_tc_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed telco accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0018",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0019_ut_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed utility accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0019",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0020_gcc_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed credit card accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0020",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0021_gpl_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed personal loan accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0021",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0022_rm_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed mortgage accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0022",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0403_al_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed auto accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0403",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0449_ca_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed charge accounts < 24 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0449",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0450_od_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed overdraft accounts < 24 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0450",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0451_re_ac_cls_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of closed equipment hire/rental accounts < 24 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0451",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0047_ac_op_lmt_mxs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0047",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0048_sec_ac_op_lmt_mxs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open secured account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0048",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0049_nsec_ac_op_lmt_mxs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open unsecured account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0049",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0050_ac_op_lmt_mns_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0050",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0051_sec_ac_op_lmt_mns_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open secured account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0051",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0052_nsec_ac_op_lmt_mns_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open unsecured account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0052",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0055_nsec_ac_op_lmt_avs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Average sum of open unsecured account limits in the last 24 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0055",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0056_ac_op_lmt_mxs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0056",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0057_sec_ac_op_lmt_mxs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open secured account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0057",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0058_nsec_ac_op_lmt_mxs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open unsecured account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0058",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0059_ac_op_lmt_mns_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0059",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0060_sec_ac_op_lmt_mns_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open secured account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0060",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0061_nsec_ac_op_lmt_mns_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open unsecured account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0061",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0064_nsec_ac_op_lmt_avs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Average sum of open unsecured account limits in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0064",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0068_ac_op_lmt_mxs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0068",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0069_sec_ac_op_lmt_mxs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open secured account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0069",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0070_nsec_ac_op_lmt_mxs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Maximum sum of open unsecured account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0070",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0071_ac_op_lmt_mns_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0071",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0072_sec_ac_op_lmt_mns_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open secured account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0072",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0073_nsec_ac_op_lmt_mns_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Minimum sum of open unsecured account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0073",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0076_nsec_ac_op_lmt_avs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Average sum of open unsecured account limits in the last 6 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0076",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0395_sec_lmt_rate_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open secured account limits in the last 6 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "1.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0395",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0396_nsec_lmt_rate_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open unsecured account limits in the last 6 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0396",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0397_sec_lmt_rate_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open secured account limits in the last 12 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "1.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0397",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0398_nsec_lmt_rate_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Proportion of open unsecured account limits in the last 12 months"
             },
             "value": {
               "_unit": "percentage",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0398",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0348_time_lmt_inc_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last open account limits increased in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0348",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0389_nsec_time_lmt_inc_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last open unsecured account limits increased in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0389",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0390_sec_time_lmt_inc_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last open secured account limits increased in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0390",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0349_gcc_time_lmt_dec_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last open credit card account limits decreased in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0349",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0350_months_lmt_inc_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 24 where open account limits increased"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0350",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0351_months_lmt_inc_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months out of the last 12 where open account limits increased"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0351",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0023_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0023",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0024_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0024",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0026_gcc_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0026",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0027_gcc_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0027",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0029_gpl_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0029",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0030_gpl_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0030",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0032_grm_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0032",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0033_grm_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0033",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0035_tc_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open telco accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0035",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0036_tc_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open telco accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0036",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0038_ut_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open utility accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0038",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0039_ut_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open utility accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0039",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0404_al_ac_op_al6m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where age of account <= 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0404",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0405_al_ac_op_agt6l12m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where age of account > 6 and <= 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0405",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7385_ac_op_al3m_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where age of account <= 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX7385",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0352_wrs_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst repayment status at closure in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0352",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0355_gcc_wrs_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst credit card repayment status at closure in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0355",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0356_gpl_wrs_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst personal loan repayment status at closure in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0356",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0357_rm_wrs_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst mortgage repayment status at closure in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0357",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0438_al_wrs_closure_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst auto repayment status at closure in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0438",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0362_ac_wrs_own_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own worst account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0362",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0363_ac_wrs_own_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own worst account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0363",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0353_ac_rs1_own_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0353",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0364_ac_own_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0364",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0365_lmt_own_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own sum of account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0365",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0366_lmt_rs1_own_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own sum of account limits where repayment status was 1+"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0366",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7364_ntcut_dist_cp_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct Credit Provider open non-telco and non-utility accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX7364",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7365_tcut_dist_cp_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct Credit Provider open telco or utility accounts in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX7365",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7366_tcut_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct Credit Provider open telco or utility accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX7366",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7367_ntcut_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct Credit Provider open non-telco and non-utility accounts"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX7367",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7377_ntcut_rs1_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open non-telco and non-utility accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7377",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7378_ntcut_rs2_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open non-telco and non-utility accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7378",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7379_ntcut_rs3_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open non-telco and non-utility accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7379",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7380_ntcut_rs4_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open non-telco and non-utility accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7380",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7381_tcut_rs1_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open telco or utility accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7381",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7382_tcut_rs2_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open telco or utility accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7382",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7383_tcut_rs3_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open telco or utility accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7383",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX7384_tcut_rs4_dist_cp_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of distinct credit provider open telco or utility accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX7384",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0041_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0041",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0044_gcc_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open credit card account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0044",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0045_gpl_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open personal loan account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0045",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0046_rm_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open mortgage account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0046",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0066_sec_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open secured account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "12000"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0066",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0067_nsec_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open unsecured account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0067",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0083_re_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open rental account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0083",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0084_od_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open overdraft account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0084",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0085_ca_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open charge card account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0085",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0358_ac_op_g_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open guarantor account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0358",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0359_gcc_ac_op_g_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open guarantor credit card account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0359",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0360_gpl_ac_op_g_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open guarantor personal loan account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0360",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0361_rm_ac_op_g_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open guarantor mortgage account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0361",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0406_al_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open auto account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0406",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0439_al_ac_op_g_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open guarantor auto account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "P",
               "__prefix": "vs",
               "__text": "Partial"
             },
             "_id": "CX0439",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0452_tc_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open telco account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0452",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0453_ua_ac_op_lmt_s_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Sum of open utilities account limits"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0453",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0094_ac_rs0_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was current"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0094",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0095_ac_rs1_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0095",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0096_ac_rs2_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0096",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0097_ac_rs3_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0097",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0098_ac_rs4_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0098",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0099_ac_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was current in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0099",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0100_ac_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0100",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0101_ac_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0101",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0102_ac_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0102",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0103_ac_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open accounts where repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0103",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0124_gcc_ac_rs0_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was current"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0124",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0125_gcc_ac_rs1_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0125",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0126_gcc_ac_rs2_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0126",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0127_gcc_ac_rs3_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0127",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0128_gcc_ac_rs4_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0128",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0129_gcc_ac_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was current in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0129",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0130_gcc_ac_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0130",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0131_gcc_ac_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0131",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0132_gcc_ac_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0132",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0133_gcc_ac_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open credit card accounts where repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0133",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0134_gpl_ac_rs0_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was current"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0134",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0135_gpl_ac_rs1_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0135",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0136_gpl_ac_rs2_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0136",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0137_gpl_ac_rs3_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0137",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0138_gpl_ac_rs4_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0138",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0139_gpl_ac_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was current in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0139",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0140_gpl_ac_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0140",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0141_gpl_ac_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0141",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0142_gpl_ac_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0142",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0143_gpl_ac_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open personal loan accounts where repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0143",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0144_rm_ac_rs0_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was current"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0144",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0145_rm_ac_rs1_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0145",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0146_rm_ac_rs2_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0146",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0147_rm_ac_rs3_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0147",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0148_rm_ac_rs4_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0148",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0149_rm_ac_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was current in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0149",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0150_rm_ac_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0150",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0151_rm_ac_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0151",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0152_rm_ac_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0152",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0153_rm_ac_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open mortgage accounts where repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0153",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0407_al_ac_rs0_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was current"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0407",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0408_al_ac_rs1_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 1+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0408",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0409_al_ac_rs2_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 2+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0409",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0410_al_ac_rs3_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 3+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0410",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0411_al_ac_rs4_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 4+"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0411",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0412_al_ac_rs0_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was current in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0412",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0413_al_ac_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0413",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0414_al_ac_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0414",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0415_al_ac_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0415",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0416_al_ac_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of open auto accounts where repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0416",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0160_ac_op_mth_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open account repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0160",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0163_gcc_ac_op_mth_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open credit card account repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0163",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0164_gpl_ac_op_mth_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open personal loan account repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0164",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0165_rm_ac_op_mth_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open mortgage account repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0165",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0166_ac_op_mth_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open account repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0166",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0169_gcc_ac_op_mth_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open credit card account repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0169",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0170_gpl_ac_op_mth_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open personal loan account repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0170",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0171_rm_ac_op_mth_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open mortgage account repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0171",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0172_ac_op_mth_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open account repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0172",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0175_gcc_ac_op_mth_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open credit card account repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0175",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0176_gpl_ac_op_mth_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open personal loan account repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0176",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0177_rm_ac_op_mth_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open mortgage account repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0177",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0178_ac_op_mth_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open account repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0178",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0181_gcc_ac_op_mth_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open credit card account repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0181",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0182_gpl_ac_op_mth_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open personal loan account repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0182",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0183_rm_ac_op_mth_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open mortgage account repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0183",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0418_al_ac_op_mth_rs1_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open auto account repayment status was 1+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0418",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0419_al_ac_op_mth_rs2_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open auto account repayment status was 2+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0419",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0420_al_ac_op_mth_rs3_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open auto account repayment status was 3+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0420",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0421_al_ac_op_mth_rs4_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since any open auto account repayment status was 4+ in the last 24 months"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0421",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0184_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0184",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0185_wrs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0185",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0186_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0186",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0193_gcc_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open credit card account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0193",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0194_gcc_wrs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open credit card account repayment status in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0194",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0195_gcc_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open credit card account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0195",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0196_gpl_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open personal loan account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0196",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0197_gpl_wrs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open personal loan account repayment status in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0197",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0198_gpl_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open personal loan account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0198",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0199_rm_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open mortgage account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0199",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0200_rm_wrs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open mortgage account repayment status in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0200",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0201_rm_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open mortgage account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0201",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0399_wrs_al6m_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status where age of account <= 6 months in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0399",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0400_wrs_agt6l12m_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status where age of account > 6 and <= 12 months in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0400",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0422_al_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open auto account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0422",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0423_al_wrs_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open auto account repayment status in the last 12 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0423",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0424_al_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open auto account repayment status in the last 24 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0424",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0443_ca_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open charge card account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0443",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0444_ca_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open charge card account repayment status < 24 mths"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0444",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0445_od_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open overdraft account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0445",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0446_od_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open overdraft account repayment status < 24 mths"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0446",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0447_re_wrs_0m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open equipment hire/rental account repayment status"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0447",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX0448_re_wrs_24m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open equipment hire/rental account repayment status < 24 mths"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX0448",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2186_wrs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2186",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2195_gcc_wrs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open credit card account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2195",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2198_gpl_wrs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open personal loan account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2198",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2199_al_wrs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open auto account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2199",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "CX2201_rm_wrs_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Worst open mortgage account repayment status in the last 6 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "C",
               "__prefix": "vs",
               "__text": "Comprehensive"
             },
             "_id": "CX2201",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8900_xr_cur"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of cross references"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8900",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8904_age_fle"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of primary file"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "171.74"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8904",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8905_ntb_flg"
             },
             "description": {
               "__prefix": "vs",
               "__text": "New to bureau flag"
             },
             "value": {
               "_unit": "boolean",
               "__prefix": "vs",
               "__text": "Yes"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8905",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8908_age_ind"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Main age of applicant"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "833.99"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8908",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8911_deceased_flg"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Deceased flag"
             },
             "value": {
               "_unit": "boolean",
               "__prefix": "vs",
               "__text": "No"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8911",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8920_em_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months at latest employer"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "3.77"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8920",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8921_ad_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months at latest address"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "0.0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8921",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8922_file_notes"
             },
             "description": {
               "__prefix": "vs",
               "__text": "File notes"
             },
             "value": {
               "_unit": "boolean",
               "__prefix": "vs",
               "__text": "-993"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8922",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7500_inslv_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of insolvencies in the last 84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7500",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7501_inslv_dis_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of discharged insolvencies in the last 84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7501",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7502_inslv_bkrpt_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of bankrupt only insolvencies in the last 84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7502",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7503_inslv_dis_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last discharged insolvency"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7503",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7504_dj_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of judgements in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7504",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7505_dj_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last judgement"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "36.0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7505",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7509_wr_48m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of court writs in the last 48 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7509",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8500_dr_cur"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of current directorships"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8500",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8501_dr_time_max_cur"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months of longest current directorship"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "191.01"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8501",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8502_dr_prev_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of previous directorships in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8502",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8503_dr_disq_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of Disqualified Directorships in the last 60 months-ever"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8503",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8504_dr_disq_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last disqualified directorship"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "81.81"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8504",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8508_pr_cur"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of business names held"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8508",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8509_dr_prev_120m_ever"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of previous directorships in the last 120 months-ever"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8509",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7513_df_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer defaults/SCI in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7513",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7514_df_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7514",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7516_df_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "2"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7516",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7517_df_tcut_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility defaults/SCI in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7517",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7518_df_tcut_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7518",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7520_df_tcut_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7520",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7524_df_rm_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7524",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7556_df_sts_pd_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer paid or current defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7556",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7560_df_sts_stld_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer settled defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7560",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7561_df_sts_na_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer new arrangement defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7561",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7568_df_sts_unpd_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer unpaid defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7568",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7585_df_origamt_tcut_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total original dollar amount of consumer telco or utility defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "500"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7585",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7586_df_origamt_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total original dollar amount of consumer defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "35500"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7586",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7589_df_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last consumer default/SCI"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "70.87"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7589",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7591_df_s_1_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Status of last consumer default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "P"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7591",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7596_df_own_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of consumer default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7596",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7597_df_latamt_own_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own total latest dollar amount of consumer default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7597",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7801_df_gpl_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer personal loan defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7801",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7802_df_al_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer auto loan defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7802",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7803_df_cc_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer credit card defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7803",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7804_df_ca_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer charge card defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7804",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7805_df_od_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer overdraft defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7805",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7806_df_re_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer equipment hire/rental defaults/SCI < 60/84 mths"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7806",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7999_enq_7d"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 7 days"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7999",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8000_enq_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8000",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8001_enq_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "2"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8001",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8002_enq_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8002",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8003_enq_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8003",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8006_enq_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "3"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8006",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8028_enq_rm_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8028",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8029_enq_rm_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8029",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8030_enq_rm_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8030",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8031_enq_rm_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8031",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8034_enq_rm_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer mortgage enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8034",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8042_enq_tcut_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8042",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8043_enq_tcut_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8043",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8044_enq_tcut_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8044",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8045_enq_tcut_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8045",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8048_enq_tcut_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer telco or utility enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8048",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8049_enq_own_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of consumer enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8049",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8050_enq_own_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of consumer enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8050",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8056_enq_amt_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Dollar amount of last consumer enquiry"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "8484"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8056",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8057_enq_amt_2"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Dollar amount of 2nd last consumer enquiry"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8057",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8059_enq_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last consumer enquiry"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "1.0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8059",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8060_enq_time_2"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since 2nd last consumer enquiry"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "5.85"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8060",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8062_enq_amt_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total value of consumer enquiries < 3 mths"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "13484"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8062",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX8063_enq_amt_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total value of consumer enquiries < 60 mths"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "13485"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX8063",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7513_df_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial defaults/SCI in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7513",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7514_df_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7514",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7516_df_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7516",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7517_df_tcut_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility defaults/SCI in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7517",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7518_df_tcut_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7518",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7520_df_tcut_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7520",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7524_df_RM_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7524",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7568_df_sts_unpd_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial unpaid defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7568",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7585_df_origamt_tcut_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total original dollar amount of commercial telco or utility defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7585",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7586_df_origamt_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total original dollar amount of commercial defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1600"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7586",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7589_df_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last commercial default/SCI"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "9.01"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7589",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7591_df_s_1_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Status of last commercial default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "O"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7591",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7596_df_own_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of commercial default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7596",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7597_df_latamt_own_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own total latest dollar amount of commercial default/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7597",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7999_enq_7d"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 7 days"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7999",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8000_enq_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8000",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8001_enq_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8001",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8002_enq_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8002",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8003_enq_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8003",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8006_enq_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8006",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8028_enq_rm_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8028",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8029_enq_rm_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8029",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8030_enq_rm_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8030",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8031_enq_rm_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8031",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8034_enq_rm_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial mortgage enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8034",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8042_enq_tcut_1m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility enquiries in the last 1 month"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8042",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8043_enq_tcut_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8043",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8044_enq_tcut_6m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility enquiries in the last 6 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8044",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8045_enq_tcut_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8045",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8048_enq_tcut_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial telco or utility enquiries in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8048",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8049_enq_own_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of commercial enquiries in the last 3 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8049",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8050_enq_own_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Own number of commercial enquiries in the last 12 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8050",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8056_enq_amt_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Dollar amount of last commercial enquiry"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1000"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8056",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8057_enq_amt_2"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Dollar amount of 2nd last commercial enquiry"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8057",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8059_enq_time_1"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since last commercial enquiry"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "9.82"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8059",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8060_enq_time_2"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Months since 2nd last commercial enquiry"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "-991.0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8060",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8062_enq_amt_3m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total value of commercial enquiries < 3 mths"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8062",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY8063_enq_amt_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total value of commercial enquiries < 60 mths"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1000"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY8063",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NA8902_age_fle_max"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Age of oldest file"
             },
             "value": {
               "_unit": "months",
               "__prefix": "vs",
               "__text": "171.74"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NA8902",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7506_dj_out_amt_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total dollar amount of outstanding (not settled paid or discontinued) judgements in the last 60 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7506",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7508_dj_out_60m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of unpaid or unsettled judgements in the last 60 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7508",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP7510_wr_out_48m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of unpaid or unsettled court writs in the last 48 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP7510",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8505_dr_adv"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of struck-off or deregistered companies for director"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8505",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NP8506_dr_adv_disq_60m_ever"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of external administration companies for director or disqualified directorships in the last 60 months-ever"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "1"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NP8506",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7511_sci_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of consumer SCI in the last 84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7511",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7512_sci_latamt_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of consumer SCI in the last 84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "-991"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7512",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7587_df_latamt_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of consumer defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "45000"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7587",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7588_df_latamt_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of consumer defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "45500"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7588",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NX7601_adv_48_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Consumer adverse on file flag in the last 48/84 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "Y"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NX7601",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7511_sci_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Number of commercial SCI in the last 84 months"
             },
             "value": {
               "_unit": "count",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7511",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7512_sci_latamt_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of commercial SCI in the last 84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "0"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7512",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7587_df_latamt_12m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of commercial defaults/SCI in the last 12 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1100"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7587",
             "_key-characteristic": "false",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7588_df_latamt_60_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Total latest dollar amount of commercial defaults/SCI in the last 60/84 months"
             },
             "value": {
               "_unit": "AUD",
               "__prefix": "vs",
               "__text": "1100"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7588",
             "_key-characteristic": "true",
             "__prefix": "vs"
           },
           {
             "variable-name": {
               "__prefix": "vs",
               "__text": "NY7601_adv_48_84m"
             },
             "description": {
               "__prefix": "vs",
               "__text": "Commercial adverse on file flag in the last 48/84 months"
             },
             "value": {
               "_unit": "char",
               "__prefix": "vs",
               "__text": "Y"
             },
             "data-level": {
               "_code": "N",
               "__prefix": "vs",
               "__text": "Negative"
             },
             "_id": "NY7601",
             "_key-characteristic": "false",
             "__prefix": "vs"
           }
         ],
         "_name": "genesis",
         "_type": "standard",
         "__prefix": "vs"
       },
       "__prefix": "vs"
     },
     "score-data": {
       "score": {
         "scorecard": {
           "name": {
             "__prefix": "vs",
             "__text": "VedaScore Apply"
           },
           "version": {
             "__prefix": "vs",
             "__text": "2.0"
           },
           "type": {
             "__prefix": "vs",
             "__text": "Consumer+Commercial"
           },
           "data-level": {
             "_code": "C",
             "__prefix": "vs",
             "__text": "Comprehensive"
           },
           "_id": "VSA_2.0_XY_CR",
           "__prefix": "vs"
         },
         "risk-odds": {
           "__prefix": "vs",
           "__text": "6.964404506368992"
         },
         "key-contributing-factors": {
           "contributing-factor": [
             {
               "name": {
                 "__prefix": "vs",
                 "__text": "Public Adverse Information"
               },
               "description": {
                 "__prefix": "vs",
                 "__text": "The existence of public adverse information such as court judgements and writs can have a powerful impact on risk."
               },
               "score-impactor": {
                 "__prefix": "vs",
                 "__text": "Greatly Increases Risk"
               },
               "_id": "KCF-011",
               "__prefix": "vs"
             },
             {
               "name": {
                 "__prefix": "vs",
                 "__text": "Commercial Default Information"
               },
               "description": {
                 "__prefix": "vs",
                 "__text": "The existence of commercial default information can be a powerful indicator of risk."
               },
               "score-impactor": {
                 "__prefix": "vs",
                 "__text": "Marginally Increases Risk"
               },
               "_id": "KCF-010",
               "__prefix": "vs"
             },
             {
               "name": {
                 "__prefix": "vs",
                 "__text": "Time Since Observed Delinquency"
               },
               "description": {
                 "__prefix": "vs",
                 "__text": "The length of time since the last late payment made can be an indicator of risk."
               },
               "score-impactor": {
                 "__prefix": "vs",
                 "__text": "Marginally Increases Risk"
               },
               "_id": "KCF-015",
               "__prefix": "vs"
             },
             {
               "name": {
                 "__prefix": "vs",
                 "__text": "Consumer Default Information"
               },
               "description": {
                 "__prefix": "vs",
                 "__text": "The existence of consumer default information can be a powerful indicator of risk."
               },
               "score-impactor": {
                 "__prefix": "vs",
                 "__text": "Marginally Increases Risk"
               },
               "_id": "KCF-007",
               "__prefix": "vs"
             }
           ],
           "__prefix": "vs"
         },
         "score-masterscale": {
           "__prefix": "vs",
           "__text": "480"
         },
         "__prefix": "vs"
       },
       "__prefix": "vs"
     },
     "__prefix": "vs"
   },
   "_xmlns:vs": "http://vedaxml.com/vxml2/vedascore-apply-v2-0.xsd",
   "_xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
   "_response-type": "report",
   "_xsi:schemaLocation": "http://vedaxml.com/vxml2/vedascore-apply-v2-0.xsd ..\\..\\vedascore-apply-v2-0-6.xsd",
   "__prefix": "vs"
 }

export default creditSummary;
