import $ from 'jquery'
import 'bootstrap'
import '@chenfengyuan/datepicker'


export const initTonikForms = () => {

// global select in forms
$('.form-label-group').on('change', 'select.form-control', function () {
    if ($(this).val() !== '') {
      $(this).addClass('filled')
    } else {
      $(this).removeClass('filled')
    }
  })
  
  $('.form-label-group select.form-control').each(function () {
    if ($(this).val() !== '') {
      $(this).addClass('filled')
    }
  })

  $('[data-toggle="datepicker"]').datepicker()


  
  $(document).on('click', '.link-password:not(.text)', function (e) {
    $(this).addClass('text')
    $(this).parent().find('.password').attr('type', 'text')
    e.preventDefault()
  })

  $(document).on('click', '.link-password.text', function (e) {
    $(this).removeClass('text')
    $(this).parent().find('.password').attr('type', 'password')
    e.preventDefault()
  })

  
// field style page
// on change
$('.check-field-extra').on('change', function () {
  if ($(this).is(':checked')) {
    $(this).parents('.checkbox-field').find('.check-field').removeClass('d-none')
  } else {
    $(this).parents('.checkbox-field').find('.check-field').addClass('d-none')
  }
})

// after page load
$('.check-field-extra').each(function () {
  if ($(this).is(':checked')) {
    $(this).parents('.checkbox-field').find('.check-field').removeClass('d-none')
  } else {
    $(this).parents('.checkbox-field').find('.check-field').addClass('d-none')
  }
})
// end field style

$('.info-tooltip').tooltip();

  return;
}

export const initTonikFormsTooltip = () => {
  $('.info-tooltip').tooltip();
  return;
}

//$('[data-toggle="datepicker"]').datepicker()