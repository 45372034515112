import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import TextNumericInput from '../common/controls/TextNumericInput';

import LoginFrame from './LoginFrame';

const Login2FAInputBoxButton = ({ loading, enabled, textVariants }) => {

  let btnText = '';
  let btnCls = 'btn btn-primary full-width';

  btnText = textVariants["Btn_Login"];

  if (loading)
   btnCls +='btn btn-primary full-width disabled';

  return (
    <button
        type="submit"
        disabled={!enabled}
        className={btnCls}>
        {btnText}
        {loading &&(<span className="spinner-border spinner-border-sm align-middle ml-2" role="status" aria-hidden="true"></span>)}
    </button>
  )
}

const Login2FAInputBox  = ({
  textVariants,
  onChange,
  oneTimeCode,
  onActionClick,    
  otcError,
  infoMessage,
  errorMessage,
  onGoBack,
  loading,
  enabled
  }) => {    

return (
  <LoginFrame
    disclaimerCtrl={<span className="text-form">By clicking 'Login', I authorise GetCreditScore to obtain my credit score and, if I’ve elected, an overview of my personal credit file from Equifax Australia Information Services and Solutions Pty Limited.</span>}
    cssClass=" n-100vh"
  >
              <div>
              <p>Please enter the code you received to login</p>
               
               <form onSubmit={onActionClick}>
                
                <div className="form-label-group">
                        <TextNumericInput
                        name="code"
                        id="code"
                        placeholder="One Time Code"
                        value={oneTimeCode}
                        label="One Time Code"
                        onChange={onChange}
                        autoComplete="off"
                        error={otcError} 
                        maxLength={6}
                        groupClass="form-label-group"/>                  
                </div>    

                {infoMessage && 
                <div className="alert alert-warning">
                  <span dangerouslySetInnerHTML={funcs.createMarkup(infoMessage)}></span>
                </div>
              }       
              {errorMessage && 
                <div className="alert alert-danger">
                  <span dangerouslySetInnerHTML={funcs.createMarkup(errorMessage)}></span>
                </div>
              }               
                                                 
                <Login2FAInputBoxButton
                      loading={loading}
                      enabled={enabled}
                      textVariants={textVariants}                                              
                    />
                <p>Pick another destination <a href="" onClick={onGoBack}>here</a></p>
              </form>
              </div>

  </LoginFrame>
);

};

Login2FAInputBox.propTypes = {
  textVariants: PropTypes.object.isRequired,  
  onChange: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  otcError: PropTypes.object,  
  loading: PropTypes.bool,  
  enabled: PropTypes.bool,  
  onActionClick: PropTypes.func.isRequired
};

export default Login2FAInputBox;
