import React from 'react';
import PropTypes from 'prop-types';

import * as pages from '../../../shared/pages';
import * as GlobalVariables from '../../../../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../../../../config/GlobalFixedVariables';

import * as ImagesPath from '../../../../config/imagesPath';
import HelperApi from '../../../../api/HelperApi';

import HLAOptionButtons from '../WizardParts/HLAOptionButtons';
import HLAAmountPicker from '../WizardParts/HLAAmountPicker';
import HLAAmountFrequencyPicker from '../WizardParts/HLAAmountFrequencyPicker';
import HLAAmountInterestPicker from '../WizardParts/HLAAmountInterestPicker';
import HLATextPicker from '../WizardParts/HLATextPicker';
import HlaWizFinish from '../WizardParts/HlaWizFinish';
import HLAExpertWizFinish from '../WizardParts/HLAExpertWizFinish';

export const ThemePack = {
  buttons: "wizard-action-btn-theme-hla",
  navigation: "wizard-navigation-buttons-theme-hla"
};

export const WizardHLAStepComponents = [
  HLAOptionButtons,
  HLAOptionButtons,
  HLAAmountPicker,
  HLAAmountPicker,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAAmountFrequencyPicker,
  HLAAmountPicker,
  HLAAmountPicker,  
  HLAAmountPicker, //10
  HLAOptionButtons,
  HLAOptionButtons,
  HLAAmountFrequencyPicker,
  HLATextPicker, // lender, put some text one here
  HLAAmountInterestPicker, // add validation for interest rate
  HlaWizFinish,
  HLAOptionButtons,
  HLAOptionButtons,  
  HlaWizFinish
];

export const StepsIndexHLA = {
  stepIndexRefinanceOrBuy: 0,
  stepIndexChoosePath: 1,
  stepIndexBuyPurchasePrice: 2,
  stepIndexBuyDeposit: 3,
  stepIndexBuyHowClose: 4,
  stepIndexBuyLiveOrInvest: 5,
  stepIndexBuyHowToPay: 6,
  stepIndexBuyIncome: 7,
  stepIndexRefinancePropertyWorth: 8,
  stepIndexRefinanceOwe: 9,
  //stepIndexRefinanceWantAddFunds: 10,
  stepIndexRefinanceAddFunds: 10,
  stepIndexRefinanceLiveOrInvest: 11,
  stepIndexRefinanceHowToPay: 12,
  stepIndexRefinanceIncome: 13,
  stepIndexRefinanceLender: 14,
  stepIndexRefinanceLenderInterestRate: 15,
  stepIndexOnlineFinish: 16,
  stepIndexBrokerBuyWhatToDo: 17,
  stepIndexBrokerRefinanceWhatToDo: 18,  
  stepIndexBrokerFinish: 19
};


export const WizardHLAStepState = {
  promotionEnabled: true,
  steps: [
    {
      step: StepsIndexHLA.stepIndexRefinanceOrBuy,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexChoosePath,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyPurchasePrice,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyDeposit,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyHowClose,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyLiveOrInvest,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyHowToPay,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBuyIncome,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: ['', 'Yearly'],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinancePropertyWorth,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceOwe,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    // {
    //   step: StepsIndexHLA.stepIndexRefinanceWantAddFunds,
    //   enabled: true,
    //   isCompleted: false,
    //   selectedItems: [false, false, false, false, false, false],
    //   selectedExtraText: '',
    //   selectedTexts: [],
    //   errorMessage: '',
    //   isDirty: false
    // },
    {
      step: StepsIndexHLA.stepIndexRefinanceAddFunds,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceLiveOrInvest,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceHowToPay,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceIncome,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: ['', 'Yearly'],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceLender,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexRefinanceLenderInterestRate,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexOnlineFinish,
      enabled: true,
      isCompleted: false,
      selectedItems: [],
      selectedExtraText: '',
      isWhenShowCompleted: true,
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBrokerBuyWhatToDo,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    {
      step: StepsIndexHLA.stepIndexBrokerRefinanceWhatToDo,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: '',
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    },
    // {
    //   step: StepsIndexHLA.stepIndexBrokerCitizenOrPR,
    //   enabled: true,
    //   isCompleted: false,
    //   selectedItems: [false, false, false, false, false, false],
    //   selectedExtraText: '',
    //   selectedTexts: [],
    //   errorMessage: '',
    //   isDirty: false
    // },
    {
      step: StepsIndexHLA.stepIndexBrokerFinish,
      enabled: true,
      isCompleted: false,
      selectedItems: [false],
      selectedExtraText: '',
      isWhenShowCompleted: true,
      selectedTexts: [],
      errorMessage: '',
      isDirty: false
    }
  ]
};


export const WizardHLAStepsDef = [
  {
    id: 'home-refinance-or-buy',
    step: StepsIndexHLA.stepIndexRefinanceOrBuy,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'What do you want to do?',
    stepsInstruction: "",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "refinance",  value: 'Refinance my home loan', shortDisplay: 'Refinance'},
        { id: 1, key: "buy", value: 'Buy a property (cash deposit)', shortDisplay: 'Buy'},
        { id: 2, key: "refinance-equity", value: 'Buy a property (refinance & use equity)', shortDisplay: 'Purchase using Equity'},
      ]
    }
  },
  {
    id: 'choose-path',
    step: StepsIndexHLA.stepIndexChoosePath,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'How would you like to find your new home loan?',
    stepsInstruction: "",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "compare-online",  value: "Compare loans online" },
        { id: 1, key: "speak-expert", value: "Speak to an expert" },
      ]
    }
  },
  {
    id: 'home-buy-purchase-price',
    step: StepsIndexHLA.stepIndexBuyPurchasePrice,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'What is the purchase price?',
    stepsInstruction: "If you're not sure tell us how much you plan on spending.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    },
    validate: (currentStepState, stepStates, stepsDefs) => {
      const minValue = 100000, maxValue = 10000000;
      const minValueFormatted = HelperApi.toCurrencyFormat(minValue);
      const maxValueFormatted = HelperApi.toCurrencyFormat(maxValue);

      const stepValue = currentStepState.selectedTexts.length > 0 ?  currentStepState.selectedTexts[0] : undefined;
      const isValid = stepValue === undefined || (stepValue >= minValue && stepValue <= maxValue);
      return {
        isValid: isValid,
        errorMessage: "Property price must be between " + minValueFormatted + " and " + maxValueFormatted + "."
      };
    }
  },
  {
    id: 'home-buy-deposit',
    step: StepsIndexHLA.stepIndexBuyDeposit,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'How much deposit do you have?',
    stepsInstruction: "If you're still saving, tell us how much you plan to have as your deposit.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount"],
      actions: [],

    },
    validate: (currentStepState, stepStates, stepsDefs) => {
      const minValue = 10000;
      const minValueFormatted = HelperApi.toCurrencyFormat(minValue);

      const stepValue = currentStepState.selectedTexts.length > 0 ?  currentStepState.selectedTexts[0] : undefined;
      const isValid = stepValue === undefined || (stepValue >= minValue);
      return {
        isValid: isValid,
        errorMessage: "Deposit amount must be over " + minValueFormatted + "."
      };
    }
  },
  {
    id: 'home-buy-how-close',
    step: StepsIndexHLA.stepIndexBuyHowClose,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'How close are you to buying?',
    stepSubTitle: '',
    stepsInstruction: '',
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    isTextInput: false,
    stepDefinition: {
      showInCols: 2,
      actions: [
        { id: 0, key: "signedContract",  value: "I've signed the contract", isTextOutside: true, imgUrl: ImagesPath.ImgWizard_HomeLoan_Signed },
        { id: 1, key: "foundProperty",  value: 'I have found the perfect property', isTextOutside: true, imgUrl: ImagesPath.ImgWizard_HomeLoan_ThumbUp },
        { id: 2, key: "stillLooking",  value: 'I am still looking for the perfect place', isTextOutside: true, imgUrl: ImagesPath.ImgWizard_HomeLoan_Magnifier },
        { id: 3, key: "curious",  value: "I am not looking right now, just curious", isTextOutside: true, imgUrl: ImagesPath.ImgWizard_HomeLoan_Info  }
      ]
    }
  },
  {
    id: "home-buy-how-to-live",
    step: StepsIndexHLA.stepIndexBuyLiveOrInvest,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "Will you live there or is it an investment?",
    stepSubTitle: "",
    stepsInstruction: "",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    isTextInput: false,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "live", value: "I will live there" },
        { id: 1, key: "investment", value: "It is an investment" },
      ]
    }
  },
  {
    id: 'home-buy-how-to-pay',
    step: StepsIndexHLA.stepIndexBuyHowToPay,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'How do you plan to pay off the loan?',
    stepSubTitle: '',
    stepsInstruction: "An interest-only home loan is a type of loan where your repayments only cover the interest on the amount you have borrowed, during the interest-only period. There is no reduction in the principal.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    isTextInput: false,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "principalInterest", value: "Principal and Interest" },
        { id: 1, key: "interest", value: 'Interest Only' },
      ]
    }
  },
  {
    id: 'home-buy-income',
    step: StepsIndexHLA.stepIndexBuyIncome,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "What is your combined income?",
    stepsInstruction: "You (and any other applicant's) employment income plus rental income, bonuses, etc before tax",
    stepsTnCs: "Please note income is requested to ensure members are matched to partners & products accurately increasing chances of approval. It is not for the purpose of calculating your capacity for a home loan as this would be done & verified by the partner that you are referred to.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount", "freq"],
      actions: []
    }
  },
  {
    id: 'home-refinance-property-worth',
    step: StepsIndexHLA.stepIndexRefinancePropertyWorth,
    barTitle: "Your Home Loan Assistant",
    stepTitle: (wizardStepState, wizardStepIndex, wizardStepsDef) => {
      let isRefinanceUseEquity = wizardStepState[wizardStepIndex.stepIndexRefinanceOrBuy].selectedItems[2];
      
      return isRefinanceUseEquity ? "Your combined property value?" : "How much is your property worth?";
    },
    stepsInstruction: (wizardStepState, wizardStepIndex, wizardStepsDef) => {
      let isRefinanceUseEquity = wizardStepState[wizardStepIndex.stepIndexRefinanceOrBuy].selectedItems[2];

      return isRefinanceUseEquity 
              ? "Please enter the combined value of the property being refinanced <span class='gcs-bold'>PLUS the value of the property being purchased (or an estimate)</span>" 
              : "";
    },
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    },
    validate: (currentStepState, stepStates, stepsDefs) => {
      const minValue = 100000, maxValue = 10000000;
      const minValueFormatted = HelperApi.toCurrencyFormat(minValue);
      const maxValueFormatted = HelperApi.toCurrencyFormat(maxValue);

        const stepValue = currentStepState.selectedTexts.length > 0 ?  currentStepState.selectedTexts[0] : undefined;
      const isValid = stepValue === undefined || (stepValue >= minValue && stepValue <= maxValue);
      return {
        isValid: isValid,
        errorMessage: "Property price must be between " + minValueFormatted + " and " + maxValueFormatted + "."
      };
    }
  },
  {
    id: 'home-refinance-owe',
    step: StepsIndexHLA.stepIndexRefinanceOwe,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "How much do you currently owe?",
    stepsInstruction: "",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    },
    validate: (currentStepState, stepStates, stepsDefs) => {
      const minValue = 100000;
      const minValueFormatted = HelperApi.toCurrencyFormat(minValue);

      const stepValue = currentStepState.selectedTexts.length > 0 ?  currentStepState.selectedTexts[0] : undefined;
      const isValid = stepValue === undefined || (stepValue >= minValue);
      return {
        isValid: isValid,
        errorMessage: "Loan amount must be above " + minValueFormatted + "."
      };
    }
  },
  // {
  //   id: "home-refinance-eq-yes-no",
  //   step: StepsIndexHLA.stepIndexRefinanceWantAddFunds,
  //   barTitle: "",
  //   stepTitle: "Do you want to access additional funds from the equity in your home?",
  //   stepSubTitle: "",
  //   stepsInstruction: "Say <strong>Yes</strong> if you want to do something like consolidate other debt, renovate your house or obtain a deposit for another property purchase",
  //   selectedExtraIndex: 5,
  //   stepSelectionnUnique: true,
  //   stepExtraDisabled : true,
  //   stepShouldAlwaysSelect: false,
  //   hideNavigation: false,
  //   shouldMoveOnAction: true,
  //   isTextInput: false,
  //   stepDefinition: {
  //     showInCols: 1,
  //     actions: [
  //       { id: 0, key: "yes", value: "Yes" },
  //       { id: 1, key: "no", value: "No" },
  //     ]
  //   }
  // },
  {
    id: 'home-refinance-eq-funds',
    step: StepsIndexHLA.stepIndexRefinanceAddFunds,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "How much additional funds would you like?",
    stepsInstruction: "Enter the amount that you plan to spend on the new property. Enter in the amount you want to loan to cover off things like renovations, debt consolidation or an investment property.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    },
    validate: (currentStepState, stepStates, stepsDefs) => {
      // const minValue = 100000;
      // const minValueFormatted = HelperApi.toCurrencyFormat(minValue);
      //
      // const stepValue = currentStepState.selectedTexts[0];
      // const isValid = stepValue === undefined || (stepValue >= minValue);
      const isValid = true;
      return {
        isValid: isValid,
        errorMessage: ''//"Loan amount must be above " + minValueFormatted + "."
      };
    }
  },
  {
    id: "home-refinance-how-to-live",
    step: StepsIndexHLA.stepIndexRefinanceLiveOrInvest,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "Will you live there or is it an investment?",
    stepSubTitle: "",
    stepsInstruction: null,//"In our experience lenders offer lower interest rates when you live in the property",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    isTextInput: false,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "live", value: "I will live there" },
        { id: 1, key: "investment", value: "It is an investment" },
      ]
    }
  },
  {
    id: 'home-refinance-how-to-pay',
    step: StepsIndexHLA.stepIndexRefinanceHowToPay,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'How do you plan to pay off the loan?',
    stepSubTitle: '',
    stepsInstruction: null, //"If you choose principal and interest, this can help you own the property sooner. If you choose interest only, it can help lower your financial burden at the start of the loan.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    isTextInput: false,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "principalInterest", value: "Principal and Interest" },
        { id: 1, key: "interest", value: 'Interest Only' },
      ]
    }
  },
  {
    id: 'home-refinance-income',
    step: StepsIndexHLA.stepIndexRefinanceIncome,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "What is your combined income?",
    stepsInstruction: "You (and any other applicant's) employment income plus expected rental income, bonuses, etc before tax",
    stepsTnCs: "Please note income is requested to ensure members are matched to partners & products accurately increasing chances of approval. It is not for the purpose of calculating your capacity for a home loan as this would be done & verified by the partner that you are referred to.",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    glyphicon: 'glyphicon-usd',
    stepDefinition: {
      inputs: ["amount", "freq"],
      actions: []
    }
  },
  {
    id: 'home-refinance-lender',
    step: StepsIndexHLA.stepIndexRefinanceLender,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "Which lender is your current home loan with?",
    stepsInstruction: "Sometimes the best deal available is to re-negotiate with your current lender, our partners can help with that",
    stepsTnCs: "",
    selectedExtraIndex: 5,
    isOptionalStep: true,
    stepSelectionnUnique: false,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,    
    glyphicon: 'glyphicon-pencil', 
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    }
  },
  {
    id: 'home-refinance-lender-interest',
    step: StepsIndexHLA.stepIndexRefinanceLenderInterestRate,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "What is your current home loan interest rate?",
    stepsInstruction: "If you don't know just enter your best guess, so our partners can calculate your estimated savings",
    stepsTnCs: "",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: false,
    isTextInput: true,
    //glyphicon: 'glyphicon-italic',
    glyphiconAddon: '%',
    stepDefinition: {
      inputs: ["amount"],
      actions: []
    },
    validate: (currentStepState, stepStates, stepsDefs) => {
                        
        const stepValue = currentStepState.selectedTexts.length > 0 ?  currentStepState.selectedTexts[0] : undefined;
        let isValid = stepValue === undefined;
        if(!isValid){ // has a value
          
          let value = Number(stepValue);        
          if((!isNaN(value) && isFinite(value)) && stepValue.length <= 8 ){
            isValid = value <= 100.00 && value >= 0;
          }
        }
              
      return {
        isValid: isValid,
        errorMessage: "The Interest Rate does't look right."
      };
    }
  },
  {
    id: "home-finish",
    step: StepsIndexHLA.stepIndexOnlineFinish,
    barTitle: "Your Home Loan Assistant",
    stepTitle: "",
    stepSubTitle: "",
    stepsInstruction: "",
    stepSelectionnUnique: false,
    stepExtraDisabled : true,
    hideNavigationDone: true,
    isFinalStep: true,
    cssClass: "wizard-home-finish",
    stepDefinition: {
      stepInstruction: "Summary",
      btnHLAText: "See my deals now",
      imgUrl: ImagesPath.ImgProduct_HomeLoan_Wizard,
      actionUrl: "",
      trackingInfo: {
        partnerId : GlobalFixedVariables.PARTNER_UNO,
        productTypeId: GlobalFixedVariables.PRODUCTTYPE_HOMELOAN,
        productId: GlobalFixedVariables.PRODUCT_UNO_HLA
      },
      getStepData: null
    }
  },
  {
    id: 'home-broker-buy-what-to-do',
    step: StepsIndexHLA.stepIndexBrokerBuyWhatToDo,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'What do you want to do?',
    stepsInstruction: "Let us know what you're looking for and we'll show you the top deals",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "buyFirstHome", value: 'Buy my first home' },
        { id: 1, key: "buyNextHome", value: 'Buy my next home' },
        { id: 2, key: "buyInvestment", value: 'Buy investment property' }
      ]
    }
  },
  {
    id: 'home-broker-refinance-what-to-do',
    step: StepsIndexHLA.stepIndexBrokerRefinanceWhatToDo,
    barTitle: "Your Home Loan Assistant",
    stepTitle: 'What do you want to do?',
    stepsInstruction: "Let us know what you're looking for and we'll show you the top deals",
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepShouldAlwaysSelect: false,
    hideNavigation: false,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, key: "refinanceLoan", value: 'Refinance existing home loan' },
        { id: 1, key: "refinanceProperty", value: 'Refinance an investment property' }
      ]
    }
  },
  // {
  //   id: 'home-broker-citizen-or-pr',
  //   step: StepsIndexHLA.stepIndexBrokerCitizenOrPR,
  //   barTitle: "",
  //   stepTitle: 'Are you an Australian Citizen or Permanent Resident?',
  //   stepsInstruction: "",
  //   selectedExtraIndex: 5,
  //   stepSelectionnUnique: true,
  //   stepShouldAlwaysSelect: false,
  //   hideNavigation: false,
  //   shouldMoveOnAction: true,
  //   stepDefinition: {
  //     showInCols: 2,
  //     actions: [
  //       { id: 0, key: "yes", value: 'Yes' },
  //       { id: 1, key: "no", value: 'No' }
  //     ]
  //   }
  // },
  {
    id: 'home-broker-finish',
    step: StepsIndexHLA.stepIndexBrokerFinish,
    barTitle: "Your Home Loan Assistant",
    stepTitle: '',
    stepSubTitle: '',
    stepsInstruction: '',
    stepSelectionnUnique: false,
    stepExtraDisabled : true,
    hideNavigationDone: true,
    isFinalStep: false,
    cssClass: "wizard-home-finish",
    stepDefinition: {
      stepInstruction: 'Summary',
      btnHLAText: "Get in touch",
      imgUrl: ImagesPath.ImgProduct_HomeLoan_Wizard,
      actionUrl: '',
      trackingInfo: {
        partnerId : null,
        productTypeId: null,
        productId: null
      },
      getStepData: null
    }
  }
];

export const HlaExpertConfiguration = {
  getStepData: (currentStepState, stepStates, stepDefs) => {

    const refinanceOrBuyActionIndex = stepStates[StepsIndexHLA.stepIndexRefinanceOrBuy].selectedItems.indexOf(true);
    const isRefinance = refinanceOrBuyActionIndex === 0;
    const isBuy = refinanceOrBuyActionIndex === 1;
    const isRefinanceWithEquity = refinanceOrBuyActionIndex === 2;

      // citizen
      // let isCitizenOrPR = null;
      // let  citizenSelectedIndex = stepStates[StepsIndexHLA.stepIndexBrokerCitizenOrPR].selectedItems.indexOf(true);
      // if(citizenSelectedIndex >= 0){
      //     isCitizenOrPR = stepDefs[StepsIndexHLA.stepIndexBrokerCitizenOrPR].stepDefinition.actions[citizenSelectedIndex].value;
      // }

      //Intention
      let intention = null;
      let intentionSelectedIndex = null;
      if (isRefinance || isRefinanceWithEquity) {
       intentionSelectedIndex = stepStates[StepsIndexHLA.stepIndexBrokerRefinanceWhatToDo].selectedItems.indexOf(true);
        if(intentionSelectedIndex >= 0){
         intention = stepDefs[StepsIndexHLA.stepIndexBrokerRefinanceWhatToDo].stepDefinition.actions[intentionSelectedIndex].value;
        }
      } else if (isBuy) {
        intentionSelectedIndex = stepStates[StepsIndexHLA.stepIndexBrokerBuyWhatToDo].selectedItems.indexOf(true);
         if(intentionSelectedIndex >= 0){
          intention = stepDefs[StepsIndexHLA.stepIndexBrokerBuyWhatToDo].stepDefinition.actions[intentionSelectedIndex].value;
        }
      }

    const summaryList = [
      {"key": "Intention", "value": intention }
      //{"key": "Citizen / PR", "value": isCitizenOrPR }
    ];

    return {
      summaryList: summaryList,
      partnerConfig: ratecityPartnerConfig
    };
  }
};

export const HlaUnoConfiguration = {
  getStepData: (currentStepState, stepStates, stepDefs) => {
    const refinanceOrBuyActionIndex = stepStates[StepsIndexHLA.stepIndexRefinanceOrBuy].selectedItems.indexOf(true);
    const isRefinance = refinanceOrBuyActionIndex === 0;
    const isBuy = refinanceOrBuyActionIndex === 1;
    const isRefinanceWithEquity = refinanceOrBuyActionIndex === 2;
    let indexes = {};
    let loanAmount = 0;

    if (isRefinance || isRefinanceWithEquity) {
      indexes = {
        refinanceOrBuy: StepsIndexHLA.stepIndexRefinanceOrBuy,
        propertyValue: StepsIndexHLA.stepIndexRefinancePropertyWorth,
        oweOrDepositValue: StepsIndexHLA.stepIndexRefinanceOwe,
        houseStatus: StepsIndexHLA.stepIndexRefinanceLiveOrInvest,
        payoff: StepsIndexHLA.stepIndexRefinanceHowToPay,
        combinedIncome: StepsIndexHLA.stepIndexRefinanceIncome
      };

      loanAmount = +stepStates[indexes.oweOrDepositValue].selectedTexts[0];

    } else if (isBuy) {
      indexes = {
        refinanceOrBuy: StepsIndexHLA.stepIndexRefinanceOrBuy,
        propertyValue: StepsIndexHLA.stepIndexBuyPurchasePrice,
        oweOrDepositValue: StepsIndexHLA.stepIndexBuyDeposit,
        houseStatus: StepsIndexHLA.stepIndexBuyLiveOrInvest,
        payoff: StepsIndexHLA.stepIndexBuyHowToPay,
        combinedIncome: StepsIndexHLA.stepIndexBuyIncome
      };

      loanAmount = (+stepStates[indexes.propertyValue].selectedTexts[0]) - (+stepStates[indexes.oweOrDepositValue].selectedTexts[0]);
    }

    const houseStatusActionIndex = stepStates[indexes.houseStatus].selectedItems.indexOf(true);
    const payoffActionIndex = stepStates[indexes.payoff].selectedItems.indexOf(true);

    const isHouseStatusSelected = houseStatusActionIndex > -1;
    const isPayoffSelected = payoffActionIndex > -1;

    const propertyWorth = stepStates[indexes.propertyValue].selectedTexts[0];
    const income = stepStates[indexes.combinedIncome].selectedTexts[0];
    const incomePeriod = stepStates[indexes.combinedIncome].selectedTexts[1];
    
    let oweOrDeposit = Number(stepStates[indexes.oweOrDepositValue].selectedTexts[0]);

    if (isRefinance || isRefinanceWithEquity){
      if(isRefinanceWithEquity && stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].enabled && stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].selectedTexts.length > 0){
        oweOrDeposit += Number(stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].selectedTexts[0]);
      }

    }

    const summaryList = [
      {"key": "You want to", "value": stepDefs[indexes.refinanceOrBuy].stepDefinition.actions[refinanceOrBuyActionIndex].shortDisplay},
      {"key": "Total property value", "value": HelperApi.toCurrencyFormat(propertyWorth)},
      {"key": (isRefinance || isRefinanceWithEquity) ? "Total Loan" : "Deposit",  "value": HelperApi.toCurrencyFormat(oweOrDeposit)},
      {"key": "Status", "value": isHouseStatusSelected? stepDefs[indexes.houseStatus].stepDefinition.actions[houseStatusActionIndex].value : ""},
      {"key": "Repayments", "value": isPayoffSelected ? stepDefs[indexes.payoff].stepDefinition.actions[payoffActionIndex].value : ""},
      {"key": "Income", "value": HelperApi.toCurrencyFormat(income) + " " + incomePeriod },
    ];

    // ---- errors --------------------
    const errorList = [];
    let rules = [];
    let selectedValues = {
      loanAmount: 0,
      propertyWorth: 0,
      deposit: 0,
      income: 0,
      incomePeriod: 'yearly'
    };

    selectedValues = {
      loanAmount: loanAmount,
      propertyWorth: propertyWorth,
      deposit: oweOrDeposit,
      income: income,
      incomePeriod: incomePeriod.toLowerCase()
    };

    if (isRefinance || isRefinanceWithEquity) {
      rules = UnoRefinanceRules;
    } else if (isBuy) {
     rules = UnoBuyRules;
    }

    rules.forEach((rule) => {
      let ruleResult = rule(selectedValues);
      if (!ruleResult.isValid) errorList.push(ruleResult.error);
    });

    return {
      summaryList: summaryList,
      errorList: errorList,
      partnerConfig: unoPartnerConfig
    };
  }

};

const UnoRefinanceRules = [
  loanAmountValidation,
  refinanceToValueValidation,
  generalLoanToValueValidation,
  incomeToLoanValidation
];

const UnoBuyRules = [
  loanAmountValidation,
  loanToValueValidation,
  generalLoanToValueValidation,
  incomeToLoanValidation
];

const unoPartnerConfig = {
  logo: ImagesPath.ImgLogoUnoWin,
  matchedPartnerText: "Home Loan Partner",
  logoCss: "uno-logo-css",
  brandTagLine: "",
  disclaimer: `By continuing, I agree to uno's <a class="wizard-partner-disclaimer-link" href="https://unohomeloans.com.au/privacy-policy">Privacy Policy</a>
                (including <a class="wizard-partner-disclaimer-link" href="https://unohomeloans.com.au/privacy-policy#econsent">e-consent</a>)
                and <a class="wizard-partner-disclaimer-link" href="https://unohomeloans.com.au/terms-and-conditions/">Website Terms & Conditions</a>.
                The referrer may receive a fee, including commission, for referring you to us.`,
  features: [
    { key:"1", title : "See your matched deals", classToAppend: "" },
    { key:"2", title : "Free consultation by phone 7 days a week", classToAppend: ""},
    { key:"3", title : "Option to apply online with support from experts", classToAppend: ""},
  ]
};


export const HlaFirstMacConfiguration = {
  getStepData: (currentStepState, stepStates, stepDefs) => {
    const refinanceOrBuyActionIndex = stepStates[StepsIndexHLA.stepIndexRefinanceOrBuy].selectedItems.indexOf(true);
    const isRefinance = refinanceOrBuyActionIndex === 0;
    const isBuy = refinanceOrBuyActionIndex === 1;
    const isRefinanceWithEquity = refinanceOrBuyActionIndex === 2;
    let indexes = {};
    let loanAmount = 0;

    if (isRefinance || isRefinanceWithEquity) {
      indexes = {
        refinanceOrBuy: StepsIndexHLA.stepIndexRefinanceOrBuy,
        propertyValue: StepsIndexHLA.stepIndexRefinancePropertyWorth,
        oweOrDepositValue: StepsIndexHLA.stepIndexRefinanceOwe,
        houseStatus: StepsIndexHLA.stepIndexRefinanceLiveOrInvest,
        payoff: StepsIndexHLA.stepIndexRefinanceHowToPay,
        combinedIncome: StepsIndexHLA.stepIndexRefinanceIncome
      };

      loanAmount = +stepStates[indexes.oweOrDepositValue].selectedTexts[0];

    } else if (isBuy) {
      indexes = {
        refinanceOrBuy: StepsIndexHLA.stepIndexRefinanceOrBuy,
        propertyValue: StepsIndexHLA.stepIndexBuyPurchasePrice,
        oweOrDepositValue: StepsIndexHLA.stepIndexBuyDeposit,
        houseStatus: StepsIndexHLA.stepIndexBuyLiveOrInvest,
        payoff: StepsIndexHLA.stepIndexBuyHowToPay,
        combinedIncome: StepsIndexHLA.stepIndexBuyIncome
      };

      loanAmount = (+stepStates[indexes.propertyValue].selectedTexts[0]) - (+stepStates[indexes.oweOrDepositValue].selectedTexts[0]);
    }

    const houseStatusActionIndex = stepStates[indexes.houseStatus].selectedItems.indexOf(true);
    const payoffActionIndex = stepStates[indexes.payoff].selectedItems.indexOf(true);

    const isHouseStatusSelected = houseStatusActionIndex > -1;
    const isPayoffSelected = payoffActionIndex > -1;

    const propertyWorth = stepStates[indexes.propertyValue].selectedTexts[0];
    const income = stepStates[indexes.combinedIncome].selectedTexts[0];
    const incomePeriod = stepStates[indexes.combinedIncome].selectedTexts[1];
    let oweOrDeposit = Number(stepStates[indexes.oweOrDepositValue].selectedTexts[0]);

    if (isRefinance || isRefinanceWithEquity){
      if(isRefinanceWithEquity && stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].enabled && stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].selectedTexts.length > 0){
        oweOrDeposit += Number(stepStates[StepsIndexHLA.stepIndexRefinanceAddFunds].selectedTexts[0]);
      }

    }

    const summaryList = [
      {"key": "You want to", "value": stepDefs[indexes.refinanceOrBuy].stepDefinition.actions[refinanceOrBuyActionIndex].shortDisplay},
      {"key": "Total property value", "value": HelperApi.toCurrencyFormat(propertyWorth)},
      {"key": (isRefinance || isRefinanceWithEquity) ? "Total Loan" : "Deposit",  "value": HelperApi.toCurrencyFormat(oweOrDeposit)},
      {"key": "Status", "value": isHouseStatusSelected? stepDefs[indexes.houseStatus].stepDefinition.actions[houseStatusActionIndex].value : ""},
      {"key": "Repayments", "value": isPayoffSelected ? stepDefs[indexes.payoff].stepDefinition.actions[payoffActionIndex].value : ""},
      {"key": "Income", "value": HelperApi.toCurrencyFormat(income) + " " + incomePeriod },
    ];

    // ---- errors --------------------
    const errorList = [];
    let rules = [];
    let selectedValues = {
      loanAmount: 0,
      propertyWorth: 0,
      deposit: 0,
      income: 0,
      incomePeriod: 'yearly'
    };

    selectedValues = {
      loanAmount: loanAmount,
      propertyWorth: propertyWorth,
      deposit: oweOrDeposit,
      income: income,
      incomePeriod: incomePeriod.toLowerCase()
    };

    if (isRefinance || isRefinanceWithEquity) {
      rules = UnoRefinanceRules;
    } else if (isBuy) {
     rules = UnoBuyRules;
    }

    rules.forEach((rule) => {
      let ruleResult = rule(selectedValues);
      if (!ruleResult.isValid) errorList.push(ruleResult.error);
    });

    return {
      summaryList: summaryList,
      errorList: errorList,
      partnerConfig: firstMacPartnerConfig
    };
  }

};

const firstMacPartnerConfig = {
  logo: ImagesPath.ImgLogoFirstMacHLA,
  matchedPartnerText: "Home Loan Partner",
  txtButton: "Speak to a Lending Specialists",
  logoCss: "firstmac-logo-css",
  brandTagLine: "",
  disclaimer: `By continuing, I agree to loans.com.au <a class="wizard-partner-disclaimer-link" href="https://www.loans.com.au/privacy-policy">Privacy Policy</a>
               and <a class="wizard-partner-disclaimer-link" href="https://www.loans.com.au/conditions-and-privacy">Website Terms & Conditions</a>`,
  features: [
    { key:"1", title : "FirstMac", classToAppend: "" }
  ]
};

const ratecityPartnerConfig = {
  logo: ImagesPath.ImgProduct_HomeLoan_Wizard,
  matchedPartnerText: "Home Loan Partner",
  txtButton: "See my deals now",
  logoCss: "rc-logo-css",
  brandTagLine: "",
  disclaimer: `By continuing, I agree to RateCity's <a class="wizard-partner-disclaimer-link" href="https://www.ratecity.com.au/privacy-policy">Privacy Policy</a>
              (including <a class="wizard-partner-disclaimer-link" href="https://www.ratecity.com.au/disclaimer">Disclaimer</a>)
              and <a class="wizard-partner-disclaimer-link" href="https://www.ratecity.com.au/terms-of-use">Terms of Use</a>.
              The referrer may receive a fee, including commission, for referring you to us.`,
  features: [
    { key:"1", title : "Speak to an expert", classToAppend: "" },
    { key:"2", title : "Free consultation by phone 7 days a week", classToAppend: ""}
  ]
};

function loanAmountValidation(selectedValues) {
  const minLoanAmount = 100000;
  const loanAmount = selectedValues.loanAmount;
  const error = {
    message: "Loan amount is less than $100,000. if correct, please proceed.",
    description: "Your loan amount of " + HelperApi.toCurrencyFormat(loanAmount) +
     " is less than minimum loan amount of $100,000. If correct you may proceed however this may restrict which lenders we can refer your to."
  };

  return {
    isValid: loanAmount >= minLoanAmount,
    error
  };
}

function loanToValueValidation(selectedValues) {
  const maxLoanToValueRatio = 0.9;
  const loanAmount = selectedValues.loanAmount;
  const propertyWorth = selectedValues.propertyWorth;

  const loanToValueRatio = loanAmount / propertyWorth;
  const minDeposit = propertyWorth * (1 - maxLoanToValueRatio);

  const propertyWorthFormatted = HelperApi.toCurrencyFormat(propertyWorth);
  const depositFormatted = HelperApi.toCurrencyFormat(selectedValues.deposit);
  const minDepositFormatted = HelperApi.toCurrencyFormat(minDeposit);
  const error = {
    message: "Please note - Your Loan-to-Value ratio (LVR) is greater than the maximum of " + maxLoanToValueRatio * 100 + "%",
    description: "Please double check your deposit amount & loan amount. A high LVR may restrict which lenders we can refer your to." +
    " With a deposit of " + depositFormatted +
    " you will be borrowing " + loanToValueRatio * 100 + "%" +
    " of the property price (" + propertyWorthFormatted + ")." +
    " This is not enough to get a home loan." +
    " Many lenders will prefer at least  " + minDepositFormatted + " deposit."
  };

  return {
    isValid: loanToValueRatio <= maxLoanToValueRatio,
    error
  };
}

function refinanceToValueValidation(selectedValues) {
  const maxRefinanceToValueRatio = 0.8;
  const loanAmount = selectedValues.loanAmount;
  const propertyWorth = selectedValues.propertyWorth;

  const loanToValueRatio = loanAmount / propertyWorth;

  const loanAmountFormatted = HelperApi.toCurrencyFormat(loanAmount);
  const propertyWorthFormatted = HelperApi.toCurrencyFormat(propertyWorth);
  const error = {
    message: "Please note your requested refinance amount would take your Loan-to-Value Ratio (LVR) to above " + maxRefinanceToValueRatio * 100 + "%",
    description: "With a loan amount of " + loanAmountFormatted +
    " you will be refinancing " + loanToValueRatio * 100 + "%" +
    " of the property price (" + propertyWorthFormatted + ")." +
    " Please double check your deposit & purchase price information. A home loan based on this current loan to valuation ratio (LVR) may be more difficult to obtain."
  };

  return {
    isValid: loanToValueRatio <= maxRefinanceToValueRatio,
    error
  };
}

function generalLoanToValueValidation(selectedValues) {
  let isValid = true;
  const error = { message: "", description: "" };
  const loanAmount = selectedValues.loanAmount;
  const propertyWorth = selectedValues.propertyWorth;

  if (loanAmount > propertyWorth) {
    isValid = false;
    error.message = "Please note your requested loan amount is greater than your property value";
    error.description= "Your loan amount of " + HelperApi.toCurrencyFormat(loanAmount) +
    " should exceed property value (" + HelperApi.toCurrencyFormat(propertyWorth) + ").";
  }

  return {
    isValid,
    error
  };
}

function incomeToLoanValidation(selectedValues) {
  const minIncomeToLoanRatio = (1 / 7);
  const incomePeriods = {
    yearly: 1,
    monthly: 12,
    fortnightly: 26,
    weekly: 52
  };
  const loanAmount = selectedValues.loanAmount;
  const income = selectedValues.income;
  const incomePeriod = selectedValues.incomePeriod;

  const annualIncome = income * incomePeriods[incomePeriod];
  const incomeToLoanRatio = (annualIncome / loanAmount);

  const error = {
    message: "Please note your combined income amount when compared to your requested loan amount may be lower than standard approval ratio's - pleasse double check your entries.",
    description: "Your combined income (" + HelperApi.toCurrencyFormat(annualIncome) +
    ") maybe below standard approval ratios when compared to your loan amount of " + HelperApi.toCurrencyFormat(loanAmount) + ". Please double check these numbers."
  };

  return {
    isValid: incomeToLoanRatio >= minIncomeToLoanRatio,
    error
  };
}
