import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as RoutesVariables from '../../config/routesVariables';
import * as ImagesPath from '../../config/imagesPath';
import { Link } from 'react-router-dom';

import {tonikInitMenu} from '../../tonik/menu';

const MenuMobile = ({ menuItemList, user, renderMobile = false}) => {

    tonikInitMenu();

    let clickButtonMobile = ()=> {
        tonikInitMenu();
    }

    var mobileMenuContent = (
        <>
          {user && user.displayName &&  (  
            <>
              <div className="menu-mobile-content">
                <h1>GetCreditScore</h1>
                <div className="menu menu-first">
                  <nav>
                    <ul>
                      {
                      menuItemList && menuItemList.map((item, i) => {
                        if(!item.id.includes('_d')) {

                          if(item.id == "my-account"){
                              var myAccountList = item.subMenuList.map( (subItem, isub) => {
                                  return <li key={isub}><a href={subItem.link ? subItem.link : subItem.ref}>{subItem.name}</a></li> ;
                              });
                              return <>{myAccountList}</>;

                          } else if(item.id != "login"){
                            return(<li key={i}><a href={item.link ? item.link : item.ref}>{item.name}</a></li>);
                          }
                        }
                      })
                      }
                    </ul>
                  </nav>
                </div>
                <div className="admin">
                  <p>{user.displayName}</p>
                  <a href={'/logout'} className="login">Log out</a>
                </div>
              </div>
            </>
          )}

          {!user || !user.displayName &&   (  
            <>
            <h1>GetCreditScore</h1>
            <nav>
              <ul>
                  {
                    menuItemList && menuItemList.map((item,i) => {
                      if(!item.id.includes('_d')) {
                        if(item.id != "login"){
                          return(<li key={i}><a href={item.link ? item.link : item.ref}>{item.name}</a></li>);
                        }
                      }
                    })
                  }
              </ul>
            </nav>
            <a href={RoutesVariables.RouteLogin} className="btn btn-primary">Log in</a>
            </>
          )}  

          <div className="footer-menu">
            <ul>
              <li><a href={RoutesVariables.RouteExternalTermsOfUse}>Terms and Conditions</a></li>
              <li><a href={RoutesVariables.RouteExternalPrivacyPolicy}>Privacy Policy</a></li>
            </ul>
          </div>
        </>
      );

   
    ReactDOM.render(mobileMenuContent, document.getElementById('menu-mobile'))

    if(renderMobile && document.getElementById('dashboard-menu')) {
        var mobileDashboardContent = (
          <div className="menu-mobile-dashboard">
            <div className="row">
              <div className="col-md-2 col-3">
                <button type="button" id="button-mobile" onClick={clickButtonMobile}><img src={ImagesPath.ImgIconMobileWhite} className="img-fluid" alt=""/></button>
              </div>
              <div className="col-md-8 col-6 logo">
                <img src={ImagesPath.ImgLogoWhite} className="img-fluid" alt=""/>
              </div>
              <div className="col-md-2 col-3 d-none">
                <div className="dropdown dropdown-header">
                  <button className="btn btn-header dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i><img src={ImagesPath.ImgIconBell} alt="" className="img-fluid"/><span className="number">2</span></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        ReactDOM.render(mobileDashboardContent, document.getElementById('dashboard-menu'))
    }

    return null;

};

MenuMobile.propTypes = {
    menuItemList: PropTypes.array,
    user: PropTypes.object,

};


export default MenuMobile;