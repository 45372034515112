import React from 'react';
import PropTypes from 'prop-types';

export const ArrowDown = ({href="#", onClick, cssClass=""}) => {

return (
  <p className={"arrow-down " + cssClass}>
        <a href={href} className="mouse" onClick={onClick}>
            <i className="fa fa-angle-down Drop animated"></i>
        </a>
  </p>
);

}
