import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';
import { Link } from 'react-router-dom';
import * as RoutesVariables from '../../../config/routesVariables';

const AboutCrModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">            
              <h3>About Your Credit Report Overview</h3>             
              <p>A credit report is a record of your credit history. It may include information on applications for credit that you have made in the past; repayment history information (including any associated financial hardship arrangements) on credit cards, personal loans and mortgages, as well as other publicly available information such as court writs and judgements relating to credit and bankruptcies.</p>              
              <p>Credit reports are available to all consumers that have a credit history and are built using information provided by applicable credit providers and public record information.</p>
              <p>Your Credit Report Overview is strictly an overview only and does not provide all the available information that you will find on your comprehensive Equifax credit report that you can request
                by clicking <a target="_blank" className="cr-modal-link" href="https://www.equifax.com.au/personal/products/credit-and-identity-products">here</a>.
              </p> 
              <h4><span>1</span> How do I get my Credit Report Overview?</h4>
              <p>Once verified, you can access your Credit Report Overview by logging in to <Link className="cr-modal-link" to={RoutesVariables.RouteHome}>GetCreditScore</Link> 
                 and clicking on the Credit Report Overview tab at the top of the screen.</p>
              
              <h4><span>2</span> Does it cost me anything to get my Credit Report Overview?</h4>
              <p>It doesn’t cost you anything to get your Credit Report Overview.</p>
              
              <h4><span>3</span> Why is my Credit Report Overview different to my credit report?</h4>
              <p>The Credit Report Overview on GetCreditScore is an overview of the information available on your full credit report. This new feature will help you understand the key factors that can have the biggest impact on the outcome of your score. If you’d also like access to your full credit report, you can request it <a target="_blank" className="cr-modal-link" href="https://www.equifax.com.au/personal/products/credit-and-identity-products">here</a>.</p>
              
              <h4><span>4</span> How often will my Credit Report Overview information be updated?</h4>
              <p>We’ll email you updates of your Credit Report Overview each month, and if there has not been any activity on your report in the past month, you will not see any changes made to your report.</p>
              <p>Your personal information reflected on your report will not always include the most up to date information as this information is only submitted in conjunction with past credit reporting activities from credit providers, utility, and telco companies. For example, your current address recorded on your file will be your address at the time you last made a request for a credit application. </p>

              <h4><span>5</span> Will accessing my Credit Report Overview via GetCreditScore impact my credit profile?</h4>
              <p>No, GetCreditScore is an Access Seeker who is assisting you in obtaining your credit information from the credit bureau, Equifax, and therefore does not negatively impact your credit profile regardless of how frequently you access this information.</p>

              <h4><span>6</span> Why aren’t all my credit accounts appearing on my credit report?</h4>
              <p>Reporting on Account Information is part of Comprehensive Credit Reporting (CCR) which was introduced in 2017.  For your accounts to show on your report, your financial institution must be participating in CCR.</p>
              <p>It can also take a while for new credit accounts to appear on your credit report, as credit providers go through different reporting processes to update a credit reporting bureau. Furthermore, credit providers may not submit information to every credit reporting bureau so there may be instances where it is visible on some credit reports and not others.</p>
                           
              <h4><span>7</span> How long does information stay on my credit report?</h4>
              <p>Personal identity information (PII) including your name, date of birth, gender, driver’s licence, and address history is held for the life of the report unless updated by information supplied from recent credit activity. For other information it depends on the type of data, but this may be up to: </p>
              <h6>Two years</h6>
              <ul>
                <li>Repayment history information</li>                
              </ul>
              <h6>Five years</h6>
              <ul>
                <li>Any credit enquiry</li>                
                <li>Overdue accounts listed as a payment default</li>                
                <li>Overdue accounts listed as clearouts</li>                
                <li>Writs and summons</li>                
                <li>Court judgments</li>                
              </ul>
              <h6>Seven years</h6>
              <ul>
                <li>Overdue accounts listed as serious credit infringements</li>                
              </ul>
              <h6>Twelve months</h6>
              <ul>
                <li>Financial Hardship arrangements</li>                
              </ul>
              <p>Information about credit accounts that you have such as mortgages and credit cards will appear on your credit report for the length of the loan or credit facility. It will also be held for an additional two years after the account has been closed.</p>                                                    
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default AboutCrModal;