/// <reference path="../../api/mockProductApi.js" />

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';
import * as constants from '../shared/constants';

import * as ImagesPath from '../../config/imagesPath';
import Menu from  '../menu/Menu';

import ProductSection from './../sections/ProductSection';

import * as productActions from '../../redux/actions/productActions';
import * as layoutActions from '../../redux/actions/layoutActions';

import GoToPreviousPage from '../common/controls/GoToPreviousPage';


//
// import ProductApi from '../../api/mockProductApi.js'
// import LayoutApi from '../../api/mockLayoutApi.js'
// import ArticlesApi from '../../api/mockArticlesApi.js'


const InsuranceTitleComponent = ({textVariants}) => {


  let text = textVariants[pages.DEALS_INSURANCE + textVariants.textVariantSeparator + "product_header_d"];
  return (  <div className="row product-section-title" >
        <div className="col-xs-12">
          <div className="text-center sm-mb-20">
            <h2 className="product-section-title-h2">
              <span dangerouslySetInnerHTML={funcs.createMarkup(text)} >
              </span>
              <img src={ImagesPath.ImgLogoBudgetDirect} alt="" width="160px" className="xs-mt-20 sm-ml-20" />
            </h2>
           </div>
        </div>
     </div>);

}

export class InsurancePage  extends React.Component{

    constructor(props, context){
        super(props, context);

        this.onProductClick = this.onProductClick.bind(this);
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
    }

    componentDidMount() {
      this.props.actions.getMenuForPage(pages.DEALS_INSURANCE);
      this.props.actions.loadProducts();
      this.props.actions.getTextVariants();
    }

    componentWillUnmount(){
    }

    onProductClick(event, product){

        this.props.actions.trackGcsProductClick(product, pages.DEALS_INSURANCE);
    }

    render(){        

        return (

              <div className="insurancePage">
                  <Menu menuItemList={this.props.menuItemList} />

                  <GoToPreviousPage
                    textVariants={this.props.textVariants}
                    containerCls='left-container-padding right-container-padding' />

                  <ProductSection
                     key="products"
                     loading={this.props.productLoading}
                     products={this.props.products}
                     textVariants={this.props.textVariants}
                     onProductClick={this.onProductClick}
                     displayMobileOption={constants.PRODUCT_DISPLAY_MOBILE_BULLETS}
                     title={this.props.textVariants[pages.DEALS_INSURANCE + this.props.textVariants.textVariantSeparator + "product_header"]}
                     disclaimer={this.props.textVariants[pages.DEALS_INSURANCE + this.props.textVariants.textVariantSeparator + "product_disclaimer"]}
                   >
                     <InsuranceTitleComponent isTitle={true} textVariants={this.props.textVariants} />
                   </ProductSection>
              </div>
        )
        }

    };

InsurancePage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.DEALS_INSURANCE]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.DEALS_INSURANCE];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

   let products = [];
   let productLoading = null;
   if(state.productResult && state.productResult.products){

     productLoading = state.productResult.loading;

      if(!productLoading){
        let tmpProducts = state.productResult.products;
        let insuranceProduct = null;
        for(var i = 0; i < tmpProducts.length && insuranceProduct == null; i++){
          if(tmpProducts[i]["id"] == "insurance"){
            insuranceProduct = tmpProducts[i];
          }
        }

        if(insuranceProduct){
          products = [...insuranceProduct.subProducts];
        }
      }
   }

   let isAnonymous = true;
   if(state.productResult){
     isAnonymous = state.productResult.isAnonymous;
   }

  return {
    products : products,
    productLoading: productLoading,
    isAnonymous: isAnonymous,
    menuItemList: menuItems,
    textVariants: txtVariants
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, layoutActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePage);
