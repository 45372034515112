import React from 'react';
import {useRef} from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as scoreActions from '../../redux/actions/scoreActions';
import * as creditSummaryActions from '../../redux/actions/creditSummaryActions';

import RegistrationForm from '../registration/RegistrationForm';
import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import CurrentAddressForm from '../mydetails/CurrentAddressForm';
import TypeIdForm from '../mydetails/TypeIdForm';

import { ChangePasswordPage } from '../login/ChangePasswordPage.js';

import * as RoutesVariables from '../../config/routesVariables';
import RecentActivitiesDemo from './RecentActivitiesDemo.js';
import AccountsDemo from './AccountsDemo.js';
import CreditEnquiriesDemo from './CreditEnquiriesDemo.js';
import AdverseInformationDemo from './AdverseInformationDemo.js';

import {tonikInitReport} from '../../tonik/report';


class CreditSummaryDemoPage extends React.Component { 
    constructor(props, context) {
        super(props, context);
        const params = new URLSearchParams(props.location.search);
        this.state = {
            customer:  {  dob: { dd: '', mm: '', yy: '' } , address:'', addressPrevious: '', hasLivedLessThan3Years: false },
            errors: {},
            saving: false,
            diplaySavedMessage: false,
            isServerError: false,
            omitFields: {
              email: true,
              password: true
            },
            tabIndex: params.get('step') ? params.get('step') : 1,
        };


        this.onTurnYourScore = this.onTurnYourScore.bind(this);
        this.updateCustomerState = this.updateCustomerState.bind(this);
        this.updateCustomerServiceAddressError = this.updateCustomerServiceAddressError.bind(this);
        this.saveCustomerFromState = this.saveCustomerFromState.bind(this);
        this.onCancel = this.onCancel.bind(this);
      
    }

    componentDidMount() {

      //window['initAppJs'](true);
      
      this.props.actions.getTextVariants();
      this.props.actions.getCreditSummary();

      this.props.actions.isAuthenticated().then(e =>
      {
              this.props.actions.getMenuForPage(pages.MYDETAILS);
              this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
              this.props.actions.loadUserDetails(true).catch(e =>
              {
                 if (e.status == 500) {
                    this.setState({ isServerError: true });
                 }
              });
      });

      if(this.state.tabIndex != 1) {
        var lis = this.tabs.children;
        for(var i =0; i< lis.length; i++){
          lis[i].className = '';
        }
        lis[this.state.tabIndex-1].className = 'current'
      }

      tonikInitReport();
    }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.isAuthenticated) {
          // go back to login
          this.props.history.push('login/my-details');
      }
      else {
        if(nextProps.userProfile.userDetails) {
             let newCustomer = Object.assign({},  nextProps.userProfile.userDetails);
              this.setState({customer: newCustomer});
        }
      }
    }

    changeTab(e, index) {
      e.preventDefault();
      e.stopPropagation();
      var lis = e.currentTarget.parentNode.children;
      for(var i =0; i< lis.length; i++){
        lis[i].className = '';
      }
      e.currentTarget.className = 'current'
      this.setState({tabIndex: index})
    }

    render() {
      let pageContent = null;

      if (this.state.isServerError) {
        pageContent = <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_RetrievingProfile_Error"]} />
      }
      else if(!this.props.userProfile.userDetails){
        pageContent =
                <LoadingIndicator
                  textPosition='bottom'
                  text={this.props.textVariants[pages.MYDETAILS + this.props.textVariants.textVariantSeparator + "loading_text"]}
                  size='200' />;
      }
      else {
        pageContent = <div className='col-lg-6 col-md-8 col-12 accordion-column'>

              { this.state.tabIndex && this.state.tabIndex == 1 && <RecentActivitiesDemo
                textVariants={this.props.textVariants}
                account={this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['accounts']['account'].length > 0 ? this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['accounts']['account'][0] : null}
                enquire={this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['credit-enquiries']['credit-enquiry'].length > 0 ? this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['credit-enquiries']['credit-enquiry'][0] : null}
                advers={this.props.creditSummary['product-data']['enquiry-report']['public-record-or-consumer-credit-information']['court-actions']['court-action'].length > 0 ? this.props.creditSummary['product-data']['enquiry-report']['public-record-or-consumer-credit-information']['court-actions']['court-action'][0] : null}
              />}

              { this.state.tabIndex && this.state.tabIndex == 2 && <AccountsDemo
                textVariants={this.props.textVariants}
                data={this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['accounts']}
              />}

              { this.state.tabIndex && this.state.tabIndex == 3 && <CreditEnquiriesDemo
                textVariants={this.props.textVariants}
                data={this.props.creditSummary['product-data']['enquiry-report']['consumer-credit-file']['credit-enquiries']}
              />}

              { this.state.tabIndex && this.state.tabIndex == 4 &&<AdverseInformationDemo
                textVariants={this.props.textVariants} 
                data={this.props.creditSummary['product-data']['enquiry-report']['public-record-or-consumer-credit-information']['court-actions']}
              />}
              
            </div>;
      }

        return (
          <span>
            <span id="dashboard-menu"></span>
          <div className="page-bg page-recent">
          
             <Menu menuItemList={this.props.menuItemList}  user={this.props.userProfile} renderMobile={true}/>

             <div className="nav-aside">
              <ul>
                <li className="current" onClick={(e)=>this.changeTab(e, 1)}><a href="#">Recent Activities</a></li>
                <li onClick={(e)=>this.changeTab(e, 2)}><a href="#">Accounts</a></li>
                <li onClick={(e)=>this.changeTab(e, 3)}><a href="#">Credit Enquiries</a></li>
                <li onClick={(e)=>this.changeTab(e, 4)}><a href="#">Adverse Information</a></li>
                <li className="button"><button onClick={(e)=>{this.onTurnYourScore(e)}} className="btn btn-primary">Resolve an issue on your credit report</button></li>
              </ul>
            </div>

             <section className="section-recent">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1>Credit Report Overview</h1>
                  </div>
                </div>
              </div>
              </section>
                
          </div>
          <section className="page-recent-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-1 col-md-4 column-nav d-none d-md-block">
                <aside className="nav-aside">
                  <ul id="tabs" ref={this.tabs}>
                    <li className="current" onClick={(e)=>this.changeTab(e, 1)}><a href="#">Recent Activities</a></li>
                    <li onClick={(e)=>this.changeTab(e, 2)}><a href="#">Accounts</a></li>
                    <li onClick={(e)=>this.changeTab(e, 3)}><a href="#">Credit Enquiries</a></li>
                    <li onClick={(e)=>this.changeTab(e, 4)}><a href="#">Adverse Information</a></li>
                    <li><button onClick={(e)=>{this.onTurnYourScore(e)}} type="button" className="btn btn-primary full-width">Turn your score into great deal!</button></li>
                  </ul>
                </aside>
              </div>

              {pageContent}
            </div>
          </div>
          </section>
          </span>
        );
    }

    onTurnYourScore(event) {
      event.preventDefault();
      
    }

    saveCustomerFromState(customer){
      var updatingCustomer = Object.assign({}, customer);
      //dob: { 'ddofbirth': '', 'mmofbirth': '', 'yyyyofbirth': '' }

      HelperApi.setInnerDateOfBirthAndDobFromNumbers(updatingCustomer, customer.dob.yy, customer.dob.mm, customer.dob.dd);


    //  updatingCustomer.dateOfBirth.setTime( updatingCustomer.dateOfBirth.getTime() + updatingCustomer.dateOfBirth.getTimezoneOffset()*60*1000 );
      updatingCustomer.email = this.props.userProfile.username; // || this.props.userProfile.rememberMeInfo.username;
      updatingCustomer.gender = customer.gender;

      return this.props.actions.updateUserDetails(updatingCustomer);
    }

    updateCustomerState(event) {

        const field = event.target.name;

        let customer = this.state.customer;
        customer[field] = event.target.value;

        let errors = this.state.errors;
        errors[field] = field== 'dob' ? {} : '';

        return this.setState({ customer: customer, errors:errors });
    }

    updateCustomerServiceAddressError(event){
      const field = event.target.name;

      let errors = this.state.errors;

      if (event.target.status && event.target.status == 401) {
        
      }

      errors[field] = this.props.textVariants["Error_cant_find_address"];

      return this.setState({ errors:errors });
    }

    onCancel(event){
      event.preventDefault();
      const browserHistory = createBrowserHistory();
      browserHistory.goBack();
    }

}

CreditSummaryDemoPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.MYDETAILS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.MYDETAILS];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }



  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    creditSummary: state.creditSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, scoreActions, creditSummaryActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditSummaryDemoPage);
