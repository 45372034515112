import ProductApi from '../../api/mockProductApi';
import PartnerApi from '../../api/partnerApi';
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';
import * as trackingActions from './trackingActions';

import {
products
} from '../../components/shared/staticProducts';

import {
creditCards
} from '../../components/shared/staticProductsCreditCards';

export function loadProductsSuccess(productResult) {
  return {type: types.LOAD_PRODUCTS_SUCCESS, productResult};
}

export function loadingProducts(loading, isFailure) {
  return {type: types.LOADING_PRODUCTS, loading, isFailure};
}

export function forceLoadingProducts() {
  return {type: types.FORCE_LOADING_PRODUCTS};
}

export function loadTrackProductSuccess(trackResult) {
  return {type: types.TRACK_PRODUCTS_SUCCESS, trackResult};
}

export function loadProducts(forced=false, requestProductType) {
  return (dispatch, getState) => {

    var state = getState();

    if(!forced && state.productResult.loaded){
      return Promise.resolve(state.productResult);
    }

    dispatch(loadingProducts(true));

    return ProductApi.getProductTypes(state["userProfile"], state["scoreProfile"], requestProductType, products).then(productResult => {

      dispatch(updateProductMetadata(productResult.userId, productResult.isAnonymous));
      dispatch(loadProductsSuccess(productResult));
      dispatch(loadingProducts(false, false));
      return productResult;
    }).catch(error => {
      dispatch(loadingProducts(false, true));

      throw(error);
    });
  };
}

export function setLastClickedProduct(product){
    return {type: types.SET_LASTCLICKEDPRODUCT, product};
}

export function trackGcsProductClick(product, page, origin) {
  return (dispatch, getState) => {

    var state = getState();    

    var isAnonymous = state.productMetadata.isAnonymous;
    var userId = state.productMetadata.userId;
    if(state.userProfile.identifier){
      userId = state.userProfile.identifier;
    }

    var prodRequest = Object.assign({
      isAnonymous: isAnonymous,
      userId: userId},
      {
        productTypeId: product.productTypeId,
        productId: product.productId,
        partnerId: product.partnerId,
        page: page,
        origin: origin
      }
    );

    dispatch(setLastClickedProduct(product));

    dispatch(trackingActions.trackProductClick(prodRequest));

    return ProductApi.trackProductClick(prodRequest).then(trackResult => {
      dispatch(loadTrackProductSuccess(trackResult));
    }).catch(error => {
      throw(error);
    });
  };
}
//
// export function partnerRefer(url) {
//   return (dispatch, getState) => {
//
//     var state = getState();
//
//     return PartnerApi.partnerRefer(state.userProfile.csrfToken, {url: url}).then( resp =>{
//
//         if(resp.redirect_url){
//           window.location.href = resp.redirect_url;
//         }
//
//     }).catch(error => {
//       throw(error);
//     });
// };
// }



export function loadCreditCardsSuccess(creditcardsResult) {
  return {type: types.LOAD_CREDITCARDS_SUCCESS, creditcardsResult};
}

export function loadingCreditCards(loading, isFailure) {
  return {type: types.LOADING_CREDITCARDS, loading, isFailure};
}

export function forceLoadingCreditCards() {
  return {type: types.FORCE_LOADING_CREDITCARDS};
}

export function loadTrackCreditCardsSuccess(trackResult) {
  return {type: types.TRACK_CREDITCARDS_SUCCESS, trackResult};
}

export function updateProductMetadata(userId, isAnonymous) {
  return {type: types.UPDATE_PRODUCT_METADATA, userId, isAnonymous};
}

export function loadCreditCards(forced=false) {
  return (dispatch, getState) => {

    var state = getState();

    if(!forced && state.creditCardsResult.loaded){
      return null;
    }

    dispatch(loadingCreditCards(true));

    return ProductApi.getProductTypes(state["userProfile"], state["scoreProfile"], { groupId: 1}, creditCards).then(creditcardsResult => {
      dispatch(updateProductMetadata(creditcardsResult.userId, creditcardsResult.isAnonymous));
      dispatch(loadCreditCardsSuccess(creditcardsResult));
      dispatch(loadingCreditCards(false, false));
    }).catch(error => {
        dispatch(loadingCreditCards(false, true));
      throw(error);
    });
  };
}


export function getProductRedirect(product){
    return (dispatch, getState) => {
        var state = getState();

        return ProductApi.getProductRedirect(state.userProfile.csrfToken, product);

    };
}

export function partnerStick(partnerId){
  return (dispatch, getState) => {
      return PartnerApi.partnerStick(partnerId);
  };
}
