import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import ResolveIssueModal from './ResolveIssueModal'
const $ = window.$;


class AdverseInformationDemo extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        modal: false,
      }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState){
  }

  openModal () {
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

render(){


    let {textVariants, data} = this.props;
    return (

      <span>
        <div id="accordion" className="accordion-reports">

        {
            data['court-action'].map( (court, index) => {
              return (
                <div className="card">
                  <div className="card-header" id={"heading-"+ index}>
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse-"+ index} aria-expanded="true" aria-controls={"collapse-"+ index}>
                      {court['creditor']['__text']} <span>Date of enquiry: <span className="date">{court['action-date']['__text']}</span></span>
                      </button>
                    </h5>
                    <h2>{court['court-type']['__text']}</h2>
                  </div>

                  <div id={"collapse-"+ index} className="collapse" aria-labelledby={"heading-"+ index} data-parent="#accordion">
                    <div className="card-body">
                      <span className="green"><sup>$</sup>{court['court-action-amount']['__text']}</span>
                      { court['court-action-status'] != null && (<div className="status">
                        <p>Status <span className="settled">{court['court-action-status']['__text']}</span></p>
                      </div>)}
                      <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>Resolve an issue on your credit report</a>
                    </div>
                  </div>
                </div>
              )
            })
        }

        </div>

        {this.state.modal && (
          <ResolveIssueModal closeModal={()=>{this.closeModal()}}></ResolveIssueModal>
          )}
      </span>
        );
      }
 }

 AdverseInformationDemo.propTypes = {
    textVariants: PropTypes.object.isRequired,
};


export default AdverseInformationDemo;
