import LayoutApi from '../../api/mockLayoutApi';
import ArticlesApi from '../../api/mockArticlesApi.js'
import ScoreApi from '../../api/mockScoreApi.js'
import * as types from './actionTypes';
import {beginAjaxCall, apiFail} from './ajaxStatusActions';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as trackingActions from './trackingActions';


export function resetScoreProfile() {
  return {type: types.RESET_SCORE_PROFILE};
}

export function ajaxLoadScore(loading) {
  return {type: types.AJAX_LOAD_SCORE, loading};
}

// export function loadScoreProfileSuccess(scoreProfile, isNoRecords, isInProgress, isTryingCountReached, options) {
//   return {type: types.LOAD_SCORE_PROFILE_SUCCESS, scoreProfile, isNoRecords, isInProgress, isTryingCountReached, options};
// }


export function loadScoreProfileSuccess(scoreProfile, options) {
  return {type: types.LOAD_SCORE_PROFILE_SUCCESS, scoreProfile, options};
}

export function updateDisplayName(displayName){
  return {type: types.USER_PROFILE_DISPLAYNAME, displayName};
}


export function loadScoreProfile(options = {includeScore: true, includeAdvices: true, includeHistory:  false}, forced=false, retry = false) {
  return (dispatch, getState) => {

    let state = getState();
    let scoreProfileState = state.scoreProfile;

    //console.log('loadScoreProfile', GlobalVariables.SCORE_RETRY_TIMEOUT, scoreProfileState.tryingCGetScoreCount, scoreProfileState.tryingGetScoreMax, forced, retry);
    if(!forced){

      //if(shouldNotLoadScoreProfile(scoreProfileState, options) || scoreProfileState.tryingCGetScoreCount > scoreProfileState.tryingGetScoreMax){

          if(scoreProfileState.tryingCGetScoreCount > scoreProfileState.tryingGetScoreMax){
              //dispatch(ajaxLoadScore(false));

              dispatch(loadScoreProfileSuccess(scoreProfileState, options));
              dispatch(trackingActions.trackScore(false));
              return Promise.resolve(scoreProfileState);
          }        
      //}
    }


    dispatch(ajaxLoadScore(true));
    options.retry = retry;
    return ScoreApi.getScoreProfile(state.userProfile.csrfToken, options).then(scoreProfile => {

        dispatch(loadScoreProfileSuccess(scoreProfile, options));
        dispatch(updateDisplayName(scoreProfile["displayName"]));
        dispatch(trackingActions.trackScore(true));

        return Promise.resolve(scoreProfile);
    }).catch(error => {

      if(error.status == 417){
        // keep trying
        //dispatch(loadScoreProfileSuccess(null, true, options));
        console.log('loadScoreProfile 417', GlobalVariables.SCORE_RETRY_TIMEOUT, scoreProfileState.tryingCGetScoreCount, scoreProfileState.tryingGetScoreMax, forced, retry);
        if(scoreProfileState.tryingCGetScoreCount > scoreProfileState.tryingGetScoreMax)
          return Promise.resolve();

        return new Promise((resolve, reject) => {
            setTimeout(() => {

                resolve(dispatch(loadScoreProfile(options, false, true)));
            }, GlobalVariables.SCORE_RETRY_TIMEOUT);
            
        });
      }
      else if(error.status == 409) {
          let scoreProfileFake = {
            scoreInfo: {
              isServiceNotAvailableNow : true,
              noScore: true
            }
          };
        
          dispatch(loadScoreProfileSuccess(scoreProfileFake, options));
          return Promise.resolve();
      }
      else {
          dispatch(apiFail("ScoreApi", "scoreProfile"));
          //dispatch(ajaxLoadScore(false));
          return Promise.resolve();
      }

    });
  };
}
//
// export function loadScoreProfile(options = {includeScore: true, includeAdvices: true, includeHistory:  false}, forced=false) {
//   return (dispatch, getState) => {
//
//     let state = getState();
//     let scoreProfileState = state.scoreProfile;
//
//     if((!forced && shouldNotLoadScoreProfile(scoreProfileState, options)) || scoreProfileState.tryingCGetScoreCount > scoreProfileState.tryingGetScoreMax){
//
//         if(scoreProfileState.tryingCGetScoreCount > scoreProfileState.tryingGetScoreMax){
//             dispatch(ajaxLoadScore(false));
//             dispatch(loadScoreProfileSuccess(null, false, scoreProfileState.hasScoreInProgress, true, options));
//
//         }
//
//       dispatch(trackingActions.trackScore(false));
//       return Promise.resolve(scoreProfileState);
//     }
//
//     dispatch(ajaxLoadScore(true));
//     return ScoreApi.getScoreProfile(state.userProfile.csrfToken, options).then(scoreProfile => {
//
//         dispatch(loadScoreProfileSuccess(scoreProfile, false, false, false, options));
//         dispatch(updateDisplayName(scoreProfile["displayName"]));
//         dispatch(trackingActions.trackScore(true));
//
//         return Promise.resolve(scoreProfile);
//     }).catch(error => {
//
//       if(error.status == 417){
//         // keep trying
//         dispatch(loadScoreProfileSuccess(null, false, true, false, options));
//
//         return new Promise((resolve, reject) => {
//             setTimeout(() => {
//                 resolve(dispatch(loadScoreProfile(options, true)));
//             }, GlobalVariables.SCORE_RETRY_TIMEOUT);
//         });
//       }
//       else if(error.status == 410){
//         // no records
//         dispatch(loadScoreProfileSuccess(null, true, false, false, options));
//
//         return new Promise((resolve, reject) => {
//             setTimeout(() => {
//                 resolve(dispatch(loadScoreProfile(options, true)));
//             }, GlobalVariables.SCORE_RETRY_TIMEOUT);
//         });
//       }
//       else {
//           dispatch(apiFail("ScoreApi", "scoreProfile"));
//           dispatch(ajaxLoadScore(false));
//           return Promise.resolve();
//       }
//
//     });
//   };
// }

function shouldNotLoadScoreProfile(scoreProfileState, options){

let scorePart = ( scoreProfileState.scoreInfo && scoreProfileState.scoreInfo.score) || !options.includeScore;
let advicesPart = ( scoreProfileState.advices &&
   (
     (scoreProfileState.advices.helping && scoreProfileState.advices.helping.length > 0) ||
     (scoreProfileState.advices.hurting && scoreProfileState.advices.hurting.length > 0)
   ) || !options.includeAdvices );

 let scoreHistoryPart = ( scoreProfileState.scoreHistory && scoreProfileState.scoreHistory.length > 0) || !options.includeHistory;

 return scorePart && advicesPart && scoreHistoryPart;

}

export function initializeScoreCounterSuccess(scoreCounter) {
  return { type: types.GET_SCORE_COUNTER_SUCCESS, scoreCounter };
}

export function updateScoreCounter(counter, loaded) {
  return { type: types.UPDATE_SCORE_COUNTER, counter, loaded };
}

export function initializeScoreCounter() {
  return (dispatch, getState) => {
    let state = getState();
    let scoreCounterState = state.scoreProfile.scoreCounter;

    if (!scoreCounterState.timerId) {
      return ScoreApi.getScoreCounter().then((scoreCounterResponse) => {
        let counter = scoreCounterResponse.counter || scoreCounterState.counter;
        let increment = scoreCounterResponse.counterIncrement || scoreCounterState.counterIncrement;
        let timerInterval = scoreCounterResponse.updateInterval || scoreCounterState.updateInterval;
        let loaded = true;

        initializeScoreCounterTimer(counter, increment, timerInterval, loaded, dispatch);

        return scoreCounterResponse;

      }).catch(error => {

        let counter = scoreCounterState.counter;
        let increment = scoreCounterState.counterIncrement;
        let timerInterval = scoreCounterState.updateInterval;
        let loaded = true;

        initializeScoreCounterTimer(counter, increment, timerInterval, loaded, dispatch);
      });
    }

  };
}

function initializeScoreCounterTimer(counter, increment, timerInterval, loaded, dispatch) {
  let isTimerOn = false;
  let timerId = setInterval(() => {
    if (isTimerOn) {
      let rndIncrement = Math.floor(Math.random() * increment) + 1;
      counter = counter + rndIncrement;
      dispatch(updateScoreCounter(counter, loaded));
    }
  }, timerInterval);

  let sC = {};
  sC.timerId = timerId;
  sC.counter = counter;
  sC.counterIncrement = increment;
  sC.updateInterval = timerInterval;
  sC.loaded = loaded;

  dispatch(initializeScoreCounterSuccess(sC));
  isTimerOn = true;
}
