import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import AboutMeApi from '../../api/aboutMeApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import {AboutMeWizard} from '../common/Wizard/AboutMe/AboutMeWizard';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

class TermOfUse extends React.Component { 
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAboutMeProfileLoaded : false,
      isAboutMeProfileLoading : true,
      isServerError: false,
      initialProfile: null,
    };


  }

  componentDidMount() {
    window['initAppJs'](true);
    this.props.actions.getTextVariants();
    this.props.actions.getMenuForPage(pages.ABOUTME);
    //this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
    this.props.actions.isAuthenticated().then(e => {
      AboutMeApi.getAboutMeProfile(this.props.csrfToken).then( resp => {
        this.setState({
          initialProfile: resp.profile, 
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: false});

      }).catch( error => {
        this.setState({
          isAboutMeProfileLoaded: true, 
          isAboutMeProfileLoading: false,
          isServerError: true});

      });
    });
  }

  componentWillReceiveProps(nextProps) {

  }








  render() {    
    let pageContent = (
    <div className="grid-section mt-5 mb-5">
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <div>
                <p><strong>General Terms of Use for all users&nbsp;</strong></p>
                <p>This legal notice applies to the entire contents of the website under the domain name&nbsp;<a href={RoutesVariables.RouteExternalHomeSPA}>www.getcreditscore.com.au</a>&nbsp;website (Site) operated by GetCreditScore Pty Ltd (ACN 169 886 891) (as the context requires&nbsp;<strong>We</strong>,&nbsp;<strong>Our</strong>&nbsp;or&nbsp;<strong>Us</strong>) and to any correspondence (by email or otherwise) between Us and you. The following are the Terms of Use (<strong>Terms of Use</strong>) between you and Us that set forth the terms and conditions for your use of the Site. Please read these terms carefully before using the Site. Using this Site indicates that you have read, understood, and agree to be bound by the Terms of Use and to comply with all applicable laws and regulations. If you do not accept these terms, please do not use the Site.</p>
                <p>Your access to the Site is subject to the GetCreditScore Privacy Statement, notices, disclaimers and any other relevant content published on the Site. If you use any of the services identified on the Site for which additional or alternative terms and conditions are referred to, by accessing, viewing or otherwise using those services, you agree also to be bound by and comply with those relevant terms and conditions.</p>
                <p>We may, at our sole discretion, vary or modify these Terms of Use without prior notice in order to comply with the law, or changes to the law. Other term variations or modifications relating to the provision of our service to you will appear on the website in addition to you being provided not less than 30 days' notice. &nbsp;Any subsequent access to, or use by you of, the Site will constitute an acceptance of varied or modified Terms of Use.&nbsp;</p>
                <p>To the extent possible we choose to carry out all transactions by means of electronic communication in accordance with the Electronic Transactions Act&nbsp;1999 (Cth) and any regulations.&nbsp; You consent to using electronic means to form, or agree to, or bind you to, legal obligations.&nbsp; Therefore, where you indicate through electronic means your consent to us acting for you, or your agreement to, or acceptance of, terms and conditions, offers and other documents, you will be taken to have agreed to, or signed, them.</p>
                <p>You agree that any where we are required, by law or otherwise, to provide you with any documentation you consent to the provision of that document electronically through the links on this website or any other means we choose to use. You consent to receive notices, information and other disclosures in electronic form we choose to use through the links on this Site or any other means.&nbsp;</p>
                <p>The Site contains information and features that you can use to obtain your credit score and any other features or content we may add in the future (the&nbsp;<strong>Services</strong>).&nbsp;</p>
                <p><strong>Eligibility to Use GetCreditScore's Services</strong></p>
                <p>To be eligible to use our services via the Site you must be either an Australian citizen or Australian permanent resident and be 18 years of age or older (<strong>Eligibility Criteria</strong>). We may vary the Eligibility Criteria from time to time. By using the Service or the Site, you represent and warrant that you are 18 or older and have an Australian driver’s licence or Australian passport. We may delete your profile and any content or information that you have posted on the Site and/or prohibit you from using or accessing the Site without limitation if we believe that you do not comply with the Eligibility Criteria.</p>
                <p><strong>Obtaining your credit score</strong></p>
                <p>This Service permits you, through GetCreditScore, to obtain your credit score from Equifax Australia Information Services and Solutions Pty Limited (ABN 26 000 602 862) (<strong>Equifax</strong>). &nbsp;You appoint us as your Access Seeker for this purpose and this appointment continues until your membership with GetCreditScore is cancelled. &nbsp;For more information on the role of an Access Seeker refer to the GetCreditScore&nbsp;<a href="/privacy-policy/">Privacy Policy</a></p>
                <p>In order to receive our Services, you must provide us with:</p>
                <ul>
                <li>Accurate, complete and up-to-date <strong><em>personal information</em></strong> (for more information about what constitutes ‘personal information’, please refer to the GetCreditScore <a href={RoutesVariables.RouteExternalPrivacyPolicy}>Privacy Policy</a>; and</li>
                <li>A valid email address.&nbsp;</li>
                </ul>
                <p><strong>Site Content, Brands and Trademarks</strong></p>
                <p>You acknowledge and agree that we own all rights to the Site and all the content displayed on the Site, including, but not limited to, the GetCreditScore logo, domain names, images, buttons, text, data, articles, design, source code, software, photos, images and other information (collectively the&nbsp;<strong>Site Content</strong>). The Site services, Site Content, trademarks, service marks, trade names, images and logos are proprietary to or used with permission by GetCreditScore.</p>
                <p>You agree that Site Content may not be copied, reproduced, distributed, republished, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without Our express prior written consent. You may not modify, transform, participate in the sale or transfer of, or create derivative or modified works based on any Site Content, in whole or in part.</p>
                <p>Nothing on the Site should be construed as granting, by implication or otherwise, any license or right to use any of the Site Content or trademarks displayed on the Site, including by linking or framing, or in any networked computer environment for any purpose, is prohibited without our prior written permission. We prohibit the use of any of the GetCreditScore trademarks as part of a link to or from any site unless establishment of such a link is approved in writing by us in advance.</p>
                <p><strong>No Advice Service</strong></p>
                <p>Any information we provide is of a general and information nature only.&nbsp; We do not provide legal, tax, financial, financial planning or investment advice or any form of advice regarding the suitability, or appropriateness of any of our services or of obtaining credit from any person.&nbsp;</p>
                <p>The Services do not take into account your personal financial situation or your personal investment objectives. Financial products accessible through the Site have a broad range of varying characteristics, not all of which may be of relevance or interest to you.&nbsp; Where we identify a financial product as a “Featured Offer” compatible with your credit score from Equifax, that link is:</p>
                <ul>
                <li>to a Third Party Site (as defined below) where the owner of that site has paid Us to promote its interest in attracting potential customers within a range that encompasses your credit score; and</li>
                <li>not any representation or suggestion by Us that the offer accessible through that Third Party Site provides the product most suited to you (whether by reason of pricing, features, term or otherwise).</li>
                </ul>
                <p>All Third Party Site offers compatible with your credit score from Equifax are available on the Site.&nbsp; You should consult a financial adviser, legal adviser, tax professional and any other professional adviser regarding your specific financial situation if you have any uncertainty or concerns regarding any Third Party Site offer.</p>
                <p><strong>Monitoring of Site Content&nbsp;</strong></p>
                <p>You acknowledge and agree that we have the right to monitor the Site and/or Site Content and to disclose any information as necessary or appropriate to satisfy any law, regulation or other governmental request, to operate the Site, or to protect itself or other users of the Site. We have no obligation to monitor content on the Site or to protect users of the Site.</p>
                <p>We may monitor communications via the Site and may withdraw any communication services or facilities at any time. We reserve to right to restrict any&nbsp;activities on the Site that We deem to be in breach of this legal notice or any other GetCreditScore agreement or policy.</p>
                <p><strong>Disclaimer</strong></p>
                <p>None of GetCreditScore, any of its affiliates or partners, providers or their respective officers, directors, employees, agents, independent contractors or licensors (referred to collectively as the&nbsp;<strong>GetCreditScore Parties</strong>) guarantees the accuracy, adequacy, timeliness, reliability, completeness, or usefulness of any of the content and GetCreditScore disclaims liability for errors or omissions in the content.</p>
                <p>The Site and all its content is provided "as is", without any warranty, either express or implied, including the implied warranties of merchantability, fitness for a particular purpose, non-infringement or title. The Site could contain technical inaccuracies or typographical errors.</p>
                <p>GetCreditScore Parties do not warrant as to the results of your use of the content or that the Site is free of viruses or other harmful components. Use of this Site is at your own risk. This does not affect those warranties which cannot be excluded or restricted under the applicable laws.</p>
                <p>We may discontinue or make changes in the content and the Site at any time without prior notice and without any liability. Any dated information is published as of its date only, and GetCreditScore Parties do not undertake any obligation or responsibility to update or amend any such information. We reserve the right to terminate any or all Site offerings or transmissions without prior notice.</p>
                <p><strong>Third Party Sites</strong></p>
                <p>The Site contains links to web sites controlled, owned, or operated by third-party entities, organisations or people who have entered into commercial arrangements with Us (the&nbsp;<strong>Third Party Sites</strong>). Not all financial service providers or market offers are represented through Third Party Site links accessible from our Site. Links to Third Party Sites on the Site are provided solely for your convenience. If you use these links, you leave the Site. We have not reviewed all of these Third Party Sites and do not control and are not responsible for these websites or their content or availability. GetCreditScore therefore does not endorse or make any representations about them, or any material found there, or any results that may be obtained from using them. If you decide to access any of the Third Party Sites linked to the Site, you do so entirely at your own risk.</p>
                <p><strong>Other Restricted Activities</strong></p>
                <p>When you use the Site or Our services you must not:<br/><br/>a. breach these Terms of Use, policies or procedures and/or any other agreement that you have entered into with Us;<br/><br/>b. violate any law, statute, ordinance, or regulation;<br/><br/>c. act in a manner which is, or post, email, transmit or otherwise publish any comments, materials or content that are, offensive, discriminatory, unlawful, threatening, harassing, defamatory or libellous, false, inaccurate or misleading;<br/><br/>d. provide false, inaccurate or misleading information;<br/><br/>e. refuse to cooperate in an investigation or provide confirmation of your identity or any information you provide to Us;<br/><br/>f. use an anonymous proxy or other technique to prevent us from identifying your location or your IP address;<br/><br/>g. use our services in a manner that results in or may result in complaints, disputes, claims, fees, fines, penalties and other liability to Us or you; and<br/><br/>h. pay for your transactions with what we reasonably believe to be potentially fraudulent funds or proceeds of crime.<br/><br/><strong>Limitation of Liability&nbsp;</strong></p>
                <p>GetCreditScore Parties (whether or not involved in creating, producing, maintaining or delivering the Site), exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including without limitation negligence), contract or otherwise) in connection with the Site in any way or in connection with the use, inability to use or the results of use of the Site, any Third Party Site or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property on account of your access to, use of, or browsing the Site or your downloading of any material from the Site or any websites linked to the Site.</p>
                <p>Nothing in this legal notice shall exclude or limit GetCreditScore's liability for:<br/><br/>a. fraud; or<br/><br/>b. misrepresentation as to a fundamental matter; or<br/><br/>c. any liability which cannot be excluded or limited under applicable law.</p>
                <p>If your use of materials from this Site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
                <p><strong>Indemnity</strong></p>
                <p>You agree to indemnify and hold harmless each GetCreditScore Party from and against any and all claims, losses, expenses, demands or liabilities, including attorneys' fees and costs, incurred by the GetCreditScore Parties in connection with any claim by a third party (including any intellectual property claim) arising out of any comments, materials or content you submit to, post to or transmit through the Site, or your use of the Site in violation of this Terms of Use or in violation of any applicable law. You agree to indemnify and hold harmless GetCreditScore from any claim arising from a third party's use of comments, materials or content of any kind that you post to the Site.</p>
                <p><strong>Other GetCreditScore Agreements&nbsp;</strong></p>
                <p>Our Terms of Use shall be subject to any other agreements you have entered into with Us. Should there be any conflict between the Terms of Use and any other agreements and / or terms and conditions, including the Loan Terms and Conditions then the agreement that has most recently been signed, executed or agreed to by you shall be taken as having precedence. Some sections or pages of the Site may contain separate terms and conditions of use which are to be taken as addition to the Terms of Use. In the event of a conflict, the additional terms and conditions will govern for those sections or pages or applicable service.</p>
                <p><strong>Waiver&nbsp;</strong></p>
                <p>Failure by us to enforce any of our rights under this legal notice shall not be construed as a waiver of those rights or any other rights in any way whatsoever. If any of the Terms of Use are found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>
                <p><strong>Governing Law and Jurisdiction</strong></p>
                <p>The Terms of Use and all other aspects of your use of the Site shall be governed by the laws of the state of New South Wales, Australia. Disputes arising in connection with this legal notice shall be subject to the non-exclusive jurisdiction of the courts of New South Wales, Australia. You agree that you will notify us of any claim or dispute concerning or relating to this legal notice and/or the Site.</p>
                <p><strong>How to Contact Us&nbsp;</strong></p>
                <p>If you wish to contact us regarding the Terms of Use or any of our Services please email us at: <strong>contact@getcreditscore.com.au</strong>.</p>
                <p><span>This Terms of Use was last updated on 25 Nov 2019</span><strong>.</strong></p>

              </div>
            </div>        
          </div>
        </div>
      </div>
    </div>
    );


    return (

      <div className="page-template-page-about bg-circle">
        {!this.props.userProfile.isAuthenticated && <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />}
        {this.props.userProfile.isAuthenticated && <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>}
        {pageContent}
        <FooterSmallSection></FooterSmallSection>
      </div>
    );
  }
}

TermOfUse.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];

  if (state.menu) {
    var menu = state.menu;
    if ( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.ABOUTME]) {
        menuItems = menu.menuItemsPerPage.menuItems[pages.ABOUTME];
     }
  }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    csrfToken: state.userProfile.csrfToken,
    identifier: state.userProfile.identifier
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, trackingActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUse);