import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';

const AccountsModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">                                       
              <h4><span>1</span> Information shown for each account listing:</h4>              
              <ul>
                <li>Name of credit provider</li>
                <li>Type of account – e.g. personal loan, credit card &amp; telco</li>
                <li>Account number or reference</li>
                <li>Open date</li>
                <li>Close date</li>
                <li>Last updated date</li>
                <li>Account number or reference</li>
                <li>Repayment history information - NOTE: The Credit Report Overview will only display information for the past 6 months</li>
              </ul>    
              <h4><span>2</span> What are Accounts and Repayment History Information?</h4>              
              <p>This section contains detailed information about the credit accounts in your name. Each account also contains its own repayment history information. Repayment history is part of the new positive data included in credit reports when CCR came into effect in 2017.</p>
              <p>Repayment history is simply a record of your minimum monthly repayments on your loans such as mortgages and/or credit cards that appear on your credit report.</p>
              <p>Your repayment history information is recorded on your credit report for a period of two years.</p>              
              <p>It’s important to know that regularly making your minimum repayments on time is a great way to demonstrate good credit behaviour and improve your credit worthiness.</p>
                           
               {/* Changes for ACRDS 3.0              */}
              {/* <h6>Each of the following will determine your repayment status:</h6>
              <ul>
                <li>Green Tick – Payment made on time</li>
                <li>Red Cross – Missed Payment</li>
                <li>Grey Circle – No data</li>
              </ul> */}

              <h6>Each of the following will determine your repayment status:</h6>
              <ul style={{paddingBottom: '20px'}}>
                <li><img src={ImagesPath.ImgIconOkNew} style={{height: '20px'}} alt=""/> – Payment made on time</li>
                <li><img src={ImagesPath.ImgIconAlertNew} style={{height: '18px'}} alt=""/> – Missed Payment</li>
                <li><img src={ImagesPath.ImgIconQuestionNew} style={{height: '20px'}} alt=""/> – No data</li>
              </ul>   

              <h4 style={{paddingBottom: '10px'}}>From 1 July 2022, we will also display hardship arrangements for applicable accounts, including the following:</h4>

              <p><img src={ImagesPath.ImgIconFHIA} alt=""/> <b>= Temporary relief / deferral Financial Hardship Arrangement (FHA) </b><br/>
              Payment obligations are temporarily reduced or deferred as a result of a financial hardship Variation Financial Hardship Arrangement (FHA)</p> 

              <p><img src={ImagesPath.ImgIconFHIV} alt=""/> <b>= Variation Financial Hardship Arrangement (VHA) </b><br/>
              A variation made to the terms or conditions as a result of a financial hardship arrangement. The repayment history reflects the new payment required.</p> 

              <p>Please note, that these hardship arrangements do not affect your credit score.</p>
             
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default AccountsModal;