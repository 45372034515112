import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';

import * as pages from '../../shared/pages';
import * as RoutesVariables from '../../../config/routesVariables';
import * as ImagesPath from '../../../config/imagesPath';
import * as funcs from '../../shared/functions';
import {initTonikForms} from '../../../tonik/shared/forms';

import TextInput from '../../common/controls/TextInput';
import TextNumericInput from '../../common/controls/TextNumericInput';
import DateInputSimple from '../../common/controls/DateInputSimple';

import { countries } from '../../../common/countries';

import {DvsConsentCheckbox} from './DvsCommon';

//const DvsPassportForm = ({ customerIds, errors, updateCustomerIdsState, changeState, countries, saveCustomerIds, isInline = false,  showButton=true }) => {
const DvsPassportForm = ({ identityRequest, onSave, onChange, errors, saving, textVariants, onBack, isInline = false}) => {
    
  return (
    <form className="d-flex justify-content-center align-items-center flex-column">    

        <div className="col-12">
          {(errors && errors['global']) &&
              (<div className="alert alert-danger" role="alert">
                      <span dangerouslySetInnerHTML={funcs.createMarkup(errors['global'])}></span>                    
                      {errors['type'] && <span> or <a onClick={onBack} style={{float: "none !important;", cursor: "pointer;"}}>click here</a> to choose another document</span>}
              </div>)}
        </div>

        <div className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"}>
            <TextInput
              name="givenName"
              id="givenName"
              autocomplete="off"
              placeholder={'Given Name as in Document'}
              value={identityRequest['givenName']}
              label={'Given Name'}
              onChange={onChange}
              error={errors['givenName']}
              groupClass="form-label-group"  />
          </div>
          <div className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"}>
            <TextInput
              name="middleName"
              id="middleName"
              autocomplete="off"
              placeholder={'Middle Name'}
              value={identityRequest['middleName']}
              label={'Middle Name'}
              onChange={onChange}
              error={errors['middleName']}
              groupClass="form-label-group"  />
          </div>
          <div className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"}>
            <TextInput
              name="familyName"
              id="familyName"
              autocomplete="off"
              placeholder={'Family Name'}
              value={identityRequest['familyName']}
              label={'Family Name'}
              onChange={onChange}
              error={errors['familyName']}
              groupClass="form-label-group"  />
          </div>
          <div  className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"}>              
             <DateInputSimple  
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={identityRequest['dateOfBirth']}
                  label={'Date Of Birth'}
                  onChange={onChange}
                  error={errors['dateOfBirth']}
                  groupClass={"form-label-group"} 
             />                                          
          </div>
          <div className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"} >
          <div className="form-label-group">
              <select className={"form-control filled " + ((errors && errors['countryCode']) ? " is-invalid" : "")}
                      id="passportIssuingCountry"
                      name="countryCode"
                      onChange={onChange}
                      value={identityRequest['countryCode']} >              
                {countries.map((item, i) => <option key={item.alpha3 + i.toString()} value={item.alpha3}>{item.name}</option>)}
              </select>
              <label htmlFor="passportIssuingCountry">Issuing Country</label>
              <div className="invalid-feedback">{errors['countryCode']}</div>
            </div>
          </div>
          <div className={isInline ? "col-12 small-input" :"col-lg-6 col-12 small-input"}>
            <TextInput
              name="number"
              id="passportNumber"
              autocomplete="off"
              placeholder={'Passport Number'}
              value={identityRequest['number']}
              label={'Passport Number'}
              onChange={onChange}
              error={errors['number']}
              groupClass="form-label-group"  />
          </div>

        <div className="col-12" >
        
        <DvsConsentCheckbox
          identityRequest={identityRequest}          
          onChange={onChange}
          errors={errors}
         />
           
        <a onClick={onSave} className="btn btn-primary" disabled={saving}>
                <span >{textVariants[pages.MYDETAILSVERIFICATION + textVariants.textVariantSeparator + "save_btn"]}</span>                                
                {saving && <span class="spinner-border spinner-border-sm align-middle ml-2" role="status" aria-hidden="true"></span>}
        </a>
      </div>
    </form>
  )
};

export default DvsPassportForm;
