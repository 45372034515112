import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import LoginFrame from './LoginFrame';

const LoginBoxButton = ({ loading, textVariants }) => {

  let btnText = '';
  let btnCls = 'btn btn-primary full-width';

  btnText = textVariants["Btn_Login"];

  if (loading)
   btnCls +='btn btn-primary full-width disabled';

  return (
    <button
        type="submit"
        className={btnCls}>
        {btnText}
        {loading &&(<span className="spinner-border spinner-border-sm align-middle ml-2" role="status" aria-hidden="true"></span>)}
    </button>
  )
}

const LoginBox  = ({
  textVariants,
  onChange,
  onActionClick,    
  error,
  userCredentials,
  onForgotPasswordClick,
  loading,  
  }) => {


    let inputEmailCls = 'form-control';
    if (error.email && (error.email.length>0) ) {
      inputEmailCls += ' is-invalid';
    }

    let inputPwdCls = 'form-control password';

    if (error.fckpass && (error.fckpass.length>0) ) {
      inputPwdCls += ' is-invalid';
    }    

    
   let feqfx = "pas"
   let omg = "sword"

return (


  <LoginFrame
    disclaimerCtrl={<span className="text-form">By clicking 'Login', I authorise GetCreditScore to obtain my credit score and, if I’ve elected, an overview of my personal credit file from Equifax Australia Information Services and Solutions Pty Limited. </span>}
  >
              <div>
              <p>Get unlimited access to your credit score, dashboard, tools, insights and personalised offers.</p>
               
               <form onSubmit={onActionClick}>
                
                <div className="form-label-group">
                  <input type="email" id="email" name="email" className={inputEmailCls} onChange={onChange} value={userCredentials.email} autoComplete="off" />
                  <label htmlFor="email" style={{cursor:'text',fontSize:"14px",color:"rgba(51, 51, 51, 0.6)"}}>Your e-mail address</label>
                  {error.email && (
                    <>
                      <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                      <div className="invalid-feedback">
                          {error.email}
                      </div>
                    </>
                  )}
                </div>

                 <div className="form-label-group">
                  <input type={feqfx+omg} id="fckpass" name="fckpass" value={userCredentials.fckpass} onChange={onChange} className={inputPwdCls} autocommplete="off"  />
                  <label htmlFor="password" style={{cursor:'text',fontSize:"14px",color:"rgba(51, 51, 51, 0.6)"}}>Password</label>
                  <a className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt="" /></a>
                  {error.fckpass && (
                    <>
                      <div className="invalid-feedback">
                          {error.fckpass}
                      </div>
                    </>
                  )}
                </div>

                {error.login && 
                  <div className="alert alert-danger">
                    <span dangerouslySetInnerHTML={funcs.createMarkup(error.login)}></span>
                  </div>
                }                

                <div className=" form-label-group form-checkbox">
                  <input className="form-check-input" type="checkbox" id="rememberMe" name="rememberMe" checked={userCredentials.rememberMe} onChange={onChange} />
                  <label className="form-check-label" htmlFor="rememberMe">
                      Remember me
                  </label>
                </div>

                                
                <a href="#" className="text-style nv-forgot-pass" onClick={onForgotPasswordClick}>Forgotten password?</a>
                  <LoginBoxButton
                        loading={loading}
                        textVariants={textVariants}                                              
                      />
                <p>No account yet? <Link to={RoutesVariables.RouteCreateAccount}>Create Account</Link></p>
              </form>
              </div>

  </LoginFrame>
);


};

LoginBox.propTypes = {
  textVariants: PropTypes.object.isRequired,
  userCredentials: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  loginMode: PropTypes.bool,
  onActionClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default LoginBox;