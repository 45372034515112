import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function textVariantReducer(state = initialState.textVariants, action) {
  switch (action.type) {
    case types.LOAD_TEXT_VARIANTS_SUCCESS:

      var newState = Object.assign({}, state, action.textVariants);

      return newState;

    default:
      return state;
  }
}
