import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

import {initTonikForms} from '../../tonik/shared/forms';
import {tonikInitMonthSlider} from '../../tonik/report';
import AccountsModal from './shared/AccountsModal';

import {LineShower} from './shared/LineShower'
import {ErrorWrapper} from './shared/ErrorWrapper';

const $ = window.$;


class Accounts extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        modal: false,
      }

      this.onAccordionBtnClick = this.onAccordionBtnClick.bind(this);
  }

  componentDidMount() {
    //window['initAppJs'](true);
    initTonikForms();
    
  }

  componentDidUpdate(prevProps, prevState){
    //window['initAppJs'](true);
    initTonikForms();
  }

  openModal () {    
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

  onAccordionBtnClick(event, account) {
    if(account.repaymentHistory && account.repaymentHistory.length > 0){
      var sliderid = 'slider-' + account.accountId;      
      tonikInitMonthSlider(sliderid);
    }
  }

render(){

    let {textVariants, data} = this.props;
    var accounts = data;
            
    if(!accounts || accounts.length == 0){
      return (<ErrorWrapper header="Accounts" >
        <div className="alert alert-warning">
                  We couldn't find any accounts.
                </div>
        </ErrorWrapper>);
    }
    
    return (
      <div className='col-lg-6 col-md-8 col-12 accordion-column'>
      <span>
        <div id="accordion" className="accordion-reports">
          {
            accounts && accounts.map( (account, index) => {           

              return (
                <div key={index} className="card">
                  <div className="card-header" id={"heading-" + account.accountId}>
                    <h5 className="mb-0">
                      <button onClick={(e) => this.onAccordionBtnClick(e, account)}
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target={"#collapse-" + account.accountId}
                            aria-expanded="true"
                            aria-controls={"collapse-" + account.accountId}>
                        {account.originalCreditProvider  ? account.originalCreditProvider : ''} 
                        
                        {account.latestUpdatedDate &&
                           (<span>Updated: <span className="date">{ account.latestUpdatedDate}</span></span>)}
                      </button>
                    </h5>
                    <h2>{account.accountType}
                     <div className="textline">{account.accountId}</div>
                      {/* {account.accountOpenDate && <LineShower elementKey="Opened:" elementValue={account.accountOpenDate} />}
                      {account.accountClosedDate &&<LineShower elementKey="Closed:" elementValue={account.accountClosedDate} />}
                      {account.relationship && index == 0 && <LineShower elementKey="Relationship:" elementValue={account.relationship} />}
                      {account.termOfLoan && <LineShower elementKey="Term of Loan::" elementValue={account.termOfLoan} />} */}
                     </h2>
                  </div>

                  <div id={"collapse-" + account.accountId} className="collapse" aria-labelledby={"heading-" + account.accountId} data-parent="#accordion">
                    <div className="card-body">
                      {account.accountOpenDate && <LineShower elementKey="Opened:" elementValue={account.accountOpenDate} />}
                      {account.accountClosedDate &&<LineShower elementKey="Closed:" elementValue={account.accountClosedDate} />}
                      {account.relationship && <LineShower elementKey="Relationship:" elementValue={account.relationship} />}
                      {account.termOfLoan && <LineShower elementKey="Term of Loan:" elementValue={account.termOfLoan} />}
                      {account.latestMaximumCredit &&
                        (<> <p className="cr_card_special_title">Current Credit limit</p>
                            <span className="green"><sup>$</sup>{account.latestMaximumCredit}</span></>)
                      }
                                                                   
                        <div className="months-column">
                        {account.repaymentHistory && 
                        (<p className="fhi_text" >Repayment History and Financial Hardship Information</p>)}
                          <div id={'slider-' + account.accountId} className={"months-slider " + ((account.repaymentHistory && account.repaymentHistory.length > 0) ? '' :'d-none') }>
                           {account.repaymentHistory && account.repaymentHistory.map((item, index) => {
                              return (
                                <div key={index} className="months-item">
                                  {/* <p>{item.period}</p> */}
                                   {/* {item.fhi ? <p>{item.fhi}</p> : <br/>} */}
                                   {item.fhi ? <p style={{height: '30px'}}><img src={item.fhi == 'A' ?
                                      ImagesPath.ImgIconFHIA :
                                      (item.fhi == 'V'  ? ImagesPath.ImgIconFHIV : ImagesPath.ImgIconQuestionNew)}
                                     alt=""/><br/></p> : <p style={{height: '30px'}}><br/></p>}
                                  <img src={item.status == '0' ?
                                      ImagesPath.ImgIconOkNew :
                                      ('C,A,R,P,T,O'.indexOf(item.status) < 0 ? ImagesPath.ImgIconAlertNew : ImagesPath.ImgIconQuestionNew)}
                                    className="img-fluid" style={{height: '23px'}} alt=""/>
                                  {/* <img src={item.status == '0' ?
                                      ImagesPath.ImgIconOk :
                                      ('C,A,R,P,T,O'.indexOf(item.status) < 0 ? ImagesPath.ImgIconAlert2 : ImagesPath.ImgIconQuestion)}
                                    className="img-fluid" alt=""/>                                     */}
                                  <p>{item.period}</p>
                                </div>
                              )
                            })}
                          </div>
                        </div>                        

                      <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>How to read your Accounts information</a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {this.state.modal && (
          <AccountsModal closeModal={()=>{this.closeModal()}}></AccountsModal>
          )}
      </span>
      </div>);
      }
 }

 Accounts.propTypes = {
    textVariants: PropTypes.object,
    data: PropTypes.array.isRequired
};


export default Accounts;
