import React from 'react';
import PropTypes from 'prop-types';

const ScoreMapListItem = ({ record, keyName = "key", valueName="value" }) =>  {

  return  (
    <div className="scoremap-list-row">
      <span className="scoremap-list-item list-key">{record[keyName]}</span>
      <span className="scoremap-list-item list-value">{record[valueName]}</span>
    </div>
  );
  
};

ScoreMapListItem.propTypes = {
  record: PropTypes.object.isRequired,
  keyName: PropTypes.string,
  valueName: PropTypes.string
}

ScoreMapListItem.defaultProps = {
  keyName: "key",
  valueName: "value"
}

const ScoreMapList = ({ data, keyPrefix = 'l', keyName = "key", valueName="value" }) => {

  return (
    <div className="scoremap-list">
      { data.map((record, index) => <ScoreMapListItem key={index + keyName} record={record} keyPrefix={keyPrefix} keyName={keyName} valueName={valueName} />) }
    </div>
  );

};

ScoreMapList.propTypes = {
    data: PropTypes.array.isRequired,
    keyPrefix: PropTypes.string,
    keyName: PropTypes.string,
    valueName: PropTypes.string
};

ScoreMapList.defaultProps = {
  keyPrefix: "l",
  keyName: "key",
  valueName: "value"
};

export default ScoreMapList;