import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import {TrustPilotReviewUrl} from './../../config/GlobalFixedVariables';



class TrusPilotBaseGcsWidget  extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {loaded: false };
    this.refreshExternalHooks = this.refreshExternalHooks.bind(this);
  }

  componentDidMount(){
    this.refreshExternalHooks();         
  }

  componentDidUpdate(){
    this.refreshExternalHooks();
  }

  refreshExternalHooks(){
    if(!this.state.loaded){
      if(window.Trustpilot && window.Trustpilot.Modules && window.Trustpilot.Modules.WidgetManagement){          
        window.Trustpilot.Modules.WidgetManagement.initialize();
        this.setState({loaded: true});
      }
    }
  }
}

class InlineTrustStoreWidget  extends TrusPilotBaseGcsWidget {
  constructor(props, context) {
    super(props, context);     
  }   
    render(){    
    return (      
      <div class="trustpilot-widget" data-locale="en-AU" data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="20px" data-style-width="100%" data-theme="light">
        <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
      </div>      
    );
    }
}

const TrustPilotMicroTrustStore = ({textVariants}) => {
  return (
    <div className="container-fluid left-container-padding right-container-padding">
      <InlineTrustStoreWidget />
    </div>
  );
};


/*  Carousel component ----------------------------------------- */

class CarouselWidget  extends TrusPilotBaseGcsWidget {
    constructor(props, context) {
      super(props, context);     
    }   
  
  render(){
    return (
    // <!-- TrustBox widget - Carousel -->    
    <div class="trustpilot-widget" data-locale="en-AU" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="130px" data-style-width="100%" data-theme="light" data-stars="4,5">
      <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
    </div>    
    // <!-- End TrustBox widget -->

    // <div 
    //   className="trustpilot-widget"
    //   data-locale="en-AU"
    //   data-template-id="53aa8912dec7e10d38f59f36"
    //   data-businessunit-id="5a7a2414e70bdc0001447258"
    //   data-style-height="130px"
    //   data-style-width="100%"
    //   data-theme="light"
    //   data-stars="5"
    //   data-schema-type="Organization">
    //   <a href={TrustPilotReviewUrl.widgetUrl} target="_blank" rel="noopener noreferrer">
    //     <iframe 
    //       className="trust-pilot-iframe trust-pilot-iframe-carousel"
    //       frameBorder="0"
    //       scrolling="no"
    //       title="Customer reviews powered by Trustpilot"
    //       src="//widget.trustpilot.com/trustboxes/53aa8912dec7e10d38f59f36/index.html?locale=en-AU&amp;templateId=53aa8912dec7e10d38f59f36&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=130px&amp;styleWidth=100%25&amp;theme=light&amp;stars=5&amp;schemaType=Organization"
    //     />
    //   </a>
    // </div>     
  );
  }
}

/*  Inline component ----------------------------------------- */

class InlineWidget  extends TrusPilotBaseGcsWidget {
    constructor(props, context) {
      super(props, context);     
    }   
  
  render(){    
  return (
    // <!-- TrustBox widget - Starter -->
<div className="trustpilot-widget" data-locale="en-AU" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="100%" data-style-width="100%" data-theme="light">
  <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
</div>
// <!-- End TrustBox widget -->
    // <div 
    //   className="trustpilot-widget"
    //   data-locale="en-AU"
    //   data-template-id="5406e65db0d04a09e042d5fc"
    //   data-businessunit-id="5a7a2414e70bdc0001447258"
    //   data-style-height="28px"
    //   data-style-width="100%"
    //   data-theme="light">
    //   <a href={TrustPilotReviewUrl.widgetUrl} target="_blank" rel="noopener noreferrer">
    //     <iframe 
    //       className="trust-pilot-iframe trust-pilot-iframe-inline"
    //       frameBorder="0" 
    //       scrolling="no" 
    //       title="Customer reviews powered by Trustpilot" 
    //       src="//widget.trustpilot.com/trustboxes/5406e65db0d04a09e042d5fc/index.html?locale=en-AU&amp;templateId=5406e65db0d04a09e042d5fc&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=28px&amp;styleWidth=100%25&amp;theme=light"
    //     />
    //   </a>
    // </div>
  );
  }
}


/* Micro Star component ----------------------------------------- */

class MicroStarWidget  extends TrusPilotBaseGcsWidget {
    constructor(props, context) {
      super(props, context);     
    }   
  
  render(){
  return (
    // <!-- TrustBox widget - Micro Star -->
<div className="trustpilot-widget" data-locale="en-AU" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="24px" data-style-width="100%" data-theme="light">
  <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
</div>

    // <div 
    //   className="trustpilot-widget" 
    //   data-locale="en-AU" 
    //   data-template-id="5419b732fbfb950b10de65e5" 
    //   data-businessunit-id="5a7a2414e70bdc0001447258" 
    //   data-style-height="24px" 
    //   data-style-width="100%" 
    //   data-theme="dark">
    //   <a href={TrustPilotReviewUrl.widgetUrl} target="_blank" rel="noopener noreferrer">Trustpilot</a>
    //   <iframe
    //     className="trust-pilot-iframe trust-pilot-iframe-micro"
    //     frameBorder="0"
    //     scrolling="no"
    //     title="Customer reviews powered by Trustpilot"        
    //     src="//widget.trustpilot.com/trustboxes/5419b732fbfb950b10de65e5/index.html?locale=en-AU&amp;templateId=5419b732fbfb950b10de65e5&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=24px&amp;styleWidth=100%25&amp;theme=dark"
    //   />
    // </div>
  );
  }
}

// /* ReviewSlides component ----------------------------------------- */

// const ReviewSlides = ({ textVariants }) => {
//   let reviews = textVariants[pages.TRUSTPILOTSECTION + textVariants.textVariantSeparator + "reviews"];
//   let reviewSlides = reviews.map((review, index) => 
//     <div key={index} className={"item " + (index === 0 ? "active" : "")}>
//       <p className="trustpilot-carousel-review-comment">{review.comment}</p>
//       <p className="trustpilot-carousel-review-author">{review.author}</p>
//     </div>
//   );

//   return (
//     <div className="carousel-inner trustpilot-carousel-inner" role="listbox">
//       {reviewSlides}                
//     </div>
//   );
// };

/* ReviewCarousel component ----------------------------------------- */
class ReviewCarousel  extends TrusPilotBaseGcsWidget {
    constructor(props, context) {
      super(props, context);     
    }   
  
  render(){
  return (

    // <!-- TrustBox widget - Slider -->
<div className="trustpilot-widget" data-locale="en-AU" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5">
  <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
</div>

    // <div className="trustpilot-reviews-carousel">
    //   {/* Wrapper for slides */}
    //   <div id="trustPilotReviewsCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
    //     <ReviewSlides textVariants={textVariants} />
    //     {/* Controls */}
    //     <a className="left carousel-control trustpilot-carousel-control" href="#trustPilotReviewsCarousel" role="button" data-slide="prev">
    //       <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
    //       <span className="sr-only">Previous</span>
    //     </a>
    //     <a className="right carousel-control trustpilot-carousel-control" href="#trustPilotReviewsCarousel" role="button" data-slide="next">
    //       <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
    //       <span className="sr-only">Next</span>
    //     </a>
    //   </div>
    // </div>
  );
  }
}

class TrustPilotMini  extends TrusPilotBaseGcsWidget {
  constructor(props, context) {
    super(props, context);     
  }   

render(){
return (
    // <!-- TrustBox widget - Mini -->
  <div className="trustpilot-widget" data-locale="en-AU" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5a7a2414e70bdc0001447258" data-style-height="150px" data-style-width="100%" data-theme="light">
    <a href="https://au.trustpilot.com/review/www.getcreditscore.com.au" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  

  // <div 
  //   className="trustpilot-widget" 
  //   data-locale="en-AU" 
  //   data-template-id="5419b732fbfb950b10de65e5" 
  //   data-businessunit-id="5a7a2414e70bdc0001447258" 
  //   data-style-height="24px" 
  //   data-style-width="100%" 
  //   data-theme="dark">
  //   <a href={TrustPilotReviewUrl.widgetUrl} target="_blank" rel="noopener noreferrer">Trustpilot</a>
  //   <iframe
  //     className="trust-pilot-iframe trust-pilot-iframe-micro"
  //     frameBorder="0"
  //     scrolling="no"
  //     title="Customer reviews powered by Trustpilot"        
  //     src="//widget.trustpilot.com/trustboxes/5419b732fbfb950b10de65e5/index.html?locale=en-AU&amp;templateId=5419b732fbfb950b10de65e5&amp;businessunitId=5a7a2414e70bdc0001447258&amp;styleHeight=24px&amp;styleWidth=100%25&amp;theme=dark"
  //   />
  // </div>
);
}
}

/* Components ----------------------------------------- */

const TrustPilotCarousel = ({textVariants}) => {
  return (
    <div className="container-fluid left-container-padding right-container-padding">
      <h2 className="trust-pilot-title text-center">{textVariants[pages.TRUSTPILOTSECTION + textVariants.textVariantSeparator + "title"]}</h2>          
      <CarouselWidget />
    </div>
  );
};

const TrustPilotInline = ({textVariants}) => {
  return (
    <div className="container-fluid left-container-padding right-container-padding">
      <h2 className="trust-pilot-title text-center">{textVariants[pages.TRUSTPILOTSECTION + textVariants.textVariantSeparator + "title"]}</h2>          
      {/* <InlineWidget /> */}
      <ReviewCarousel textVariants={textVariants} />
    </div>
  );
};

const TrustPilotInlineMinimal = ({textVariants}) => {
  return (
    <div className="container-fluid left-container-padding right-container-padding">
      <InlineWidget />
    </div>
  );
};

const TrustPilotMicro = () => {
  return (
    <div className="trust-pilot-micro">
      <MicroStarWidget />
    </div>);
};

function trustPilotComponentSelector (params) {
    let component = <div />;
    let { type } = params;
  
    if (type === "carousel") {
      component = <TrustPilotCarousel {...params} />;
    } else if (type === "inline") {
      component = <TrustPilotInline {...params} />;
    } else if (type === "inline-minimal") {
      component = <TrustPilotInlineMinimal {...params} />;
    } else if (type === "micro") {
      component = <TrustPilotMicro {...params} />;
    }
    else if (type === "micro-truststore"){
      component = <TrustPilotMicroTrustStore {...params} />;
    }
    else if (type === "mini"){
      component = <TrustPilotMini {...params} />;
    }
  
    return component;  
  }

/* Main component ----------------------------------------- */

const TrustPilotSection = ({ isEnabled = false, type = "", forMobile = false, trustPilotSectionTheme = "", textVariants }) => {
  let section = <div />;
  
  if (isEnabled) {
    let component = trustPilotComponentSelector({type, textVariants});

    section = (
      <section className={"trust-pilot-section " + trustPilotSectionTheme + (forMobile ? " hidden-sm hidden-md hidden-lg" : " ")}>
        {component}    
      </section>
    );
  }

  return section;  
};

TrustPilotSection.propTypes = {
  isEnabled: PropTypes.bool,
  type: PropTypes.string,
  forMobile: PropTypes.bool,
  trustPilotSectionTheme: PropTypes.string,
  textVariants: PropTypes.object.isRequired
};

export default TrustPilotSection;