import React from 'react'
import * as pages from './pages.js';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import {USERROLES, hasOneRole} from '../../common/gcsLogic';

export const menuItemList = [
  {
        id: 'deals_d',
        name: 'Deals',
        link: '',
        ref: '#product-section',
        className: '',
        isAuthenticated: false
    },
    {
      id: 'creditsummary_d',
      name: 'Credit Report Overview',
      link: '/credit-report-overview',
      isAuthenticated: true,
      role: USERROLES.IsInCohort
    },
    {
      id: 'creditsummary_m',
      name: 'Credit Report Overview',
      link: '/credit-report-overview',
      isAuthenticated: true,
      role: USERROLES.IsInCohort
    },
   {
        id: 'articles',
        name: 'Articles',
        link: '',
        ref: RoutesVariables.RouteExternalArticles
    }, {
        id: 'helpcentre',
        name: 'Help',
        link: '',
        ref: RoutesVariables.RouteExternalHelpCenter
    }, {
        id: 'login',
        name: 'Log in',
        link: '/login',
        ref: '',
        isAuthenticated: false,
    }, {
        id: 'my-account',
        name: 'My account',
        link: '',
        ref: '',
        isAuthenticated: true,
        subMenuList: [
          {
            id: 'manage-account',
            name: 'Edit profile',
            link: '/my-details',
            ref: ''
          },
          {
            id: 'manage-identity',
            name: 'Verify Identity',
            link: '/my-details/verification',
            ref: ''
          },
          {
            id: 'about-me',
            name: 'About me',
            link: '/about-me',
            ref: ''
          },
          {
            id: 'my-preferences',
            name: 'Preferences',
            link: '/my-preferences',
            ref: ''
          },
          {
            id: 'change-password',
            name: 'Change password',
            link: '/change-password',
            ref: ''
          },
          {
            id: 'logout',
            name: 'Log out',
            link: '/logout',
            ref: ''
          }
        ]
    }, {
        id: 'getscore_d',
        name: 'Get score',
        link: '/create-account',
        className: 'btn btn-primary',
        ref: '',
        isAuthenticated: false
    }, 
    {
        id: 'getscore_m',
        name: 'Get score',
        link: '/create-account',
        className: 'btn btn-primary',
        ref: '',
        isAuthenticated: false
    }, 
    {
        id: 'dashboard_d',
        name: 'Dashboard',
        link: '/dashboard',
        className: '',
        ref: '',
        isAuthenticated: true,
    }, {
        id: 'dashboard_m',
        name: 'Dashboard',
        link: '/dashboard',
        className: '',
        ref: '',
        isAuthenticated: true,
      },
      {
        id: 'contactus_d',
        name: 'Contact Us',
        link: '/contact-us',
        //link: '',
        className: '',
        ref: '',
        //ref: RoutesVariables.RouteContactUsFix,
        isAuthenticated: true,
    }, {
        id: 'contactus_m',
        name: 'Contact Us',
        link: '/contact-us',
        //link: '',
        className: '',
        ref: '',
        //ref: RoutesVariables.RouteContactUsFix,
        isAuthenticated: true,
      },
       {
          id: 'viewoffersD_d',
          name: 'View offers',
          link: '/dashboard#product-section',
          className: '',
          ref: '',
          isAuthenticated: true,
      }, {
          id: 'viewoffersD_m',
          name: 'View offers',
          link: '/dashboard#product-section',
          ref: '',
          //className: 'hidden-lg hidden-md hidden-sm',
          className: '',
          //subMenuListOnlyMobile: true,
          isAuthenticated: true
    }, {
        id: 'viewoffers_d',
        name: 'View offers',
        link: '',
        className: '',
        ref: '#product-section',
        isAuthenticated: true,
    }, {
        id: 'viewoffers_m',
        name: 'View offers',
        link: '',
        ref: '#product-section',
        //className: 'hidden-lg hidden-md hidden-sm',
        className: '',
        //subMenuListOnlyMobile: true,
        isAuthenticated: true     
    }
];

let _menuItemsNamePerPage = {};
//["deals", "articles", "helpcentre","login","my-account","getscore_d", "getscore_m","dashboard_d","dashboard_m","viewoffers_d","viewoffers_m"];
let articlesMenuItems = ["helpcentre","dashboard_m","creditsummary_m","login","my-account","getscore_d", "getscore_m","dashboard_d"];
_menuItemsNamePerPage["DEFAULT"] = ["contactus_d", "contactus_m","dashboard_m","creditsummary_m","deals_d","deals_m", "articles", "helpcentre","login","my-account","getscore_d", "getscore_m","dashboard_d"];
_menuItemsNamePerPage[pages.HOME] = ["articles","helpcentre","dashboard_m","creditsummary_m","login","my-account","getscore_d", "getscore_m","dashboard_d",'creditsummary_d'];
_menuItemsNamePerPage[pages.DASHBOARD] = ["articles", "helpcentre","contactus_d", "contactus_m","dashboard_m","creditsummary_m",'creditsummary_d',"login","my-account"];
_menuItemsNamePerPage[pages.DASHBOARDHISTORY] = ["articles", "helpcentre","contactus_d", "contactus_m","dashboard_d","dashboard_m","creditsummary_m",'creditsummary_d',"login","my-account"];
_menuItemsNamePerPage[pages.DEALS_INSURANCE] = ["articles","helpcentre","login","my-account","getscore_d", "getscore_m", "dashboard_d","dashboard_m",'creditsummary_d',"creditsummary_m"];
_menuItemsNamePerPage[pages.CREDITCARDS] = ["articles","helpcentre","login","my-account","getscore_d", "getscore_m","dashboard_d","dashboard_m",'creditsummary_d',"creditsummary_m"];
_menuItemsNamePerPage[pages.PERSONALLOANS] = ["articles", "helpcentre","login","my-account","getscore_d", "getscore_m","dashboard_d","dashboard_m",'creditsummary_d',"creditsummary_m"];
_menuItemsNamePerPage[pages.DEALS_HOMELOANS] = ["articles", "helpcentre","login","my-account","getscore_d","dashboard_d",'creditsummary_d',"creditsummary_m"];
_menuItemsNamePerPage[pages.DEALS_CARLOANS] = ["articles", "helpcentre","login","my-account","getscore_d", "getscore_m","dashboard_d","dashboard_m",'creditsummary_d',"creditsummary_m"];
_menuItemsNamePerPage[pages.CREDITCARDSOFFERS] = ["articles", "helpcentre","login","my-account","getscore_d", "getscore_m", "dashboard_d","dashboard_m"];
_menuItemsNamePerPage[pages.HOMELOANCUSTOMERJOURNEY] = ["articles", "helpcentre","login","my-account","dashboard_d","dashboard_m"];

_menuItemsNamePerPage[pages.SCOREMAP] = _menuItemsNamePerPage["DEFAULT"];
_menuItemsNamePerPage[pages.HELPCENTRE] = ["articles","login","my-account","getscore_d", "getscore_m","dashboard_d","dashboard_m"];
_menuItemsNamePerPage[pages.LOGIN] = [];
_menuItemsNamePerPage[pages.CREATEACCOUNT] = [];
_menuItemsNamePerPage[pages.REGISTER] = [];
_menuItemsNamePerPage[pages.MYDETAILSVERIFICATION] = ["dashboard_d","dashboard_m",'creditsummary_d',"my-account"];
// _menuItemsNamePerPage[pages.PREFERENCES] = [];
_menuItemsNamePerPage[pages.ARTICLES] = articlesMenuItems;
_menuItemsNamePerPage[pages.ARTICLE] = articlesMenuItems;
_menuItemsNamePerPage[pages.FAQS] = articlesMenuItems;
_menuItemsNamePerPage[pages.MYDETAILS] = _menuItemsNamePerPage["DEFAULT"];
_menuItemsNamePerPage[pages.CREDITREPORTSUMMARY] = _menuItemsNamePerPage["DEFAULT"];
_menuItemsNamePerPage[pages.PAGENOTFOUND] = [];
_menuItemsNamePerPage[pages.EMAILSENTBLUE] = [];
_menuItemsNamePerPage[pages.VALIDATEEMAILBLUE] = [];
_menuItemsNamePerPage[pages.MYPREFERENCES] = _menuItemsNamePerPage["DEFAULT"];
_menuItemsNamePerPage[pages.HOMEOHNO] = [];
_menuItemsNamePerPage[pages.PARTNERCONSENT] = [];


export const menuItemsNamePerPage = _menuItemsNamePerPage;


export const keyFeatures = [
    {
        key:0,
        title:<h4>Won’t affect Your Score</h4>,
        description:<p>Accessing your credit score via<br/> GetCreditScore won’t negatively <br/>affect your credit score.</p>,
        imgUrl: ImagesPath.ImgIconSvgHome1
    },
    {
        key:1,
        title:<h4>100% free to join</h4>,
        description:<p>We believe access to your<br/> personal credit score is your right,<br/> and an important step on your<br/> way to financial health.</p>,
        imgUrl: ImagesPath.ImgIconSvgHome9
    },
    {
        key:2,
        title:<h4>Get the answers behind<br/> your credit score</h4>,
        description:<p>See your credit report overview<br/> information that is the details<br/> behind your credit score.</p>,
        imgUrl: ImagesPath.ImgIconSvgHome3
    },
    {
        key:3,
        title:<h4>Access to Product Offers</h4>,
        description:<p>Review hundreds of different<br/> product offers for GetCreditScore<br/> members to help you find the offer<br/> that suits you.</p>,
        imgUrl: ImagesPath.ImgIconSvgHome4
    },
    {
        key:4,
        title:<h4>{'Quick & Easy'}</h4>,
        description:<p>It is simple and fast to sign up and<br/> get your free credit score</p>,
        imgUrl: ImagesPath.ImgIconSvgHome8
    },
    {
        key:5,
        title:<h4>Get an idea of how lenders view you</h4>,
        description:<p>Many organisations including<br/> financial, telco and utility providers<br/> may review credit scores as part of<br/> your application.</p>,
        imgUrl: ImagesPath.ImgIconSvgHome6
    },
    {
        key:5,
        title:<h4>{'Safe, Secure & Simple'}</h4>,
        description:<p>Your information is protected via<br/> identity checks and encryption. We<br/> retrieve your credit score directly<br/> from Equifax (formerly Veda).</p>,
        imgUrl: ImagesPath.ImgIconSvgHome7
    }
];


export const gcsProcessSteps =
{
steps:[
    {
    key:"1",
    title:"Get your <b>FREE</b> Credit Score",
    text:"Sign up for your free Credit Score, it takes 60 seconds.",
    hideOnMobile: true
  },
    {
    key:"2",
    title:"Know where you stand *",
    text:"Use our interactive credit score dashboard to understand what's driving your score & how to improve it.",
    hideOnMobile: false
    },
    {
     key:"3",
     title:"Get a better indication of how lenders view you",
     text:"Being aware of your credit score may allow you to negotiate a better rate with some lenders.",
     hideOnMobile: true
    }
],
disclaimer: "* The GetCreditScore (GCS) credit score is based on the Equifax Credit Score Model. Third parties may not use the GCS credit score to assess your credit worthiness."
};

export const gcsHomeFaqs =[
    {
    key:"1",
    title:"What is a Credit Score?",
    text: "A credit score is a number which summarises the credit information from your credit report. Credit scores are one indicator of your credit risk and may be used by credit providers like banks and credit card companies to help understand how responsible you are to lend to. Generally, a higher credit score is considered better as it indicates lower risk. Your credit score from GetCreditScore is generated by Australia’s leading credit bureau, Equifax, and is a number between 0 and 1200.",
    //text: "A credit score is a number that is calculated based on the information in your credit report, which helps credit providers know how responsible you are to lend to. It’s a number that is between 0 and 1200, where a higher number means you are more likely to be able to repay the loan. Equifax, whose credit score is available from Get Credit Score, is Australia’s leading credit reporting body, and has credit information on 18 million Australians, and is used by most lenders and credit providers.",
    //text: "A credit score is a number that helps credit providers know how responsible you are to lend to. It’s between 0 and 1200, where a higher number means you are more likely to be able repay the loan. Equifax, whose credit score is available from GetCreditScore, is Australia’s leading credit reporting body, and has credit information on 18 million Australians, and is used by most lenders and credit providers.",
    hideOnMobile: false
  },
    {
    key:"2",
    title:"How is my credit score calculated?",
    text:"Your credit score* with GetCreditScore is calculated from your current situation and past behaviour as reported to Equifax, Australia’s leading credit bureau. This may include credit limits, your repayment history, overdue debts, how often you’ve asked for credit and the kinds of loans or credit you’ve asked for, the frequency of your credit applications, and several other factors.* Your GetCreditScore credit score is based on the Equifax Credit Score Model",
    hideOnMobile: false
    }
];


export const dashboardtabs = [
    {
      key: 'DashboardScore',
      tabIndex : 0,
      imgUrl: ImagesPath.ImgDashboard_score,
      text:'SCORE',
      widgetName: 'DashboardScore',
      linkCssClass: 'link-touch'
    }
    ,{
      key: 'DashboardScoreHistory',
      tabIndex : 1,
      imgUrl: ImagesPath.ImgDashboard_history,
      text:'HISTORY',
      widgetName: 'DashboardScoreHistory',
      linkCssClass: 'link-touch'
    }
    ,{
      key: 'DashboardScoreNextSteps',
      tabIndex : 2,
      imgUrl: ImagesPath.ImgDashboard_history,
      text:'NEXT STEPS',
      widgetName: 'DashboardScore',
      link: '#dashboard-next-steps-section',
      cssClass: 'hidden-md hidden-lg',
      linkCssClass: 'link-touch'
    }
    ,{
      key: 'DashboardScoreOffers',
      tabIndex : 3,
      imgUrl: ImagesPath.ImgDashboard_compare,
      text:'DEALS',
      widgetName: 'DashboardScore',
      link: '#product-section',
      linkCssClass: 'link-touch'
    }
    // },
    // {
    //   key:'DashboardScoreCompare',
    //   tabIndex : 2,
    //   imgUrl:'Media/images/compare.png',
    //   text:'COMPARE'
    // }
];
