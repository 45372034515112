import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
import creditSummary from '../components/shared/creditSummary';

class CreditSummaryApi {


  

  static getCreditSummaryData() {

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(creditSummary);
        }, delay);
    });
  }



  }


export default CreditSummaryApi;
