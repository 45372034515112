import React from 'react';

const DropwDownListForInput = ({selectedValue, onChange, listValues = ["Yearly", "Monthly", "Fortnightly", "Weekly"]}) => {
  return (
  
  // <div className="input-group-append">
  //   <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</button>
  //   <div className="dropdown-menu">
  //     <a className="dropdown-item" href="#">Action</a>
  //     <a className="dropdown-item" href="#">Another action</a>
  //     <a className="dropdown-item" href="#">Something else here</a>
  //     <div role="separator" className="dropdown-divider"></div>
  //     <a className="dropdown-item" href="#">Separated link</a>
  //   </div>
  // </div>
  
  <div className="input-group-btn" style={{backgroundColor:'#e9ecef'}}>
    <div className="btn-group">
      <button type="button" className="btn dropdown-toggle input-lg" data-toggle="dropdown">
      {selectedValue} <div className="caret d-inline-flex"></div></button>
      <ul className="dropdown-menu" role="menu">
        {listValues.map( (v, p) => <li key={p}><span className="mt-1" href="#" onClick={(evt) => {evt.preventDefault(); onChange(evt, v);}} >{v}</span></li>  )}
      </ul>
    </div>
  </div>
  
  );
};

export default DropwDownListForInput;