import React from 'react';
import {useRef} from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as reportActions from '../../redux/actions/reportActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import FooterSmallSection from '../common/footerSmall';

import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';

import RecentActivities from './RecentActivities.js';
import Accounts from './Accounts.js';
import CreditEnquiries from './CreditEnquiries.js';
import Defaults from './Defaults'
import CourtActions from './CourtActions.js';
import ResolveIssueModal from './shared/ResolveIssueModal';
import AboutCrModal from './shared/AboutCrModal';

import {tonikInitReport} from '../../tonik/report';
//import {initTonikForms} from '../../tonik/shared/forms';

import * as RoutesVariables from '../../config/routesVariables';
import { USERROLES, hasOneRole, getDvsInfoFromRoles } from '../../common/gcsLogic';

import {ErrorWrapper} from './shared/ErrorWrapper';
import {parse} from 'query-string';

class CreditReportSummaryPage extends React.Component { 
    constructor(props, context) {
        super(props, context);
        const params = parse(props.location.search);
        this.state = {           
            resolveIssueModal: false,
            aboutCrModal: false,
            tabIndex: params['step'] ? params['step'] : 2,
            needsDvs: true,
            isDriverLicenceDone: false,
            isPassportDone: false,
            isMedicareDone: false
        };        

        this.onClickGoToDeals = this.onClickGoToDeals.bind(this);   
        this.getPageContent = this.getPageContent.bind(this);        
        this.toggleResolveIssueModal = this.toggleResolveIssueModal.bind(this);
        this.toggleAboutCrModal = this.toggleAboutCrModal.bind(this);
        this.onGoToDVS = this.onGoToDVS.bind(this);
    }

    componentDidMount() {      
      
      //initTonikForms();
      this.props.actions.getTextVariants();
      this.props.actions.isAuthenticated(USERROLES.IdentityVerified).then(e => {

        if(!hasOneRole(e.roles, USERROLES.IdentityVerified) ||
         !hasOneRole(e.roles, USERROLES.TwoFactor)){
          this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteCreditSummary);
         return;
        }
        if(!hasOneRole(e.roles, USERROLES.IsInCohort)){
          this.props.history.push(RoutesVariables.RouteDashboard);
        }

        if(e.roles){
          var ret = getDvsInfoFromRoles(e.roles);
          ret.needsDvs = ret.dvsCount < 2;
          //ret.needsDvs = true;
          this.setState(ret);
        }
  
        this.props.actions.initUser(true).then((initUserResponse) => {            
          this.props.actions.getMenuForPage(pages.CREDITREPORTSUMMARY);                                                       
          this.props.actions.loadReportProfile().then(e => {                                                
            tonikInitReport();
          });
        });
      });

      if(this.state.tabIndex != 1 && this.tabs) {
        var lis = this.tabs.children;
        for(var i = 0; i< lis.length; i++){
          lis[i].className = '';
        }
        
        lis[this.state.tabIndex-1].className = 'current'
      }      
    }

    componentWillReceiveProps(nextProps) {      
        if (nextProps && !nextProps.isAuthenticated) {                
            this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteCreditSummary);
        }            
    }

    onGoToDVS(event){
      event.preventDefault();
      this.props.history.push("/my-details/verification");                    
    }
    
    getPageContent(reportInfo){      

         // return <div className='col-lg-6 col-md-8 col-12 accordion-column'>

      var component = <></>;
        {/* { this.state.tabIndex && this.state.tabIndex == 1 &&
        <RecentActivities
            textVariants={this.props.textVariants}
            accounts={reportInfo["accounts"]}
            enquiries={reportInfo["enquiries"]}
            courtActions={reportInfo["courtActions"]}
        />} */}

        if(this.state.tabIndex){
          if(this.state.tabIndex == 1){
              // component = (<ErrorWrapper header="Please select one option from the menu">
              //                 <div className="w-100">            
              //                 </div>
              //             </ErrorWrapper>);        
                          component = <></>;      
          } else if(this.state.tabIndex == 2){
            component = (<Accounts
                            textVariants={this.props.textVariants}
                            data={reportInfo["accounts"]}
                          />);
          } else if(this.state.tabIndex == 3){
            component = (<CreditEnquiries
                            textVariants={this.props.textVariants}
                            data={reportInfo["enquiries"]}
                          />);
          } else if(this.state.tabIndex == 4){
              component = (<Defaults
                              textVariants={this.props.textVariants}
                              data={reportInfo["accounts"]}
                          />);
          } else if(this.state.tabIndex == 5){
              component = (<CourtActions 
                              textVariants={this.props.textVariants} 
                              data={reportInfo["courtActions"]}
                          />);
          }
        }
                      
        return (component);
    }

    changeTab(e, index) {
      e.preventDefault();
      e.stopPropagation();
      
      var lis = e.currentTarget.parentNode.children;
      for(var i = 0; i< lis.length; i++){
        lis[i].className = '';
      }
      e.currentTarget.className = 'current'
      this.setState({tabIndex: index});
    }    

    onClickGoToDeals(event) {
      event.preventDefault();
      this.props.history.push('/dashboard');      
    }  
    
    toggleResolveIssueModal (isOpen, event) {    
      this.setState({resolveIssueModal: isOpen});
    }
  
    toggleAboutCrModal (isOpen, event) {      
      this.setState({aboutCrModal: isOpen});      
    }

    render() {
      let pageContent = null;
      var reportProfile = this.props.reportProfile;
      var reportInfo = reportProfile && reportProfile.reportInfo;

      if(reportProfile && (reportProfile.isServiceNotAvailableNow || reportProfile.apiFail)) {
        pageContent = <ErrorWrapper header="An error has ocurred">
            <div className="alert alert-danger">
              <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_Equifax_Apply_Down"]} />
            </div>
          </ErrorWrapper>
      }
      else if(reportInfo && reportInfo.issue && reportInfo.issue.isTryLater) {
        pageContent = <ErrorWrapper header="An error has ocurred">
            <div className="alert alert-danger">
              <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_Equifax_Apply_TryLater"]} />
            </div>
          </ErrorWrapper>
      }
      else if(reportInfo && reportInfo.issue && reportInfo.issue.isScoreBadRequest) {
        pageContent = <ErrorWrapper header="An error has ocurred">
            <div className="alert alert-danger">
              <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_Equifax_Apply_BadRequest"]} />
            </div>
          </ErrorWrapper>
      }
      else if(reportInfo && reportInfo.issue && (reportInfo.issue.isNoFile || reportInfo.issue.noReport)) {
        pageContent = <ErrorWrapper header="An error has ocurred">
            <div className="alert alert-danger">
              <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_Equifax_Apply_NoFile"]} />
            </div>
          </ErrorWrapper>
      }      
      else if(reportProfile && (reportProfile.loading || (reportInfo && !reportInfo.loaded))){
        pageContent = <ErrorWrapper header="Your information is loading ...">
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated"
                   role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '75%'}}></div>
                </div>
            </ErrorWrapper>
      }
      else if(this.state.needsDvs || (reportProfile && reportProfile.isIdentityIssue)){
        pageContent =
            <ErrorWrapper header="Identity verification is required">
              <div>
                <div className="alert alert-warning">
                  In order to access this report overview you need to provide 2 identity verifications.
                  Please click the button below to proceed.
                </div>
                <button onClick={(e)=>{this.onGoToDVS(e)}} className="btn btn-primary full-width">Verify Identity</button>
                </div>
            </ErrorWrapper>
      }
      else {
        pageContent = this.getPageContent(reportInfo);
      }

      var accountN = reportInfo &&  reportInfo.accounts ? reportInfo.accounts.length : 0;
      var enquiriesN = reportInfo &&  reportInfo.enquiries ? reportInfo.enquiries.length : 0;
      var courtActionsN = reportInfo &&  reportInfo.courtActions ? reportInfo.courtActions.length : 0;
      var defaulN = reportInfo &&  reportInfo.accounts && reportInfo.accounts.filter(a => a.hasDefaults).length;
      

        return (
          <span>
          <span id="dashboard-menu"></span>
          <div className="page-bg page-recent">          
              <Menu menuItemList={this.props.menuItemList}  user={this.props.userProfile} renderMobile={true}/>

              <div className="nav-aside">
                <ul>
                  {/* <li className="current" onClick={(e)=>this.changeTab(e, 1)}><a href="#">Recent Activities</a></li> */}
                  <li onClick={(e)=>{this.changeTab(e, 1); this.toggleAboutCrModal(true, e);}}><a href="#">{"About Your Credit Overview"}</a></li>
                  <li className="current" onClick={(e)=>this.changeTab(e, 2)}><a href="#">{`Accounts (${accountN})`}</a></li>
                  <li onClick={(e)=>this.changeTab(e, 3)}><a href="#">{`Credit Enquiries (${enquiriesN})`}</a></li>
                  <li onClick={(e)=>this.changeTab(e, 4)}><a href="#">{`Defaults (${defaulN})`}</a></li>
                  <li onClick={(e)=>this.changeTab(e, 5)}><a href="#">{`Court Actions (${courtActionsN})`}</a></li>
                  <li className="button">
                    <button onClick={(e)=>{this.toggleResolveIssueModal(true, e)}} className="btn btn-primary full-width">Something doesn't look right?</button>
                  </li>
                </ul>
              </div>

              <section className="section-recent">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h1>Credit Report Overview</h1>
                    </div>
                  </div>
                </div>
                </section>                
          </div>
          <section className="page-recent-content">
              <div className="container">
              {/* <div className="row">
                <p>Credit Report Overview</p>
              </div> */}
                <div className="row">
                  <div className="col-lg-4 offset-lg-1 col-md-4 column-nav d-none d-md-block">
                    <aside className="nav-aside">
                      <ul id="tabs" ref={this.tabs}>
                        {/* <li  onClick={(e)=>this.changeTab(e, 1)}><a href="#">Recent Activities</a></li> */}
                        <li onClick={(e)=> {this.changeTab(e, 1); this.toggleAboutCrModal(true, e);}}><a href="#">{"About Your Credit Overview"}</a></li>
                        <li className="current" onClick={(e)=>this.changeTab(e, 2)}><a href="#">{`Accounts (${accountN})`}</a></li>
                        <li onClick={(e)=>this.changeTab(e, 3)}><a href="#">{`Credit Enquiries (${enquiriesN})`}</a></li>
                        <li onClick={(e)=>this.changeTab(e, 4)}><a href="#">{`Defaults (${defaulN})`}</a></li>
                        <li onClick={(e)=>this.changeTab(e, 5)}><a href="#">{`Court Actions (${courtActionsN})`}</a></li>
                        <li> {!this.state.needsDvs && <button onClick={(e)=>{this.toggleResolveIssueModal(true,e)}} type="button" className="btn btn-primary full-width">Something doesn't look right?</button>}</li>
                      </ul>
                    </aside>
                  </div>

                  {pageContent}


              {/* Terms */}
              <div className="row">
                <div className="col-12 offset-lg-1">
                  <div className="pb-2 ml-2 mr-2">
                  <span className="terms-text">
                      <p>The Credit Report Overview only relates to your personal credit information. It does not include any commercial related credit enquiries or any other information that may apply to you.<br />
                      If you require your full credit report please visit <a className="reg-link" href="https://www.equifax.com.au/personal/products/credit-and-identity-products"  target="_blank">Equifax</a>.</p>
                    </span>
                  </div>                   
                </div>              
              </div>     

                </div>
              </div>
                       
          </section>
          <FooterSmallSection />
          {this.state.resolveIssueModal && (
          <ResolveIssueModal closeModal={(e) => this.toggleResolveIssueModal(false, e)}></ResolveIssueModal>
          )}
           {this.state.aboutCrModal && (
          <AboutCrModal closeModal={(e) => this.toggleAboutCrModal(false, e)}></AboutCrModal>
          )}
          </span>
        );
    }      
}

CreditReportSummaryPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.CREDITREPORTSUMMARY]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.CREDITREPORTSUMMARY];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    reportProfile : state.reportProfile,
    isAuthenticated: state.userProfile.isAuthenticated,    
    roles: state.userProfile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, trackingActions, layoutActions, userActions, reportActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditReportSummaryPage);
