import React from 'react';
import * as ImagesPath from '../../../config/imagesPath';
import {tonikInitMenu} from '../../../tonik/menu';

const CourtActionsModal = ({ closeModal }) => {

  //tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">
              
            <h4><span>1</span> Information shown for each court action listing:</h4>              
                <ul>
                  <li>Name of credit provider</li>
                  <li>Type of court</li>                  
                  <li>Date</li>
                  <li>Amount</li>                                    
                </ul>  

              <h4><span>2</span> What is a court action?</h4>                                        
              <p>A court action is an order by a court that requires you to pay the credit provider with whom you have an outstanding debt and includes any fees and interest.</p>              
              <p>A court action on your credit report can severely impact your credit worthiness. This is because it indicates that you haven’t been able to pay off your debt and requires legal action to reach an acceptable agreement.</p>
              <p>A court action remains on your credit report for up to five years.</p>              
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default CourtActionsModal;