import UserApi from '../../api/mockUserApi';
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';
import HelperApi from '../../api/HelperApi';
import * as ScoreActions from './scoreActions';
import * as ProductActions from './productActions';
import * as trackingActions from './trackingActions';
import ValidationApi from '../../api/validationApi';

import { hasOneRole } from '../../common/gcsLogic';

export function loginRestoreFromLocalStorage() {
  return {type: types.LOGIN_INITIALIZE_STORE};
}

export function loginSuccess(loginResult) {
  return {type: types.LOGIN_SUCCESS, loginResult};
}

export function updateRegisteringUsername(username, rememberMe) {
  return {type: types.REGISTERING_USERNAME, username, rememberMe};
}

export function createAccountSuccess(accountResult) {
  return {type: types.CREATEACCOUNT_SUCCESS, accountResult};
}

export function registertSuccess(userToRegister, registrationResult) {
  return {type: types.REGISTER_SUCCESS, registration: {userToRegister: userToRegister, registrationResult: registrationResult}};
}

export function tmSuccess(tmResponse) {
  return {type: types.LOGIN_TM_SUCCESS, tmResponse};
}

export function changePasswordSuccess(loginResult) {
  return {type: types.PASSWORDCHANGE_SUCCESS, loginResult};
}

export function loadUserDetailsSuccess(userDetails) {
  return {type: types.LOADUSERDETAILS_SUCCESS, userDetails};
}

export function updateUserDetailsSuccess(userDetails) {
  return {type: types.UPDATEUSERDETAILS_SUCCESS, userDetails};
}

export function isAuthenticatedSuccess(isAuthenticatedResponse) {
  return {type: types.IS_AUTHENTICATED_SUCCESS, isAuthenticatedResponse};
}

export function logoutSuccess(){
    return {type: types.LOGOUT_SUCCESS };
}

export function participatedInPromotionSuccess(){
    return {type: types.PROMOTION_PARTICIPATED };
}

export function initUserSuccess(initUser) {
  return {type: types.INIT_USER_SUCCESS, initUser};
}

export function getPreferencesSuccess(preferences) {
  return {type: types.GET_PREFERENCES_SUCCESS, preferences};
}

export function setPreferencesSuccess(preferences) {
  return {type: types.SET_PREFERENCES_SUCCESS, preferences};
}

export function getExistingCustomerSuccess(existingCustomer) {
  return {type: types.GET_EXISTING_CUSTOMER_SUCCESS, existingCustomer};
}

export function getExistingCustomerError() {
  return {type: types.GET_EXISTING_CUSTOMER_ERROR};
}

export function initApp(urlPrefix) {
  return {type: types.INITAPP, urlPrefix: urlPrefix};
}

export function logout() {
  return (dispatch, getState) => {
    dispatch(logoutSuccess());
    dispatch(ProductActions.forceLoadingProducts());
    dispatch(ScoreActions.resetScoreProfile());
    dispatch(ProductActions.forceLoadingCreditCards());
    return UserApi.logout();
  };
}

export function refreshAuthentication(channel) {
  return (dispatch, getState) => {

    var state = getState();      
    
    return UserApi.refreshToken(state.userProfile, channel).then(loginResult => {               
        return dispatchAfterLogin(dispatch, loginResult);

    }).catch(error => {
        throw(error);
    });
  };
}

export function refreshAuthenticationWithHash(channel) {
  return (dispatch, getState) => {

    var state = getState();      
    return UserApi.refreshTokenWithHash(channel).then(loginResult => {               
        return dispatchAfterLogin(dispatch, loginResult);

    }).catch(error => {
        throw(error);
    });
  };
}

function dispatchAfterLogin(dispatch, loginResult){
  
   var dispatchArray = [];
   dispatchArray.push(dispatch(loginSuccess(loginResult)));
   dispatchArray.push(dispatch(ProductActions.forceLoadingProducts()));
   dispatchArray.push(dispatch(ScoreActions.resetScoreProfile()));
   dispatchArray.push(dispatch(ProductActions.forceLoadingCreditCards()));
   dispatchArray.push(dispatch(trackingActions.trackLogin(loginResult.identifier)));
   dispatchArray.push(dispatch(initUser(true)));
   return Promise.all(dispatchArray).then( r => loginResult);
}


export function login(userCredentials, recaptchaToken) {
  return (dispatch, getState) => {

    let context = userCredentials["context"]; 
    if(context){
      userCredentials.clientId = context;
    }

    let state = getState();

    return UserApi.login(userCredentials, state.userProfile, recaptchaToken).then(loginResult => {
      return dispatchAfterLogin(dispatch,
         Object.assign({}, loginResult, { rememberMe: userCredentials.rememberMe})
      ).then( r => loginResult);

    }).catch(error => {
        //  console.log('login error in action' + JSON.stringify(error));
        throw(error);
    });
  };
}

export function isAuthenticated(requiredRole) {
  return (dispatch, getState) => {

    var state = getState();
    
    return UserApi.isAuthenticated(state.userProfile.csrfToken).then(isAuthenticatedResponse => {
            
             dispatch(isAuthenticatedSuccess(isAuthenticatedResponse));
             
             let currentRoles = isAuthenticatedResponse.roles;
             return {
              isAuthenticated: isAuthenticatedResponse.isAuthenticated,
              identifier: isAuthenticatedResponse.identifier,
              hasRequiredRole: hasOneRole(currentRoles, requiredRole),
              roles: currentRoles
             };

    }).catch(error => {
        
        let response = {isAuthenticated: false, identifier: null};
        dispatch(isAuthenticatedSuccess(response));
        return Promise.resolve(response);
    });
  };
}


export function createAccount(userCredentials) {
    return (dispatch, getState) => {

      return UserApi.createAccount(userCredentials).then(() => {

              dispatch(trackingActions.trackAccountCreated());

               dispatch(createAccountSuccess(Object.assign({},
                 {
                   rememberMe: userCredentials.rememberMe,
                   username: userCredentials.username
                 })));

      }).catch(error => {
          //  console.log('createAccount  error in action' + JSON.stringify(error));
          throw(error);
      });
    };
}


export function initUser(forced = false) {
    return (dispatch, getState) => {

      var state = getState();

      if(!forced && state.userProfile.rnd && state.userProfile.isThemeLoaded){
        return Promise.resolve(state.userProfile);
      }

      var csrfToken = '';
      if(state.userProfile && state.userProfile.csrfToken){
        csrfToken = state.userProfile.csrfToken;
      }

      return UserApi.initUser(csrfToken).then((response) => {

               dispatch(initUserSuccess(response));
               return Promise.resolve(response);

      }).catch(error => {
          //  console.log('createAccount  error in action' + JSON.stringify(error));
          throw(error);
      });
    };
}

export function confirmAccount(email, hash) {
    return (dispatch, getState) => {

      return UserApi.confirmAccount(email, hash).then((r) => {

              dispatch(trackingActions.trackAccountVerified(true));
              return Promise.resolve(r);

      }).catch(error => {
          dispatch(trackingActions.trackAccountVerified(false));
          //  console.log('createAccount  error in action' + JSON.stringify(error));
          throw(error);
      });
    };
}

  export function register(userToRegister ) {
    return (dispatch, getState) => {

      return UserApi.register(userToRegister).then((response) => {
               dispatch(trackingActions.trackUserDetailsCreated());
               dispatch(registertSuccess(userToRegister, response));
               return response;

      }).catch(error => {
          //  console.log('register  error in action' + JSON.stringify(error));
          throw(error);
      });
    };
  }

  export function registerFull(userToRegister, recaptchaToken) {
    return (dispatch, getState) => {

      return UserApi.registerFull(userToRegister, recaptchaToken).then((response) => {

               dispatch(trackingActions.trackUserDetailsCreated());

               dispatch(registertSuccess(userToRegister, response));

      }).catch(error => {
          //  console.log('register  error in action' + JSON.stringify(error));
          throw(error);
      });
    };
  }


    export function tm(isForced = false ) {
      return (dispatch, getState) => {

        var state = getState();
        if(!isForced && (state && state.userProfile && state.userProfile.tm != null)){
          return Promise.resolve();
        }

        return UserApi.tm().then((response) => {

                 dispatch(tmSuccess(response));

        }).catch(error => {
            //  console.log('register  error in action' + JSON.stringify(error));
            throw(error);
        });
    };
  }


    export function changePassword(oldPassword, newPassword) {
      return (dispatch, getState) => {

        var state = getState();

        return UserApi.changePassword(state.userProfile.csrfToken, {
                                   oldPassword: oldPassword,
                                   newPassword: newPassword
                               }).then((response) => {


                                 dispatch(changePasswordSuccess(Object.assign({}, response)));


        }).catch(error => {
            //  console.log('changePassword  error in action' + JSON.stringify(error));
            throw(error);
        });
      };
 }

  export function loadUserDetails(forced=false) {
    return (dispatch, getState) => {

      var state = getState();

      if (!state.userProfile.userDetails || forced) {
        return UserApi.loadUserDetails(state.userProfile.csrfToken).then((userDetails) => {

                 HelperApi.setDateIntoCustomerFromIsoString(userDetails, userDetails.dateOfBirth);
                 dispatch(loadUserDetailsSuccess(userDetails));
                 return userDetails;

        }).catch(error => {
            //  console.log('loadUserDetails  error in action' + JSON.stringify(error));
            throw(error);
        });
      }

      return Promise.resolve();

    };
  }

  export function updateUserDetails(user) {
    return (dispatch, getState) => {

      var state = getState();

      //return UserApi.updateUserDetails(state.userProfile.userDetails).then((response) => {
      return UserApi.updateUserDetails(state.userProfile.csrfToken, user).then((response) => {

               dispatch(ScoreActions.resetScoreProfile());
               dispatch(updateUserDetailsSuccess(user)); // Update with the original request if the update succeded
               dispatch(trackingActions.trackUserDetailsUpdated());
            return response;

      }).catch(error => {

          //  console.log('updateUserDetails  error in action' + JSON.stringify(error));
          throw(error);
      });

    };
  }

  export function validateIdentityDocuments(identityRequest) {
    return (dispatch, getState) => {

      var state = getState();     
      
      return ValidationApi.validateIdentityDocuments(state.userProfile.csrfToken, identityRequest).then(r => {
        
        return r;
                              
      }).catch(error => {
          throw(error);
      });

    };
  }

  export function getIdentityDocumentsValidation(identityRequest) {
    return (dispatch, getState) => {
      var state = getState();     
      
      return ValidationApi.getIdentityDocumentsValidation(state.userProfile.csrfToken, identityRequest).then(r => {
        
        return r;
                              
      }).catch(error => {
          throw(error);
      });
    };
  }

  export function getPreferences(forced = false) {
      return (dispatch, getState) => {

        var state = getState();

        if(!forced && (state.userProfile && state.userProfile.preferences && state.userProfile.preferences.loaded)){
          return null;
        }

        return UserApi.getPreferences(state.userProfile.csrfToken).then((response) => {
                 dispatch(getPreferencesSuccess(response));
                 return response;

        }).catch(error => {
            //  console.log('createAccount  error in action' + JSON.stringify(error));
            throw(error);
        });
      };
  }

  export function setPreferences(preferences, forced = false) {
      return (dispatch, getState) => {

        var state = getState();

        return UserApi.setPreferences(state.userProfile.csrfToken, preferences).then((response) => {
                 dispatch(setPreferencesSuccess(response));
                 //return Promise.resolve(response);

        }).catch(error => {
            //  console.log('createAccount  error in action' + JSON.stringify(error));
            throw(error);
        });
      };
  }

export function getExistingCustomer(extendedCustomer)  {
  return (dispatch, getState) => {
    var state = getState();

    return UserApi.getExistingCustomer(extendedCustomer)
            .then((response) => {
              dispatch(getExistingCustomerSuccess(response));
            }).catch(error => {
              dispatch(getExistingCustomerError());
            });
  };
}

export function getCustomerHomeShowCodeSuccess(code) {
  return {type: types.GET_CUSTOMER_HOMESHOW_CODE_SUCCESS, code};
}

export function getCustomerHomeShowCodeError() {
  return {type: types.GET_CUSTOMER_HOMESHOW_CODE_ERROR};
}

export function getCustomerHomeShowCode() {
  return (dispatch, getState) => {
    var state = getState();
    
    return UserApi.getCustomerHomeShowCode(state.userProfile.csrfToken)
    .then((response) => {
      dispatch(getCustomerHomeShowCodeSuccess(response));
    }).catch(error => {
      dispatch(getCustomerHomeShowCodeError());
    });
  };
}
