import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as pages from '../../shared/pages';

import * as GlobalVariables from '../../../config/configureGlobalVariables';
import LoadingIndicator from '../../common/controls/LoadingIndicator';
import * as funcs from '../../shared/functions';
import * as selectors from '../../../selectors/selectors';
import {noScoreReasons, noScoreReasonsActions} from '../../shared/staticNoScoreReasons';
import {USERROLES, hasOneRole} from '../../../common/gcsLogic';

const RenderArrayOfItems = ({componentArr}) => (

  <div>
    {componentArr.map(component => (
      <span key={component.key}>{component}</span>
    ))}
  </div>
);

const NoScoreTextWithLinks = ({item, actions}) =>
{
    let component = <span></span>;

    let componentArr = [];
    let currentString = item.text;
    let indexOfOccuarance = 0;


    if (currentString.indexOf('{') > -1) {

      while (currentString.indexOf('{')>-1 && currentString.indexOf('}')>-1) {

        let startPlaceholderIndex = currentString.indexOf('{');
        let finishPlaceholderIndex = currentString.indexOf('}');

        let placeholderText = currentString.substring(startPlaceholderIndex, finishPlaceholderIndex+1);
        let placeholderTextWithoutBrackets = currentString.substring(startPlaceholderIndex+1, finishPlaceholderIndex);
        let placeholderLink = null;

        let componentPart = <span key={indexOfOccuarance}
                          dangerouslySetInnerHTML={funcs.createMarkup(currentString.substring(0, startPlaceholderIndex))}></span>;

        componentArr.push(componentPart);

        for(let i=0;i<item.links.length;i++) {
          if (item.links[i].id == placeholderTextWithoutBrackets) {
            placeholderLink = item.links[i];
            break;
          }
        }

        if (placeholderLink) {
          let noScoreActionButton = <NoScoreActionButton key={placeholderLink.id} actions={actions} cls='' item={placeholderLink} />;
          componentArr.push(noScoreActionButton);
        }

        indexOfOccuarance++;

        currentString = currentString.substring(finishPlaceholderIndex + 1);

      }

      let lastPart = <span
                      key={indexOfOccuarance}
                      dangerouslySetInnerHTML={funcs.createMarkup(currentString)}></span>;

      componentArr.push(lastPart);

      component = <RenderArrayOfItems componentArr={componentArr} />;
    }

    else {
      component = <span dangerouslySetInnerHTML={funcs.createMarkup(item.text)}></span>;
    }

    return (component);
}

const NoScoreActionButton = ({item, children, actions, cls='btn btn-info'}) =>
{

    let component = null;
    //let actionText = item.actionPlaceholderText ? item.actionPlaceholderText:item.actionText;

    function handleClick(evt, item) {
        actions.trackNoScoreActionClick(item.trackingLabel);
     }

    if (item.href) {
      component = <a
         href={item.href}
         className={cls}
         onClick={(evt)=>handleClick(evt, item)}
         target="_blank">
            {children}
      </a>;
    }

  else if(item.actionRoute){
    component = <Link
                    onClick={(evt)=>handleClick(evt, item)}
                    className={cls}
                    to={item.actionRoute}>
                        {children}
                    </Link>;
  }

  return (component);
}

const NoScoreReasonItem = ({item, actions}) =>
{

  return (
    <div className="container-fluid noscore-item xs-mt-20 xs-mb-20 md-mt-30 md-mb-30">
      <div className="row mobile-noscore-header">
        {/* <div className="col-lg-1 col-sm-2 col-xs-2 text-left col-phones-6">
            
        </div> */}

        <div className="col-lg-9 col-sm-8 col-xs-8 hidden-phones text-left">
            <NoScoreTextWithLinks item={item} actions={actions} />
        </div>

        <div className="col-lg-3 col-sm-4 col-xs-4 col-phones-6 text-left">        
          <NoScoreActionButton item={item} actions={actions} cls=''>
            <img className="noscore-img" src={item.imgUrl} />
          </NoScoreActionButton>
        </div>
      </div>

      <div className="row hidden-sm hidden-md hidden-lg hidden-xs visible-phones mobile-noscore-content">
        <div className="col-xs-12 text-left">

            <NoScoreTextWithLinks item={item} actions={actions} />
        </div>
      </div>
      </div>
    )
}

export const NoScoreComponent = ({userProfile, scoreInfo, actions, textVariants}) =>
{

  var identityIssuePrefix = "";
  if(scoreInfo.isIdentityIssue){
    identityIssuePrefix = "_inv";
  }

  let title = textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "no_score_title" + identityIssuePrefix];
  let reason = textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "no_score_reason" + identityIssuePrefix];

  let newReasons =  noScoreReasons;
  
  if(userProfile && userProfile.roles){
    if(hasOneRole(userProfile.roles, USERROLES.IdentityNotVerified)){
      newReasons =  noScoreReasons.filter(r => r.verifyIdentity == true);
    }
    else{
      newReasons =  noScoreReasons.filter(r => r.verifyIdentity != true);
    }
  }

      return (
        <div className="no-score" >

          <h2 className="text-center">
            <span className="title" dangerouslySetInnerHTML={funcs.createMarkup(title)}></span>
          </h2>

          <div className ="xs-mt-20 xs-mb-20 text-center subtitle">
            <span dangerouslySetInnerHTML={funcs.createMarkup(reason)}></span>
          </div>

          { newReasons.map( item => <NoScoreReasonItem item={item} actions={actions} key={item.id} />)}

          <p>Once you have followed these steps, please log back into your GetCreditScore account so we can attempt to retrieve the score.</p>

        </div>
      )
};
