import * as constants from './constants';


const DashboardThemeSun = {
  key: 'DashboardThemeSun',
  tabControl: " tab-control-bckgnd-sun ",
  tabContent: " tab-control-score-content-bckgnd-transparent ",
  tabContentNoScore: " tab-control-score-content-bckgnd-bluegradient ",
  menu: " tab-control-menu-bckgnd-opacity ",
  showScoreMessagesOption: "new",
  showNextStepsSection: true,
  hasAnimations: true,
  productDisplayOption: constants.PRODUCT_DISPLAY_REAL_IMG,
  productTitleKey: "product_title",
  showCallToActions: function(that){
    try{
      return that.state.scoreShown || that.props.scoreProfile.scoreInfo.noscore;
    }
    catch(e){
    }

    return true;
  }
};


const DashboardThemeSunFlip = Object.assign({}, DashboardThemeSun,
   {
     key: 'DashboardThemeSunFlip',
     productDisplayOption: constants.PRODUCT_DISPLAY_REAL_IMG_FLIPCARD,
   });

const DashboardThemeSunGrowAlt = Object.assign({}, DashboardThemeSun,
  {
    key: 'DashboardThemeSunGrowAlt',
    productDisplayOption: constants.PRODUCT_DISPLAY_REAL_IMG_GROW_ALT,
  });

const DashboardThemeSunTada = Object.assign({}, DashboardThemeSun,
   {
     key: 'DashboardThemeSunTada',
     productDisplayOption: constants.PRODUCT_DISPLAY_REAL_IMG_TADA,
   });


const DashboardThemeFollio = {
  key: 'DashboardThemeFollio',
  tabControl: "  ",
  tabContent: " tab-control-score-content-bckgnd-bluegradient ",
  tabContentNoScore: " tab-control-score-content-bckgnd-bluegradient ",
  menu: " tab-control-menu-bckgnd-default ",
  showScoreMessagesOption: "old",
  showNextStepsSection: false,
  hasAnimations: false,
  productDisplayOption: constants.PRODUCT_DISPLAY_MOBILE_NORMAL,
  productTitleKey: "product_title",
  showCallToActions: function(that){
    return true;
  }
};

export const GlobalThemes = {};
GlobalThemes.dashboard_themeSun = DashboardThemeSun;
GlobalThemes.dashboard_themeFollio = DashboardThemeFollio;
GlobalThemes.dashboard_themeSunFlip = DashboardThemeSunFlip;
GlobalThemes.dashboard_themeSunGrowAlt = DashboardThemeSunGrowAlt;
GlobalThemes.dashboard_themeSunTada = DashboardThemeSunTada;
