export const countries = 
[{"name":"Ascension Island","alpha3":""},
{"name":"Andorra","alpha3":"AND"},{"name":"United Arab Emirates","alpha3":"ARE"},{"name":"Afghanistan","alpha3":"AFG"},{"name":"Antigua And Barbuda","alpha3":"ATG"},{"name":"Anguilla","alpha3":"AIA"},{"name":"Albania","alpha3":"ALB"},{"name":"Armenia","alpha3":"ARM"},{"name":"Angola","alpha3":"AGO"},{"name":"Antarctica","alpha3":"ATA"},{"name":"Argentina","alpha3":"ARG"},{"name":"American Samoa","alpha3":"ASM"},{"name":"Austria","alpha3":"AUT"},{"name":"Australia","alpha3":"AUS"},{"name":"Aruba","alpha3":"ABW"},{"name":"Åland Islands","alpha3":"ALA"},{"name":"Azerbaijan","alpha3":"AZE"},{"name":"Bosnia & Herzegovina","alpha3":"BIH"},{"name":"Barbados","alpha3":"BRB"},{"name":"Bangladesh","alpha3":"BGD"},{"name":"Belgium","alpha3":"BEL"},{"name":"Burkina Faso","alpha3":"BFA"},{"name":"Bulgaria","alpha3":"BGR"},{"name":"Bahrain","alpha3":"BHR"},{"name":"Burundi","alpha3":"BDI"},{"name":"Benin","alpha3":"BEN"},{"name":"Saint Barthélemy","alpha3":"BLM"},{"name":"Bermuda","alpha3":"BMU"},{"name":"Brunei Darussalam","alpha3":"BRN"},{"name":"Bolivia, Plurinational State Of","alpha3":"BOL"},{"name":"Bonaire, Saint Eustatius And Saba","alpha3":"BES"},{"name":"Brazil","alpha3":"BRA"},{"name":"Bahamas","alpha3":"BHS"},{"name":"Bhutan","alpha3":"BTN"},{"name":"Bouvet Island","alpha3":"BVT"},{"name":"Botswana","alpha3":"BWA"},{"name":"Belarus","alpha3":"BLR"},{"name":"Belize","alpha3":"BLZ"},{"name":"Canada","alpha3":"CAN"},{"name":"Cocos (Keeling) Islands","alpha3":"CCK"},{"name":"Democratic Republic Of Congo","alpha3":"COD"},
{"name":"Central African Republic","alpha3":"CAF"},{"name":"Republic Of Congo","alpha3":"COG"},{"name":"Switzerland","alpha3":"CHE"},{"name":"Cote d'Ivoire","alpha3":"CIV"},{"name":"Cook Islands","alpha3":"COK"},{"name":"Chile","alpha3":"CHL"},{"name":"Cameroon","alpha3":"CMR"},{"name":"China","alpha3":"CHN"},{"name":"Colombia","alpha3":"COL"},{"name":"Clipperton Island","alpha3":""},{"name":"Costa Rica","alpha3":"CRI"},{"name":"Cuba","alpha3":"CUB"},{"name":"Cabo Verde","alpha3":"CPV"},{"name":"Curacao","alpha3":"CUW"},{"name":"Christmas Island","alpha3":"CXR"},{"name":"Cyprus","alpha3":"CYP"},{"name":"Czech Republic","alpha3":"CZE"},{"name":"Germany","alpha3":"DEU"},{"name":"Diego Garcia","alpha3":""},{"name":"Djibouti","alpha3":"DJI"},{"name":"Denmark","alpha3":"DNK"},{"name":"Dominica","alpha3":"DMA"},{"name":"Dominican Republic","alpha3":"DOM"},{"name":"Algeria","alpha3":"DZA"},{"name":"Ceuta, Mulilla","alpha3":""},{"name":"Ecuador","alpha3":"ECU"},{"name":"Estonia","alpha3":"EST"},{"name":"Egypt","alpha3":"EGY"},{"name":"Western Sahara","alpha3":"ESH"},{"name":"Eritrea","alpha3":"ERI"},{"name":"Spain","alpha3":"ESP"},{"name":"Ethiopia","alpha3":"ETH"},{"name":"European Union","alpha3":""},{"name":"Finland","alpha3":"FIN"},{"name":"Fiji","alpha3":"FJI"},{"name":"Falkland Islands","alpha3":"FLK"},{"name":"Micronesia, Federated States Of","alpha3":"FSM"},{"name":"Faroe Islands","alpha3":"FRO"},{"name":"France","alpha3":"FRA"},{"name":"France, Metropolitan","alpha3":""},
{"name":"Gabon","alpha3":"GAB"},{"name":"United Kingdom","alpha3":"GBR"},{"name":"Grenada","alpha3":"GRD"},{"name":"Georgia","alpha3":"GEO"},{"name":"French Guiana","alpha3":"GUF"},{"name":"Guernsey","alpha3":"GGY"},{"name":"Ghana","alpha3":"GHA"},{"name":"Gibraltar","alpha3":"GIB"},{"name":"Greenland","alpha3":"GRL"},{"name":"Gambia","alpha3":"GMB"},{"name":"Guinea","alpha3":"GIN"},{"name":"Guadeloupe","alpha3":"GLP"},{"name":"Equatorial Guinea","alpha3":"GNQ"},{"name":"Greece","alpha3":"GRC"},{"name":"South Georgia And The South Sandwich Islands","alpha3":"SGS"},{"name":"Guatemala","alpha3":"GTM"},{"name":"Guam","alpha3":"GUM"},{"name":"Guinea-bissau","alpha3":"GNB"},{"name":"Guyana","alpha3":"GUY"},{"name":"Hong Kong","alpha3":"HKG"},{"name":"Heard Island And McDonald Islands","alpha3":"HMD"},{"name":"Honduras","alpha3":"HND"},{"name":"Croatia","alpha3":"HRV"},{"name":"Haiti","alpha3":"HTI"},{"name":"Hungary","alpha3":"HUN"},{"name":"Canary Islands","alpha3":""},{"name":"Indonesia","alpha3":"IDN"},{"name":"Ireland","alpha3":"IRL"},{"name":"Israel","alpha3":"ISR"},{"name":"Isle Of Man","alpha3":"IMN"},{"name":"India","alpha3":"IND"},{"name":"British Indian Ocean Territory","alpha3":"IOT"},{"name":"Iraq","alpha3":"IRQ"},{"name":"Iran, Islamic Republic Of","alpha3":"IRN"},{"name":"Iceland","alpha3":"ISL"},{"name":"Italy","alpha3":"ITA"},{"name":"Jersey","alpha3":"JEY"},{"name":"Jamaica","alpha3":"JAM"},{"name":"Jordan","alpha3":"JOR"},{"name":"Japan","alpha3":"JPN"},
{"name":"Kenya","alpha3":"KEN"},{"name":"Kyrgyzstan","alpha3":"KGZ"},{"name":"Cambodia","alpha3":"KHM"},{"name":"Kiribati","alpha3":"KIR"},{"name":"Comoros","alpha3":"COM"},{"name":"Saint Kitts And Nevis","alpha3":"KNA"},{"name":"Korea, Democratic People's Republic Of","alpha3":"PRK"},{"name":"Korea, Republic Of","alpha3":"KOR"},{"name":"Kuwait","alpha3":"KWT"},{"name":"Cayman Islands","alpha3":"CYM"},{"name":"Kazakhstan","alpha3":"KAZ"},{"name":"Lao People's Democratic Republic","alpha3":"LAO"},{"name":"Lebanon","alpha3":"LBN"},{"name":"Saint Lucia","alpha3":"LCA"},{"name":"Liechtenstein","alpha3":"LIE"},{"name":"Sri Lanka","alpha3":"LKA"},{"name":"Liberia","alpha3":"LBR"},{"name":"Lesotho","alpha3":"LSO"},{"name":"Lithuania","alpha3":"LTU"},{"name":"Luxembourg","alpha3":"LUX"},{"name":"Latvia","alpha3":"LVA"},{"name":"Libya","alpha3":"LBY"},{"name":"Morocco","alpha3":"MAR"},{"name":"Monaco","alpha3":"MCO"},{"name":"Moldova","alpha3":"MDA"},{"name":"Montenegro","alpha3":"MNE"},{"name":"Saint Martin","alpha3":"MAF"},{"name":"Madagascar","alpha3":"MDG"},{"name":"Marshall Islands","alpha3":"MHL"},{"name":"Macedonia, The Former Yugoslav Republic Of","alpha3":"MKD"},{"name":"Mali","alpha3":"MLI"},{"name":"Myanmar","alpha3":"MMR"},{"name":"Mongolia","alpha3":"MNG"},{"name":"Macao","alpha3":"MAC"},{"name":"Northern Mariana Islands","alpha3":"MNP"},{"name":"Martinique","alpha3":"MTQ"},{"name":"Mauritania","alpha3":"MRT"},{"name":"Montserrat","alpha3":"MSR"},{"name":"Malta","alpha3":"MLT"},{"name":"Mauritius","alpha3":"MUS"},
{"name":"Maldives","alpha3":"MDV"},{"name":"Malawi","alpha3":"MWI"},{"name":"Mexico","alpha3":"MEX"},{"name":"Malaysia","alpha3":"MYS"},{"name":"Mozambique","alpha3":"MOZ"},{"name":"Namibia","alpha3":"NAM"},{"name":"New Caledonia","alpha3":"NCL"},{"name":"Niger","alpha3":"NER"},{"name":"Norfolk Island","alpha3":"NFK"},{"name":"Nigeria","alpha3":"NGA"},{"name":"Nicaragua","alpha3":"NIC"},{"name":"Netherlands","alpha3":"NLD"},{"name":"Norway","alpha3":"NOR"},{"name":"Nepal","alpha3":"NPL"},{"name":"Nauru","alpha3":"NRU"},{"name":"Niue","alpha3":"NIU"},{"name":"New Zealand","alpha3":"NZL"},{"name":"Oman","alpha3":"OMN"},{"name":"Panama","alpha3":"PAN"},{"name":"Peru","alpha3":"PER"},{"name":"French Polynesia","alpha3":"PYF"},{"name":"Papua New Guinea","alpha3":"PNG"},{"name":"Philippines","alpha3":"PHL"},{"name":"Pakistan","alpha3":"PAK"},{"name":"Poland","alpha3":"POL"},{"name":"Saint Pierre And Miquelon","alpha3":"SPM"},{"name":"Pitcairn","alpha3":"PCN"},{"name":"Puerto Rico","alpha3":"PRI"},{"name":"Palestinian Territory, Occupied","alpha3":"PSE"},{"name":"Portugal","alpha3":"PRT"},{"name":"Palau","alpha3":"PLW"},{"name":"Paraguay","alpha3":"PRY"},{"name":"Qatar","alpha3":"QAT"},{"name":"Reunion","alpha3":"REU"},{"name":"Romania","alpha3":"ROU"},{"name":"Serbia","alpha3":"SRB"},{"name":"Russian Federation","alpha3":"RUS"},{"name":"Rwanda","alpha3":"RWA"},{"name":"Saudi Arabia","alpha3":"SAU"},{"name":"Solomon Islands","alpha3":"SLB"},
{"name":"Seychelles","alpha3":"SYC"},{"name":"Sudan","alpha3":"SDN"},{"name":"Sweden","alpha3":"SWE"},{"name":"Singapore","alpha3":"SGP"},{"name":"Saint Helena, Ascension And Tristan Da Cunha","alpha3":"SHN"},{"name":"Slovenia","alpha3":"SVN"},{"name":"Svalbard And Jan Mayen","alpha3":"SJM"},{"name":"Slovakia","alpha3":"SVK"},{"name":"Sierra Leone","alpha3":"SLE"},{"name":"San Marino","alpha3":"SMR"},{"name":"Senegal","alpha3":"SEN"},{"name":"Somalia","alpha3":"SOM"},{"name":"Suriname","alpha3":"SUR"},{"name":"South Sudan","alpha3":"SSD"},{"name":"São Tomé and Príncipe","alpha3":"STP"},{"name":"USSR","alpha3":""},{"name":"El Salvador","alpha3":"SLV"},{"name":"Sint Maarten","alpha3":"SXM"},{"name":"Syrian Arab Republic","alpha3":"SYR"},{"name":"Swaziland","alpha3":"SWZ"},{"name":"Tristan de Cunha","alpha3":""},{"name":"Turks And Caicos Islands","alpha3":"TCA"},{"name":"Chad","alpha3":"TCD"},{"name":"French Southern Territories","alpha3":"ATF"},{"name":"Togo","alpha3":"TGO"},{"name":"Thailand","alpha3":"THA"},{"name":"Tajikistan","alpha3":"TJK"},{"name":"Tokelau","alpha3":"TKL"},{"name":"East Timor","alpha3":"TLS"},{"name":"Turkmenistan","alpha3":"TKM"},{"name":"Tunisia","alpha3":"TUN"},{"name":"Tonga","alpha3":"TON"},{"name":"Turkey","alpha3":"TUR"},{"name":"Trinidad And Tobago","alpha3":"TTO"},{"name":"Tuvalu","alpha3":"TUV"},{"name":"Taiwan, Province Of China","alpha3":"TWN"},{"name":"Tanzania, United Republic Of","alpha3":"TZA"},{"name":"Ukraine","alpha3":"UKR"},{"name":"Uganda","alpha3":"UGA"},{"name":"United Kingdom","alpha3":""},
{name: "United States Minor Outlying Islands", alpha3: "UMI"},
{name: "United States", alpha3: "USA"},
{name: "Uruguay", alpha3: "URY"},
{name: "Uzbekistan", alpha3: "UZB"},
{name: "Vatican City State", alpha3: "VAT"},
{name: "Saint Vincent And The Grenadines", alpha3: "VCT"},
{name: "Venezuela, Bolivarian Republic Of", alpha3: "VEN"},
{name: "Virgin Islands (British)", alpha3: "VGB"},
{name: "Virgin Islands (US)", alpha3: "VIR"},
{name: "Viet Nam", alpha3: "VNM"},
{name: "Vanuatu", alpha3: "VUT"},
{name: "Wallis And Futuna", alpha3: "WLF"},
{name: "Samoa", alpha3: "WSM"},
{name: "Yemen", alpha3: "YEM"},
{name: "Mayotte", alpha3: "MYT"},
{name: "South Africa", alpha3: "ZAF"},
{name: "Zambia", alpha3: "ZMB"},
{name: "Zimbabwe", alpha3: "ZWE"}]