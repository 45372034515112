import LayoutApi from '../../api/mockLayoutApi';
import ArticlesApi from '../../api/mockArticlesApi.js'
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';

export function loadkeyFeatureListSuccess(keyFeatues) {
  return {type: types.LOAD_KEYFEATURES_SUCCESS, keyFeatues};
}



export function loadkeyFeatureList(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    if(!forced && state.homePage.keyFeatureList && state.homePage.keyFeatureList.length > 0){
      return null;
    }

    return LayoutApi.getKeyFeatures().then(keyFeatues => {
      dispatch(loadkeyFeatureListSuccess(keyFeatues));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadProcessStepsSuccess(keyFeatues) {
  return {type: types.LOAD_PROCESSSTEPS_SUCCESS, keyFeatues};
}

export function loadProcessSteps(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    if(!forced && state.homePage.processSteps && state.homePage.processSteps.length > 0){
      return null;
    }

    return LayoutApi.getGcsProcessSteps().then(processSteps => {
      dispatch(loadProcessStepsSuccess(processSteps));
    }).catch(error => {
      throw(error);
    });
  };
}


export function loadHomeFaqSuccess(gcsHomeFaqs) {
  return {type: types.LOAD_HOMEFAQS_SUCCESS, gcsHomeFaqs};
}

export function loadHomeFaq(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    if(!forced && state.homePage.gcsHomeFaqs && state.homePage.gcsHomeFaqs.length > 0){
      return null;
    }

    return LayoutApi.getHomeFaq().then(gcsHomeFaqs => {
      dispatch(loadHomeFaqSuccess(gcsHomeFaqs));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadingArticlesForHomePageSuccess(articlesLoading) {
  return {type: types.LOADING_DASHBOARD_ARTICLES_SUCCESS, articlesLoading};
}

export function loadArticlesForHomePageSuccess(articles) {
  return {type: types.LOAD_HOMEPAGE_ARTICLES_SUCCESS, articles};
}

export function loadArticlesForHomePage(forced=false) {
  return (dispatch, getState) => {

    var state = getState();   

    if(!forced && state.homePage.articles && state.homePage.articles.length > 0){
      return null;
    }

    dispatch(loadingArticlesForHomePageSuccess(true));

    return ArticlesApi.getArticlesForHome().then(articles => {
      dispatch(loadArticlesForHomePageSuccess(articles));
    }).catch(error => {
      dispatch(loadingArticlesForHomePageSuccess(false));
      throw(error);
    });
  };
}
