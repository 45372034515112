import $ from 'jquery'
import 'bootstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import slick from 'slick-carousel-latest'


export const tonikInitScrollWithAnimation = () => {

// scroll horizontally for mobile menu
// scroll to target function

try{
$('.scrollable').click(function (e) {
  e.preventDefault()
  var target = $(this).attr('href')
  $('body,html').animate({
    scrollTop: $(target).offset().top
  }, 1000)
})
}
catch(e){
  
}
return;
}


export const tonikInitScrollWithAnimationToId = () => {

  // scroll horizontally for mobile menu
  // scroll to target function
try{
  
  $('.scroll-to-id').click(function (e) {
    e.preventDefault()
    var target = $(this).attr('data-scroll-to-id')    
    $('body,html').animate({
      scrollTop: $('#' + target).offset().top
    }, 1000)
  })
}
catch(e){
  
}

 return;
}