import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import FooterSmallSection from '../common/footerSmall'

class DeleteAccountSuccessPage extends React.Component { 
    constructor(props, context) {
        super(props, context);

        this.state = {
          errorPassword: null,
        };
    }

    componentDidMount() {

      this.props.actions.getTextVariants();

      this.props.actions.isAuthenticated().then(e =>
      {
              this.props.actions.getMenuForPage(pages.LOGIN);
              this.props.actions.loadUserDetails(true).catch(e =>
              {
                 if (e.status == 500) {
                    this.setState({ isServerError: true });
                 }
              });
      });

    }

    componentWillReceiveProps(nextProps) {
    }



    render() {
        return (

            <div className="page-template-page-account-delete bg-circle">
              <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block"  activeItem={false} />


            <section className="page-account-delete">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                    <div className="images">
                      <img src={ImagesPath.ImgIconTrash} className="img-fluid" alt=""/>
                    </div>
                    <h1>Your account has been deleted</h1>
                    <p>Thank you for using our services.<br/>
                    We try to meet the expectations of our users and Your feedback will help us improve this app. Please tell us more about your experience.</p>
                    <div className="button">
                      <a href={RoutesVariables.RouteContactUs} className="btn btn-primary">Send us your feedback</a>
                      <a href={RoutesVariables.RouteHome} className="btn btn-secondary">Go to the homepage</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="footer-account">
              <FooterSmallSection></FooterSmallSection>
            </div>

          </div>

        );
    }
    


}

DeleteAccountSuccessPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.LOGIN]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.LOGIN];
     }
   }

   
   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountSuccessPage);
