import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import { createMarkup } from '../../components/shared/functions.js'
import * as GlobalVariables from '../../config/configureGlobalVariables';


const CreditCardsBoxesContent = ({products, textVariants, onProductClick}) => {

  let columnClass = "col-sm-4 col-xs-6";

  var sectionContent = products.map(p =>
     {
         return (
           <ProductItemPreview
               key={p.id} product={p}
               onProductClick={onProductClick}
               columnClass={columnClass}
               textVariants={textVariants}
            />)
     })

  return (

  <div className="row md-mt-15 xs-mt-15 is-table-row">
    {sectionContent}
  </div>
  )
}

CreditCardsBoxesContent.propTypes = {
    textVariants: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    onProductClick: PropTypes.func.isRequired
};

const ProductSectionCompareCards = ({textVariants, onCreditCardComparisonTableClick}) => {

    return (
      <section className="text-center xs-pt-20 md-pb-20 sm-pb-20 xs-pb-20 lg-pb-40">
        <h4 className="md-pb-20 sm-pb-20 xs-pb-20">
          <span dangerouslySetInnerHTML={createMarkup(textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "cc_compare_text"])}></span>
            <strong>
              <a onClick={onCreditCardComparisonTableClick} href={GlobalVariables.WEB_SPA_URL + 'deals/credit-cards'}>here</a>
            </strong>

        </h4>
        <a  onClick={onCreditCardComparisonTableClick}  className="square-btn md-mt-20 sm-mt-20 xs-mt-20"
           href={GlobalVariables.WEB_SPA_URL + 'deals/credit-cards'}>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "cc_btncompare_text"]}</a>
      </section>
    )
}

ProductSectionCompareCards.propTypes = {
      textVariants: PropTypes.object.isRequired,
      onCreditCardComparisonTableClick :  PropTypes.func.isRequired
};



const ProductItemPreviewDescriptionItem = ({feature}) => {

    return (
      <div className="row">
        <div className="col-xs-5 md-mb-10" dangerouslySetInnerHTML={createMarkup(feature.title)}></div>
          <div className="col-xs-7 md-mb-10 content-part-value">
           <span dangerouslySetInnerHTML={createMarkup(feature.value)}></span>
          </div>
      </div>
    )
 };

 ProductItemPreviewDescriptionItem.propTypes = {
     feature: PropTypes.object.isRequired
 };

const ProductItemPreview = ({product, onProductClick, textVariants}) => {

    var linkHref = product.href ? product.href : null;

    return (
      <div className='col-md-4 col-xs-12 md-mb-20 sm-mb-20 xs-mb-20' id={product.id}>
       <div className='credit-card-item'>
        <div className="head-part">
         <img src={product.imgUrl} className="img-responsive" />
        </div>

        <div className="content-part content-part-min-height">
         <h5 className="text-center" dangerouslySetInnerHTML={createMarkup(product.title)}></h5>
         {
           product.features.map( feature => {
               return (<ProductItemPreviewDescriptionItem key={feature.key} feature={feature} />)
           })
         }

        </div>

        <div className="revert-rate">
         <div>Revert Rate {product.revertRate}</div>
        </div>



        <div className="footer-part text-center">
             <a className="cc-square-btn xs-mr-5" target="_blank" href={product.href} onClick={(evt) => onProductClick(evt, product)}>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "btn_Details"]}</a>
             <a className="cc-square-btn details" target="_blank" href={product.href} onClick={(evt) => onProductClick(evt, product)}>{textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "btn_Apply"]}</a>
         </div>

        </div>
       </div>
  )
};

ProductItemPreview.propTypes = {
   product: PropTypes.object.isRequired,
   onProductClick: PropTypes.func.isRequired,
   textVariants: PropTypes.object.isRequired,
};

const CreditCardsBoxes = ({textVariants,
   loading,
   creditcards,
   onProductClick,
   disclaimer=textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "disclaimer"],
 onCreditCardComparisonTableClick}
) => {

  var sectionContent = null;

  if (!creditcards || creditcards.length==0 || loading) {
    sectionContent = <LoadingIndicator
                        textPosition='top'
                        text={textVariants[pages.CREDITCARDSOFFERS + textVariants.textVariantSeparator + "loading_text"]} />
  }
  else {
    sectionContent = <CreditCardsBoxesContent
                          products={creditcards}
                          textVariants={textVariants}
                          onProductClick={onProductClick}
                          title={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "product_header"]}
                          />;
  }

  var sectionCls = 'credit-cards-boxes ' + pages.COMMON_SECTION_PADDING;

    return (

    <section className={sectionCls}>

          <div className="container-fluid left-container-padding right-container-padding product-section">

              {sectionContent}

           <ProductSectionCompareCards textVariants={textVariants} onCreditCardComparisonTableClick={onCreditCardComparisonTableClick} />

          </div>

      </section>
    )
};

CreditCardsBoxes.propTypes = {
    textVariants: PropTypes.object.isRequired,
    creditcards: PropTypes.array.isRequired,
    onProductClick: PropTypes.func.isRequired,
    onCreditCardComparisonTableClick: PropTypes.func.isRequired
};

export default CreditCardsBoxes;
