
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//process.env.REACT_APP_API_BASE_URL;
export const WEB_SPA_URL = process.env.REACT_APP_WEB_SPA_URL;
export const WEB_BASE_URL = process.env.REACT_APP_WEB_BASE_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const MAILCHIMP_RESUBSCRIBELINK = process.env.REACT_APP_MAILCHIMP_RESUBSCRIBELINK;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const IMAGES_URL = CDN_URL + 'images/';
export const SCRIPTS_BASE_URL = CDN_URL + 'scripts/';


export const CONTACTUS_BASE_URL ='https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
export const CONTACTUS_ORGID = '00D28000001zntC';
export const CONTACTUS_RECORDTYPE = '012900000016Bb2';
export const ADDRESS_SERVICE_URL = 'https://uat-api-v2.getcreditscore.com.au/autocomplete/address?address=';
export const SCORE_INCREMENT_STEP = 20;
export const SCORE_RETRY_TIMEOUT = parseInt('' + process.env.REACT_APP_SCORE_RETRY_TIMEOUT, 10) || 1000; //5000;
export const REPORT_RETRY_MAX = parseInt('' + process.env.REACT_APP_REPORT_RETRY_MAX, 10) || 8; // 8
export const SCORE_RETRY_MAX = parseInt('' + process.env.REACT_APP_SCORE_RETRY_MAX, 10) || 5; // 3
export const CREDITCARDS_URL = 'https://www.ratecity.com.au/whitelabels/getcreditscore/credit-cards';
export const PERSOANLLOANS_URL = 'https://www.ratecity.com.au/whitelabels/getcreditscore/personal-loans';
export const CARLOANS_URL = "https://www.ratecity.com.au/whitelabels/getcreditscore/car-loans";

