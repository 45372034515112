import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';

import * as pages from '../../shared/pages';
import * as RoutesVariables from '../../../config/routesVariables';
import * as ImagesPath from '../../../config/imagesPath';
import * as funcs from '../../shared/functions';
import {initTonikForms} from '../../../tonik/shared/forms';

import TextInput from '../../common/controls/TextInput';
import TextNumericInput from '../../common/controls/TextNumericInput';
import DateInputSimple from '../../common/controls/DateInputSimple';

import { medicareCardColour } from '../../../common/dvsMedicare';


export const DvsConsentCheckbox = ({identityRequest, onChange, errors}) => {
  return  ( 
      <>
        <div className="n-dvs-terms-init">By submitting this form,</div>
          <div className="checkbox-field">
            <div className="form-label-group form-checkbox ">
              {/* <span className="reg-title-up reg-title-lbl">Required*</span> */}
              <input
              className="form-check-input"
              type="checkbox"            
              value={identityRequest['consent']}
              name="consent"
              id="consent"
              onChange={onChange}
              />
              <label className="form-check-label" htmlFor="consent">
                I confirm that I am authorised to provide my personal information and I consent to it being checked with the document issuer or official record holder through third party systems for the purpose of confirming my identity.
              </label>
              {errors && errors.consent && (
                  <>
                    <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon n-alert-left-40" alt=""></img>              
                    <div className="invalid-feedback text-danger form-error-msg">
                            {errors.consent}
                    </div>
                  </>
                )}
            </div>
          </div>    
          <div className="checkbox-field">
            <div className="form-label-group form-checkbox ">
              {/* <span className="reg-title-up reg-title-lbl">Required*</span> */}
              <input
              className="form-check-input"
              type="checkbox"            
              value={identityRequest['consent1']}
              name="consent1"
              id="consent1"
              onChange={onChange}
              />
              <label className="form-check-label" htmlFor="consent1">
                I appoint GetCreditScore as an agent and 'Access seeker' to retrieve my Equifax credit reporting information on my behalf.
              </label>
              {errors && errors.consent1 && (
                  <>
                    <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon n-alert-left-40" alt=""></img>              
                    <div className="invalid-feedback text-danger form-error-msg">
                            {errors.consent1}
                    </div>
                  </>
                )}
            </div>
          </div>    
    </>);
};
