import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from './LoginBoxMessage';
import * as pages from '../shared/pages.js';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import UserApi from '../../api/mockUserApi.js';
import LayoutApi from '../../api/mockLayoutApi.js';
import ValidationApi from '../../api/validationApi.js';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import FooterSmallSection from '../common/footerSmall'
import * as RoutesVariables from '../../config/routesVariables';
import {initTonikForms} from '../../tonik/shared/forms';
import ChangePasswordForm from './ChangePasswordForm';
import LoginFrame from './LoginFrame';

import { USERROLES, hasOneRole, getDvsInfoFromRoles } from '../../common/gcsLogic';

export class ChangePasswordPage extends React.Component { 
  constructor(props, context){
         super(props, context);

         this.state = {
             menuItemList: [],
             customer:  {
                oldP: '',
                newP: '',
                confirmP: ''
              },
             errors: {},
             saving: false,
             currentPage: 'changePassword',
             p: null
         };

      this.updateCustomerState = this.updateCustomerState.bind(this);
      this.onChangePassword =  this.onChangePassword.bind(this);
      this.changePasswordIsValid = this.changePasswordIsValid.bind(this);
      this.onOkPasswordChanged = this.onOkPasswordChanged.bind(this);
     }

     componentDidMount() {
        //this.props.actions.getMenuForPage(pages.LOGIN);
        this.props.actions.getTextVariants();

        this.props.actions.isAuthenticated().then(e => {

          if(!hasOneRole(e.roles, USERROLES.OneFactor) &&
             !hasOneRole(e.roles, USERROLES.TwoFactor)){
              this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteChangePassword);
             return;
          }
          // if(!hasOneRole(e.roles, USERROLES.IsInCohort)){
          //   this.props.history.push(RoutesVariables.RouteDashboard);
          // }

          let p = parse(this.props.location.search).p;
          if(p && p.length > 50){
            p = p.substr(0, 50);
          }

          if (p) {
            let customer = this.state.customer;
            customer.hasPassword = true;
            this.setState({p, customer: customer});
          }

          initTonikForms();

       });       
     }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.isAuthenticated) {
        // go back to login
        this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteChangePassword);
      }
    }

     updateCustomerState(event) {
         const field = event.target.name;

         let customer = this.state.customer;
         customer[field] = event.target.value;

         let errors = this.state.errors;
         errors[field] = '';

         return this.setState({ customer: customer, errors:errors });
     }

     onOkPasswordChanged(evt) {
        this.props.history.push(RoutesVariables.RouteCreditScore);
     }

    onChangePassword(evt){
        this.setState({ saving: true });

        evt.preventDefault();

        if (!this.changePasswordIsValid()) {
            this.setState({ saving: false });
            return;
        }

        let errors = {};

        let oldPass = this.state.customer.oldP;
        if(this.state.customer["hasPassword"]){
          oldPass = this.state.p;
        }

        //this.props.actions.
        var that = this;
        this.props.actions.changePassword(oldPass, this.state.customer.newP).then(
            r => {
                //this.setState({ saving: false, currentPage: 'changePasswordSucceed' });
                return that.props.actions.refreshAuthentication().then(r =>{              
                    var navigateUrl = r.redirect_uri || RoutesVariables.RouteDashboard;                     
                    this.props.history.push(navigateUrl);
                });
            
        }).catch(e => {
            if(e.status == 401){
                errors.generic = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_401"];
            }
            else{
                errors.generic = this.props.textVariants["Api_Generic"];
            }

            this.setState({saving: false, errors: errors});
        });

        return false;
    }

     changePasswordIsValid() {

         let formIsValid = true;
         let errors = {};

         //check if old password match value from the input box=password?

         var errorPassword = ValidationApi.validatePassword(this.state.customer.newP);
         if (errorPassword) {
             errors.newP = errorPassword;
             formIsValid = false;
         }

         var errorResetPassword = ValidationApi.validatePassword(this.state.customer.confirmP);
         if (errorResetPassword) {
             errors.confirmP = errorResetPassword;
             formIsValid = false;
         }

         if(!this.state.customer["hasPassword"]){
                 if (!this.state.customer.oldP) {
                   errors.oldP = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_oldPasswordRequired"];
                   formIsValid = false;
                 }
         }

         if (this.state.customer.newP != this.state.customer.confirmP) {
           errors.generic = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_passwordMismatch"];
           formIsValid = false;
         }

         this.setState({errors: errors});

         return formIsValid;
       }

    render() {
      var pageContent = null;

      switch(this.state.currentPage) {

        case 'changePassword':
            pageContent = <ChangePasswordForm
             textVariants={this.props.textVariants}
             onChange={this.updateCustomerState}
             error={this.state.errors}
             customer={this.state.customer}
             onChangePassword={this.onChangePassword}
             saving={this.state.saving}
             isOnlyForm={this.props.isOnlyForm}
            />
        break;

        case 'changePasswordSucceed':
          pageContent = <LoginBoxMessage
          titleText={this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "confirmationTitle"]}
          contentText={this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "confirmationText"]}
          actionName={this.props.textVariants["Btn_Ok"]}
          onActionClick={this.onOkPasswordChanged}
          />
        break;
    }

        return (
            <>
              {/* {this.props.isOnlyForm &&(<>{pageContent}</>)} */}
              {/* {!this.props.isOnlyForm && */}
              <div>
                <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />
                
                <LoginFrame
                disclaimerCtrl={<FooterSmallSection/>}
                >
                  {pageContent}
                </LoginFrame>                                
             </div>
            </>
      )
   }
}

ChangePasswordPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOME]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOME];
     }
   }

 let txtVariants = {};
 if(state.textVariants){
   txtVariants = state.textVariants;
 }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    isAuthenticated: state.userProfile.isAuthenticated,
    userProfile:  state.userProfile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
