import React from 'react';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

class FooterSmallSection extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.year = new Date().getFullYear();
  }

  render() {
    return (
      <footer className="footer-log  position-relative">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-md-12 text-md-center">
                      <p>All Rights Reserved. ©{this.year} GetCreditScore Pty Ltd (GCS) ABN 96 169 886 891, Australian Credit Licence 501455.</p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <ul className="text-md-center">
                        <li><a href={RoutesVariables.RouteExternalTermsOfUse}>Terms and Conditions</a></li>
                        <li><a href={RoutesVariables.RouteExternalPrivacyPolicy}>Privacy Policy </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
    )
  }
}


export default FooterSmallSection;


