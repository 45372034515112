// TODO: adrian this won't work
import $ from 'jquery'
import 'bootstrap'
import ScrollMagic from 'scrollmagic'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

export const tonikInitGlobal = () => {

const COMPONENT_SELECTOR = '[data-sticky]'

let component = document.querySelectorAll(COMPONENT_SELECTOR)
let controller = new ScrollMagic.Controller()

if (window.matchMedia('(min-width: 992px)').matches) {
  window.addEventListener('load', () => {
    for (var i = 0; i < component.length; i++) {
      // eslint-disable-next-line no-new
      new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: component[i].parentElement,
        offset: 0,
        duration: component[i].parentElement.offsetHeight - 2000
        // duration: component[i].parentElement.offsetHeight - component[i].offsetHeight
      })
        .setPin(component[i])
        // .addIndicators()
        .addTo(controller)
    }
  })
}


return;
}
