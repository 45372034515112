import React from 'react';

import WizardErrorMessage from './WizardErrorMessage';
import ProgressActionButtons from './ProgressActionButtons';
import WizardProgressBar from './WizardProgressBar';
import WizardSelectionBoxCollection from './WizardSelectionBoxCollection';

function getWizardStep(wizardStepsDef, searchField, searchValue) {
  let item = null;

  if (wizardStepsDef && searchField) {
    let i = 0;
    let found = false;

    for (i = 0; i < wizardStepsDef.length && !found; i++) {
      if (wizardStepsDef[i][searchField] == searchValue) {
        found = true;
        item = wizardStepsDef[i];
      }
    }
  }

  return item;
}

export const WizardBox = ({wizardStepComponents,
                            wizardStepIndex,
                            wizardStepsDef,
                            previousStep,
                            currentStep,
                            wizardStepState,
                            onUpdate,
                            onCustomAction,
                            onMoveStep,
                            wizardErrorMessage,
                            wizardWarningMessageList,
                            identifier,
                            theme}) => {

  let wizardCurrentStep =  getWizardStep(wizardStepsDef, 'step', currentStep);
  let component = <div></div>;

  if (wizardCurrentStep) {
    let wizardCurrentStepState = wizardStepState["steps"][currentStep];

    component = <div className="wizard-box">
                  <WizardProgressBar
                    wizardStepState={wizardStepState["steps"]}
                    wizardStepsDef={wizardStepsDef}
                    currentStep={currentStep}
                    title={wizardCurrentStep['barTitle']}
                    step={wizardCurrentStep}
                    onAction={onMoveStep}
                    theme={theme}
                  />                  
                  <WizardSelectionBoxCollection
                    wizardStepComponents={wizardStepComponents}
                    step={wizardCurrentStep}
                    previousStep={previousStep}
                    currentStep={currentStep}
                    stepState={wizardCurrentStepState}
                    onUpdate={onUpdate}
                    onCustomAction={onCustomAction}
                    identifier={identifier}
                    theme={theme}
                    wizardStepState={wizardStepState["steps"]}
                    wizardStepIndex={wizardStepIndex}
                    wizardStepsDef={wizardStepsDef}
                    onMoveStep={onMoveStep}
                    message={wizardErrorMessage}
                    wizardWarningMessageList={wizardWarningMessageList}
                  />
                </div>;
  }

  return (component);
}

export default WizardBox;
