
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';
import * as constants from '../shared/constants';
import * as ImagesPath from '../../config/imagesPath';

import LoadingIndicator from '../common/controls/LoadingIndicator';

const $ = window.$;

export const ProductLink = ({product, classCss, children, onProductClick, avoidHandShake = false}) =>
{
      let component = null;

      if(product["hasHandshake"] && !avoidHandShake){
        
        var mode = product["hasPushLead"] ? 'p' : 'd';
        var linkTo = '/partner-refer?productId=' + product.id + '&m=' + mode + (product.isNextStep ? '&isn=1': '');

        component =
         <Link to={linkTo}
           onClick={(evt) => onProductClick(evt, product)}
           target="_blank"
           rel="noopener noreferrer" 
           className={classCss} >
           {children}
        </Link>;
      }
      else if(product.href){

              component =
              <a href={product.href}
                 onClick={(evt) => onProductClick(evt, product)}
                 className={classCss}
                 rel="noopener noreferrer" 
                 target="_blank">
                    {children}
              </a>;
      }
      else if(product.actionRoute){
        component =
         <Link to={product.actionRoute}
           onClick={(evt) => onProductClick(evt, product)}
           className={classCss} >
           {children}
        </Link>;
      }

      return (component);
}

const ProductItemCollapsibleMobilePreview = ({product, onProductClick, textVariants, cssClass="product-box-border-shadow"}) => {

    return (
      <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
      <div className={"product-box " + cssClass}>

        <div className="product-box-header-inline">

            <div className="product-box-header-inline-left">
              <img alt="" className="product-img" src={product.imgUrl} />
              <span className="product-title">{product.title}</span>
            </div>

            <div className="pull-right">
                  <ProductLink
                      onProductClick={onProductClick}
                      product={product}
                      classCss="inline-link"
                    >
                      <button className="btn btn-info pull-right">{textVariants["Btn_Quote"]}</button>
                    </ProductLink>
            </div>

           <div className="clearfix"></div>
        </div>

        <div className="product-box-body ">
          <ProductItemBodyFeatureList
          product={product} />
        </div>

        </div>
      </div>
    )
}

const ProductItemPreviewDescriptionItem = ({feature}) => {
    return (
         <li className={"list-group-item " + feature.classToAppend} >
              <span className="list-group-item-text"> {feature.title} </span>
         </li>)
 };

 ProductItemPreviewDescriptionItem.propTypes = {
     feature: PropTypes.object.isRequired
 };

 const ProductItemBodyFeatureList = ({product}) => {

return (
  <div className="product-box-body-inner">
    <div className="list-container">
      { product.features &&  
     <ul className="list-group list-group-flush text-left">
            {
                product.features.map( feature => {
                    return (<ProductItemPreviewDescriptionItem key={feature.key} feature={feature} />)
                })
            }
        </ul>
    }
      </div>
    </div>

)
};

 const ProductItemPreview = ({product, columnClass, onProductClick, cssProductBox="product-box-border-shadow", productid="", isDashboard = false}) => {
          
     if(!productid){
       productid = product.linkRef;
     }

     if(!isDashboard){
      return (
        <div className="col-lg-4 col-6" id={productid}>
          <div className="offer-box-link">
            <ProductLink
                  onProductClick={onProductClick}
                  product={product}
                  classCss="" >
              <img src={product.imgUrl_Sales} className="img-fluid img-product" alt=""/>
              {product.title}
            </ProductLink>
          </div>
        </div>
      )
     }
     else {
      return (
        <div className="col-lg-4 col-6 current-deal-box" id={productid}>
            <ProductLink
                  onProductClick={onProductClick}
                  product={product}
                  classCss="" >
              <img src={product.imgUrl_Sales} className="img-fluid img-product" alt=""/>
              {product.title}
            </ProductLink>
        </div>
      )
     }

};

ProductItemPreview.propTypes = {
    product: PropTypes.object.isRequired,
    columnClass: PropTypes.string.isRequired,
    onProductClick: PropTypes.func.isRequired
};



const ProductItemPreviewRealImagesForACL = ({product, productsByType, columnClass, onProductClick, onClickSeeMore, cssLink="gcs-grow ", productid = "", index}) => {

  var cssProductBox= "product-box-border-shadow";

  if(!productid){
    productid = product.linkRef;
  }

  var isSeeMoreEnabled = productsByType && productsByType["pt_" + product.productTypeId] && productsByType["pt_" + product.productTypeId].length > 0;

 // cssLink = cssLink + " delay-" + index;

  return (
          <div className={"" +  columnClass} id={productid}>

                  <div className={"product-box  " + cssProductBox}>
                    <div className="product-box-header">
                      <div className="product-box-header-inner">
                        <ProductLink
                          onProductClick={onProductClick}
                          product={product}
                          classCss="" >                           
                            <div className="product-box-header-title">{product.callToAction.salesTitle}</div>
                        </ProductLink>
                        </div>

                        <ProductLink
                           onProductClick={onProductClick}
                           product={product}
                           classCss={" " + cssLink} >
                               <div className="product-box-header-img-container-sales">
                                 <img alt="" src={product.imgUrl_Sales} className="product-box-img-sales" />
                               </div>
                         </ProductLink>
                    </div>
                  
                    {/* <div className="product-box-body hidden-xs">
                      <ProductItemBodyFeatureList
                        product={product} />
                    </div> */}

                    <div className="product-box-footer hidden-xs">
                      <ProductLink
                        onProductClick={onProductClick}
                        product={product}
                        classCss="btn btn-success btn-lg btn-block">
                        <span>{product.callToAction.title}</span>
                      </ProductLink>
                    </div>

                    <div className="product-box-footer hidden-sm hidden-md hidden-lg">
                      <ProductLink
                        onProductClick={onProductClick}
                        product={product}
                        classCss="btn btn-success btn-block">
                        <span>{product.callToAction.title}</span>
                      </ProductLink>
                    </div>

                    <div className="product-box-see-more-wrap">
                    {isSeeMoreEnabled && onClickSeeMore && 
                              <span
                                className="product-box-see-more"                             
                                onClick={(evt) => onClickSeeMore(evt, product)}
                              >or see all</span>
                            }
                            {!isSeeMoreEnabled && 
                              <span
                                style={{visibility: "hidden"}}                                
                              >or see all</span>
                            }
                      </div>

                  </div>             
          </div>
     )
};


 const ProductItemPreviewRealImages = ({product, productsByType, columnClass, onProductClick, onClickSeeMore, cssLink="gcs-grow ", productid = "", index}) => {    

     if(!productid){
       productid = product.linkRef;
     }

     var isSeeMoreEnabled = productsByType && productsByType["pt_" + product.productTypeId] && productsByType["pt_" + product.productTypeId].length > 0;

    // cssLink = cssLink + " delay-" + index;

     return (
             <div className={"" +  columnClass} id={productid}>
                  <div className="product-box">
                          <div className="product-box-header">

                            <ProductLink
                              onProductClick={onProductClick}
                              product={product}
                              classCss={" " + cssLink} >
                                  <div className="product-box-header-img-container-sales">
                                    <img alt="" src={product.imgUrl_Sales} className="product-box-img-sales" />
                                  </div>
                            </ProductLink>

                            <ProductLink
                              onProductClick={onProductClick}
                              product={product}
                              classCss="btn btn-lg btn-block btn-product-action"
                            >
                              <span>{product.callToAction.salesTitle}</span>
                            </ProductLink>

                            {isSeeMoreEnabled && 
                              <span
                                className="product-box-see-more"                             
                                onClick={(evt) => onClickSeeMore(evt, product)}
                              >See more</span>
                            }
                            {!isSeeMoreEnabled && 
                              <span
                                style={{visibility: "hidden"}}                                
                              >See more</span>
                            }

                          </div>
                  </div>
             </div>
        )
};


 const ProductItemPreviewFlipCards = ({product, columnClass, onProductClick, index}) => {

     return (
       <div className={columnClass}>
         <div className="gcs-card-container">
            <div className="gcs-card">

              <div className="gcs-card-side">
                  <ProductItemPreviewRealImages
                      product={product}
                      onProductClick={onProductClick}
                      cssLink=""
                      index={index}
                      productid={product.linkRef}
                   />
              </div>

              <div className="gcs-card-back">
                <ProductItemPreview
                    product={product}
                    onProductClick={onProductClick}
                    productid=""
                    index={index}
                    columnClass=""
                 />
              </div>

            </div>
          </div>
          </div>
        )
};


const ProductsSectionTitle = ({title, titleFeatured, cssClass=" product-section-title ", cssAnimation="  "}) => {

//wow bounceInUp animated
  return (
    <div className={"row " + cssClass + cssAnimation} data-wow-offset="220"  data-wow-duration="1.5s">
        <div className="col-xs-12">
          <div className="text-center sm-mb-20">
            <h2 className="product-section-title-h2">
              <span dangerouslySetInnerHTML={funcs.createMarkup(title)} >
              </span>
            </h2>
            <h3 className="product-section-title-h3">
              <span dangerouslySetInnerHTML={funcs.createMarkup(titleFeatured)} ></span>
            </h3>
           </div>
        </div>
     </div>)
}

ProductsSectionTitle.propTypes = {
    title: PropTypes.string.isRequired
};

const ProductsSectionFooter = ({disclaimer, cssClass=""}) => {
   return (
     <div className={"row md-mt-20 sm-mt-15 xs-mt-10 " + cssClass}>
        <div className="col-xs-12">
          <p className="text-center disclaimer">
          {disclaimer}
          </p>
        </div>
     </div>
)
}
ProductsSectionFooter.propTypes = {
      disclaimer: PropTypes.string.isRequired
};


const ProductsSectionContentOld = ({products, textVariants, onProductClick, displayMobileOption, isDashboard = false}) => {
  var sectionContent = products.map((p, index) => {
         return (
           <ProductItemPreview
               key={p.id}
               product={p}
               onProductClick={onProductClick}
               columnClass={''}
               isDashboard={isDashboard}
            />);
      })

  return sectionContent;
}

const ProductMoreOptionsItem = ({product, onProductClick, classCss="btn btn-success"}) => {


//partnerLogoImgUrl
//partnerTitle
//partnerDescription
//href (prod link)

let styleObj = {};
styleObj.img = "col-sm-2";
styleObj.title = "col-sm-3";
styleObj.desc = "col-sm-4";
styleObj.btn = "col-sm-3";

if(!product.partnerDescription){
  styleObj.title = "col-sm-7";
  styleObj.desc = "hidden";  
}

  return (
    <div className="row product-see-more-list-row">      

        <div className="hidden-xs">
            <div className={styleObj.img}>
              <img src={product.partnerLogoImgUrl} alt="" />
            </div>

            <div className={styleObj.title}>
              <span>{product.partnerTitle}</span>
            </div>

            <div className={styleObj.desc}>
              <span>{product.partnerDescription}</span>
            </div>
            <div className={styleObj.btn}>
                    <ProductLink
                                            onProductClick={onProductClick}
                                            product={product}
                                            classCss={classCss}
                                          >
                                            <span>GET MY DEAL</span>
                    </ProductLink>
            </div> 
        </div> 
        <div className="hidden-sm hidden-md hidden-lg">            

            <div className="col-xs-7">
            <img src={product.partnerLogoImgUrl} alt="" />
            </div>

            <div className="col-xs-5">
                    <ProductLink
                                            onProductClick={onProductClick}
                                            product={product}
                                            classCss={classCss}
                                          >
                                            <span>GET MY DEAL</span>
                    </ProductLink>
            </div> 
        </div>                                    

    </div>
  );
}


const ProductMoreOptionsModal = ({modalId, featuredProduct, productsByType, onProductClick}) => {


  var products = null;
  
  if(featuredProduct && productsByType){
    products = productsByType["pt_" + featuredProduct.productTypeId];
  }

  return (    
       <div className="modal fade"
            id={modalId}
            role="dialog">
          <div className="modal-dialog modal-lg">
          
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Featured Offers</h4>
                </div>
                <div className="modal-body">
                  

                <div className="product-see-more-list container-fluid table table-striped">

                  {products && 
                    products.map( (p, i) =>
                     <ProductMoreOptionsItem
                      key={i} 
                      product={p}
                      onProductClick={onProductClick}
                       />)}                  

                </div> 
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
          </div>
       </div>
  );

};

const ProductsSectionContent = ({products, productsByType, textVariants, onProductClick, onClickSeeMore, displayMobileOption, isDashboard = false}) => {

  let content = <ProductsSectionContentOld
    products={products}
    textVariants={textVariants}
    onProductClick={onProductClick}
    displayMobileOption={displayMobileOption}
    isDashboard = {isDashboard}
  />;

  return content
}

ProductsSectionContent.propTypes = {
    textVariants: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    onProductClick: PropTypes.func.isRequired,
    onClickSeeMore: PropTypes.func.isRequired,
    productsByType: PropTypes.array
};


export default class ProductSection  extends React.Component
{

  constructor(props, context){
      super(props, context);

      this.state = {
        productTypeId: null,
        featuredProduct: null,
        displayingExtraProducts: false
      };
     
      this.modalName = "prod-options";
      this.onProductClickReferralWrapper = this.onProductClickReferralWrapper.bind(this);
      this.onClickSeeMore = this.onClickSeeMore.bind(this);      
      this.closeMoreProducts = this.closeMoreProducts.bind(this);
      this.showMoreProducts = this.showMoreProducts.bind(this);

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState){
  }



  closeMoreProducts(){
    this.setState({displayingExtraProducts : false});
    $('#' + this.modalName).modal('hide');
    //this.clearTimer();
  }

  showMoreProducts(){
      $('#' + this.modalName).modal('show');
      //let that = this;
      //this.timerHandler = setTimeout(() => {that.performReferralFromTimer(product)}, 4000);
  }

  onProductClickReferralWrapper(evt, product){

      this.props.onProductClick(evt, product);

  }

  onClickSeeMore(evt, product){
    
    evt.preventDefault();


    this.setState({
      featuredProduct: product,
      productTypeId: product.productTypeId,
      displayingExtraProducts: true
    }, this.showMoreProducts);

  }

  render(){

    let {children,
    textVariants,
    loading,
    products,
    productsByType,    
    lastProduct,
    onProductClick,
    displayMobileOption,
    title=textVariants["product_header"],
    titleFeatured=textVariants["product_header_featured"],
    disclaimer=textVariants["product_disclaimer"],
    cssClass="",
    isDashboard=false} = this.props;

  var sectionContent = null;
  if (!products || loading) {
    sectionContent = <LoadingIndicator
                        textPosition='top'
                        text={textVariants[pages.PRODUCT + textVariants.textVariantSeparator + "loading_text"]} />
  }
  else {
    sectionContent = <ProductsSectionContent
                          products={products}
                          productsByType={productsByType}
                          textVariants={textVariants}
                          onProductClick={this.onProductClickReferralWrapper}
                          onClickSeeMore={this.onClickSeeMore}
                          displayMobileOption={displayMobileOption}
                          isDashboard = {isDashboard}
                          />;
  }


  if(products.length == 0){
    sectionContent = <div></div>;
  }

  return (
    <div className="row">
      <a id="product-section" />
        {sectionContent}
    </div>
  );

  }
};

ProductSection.contextTypes = {
  router: PropTypes.object
};

ProductSection.propTypes = {
    textVariants: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    onProductClick: PropTypes.func.isRequired,
    displayMobileOption: PropTypes.string,
    productsByType: PropTypes.array
};


const ExtraProductsModal = ({ closeModal, products, onProductClick }) => {

  //tonikInitMenu();

    return (
        <div  className="modal d-block n-prod-modal" role="dialog">          
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid img-product" src={ImagesPath.ImgIconCloseBlack}/></a>
              <span className="prod-header">Other available offers</span>
            </div>
            <div className="modal-body">

            {products && 
              products.map( (product, i) => (
                  <ul className="alerts ">
                    <li>
                      <a href="#">
                        <img src={product.partnerLogoImgUrl} className="img-fluid img-product partner-img d-none d-sm-block" alt="" />
                          {product.partnerTitle}<br /> {product.partnerDescription}
                        <span className="time prod-span">
                            <ProductLink
                              onProductClick={onProductClick}
                              product={product}
                              classCss=""
                            >
                              <button className="btn btn-primary">GET MY DEAL</button>
                            </ProductLink>
                          {/* <img src="images/icons/time.svg" className="img-fluid" alt="" />
                          15 min ago */}
                        </span>
                      </a>
                    </li> 
                  </ul>
              ))
            }                
            </div>
          </div>
        </div>
      </div> 
    );
  };  

export class ProductAdditionalSection  extends React.Component
{
  constructor(props, context){
      super(props, context);

      this.state = {
        productTypeSelected: null,
        productsSelected: [],
        productTypes: [],
        isModalOpen: false                      
      };    
      
      this.onProductTypeClick = this.onProductTypeClick.bind(this);
      this.toggleProductModal = this.toggleProductModal.bind(this);
      this.onProductClickWrapper = this.onProductClickWrapper.bind(this);
  }

  componentDidMount() {    
    var productsByType =  this.props.productsByType;
    var productTypes = [];
    if(productsByType){
      for(var typeIndex in productsByType){
        if(productsByType.hasOwnProperty(typeIndex)){
            if(productsByType[typeIndex] && productsByType[typeIndex] && productsByType[typeIndex].length > 0){
                let prodType = Object.assign({}, productsByType[typeIndex][0]);
                prodType.href = "#";
                prodType.typeKey = typeIndex;
                productTypes.push(prodType);
            }        
        }
      }
      this.setState({productTypes});
    }
  }

  componentDidUpdate(prevProps, prevState){
  }  

  onProductTypeClick(event, prodType){
    event.preventDefault(); // So it doesn't go to the product partner url
    
    // Load those selected products
    this.setState({productTypeSelected : prodType.typeKey, productsSelected : this.props.productsByType[prodType.typeKey]}, () => {
      // Open Modal
      this.toggleProductModal(true);
    });    
  }

  toggleProductModal(isOpen, event){
    this.setState({isModalOpen: isOpen});
  }

  onProductClickWrapper(event, product){
      this.toggleProductModal(false);
      if(this.props.onProductClick){
        this.props.onProductClick(event, product);
      }      
  }

  render(){

    let {
    textVariants,
    loading,    
    productsByType,        
    onProductClick,        
    cssClass="",
    isDashboard=false} = this.props;

  var sectionContent = null;
  if (!productsByType || loading) {
    sectionContent = <LoadingIndicator
                        textPosition='top'
                        text={textVariants[pages.PRODUCT + textVariants.textVariantSeparator + "loading_text"]} />
  }
  else {
    sectionContent = <> 
                      <ProductsSectionContent
                          products={this.state.productTypes}                          
                          textVariants={textVariants}
                          onProductClick={this.onProductTypeClick}
                          isDashboard = {isDashboard}
                          />
                          {this.state.isModalOpen && (
                              <ExtraProductsModal 
                                 closeModal={(e) => this.toggleProductModal(false, e)}
                                 products={this.state.productsSelected}
                                 onProductClick={this.onProductClickWrapper}
                              />                              
                            )}
                    </>;
  }

  if(this.state.productTypes.length == 0){
    sectionContent = <div></div>;
  }

  return (    
    <div className={"row" + cssClass}>
      <a id="product-extra-section" />
        {sectionContent}
    </div>
  );

  }
};
