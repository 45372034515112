import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../../shared/pages';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as GlobalVariables from '../../../config/configureGlobalVariables';
import LoadingIndicator from '../../common/controls/LoadingIndicator';
import * as funcs from '../../shared/functions';
import * as selectors from '../../../selectors/selectors';
import {noScoreReasons, noScoreReasonsActions} from '../../shared/staticNoScoreReasons';
import {NoScoreComponent} from './NoScoreComponent';
import * as ImagesPath from '../../../config/imagesPath';
import * as RoutesVariables from '../../../config/routesVariables';
import Menu from  '../../menu/Menu';


import * as layoutActions from '../../../redux/actions/layoutActions';
import * as scoreActions from '../../../redux/actions/scoreActions';
import * as userActions from '../../../redux/actions/userActions';
import FooterSmallSection from '../../common/footerSmall';

import {USERROLES, hasOneRole} from '../../../common/gcsLogic';

import {tonikInitDashboard} from '../../../tonik/dashboard';
import {initTonikHistory} from '../../../tonik/history';

const c3 = window.c3;

export class DashboardScoreHistory extends React.Component {

    constructor(props, context){
        super(props, context);
        

        this.state = {
          loading: false,
          historyLength: 0,
          mounted: false
        };

        this.historyDoesNotHaveValues = this.historyDoesNotHaveValues.bind(this);
    }

    componentDidMount() {      
      this.setState({loading:true});
      this.props.actions.isAuthenticated(USERROLES.IdentityVerified).then(e =>
      {
        
          this.props.actions.initUser(true).then((initUserResponse) => {
            this.props.actions.getMenuForPage(pages.DASHBOARDHISTORY);
            this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true}).then(() => {
                  tonikInitDashboard();
                  initTonikHistory();
                  this.setState({loading: false})
            });
          });
      });

    }

    componentWillReceiveProps(nextProps) {
      if (nextProps) {
        if (!nextProps.userProfile.isAuthenticated) {
          // go back to login          
          this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteCreditScore);
        }
      }      
    }

    componentDidUpdate(prevProps, prevState){
        //window['initAppJs'](true);
        if(this.state.historyLength == 0){
          var length = this.props.scoreProfile.scoreHistory.length;
          if(length > 0){
            this.setState({historyLength: length});
          }
            
        } else{
          if(!this.state.loading && !this.state.mounted && this.state.historyLength > 0){
            var historyClone = [...this.props.scoreProfile.scoreHistory];
            window['initAllChars'](historyClone);
            this.setState({mounted: true});
          }          
        }
    }


    historyDoesNotHaveValues(scoreHistory){

      let hasValue = false;
      if(scoreHistory && scoreHistory.length > 0)
      {
        for(let i = 0; i < scoreHistory.length && !hasValue; i++){
          if(scoreHistory[i] && scoreHistory[i].score > 0){
            hasValue = true;
          }
        }
      }

      return !hasValue;
    }


render() {
  var desc='';
  var bandHtml = '';
  switch (this.props.scoreProfile.scoreInfo.bandDescription) {
    case 'Below Average':
      desc = this.props.textVariants["DASHBOARD$$belowAverageDescription"];
      bandHtml = 'Below <br /> Average';
      break;

      case 'Average':
      desc = this.props.textVariants["DASHBOARD$$averageDescription"];
      bandHtml = 'Average';
      break;

      case 'Good':
      desc = this.props.textVariants["DASHBOARD$$goodDescription"];
      bandHtml = 'Good';
      break;

      case 'Very Good':
      desc = this.props.textVariants["DASHBOARD$$veryGoodDescription"];
      bandHtml = 'Very <br /> Good';
      break;

      case 'Excellent':
      desc = this.props.textVariants["DASHBOARD$$excellentDescription"];
      bandHtml = 'Excellent';
      break;
  
    default:
      break;
  }
  return (
  <span>
        <span id="dashboard-menu"></span>
    <div className="page-bg page-history">
      <Menu menuItemList={this.props.menuItemList} user={this.props.userProfile} renderMobile={true}/>
            
      <section className="section-history" id="history">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-12 chart">
              <div className="title-wrapper">
              <h1 className="title">{'Score History'}</h1>
              </div>
      
              <div className="chart-tab-wrapper">
                <span>Sort by</span>
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link" id="chart-three-months-tab" data-toggle="pill" href="#chart-three-months" role="tab" aria-controls="chart-three-months" aria-selected="true">3 months</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="chart-month-tab" data-toggle="pill" href="#chart-month" role="tab" aria-controls="chart-month" aria-selected="false">6 months</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" id="chart-year-tab" data-toggle="pill" href="#chart-year" role="tab" aria-controls="chart-year" aria-selected="false">All</a>
                  </li>
                </ul>
      
                <div className="tab-content">                  
      
                  <div className="tab-pane fade" id="chart-three-months" role="tabpanel" aria-labelledby="chart-three-months-tab">
                    <canvas id="chartjs-30"></canvas>
                    <div className="chart-my-score-wrapper text-right  d-none d-sm-block">
                      <dl>
                        <dd>My score</dd>
                        <dt className="large">{this.props.scoreProfile.scoreInfo.score}</dt>
                      </dl>
                      <dl>
                        <dd>Status</dd>
                        <dt className="medium" dangerouslySetInnerHTML={funcs.createMarkup(bandHtml)} ></dt>
                      </dl>
                      {/* <dl>
                      <dd>{this.props.scoreProfile.scoreInfo.scoreDiffSince}</dd>
                       <dt className="small green">{this.props.scoreProfile.scoreInfo.scoreDiff}</dt>
                      </dl> */}
                    </div>
                  </div>

                  <div className="tab-pane fade" id="chart-month" role="tabpanel" aria-labelledby="chart-month-tab">
                    <canvas id="chartjs-20"></canvas>
                    <div className="chart-my-score-wrapper text-right d-none d-sm-block">
                      <dl>
                        <dd>My score</dd>
                        <dt className="large">{this.props.scoreProfile.scoreInfo.score}</dt>
                      </dl>
                      <dl>
                        <dd>Status</dd>
                        <dt className="medium" dangerouslySetInnerHTML={funcs.createMarkup(bandHtml)} ></dt>
                      </dl>
                      {/* <dl>
                      <dd>{this.props.scoreProfile.scoreInfo.scoreDiffSince}</dd>
                       <dt className="small green">{this.props.scoreProfile.scoreInfo.scoreDiff}</dt>
                      </dl> */}
                    </div>
                  </div>
      
                  <div className="tab-pane fade show active" id="chart-year" role="tabpanel" aria-labelledby="chart-year-tab">
                    <canvas id="chartjs-10"></canvas>
                    <div className="chart-my-score-wrapper text-right  d-none d-sm-block">
                      <dl>
                        <dd>My score</dd>
                        <dt className="large">{this.props.scoreProfile.scoreInfo.score}</dt>
                      </dl>
                      <dl>
                        <dd>Status</dd>
                        <dt className="medium" dangerouslySetInnerHTML={funcs.createMarkup(bandHtml)} ></dt>
                      </dl>
                      {/* <dl>
                      <dd>{this.props.scoreProfile.scoreInfo.scoreDiffSince}</dd>
                       <dt className="small green">{this.props.scoreProfile.scoreInfo.scoreDiff}</dt>
                      </dl> */}
                    </div>
                  </div>
      
                </div>
              </div>
              
            </div>
            <div className="col-lg-5 col-md-6 status-scale d-none d-md-block">
              <div className="status-scale-wrapper">
                <h3>EXCELLENT <span className="green" style={{backgroundColor:'#009A44'}}>Score  <strong>853 - 1200</strong></span></h3>
                <p>{this.props.textVariants["DASHBOARD$$excellentDescription"]}</p>
                <h3>VERY GOOD <span className="green" style={{backgroundColor:'#6CC938'}}>Score   <strong>735 - 852</strong></span></h3>
                <p>{this.props.textVariants["DASHBOARD$$veryGoodDescription"]}</p>
                <h3>GOOD <span className="green" style={{backgroundColor:'#F6DD37'}}>Score   <strong>661 - 734</strong></span></h3>
                <p>{this.props.textVariants["DASHBOARD$$goodDescription"]}</p>
                <h3>AVERAGE <span className="green" style={{backgroundColor:'#F89400'}}>Score   <strong>460 - 660</strong></span></h3>
                <p>{this.props.textVariants["DASHBOARD$$averageDescription"]}</p>
                <h3>BELOW AVERAGE <span className="green" style={{backgroundColor:'#F80000'}}>Score   <strong>0 - 459</strong></span></h3>
                <p>{this.props.textVariants["DASHBOARD$$belowAverageDescription"]}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>

  <section className="section-info" style={{marginBottom: '230px'}}>
    <div className="container">
      <div className="row">

       {this.state.loading && (<LoadingIndicator textPosition='top' />)}
       {!this.state.loading && ( <>
          <div className="col-lg-7 col-md-6 col-12">
              <button type="button" className="btn btn-modal d-md-none d-sm-block" data-toggle="modal" data-target="#modal-history">
                <img className="img-fluid" src={ImagesPath.ImgIconInfoBlue}/>
              </button>
              <img className="img-fluid d-md-block d-none" src={ImagesPath.ImgIconInfoBlue}/>
              <h3>{this.props.scoreProfile.scoreInfo.bandDescription + ' ' + this.props.scoreProfile.scoreInfo.bandRange}</h3>
              <p>{desc}</p>
            </div>

            {this.props.isInCohort && <div className="col-lg-7 col-md-6 col-12">
              <button type="button" className="btn btn-modal d-md-none d-sm-block" data-toggle="modal" data-target="#modal-history">
                <img className="img-fluid" src={ImagesPath.ImgIconInfoBlue}/>
              </button>
              <img className="img-fluid d-md-block d-none" src={ImagesPath.ImgIconInfoBlue}/>
              <h3>Credit Score History Update</h3>
              <p>With the introduction of our new comprehensive score, your score history has been reset and your old score has been removed.</p>
            </div>
            }


        </>)}


      </div>
    </div>
  </section>
  <div className="footer-account">
    <FooterSmallSection></FooterSmallSection>
  </div>

  <div className="d-md-none d-sm-block">
    <div id="modal-history" className="modal" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Status scale</h2>
            <a href="#" role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
          </div>
          <div className="modal-body">
            {/* <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id purus erat. Fusce volutpat.</span>
            <h3>EXCELLENT <span className="green">Score  <strong>833 - 1200</strong></span></h3>
            <p>You’re in the top 20% of Equifax’s credit-active population, suggesting it’s HIGHLY UNLIKELY that an adverse event could harm your credit report in the next 12 months. Your odds of keeping a clean file are 5 times better than Equifax’s average population. </p>
            <h3>VERY GOOD <span className="green">Score   <strong>726 - 832</strong></span></h3>
            <p>Your EquifaxScore suggests it's UNLIKELY that you will incur an adverse event in the next 12 months that could harm your credit report. Your odds of keeping a clean credit report are 2 times better than Equifax's average credit-active population. </p>
            <h3>GOOD <span className="green">Score   <strong>622 - 725</strong></span></h3>
            <p>Your EquifaxScore suggests it's LESS LIKELY you will incur an adverse event that could harm your credit report in the next 12 months. Your odds of keeping a clean credit report over this period are better than Equifax's average credit-active population. </p>
            <h3>AVERAGE <span className="green">Score   <strong>510 - 621</strong></span></h3>
            <p>If your EquifaxScore is between 510 to 621, your credit score range is Average. Your EquifaxScore would suggest it's LIKELY that you will incur an adverse event such as a default, bankruptcy or court judgment in the next 12 months.</p>
            <h3>BELOW AVERAGE <span className="green">Score   <strong>0 - 509</strong></span></h3>
            <p>If your credit score is Below Average, you're in the bottom 20% of Equifax's credit-active population, suggesting it's MORE LIKELY that you will incur an adverse event such as a default, bankruptcy or court judgment in the next 12 months.</p> */}
          </div>
        </div>
      </div>
    </div>
  </div>



</span>);

}
};


DashboardScoreHistory.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.DASHBOARDHISTORY]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.DASHBOARDHISTORY];
     }
   }

  let txtVariants = {};
  if(state.textVariants){
    txtVariants = state.textVariants;
  }

  let products = [];
  let productLoading = null;
  if(state.productResult && state.productResult.products){
    products = state.productResult.products;
    productLoading = state.productResult.loading;
  }

  let productsByType = [];  
  if(state.productResult && state.productResult.productsByType){
    productsByType = state.productResult.productsByType;    
  }

  let callsToAction = [...state.dashboardPage.callsToAction];

  let isAnonymous = true;
  if(state.productResult){
    isAnonymous = state.productMetadata.isAnonymous;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    scoreProfile: state.scoreProfile,
    userProfile:  state.userProfile,
    isInCohort: !!hasOneRole(state.userProfile.roles, USERROLES.IsInCohort)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, userActions, layoutActions, scoreActions) , dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardScoreHistory);
