import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

import {initTonikForms} from '../../tonik/shared/forms';
import {tonikInitMonthSlider} from '../../tonik/report';
import CreditEnquiriesModal from './shared/CreditEnquiriesModal';

import {LineShower} from './shared/LineShower';
import {ErrorWrapper} from './shared/ErrorWrapper';

const $ = window.$;


class CreditEnquiries extends React.Component {

  constructor(props, context){
      super(props, context);
      this.onAccordionBtnClick = this.onAccordionBtnClick.bind(this);

      this.state = {
        modal: false,
      }
  }

  componentDidMount() {
    //window['initAppJs'](true);
    initTonikForms();
    
  }

  componentDidUpdate(prevProps, prevState){
    //window['initAppJs'](true);
    initTonikForms();
  }

  openModal () {    
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

  onAccordionBtnClick(event, account) {
    if(account.repaymentHistory && account.repaymentHistory.length > 0){
      //tonikInitMonthSlider();
    }
  }

render(){

    let {textVariants, data} = this.props;
    var enquiries = data;

    if(!enquiries || enquiries.length == 0){
      return (<ErrorWrapper header="Enquiries">
                <div className="alert alert-warning">
                  We couldn't find any enquiries.
                </div>
        </ErrorWrapper>);
    }

    return (
    <div className='col-lg-6 col-md-8 col-12 accordion-column'>
      <span>
        <div id="accordion" className="accordion-reports">
        {
            enquiries && enquiries.map( (enquiry, index) => {
              return (
                <div key={index} className="card">
                  <div className="card-header" id={"heading-" + index}>
                    <h5 className="mb-0">
                      <button
                       className="btn btn-link collapsed"
                       data-toggle="collapse"
                       data-target={"#collapse-" + index}
                       aria-expanded="true"
                       aria-controls={"collapse-" + index} >
                        {enquiry.creditEnquirier} <span>Date of enquiry: <span className="date">{enquiry.enquiryDate} </span></span>
                      </button>
                    </h5>
                    <h2>{enquiry.accountType}</h2>                   
                  </div>

                  <div id={"collapse-" + index} className="collapse" aria-labelledby={"heading-" + index} data-parent="#accordion">
                    <div className="card-body">
                      {enquiry.relationship && <LineShower elementKey="Credit Obligation:" elementValue={enquiry.relationship} />}
                      <span className="green pt-3"><sup>$</sup>{enquiry.enquiryAmount}</span>
                      {enquiry.enquiryClientReference != null &&
                       (<p className="cr_card_special_title">Customer Reference No:<span className="reference-no">{enquiry.enquiryClientReference}</span></p>)
                      }
                      
                      <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>How to read your Credit Enquiries information</a>
                    </div>                   
                  </div>
                </div>
              )
            })
        }

        </div>

        {this.state.modal && (
          <CreditEnquiriesModal closeModal={()=>{this.closeModal()}}></CreditEnquiriesModal>
          )}

      </span>
      </div>
        );
      }
 }

 CreditEnquiries.propTypes = {
    textVariants: PropTypes.object,
    data: PropTypes.array.isRequired
};


export default CreditEnquiries;
