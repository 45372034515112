

export const USERROLES = {
    IdentityVerified:  "IV",
    IdentityNotVerified: "INV",
    IdentityVerifiedCount: "ICV",
    IdentityVerifiedDriverLicence: "IDL",
    IdentityVerifiedPassport: "IPA",
    IdentityVerifiedMedicare: "IME",
    TemporaryPassword: "TP",
    EmailVerified: "EV",
    MobileVerified: "MV",
    OneFactor: "1FA",
    TwoFactor: "2FA",
    IsInCohort: "IHC",
    RoleSeparator: ","
  };
 


export const hasOneRole = (roles, roleToFind) => {
    let hasTheRole = roleToFind == undefined;

    if(roles && !hasTheRole){
        let rs = roles.split(USERROLES.RoleSeparator);
        if(rs){
            let roleFound = null;
            for(let i = 0; i < rs.length && !roleFound; i++){
                if(rs[i] == roleToFind){
                    roleFound = rs[i];
                }
            }

            hasTheRole = roleFound;
        }
    }

    return hasTheRole;
};

export const getDvsInfoFromRoles = (roles) => {
    var ret = {};
    ret.isDriverLicenceDone = hasOneRole(roles, USERROLES.IdentityVerifiedDriverLicence);
    ret.isPassportDone = hasOneRole(roles, USERROLES.IdentityVerifiedPassport);
    ret.isMedicareDone = hasOneRole(roles, USERROLES.IdentityVerifiedMedicare);
    ret.dvsCount = (ret.isDriverLicenceDone ? 1:0) + 
              (ret.isMedicareDone ? 1:0) + 
              (ret.isPassportDone ? 1:0);
    return ret;
}

