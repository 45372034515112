import React from 'react';
import * as funcs from '../../../shared/functions';
import ProgressActionButtons from './ProgressActionButtons';
import WizardErrorMessage from '../WizardCoreComponents/WizardErrorMessage'

const WizardTitle = ({wizardStepState, wizardStepIndex, wizardStepsDef, step}) => {
  let stepTitle = (typeof step.stepTitle === "function") 
                  ? step.stepTitle(wizardStepState, wizardStepIndex, wizardStepsDef) 
                  : step.stepTitle;

  let cssClass = stepTitle ? "" : step.cssClass;

  return (
    <>
    <h2>{stepTitle}</h2>
    <p>{step["stepsInstruction"]}</p>
    </>
  );
};


const WizardSelectionBoxCollection = ({wizardStepComponents,
                                        step,
                                        previousStep,
                                        currentStep,
                                        stepState,
                                        onUpdate,
                                        onCustomAction,
                                        identifier,
                                        theme,
                                        wizardStepState,
                                        wizardStepIndex,
                                        wizardStepsDef,
                                        onMoveStep= null,
                                        message=null,
                                        wizardWarningMessageList=null}) => {

  let Component = wizardStepComponents[step["step"]];

  if (!Component) {
    Component = <div />;
  }

  let cssClass = "";

  if (previousStep != null && previousStep != currentStep) {
    cssClass = ((previousStep < currentStep )? " slideInRight": " slideInLeft") + " animated";
  }


  let classCol = "col-xl-4 offset-xl-4 col-md-6 offset-md-3 col-12";
  let hasImg = false;

  

  if(step["stepDefinition"]["actions"] ){
    if(step["stepDefinition"]["actions"].length > 0 ){
      if(step["stepDefinition"]["actions"][0]["imgUrl"]) {
        classCol=  "col-lg-8 offset-lg-2 col-12";
        hasImg = true;
      }
    }
  }


  return (
  <section key={currentStep} className={"content-form-select pt-0"}>
    <div className="container">
     <div className="row">
      <div className={classCol}> 
          <WizardTitle 
            wizardStepState={wizardStepState} 
            wizardStepIndex={wizardStepIndex} 
            wizardStepsDef={wizardStepsDef}
            step={step} 
          />

        {hasImg && 
          <>
          <form className={"select-one"}>
            <div className="row">
                <Component
                  step={step}
                  stepState={stepState}
                  onUpdate={onUpdate}
                  onCustomAction={onCustomAction}
                  identifier={identifier}
                  cssClass=''
                  theme={theme}
                  wizardStepState={wizardStepState}
                  wizardStepIndex={wizardStepIndex}
                  wizardStepsDef={wizardStepsDef}
                />
              
                { !step["hideNavigation"] &&
                      <ProgressActionButtons
                        step={step}
                        onAction={onMoveStep}
                        stepsState={wizardStepState}
                        currentStepIndex={currentStep}
                        theme={theme}
                        isFootButtons ={true}
                      />
                }
                <WizardErrorMessage
                    message={message}
                    wizardWarningMessageList={wizardWarningMessageList}
                    currentStep={currentStep}
                    wizardStepIndex={wizardStepIndex}
                    wizardStepsDef={wizardStepsDef}
                  />
            </div>
          </form>
          {step["stepSubTitle"] && <span>{step["stepSubTitle"]}</span>}
          </>
        }
        {(!hasImg) && 
          <form className={step["stepDefinition"]["showInCols"]  > 1 ? "multiselect" : ""}>
              <Component
                step={step}
                stepState={stepState}
                onUpdate={onUpdate}
                onCustomAction={onCustomAction}
                identifier={identifier}
                cssClass=''
                theme={theme}
                wizardStepState={wizardStepState}
                wizardStepIndex={wizardStepIndex}
                wizardStepsDef={wizardStepsDef}
              />
            
              { !step["hideNavigation"] &&
                    <ProgressActionButtons
                      step={step}
                      onAction={onMoveStep}
                      stepsState={wizardStepState}
                      currentStepIndex={currentStep}
                      theme={theme}
                      isFootButtons ={true}
                    />
                  }
                  <WizardErrorMessage
                    message={message}
                    wizardWarningMessageList={wizardWarningMessageList}
                    currentStep={currentStep}
                    wizardStepIndex={wizardStepIndex}
                    wizardStepsDef={wizardStepsDef}
                  />
          </form>
        }
        </div>
      </div>
    </div>
  </section>
  );
}

export default WizardSelectionBoxCollection;
