import * as GlobalVariables from '../config/configureGlobalVariables';

class ScoreMapApi {

  static getSA3Areas(csrfToken, searchTerm) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/map/areas';

    let headers = {};
    headers['Content-Type'] = 'application/json';
    if(csrfToken){
      headers['x-gcs-xsrf'] = csrfToken;
    }   
    const options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(searchTerm)
    };

    return fetch(fullUrl, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      else {
        return response.json();
      }
    }).catch(function(error) {
      throw(error);
    });
  }

  static getGeoJsonForArea(csrfToken, sA3Code) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/map/area';

    let headers = {};
    headers['Content-Type'] = 'application/json';
    if(csrfToken){
      headers['x-gcs-xsrf'] = csrfToken;
    }   
    const options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(sA3Code)
    };

    return fetch(fullUrl, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      else {
        return response.json();
      }
    }).catch(function(error) {
      throw(error);
    });
  }

}

export default ScoreMapApi;
