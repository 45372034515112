import React from 'react';
import * as ImagesPath from '../../config/imagesPath';
import {tonikInitMenu} from '../../tonik/menu';

const ResolveIssueModal = ({ closeModal }) => {

  tonikInitMenu();

    return (
        <div id="modal-reports" className="modal d-block" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a onClick={closeModal} role="button" data-dismiss="modal" className="btn btn-close"><img className="img-fluid" src={ImagesPath.ImgIconCloseBlack}/></a>
            </div>
            <div className="modal-body">
              <h3>Resolve an issue on your credit report</h3>
              <p>We retrieve your credit information from Equifax, Australia's leading credit bureau.  If you do not recognise a record, or believe there is an issue on your credit information, you can follow the below steps, however things can stay on your credit file for many years – read more here.</p>
              <h4><span>1</span> Contact the credit provider</h4>
              <p>You can firstly contact the credit provider, such as the bank, telco or utility company, that the error relates to and ask that it be investigated and your credit report amended. Equifax has a list of most credit provider contacts here to help you.</p>
              <h4><span>2</span> Contact Equifax</h4>
              <p>Equifax offers a free service investigating the accuracy of information on your credit report that is in dispute or you think needs fixing.</p>
              <h6>Here’s how it works:</h6>
              <ul>
                <li>Submit your request</li>
                <li>Make a consumer correction - to request a change to the consumer or public record sections of your credit report.</li>
                <li>Make a commercial correction - for a request regarding commercial/company matters.</li>
              </ul>
              <p>We retrieve your credit information from Equifax, Australia's leading credit bureau.  If you do not recognise a record, or believe there is an issue on your credit information, you can follow the below steps, however things can stay on your credit file for many years – read more here.</p>
              <p>We retrieve your credit information from Equifax, Australia's leading credit bureau.  If you do not recognise a record, or believe there is an issue on your credit information, you can follow the below steps, however things can stay on your credit file for many years – read more here.</p>
            </div>
          </div>
        </div>
      </div> 
    )
  }

  export default ResolveIssueModal;