import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from '../login/LoginBoxMessage';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import FooterSmallSection from '../common/footerSmall';

import { USERROLES, hasOneRole, getDvsInfoFromRoles } from '../../common/gcsLogic';

import DvsMedicareForm from './Dvs/DvsMedicareForm';
import DvsDriverLicenceForm from './Dvs/DvsDriverLicenceForm';
import DvsPassportForm from './Dvs/DvsPassportForm';

import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi.js';


import * as GlobalVariables from '../../config/configureGlobalVariables';
import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import { medicareCardColour } from '../../common/dvsMedicare';

import * as RoutesVariables from '../../config/routesVariables';
import * as ImagesPath from '../../config/imagesPath';

import {DVSSelectionForm} from './Dvs/DVSSelectionForm'
import {DvsVerificationFrame} from './Dvs/DvsVerificationFrame'

import {documentSelections} from './Dvs/dvsDocSelections'

class MyDetailsVerificationPage extends React.Component { 
    constructor(props, context) {
        super(props, context);

        this.state = {
            dlRequest:  {
               number: '',
               cardNumber: '',
               stateCode: 'NSW',
               consent: false,
               consent1: false,
               dateOfBirth : { dd: '', mm: '', yy: '' }
            },
            passportRequest: {
               number: '',
               countryCode: 'AUS',
               consent: false,
               consent1: false,
               dateOfBirth : { dd: '', mm: '', yy: '' }
            },
            medicareRequest: {
               number: '',
               referenceNumber: '',
               middleNameOnCard: '',
               dateOfExpiry : { dd: '', mm: '', yy: '' },
               cardColour: medicareCardColour.green,
               consent: false,
               consent1: false ,
               dateOfBirth : { dd: '', mm: '', yy: '' }
            },
            //identityStatusText: hasOneRole(props.roles, USERROLES.IdentityVerified) ? "Identity Verified" : "Identity Not Verified",            
            isDriverLicenceDone : false,
            isPassportDone : false,
            isMedicareDone : false,
            documentSelection: null,
            errors: {},            
            saving: false,
            diplaySavedMessage: false,
            isServerError: false,
            trackingReference: null,
            trackingCount: 0       
        };
        

        this.onInitiateValidationRequest = this.onInitiateValidationRequest.bind(this);
        this.updateIdentityRequetInState = this.updateIdentityRequetInState.bind(this);        
        this.initiateIdVerification = this.initiateIdVerification.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.checkIdentityStatus = this.checkIdentityStatus.bind(this);
        this.validateIdentityDocumentRequest = this.validateIdentityDocumentRequest.bind(this);
        this.selectDocumentType = this.selectDocumentType.bind(this);
        this.selectDocumentsDoneFromRole = this.selectDocumentsDoneFromRole.bind(this);
        this.setUserDetails = this.setUserDetails.bind(this);
    }

    componentDidMount() {

      this.props.actions.getTextVariants();
      
      this.props.actions.isAuthenticated().then(e => {

          this.selectDocumentsDoneFromRole(this.props.roles);

          this.props.actions.getMenuForPage(pages.MYDETAILSVERIFICATION);
          
          var userDetails = this.props.userDetails;

          if(userDetails && userDetails.firstName){
            this.setUserDetails(userDetails);
             
          } else{
            this.props.actions.loadUserDetails(false).then(userDetails => {                
                this.setUserDetails(userDetails);
              }
            );
          }
      
      });                

    }

    setUserDetails(userDetails){
      var dob = { dd: '', mm: '', yy: '' };
      var givenName = userDetails.firstName || '';
      var middleName = userDetails.middleName || '';
      var familyName = userDetails.lastName || '';
      if(userDetails.dob &&userDetails.dob.yy){
          dob = this.props.userDetails.dob;
      }           
      this.setState({ 
        dlRequest: Object.assign({}, this.state.dlRequest, {dateOfBirth: dob, givenName, middleName, familyName} ),
        passportRequest: Object.assign({}, this.state.passportRequest, {dateOfBirth: dob, givenName, middleName, familyName} ),
        medicareRequest: Object.assign({}, this.state.medicareRequest, {dateOfBirth: dob, givenName, middleName, familyName} )
      });  
    }

    selectDocumentsDoneFromRole(roles) {
      
      var ret = getDvsInfoFromRoles(roles);      
      this.setState(ret);
      return ret;
    }

    componentWillReceiveProps(nextProps) {

      if (!nextProps.isAuthenticated) {
          // go back to login
          this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.MyDetailsVerification);
      }      
    }

    checkIdentityStatus(that, trackingReference){
      that.props.actions.getIdentityDocumentsValidation({trackingReference}).then(r =>{

        let errors = that.state.errors;
        let tryAgain = false;
        if(r.isCompleted){
          if(r.isRefreshToken){
            // refresh token (it worked)
            that.props.actions.refreshAuthentication().then(r =>{

              if(r.roles && hasOneRole(r.roles, USERROLES.IdentityVerified)){    
                var navigateUrl = r.redirect_uri;
                if(!navigateUrl){
                      navigateUrl = RoutesVariables.RouteDashboard;
                      var currentDocs = that.selectDocumentsDoneFromRole(r.roles);
                                           
                      if(currentDocs.dvsCount > 1){
                        navigateUrl = RoutesVariables.RouteCreditSummary;
                      }
                }              
                this.props.history.push(navigateUrl);
              }
              else{
                errors["global"] = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_worked"];
                that.setState({errors: errors, saving : false, diplaySavedMessage: false});
              }
            }).catch(e => {
                errors["global"] = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "refresh_identity_fails"];
                that.setState({errors: errors, saving : false, diplaySavedMessage: false});
            });
          }
          else{

            errors["global"] = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed"];
            var genericTop = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed_generic"];;
            var genericEnd = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed_end"];;
            if(that.state.documentSelection == documentSelections.medicare){
              errors["global"] = genericTop + that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed_medicare"] + genericEnd;
              errors["type"] = documentSelections.medicare;
             
            } else if(that.state.documentSelection == documentSelections.passport){
              errors["global"] = genericTop + that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed_passport"] + genericEnd;
              errors["type"] = documentSelections.passport;
            
            } else if(that.state.documentSelection == documentSelections.driverLicence){
              errors["global"] = genericTop + that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "identity_failed_driverlicence"] + genericEnd;
              errors["type"] = documentSelections.driverLicence;
            }                      

            that.setState({errors: errors, saving : false, diplaySavedMessage: false});
          }                  
        } else if(r.isInProgess){          
          tryAgain = true;
        }
        else if(r.isDeferred){
          errors["global"] = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "error_deferred"];
          that.setState({errors: errors, saving : false, diplaySavedMessage: false});
        }  else{
          tryAgain = true;
        }  
        
        if(tryAgain){
            if(that.state.trackingCount < 10){
              that.setState({trackingCount: that.state.trackingCount + 1});
              setTimeout(() => {
                that.checkIdentityStatus(that, trackingReference);
              }, 5000); //GlobalVariables.SCORE_RETRY_TIMEOUT);
            }
            else{
              errors["global"] = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "error_deferred"];
              that.setState({errors: errors, saving : false, diplaySavedMessage: false});
            }
        }

      }).catch(e => {
        let errors = that.state.errors;
        let msg =  null;

        if (e.status == 400) {
          msg = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "400_errorOnUpdate"];
        } else if(e.status == 417){
          if(e.body && e.body.errorMessage){           
             msg = e.body.errorMessage;                          
          }
          
          if(!msg){
            msg = that.props.textVariants[pages.MYDETAILSVERIFICATION + that.props.textVariants.textVariantSeparator + "417"];
          }
        } else if (e.status == 500){
          msg = that.props.textVariants["Api_Generic"];
        }
        else{
          msg = that.props.textVariants["Api_Generic"];
        }

        errors["global"] = msg;
        that.setState({errors: errors, saving : false, diplaySavedMessage: false});
      });
    }

    selectDocumentType(event, documentSelected){      
      this.setState({documentSelection: documentSelected, errors: {}});
    }    
   
    onInitiateValidationRequest(event) {
      event.preventDefault();

      // todo change this part for the other documents
      this.validateIdentityDocumentRequest().then(validationResult => {
        if (validationResult.isValid) {
          this.setState({saving : true, diplaySavedMessage: false, trackingReference: null, errors: {}});
          
          this.initiateIdVerification().then(r => {

            this.setState({trackingReference: r.trackingReference, trackingCount: 0, errors: {}});   
            
            // Set timeout to check when it is finished
            var that = this;
            setTimeout(() => {
              that.checkIdentityStatus(that, r.trackingReference);
          }, GlobalVariables.SCORE_RETRY_TIMEOUT);


          }).catch(e => {
            let errors = this.state.errors;
            let msg =  null;

            if (e.status == 400) {
              msg = this.props.textVariants[pages.MYDETAILSVERIFICATION + this.props.textVariants.textVariantSeparator + "400_errorOnUpdate"];

            } else if(e.status == 417){
              if(e.body){
                if(e.body.isDeferred){
                  msg = this.props.textVariants[pages.MYDETAILSVERIFICATION + this.props.textVariants.textVariantSeparator + "error_deferred"];
                } else if(e.body.isDisabled){
                  msg = this.props.textVariants[pages.MYDETAILSVERIFICATION + this.props.textVariants.textVariantSeparator + "error_disabled"];
                }
              }
              
              if(!msg){
                msg = this.props.textVariants[pages.MYDETAILSVERIFICATION + this.props.textVariants.textVariantSeparator + "417"];
              }

            } else if (e.status == 500){
              msg = this.props.textVariants["Api_Generic"];
            }

            errors["global"] = msg;
            this.setState({errors: errors, saving : false, diplaySavedMessage: false});
          });

        } else {          
          this.setState({errors: validationResult.errors, saving: false});
        }
      });
    }

    initiateIdVerification(){

      var apidlRequest = {};   
      if(this.state.documentSelection == documentSelections.medicare){

        apidlRequest.givenName = this.state.medicareRequest.givenName;
        apidlRequest.middleName = this.state.medicareRequest.middleName;
        apidlRequest.familyName = this.state.medicareRequest.familyName;        
        var dateParsed = HelperApi.getInnerDateOfBirthAndDobFromNumbers(this.state.medicareRequest.dateOfBirth.yy, this.state.medicareRequest.dateOfBirth.mm, this.state.medicareRequest.dateOfBirth.dd);        
        apidlRequest.dateOfBirth = dateParsed.dateTypeObj;

        apidlRequest.medicare =  {
          referenceNumber: this.state.medicareRequest.referenceNumber,
          number: this.state.medicareRequest.number,
          middleNameOnCard: this.state.medicareRequest.middleNameOnCard,
          dateOfExpiry: this.state.medicareRequest.dateOfExpiry.yy + '-' +
                        this.state.medicareRequest.dateOfExpiry.mm +
                        ((this.state.medicareRequest.cardColour == medicareCardColour.green)? '': ('-' + this.state.medicareRequest.dateOfExpiry.dd )),
          cardColour: this.state.medicareRequest.cardColour
        };
      } else if(this.state.documentSelection == documentSelections.passport){

        apidlRequest.givenName = this.state.passportRequest.givenName;
        apidlRequest.middleName = this.state.passportRequest.middleName;
        apidlRequest.familyName = this.state.passportRequest.familyName;
        var dateParsed = HelperApi.getInnerDateOfBirthAndDobFromNumbers(this.state.passportRequest.dateOfBirth.yy, this.state.passportRequest.dateOfBirth.mm, this.state.passportRequest.dateOfBirth.dd);        
        apidlRequest.dateOfBirth = dateParsed.dateTypeObj;

        apidlRequest.passport =  {
          countryCode: this.state.passportRequest.countryCode,
          number: this.state.passportRequest.number
        };
      } else if(this.state.documentSelection == documentSelections.driverLicence){

          apidlRequest.givenName = this.state.dlRequest.givenName;
          apidlRequest.middleName = this.state.dlRequest.middleName;
          apidlRequest.familyName = this.state.dlRequest.familyName;
          var dateParsed = HelperApi.getInnerDateOfBirthAndDobFromNumbers(this.state.dlRequest.dateOfBirth.yy, this.state.dlRequest.dateOfBirth.mm, this.state.dlRequest.dateOfBirth.dd);        
          apidlRequest.dateOfBirth = dateParsed.dateTypeObj;
  
          apidlRequest.driversLicence =  {
            stateCode: this.state.dlRequest.stateCode,
            number: this.state.dlRequest.number,
            cardNumber: this.state.dlRequest.cardNumber
          };
      }          
      
      return this.props.actions.validateIdentityDocuments(apidlRequest);
    }

    validateIdentityDocumentRequest(){
      
      if(this.state.documentSelection == documentSelections.medicare)
        return ValidationApi.validateIdentityRequestMedicarePromise(this.state.medicareRequest);
      else if(this.state.documentSelection == documentSelections.passport)
        return ValidationApi.validateIdentityRequestPassportPromise(this.state.passportRequest);
      else if(this.state.documentSelection == documentSelections.driverLicence)
        return ValidationApi.validateIdentityRequestDLPromise(this.state.dlRequest);

      return Promise.resolve({isValid: false, validationResult: {errors:['Document not selected']}});
    }   

    updateIdentityRequetInState(event) {
   
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      if(this.state.documentSelection == documentSelections.passport){
        const field = event.target.name;
        let passportRequest = this.state.passportRequest;        
        passportRequest[field] = value;
        let errors = this.state.errors;
        errors[field] = '';
        return this.setState({ passportRequest: passportRequest, errors:errors });

      } else if(this.state.documentSelection == documentSelections.medicare){
        const field = event.target.name;
        let medicareRequest = this.state.medicareRequest;
        medicareRequest[field] = value;
        let errors = this.state.errors;
        errors[field] = '';
        return this.setState({ medicareRequest: medicareRequest, errors:errors });

      } else if(this.state.documentSelection == documentSelections.driverLicence){
        const field = event.target.name;
        let dlRequest = this.state.dlRequest;
        dlRequest[field] = value;
        let errors = this.state.errors;
        errors[field] = '';
        return this.setState({ dlRequest: dlRequest, errors:errors });
      }                      
    }   

    onCancel(event){
      event.preventDefault();
      this.props.history.push(RoutesVariables.RouteDashboard);
    }

    render() {

      let pageContent = null;

      if (this.state.isServerError) {
        pageContent = <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_RetrievingProfile_Error"]} />;
      }
      else if(!this.props.roles){
        pageContent =
                <LoadingIndicator
                  textPosition='bottom'
                  text={this.props.textVariants[pages.MYDETAILS + this.props.textVariants.textVariantSeparator + "loading_text"]}
                  size='200' />;
      }
      else {
               
        let isInCohort = hasOneRole(this.props.roles, USERROLES.IsInCohort);
        let componentGenericProperties = {
          onSave: this.onInitiateValidationRequest,
          onChange: this.updateIdentityRequetInState,
          errors: this.state.errors,
          saving: this.state.saving,          
          textVariants: this.props.textVariants          
        }

        let currentDocumentForm = <DVSSelectionForm 
                                    onDocumentSelect={this.selectDocumentType}
                                    isDriverLicenceDone={this.state.isDriverLicenceDone}
                                    isPassportDone={this.state.isPassportDone}
                                    isMedicareDone={this.state.isMedicareDone}
                                     />;
        if(this.state.documentSelection == documentSelections.medicare)
          currentDocumentForm = <DvsMedicareForm identityRequest={this.state.medicareRequest} onBack={() => this.selectDocumentType(null)} {...componentGenericProperties} />;
        else if(this.state.documentSelection == documentSelections.passport)
          currentDocumentForm = <DvsPassportForm identityRequest={this.state.passportRequest} onBack={() => this.selectDocumentType(null)} {...componentGenericProperties} />;
        else if(this.state.documentSelection == documentSelections.driverLicence)
          currentDocumentForm = <DvsDriverLicenceForm identityRequest={this.state.dlRequest} onBack={() => this.selectDocumentType(null)} {...componentGenericProperties} />;        

        pageContent = 
            <><DvsVerificationFrame
               classTop={this.state.documentSelection != null ? 'section-form-log page-medicare': 'section-form-log page-verification'}
               documentSelection={this.state.documentSelection}
               onBack={() => this.selectDocumentType(null)}
               isDriverLicenceDone={this.state.isDriverLicenceDone}
               isMedicareDone={this.state.isMedicareDone}
               isPassportDone={this.state.isPassportDone}
               isInCohort={isInCohort}
             >
              {currentDocumentForm}    
            </DvsVerificationFrame>
            <FooterSmallSection />
            </>
            ; 

      }

        return (
          <div >
            <Menu blueIcons={true} logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />              
              {pageContent}
          </div>
        );
    }
}

MyDetailsVerificationPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.MYDETAILSVERIFICATION]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.MYDETAILSVERIFICATION];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,    
    isAuthenticated: state.userProfile.isAuthenticated,
    userDetails: state.userProfile.userDetails,
    roles: state.userProfile.roles    
    //roles: `${USERROLES.IsInCohort},${USERROLES.TwoFactor}`
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDetailsVerificationPage);
