import { combineReducers } from 'redux';
//import { routerReducer } from 'react-router-redux';

import products from './productReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import menu from './menuReducer';
import user from './userReducer';
import homePage from './homePageReducer';
import dashboardPage from './dashboardPageReducer';
import score from './scoreReducer';
import report from './reportReducer';
import textVariantReducer from './textVariantReducer';
import creditSummaryReducer from './creditSummaryReducer';
import creditCards from './creditCardReducer';
import productsMetadata from './productsMetadataReducer';

const rootReducer = combineReducers({
  menu: menu,
  textVariants: textVariantReducer,
  creditSummary: creditSummaryReducer,
  userProfile: user,
  productResult: products,
  productMetadata: productsMetadata,
  homePage: homePage,
  dashboardPage: dashboardPage,
  scoreProfile: score,
  reportProfile: report,
  creditCardsResult: creditCards,
  ajaxCallsInProgress: ajaxCallsInProgress
  //routing: routerReducer
});

export default rootReducer;