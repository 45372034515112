
import * as ImagesPath from '../../config/imagesPath';


export const articlesCategories = [
  {
      id: 'financial',
      name:'FINANCIAL'
  },
  {
      id: 'saving',
      name:'SAVINGS'
  },
  {
      id: 'credithealth',
      name:'CREDIT HEALTH'
  }
];

export const articles = [
  {
      "id": "0",
      "title": "5 ways to improve your credit score",
      "seoName":"5-ways-to-improve-your-credit-score",
      "imgUrl": ImagesPath.ImgArticles_5waystoimproveyourcreditscore,
      "category": { "id":"credithealth", "name":"CREDIT HEALTH"},
      "text":"These five steps to improving your credit score can help you turn your score into an Excellent credit score!"
  },
  {
      "id": "1",
      "title":"Credit Score Myths",
      "seoName":"credit-score-myths",
      "imgUrl": ImagesPath.ImgArticles_mythsvsfacts,
      "category": { "id":"financial", "name":"FINANCIAL"},
      "text":"With so much information available online, it can be tough to separate fact from fiction. To help you cut through the noise, we’ve busted the eight biggest credit score myths."
  },{
      "id": "2",
      "title":"Credit Score Infographic - How is your credit score calculated?",
      "seoName":"credit-score-infographic-how-is-your-credit-score-calculated",
      "imgUrl":ImagesPath.ImgArticles_infographics,
      "category": { "id":"credithealth", "name":"CREDIT HEALTH"},
      "text":"Have you ever wondered what actually makes up your credit score? Find out here."
  },{
      "id": "3",
      "title":"Free Credit Score | Discover Yours Now",
      "seoName":"free-credit-score-discover-yours-now",
      "imgUrl":ImagesPath.ImgArticles_discoveryourcreditscore,
      "category": { "id":"financial", "name":"FINANCIAL"},
      "text":"Did you know its super simple to find out your credit score? You don’t need to be credit savvy to find yours; it takes less than 60 seconds and is 100% free."
  },
  {
      "id": "4",
      "title": "What Comprehensive Credit Reporting means for you and your credit score",
      "seoName": "everything-to-know-about-comprehensive-credit-reporting",
      "imgUrl": ImagesPath.ImgArticles_comprehensivecreditreporting,
      "category": { "id":"credithealth", "name":"CREDIT HEALTH"},
      "text": "Everything to know about Comprehensive Credit Reporting."
  },
  {
      "id": "5",
      "title":"How To Understand Your Credit Score",
      "seoName":"understanding-your-credit-score",
      "imgUrl":ImagesPath.ImgArticles_understandyourcreditscore,
      "category": { "id":"credithealth", "name":"CREDIT HEALTH"},
      "text":"Learn more about your credit score and understand how your credit score is calculated, as it could help boost your confidence when you apply for credit."
  },{
      "id": "6",
      "title":"Generation buY; why not all hope is lost for us",
      "seoName":"generation-buy-why-not-all-hope-is-lost-for-us",
      "imgUrl":ImagesPath.ImgArticles_generationbuy,
      "category": { "id":"credithealth", "name":"CREDIT HEALTH"},
      "text":"Many Gen Y's believe they might never own their own home due to the skyrocketing house prices in major Australian cities."
  },
  {
      "id": "7",
      "title": "From Bondi to Bolivia; how to save for a trip of a lifetime",
      "seoName": "from-bondi-to-bolivia-how-to-save-for-a-trip-of-a-lifetime",
      "imgUrl": ImagesPath.ImgArticles_bonditobolivia,
      "category": { "id":"financial", "name":"FINANCIAL"},
      "text": "From the classroom, to the Uni lecture theatre, to your office desk – there is so much more of the world to see."
  },
  {
      "id": "8",
      "title": "Could your partner’s financial behaviours be impacting your future?",
      "seoName": "could-your-partner-s-financial-behaviours-be-impacting-your-future",
      "imgUrl": ImagesPath.ImgArticles_partnersbehaviour,
      "category": { "id":"financial", "name":"FINANCIAL"},
      "text": "What do you look for in a partner? A kind heart? A good sense of humour? Good financial habits?"
  }
];

export const articlesLearn =
{
    title:'Learn from the experts',
    article: articles[0],
    articles: [articles[1],
               articles[2],
             articles[4],
           ]
};
