import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as reducerHelper from './reducerHelper';


export default function productReducer(state = initialState.productResult, action) {
  switch (action.type) {

    case types.LOADING_PRODUCTS:
        var newState = Object.assign({}, state);
        newState.loading = action.loading;
        if(!action.loading){
          newState.loaded = !action.isFailure;
        }

        if(action.isFailure){
          newState.products = [];
          newState.hiddenProducts = [];
          newState.productsCall2Action = [];
          newState.call2Actions = [];
          newState.productsByType = [];
        }

     return newState;

    case types.FORCE_LOADING_PRODUCTS:
      var newState = Object.assign({}, state);
      newState.loaded = false;
      return newState;

    case types.LOAD_PRODUCTS_SUCCESS:

     // Update the links with the user id
    //  if(action.productResult && action.productResult.userId && action.productResult.products){
    //     reducerHelper.updateProducts(action.productResult.products, action.productResult.isAnonymous, action.productResult.userId);
    //   }

    //   if(action.productResult && action.productResult.userId && action.productResult.productsCall2Action){
    //      reducerHelper.updateProducts(action.productResult.productsCall2Action, action.productResult.isAnonymous, action.productResult.userId);
    //    }

      return action.productResult;

    case types.SET_LASTCLICKEDPRODUCT:
      var newState = Object.assign({}, state);
      newState.lastProduct = action.product;
    return newState;

    default:
      return state;
  }
}
