import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardPageReducer(state = initialState.dashboardPage, action) {
  switch (action.type) {
    case types.LOAD_DASHBOARD_TABS_SUCCESS:

      var newState = Object.assign({}, state);
      newState.dashboardTabs = [...action.dashboardTabs];

      return newState;

    case types.LOAD_SCORE_CALLTOACTION_SUCCESS:

        var newState = Object.assign({}, state);
        newState.callsToAction = [...action.callsToAction];

        return newState;

    case types.LOADING_DASHBOARD_ARTICLES_SUCCESS:
        
      var newState = Object.assign({}, state);
      newState.articlesLoading = action.articlesLoading;
      if (newState.articlesLoading) {
        newState.articlesLoaded = false;
      }
      
      return newState;

    case types.LOAD_DASHBOARD_ARTICLES_SUCCESS:

      var newState = Object.assign({}, state);
      newState.articles = [...action.articles];
      newState.articlesLoaded = true;
      newState.articlesLoading = false;

      return newState;

    case types.UPDATE_DASHBOARD_TABINDEX_SUCCESS:

        var newState = Object.assign({}, state);
        newState.selectedDashboardTab = action.tabIndex;

        return newState;


    default:
      return state;
  }
}
