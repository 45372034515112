import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';


class UserApi {


    static isAuthenticated(csrfToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/is-authenticated';

        let headers = {};        
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }     
        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
             headers: headers};

          return fetch(fullUrl, options).then(response =>
            {
                    if (!response.ok) {
                      return Promise.resolve({isAuthenticated : false});
                    }
                    else{
                      return response.json();
                      // .then( r => {
                      //        return Promise.resolve({isAuthenticated : true, identifier : r.identifier});
                      //       });
                    }
            }
            ).catch(function (error) {
              throw(error);
            });
    }

    static tm() {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/tm';

        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
          };

          return fetch(fullUrl, options).then(response =>
            {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
            }
            ).catch(function (error) {
              throw(error);
            });
    }

    static initUser(csrfToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/init';

        let headers = {};        
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }     
        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
             headers: headers
        };
          return fetch(fullUrl, options).then(response =>
          {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
            }
            ).catch(function (error) {
              throw(error);
            });
    }

    static loadUserDetails(csrfToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/details';
        let headers = {};
        
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }     
        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
             headers: headers
          };

          return fetch(fullUrl, options).then(response =>
            {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
            }
            ).catch(function (error) {

              throw(error);
            });
    }


    static updateUserDetails(csrfToken, user, fields) {
    
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/details';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }   
      let options = {
           method: fields ? 'PUT' : 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(user)
         }

        return fetch(fullUrl, options).then(response =>
        {
                if (!response.ok) {
                  return Promise.reject(response);
                }
                else{
                  return response.json();
                }
        }
          ).catch(function (error) {
            throw(error);
          });
    }



    static changePassword(csrfToken, changePasswordRequest) {
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/changePassword';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }   
      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(changePasswordRequest)
         }

        return fetch(fullUrl, options).then(response =>
        {
              if (!response.ok) {
                return Promise.reject(response);
              }
              else{
                return response.json();
              }
        }
          ).catch(function (error) {

            throw(error);
          });
    }

    static forgotPassword(customer, token) {
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/forgot-password';
     
      const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-recaptcha': token
        },
        body: JSON.stringify(customer)
      };

      return fetch(fullUrl, options).then(response =>
        {
          if (!response.ok) {
            return Promise.reject(response);
          } else {
            return response.ok;
          }
        }
      ).catch(function (error) {
        throw(error);
      });
    }


    static confirmAccount(email, hash) {
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/confirm-account';

      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: {
            'Content-Type': 'application/json'
          },
           body: JSON.stringify({email: email, hash: hash})
         }

        return fetch(fullUrl, options).then(response =>
              {
                      if (!response.ok) {

                        return Promise.reject(response);
                      }
                      else{

                        return response.json();
                      }
              }
          ).catch(function (error) {
            throw(error);
          });
    }


    static logout() {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/oauth2/logout';

      let options = {
           method: 'GET',
           mode: 'cors',
           credentials: 'include'
         }

      return fetch(fullUrl, options).catch(function (error) {
            return Promise.resolve();
      });
    }

    static login(userCredentials, credentialOptions, recaptchaToken) {

        let loginAttempt = {
           username: userCredentials.username,
           password: userCredentials.password,
           grant_type: 'password',
           client_id: userCredentials.clientId ? userCredentials.clientId: 123          
          };       

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/oauth2/token';

        let options = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: {
              'Content-Type': 'application/json',
              'x-tm': credentialOptions && credentialOptions.tm,
              'x-recaptcha': recaptchaToken
             },
             body: JSON.stringify(loginAttempt)
        };

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return response.json();
                        }
                }
            ).catch(function (error) {

              throw(error);
            });
    }

    static refreshToken(userProfile, channel) {

      let loginAttempt = {         
         grant_type: 'refresh_token',
         client_id: 123,
         mFAVerificationType: channel
        };       

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/oauth2/token';

      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(userProfile && userProfile.csrfToken){
        headers['x-gcs-xsrf'] = userProfile.csrfToken;
      }    
      if(userProfile && userProfile.tm){
        headers['x-tm'] = userProfile.tm;
      }
      
      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(loginAttempt)
         }

        return fetch(fullUrl, options).then(response =>
              {
                      if (!response.ok) {
                        return Promise.reject(response);
                      }
                      else{
                        return response.json();
                      }
              }
          ).catch(function (error) {

            throw(error);
          });
     }

     static refreshTokenWithHash(channel) {

      let loginAttempt = {
         mFAVerificationType: channel,         
         grant_type: 'custom_refresh',
         client_id: 123
        };       

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/oauth2/token';

      let options = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: {
            'Content-Type': 'application/json'            
          },
           body: JSON.stringify(loginAttempt)
         }

        return fetch(fullUrl, options).then(response =>
              {
                      if (!response.ok) {
                        return Promise.reject(response);
                      }
                      else{
                        return response.json();
                      }
              }
          ).catch(function (error) {

            throw(error);
          });
     }

    static createAccount(userCredentials) {

        const loginAttempt = {
           username: userCredentials.username,
           password: userCredentials.password,
        };

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/create';

        let options = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: {
              'Content-Type': 'application/json'
            },
             body: JSON.stringify(loginAttempt)
           }

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return response.ok;
                        }
                }
            ).catch(function (error) {

              
              throw(error);
            });
    }

    static register(user) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register';

        let options = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: {
              'Content-Type': 'application/json'
            },
             body: JSON.stringify(user)
           }

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return response;
                        }
                }
            ).catch(function (error) {
              throw(error);
            });
    }

    static registerFull(user, recaptchaToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/register-full';

        let options = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: {
              'Content-Type': 'application/json',
              'x-recaptcha': recaptchaToken
            },
             body: JSON.stringify(user)
           }

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return response.json();
                        }
                }
            ).catch(function (error) {
              throw(error);
            });
    }

    static getPreferences(csrfToken) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/preferences';

        let headers = {};
        headers['Content-Type'] = 'application/json';
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }    
        let options = {
             method: 'GET',
             mode: 'cors',
             credentials: 'include',
             headers: headers
        };

          return fetch(fullUrl, options).then(response =>
            {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
            }
            ).catch(function (error) {
              throw(error);
            });
    }

    static setPreferences(csrfToken, preferences) {

        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/preferences';

        let headers = {};
        headers['Content-Type'] = 'application/json';
        if(csrfToken){
          headers['x-gcs-xsrf'] = csrfToken;
        }   
        let options = {
             method: 'POST',
             mode: 'cors',
             credentials: 'include',
             headers: headers,
             body: JSON.stringify(preferences)
           }

          return fetch(fullUrl, options).then(response =>
                {
                        if (!response.ok) {
                          return Promise.reject(response);
                        }
                        else{
                          return Promise.resolve(response);
                        }
                }
            ).catch(function (error) {
              throw(error);
            });
    }

  static getExistingCustomer(extendedCustomer) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/customer';

    let options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(extendedCustomer)
    };

    return fetch(fullUrl, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      } else {
        return response.json();
      }
    });
  }

  static getExistingCustomerSms(customer) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/customersms';

    let options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customer)
    };

    return fetch(fullUrl, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      } else {
        return response.json();
      }
    });
  }

  static getCustomerHomeShowCode(csrfToken) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/user/gethomeshowcode';

    let headers = {};
    
    if(csrfToken){
      headers['x-gcs-xsrf'] = csrfToken;
    }   
    let options = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: headers
    };

    return fetch(fullUrl, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      } else {
        return response.json();
      }
    }
    ).catch(function (error) {
      throw(error);
    });
  }  

}

export default UserApi;
