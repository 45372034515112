import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu';
import LoginBoxMessage from './LoginBoxMessage';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import TextInput from '../common/controls/TextInput';
import DateInputSimple from '../common/controls/DateInputSimple';

import UserApi from '../../api/mockUserApi.js';
import LayoutApi from '../../api/mockLayoutApi';
import ValidationApi from '../../api/validationApi';
import * as funcs from '../shared/functions';
import HelperApi from '../../api/HelperApi';
import FooterSmallSection from '../common/footerSmall';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
import DateInputGeneral from '../common/controls/DateInputGeneral';

import LoginFrame from './LoginFrame';

import {initTonikForms} from '../../tonik/shared/forms';
const $ = window.$;

const ForgetPasswordBox = ({ textVariants, onChange, error, customer, onPasswordSubmit, callbackWaiting }) => {

  let emailInputCls = 'form-control';

  if (error && error.length > 0) {
    emailInputCls += ' has-error';
  }

  return (

    <LoginFrame
    disclaimerCtrl={<span className="text-form">By clicking 'Login', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia InformationServices and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's <a href="#">Privacy Policy</a> and <a href="#">Terms of Use.</a></span>}
    >
   <div>
    <h4 class="text-center">{textVariants[pages.FORGOTPASSWORD + textVariants.textVariantSeparator + "top_title"]}</h4>
    <p dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.FORGOTPASSWORD + textVariants.textVariantSeparator + "content"])} />
    <form onSubmit={onPasswordSubmit}>
      <div className="form-label-group">
        <input type="email" id="email" name="email" className={error.email ? "form-control is-invalid" : "form-control"} onChange={onChange} value={customer.email} placeholder={textVariants[pages.FORGOTPASSWORD + textVariants.textVariantSeparator + "emailPlaceholder"]} autoComplete="off" />
        <label htmlFor="email" style={{cursor:'text'}}>Your e-mail address</label>
        {error.email && (
            <>
              <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
              <div className="invalid-feedback">
                  {error.email}
              </div>
            </>
          )}
      </div>

      <DateInputGeneral
        customer={customer}
        error={error.dob}
        textVariants={textVariants}
        onChange={onChange} 
        toolTip={textVariants[pages.FORGOTPASSWORD+ textVariants.textVariantSeparator + "dobTooltip"]}
        groupClass="form-label-group"
        />

      <button
          type="submit"
          className={callbackWaiting ? 'btn btn-primary full-width disabled' : 'btn btn-primary full-width'}>
          {textVariants["Btn_ForgotPassword"]}
          {callbackWaiting &&(<div class="spinner-border text-light float-right mt-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>)}
      </button>

    <p> <Link to="/login">{textVariants[pages.FORGOTPASSWORD + textVariants.textVariantSeparator + "goBackToLogin"]}</Link></p>
    </form>
    </div>
  </LoginFrame>   
  );
};

ForgetPasswordBox.propTypes = {
    textVariants: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.object,
    onPasswordSubmit: PropTypes.func.isRequired
};


export default ForgetPasswordBox;