import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import ScoreMapGenerationBox from './ScoreMapGenerationBox';

const ScoreMapGenerations = ({ areaGenerations = [], textVariants }) => {
  
  const mdMaxColNum = 4;
  const smMaxColNum = 2;
  const mdColsNum = Math.min(areaGenerations.length, mdMaxColNum);
  const smColsNum = Math.min(areaGenerations.length, smMaxColNum);
  const mdClass = "col-md-" + (12 / mdColsNum);
  const smClass = "col-sm-" + (12 / smColsNum);
  
  const generations = areaGenerations.map(gen => {
    return (       
      <div key={gen.spec.age} className={mdClass + " " + smClass + " col-xs-12"}>
        <ScoreMapGenerationBox             
          generationScores={gen}
          textVariants={textVariants} />
      </div>
    );
  });
  
  return (
    <div className="container-fluid left-container-padding right-container-padding scoremap-page-section scoremap-generations scoremap-page-section">
      <div className="scoremap-page-section-title">
        {areaGenerations && <p className="title">
          <span className="scoremap-has-disclaimer">{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "generationTitleWithDisclaimer"]}</span>
          {textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "generationTitle"]}
        </p>}
      </div>
      <div className="row">        
        {generations}
      </div>
      <div className="scoremap-disclaimer-note">
        <p>{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "generalDisclaimer"]}</p>
        <p>{textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "legalDisclaimer"]}</p>
      </div>
    </div>
  )
}

ScoreMapGenerations.propTypes = {
  areaGenerations: PropTypes.array,
  textVariants: PropTypes.object.isRequired
};

ScoreMapGenerations.defaultProps = {
  areaGenerations: []
};
  
export default ScoreMapGenerations;