import React from 'react';
import HelperApi from '../../../../api/HelperApi';
import * as funcs from '../../../shared/functions';

const HLATextPicker = ({step, stepState, wizardStepState, wizardStepIndex, wizardStepsDef, onUpdate, cssClass}) => {
  let component = <div />;
  let _key = 0;

  let updateText = function(event, stepId, stepNro, questionId, key) {
    const unmaskedValue = event.target.value;
    onUpdate(stepId, stepNro, questionId, null, false, [{inputKey: key, inputValue: unmaskedValue}]);
  };

  let moveForward = function(e) {
    e.preventDefault();
    onUpdate(step["id"], step["step"], 'keyvalue', null,  true, [{inputKey: _key, inputValue: stepState["selectedTexts"][_key]}]);

  };

  if (step && stepState) {
    let i = 0;
    const maskedValue = stepState["selectedTexts"][_key]; // HelperApi.toCurrencyFormat(stepState["selectedTexts"][_key] ,2 , "");
    let stepsInstruction = (typeof step["stepsInstruction"] === "function")
                            ? step.stepsInstruction(wizardStepState, wizardStepIndex, wizardStepsDef)
                            : step["stepsInstruction"];

    let extraComponent = <div />;

    extraComponent = (
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <div className="input-group-text"><i className="fa fa-pencil"></i></div>
        </div>
        <input
                  className="form-control input-lg wizard-action-input-txt"
                  type="text"
                  maxLength="50"
                  value={maskedValue}                                                                     
                  onChange={(evt) => updateText(evt, step["id"], step["step"], 'keyvalue', _key)}
                />
                
                { step["stepsTnCs"] && <span className="wizard-step-title" dangerouslySetInnerHTML={funcs.createMarkup(step["stepsTnCs"])} ></span>}
      </div>
    );

    component = (
      // <div className={"container-fluid wizard-step" + cssClass}>
      //   <div className="row wizard-step-title">
      //     <div className="col-xs-12 text-center" dangerouslySetInnerHTML={funcs.createMarkup(stepsInstruction)} />
      //   </div>
      //   {extraComponent}
      // </div>
      <form onSubmit={(e) => moveForward(e)}>
        {extraComponent}
      </form>
    );
  }

  return ( component );
};

export default HLATextPicker;
