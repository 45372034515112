import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../../shared/pages';
import * as funcs from '../../shared/functions';
import * as GlobalFixedVariables from '../../../config/GlobalFixedVariables';


export const AlreadyMember = ({cssClass, text, linkText, isAuthenticated, onLinkClick}) => {
  let component = <span></span>;

  if(!isAuthenticated){
    component = (
      <p className={"already-member " + cssClass}>
        <span className="text rwd-line">{text}</span>
        <a to="/login" className="link rwd-line" onClick={onLinkClick}>{linkText}</a>
      </p>
    );
  }

  return ( component );
}
