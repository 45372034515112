import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function creditSummaryReducer(state = initialState.creditSummary, action) {
  switch (action.type) {
    case types.GET_CREDIT_SUMMARY_SUCCESS:

      var newState = Object.assign({}, state, action.creditSummary);

      return newState;

    default:
      return state;
  }
}
