import delay from './delay';

import{ articlesLearn,  articlesCategories, articles} from '../components/shared/staticArticles';
import{  faqTopics, faqs} from '../components/shared/staticFaq';


class ArticlesApi {
    static getArticlesForHome() {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(articles.slice(0,6));
            }, delay);
        });
    }

    static getArticlesForDashboard() {

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                //resolve(Object.assign([], products));
                //resolve(articlesLearn);
                resolve(articles.slice(0,6));
            }, delay);
        });
    }

    static getArticle( articleSearch = { id:null, seoName:null, isFull: true }) {

        return new Promise((resolve, reject) => {

            setTimeout(() => {

              var article = {};
                for(var i = 0; i < articles.length; i++){
                  var art = articles[i];
                  if(art.id == articleSearch.id || art.seoName == articleSearch.seoName){
                    article = art;
                     if(!article.nextArticle){
                        var j =  i +  1;
                        if (j >= articles.length){
                          j = 0;
                        }

                        article.nextArticle = {
                          id: articles[j].id,
                          title: articles[j].title,
                          seoName: articles[j].seoName
                        };

                        break;
                      }
                  }
                }


              resolve(article);
            }, delay);
        });
    }

    static searchFaqAndArticles(searchParams = { searchTerm: '', hasArticles:true, hasFaqs:true, categoryId:null}) {

        return new Promise((resolve, reject) => {

            setTimeout(() => {

               var tmpArticles = [];
               if(searchParams.categoryId){
                 for(var i in articles){
                   var art = articles[i];
                   if(art.category.id == searchParams.categoryId){
                     tmpArticles.push(art);
                   }
                 }
               }
               else {
                 tmpArticles = articles;
               }

                var searchResult = {
                  faqs : faqs,
                  articles: tmpArticles
                }
                //resolve(Object.assign([], products));
                resolve(searchResult);
            }, delay);
        });
    }

    static getArticlesCategories() {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(articlesCategories);
            }, delay);
        });
    }

    static getFaqTopics() {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //resolve(Object.assign([], products));
                resolve(faqTopics);
            }, delay);
        });
    }

    static getFaqByTopic(faqTopicId){
      return new Promise((resolve, reject) => {
          setTimeout(() => {
               var topic = null;
               for(var i in faqTopics){
                 var cTopic = faqTopics[i];
                 if(cTopic.id == faqTopicId){
                   topic = cTopic;
                   break;
                 }
               }

              var faqsToReturn = [];

              for(var j in faqs){
                var cFaq = faqs[j];
                if(cFaq.faqTopicId == faqTopicId){
                  faqsToReturn.push(cFaq);
                }
              }

              //resolve(Object.assign([], products));
              resolve({
                  topic : topic,
                  faqs: faqsToReturn
              });
          }, delay);
      });
    }

    //static saveAuthor(author) {
    //    author = Object.assign({}, author); // to avoid manipulating object passed in.
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            // Simulate server-side validation
    //            const minAuthorNameLength = 3;
    //            if (author.firstName.length < minAuthorNameLength) {
    //                reject(`First Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.lastName.length < minAuthorNameLength) {
    //                reject(`Last Name must be at least ${minAuthorNameLength} characters.`);
    //            }

    //            if (author.id) {
    //                const existingAuthorIndex = authors.findIndex(a => a.id == author.id);
    //                authors.splice(existingAuthorIndex, 1, author);
    //            } else {
    //                //Just simulating creation here.
    //                //The server would generate ids for new authors in a real app.
    //                //Cloning so copy returned is passed by value rather than by reference.
    //                author.id = generateId(author);
    //                authors.push(author);
    //            }

    //            resolve(author);
    //        }, delay);
    //    });
    //}

    //static deleteAuthor(authorId) {
    //    return new Promise((resolve, reject) => {
    //        setTimeout(() => {
    //            const indexOfAuthorToDelete = authors.findIndex(author => {
    //                author.authorId == authorId;
    //            });
    //            authors.splice(indexOfAuthorToDelete, 1);
    //            resolve();
    //        }, delay);
    //    });
    //}
}

export default ArticlesApi;
