import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';
import {initTonikForms} from '../../tonik/shared/forms';

import * as ImagesPath from '../../config/imagesPath';

const $ = window.$;


const ErrorLine = ({message}) =>
{
  return (

    <div className='form-group '>

      <label className="control-label col-sm-4"></label>

      <div className="field col-sm-6 col-sm-offset-right-2">
         <div className="error-message">
          <span dangerouslySetInnerHTML={funcs.createMarkup(message)} />
         </div>
     </div>
    </div>
  )
};

class CurrentAddressForm extends React.Component {

  constructor(props, context){
      super(props, context);
  }

  componentDidMount() {
    initTonikForms();
  }

  componentDidUpdate(prevProps, prevState){

  }


render(){

    let {customer, onSave, onChange, saving, errors, onServiceError, textVariants, diplaySavedMessage, onCancel, omitFields, saveText} = this.props;

    return (
              <form method="POST">
                <div className="row">
                <div className="form-label-group col-12">
                                <AddressInput
                                    addressname="address"
                                    hasLivedLessThan3YearsId="hasLivedLessThan3Years"
                                    prevAddressName="addressPrevious"
                                    prevAddress={customer.addressPrevious}
                                    address={customer.address}
                                    hasLivedLessThan3Years={customer.hasLivedLessThan3Years}
                                    label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "address_label"]}
                                    labelPreviousAddress={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "prev_address_label"]}
                                    hasLivedLessThan3YearsLabel={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "use_prev_address_label"]}
                                    placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "address_plh"]}
                                    prevaddplaceholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "prev_address_plh"]}
                                    onChange={onChange}
                                    onServiceError={onServiceError}
                                    addressError={errors.address}
                                    prevAddressError={errors.addressPrevious}
                                    toolTip={textVariants[pages.REGISTER+ textVariants.textVariantSeparator + "address_tooltip"]}
                                    />
                    </div>
                </div>             

          {errors && errors["global"] && <ErrorLine message={errors["global"]} /> }
                     
        </form>
        );
      }
 }

 CurrentAddressForm.propTypes = {
    customer: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onServiceError: PropTypes.func,
    textVariants: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    errors: PropTypes.object,
    diplaySavedMessage: PropTypes.bool,
    onCancel: PropTypes.func,
    omitFields: PropTypes.object,
    saveText: PropTypes.string.isRequired
};


export default CurrentAddressForm;
