import React from 'react'
import * as ImagesPath from '../../../config/imagesPath';
import * as RoutesVariables from '../../../config/routesVariables';

export const DvsVerificationFrame = ({classTop, documentSelection, onBack, isDriverLicenceDone, isPassportDone, isMedicareDone, isInCohort, children}) => {

  var isIdentityVerified = isDriverLicenceDone || isPassportDone || isMedicareDone;
  var numberDocs = (isDriverLicenceDone ? 1:0) + 
                      (isMedicareDone ? 1:0) + 
                      (isPassportDone ? 1:0);    
                      
    var isDocSelect = !documentSelection;
    var stepNumber = isDocSelect ? "5":"6"
    return (
            <section className={classTop} >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-10 offset-md-1 form-log-wrapper">
                         <div>
                            {!isIdentityVerified && <div className={"nav-register n-nav-register-" + stepNumber}> {/*nav-register-2 */}
                                {!isDocSelect && <a onClick={onBack} className="prev-form"><img src={ImagesPath.ImgIconPrev} className="img-fluid" alt="" /></a>}
                                <h2><span>{stepNumber} <span className="separator" /> 6</span> Identity Verification</h2>
                            </div>}
                            {isIdentityVerified && <div className="nav-register2">                              
                              <h2>Identity Verification</h2>
                            </div>}
                            <div className="form-log-box">
                              <img src={ImagesPath.ImgLogo} className="img-fluid logo d-block d-sm-block d-lg-none" alt=""/>
                              {isIdentityVerified && 
                                <p className="alert alert-success">You have successfully verified <span>{numberDocs}</span> document(s) <br />
                                {numberDocs == 1 && isInCohort && <span>However you need to verify 2 documents in total to access your credit report overview.<br />Please select one of the following documents</span>}
                                {numberDocs > 1 && <span>You don't need to verify your identity again.<br /></span>}                                
                                   </p>
                                }
                              {!isIdentityVerified &&
                               <p>We need to verify your Identity for security purposes. Please select one of the following Identity Documents.<br /> If possible, we strongly recommend that you provide us with your Driver's Licence for matching purposes.</p>}
                              {children}                                                                                        
                            </div>
                            <div>
                            {/* {!isDocSelect && <span className="agree terms-text">
                                <p>By submitting this form I confirm that I am authorised to provide my personal information and I consent to it being checked with the document issuer or official record holder through third party systems for the purpose of confirming my identity. I appoint GetCreditScore as an agent and 'access seeker' to retrieve my Equifax credit information on my behalf.<br />                                
                                You can find further information on what personal information we hold, and how we use it within the GetCreditScore <a href={RoutesVariables.RouteExternalPrivacyPolicy}  target="_blank">Privacy Policy</a></p>                                
                              </span>} */}
                            </div>
                         </div>
                      </div>
                    </div>
                  </div>
              </section>
           );
  };