import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import Menu from  '../menu/Menu.js';
import LoginBox from './LoginBox';
import * as pages from '../shared/pages';
import LoadingIndicator from '../common/controls/LoadingIndicator';

import * as funcs from '../shared/functions';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import ValidationApi from '../../api/validationApi.js';

import ThreatMetrixComponent from '../common/ThreatMetrixComponent';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as RoutesVariables from '../../config/routesVariables';

import {PromotionBanner} from '../sections/PromotionBanner';
import FooterSmallSection from '../common/footerSmall';

import GcsRecaptcha from "../common/GcsRecaptcha";

import {initTonikForms} from '../../tonik/shared/forms';

export class LoginPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      userCredentials:  {
        email: props.registeringUsername,
        fckpass: '',
        rememberMe: props.registeringRememberMe
      },
      errors: {},
      loginMode: true,
      accountBeingCreated: false,
      loading: false,
      tmLoaded: null,
      tmValue: null,
      tmFinished: true, //!(process.env.REACT_APP_TM == 'true'),
      tmTryCount : 1,
      forcedPassword: false,
      resetRecaptcha: false
    };

    this.updateCustomerState = this.updateCustomerState.bind(this);
    this.onForgotPasswordClick =  this.onForgotPasswordClick.bind(this);
    this.onActionClick =  this.onActionClick.bind(this);
    this.onChangeModeClick = this.onChangeModeClick.bind(this);
    this.performLogin = this.performLogin.bind(this);
    this.doLogin = this.doLogin.bind(this);  
    this.onGoBackToHomePage = this.onGoBackToHomePage.bind(this);
    this.loadTmVariables = this.loadTmVariables.bind(this);
    this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
    this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  
    this.onProfileComplete = this.onProfileComplete.bind(this);

    this.recaptchaRef = React.createRef();  
  }

     componentDidMount() {
       
        if (parse(this.props.location.search).p && parse(this.props.location.search).u) {
          this.setState({ forcedPassword: true});
        }

        this.props.actions.getMenuForPage(pages.LOGIN);
        this.props.actions.getTextVariants();
        //this.loadTmVariables();

         this.props.actions.initUser().then(r => {
          if (this.props.rememberMeInfo.rememberMe) {
            let currentStateCredentials =  this.state.userCredentials;
            currentStateCredentials.email = this.props.rememberMeInfo.username;
            currentStateCredentials.rememberMe = true;
            this.setState({ userCredentials: currentStateCredentials });
          }

          this.loadTmVariables().then(r => {
            let fckpass = parse(this.props.location.search).p;
            let username = parse(this.props.location.search).u;

            if (fckpass && username) {
              let user = this.state.userCredentials;
              user.email = username;
              user.fckpass = fckpass;

              this.setState({userCredentials: user }, () => {
                this.performLogin();
              });
            }
          });
        });

        this.doResetRecaptcha();
        initTonikForms();
   
     }

     loadTmVariables(isForced){
       return this.props.actions.tm(isForced).then(r => {
          this.setState({tmLoaded : true});
       }).catch(e => {
          //this.setState({tmLoaded : false});
       });
     }

     onProfileComplete(session_id){
       this.setState({tmFinished : true});
       //console.log('finish tm loading', this.props.tm == session_id, this.props.tm, session_id);
       if(this.props.tm != session_id){
         this.props.actions.tmSuccess({id: session_id})
       }
     }

     componentWillReceiveProps(nextProps) {

        // redirects from this methods because this page shouldn't be accessed
        // if the user is logged-in   
                
        if (nextProps.isAuthenticated) {
          // go back to the page if the r parameter is set

          let ret = '';

          if (this.state.forcedPassword) {                       
              var par = parse(this.props.location.search).p
              if(par && par.length > 50){
                par = par.substr(0,50);
              }
              ret = nextProps.redirect_uri ? nextProps.redirect_uri : RoutesVariables.RouteChangePassword + '?p=' + par;           
          }

          if (ret == '') {
              ret = RoutesVariables.RouteCreditScore;

              let returnUrl = this.props.match.params.returnUrl;
              let retUrl = parse(this.props.location.search).retUrl;

              if (nextProps.redirect_uri) {
                if(nextProps.redirect_uri.indexOf(RoutesVariables.RouteLogin2FA) >= 0){
                  ret = nextProps.redirect_uri + (retUrl ? "?retUrl=" + encodeURIComponent(retUrl) : "");
                }else{
                  ret = nextProps.redirect_uri;
                }

              } else if (returnUrl) {
                ret = '/' + returnUrl;
              } else if (retUrl) {
                ret = '' + retUrl;
              }
          }
          
          this.props.history.push(ret);
          return;
        }

        if (nextProps.registeringUsername != this.props.registeringUsername) {
          let userCredentials = Object.assign({}, this.state.userCredentials);
          userCredentials.email = nextProps.registeringUsername;
          userCredentials.rememberMe = this.props.registeringRememberMe;
          this.setState({ userCredentials });
        }

        this.doResetRecaptcha();
     }

     onSelectRecaptcha(token){   
        if (token) {              
            this.doLogin(token);        
        }      
    
        this.setState({resetRecaptcha: true, loading: false});
     }
  
     doResetRecaptcha(){
      if (this.state.resetRecaptcha) {
        try{
          this.recaptchaRef.current.reset();
          this.setState({resetRecaptcha: false});
        }
        catch(ex){
          console.log(ex);
        }
        
      }
    }

     componentWillUnmount(){
       this.props.actions.updateRegisteringUsername(this.state.userCredentials.email, this.state.userCredentials.rememberMe);
     }

     updateCustomerState(event) {
         const field = event.target.name;

         let userCredentials = this.state.userCredentials;
         userCredentials[field] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

         let errors = this.state.errors;
         errors[field] = '';

         return this.setState({ userCredentials: userCredentials, errors: errors });
     }

     onChangeModeClick(evt){
       evt.preventDefault();

       this.props.history.push(RoutesVariables.CreateAccount);
     }

    onForgotPasswordClick(evt){
      evt.preventDefault();

      this.props.history.push(RoutesVariables.RouteForgotPassword);
    }

    onGoBackToHomePage(evt){
      evt.preventDefault();
      this.props.history.push('/');
    }

    onActionClick(event) {
        event.preventDefault();
        this.performLogin();
    }

    performLogin(){
      this.setState({ loading: true });

       // Clean errors
       let errors = this.state.errors;
       errors = {};
       this.setState({ errors: errors });

       if (!this.loginFormIsValid()) {
         this.setState({ loading: false, forcedPassword: false });
           return;
       }

       this.recaptchaRef.current.execute();      
    }

    doLogin(token){
     
      var loginContext = null;
      let loginCredentials = { username: this.state.userCredentials.email,
                               password: this.state.userCredentials.fckpass,
                               rememberMe: this.state.userCredentials.rememberMe,
                               context: loginContext
                               };

      let errors = {};      
     if(this.state.tmLoaded || this.props.tm != null)
     {

       this.props.actions.login(loginCredentials, token).catch( e => {

          if(e.status == 401){
              errors['login'] = this.props.textVariants[pages.LOGIN + this.props.textVariants.textVariantSeparator + "incorrectUserOrPass"];
          }
          else if(e.status == 406){

              errors['login'] = this.props.textVariants[pages.LOGIN + this.props.textVariants.textVariantSeparator + "threatMetrix_breach"];
          }
          else if(e.status == 403){

              errors['login'] = this.props.textVariants[pages.LOGIN + this.props.textVariants.textVariantSeparator + "user_details_not_present"];
          }
          else if(e.status == 409){

              errors['login'] = this.props.textVariants[pages.LOGIN + this.props.textVariants.textVariantSeparator + "createAccountErrorRegistrationInProcess"];
          }
          else {

             if(!funcs.hasLocalStorage()){
               errors['login'] = this.props.textVariants["Error_localStorage_Missing"];
             }
             else{
              errors['login'] = this.props.textVariants["Api_Generic"];
            }
          }

         this.setState({ errors: errors, loading: false });
         
       }).finally( e => {
         
         if(!this.props.isAuthenticated){
          this.setState({ loading: false, forcedPassword: false });
          this.doResetRecaptcha();
         }
       });
     }
     else {
      
         if(this.state.tmTryCount > GlobalVariables.SCORE_RETRY_MAX){
             errors['login'] = this.props.textVariants[pages.LOGIN + this.props.textVariants.textVariantSeparator + "tmNotLoaded"];
             this.setState({ errors: errors, loading: false, forcedPassword: false });
         }
         else {

               setTimeout(() => {

                   this.setState({tmTryCount: this.state.tmTryCount + 1});
                   this.loadTmVariables();
                   this.performLogin();
               }, GlobalVariables.SCORE_RETRY_TIMEOUT);
         }
     }
    }


    loginFormIsValid() {

         let formIsValid = true;
         let errors = {};

         var errorEmail = ValidationApi.validateEmail(this.state.userCredentials.email);
         if (errorEmail) {
             errors.email = errorEmail;
             formIsValid = false;
         }

         var errorPass = ValidationApi.validatePassword(this.state.userCredentials.fckpass);
         if (errorPass) {
             errors.fckpass = errorPass;
             formIsValid = false;
         }

         this.setState({errors: errors});

         return formIsValid;
    }

    render() {      
        let loginArea =
        <div>          
           <LoginBox
                 textVariants={this.props.textVariants}
                 onChange={this.updateCustomerState}
                 onActionClick={this.onActionClick}
                 error={this.state.errors}
                 userCredentials={this.state.userCredentials}                               
                 onForgotPasswordClick={this.onForgotPasswordClick}
                 loading={this.state.loading} 
                 enabled={this.state.tmFinished}
               />
           </div>;

        if(this.state.forcedPassword){
          loginArea = <LoadingIndicator text="" />;
        }        

        return (
            <div>
               <GcsRecaptcha
                  recaptchaRef={this.recaptchaRef}
                  onSelectRecaptcha={this.onSelectRecaptcha}
               />
              <ThreatMetrixComponent
                userIdentifier={this.props.tm}
                onProfileComplete={this.onProfileComplete}
                pageNumber={1}
              />
              <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />
              
              {loginArea}

              <FooterSmallSection></FooterSmallSection>
            </div>
        );
   }
}

LoginPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.LOGIN]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.LOGIN];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    tm: state.userProfile.tm,
    menuItemList: menuItems,
    textVariants: txtVariants,
    registeringUsername: state.userProfile.registeringUsername,
    registeringRememberMe: state.userProfile.registeringRememberMe,
    isAuthenticated: state.userProfile.isAuthenticated,
    redirect_uri: state.userProfile.redirect_uri,
    rememberMeInfo: state.userProfile.rememberMeInfo,
    userProfile:  state.userProfile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
