import React, { useEffect } from "react";
import './App.css';

import {tonikInitGlobal} from '../tonik/shared/global';

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount(){
    tonikInitGlobal();    
  }

  render(){
    return (
      <div className="main-root n-expand">
          {this.props.children}
      </div>
    );

  }
  
  
}

export default App;
