import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

const $ = window.$;


class CreditEnquiriesDemo extends React.Component {

  constructor(props, context){
      super(props, context);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState){
  }

render(){

    let {textVariants, data} = this.props;
    return (

      <span>
        <div id="accordion" className="accordion-reports">
        {
            data['credit-enquiry'].map( (enquiry, index) => {
              return (
                <div className="card">
                  <div className="card-header" id={"heading-" + index}>
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse-" + index} aria-expanded="true" aria-controls={"collapse-" + index}>
                      {enquiry['credit-enquirer']['name']['__text']} <span>Date of enquiry: <span className="date">{enquiry['_enquiry-date']} </span></span>
                      </button>
                    </h5>
                    <h2>{enquiry['account-type']['__text']}</h2>
                  </div>

                  <div id={"collapse-" + index} className="collapse" aria-labelledby={"heading-" + index} data-parent="#accordion">
                    <div className="card-body">
                      <span className="green"><sup>$</sup>{enquiry['enquiry-amount']['__text']}</span>
                      {enquiry['enquiry-client-reference'] != null && (<p>Customer Reference No:<span className="reference-no">{enquiry['enquiry-client-reference']['__text']}</span></p>)}
                    </div>
                  </div>
                </div>
              )
            })
        }

        </div>
      </span>
        );
      }
 }

 CreditEnquiriesDemo.propTypes = {
    textVariants: PropTypes.object.isRequired,
};


export default CreditEnquiriesDemo;
