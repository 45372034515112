import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import LoginFrame from './LoginFrame';

const Login2FASelectionBox  = ({
  textVariants,  
  onActionClick,  
  infoMessage,
  errorMessage    
  }) => {       

return (
  <LoginFrame
    disclaimerCtrl={null}
    cssClass=" n-100vh"
  >
      <div className="select-wrapper n-log-selection-wrapper text-center">
        <h2>2nd Factor Verification</h2>
        <p>Please select where to receive a one time code</p>
        <form className="n-login-selectform">
          <div className="container">
          <div className="row">
            <div className="form-check col-6" onClick={(e) => {onActionClick(e, 'E')}}>
              <input className="input-radio input-select square-input form-check-input" type="radio" id="item0" name="emailSelected"  />
              <label className="form-check-label align-content-center n-label" htmlFor="item0" >
                <img src={ImagesPath.ImgIconEmail2FA} className="img-fluid mt-2" alt="" />
                Email
                </label>
            </div>
            <div className="form-check col-6" onClick={(e) => {onActionClick(e, 'M')}}>
              <input className="input-radio input-select square-input form-check-input" type="radio" id="item1" name="mobileSelected"  />
              <label className="form-check-label align-content-center n-label" htmlFor="item1" >
                <img src={ImagesPath.Img2FAMobile} className="img-fluid mt-2" alt="" />
                Mobile
                </label>
            </div>           

          </div>
          </div>
        </form>

        {infoMessage && 
                <div className="alert alert-warning">
                  <span dangerouslySetInnerHTML={funcs.createMarkup(infoMessage)}></span>
                </div>
              }       
              {errorMessage && 
                <div className="alert alert-danger">
                  <span dangerouslySetInnerHTML={funcs.createMarkup(errorMessage)}></span>
                </div>
              }   
      </div>

  </LoginFrame>
);

};


Login2FASelectionBox.propTypes = {
  textVariants: PropTypes.object.isRequired,      
  onActionClick: PropTypes.func.isRequired,  
};

export default Login2FASelectionBox;
