import React from 'react';
import PropTypes from 'prop-types';

import * as pages from '../shared/pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import LoadingIndicator from '../common/controls/LoadingIndicator';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

const ArticlesSectionItemPreview = ({article, onArticleClick, onResourceClick}) => {

  const createMarkup = function(txt) {
      return {__html: txt};
  };

        return (

          <div className="col-lg-4 col-12 post-article">
          <div className="post-article-item h-100">
            <div className="row h-100">
              <div className="col-12 picture-img">
                <a href={RoutesVariables.RouteExternalArticles + '/' + article.seoName}><img src={article.imgUrl} alt="" className="img-fluid"/></a>
              </div>
              <div className="col-12">
                <div className="content-link-post">
                  <span className="category"><a  href={"/articles?c=" + article.category.id}>{article.category.name}</a></span>
                  <span className="date"></span>
                  <h2><a href={RoutesVariables.RouteExternalArticles + '/' + article.seoName}>{article.title}</a></h2>
                  <p>{article.text} </p>
                  
                </div>
              </div>
              <div className="col-12">                
               <div className="content-link-post">
                <a href={RoutesVariables.RouteExternalArticles + '/' + article.seoName} className="read-more">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </div>

)};

ArticlesSectionItemPreview.propTypes = {
       article: PropTypes.object.isRequired,
       onArticleClick: PropTypes.func.isRequired,
       onResourceClick: PropTypes.func.isRequired
};


const ArticlesSectionItemPreviewMobile = ({article, onArticleClick, onResourceClick}) => {

        let classes = "item  section-content-alignment " + (article.id == 0 ? "active":"");

        const createMarkup = function(txt) {
            return {__html: txt};
        };

        return (

              <div className={classes} >
                <a className="article-link-wrapper"
                  onClick={(evt) => onArticleClick(evt, article)}
                   href={RoutesVariables.RouteExternalArticles + '/' + article.seoName}>
                                  <img src={article.imgUrl} className="img-responsive" />
                                  <h5>{article.title}</h5>
                                  <p className='article-preview-description hidden-xs' dangerouslySetInnerHTML={createMarkup(article.text)} ></p>
                </a>
                                 </div>
            )
};

ArticlesSectionItemPreviewMobile.propTypes = {
       article: PropTypes.object.isRequired,
       onArticleClick: PropTypes.func.isRequired,
       onResourceClick: PropTypes.func.isRequired
};


const ArticlesSectionContent = ({articles, onLearnMoreClick, onArticleClick, onResourceClick, textVariants}) => {
  return (
    <div className="slider-post row">
      {
        articles.map( article => <ArticlesSectionItemPreview key={article.id} article={article} onArticleClick={onArticleClick} onResourceClick={onResourceClick} />)
      }
    </div>
      )
}



ArticlesSectionContent.propTypes = {
       articles: PropTypes.array.isRequired,
       onLearnMoreClick: PropTypes.func.isRequired,
       onArticleClick: PropTypes.func.isRequired,
       onResourceClick: PropTypes.func.isRequired,
       textVariants: PropTypes.object.isRequired
   };

const ArticlesSection = ({articles, onLearnMoreClick, onArticleClick, onResourceClick, textVariants}) => {

var sectionContent = null;


if (articles && articles.length>0) {
sectionContent = <ArticlesSectionContent articles={articles}
                  onLearnMoreClick={onLearnMoreClick}
                  onArticleClick={onArticleClick}
                  onResourceClick={onResourceClick}
                  textVariants={textVariants} />;
                }
                else {
      sectionContent = <LoadingIndicator
                          textPosition='bottom'
                          text={textVariants[pages.ARTICLES + textVariants.textVariantSeparator + "loading_text"]} />
                }

var sectionCls = 'home-articles ' + pages.COMMON_SECTION_PADDING;

        return (

    <section id="learn-articles-section" className="content-page-article">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h2>Learn from the experts</h2>
            <a href={RoutesVariables.RouteExternalArticles} className="post-link d-none d-lg-inline-block">See More Tips <img src={ImagesPath.ImgIconArrowLeftBlue} alt="" className="img-fluid"/></a>
            
            {sectionContent}
            
            <a href={RoutesVariables.RouteExternalArticles} className="post-link d-lg-none d-md-inline-block">See More Tips <img src={ImagesPath.ImgIconArrowLeftBlue} alt="" className="img-fluid"/></a>
          </div>
        </div>
      </div>
    </section>

)};


ArticlesSection.propTypes = {
       articles: PropTypes.array.isRequired,
       onLearnMoreClick: PropTypes.func.isRequired,
       onArticleClick: PropTypes.func.isRequired,
       onResourceClick: PropTypes.func.isRequired,
       textVariants: PropTypes.object.isRequired
   };

export default ArticlesSection;
