import $ from 'jquery'
import 'bootstrap'

import PerfectScrollbar from 'perfect-scrollbar'


export const tonikInitModal = () => {

if ($('.modal-body').length > 0) { // modals
  //const ps = new PerfectScrollbar('.modal-body')
  new PerfectScrollbar('.modal-body');
}

// bootstrap modal initialize
$('#modal-dashboard').modal('show')

$('#modal-reports').modal('show')

$('#modal-history').modal('hide')

$('#modal-offer').modal('hide')

return;
}
