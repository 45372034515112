import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as scoreActions from '../../redux/actions/scoreActions';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as pages from '../shared/pages';
import HelperApi from '../../api/HelperApi';
import * as ImagesPath from '../../config/imagesPath';
import TrustPilotSection from './../sections/TrustPilotSection';
import {tonikInitiTruspilotSlider} from '../../tonik/homepage';

const CounterNumbersBox = ({ counter, loaded }) => {
  const separatorChar = ",";
  let splittedValue = HelperApi.toThousandSeparatedFormat(counter).split("");

  let numberBoxes = splittedValue.map((num, i) => {
    if (num === separatorChar) {
      return <span key={i} className="counter-section-separator-text">{num}</span>;
    } else {
      return <span key={i} className={"counter-section-number-text"}>{num}</span>;
    }
  });

  return (
    <div>
      <div className={"counter-section-loading " + (loaded ? "counter-section-hide" : "")}>
        <div className="progress counter-section-progress">
          <div className="progress-bar progress-bar-striped progress-bar-animated active counter-section-progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
        </div>
      </div>

      <span className={"counter-section-number-box " + (loaded ? "" : "counter-section-hide")}>
        {numberBoxes}
      </span>
    </div>
  );
};

class CounterSection extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      reviews: [],
      mounted: false      
    }    
  }

  componentDidMount() {
    
    this.props.actions.initializeScoreCounter();

    var reviews = this.props.textVariants[pages.TRUSTPILOTSECTION + "reviews"];    

    this.setState({reviews: reviews});
  }

  componentDidUpdate(prevProps, prevState){
      if(this.state.reviews.length > 0 && !this.state.mounted){
        var mounted = tonikInitiTruspilotSlider();
        this.setState({mounted});
      }
  }

  render() {
    let component = <div />;

    if (this.props.isEnabled) {
      component = (

        <section className="section-counter">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="counter-box">
                  <h2>Credit Scores Given</h2>
                  <div id="counter" className="counter" data-counter="4852190">4852000</div>
                  {/* <div className="row">
                    <div className="col-lg-4 offset-lg-1 col-md-6 col-12">
                      <div className="trustpilot">
                        
                        <TrustPilotSection
                          key="trustPilot"
                          isEnabled={this.props.isTrustPilotEnabled}
                          type="mini"
                          trustPilotSectionTheme="trust-pilot-section-theme-light trust-pilot-section-theme-default"
                          textVariants={this.props.textVariants}
                        />                        
                      </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 col-md-6 col-12">
                      <div className="opinion-carousel">

                        {this.state.reviews.map( (review, i) => {
                          return (
                            <div className="opinion-item">
                              <blockquote>{review.comment}</blockquote>
                              <span>{review.author}</span>
                            </div>
                          );
                        })}
                                                                                               
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

      );
    }

    return component;
  }

}

CounterSection.propTypes = {
  forMobile: PropTypes.bool,
  counterSectionTheme: PropTypes.string.isRequired
};

CounterSection.defaultProps = {
  forMobile: false
};

function mapStateToProps(state, ownProps) {

  let textVariants = {};
  if (state.textVariants) {
    textVariants = state.textVariants;    
  }

  let scoreCounter = {};
  if (state.scoreProfile.scoreCounter) {
    scoreCounter = state.scoreProfile.scoreCounter;
  }

  return {
    isEnabled: scoreCounter.isEnabled,
    counter:  scoreCounter.counter,
    updateInterval: scoreCounter.updateInterval,
    loaded: scoreCounter.loaded,
    textVariants: textVariants
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterSection);
