import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { composeWithDevTools } from 'redux-devtools-extension';


import rootReducer from '../reducers';
//import DevTools from '../DevTools'

export default function configureStore(initialState) {
  const store = createStore (
    rootReducer,
    initialState,
    compose(
      composeWithDevTools(
        applyMiddleware(thunk, reduxImmutableStateInvariant(), createLogger({ collapsed: true }))
      ),
      //DevTools.instrument()
    )
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    })
  }

  return store;
}