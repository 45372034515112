import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';

import * as pages from '../../shared/pages';
import * as RoutesVariables from '../../../config/routesVariables';
import * as ImagesPath from '../../../config/imagesPath';
import * as funcs from '../../shared/functions';
import {initTonikForms} from '../../../tonik/shared/forms';

import TextInput from '../../common/controls/TextInput';
import TextNumericInput from '../../common/controls/TextNumericInput';
import DateInputSimple from '../../common/controls/DateInputSimple';

import {DvsConsentCheckbox} from './DvsCommon';

const stateCodesForDropDown = [
  {value: "NSW", text: "NSW - New South Wales"},
  {value: "VIC", text: "VIC - Victoria"},
  {value: "SA", text: "SA - South Australia"},
  {value: "WA", text: "WA - Western Australia"},
  {value: "NT", text: "NT - Northen Territory"},
  {value: "QLD", text: "QLD - Queensland"},
  {value: "ACT", text: "ACT - Australian Capital Territory"},
  {value: "TAS", text: "TAS - Tasmania"}
];

const DvsDriverLicenceForm = ({ identityRequest, onSave, onChange, errors, saving, textVariants, onBack, isInline = false}) => {
  
  let dlImage = ImagesPath['ImgDvs_DL_' + identityRequest['stateCode']];

  let showCardNumber= true;
  if ((identityRequest['stateCode'] == "NSW") || (identityRequest['stateCode'] == "SA") ||
  (identityRequest['stateCode'] == "WA") || (identityRequest['stateCode'] == "NT") ||
  (identityRequest['stateCode'] == "ACT") || (identityRequest['stateCode'] == "TAS") || (identityRequest['stateCode'] == "QLD") || (identityRequest['stateCode'] == "VIC"))
  {
    showCardNumber= true;
  }
  else
  {
    showCardNumber= false;
  }


  return (
    <form>
      <div className="row">
        
      <div className="col-12">
          {(errors && errors['global']) &&
            (<div className="alert alert-danger" role="alert">
                <span dangerouslySetInnerHTML={funcs.createMarkup(errors['global'])}></span>                    
                {errors['type'] && <span> or <a onClick={onBack} style={{float: "none !important;", cursor: "pointer;"}}>click here</a> to choose another document</span>}
              </div>)}
        </div>
        
        <div className={isInline ? "col-12" :"col-lg-6 col-12"}>

          <div className={isInline ? "col-12 d-lg-none d-inline-block" :"col-lg-6 col-12 d-lg-none d-inline-block"}>
            <div className="">
              <img className="img-fluid" src={dlImage} style={{maxWidth:'90%', margin:'auto !important'}} alt=""/>
            </div>
          </div>
          <div className="col-12 small-input">
            <TextInput
              name="givenName"
              id="givenName"
              autocomplete="off"
              placeholder={'Given Name as in Document'}
              value={identityRequest['givenName']}
              label={'Given Name'}
              onChange={onChange}
              error={errors['givenName']}
              groupClass="form-label-group"  />
          </div>
          <div className="col-12 small-input">
            <TextInput
              name="middleName"
              id="middleName"
              autocomplete="off"
              placeholder={'Middle Name'}
              value={identityRequest['middleName']}
              label={'Middle Name'}
              onChange={onChange}
              error={errors['middleName']}
              groupClass="form-label-group"  />
          </div>
          <div className="col-12 small-input">
            <TextInput
              name="familyName"
              id="familyName"
              autocomplete="off"
              placeholder={'Family Name'}
              value={identityRequest['familyName']}
              label={'Family Name'}
              onChange={onChange}
              error={errors['familyName']}
              groupClass="form-label-group"  />
          </div>
          <div className="col-12 small-input">              
             <DateInputSimple  
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={identityRequest['dateOfBirth']}
                  label={'Date Of Birth'}
                  onChange={onChange}
                  error={errors['dateOfBirth']}
                  groupClass={"form-label-group"} 
             />                                          
          </div>
          <div className="col-12 small-input">
            <div className="form-label-group">
              <select className={errors['stateCode'] ? "form-control filled is-invalid" : "form-control filled"}
                      id="stateCode"
                      name="stateCode"
                      onChange={onChange}
                      value={identityRequest['stateCode']}>

                      {stateCodesForDropDown.map( (item, i) => <option key={item.value} value={item.value}>{item.text}</option> )}                
              </select>
              <label htmlFor="stateCode">State</label>
              <div className="invalid-feedback">{errors['stateCode']}</div>
            </div>
          </div>
          <div className="col-12 small-input">
            <TextInput
              name="number"
              id="driverLicenceNumber"
              autocomplete="off"
              placeholder={'Current driver’s licence number'}
              value={identityRequest['number']}
              label={'Current driver’s licence number'}
              onChange={onChange}
              error={errors['number']}
              groupClass="form-label-group"  />
          </div>
           {/* New part for DVS Change */}
         {showCardNumber && 
          <div className="col-12 small-input">
            <TextInput
              name="cardNumber"
              id="driverLicenceCardNumber"
              autocomplete="off"
              placeholder={'Card number'}
              value={identityRequest['cardNumber']}
              label={'Card number'}
              onChange={onChange}
              error={errors['cardNumber']}
              groupClass="form-label-group"  />
          </div>
          }

        </div>
        <div className={isInline ? "col-12 d-none d-lg-block" :"col-lg-6 col-12 d-none d-lg-block"}>
          <div className="">
            <img className="img-fluid" src={dlImage} style={{maxWidth:'90%', margin:'auto !important'}} alt=""/>
          </div>
        </div>
      </div>
      <div className="col-12" >
        
        <DvsConsentCheckbox
          identityRequest={identityRequest}          
          onChange={onChange}
          errors={errors}
         />
           
        <a onClick={onSave} className="btn btn-primary" disabled={saving}>
                <span >{textVariants[pages.MYDETAILSVERIFICATION + textVariants.textVariantSeparator + "save_btn"]}</span>                                
                {saving && <span class="spinner-border spinner-border-sm align-middle ml-2" role="status" aria-hidden="true"></span>}
        </a>
      </div>
    </form>
  );
};


export default DvsDriverLicenceForm;
