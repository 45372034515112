import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function homePageReducer(state = initialState.homePage, action) {
  switch (action.type) {
    case types.LOAD_KEYFEATURES_SUCCESS:

      var newState = Object.assign({}, state);
      newState.keyFeatureList = [...action.keyFeatues];

      return newState;

      case types.LOAD_PROCESSSTEPS_SUCCESS:

        var newState = Object.assign({}, state);
        newState.processSteps = {steps: [...action.keyFeatues.steps], disclaimer: action.keyFeatues.disclaimer};

        return newState;

      case types.LOADING_HOMEPAGE_ARTICLES_SUCCESS:
        
        var newState = Object.assign({}, state);
        newState.articlesLoading = action.articlesLoading;
        if (newState.articlesLoading) {
          newState.articlesLoaded = false;
        }
          
        return newState;

      case types.LOAD_HOMEPAGE_ARTICLES_SUCCESS:

        var newState = Object.assign({}, state);
        newState.articles = [...action.articles];
        newState.articlesLoaded = true;
        newState.articlesLoading = false;

        return newState;

      case types.LOAD_HOMEFAQS_SUCCESS:

          var newState = Object.assign({}, state);
          newState.gcsHomeFaqs = [...action.gcsHomeFaqs];

          return newState;


    default:
      return state;
  }
}
