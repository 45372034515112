import React from 'react';
import PropTypes from 'prop-types';
import {createBrowserHistory} from 'history';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';

import Menu from  '../menu/Menu.js';
import LoadingIndicator from '../common/controls/LoadingIndicator';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as funcs from '../../components/shared/functions.js';

import * as RoutesVariables from '../../config/routesVariables';

import CustomCheckBox from '../common/controls/CustomCheckBox';
import CustomCheckBoxList from '../common/controls/CustomCheckBoxList';
import FooterSmallSection from '../common/footerSmall';

import * as ImagesPath from '../../config/imagesPath';

var testCheckBoxes = [
  {
    id: 0,
    //className: 'customDarkBkndCheckBox',
    name: 'checkbox1',
    selected: false
  },
  {
    id: 1,
    name: 'checkbox2',
    selected: false
  },
  {
    id: 2,
    name: 'checkbox3',
    selected: false
  },
  {
    id: 3,
    name: 'preference3',
    selected: false
  }
];

const MessageDisplay = ({message, isError}) => {
  let component = <div className="message-display"></div>;
  let cssClass = 'alert ' + (isError ? 'alert-danger' : 'alert-success');

  if (message) {
    component =  (
      <div className="message-display">
        <div className="text-center">
          <div className={cssClass}>
            <span  dangerouslySetInnerHTML={funcs.createMarkup(message)}></span>
          </div>
        </div>
      </div>
    );
  }

  return (component);
}

const MessageDisplayWarning = ({message}) => {
  let component = <div className="message-display"></div>;
  let cssClass = 'alert alert-warning';

  if (message) {
    component =  (
      <div className="message-display">
        <div className="text-center">
          <div className={cssClass}>
            <span dangerouslySetInnerHTML={funcs.createMarkup(message)}></span>
          </div>
        </div>
      </div>
    );
  }

  return (component);
}

const MyPreferenceMailChimpCategoryDisplay = ({category, onItemSelected, box=false}) => {
  return (
    <>
      <p className="text-muted">{category.name}</p>
      <div className="row ml-1">
        <CustomCheckBoxList
          items={category.interests}
          onItemSelect={onItemSelected}
          box={box}
        />
      </div>
    </>
  );
}

const MyPreferencesTitle = ({textVariants}) => {
  return (
    <section className="financial-goals ">
      <div className="container-fluid left-container-padding right-container-padding">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h3>{textVariants[pages.MYPREFERENCES + textVariants.textVariantSeparator + "title"]}</h3>
            <p>{textVariants[pages.MYPREFERENCES + textVariants.textVariantSeparator + "subtitle"] }</p>
          </div>
        </div>
      </div>
    </section>
  );
}

class MyPreferencesPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      preferences: null,
      saving: false,
      loaded: false,
      message: '',
      isError: false,
      showMailchimpLink: false
    };

    this.onItemSelected = this.onItemSelected.bind(this);
    this.onSubscriptionSelected = this.onSubscriptionSelected.bind(this);
    this.onCancelAccountSelected = this.onCancelAccountSelected.bind(this);
    this.onEqfxmarketingSelected = this.onEqfxmarketingSelected.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.props.actions.initUser(true).then(d => {
      this.props.actions.isAuthenticated().then(e => {
        this.props.actions.getMenuForPage(pages.MYPREFERENCES);
        this.props.actions.getTextVariants();

        // TODO: cancel Async/API call in ComponentWillUnmount
        this.props.actions.getPreferences().then(r => {
          var pref = r || this.props.userProfile.preferences;
          var o =  Object.assign({}, pref);
          o.categories = [];

          if (pref.categories) {
            for(var c = 0; c < pref.categories.length; c++){
              o.categories.push(Object.assign({}, pref.categories[c]));

              if(pref.categories[c].interests){
                o.categories[c].interests = [];
                for(var i = 0; i < pref.categories[c].interests.length; i++){
                  o.categories[c].interests.push(Object.assign({}, pref.categories[c].interests[i]));
                }
              }
            }
          }

          this.setState({preferences: o, loaded : true});

        }).catch(e => {
          var msg =  null;

          if (e.status == 400) {
            msg = this.props.textVariants[pages.MYPREFERENCES + this.props.textVariants.textVariantSeparator + "400_ErrorWhenRequestingData"];

          } else if (e.status == 404) {
            msg = this.props.textVariants[pages.MYPREFERENCES + this.props.textVariants.textVariantSeparator + "404_SyncProblemWithMailchimp"];

          } else if (e.status == 500) {
            msg = this.props.textVariants["Api_Generic"];

          } else {
            msg = this.props.textVariants["Api_Unknown"];
          }

          this.setState({saving: false, message: msg, isError: true, loaded: true});
        });
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isAuthenticated) {
      // go back to login
      this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.RouteMyPreferences);
    }
  }

  onItemSelected(evt){
    // target: {
    //   type: 'checkbox',
    //   name: this.props.name,
    //   id: this.props.id,
    //   isChecked: !this.state.isChecked

    let isChecked = evt.target.isChecked;

    let preferences = this.state.preferences;
    let found = false;
    for (var i = 0; i <preferences.categories.length && !found; i++) {
      var category = preferences.categories[i];
      if(category){
        for (var j = 0; j < category.interests.length && !found; j++) {
          var interest = category.interests[j];
          if(interest["id"] == evt.target.id){
            interest["selected"] = isChecked;
            found = true;
          }
        }
      }
    }

    this.setState({preferences: preferences, saving: false, message: '', isError: false});
  }

  onSubscriptionSelected(evt){
    // target: {
    //   type: 'checkbox',
    //   name: this.props.name,
    //   id: this.props.id,
    //   isChecked: !this.state.isChecked
    let isChecked = evt.target.isChecked;
    let showMailchimpLink = false;
    if (!isChecked) {
      showMailchimpLink = true;
    }

    var preferences = this.state.preferences;
    preferences.isSubscribed = !isChecked;
    this.setState({preferences: preferences, saving: false, message: '', isError: false, showMailchimpLink: showMailchimpLink});
  }

  onEqfxmarketingSelected(evt){
    let isChecked = evt.target.isChecked;        
    var preferences = this.state.preferences;
    preferences.eqfxmarketing = !isChecked;
    this.setState({preferences: preferences, saving: false, message: '', isError: false});
  }

  onCancelAccountSelected(evt){
    let isChecked = evt.target.isChecked;

    var preferences = this.state.preferences;
    preferences.isActive = !isChecked;
    this.setState({preferences: preferences, saving: false, message: '', isError: false});
  }

    onSave(event){
      event.preventDefault();

      let errors = {};

       this.setState({saving : true, diplaySavedMessage: false});

       this.props.actions.setPreferences(this.state.preferences).then(
          r => {

            this.setState({ saving: false, message: this.props.textVariants['Gen_Save_Success'], isError: false });

            // log the user out if it was selected as well
            if(!this.state.preferences.isActive){
              this.props.actions.logout();
              // setTimeout(() => {
              // }, 2000);
            }


        }).catch(e => {
          var msg =  null;
           if(e.status == 400){
              msg = this.props.textVariants[pages.MYPREFERENCES + this.props.textVariants.textVariantSeparator + "400_ErrorWhenSaving"];
           }
           else if(e.status == 500){
            msg = this.props.textVariants["Api_Generic"];
           }
           else{
               msg = this.props.textVariants["Api_Unknown"];
           }

            this.setState({saving: false, message: msg, isError: true});
        });

    }

  onCancel(event){
    event.preventDefault();
    const browserHistory = createBrowserHistory();
    browserHistory.goBack();
  }

  render() {
    let preferences = this.state.preferences;
    let mainComponent = <div></div>;
    let textVariants = this.props.textVariants;

    let pageContent = (
      <LoadingIndicator
        textPosition='bottom'
        text={this.props.textVariants[pages.ABOUTME + this.props.textVariants.textVariantSeparator + "loading_text"]}
        size='200'
      />
    );

    if (this.state.loaded) {
      if (this.state.isError && !this.state.preferences) {
        pageContent = (
          <div>
            {this.state.message && <MessageDisplay
                message={this.state.message}
                isError={this.state.isError}
              />}
          </div>
        );

      } else {
        pageContent = (
        <section className="page-preference">
          <div className="container">
            <div className="row">
            <div className="col-xl-11 offset-xl-1 col-12 preference-content">
              <form className="d-block">
              <div className="row-12 d-md-flex mb-2">
                <div className="col-xl-4 col-lg-6 col-12 mt-2 title" style={{zIndex:"-1"}}>
                  <h2><img src={ImagesPath.ImgIconPreferences} alt="" className="img-fluid"/>Communication Preferences</h2>
                </div>
                <div className="col-xl-7 col-lg-6 col-12 subscription-column">
                  { preferences && preferences.categories && preferences.categories.map((c, index) =>
                          <>
                          {index == 0 && (<MyPreferenceMailChimpCategoryDisplay
                            category={c}
                            onItemSelected={this.onItemSelected}
                            key={c.id}
                            box={true}
                          />)}
                          {index != 0 && (<MyPreferenceMailChimpCategoryDisplay
                            category={c}
                            onItemSelected={this.onItemSelected}
                            key={c.id}
                          />)}
                          </>
                  )}
                </div>
              </div>
              <div className="row-12 d-md-flex mb-2">
                <div className="col-xl-4 col-lg-6 col-12 mt-2 title pt-3" style={{zIndex:"-1"}}>
                  <h2><img src="images/icons/subscription.svg" alt="" className="img-fluid"/>Equifax and its partners' communications</h2>
                </div>
                <div className="col-xl-7 col-lg-6 col-12 subscription-column mt-md-3">
                  <CustomCheckBox
                      name={textVariants[pages.MYPREFERENCES + textVariants.textVariantSeparator + "eqfxMarketingItem"] }
                      checked={!preferences.eqfxmarketing}
                      onClick={this.onEqfxmarketingSelected}
                      id="eqfxmarketing" />                                                                  
                </div>
              </div>
              <div className="row-12 d-md-flex mb-2">
                <div className="col-xl-4 col-lg-6 col-12 mt-2 title pt-3" style={{zIndex:"-1"}}>
                  <h2><img src="images/icons/subscription.svg" alt="" className="img-fluid"/>All type of emails</h2>
                </div>
                <div className="col-xl-7 col-lg-6 col-12 subscription-column mt-md-3">
                  <CustomCheckBox
                      name={textVariants[pages.MYPREFERENCES + textVariants.textVariantSeparator + "subscriptionItem"] }
                      checked={!preferences.isSubscribed}
                      onClick={this.onSubscriptionSelected}
                      id="subscription" />
                        
                  {this.state.showMailchimpLink &&
                    <MessageDisplayWarning
                        message={ textVariants["mailChimpLinkSuggestion"].replace('{LINK}', '<a target="_blank" href="' + GlobalVariables.MAILCHIMP_RESUBSCRIBELINK + '">click here</a>') }
                    />
                  }
                </div>
              </div>
              <div className="row-12 d-md-flex mb-2">
                <div className="col-xl-4 col-lg-6 col-12 mt-2 title pt-3" style={{zIndex:"-1"}}>
                  <h2><img src="images/icons/subscription.svg" alt="" className="img-fluid"/>Account Management</h2>
                </div>
                <div className="col-xl-7 col-lg-6 col-12 subscription-column mt-md-3">
                      <CustomCheckBox
                        name={textVariants[pages.MYPREFERENCES + textVariants.textVariantSeparator + "accountItem"] }
                        checked={!preferences.isActive}
                        onClick={this.onCancelAccountSelected}
                        id="account"
                      />
                      {this.state.message && <MessageDisplay
                        message={this.state.message}
                        isError={this.state.isError}
                    />}
                </div>
              </div>

                <div className="row-12 d-md-flex mb-2 justify-content-center">
                <button
                      type="button"
                      onClick={this.onSave}
                      className={this.state.saving?"btn btn-primary m-3 disabled":"btn btn-primary m-3"}>
                      {textVariants["Btn_Save"]}
                </button>
                {/* <button type="button" style={{width:'150px'}}
                  onClick={this.onCancel}
                  className="btn btn-secondary m-3">
                  {textVariants["Btn_Cancel"]}
                </button> */}
                </div>

              </form>
              </div>
            </div>
          </div>
        </section>
        );
      }
    }

    return (
      <>
        <span id="dashboard-menu"></span>
        <div className="page-bg page-nottitle">
          <Menu menuItemList={this.props.menuItemList} renderMobile={true}/>
        </div>
        {pageContent}
        <FooterSmallSection></FooterSmallSection>
      </>
    );
  }
}

MyPreferencesPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];

  if (state.menu) {
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.MYPREFERENCES]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.MYPREFERENCES];
     }
   }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile: state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPreferencesPage);
