
import $ from 'jquery'
import 'bootstrap'
import offside from 'offside-js'


export const tonikInitMenu = () =>{

// mobile menu
if ($('#menu-mobile').length > 0) {
  let myOffside = offside('#menu-mobile', {
    slidingElementsSelector: '#app',
    buttonsSelector: '#button-mobile'
  })

  $('.site-overlay').on('click touchstart', function (e) {
    let container = $('.offside')
    if (!container.is(e.target) && container.has(e.target).length === 0 && !$('#button-mobile').is(e.target) && $('#button-mobile').has(e.target).length === 0) {
      myOffside.close()
    }
  })
}
// mobile menu END

}
