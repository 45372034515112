import * as ImagesPath from '../../config/imagesPath';

const genY = {
  title: "Generation Y",
  age : "18-34",
  img: ImagesPath.ImgScoreMap_GenerationY
};

const genX = {
  title: "Generation X",
  age : "35-44",
  img: ImagesPath.ImgScoreMap_GenerationX
};

const boomer = {
  title: "Baby Boomers",
  age : "45-64",
  img: ImagesPath.ImgScoreMap_GenerationBoomer
};

const builder = {
  title: "Builders",
  age : "65+",
  img: ImagesPath.ImgScoreMap_GenerationBuilder
};

const _generationsModel = {};

_generationsModel["genY"] = genY;
_generationsModel["genX"] = genX;
_generationsModel["boomer"] = boomer;
_generationsModel["builder"] = builder;

export const generationsModel = _generationsModel;