import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';
import HelperApi from '../../api/HelperApi';
import ProductApi from '../../api/mockProductApi';
//import ReactGA from 'react-ga';

const dataLayer = window.dataLayer;

export function trackReferralFromPartner(tid, userIdentifier) {
  return (dispatch, getState) => {

    if(tid){
          //var state = getState();
          var referralRequest = { tid: tid, userIdentifier: userIdentifier};
          return ProductApi.trackReferralClick(referralRequest);
    }
  };
}

function setUserId(state){

  if(dataLayer && state && state.userProfile && state.userProfile.identifier ){
      dataLayer.push({user_id: state.userProfile.identifier});
      //ReactGA.set({ userId: state.userProfile.identifier });
      //ReactGA.set({ dimension1: state.userProfile.identifier });
  }
}

function trackGaEvent(category, action, label, value){

  var obj = {
      //hitType: 'event',
      event: 'custom',
      event_value: value,
      event_category: category,
      event_action: action,
      event_label: label      
  };

  try{
    if(dataLayer){
      dataLayer.push(obj);
    }
    //ReactGA.send(obj);
  }
  catch(e){
    console.log(e);
  }  
}

function trackFbEvent( keyString){

  try{
    if(window["fbq"]){
       window["fbq"]('trackCustom', keyString);
    }
  }
  catch(e){
    console.log(e);
  }

}

export function trackPageView() {
  return (dispatch, getState) => {
    var state = getState();

    //ReactGA.set({ page: window.location.pathname });
    setUserId(state);

    //ReactGA.pageview(window.location.pathname);

    if(window["fbq"]){
       window["fbq"]('track', 'PageView', {page: window.location.pathname });
    }

    return null;

  };
}


export function trackButtonClick(page, btnName) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Click-' + page, 'click', btnName || '');
  return null;
  };
}

export function trackGetScoreButtonClick(page, btnName) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('GetScore', 'click', btnName || '');
    trackFbEvent('GetScore_Click');
    trackFbEvent('GetScore_Click_' + btnName);

  return null;
  };
}

export function trackScoreMapSearch(source, postcode) {
  return (dispatch, getState) => {
    const state = getState();
    setUserId(state);

    trackGaEvent('ScoreMap', 'search-' + source, postcode || '');    
 
    return null;
  };
}


// export function trackCreditCardClick(productInfo) {
//   return (dispatch, getState) => {
//     var state = getState();
//     setUserId(state);
//
//
//     return null;
//   };
// }



export function trackAccountCreated() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Account', 'Created', 'Create My Account');
    trackFbEvent('Account_Created');
    return null;
  };
}

export function trackDashboardTab(tabName) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Dashboard', 'TabView', tabName);

    return null;
  };
}

export function trackLogin(identifier) {
  return (dispatch, getState) => {
    var state = getState();
    //ReactGA.set({ userId: identifier });
    //ReactGA.set({ dimension1: identifier });

    trackGaEvent('Account', 'Login', 'Login to Account');
    trackFbEvent('Account_Login');
    return null;
  };
}

export function trackAboutMe(action, step, actionid) {
  return (dispatch, getState) => {

    try {
          var state = getState();
          setUserId(state);

          let actionNum = parseInt(actionid, 10);
          if(actionNum){
            actionNum = null;
          }

          trackGaEvent('AboutMe', action, step, actionNum);
    }
    catch(ex){

    }

    //trackFbEvent('Account_Registered');
    return null;
  };
}

export function trackPromotionParticipated(action, step, actionid) {
  return (dispatch, getState) => {

    try {
          var state = getState();
          setUserId(state);

          trackGaEvent('Promotion', 'Participated', 'GiveAway201708');
    }
    catch(ex){

    }

    //trackFbEvent('Account_Registered');
    return null;
  };
}

export function trackUserDetailsCreated() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Account', 'Registered', 'Save ID Form');
    trackFbEvent('Account_Registered');

    // if (isExistingCustomer) {
    //   trackGaEvent('Account', 'Registered', 'Save ID Form Existing Customer');
    // }

    return null;
  };
}


export function trackUserDetailsUpdated() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Account', 'Updated', 'Update ID Form');
    trackFbEvent('Account_Updated');
    return null;
  };
}


export function trackAccountVerified() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Account', 'Verfified', 'AccountVerfified');
    trackFbEvent('Account_Verified');
    return null;
  };
}


export function trackNotFoundPage() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('NotFound', 'React', window.location.pathname);
    return null;
  };
}

export function trackScore(success) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    if(success){
      trackGaEvent('Dashboard', 'ScoreResult', 'Success');
      trackFbEvent('Score Success');
    }
    else{
      trackGaEvent('Dashboard', 'ScoreResult', 'Failure');
      trackFbEvent('Score Failure');
    }
    return null;
  };
}

export function trackReport(success) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    if(success){
      trackGaEvent('Dashboard', 'ReportResult', 'Success');
      trackFbEvent('Report Success');
    }
    else{
      trackGaEvent('Dashboard', 'ReportResult', 'Failure');
      trackFbEvent('Report Failure');
    }
    return null;
  };
}


export function trackSearchHelpCentre() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    return null;
  };
}

export function trackSearchArticles() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    return null;
  };
}

export function trackProductClick(productInfo) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Product', 'click', productInfo.productTypeId, productInfo.partnerId);
    if(productInfo.productId){
        trackGaEvent('ProductClick', productInfo.productTypeId, productInfo.partnerId, productInfo.productId);
    }
    trackFbEvent('Product_Click');
    trackFbEvent('Product_Click_' + productInfo.productTypeId);

    return null;
  };
}

export function trackScoreActionClick(actionId) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Dashboard', 'ScoreAction', actionId);

    return null;
  };
}

export function trackScoreTooltipClick() {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Dashboard', 'ScoreTooltip', 'ScoreApiChanged');

    return null;
  };
}

export function trackNoScoreActionClick(actionLabel) {
  return (dispatch, getState) => {
    var state = getState();
    setUserId(state);

    trackGaEvent('Dashboard', 'NoScoreAction', actionLabel);

    return null;
  };
}