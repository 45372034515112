import * as types from '../actions/actionTypes';
import initialState from './initialState';

import StorageHelper from '../../api/StorageHelper';


function updateLoginResult(newState, loginResult, includeRememberMe){
  
  newState.isAuthenticated = true;
  //newState.tm = null;
  newState.redirect_uri = loginResult.redirect_uri;
  newState.csrfToken = loginResult.csrfValue;
  newState.identifier = loginResult.identifier;    
  newState.roles = loginResult.roles;

  let expires_in = loginResult.expires_in;

  let date = new Date();
  date.setTime(date.getTime() + (expires_in * 1000));

  newState.expires_at = new Date(date);

   if(includeRememberMe){
       var rememberMeInfo =  {rememberMe:false};

       if(loginResult.rememberMe != undefined){
        if(loginResult.rememberMe){
          rememberMeInfo.rememberMe = true;
          rememberMeInfo.username = loginResult.username;
         }

          newState.rememberMeInfo = Object.assign({},rememberMeInfo);
          StorageHelper.storeJson('gcsRememberMe', rememberMeInfo);
       }        
   }

    let toStore = Object.assign({}, {
                                     csrfValue: newState.csrfToken,
                                     roles: newState.roles,
                                     expires_at :date.getTime(),
                                     isAuthenticated: true,
                                     identifier: newState.identifier
                                   });

    StorageHelper.storeJson('gcsLogin', toStore);

}


export default function userReducer(state = initialState.userProfile, action) {
  switch (action.type) {

    case types.PASSWORDCHANGE_SUCCESS:
       var newState = Object.assign({}, state);
       newState.redirect_uri = null;
       if(action.loginResult){

         updateLoginResult(newState, action.loginResult, true);
       }

      return newState;

    case types.GET_PREFERENCES_SUCCESS:
        var newState = Object.assign({}, state);
        var o =  Object.assign({}, state.preferences, action.preferences);
        o.categories = [];
        if(action.preferences.categories){
            for(var c = 0; c < action.preferences.categories.length; c++){
              o.categories.push(Object.assign({}, action.preferences.categories[c]));

              if(action.preferences.categories[c].interests){
                o.categories[c].interests = [];
                for(var i = 0; i < action.preferences.categories[c].interests.length; i++){
                  o.categories[c].interests.push(Object.assign({}, action.preferences.categories[c].interests[i]));
                }
              }
            }
        }

        newState.preferences = o;
      return newState;

    case types.SET_PREFERENCES_SUCCESS:
        var newState = Object.assign({}, state);
        var newState = Object.assign({}, state);
        var o =  Object.assign({}, state.preferences, action.preferences);
        o.categories = [];
        if(action.preferences.categories){
            for(var c = 0; c < action.preferences.categories.length; c++){
              o.categories.push(Object.assign({}, action.preferences.categories[c]));

              if(action.preferences.categories[c].interests){
                o.categories[c].interests = [];
                for(var i = 0; i < action.preferences.categories[c].interests.length; i++){
                  o.categories[c].interests.push(Object.assign({}, action.preferences.categories[c].interests[i]));
                }
              }
            }
        }

        newState.preferences = o;
      return newState;

    case types.INITAPP:

          var newState = Object.assign({}, state);
          newState.urlPrefix = action.urlPrefix;
      return newState;

    case types.REGISTER_SUCCESS:

        var newState = Object.assign({}, state);
        newState.userDetails = Object.assign({}, action.registration.userToRegister, {identifier: action.registration.registrationResult.identifier});
        newState.identifier = action.registration.registrationResult.identifier;
      return newState;

      case types.INIT_USER_SUCCESS:
      
          var newState = Object.assign({}, state);
          newState.rnd = action.initUser.rnd;
          newState.isPromotionEnabled = action.initUser.isPromotionEnabled;
          newState.isPromotionEnabledForUser = action.initUser.isPromotionEnabledForUser;
          newState.isPromotionNewUser = action.initUser.isPromotionNewUser;
          newState.isPromotionEnabledLoaded = true;
          newState.extras = action.initUser.extras;

          newState.isThemeLoaded = true;
          if(action.themes){
              newState.themes = action.themes;
          }
        return newState;

      case types.USER_PROFILE_DISPLAYNAME:

            if(action["displayName"]){
              var newState = Object.assign({}, state);
              newState.displayName = action["displayName"];
              return newState;
            }
          return state;

    case types.PROMOTION_PARTICIPATED:
          var newState = Object.assign({}, state);
          newState.isPromotionEnabled = false;
          newState.isPromotionEnabledForUser = false;
          newState.isPromotionNewUser = action.initUser.isPromotionNewUser;
          newState.isPromotionEnabledLoaded = true;
      return newState;

    case types.LOGOUT_SUCCESS:
        var newState = Object.assign({}, state);

         newState.isAuthenticated = false;

         newState.csrfToken = null;
         newState.expires_at = null;
         newState.identifier = null;
         newState.userDetails = null;
         newState.roles = null;
         newState.isPromotionEnabledForUser = false;
         newState.isPromotionEnabledLoaded = false;
         newState.isPromotionNewUser = false;
         console.log('types.LOGOUT_SUCCESS');
         StorageHelper.storeJson('gcsLogin', {});
         //todo: save in local storage / cookie

      return newState;


      case types.IS_AUTHENTICATED_SUCCESS:
          var newState = Object.assign({}, state);
          let isAuthenticatedResponse = action.isAuthenticatedResponse;
          newState.isAuthenticated = isAuthenticatedResponse.isAuthenticated;
          newState.identifier = isAuthenticatedResponse.identifier;          
          newState.roles = isAuthenticatedResponse.roles;

          if(!newState.isAuthenticated){

            newState.userDetails = null;
            newState.csrfToken = null;
            newState.expires_at = null;
            newState.identifier = null;
            newState.roles = null;
            //console.log('types.IS_AUTHENTICATED_SUCCESS not auth' );
            StorageHelper.storeJson('gcsLogin', {});
          }

        return newState;


    case types.CREATEACCOUNT_SUCCESS:

          var newState = Object.assign({}, state);
          newState.username = action.accountResult.username;

          var rememberMeInfo = {
              rememberMe: action.accountResult.rememberMe,
              username: action.accountResult.username
          };

           StorageHelper.storeJson('gcsRememberMe', rememberMeInfo);

      return newState;

    case types.LOGIN_INITIALIZE_STORE:

          let loginInLocalStorage = StorageHelper.getJson('gcsLogin');                    

          var newState = Object.assign({}, state);
          if(loginInLocalStorage.hasOwnProperty('csrfValue'))
          {
              if(loginInLocalStorage.expires_at){
                 newState.expires_at = new Date(loginInLocalStorage.expires_at);
                 if(Date.now() <= loginInLocalStorage.expires_at){
                   newState.isAuthenticated = loginInLocalStorage.isAuthenticated;
                   newState.csrfToken = loginInLocalStorage.csrfValue;
                   newState.identifier = loginInLocalStorage.identifier;
                   newState.roles = loginInLocalStorage.roles;
                 }
              }

          }

          let rememberMeInfo2 = StorageHelper.getJson('gcsRememberMe');
          if(rememberMeInfo2 && rememberMeInfo2["rememberMe"]){
              newState.rememberMeInfo = Object.assign({}, rememberMeInfo2);
          }

      return newState;

    case types.LOGIN_SUCCESS:

          var newState = Object.assign({}, state);

          if(action.loginResult){

            updateLoginResult(newState, action.loginResult, true);
          }

      return newState;

      case types.LOGIN_TM_SUCCESS:
      var newState = Object.assign({}, state);

        if(action.tmResponse){
          newState.tm = action.tmResponse.id;
        }

      return newState;

      case types.LOADUSERDETAILS_SUCCESS:

           var newState = Object.assign({}, state);
           newState.userDetails = Object.assign({}, action.userDetails);
        return newState;

      case types.UPDATEUSERDETAILS_SUCCESS:

           var newState = Object.assign({}, state);
           newState.userDetails = Object.assign({}, action.userDetails);

           // it needs to refresh the score data


        return newState;


        case types.REGISTERING_USERNAME:

        var newState = Object.assign({}, state);
          newState.registeringUsername = action.username;
          newState.registeringRememberMe = action.rememberMe;
        return newState;

  case types.GET_EXISTING_CUSTOMER_SUCCESS:
    var newState = Object.assign({}, state);
    var newCustomerState = Object.assign({}, newState.existingCustomer);
    newCustomerState["loaded"] = true;
    newCustomerState["hasError"] = false;
    newCustomerState["detail"] = action.existingCustomer;
    newState.existingCustomer = newCustomerState;

    return newState;

  case types.GET_EXISTING_CUSTOMER_ERROR:
    var newState = Object.assign({}, state);
    var newCustomerState = Object.assign({}, newState.existingCustomer);
    newCustomerState["loaded"] = true;
    newCustomerState["hasError"] = true;
    newState.existingCustomer = newCustomerState;

    return newState;

  case types.GET_CUSTOMER_HOMESHOW_CODE_SUCCESS:
    var newState = Object.assign({}, state);
    var homeShowState = Object.assign({}, newState.homeShow);

    homeShowState.loaded = true;
    homeShowState.hasError = false;
    homeShowState.code = action.code;

    newState.homeShow = homeShowState;
    
    return newState;
  
  case types.GET_CUSTOMER_HOMESHOW_CODE_ERROR:
    var newState = Object.assign({}, state);
    var homeShowState = Object.assign({}, newState.homeShow);

    homeShowState.loaded = true;
    homeShowState.hasError = true;

    newState.homeShow = homeShowState;
    
    return newState;

  default:
    return state;
  }
}
