import * as pages from './pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';


export const pagesVariants = {};
pagesVariants.textVariantSeparator = "$$";

let productDisclaimerText =  "By clicking \"Get Featured Deal\" or \"Get my deal\" or on the product icons above I consent to being contacted by GetCreditScore, their partners, Equifax and their related entities and for them to use and disclose my personal information for business and marketing purposes. I can opt-out of direct marketing by contacting GetCreditscore or any of the above entities directly. If you apply for a product from one of our partners as a result of a referral from GetCreditScore, we may receive a commission from that partner for the referral.";

// let loginTermsAndCs = "<p>By clicking 'Login', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia Information" +
// "Services and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's Privacy Policy and Terms of Use." +
// "</p>";

let loginTermsAndCs = "<p>By clicking 'Login', I authorise GetCreditScore to obtain my credit score and, if I’ve elected, an overview of my personal credit file from Equifax Australia Information Services and Solutions Pty Limited</p>";

let loginTermsAndCsLanding = "<p>By clicking 'GET MY CREDITSCORE NOW', I authorise GetCreditScore to obtain my Equifax credit score. I accept GetCreditScore's <a target='_blank' href='/privacy-policy'>Privacy Policy</a> and <a target='_blank' href='/terms-of-use'>Terms of Use</a> " +
"and agree that GetCreditScore and its partners may contact me for marketing purposes. I understand I can unsubscribe at any time by updating <a target='_blank' href='/my-preferences'>my preferences</a>.</p>";

let loginTermsAndCsMobileUpdate = "<p>By clicking 'UPDATE', I authorise GetCreditScore to obtain my Equifax credit score. I accept GetCreditScore's <a target='_blank' href='/privacy-policy'>Privacy Policy</a> and <a target='_blank' href='/terms-of-use'>Terms of Use</a> " +
"and agree that GetCreditScore and its partners may contact me for marketing purposes. I understand I can unsubscribe at any time by updating <a target='_blank' href='/my-preferences'>my preferences</a>.</p>";

// let loginTermsAndCsLanding = "<p>By clicking 'GET MY CREDITSCORE NOW', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia Information\
// Services and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's <a target='_blank' href='/privacy-policy'>Privacy Policy</a> and <a target='_blank' href='/terms-of-use'>Terms of Use</a>.\
// </p><p>I consent to being contacted by GetCreditScore and their partners, Equifax and their related entities, and for them to use and disclose my personal information for business and marketing purposes.\
// I can opt out of direct marketing by any of the above entities by contacting the entities at any time.\
// </p>";

let activateAccTermsAndCs = "<p>By clicking 'Activate Account', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia Information" +
"Services and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's Privacy Policy and Terms of Use." +
"</p><p>I consent to being contacted by GetCreditScore and their partners, Equifax and their related entities, and for them to use and disclose my personal information for business and marketing purposes." +
"I can opt out of direct marketing by any of the above entities by contacting the entities at any time." +
"</p>";

let homeLoanAssistantTermsAndCs = "<p>By clicking 'Create My Account' or 'Login', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia Information" +
"Services and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's Privacy Policy and Terms of Use." +
"</p>";

let partnerConsent_Toyota = "<p>By clicking 'I consent', I authorise GetCreditScore Pty Limited (GetCreditScore) to obtain my credit score from Equifax Australia Information" +
"Services and Solutions Pty Limited (Equifax). I acknowledge and accept GetCreditScore's Privacy Policy and Terms of Use." +
"</p><p>I consent to being contacted by GetCreditScore and their partners, Equifax and their related entities, and for them to use and disclose my personal information for business and marketing purposes." +
"I can opt out of direct marketing by any of the above entities by contacting the entities at any time." +
"</p>";

let promoGiveAway2017TermsAndCs = "<p>I confirm my details are correct. AU18+ only. Starts: 01/08/17 9am AEST. Ends: 31/08/17 11:59 pm AEST. Max 1 entry form submission/person. <a target='_blank' href='/promotion-terms-and-conditions'>Terms & Conditions</a> apply</p>";

pagesVariants["Api_Generic"] = "An unexpected error occurred when trying to perform your request";
pagesVariants["Api_Unknown"] = "An unexpected error occurred";
pagesVariants["Btn_Ok"] = "OK";
pagesVariants["Btn_Login"] = "LOGIN";
pagesVariants["Btn_GetMyScoreNow"] = "GET MY CREDITSCORE NOW";
pagesVariants["Btn_CreateAccount"] = "CREATE MY ACCOUNT";
pagesVariants["Btn_Save"] = "SAVE";
pagesVariants["Btn_Cancel"] = "CANCEL";
pagesVariants["Btn_Send"] = "SEND";
pagesVariants["Btn_Start"] = "START";
pagesVariants["Btn_Quote"] = "QUOTE";
pagesVariants["Btn_GetScoreHistory"] = "GET SCORE HISTORY";
pagesVariants["Btn_LearnMore"] = "LEARN MORE";
pagesVariants["Btn_GetScore"] = "GET SCORE";
pagesVariants["Btn_GetMyScore"] = "GET MY SCORE";
pagesVariants["Btn_GetMyScore" + "promo"] = "GET MY SCORE & WIN";
pagesVariants["Btn_ViewMore"] = "VIEW MORE";
pagesVariants["Btn_NextSteps"] = "NEXT STEPS";
pagesVariants["Btn_IConsent"] = "I CONSENT";
pagesVariants["Btn_IDontConsent"] = "I DO NOT CONSENT";
pagesVariants["Btn_Reset"] = "RESET";
pagesVariants["Btn_ChangePassword"] = "SAVE MY NEW PASSWORD";
pagesVariants["Btn_ForgotPassword"] = "RESET MY PASSWORD";
pagesVariants["Api_RetrievingProfile_Error"] = "Uh oh - we've encountered an issue in trying to retrieve your profile. We're trying to fix it right now, so please try again shortly or contact us if the issue remains.";
pagesVariants["Back_to_previous_page"] = "Back to My Deals";
pagesVariants["Error_cant_find_address"] = "We can't find your address";
pagesVariants["Error_localStorage_Missing"] = "Sorry - we cannot validate your device whilst using Private Mode - Please try logging in with a normal browser window";

pagesVariants["Gen_Save_Success"] = "Your information was saved";

let Api_Equifax_OtherLinks = "<ul>" +
"<li><a href='/articles'>Money Savings tips</a></li>" +
"<li><a href='/#product-section'>Money Saving offers</a></li>" +
"<li><a href='/helpcentre'>FAQ's on credit scores & reports</a></li>" +
"</ul>";

pagesVariants["Api_Equifax_Apply_Down"] = "Our link to Equifax is currently under maintenance so we are unable to retrieve your credit information at the moment.";
pagesVariants["Api_Equifax_Apply_TryLater"] = "Equifax is processing many requests now and it will take a bit more time than usual to retrieve your credit information. Please try again in a few minutes.";
pagesVariants["Api_Equifax_Apply_NoFile"] = "We could not find your credit information with the details provided. Please verify your details or contact us";
pagesVariants["Api_Equifax_Apply_BadRequest"] = "We could not find your credit information with the details provided. Please verify your details or contact us";


pagesVariants["Api_Equifax_Down"] = "<h2>Sorry!</h2><p>Our link to Equifax is currently under maintenance so we are unable to retrieve your credit score at the moment.</p>" +
"<h2>What should I do?</h2>" +
"<p>It won't take long to resolve, but you'll need to log in at a later time to try again.</p>" +
"<h2>What can I do in the meantime?</h2>" +
"<p><strong>Did you know we offer:</strong></p>" +
 Api_Equifax_OtherLinks;

pagesVariants["Api_Equifax_TryLater"] = "<h2>Sorry!</h2><p>Equifax is processing many requests now and it will take a bit more time than usual to retrieve your credit score.</p>" +
"<h2>What should I do?</h2>" +
"<p>Please try to refresh the page a bit later to see if your score could be retrieved.</p>" +
"<h2>What can I do in the meantime?</h2>" +
"<p><strong>Did you know we offer:</strong></p>" +
Api_Equifax_OtherLinks;

pagesVariants["partner_title_Toyota"] = "Please provide consent to get your credit score";
pagesVariants["partner_consent_Toyota"] = partnerConsent_Toyota;


pagesVariants["score_disclaimer"] = "The credit score is based on the Equifax credit score model";

pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "top_title"] = "Get unlimited access to your credit score, dashboard, tools, insights and personalised offers.";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "login"] = "Login";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccount"] = "Create Account";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "rememberMe"] = " Remember me";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "forgotPassword"] = "Forgotten password";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "loginTandCs"] = loginTermsAndCs;
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "loginTandCs" + "promo"] = loginTermsAndCs + promoGiveAway2017TermsAndCs;

pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "incorrectUserOrPass"] = "Uh oh! Incorrect username or password.  Please check your details and try again, or click Forgotten password below to reset your password.";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountErrorUserAlreadyExists"] = "There is already a user with your email. ";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountErrorUserAlreadyExistsLinkText"] = "Click here to login instead.";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountConfirmationTitle"] = "Confirmation";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountConfirmationText"] = "<p class='text'>Your request for an account is being processed.</p><p class='text'>You should receive an email in the provided email address with instructions on how to proceed</p>";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountErrorBadRequest"] = "The data you have provided is incorrect or incomplete. Please try again.";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "createAccountErrorRegistrationInProcess"] = "We detected an application in progress. We have sent you the validation email again";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "user_details_not_present"] = "Please click on Create Account to finish your registration process";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "threatMetrix_breach"] = "Your account has been locked. Please <a href='/contact-us'>contact us</a> to activate";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "tmNotLoaded"] = "There seems to be a problem with your session. Please click <a href='/login'>here</a> to refresh the page and try again.";

pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "noAccount"] = "No account yet? ";
pagesVariants[pages.LOGIN + pagesVariants.textVariantSeparator + "signUpHere"] = "Sign up here";


pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header"] = 'Almost there!';
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "tcsAndCs"] = loginTermsAndCsLanding;
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "tcsAndCs" + "promo"] = loginTermsAndCsLanding + promoGiveAway2017TermsAndCs;
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account"] = 'Create your FREE account';
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_1"] = "  It’s simple and only takes a minute";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_2"] = "  Unlimited access to your credit score";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_3"] = "  Track & monitor your score each month";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_dontworry"] = "Don't worry, you only need to do this once!";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_already_member_part1"] = "Already a member?";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "header_create_free_account_already_member_part2"] = "Login here";



pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "description"] = "We only need a few more details, so we can accurately identify you.  Once you complete this form & verify your email, you'll have access to your new member dashboard where you can monitor and track your credit score.";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "do_once"] = "Don't worry, you'll only need to do this once!";


pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "registrationCompleteMsgTitle"] = "Check your email!";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "registrationCompleteMsgText"] = "We've sent a message to the email you gave us. Open it up and click Activate Account.  We'll take it from there.";

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "409_startAgain"] = "Your previous registration has expired, can you please try again";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "300_userNotVerified"] = "There is already a user registered with your email. We will send you an email in case your account is not verified otherwise please try to login or reset your password";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "302_userExists"] = "Looks like there is already a member registered with your email. You can Login to your dashboard <a href='/login'>here</a>.";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "400_badRequest"] = "Please check that the information provided is correct";

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "first_name_label"] = "FIRST NAME*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "middle_name_label"] = "MIDDLE NAME";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "last_name_label"] = "LAST NAME*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "dob_label"] = "DATE OF BIRTH*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "address_label"] = "YOUR ADDRESS*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "use_prev_address_label"] = "I have lived here for less than 3 years";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "prev_address_label"] = "PREVIOUS ADDRESS";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "mobile_label"] = "MOBILE NUMBER*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "license_label"] = "DRIVER'S LICENCE NUMBER*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "gender_label"] = "GENDER*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"email_label"] = "EMAIL*";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"password_label"] = "PASSWORD*";

pagesVariants["enter_email"] = "Enter Email";
pagesVariants["enter_password"] = "Enter Password";

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "first_name_plh"] = "First name";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "middle_name_plh"] = "Middle name";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "last_name_plh"] = "Last name";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "address_plh"] = "Start typing and pick from the list";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "prev_address_plh"] = "Start typing and pick from the list";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "mobile_plh"] = "Mobile number";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "license_plh"] = "Driver's licence number";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"password_plh"] = "Password";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"email_plh"] = "Email";

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"email_tooltip"] = {linkText: " Why do I need to provide this?", helpText: "We’ll send you credit score alerts each month to help you track and improve your credit score"};
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"license_tooltip"] = {linkText: " Why do I need to provide this?", helpText: "We use this to verify your identity and ensure that only you can access your credit score"};
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"password_tooltip"] = {linkText: " Why do I need this?", helpText: "By creating an account you get unlimited access to your credit score"};
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"address_tooltip"] = {linkText: " No address found?", helpText: "Just enter your full address like '12 Smith Street, Richmond, VIC 3121'"};

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "save_btn"] = "SAVE";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "cancel_btn"] = "CANCEL";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "saved_message"] = "Your information has been saved!";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"validation_error"] = "Oops, looks like a field above has an issue.";
pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator +"validation_error_instruction"] = "Please scroll up and check your info.";

pagesVariants[pages.REGISTER + pagesVariants.textVariantSeparator + "new_member_error"] = "User does not exist. Hmm.. Something went wrong in retrieving your details. Please complete the form below to see your credit score.";

pagesVariants[pages.VALIDATEEMAIL + pagesVariants.textVariantSeparator + "resultTitle"] = "INFORMATION";
pagesVariants[pages.VALIDATEEMAIL + pagesVariants.textVariantSeparator + "succeed"] = "Click below to login to your member dashboard.";
pagesVariants[pages.VALIDATEEMAIL + pagesVariants.textVariantSeparator + "fail"] = "We couldn't validate your email account. Please try to register again. Contact Us if you continue to experience issues.";
pagesVariants[pages.VALIDATEEMAIL + pagesVariants.textVariantSeparator + "user_not_present"] = "In order to validate your account, you need to complete your registration <a href='/create-account'>here</a>";
pagesVariants[pages.VALIDATEEMAIL + pagesVariants.textVariantSeparator + "mailChimpLinkSuggestion"] = "We detected that you unsubscribed from us before, therefore you will need to give consent to our email server provider by cliking the START and complete their process";


pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "reset_password_title"] = "Set your new password";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "confirmationTitle"] = "Confirmation";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "confirmationText"] = "<p class='text'>Your password has been updated.</p>";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "error_401"] = "Your login has expired. You need to login again in order to set your new password";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "error_oldPasswordRequired"] = "Old password is required";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "error_passwordMismatch"] = "Your new passwords do not match";
pagesVariants[pages.CHANGEPASSWORD + pagesVariants.textVariantSeparator + "goBackToDashboard"] = "Go back to dashboard";



pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "top_title"] = "Forgot your password?";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "content"] = "<p class='text'>Don't stress.  We'll email you a password reset link, just enter your email address below.</p>";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "confirmationTitle"] = "Send successful!";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "confirmationText"] = "  <p class='text'>We've sent an email to your email address with a link for you to reset your password.</p><p class='text'>If the email doesn't show up soon, please check your spam/junk folder.</p>";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "error_400"] = "The email is empty or incorrect";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "goBackToLogin"] = "Go back to login";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "emailLabel"] = "EMAIL";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "emailPlaceholder"] = "Enter your email address";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "dobLabel"] = "DATE OF BIRTH";
pagesVariants[pages.FORGOTPASSWORD + pagesVariants.textVariantSeparator + "dobTooltip"] = {linkText: " Why do I need to provide this?", helpText: "We need extra verification before resetting your password. In some browser the format is yyyy-mm-dd."};

pagesVariants["product_disclaimer"] = productDisclaimerText;
pagesVariants["product_header"] = "Check out the special offers from our partners";//"Get a better deal today with the below <strong>special offers</strong>";
pagesVariants["product_title_sales"] = "See the <b>deals</b> you've <b>unlocked</b> with your <b>score<b/>";
pagesVariants["product_header_featured"] = "Featured Offers";


pagesVariants[pages.PRODUCT + pagesVariants.textVariantSeparator + "product_more_deals"] = "More deals";
pagesVariants[pages.PRODUCT + pagesVariants.textVariantSeparator + "last_product_description"] = "Keep an eye out for more GetCreditScore member benefits including more ways to save, coming soon!";
pagesVariants[pages.PRODUCT + pagesVariants.textVariantSeparator + "loading_text"] = "Your deals are loading...";

pagesVariants[pages.ABOUTME + pagesVariants.textVariantSeparator + "loading_text"] = "Your profile is loading...";
pagesVariants[pages.DEALS_HOMELOANS + pagesVariants.textVariantSeparator + "loading_text"] = "Your options are loading...";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "score_advice"] = "Score Advice";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "score_advice_update"] = "See what's helping & hurting your credit score from the below Key Contributing Factors.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "score_advice_helpcenter"] = "HELP CENTRE";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "nextsteps_carousel"] = "NEXT STEPS";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "score_loading_text"] = "Your credit score info is loading...";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "actions_loading_text"] = "Your next steps are loading...";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "HistoryNote"] = "Your score is updated monthly, upon logging in to your dashboard";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "no_score_title"] = "It seems we are unable to retrieve your score from Equifax.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "no_score_reason"] = "<p> To resolve, please take the following steps:</p>";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "no_score_title_inv"] = "It seems we are unable to retrieve your score from Equifax.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "no_score_reason_inv"] = "<br />"; //<p><br />Unfortunately we are unable to retrieve your score from Equifax without verifying your identity.</p>";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "belowAverageDescription"] = "If your credit score is between 0 to 459, your credit score range is Below Average. Your credit score would suggest it's MORE LIKELY that you will incur an adverse event such as a default, bankruptcy or court judgment in the next 12 months.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "averageDescription"] = "If your credit score is between 460 to 660, your credit score range is Average. Your credit score would suggest it's LIKELY that you will incur an adverse event such as a default, bankruptcy or court judgment in the next 12 months.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "goodDescription"] = "If your credit score is between 661 and 734, your credit score range is Good. Your credit score would suggest it's LESS LIKELY you will incur an adverse event that could harm your credit report in the next 12 months. Your odds of keeping a clean credit report over this period are better than Equifax's average credit-active population.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "veryGoodDescription"] = "If your credit score is between 735 and 852, your credit score range is Very Good. Your credit score would suggest it's UNLIKELY that you will incur an adverse event in the next 12 months that could harm your credit report. Your odds of keeping a clean credit report are 2 times better than Equifax's average credit-active population.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "excellentDescription"] = "If your credit score is between 853 and 1200, your credit score range is Excellent. Your credit score would suggest it’s HIGHLY UNLIKELY that an adverse event could harm your credit report in the next 12 months. Your odds of keeping a clean file are 5 times better than Equifax’s average population.";

pagesVariants["score_result_sell"] = "Now check what your score can unlock for you!";
pagesVariants["score_result_sell-853-1200"] = "Great work <b>{displayName}</b>! You've now <b>unlocked</b> our best <b>deals</b>!";
pagesVariants["score_result_sell-735-852"] = "Keep it up <b>{displayName}</b>! See the <b>deals</b> you've <b>unlocked</b>";
pagesVariants["score_result_sell-661-734"] = "Nice work <b>{displayName}</b>! Put your good score to work";
pagesVariants["score_result_sell-460-660"] = "Not bad <b>{displayName}</b>. Can we be of any assistance?";
pagesVariants["score_result_sell-0-459"] = "How can we help? Want to learn more about your credit score?";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "legend_score_month"] = "Your credit score as retrieved that month from Equifax.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "legend_score_month_nologin"] = "With no login this month, this is an estimated score.";

pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "different_score"] = "Different score in the new web site? This may be why.";
pagesVariants[pages.DASHBOARD + pagesVariants.textVariantSeparator + "different_score_tooltip_message"] = "All credit scores from Equifax have been updated following a change of their scoring calculation, bringing it in line with the most current model used to calculate credit scores that are disclosed to credit providers. This new model incorporates “Comprehensive Credit Reporting” which may give you more control over improving your credit score.  Read more <a href='/faq/improve-my-credit-score/#what-is-comprehensive-credit-reporting'>here</a>.";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "featured_on"] = "FEATURED ON";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score_header"] = "Get Your Credit Score - <b>It's FREE</b>";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score_header" + "promo"] = "Get Your Credit Score and Win - <b>It's FREE</b>";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score_header" + "veda"] = "Get Your Veda Credit Score - <b>It's FREE</b>";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score"] = "";//"Get a better deal today by finding out your credit score for free";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "full"] = ""; //"Get a better deal today by finding out your credit score for free";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "savvy"] = "Be savvy by finding out your credit score for free";//"Be savvy and get a better deal today by finding out your credit score for free";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "toyota"] = "Thanks to our friends at Toyota Finance.";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "clean"] = "";//"Get a better deal today by finding out your credit score for free";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "veda"] = "";//"Get a better deal today by checking your Equifax (formerly Veda) credit score now";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "smart"] = "Be credit smart about your finances and find out your credit score for free";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_free_score" + "promo"] = "";//"Get a better deal today by finding out your credit score for free<br />You’ll also have the chance to see if you’ve instantly won a prize!";

pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_started"] = "Get started - it's FREE!";
pagesVariants[pages.HOME + pagesVariants.textVariantSeparator + "get_started_text"] = "Receive & understand your credit score and access relevant financial information & offers.";

pagesVariants[pages.HOMEOHNO + pagesVariants.textVariantSeparator + "no_account_header_1"] = "<b>Oh No!</b>";
pagesVariants[pages.HOMEOHNO + pagesVariants.textVariantSeparator + "no_account_header_2"] = "It looks like you don't have an account";
pagesVariants[pages.HOMEOHNO + pagesVariants.textVariantSeparator + "no_account_header_3"] = "Complete the form below to get your credit score";

pagesVariants[pages.HELPCENTRE + pagesVariants.textVariantSeparator + "browse_topics_title"] = "BROWSE TOPICS";
pagesVariants[pages.HELPCENTRE + pagesVariants.textVariantSeparator + "search_results_faq"] = "FAQ's";
pagesVariants[pages.HELPCENTRE + pagesVariants.textVariantSeparator + "search_results_articles"] = "Articles";
pagesVariants[pages.HELPCENTRE + pagesVariants.textVariantSeparator + "title"] = "Help Centre";
pagesVariants[pages.HELPCENTRE + pagesVariants.textVariantSeparator + "title_faq"] = "Search or browse our frequently asked questions below";

pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "sidebarscore_title"] = "Credit Score";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "sidebarscore_text"] = "Get your credit score for free and enjoy the added benefits GetCreditScore can offer you. It's Free and won't hurt your credit score.";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "sidebarscore_share"] = "SHARE";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "article_resource"] = "RESOURCE";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "article_next_article"] = "NEXT ARTICLE";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "articles_title"] = "Articles";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "articles_short_title"] = "This is your communication hub to get the best insights and expert tips to creating better wealth and lifestyle";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "articles_back"] = "Back to Articles";
pagesVariants[pages.ARTICLES + pagesVariants.textVariantSeparator + "loading_text"] = "Articles are loading...";

pagesVariants[pages.CONTACTUS + pagesVariants.textVariantSeparator + "contactus_confirm_title"] = "Confirmation";
pagesVariants[pages.CONTACTUS + pagesVariants.textVariantSeparator + "contactus_confirm_message"] = "Your request has been submitted! Thank you!";
pagesVariants[pages.CONTACTUS + pagesVariants.textVariantSeparator + "contactus_return_home"] = "Return to Home page";

// pagesVariants[pages.MYDETAILSMOBILE + pagesVariants.textVariantSeparator + "save_btn"] = "UPDATE";
// pagesVariants[pages.MYDETAILSMOBILE + pagesVariants.textVariantSeparator + "cancel_btn"] = "SKIP";
// pagesVariants[pages.MYDETAILSMOBILE + pagesVariants.textVariantSeparator + "header"] = "Security Requirement to Update Mobile Numbers";
// pagesVariants[pages.MYDETAILSMOBILE + pagesVariants.textVariantSeparator + "description"] = "GetCreditScore regularly update the security features of our website in an effort to ensure your information remains secure and protected. To ensure we can validate your account, we are requesting our members review and update their contact mobile numbers. If we believe that your registered contact mobile number is false or a dummy number, your account may become disabled and you may no longer be able to use our services";
// pagesVariants[pages.MYDETAILSMOBILE + pagesVariants.textVariantSeparator + "tcsAndCs"] = loginTermsAndCsMobileUpdate;

pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "save_btn"] = "VERIFY";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "cancel_btn"] = "SKIP";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "header"] = "Identity Verification";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "description"] = "Please enter your Driver's Licence details to verify your identity.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "tcsAndCs"] = "By clicking verify we will do a DVS check";

pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_verification_tooltip"] = {linkText: " Why do I need to provide this?", helpText: "We use this to verify your identity and ensure that only you can access your credit score"};
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "400_errorOnUpdate"] = "The documentation number you have provided is either incorrect or invalid. Please check and try again.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "417"] = "Unfortunately our server is currently busy. Please try again later. We apologise for any inconvenience caused.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "error_deferred"] = "Unfortunately our server is currently busy. Please try again later. We apologise for any inconvenience caused.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "error_disabled"] = "Unfortunately our server is currently busy. Please try again later. We apologise for any inconvenience caused.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed"] = "We could not verify your identity with your registration details plus this information. Please verify your details or <a href='/contact-us' style='float:none !important;'>contact us</a>";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed_generic"] = "<b>Please try again.</b><br />Please review your details and update to match exactly with the document you are using.<br />In order to verify your identity, we use the above details.  Please check the following:<br />";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed_end"] = 'Please update the above and click <b>“Verify”</b>';
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed_medicare"] = "<ol><li>Your first name and last name are as per the document being verified<br />E.g The use of Hyphens “-” Capitals, spaces, abbreviations, two word first/last names</li><li>Check any numbers entered are in the correct order</li><li>Medicare - ensure you have the correct reference number that is against your name and make sure there are no spaces in between the numbers</li></ol><br />";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed_passport"] = "<ol><li>Your first name and last name are as per the document being verified<br />E.g The use of Hyphens “-” Capitals, spaces, abbreviations, two word first/last names</li><li>Check any numbers entered are in the correct order</li><li>Passport number - all numbers and letters have been correctly entered</li></ol><br />";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_failed_driverlicence"] = "<ol><li>Your first name and last name are as per the document being verified<br />E.g The use of Hyphens “-” Capitals, spaces, abbreviations, two word first/last names</li><li>Check any numbers entered are in the correct order</li><li>Drivers Licence - ensure you are using your Licence number and not card number</li></ol><br />";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "identity_worked"] = "We are refreshing your login. If this doesn't work please login again.";

pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "validation_error"] = "Oops, looks like a field above has an issue.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "saved_message"] = "Your information has been saved!";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator + "license_plh"] = "DRIVER'S LICENCE NUMBER";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator +"validation_error_instruction"] = "Please scroll up and check your info.";
pagesVariants[pages.MYDETAILSVERIFICATION + pagesVariants.textVariantSeparator +"refresh_identity_fails"] = "We tried to refresh your session with no luck. Please login again to refresh your profile.";


pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "header"] = "Your details & identification";
pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "description"] = "Update your details here";
pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "update_btn"] = "Update";
pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "loading_text"] = "One moment please, we're just retrieving your details...";
pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "400_errorOnUpdate"] = "There was an error updating your information.  Please refresh this page to try again.";
pagesVariants[pages.MYDETAILS + pagesVariants.textVariantSeparator + "404_userNotFound"] = "We couldn't find the user to be updated";

pagesVariants[pages.CREDITCARDS + pagesVariants.textVariantSeparator + "header"] = 'Credit Card';
pagesVariants[pages.CREDITCARDS + pagesVariants.textVariantSeparator + "Knowing_credit_score_text"] = "";//'Knowing your credit score is important as most credit card approvals tend to be based on your credit score and other factors such as income and credit card debt.'
pagesVariants[pages.CREDITCARDS + pagesVariants.textVariantSeparator + "latest_offers_text"] = 'See the latest offers from a range of providers including <strong>balance transfer offers, low rate deals</strong> and <strong>reward point offerings</strong>,  powered by RateCity.  Click on the headings to sort your results';
pagesVariants[pages.CREDITCARDS + pagesVariants.textVariantSeparator + "rateCity _text"] = 'If you apply for a credit card via RateCity as a result of a referral from GetCreditScore, we may receive a commission from RateCity for the referral.  For further details on this comparison table please contact RateCity at <a href="http://www.ratecity.com.au" target="_blank">www.ratecity.com.au</a>';

pagesVariants[pages.PERSONALLOANS + pagesVariants.textVariantSeparator + "header"] = 'Personal Loans';
pagesVariants[pages.PERSONALLOANS + pagesVariants.textVariantSeparator + "Knowing_credit_score_text"] = "Knowing your credit score may help you negotiate a better rate with some lenders."; //'Knowing your credit score is important as most persoanl loan approvals tend to be based on your credit score and other factors such as income and debt.'
pagesVariants[pages.PERSONALLOANS + pagesVariants.textVariantSeparator + "latest_offers_text"] = 'See the latest offers from a range of providers,  powered by RateCity.  Click on the headings to sort your results';
pagesVariants[pages.PERSONALLOANS + pagesVariants.textVariantSeparator + "rateCity _text"] = 'If you apply for a personal loan via RateCity as a result of a referral from GetCreditScore, we may receive a commission from RateCity for the referral.  For further details on this comparison table please contact RateCity at <a href="http://www.ratecity.com.au" target="_blank">www.ratecity.com.au</a>';


pagesVariants[pages.DEALS_CARLOANS + pagesVariants.textVariantSeparator + "header"] = 'Car Loans';
pagesVariants[pages.DEALS_CARLOANS + pagesVariants.textVariantSeparator + "Knowing_credit_score_text"] ='Easily compare Car Loan offers below to get yourself the lowest rate & best features possible!'
pagesVariants[pages.DEALS_CARLOANS + pagesVariants.textVariantSeparator + "latest_offers_text"] = 'See the latest offers from a range of providers,  powered by RateCity.  Click on the headings to sort your results';
pagesVariants[pages.DEALS_CARLOANS + pagesVariants.textVariantSeparator + "rateCity _text"] = 'If you apply for a car loan via RateCity as a result of a referral from GetCreditScore, we may receive a commission from RateCity for the referral.  For further details on this comparison table please contact RateCity at <a href="http://www.ratecity.com.au" target="_blank">www.ratecity.com.au</a>';


pagesVariants[pages.DEALS_HOMELOANS + pagesVariants.textVariantSeparator + "header"] = 'Home Loans';
pagesVariants[pages.DEALS_HOMELOANS + pagesVariants.textVariantSeparator + "Knowing_credit_score_text"] = "";//'Knowing your credit score is important as most home loan approvals include your credit score along with other factors such as income and other debts.'
pagesVariants[pages.DEALS_HOMELOANS + pagesVariants.textVariantSeparator + "latest_offers_text"] = 'See the latest offers from a range of providers including <strong>low rate deals, no upfront fee offers</strong> and <strong>redraw facility offerings</strong>, powered by RateCity. Click on the headings to sort your results';
pagesVariants[pages.DEALS_HOMELOANS + pagesVariants.textVariantSeparator + "rateCity _text"] = 'If you apply for a home loan via RateCity as a result of a referral from GetCreditScore, we may receive a commission from RateCity for the referral.  For further details on this comparison table please contact RateCity at <a href="http://www.ratecity.com.au" target="_blank">www.ratecity.com.au</a>';

pagesVariants[pages.DEALS_INSURANCE + pagesVariants.textVariantSeparator + "product_header"] = "Insurance";
pagesVariants[pages.DEALS_INSURANCE + pagesVariants.textVariantSeparator + "product_header_d"] = "Insurance, powered by";
pagesVariants[pages.DEALS_INSURANCE + pagesVariants.textVariantSeparator + "product_header_m"] = "Insurance, by";
pagesVariants[pages.DEALS_INSURANCE + pagesVariants.textVariantSeparator + "product_disclaimer"] = "Because we don't know your financial needs, we can't advise if this insurance will suit you. You should consider your needs and read the PDS before making a decision to buy insurance. View or download a copy of the Product Disclosure Statement or Financial Services Guide from our partners website. Online Discount Terms & Conditions apply (see partner website). Car insurance not available in NT. \ This advertisement is brought to you by Budget Direct Insurance.\
If you apply for a product from one of our partners as a result of a referral from GetCreditScore, we may receive a commission from that partner for the referral.";


pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "header"] = "<h2>Activate your account now!</h2><p class='subHeader'>To see your credit score.<br />It's fast, free and secure</p>";
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "header" + "promo"] = "<h2>See your credit score now!</h2><p class='subHeader'>It’s fast, free and secure.<br />PLUS you could win instantly!";
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "button_text"] = "Activate account";
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "tandcs"] = activateAccTermsAndCs;
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "tandcs" + "promo"] = activateAccTermsAndCs + promoGiveAway2017TermsAndCs;
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "point1"] = "Get your <strong>FREE</strong> Credit Score";
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "point2"] = "Won't affect your credit score";
pagesVariants[pages.ACTIVATEACCOUNT + pagesVariants.textVariantSeparator + "point3"] = "Get a better deal";

pagesVariants[pages.CONTACTUS + pagesVariants.textVariantSeparator + "title"] = "WE'D LOVE TO HEAR FROM YOU!";
pagesVariants[pages.CONTACTUS + pagesVariants.textVariantSeparator + "subTitle"] = "If you have a question about our service or just want more information about credit scores in general, please send us an email. We'll do our best to respond as quickly as possible";

pagesVariants[pages.PAGENOTFOUND + pagesVariants.textVariantSeparator + "page_not_found"] = "Page not found!";
pagesVariants[pages.PAGENOTFOUND + pagesVariants.textVariantSeparator + "go_to_main_page"] = "Go back to the main page";

pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "header"] = 'Get a better deal.';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "cc_text"] = 'GetCreditScore has done the work for you and found some of the best balance transfer credit cards on the market today.';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "betterdeal_text"]  = "Reward your credit score";//'Reward your credit score and get a better deal today!';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "disclaimer"] =  "1 Handling fee may apply from credit card provider.  Reverts back to cash rate after transfer period.  See Details for each product for full terms & conditions.<br /> 2 Terms & Conditions apply.  See Details for each product. As a result of applying for a credit card through our partners GetCreditScore may receive a commission.";//'* Terms and Conditions apply. See details for each product.<br> ** Handling fee may apply from credit card provider. See details for each product.';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "loading_text"] = "Credit cards are loading...";
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "btn_Details"] = 'Details';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "btn_Apply"] = 'Apply';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "cc_compare_text"] = 'Want to compare <strong>more</strong> cards? See our full comparison ';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "cc_btncompare_text"] = 'Compare all Credit Cards';
pagesVariants[pages.CREDITCARDSOFFERS + pagesVariants.textVariantSeparator + "cc_view_deals"] = 'VIEW DEALS';

pagesVariants[pages.EMAILSENTBLUE + pagesVariants.textVariantSeparator + "header"] = 'Check your email!';
pagesVariants[pages.EMAILSENTBLUE + pagesVariants.textVariantSeparator + "email_sent"] = "We've sent a verification email to the email address you provided.";
pagesVariants[pages.EMAILSENTBLUE + pagesVariants.textVariantSeparator + "activate_acc_message"] = 'To instantly see your credit score, open the email we sent you <br /> (be sure to check your junk mail as well) and click <b>Activate Account.</b>';
pagesVariants[pages.EMAILSENTBLUE + pagesVariants.textVariantSeparator + "activate_acc_message" + "promo"] = 'To instantly see your credit score and to see if you’ve won, open the email we sent you <br /> (be sure to check your junk mail as well) and click <b>Activate Account.</b>';



pagesVariants[pages.VALIDATEEMAILBLUE + pagesVariants.textVariantSeparator + "header"] = "<h2>Welcome!</h2><p>You're activated and ready to login and get your credit score!</p>";
pagesVariants[pages.VALIDATEEMAILBLUE + pagesVariants.textVariantSeparator + "header" + "promo"] = "<h2>Welcome!</h2><p>You're activated and ready to login and get your credit score and see if you’re an INSTANT winner!</p>";



pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "title"] = "Your Home Loan Assistant";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "short_description"] = "To get the right home loan in the easiest way possible, follow our Home Loan Assistant below, and you will be saving money in no time at all!";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "question_how_find_loan"] = "Q. How would you like to find your new home loan?";

pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "question_post_code"] = "Q2. So we can match you with your local broker, what is your post code?";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "question_loan_type"] = "Q3. And finally, what type of loan are you looking for";

pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_broker"] = "Connect me to my local broker";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_compare"] = "Let me compare from a list";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_broker_feature1"] = "- They take care of the paperwork";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_broker_feature2"] = "- Access deals the public can't get";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_broker_feature3"] = "- Can be 100% online if desired";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_compare_feature1"] = "- Search, choose & settle online";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_compare_feature2"] = "- Over 30 major Aussie lenders";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "findloan_compare_feature3"] = "- Comparing loans in 30 secs";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "btn_GO"] = "GO";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "btn_Next"] = "NEXT";
pagesVariants[pages.HOMELOANCUSTOMERJOURNEY + pagesVariants.textVariantSeparator + "disclaimer"] = homeLoanAssistantTermsAndCs;


pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "title"] = "My preferences";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "subtitle"] = "Please manage your preferences";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "categoryHeader"] = "Communication Preferences";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "opt-out-prefix"] = "Unsubscribe from ";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "subscriptionHeader"] = "All type of emails";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "subscriptionItem"] = "Unsubscribe me from all communications other than as required by law";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "eqfxMarketingItem"] = "Unsubscribe me from marketing offers and communications from Equifax Group and its partners";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "eqfxMarketingHeader"] = "Equifax and its partners' communications";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "accountHeader"] = "Account Management";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "accountItem"] = "Cancel my account";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "400_ErrorWhenSaving"] = "An error ocurred when trying to save the data. Please try again.";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "404_SyncProblemWithMailchimp"] = "An error ocurred when trying save your data in our email server provider. Please contact us.";
pagesVariants[pages.MYPREFERENCES + pagesVariants.textVariantSeparator + "400_ErrorWhenRequestingData"] = "We are not able to retrieve the data from our email server";
pagesVariants["mailChimpLinkSuggestion"] = "If you have unsubscribed before by clicking in our emails, please <span class='linkformat'>{LINK}</span> to consent re-subscribing into our email server provider";

pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "titleNormal"] = "Compare your region's";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "titleBoldWithDisclaimer"] = "credit score";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "titleBold"] = "now";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "subtitle"] = "We've compared over two million credit scores across 326 regions.";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "getScoreLink"] = "Get you credit score now";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "postCodePlaceHolder"] = "Postcode";
pagesVariants[pages.SCOREMAPSECTION + pagesVariants.textVariantSeparator + "compareNowButtonText"] = "COMPARE NOW";

pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "title"] = "How does your credit score compare";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "subtitle"] = "We've compared over two million credit scores across 326 regions from annual Equifax Australian Credit scorecard";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "postCodePlaceHolder"] = "Postcode";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "compareNowButtonText"] = "Compare Now";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "mapSearchTitleWithDisclaimer"] = "Median Score";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "mapSearchTitle"] = "By Region";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "mapSearch"] = "Start typing postcode, area or region";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "popupContent"] = "<p class='pop-up-score'>The average Equifax Score in <br/> <b> {SA3Name} region</b> is<br> <span> {SA3Score} </span><br> <a href='" + GlobalVariables.WEB_SPA_URL + "dashboard' style='text-decoration: underline;' target='_blank'>What&#39;s your score?</a></p>";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "changeLocation"] = "Change Location";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "showResultFor"] = "Showing Results for";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "gradientLowestScore"] = "Lowest Score";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "gradientHighestScore"] = "Highest Score";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "generationTitleWithDisclaimer"] = "Median Score";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "generationTitle"] = "By Age For Selected Region";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "generationMale"] = "Male";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "generationFemale"] = "Female";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "areaScoresTitle"] = "Min And Max Region Scores";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "lowestScoreTitle"] = "Lowest Score Regions";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "highestScoreTitle"] = "Highest Score Regions";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "errorTextGeneral"] = "Sorry an Error ocurred, please try again.";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "errorTextNoResult"] = "Sorry, try expanding your search to a broader area or geographical region.";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "errorPostCodeNoResult"] = "No result found for this Postcode.";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "generalDisclaimer"] = "*The GetCreditScore credit score is based on the Equifax Credit Score Model. Third parties may not use the GetCreditScore credit score to assess your credit worthiness.";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "legalDisclaimer"] = "*The Median scores by Age displayed in this score map are general in nature and are based on the average credit scores held within GetCreditScore as at Dec 2017. Credit Scores may change as they are dynamic and are dependent on time and circumstance. The average credit scores shown do not reflect an individual’s credit score or personal circumstances.";
pagesVariants[pages.SCOREMAP + pagesVariants.textVariantSeparator + "regionDisclaimer"] = "*The Region scores displayed in this score map are general in nature and are based on the average credit scores held within GetCreditScore as at Dec 2017. Credit Scores may change as they are dynamic and are dependent on time and circumstance. The average credit scores shown do not reflect an individual’s credit score or personal circumstances.";

pagesVariants[pages.COUNTER + pagesVariants.textVariantSeparator + "labelDesktop"] = "CREDIT SCORES GIVEN";
pagesVariants[pages.COUNTER + pagesVariants.textVariantSeparator + "labelMobile"] = "SCORES GIVEN";

pagesVariants[pages.TRUSTPILOTSECTION + pagesVariants.textVariantSeparator + "title"] = "What our members say";
pagesVariants[pages.TRUSTPILOTSECTION + "reviews"] = [
   {comment: "Super quick & easy! Had my report within minutes! Have already recommended these guys to friends and family.", author: "Kerry Fisher" },
   {comment: "Very quickly updated with my credit score and very sound information about improving your credit score.", author: "Richard Collins" },
   {comment: "Excellent service straight to the point and very prompt to respond to my requests, very happy and recommend for everyone to use.", author: "Samuel Kamo" },
   {comment: "It was easy and quick and I am hoping it will get me what I want in the future.", author: "Peter Bloomfield" }
];

pagesVariants[pages.NEWMEMBERSMS + pagesVariants.textVariantSeparator + "description"] = "Get your 100% free credit score now. It takes less than 60 seconds and no credit card is required.";
pagesVariants[pages.NEWMEMBERSMS + pagesVariants.textVariantSeparator + "title"] = "Enter Your Email";
pagesVariants[pages.NEWMEMBERSMS + pagesVariants.textVariantSeparator + "btnSubmit"] = "Get Started";

export default pagesVariants;
