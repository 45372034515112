import React from 'react';
import PropTypes from 'prop-types';
import * as funcs from '../../../components/shared/functions';

const $ = window.$;

const TabControlItem = ({tabItem, isSelected, onTabChange, showImage = true}) => {
  let liCssClass = tabItem["cssClass"] ? tabItem["cssClass"]:"";
  liCssClass += isSelected ? ' active':'';
  let localTabIndex = tabItem.tabIndex;
  let href = tabItem["link"] ? tabItem["link"]: "#";
  const linkCssClass = tabItem["linkCssClass"] ? tabItem["linkCssClass"] : "";

  return(
    <li order={tabItem.tabIndex} className={liCssClass}>
      <a className={"tab-control-tab-a " + linkCssClass} href={href} onClick={(evt) => onTabChange(localTabIndex, evt)}>
        <img alt="" src={tabItem.imgUrl} className={"tab-control-tab-img " + (!showImage ? "hidden-xs hidden-sm":"")} />
        <p className="tab-control-tab-p">{tabItem.text}</p>
      </a>
    </li>
  );
};

TabControlItem.propTypes = {
  tabItem: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  showImage: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired
};

export class TabControl extends React.Component {
  constructor(props, context) {
    super(props, context);

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { tabs,
      tabsComponent,
      selectedTabIndex,
      onTabChange,
      textVariants,
      cssClass="",
      cssClassMenu="tab-control-menu",
      cssClassContent="tab-control-score-content",
      sectionId="dashboard-top-page" } = this.props;

    const mainComponet = (
      <div className={cssClass}>
        <section className={cssClassContent + " md-pt-20 sm-pt-20 xs-pt-15 md-pb-30 sm-pb-30 xs-pb-20"}>
          <div className="container-fluid left-container-padding right-container-padding">
            {tabsComponent}
          </div>
        </section>
      </div>
    );
    return tabsComponent
  }
}

TabControl.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabsComponent: PropTypes.object.isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  textVariants: PropTypes.object.isRequired,
  cssClassMenu: PropTypes.string,
  cssClassContent: PropTypes.string
};
