import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
import * as GlobalFixedVariables from '../config/GlobalFixedVariables';
import * as funcs from '../components/shared/functions';


// import {
// callToActionConfiguration,
// masterCallsToAction,
// } from '../components/shared/staticCallsToAction';

import {
products
} from '../components/shared/staticProducts';

// const _scoreHistory = [
//    { date: '20160101', score: 400, isReal: true},
//    { date: '20160101', score: 500, isReal: false},
//    { date: '20160401', score: 600, isReal: true},
//    { date: '20160601', score: 700, isReal: true},
//    { date: '20160601', score: 750, isReal: false},
//    { date: '20160901', score: 800, isReal: true},
//    { date: '20160601', score: 850, isReal: false},
//    { date: '20160901', score: 900, isReal: true}
//  ];

class ScoreApi {
    static getScoreProfile(csrfToken, options =  {includeScore: true, includeAdvices: true, includeHistory:  true}) {

      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/creditscore';
      let headers = {};
      headers['Content-Type'] = 'application/json';
      if(csrfToken){
        headers['x-gcs-xsrf'] = csrfToken;
      }     
      let requestOptions = {
           method: 'POST',
           mode: 'cors',
           credentials: 'include',
           headers: headers,
           body: JSON.stringify(options)
         }

        return fetch(fullUrl, requestOptions).then(response =>
                {
                    if (!response.ok) {
                      return Promise.reject(response);
                    }
                    else{
                      return response.json();
                    }
                }
          ).catch(function (error) {
            throw(error);
          });      
    }

    // static findActionById(masterCallsToAction, actionId){

    //     var item = null;
    //     for(var i = 0; i < masterCallsToAction.length && item == null; i++){
    //       var searchItem = masterCallsToAction[i];
    //       if(searchItem.id == actionId){
    //           item= searchItem;
    //       }
    //     }

    //     return item;
    // }

    // static getCallsToAction(csrfToken, scoreProfile, userProfile) {
    //
    //   const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/creditscore/call2actions';
    //
    //   let requestOptions = {
    //        method: 'GET',
    //        mode: 'cors',
    //        credentials: 'include',
    //        headers: {
    //         'Content-Type': 'application/json',
    //         'x-gcs-xsrf': csrfToken
    //       }
    //       // ,body: JSON.stringify()
    //      }
    //
    //     return fetch(fullUrl, requestOptions).then(response =>
    //             {
    //                 if (!response.ok) {
    //                   return Promise.reject(response);
    //                 }
    //                 else{
    //                   return response.json();
    //                 }
    //             }
    //       ).catch(function (error) {
    //         throw(error);
    //       });
    // }

    static getCallsToAction(scoreProfile, userProfile, productResult) {
      
      let callToActionArray = [];

      for(let i = 0; i < productResult.call2Actions.length; i++){

        var loadedNextStep = productResult.call2Actions[i];
        var nextStepItem = Object.assign({}, loadedNextStep);        

        callToActionArray.push(nextStepItem);

      }      

      return Promise.resolve(callToActionArray);  
    }

    // static getCallsToActionOld(scoreProfile, userProfile, productResult) {

    //   var currentScore = 0;
    //   if(scoreProfile && scoreProfile.scoreInfo){
    //       var currentScore = scoreProfile.scoreInfo.score || 0;
    //   }

    //   // var configItem = null;
    //   // for(var cocnfigIndex = 0; cocnfigIndex < callToActionConfiguration.length && configItem == null; cocnfigIndex++){
    //   //   var tmpConfigItem = callToActionConfiguration[cocnfigIndex];
    //   //   if(tmpConfigItem.range.min <= currentScore && tmpConfigItem.range.max >= currentScore) {
    //   //     configItem = tmpConfigItem;
    //   //   }
    //   // }

    //   if(productResult.partnerId == GlobalFixedVariables.PARTNER_TOYOTA){
    //     configItem = null;
    //   }

    //   let isMobile = funcs.isMobileDevice();
    //   var callToActionArray = [];
    //   if(configItem){
        
    //      var stepIndex = 0;
    //      var exclude = false;
    //      var idCache = {};
    //       for(var j = 0; j < configItem.actions.length; j++){
    //           exclude = false;

    //           var actionId = configItem.actions[j];
    //           var actionItem = ScoreApi.findActionById(masterCallsToAction, actionId);

    //           var customAction = null;
    //           if(actionItem && actionItem["isCustomRuleSatisfied"]){
    //             customAction = actionItem["isCustomRuleSatisfied"](userProfile, scoreProfile, configItem);
    //             if(!customAction.isSatisfied){
    //               if(customAction.altId && !idCache["" + customAction.altId]){
    //                 actionItem = ScoreApi.findActionById(masterCallsToAction, customAction.altId);
    //                 idCache["" + customAction.altId] = true;
    //                 customAction = null;
    //               }
    //               else{
    //                 exclude = true;
    //               }
    //             }
    //           }

    //           if(!exclude && actionItem && !(isMobile && actionItem["excludeFromMobile"])){

    //             stepIndex++;
    //             var actionItemWithStep = Object.assign({step: stepIndex}, actionItem);
    //             if(customAction && customAction.altUrl){
    //               actionItemWithStep.href = customAction.altUrl;
    //             }

    //             let prodItem = null;
    //             if(actionItemWithStep["elementType"] == 'homeloan'){
    //               prodItem = funcs.searchInList(productResult.productsCall2Action, "productTypeId", actionItemWithStep.productTypeId);
    //               if(prodItem){
    //                 let itemMetadata = actionItemWithStep.productDefinitions[prodItem.productIdentifier];
    //                 actionItemWithStep = Object.assign({}, actionItemWithStep, itemMetadata);
    //               }
    //             }

    //             let standardLoad = !actionItemWithStep["loadLinkFromProducts"];
    //             if(!standardLoad){

    //               prodItem = funcs.searchInList(productResult.productsCall2Action, "productIdentifier", actionItemWithStep["productIdentifier"]);

    //               if(prodItem){
    //                   var masterProdConfig = funcs.searchInList(products, "id", actionItemWithStep["productIdentifier"]);
    //                   let mixedProdConfig = Object.assign({}, masterProdConfig, prodItem);
    //                   actionItemWithStep.product = mixedProdConfig;
    //                   actionItemWithStep.partnerId = mixedProdConfig.partnerId;
    //                   actionItemWithStep.productTypeId = mixedProdConfig.productTypeId;
    //                   actionItemWithStep.productId = mixedProdConfig.productId;
    //               }
    //               else{
    //                 standardLoad = true;
    //               }

    //             }

    //             if(standardLoad){
    //               if(userProfile && userProfile.identifier && actionItem && actionItemWithStep.href){
    //                   actionItemWithStep.href =  actionItemWithStep.href.replace('{USERID}', 'u-' + userProfile.identifier);
    //               }
    //             }

    //             if(actionItem){
    //                 idCache["" + actionItem.id] = true;
    //                 callToActionArray.push(actionItemWithStep);
    //               }

    //           }
    //       }
    //   }

    //   return Promise.resolve(callToActionArray);
               
    //     // return new Promise((resolve, reject) => {
    //     //     setTimeout(() => {
    //     //         resolve(callToActionArray);

    //     //     }, delay);
    //     // });
    // }


    // static getScoreHistory(userContext) {
    //
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             // pick the right menu items
    //             //resolve(Object.assign([], products));
    //             // console.log(JSON.stringify(menuItemsToReturn));
    //             resolve(
    //
    //             );
    //         }, delay);
    //     });
    // }

  static getScoreCounter() {
    const  fullUrl = GlobalVariables.API_BASE_URL + "v1/creditscore/counter";

    const options = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',     
    };

    return fetch(fullUrl, options)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else {
        return Promise.reject(response);
      }
    })
    .catch(function (error) {
      throw(error);
    });
  }
};

export default ScoreApi;
