import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';

import {initTonikForms} from '../../tonik/shared/forms';
import {tonikInitMonthSlider} from '../../tonik/report';
import DefaultsModal from './shared/DefaultsModal';

import {LineShower} from './shared/LineShower';
import {ErrorWrapper} from './shared/ErrorWrapper';

const $ = window.$;

class Defaults extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        modal: false,
      }

      this.onAccordionBtnClick = this.onAccordionBtnClick.bind(this);
  }

  componentDidMount() {
    //window['initAppJs'](true);
    initTonikForms();
    
  }

  componentDidUpdate(prevProps, prevState){
    //window['initAppJs'](true);
    initTonikForms();
  }

  openModal () {    
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

  onAccordionBtnClick(event, account) {
    if(account.repaymentHistory && account.repaymentHistory.length > 0){
      //tonikInitMonthSlider();
    }
  }

render(){

    let {textVariants, data} = this.props;
    var accounts = data;

    if(accounts){
      accounts = accounts.filter(a => a.hasDefaults == true);
    }
    if(!accounts || accounts.length == 0){      
      return (<ErrorWrapper header="Defaults">
                    <div className="alert alert-warning">
                      We couldn't find any defaults.
                    </div>
             </ErrorWrapper>);
    }    

    return (
      <div className='col-lg-6 col-md-8 col-12 accordion-column'>
      <span>
        <div id="accordion" className="accordion-reports">
          {
            accounts && accounts.map( (account, index) => {                            
                    return (
                      <div key={index} className="card">
                        <div className="card-header" id={"heading-" + account.accountId}>
                          <h5 className="mb-0">
                            <button onClick={(e) => this.onAccordionBtnClick(e, account)}
                                  className="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target={"#collapse-" + account.accountId}
                                  aria-expanded="true"
                                  aria-controls={"collapse-" + account.accountId}>
                              {account.latestCreditProvider  ? account.latestCreditProvider : ''} 
                              
                              {account.latestUpdatedDate &&
                                (<span>Updated: <span className="date">{ account.latestUpdatedDate}</span></span>)}
                            </button>
                          </h5>
                          <h2>
                          {account.accountType}
                          
                          </h2>
                            {account.originalCreditProvider && <LineShower mainClass="" elementKey="Relationship:" elementValue={account.relationship} />}
                            {account.originalCreditProvider && <LineShower mainClass="" elementKey="Original Credit Provider:" elementValue={account.originalCreditProvider} />}
                        </div>
      
                        <div id={"collapse-" + account.accountId} className="collapse" aria-labelledby={"heading-" + account.accountId} data-parent="#accordion">
                          <div className="card-body">
                            {account.defaults.map( (defaulT, indexd) => {                            
                                return (
                                <span key={indexd}>
                                 {(defaulT.originalAmount || defaulT.currentAmount || defaulT.DateOfDefault) &&
                                    
                                    <h2 className="cr_card_textline">Default</h2>}                                    
                                    {defaulT.DateOfDefault && <LineShower elementKey="Date of Default:" elementValue={defaulT.DateOfDefault} />}
                                    {defaulT.isDisputed && <LineShower elementKey="Is Disputed:" elementValue={defaulT.isDisputed ? "yes": "no"} />}
                                    {defaulT.originalAmount && <LineShower elementKey="Original Amount:" elementValue={'$' + defaulT.originalAmount} />}
                                    
                                    {defaulT.currentAmount &&
                                    <>
                                    <LineShower elementKey="Current Amount:" />
                                     <span className="green cr_red"><sup>$</sup>{defaulT.currentAmount}</span>
                                    </>
                                    }
                                    
                                    { defaulT.status && (<div className="status">
                                        <p className="cr_card_special_title">Status <span className={"settled " + (defaulT.status == 'Paid'? 'court-action-status-paid':'')}>{defaulT.status}</span></p>
                                     </div>)
                                    }

                        {/* {defaulT.newArrangement && <LineShower elementKey="New Arrangement:" elementValue={ defaulT.newArrangement} />} */}

                                     { defaulT.newArrangement && 
                                     (<div className="NewArr" style={{display: 'inline-block'}}>
                                        <div className="NewArrHeader" 
                                        // style={{fontSize: '12px', float: 'left', marginLeft: '230px', width: '20%', display: 'block'}}
                                        >
                                          New Arrangement: </div>
                                        <div className="NewArrBody" 
                                        // style={{fontSize: '13px', float: 'right', width: '37%', paddingRight: '14px'}}
                                          >
                                          {defaulT.newArrangement=='N'? 'New consumer credit provided that relates, wholly or in part, to this amount of credit.'
                                          : (defaulT.newArrangement=='V'? 'Varied, Terms or conditions of the original consumer credit that relate to the repayment of the amount of the credit are varied.'
                                          : '' )}</div>
                                     </div>)
                                    }                                   
                                                                                                                  
                                        
                                    <div className="pt-3 d-inline-block"></div>
                                    </span>)
                              })}                                                        
                                                                                                            
                            <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>How to read your Defaults information</a>
                          </div>
                        </div>
                      </div>
                    )                           
            })
          }
        </div>

        {this.state.modal && (
          <DefaultsModal closeModal={()=>{this.closeModal()}}></DefaultsModal>
          )}
      </span>
      </div>);
      }
 }

 Defaults.propTypes = {
    textVariants: PropTypes.object,
    data: PropTypes.array.isRequired
};


export default Defaults;
