import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js'
import LodgeacomplaintTitleSection from './LodgeacomplaintTitleSection.js'

import LodgeacomplaintForm from './LodgeacomplaintForm';
import ValidationApi from '../../api/validationApi.js'

import LoginBoxMessage from '../login/LoginBoxMessage';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';

import {LodgeAComplaintApi} from '../../api/externalServices';

import * as GlobalVariables from '../../config/configureGlobalVariables';

import FooterSection from  '../common/footer'

import * as userActions from '../../redux/actions/userActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import {initTonikContactUs} from '../../tonik/contactus';
import {initTonikForms} from '../../tonik/shared/forms';

import * as RoutesVariables from '../../config/routesVariables';

import GcsRecaptcha from "../common/GcsRecaptcha";

class LodgeacomplaintPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      request:  {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        subject: '',
        description: ''
      },
      resetRecaptcha: false,
      errors: {}
    };

    this.onContactUsFormSubmit = this.onContactUsFormSubmit.bind(this);
    this.updateRequestState = this.updateRequestState.bind(this);
    this.onContactUsConfirmed = this.onContactUsConfirmed.bind(this);
    this.onSendContactUs = this.onSendContactUs.bind(this);

    this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
    this.doResetRecaptcha = this.doResetRecaptcha.bind(this);      
    this.recaptchaRef = React.createRef();     
  }

    componentDidMount() {
      //window['initAppJs'](true);
      this.props.actions.getMenuForPage(pages.HOME);
      this.props.actions.getTextVariants();       

      initTonikContactUs();
      initTonikForms();
      this.doResetRecaptcha();
    }

    onSelectRecaptcha(token){
   
      if (token) {
        this.onSendContactUs(token);
      }      
  
      this.setState({resetRecaptcha: true});
    }
  
    doResetRecaptcha(){
      if (this.state.resetRecaptcha) {
        try{
          this.recaptchaRef.current.reset();
        }
        catch(ex){
          console.log(ex);
        }
        this.setState({resetRecaptcha: false});
      }
    }
  
    contactUsFormIsValid() {

        let formIsValid = true;
        let errors = {};

        var errorName = ValidationApi.requiredField('Full name', this.state.request.name);
        if (errorName) {
            errors.name = errorName;
            formIsValid = false;
        }

        var errorLastName = ValidationApi.requiredField('Last name', this.state.request.lastname);
        if (errorLastName) {
            errors.lastname = errorLastName;
            formIsValid = false;
        }

        var errorDescription = ValidationApi.requiredField('Message', this.state.request.description);
        if (errorDescription) {
            errors.description = errorDescription;
            formIsValid = false;
        }

        var errorSubject = ValidationApi.requiredField('Subject', this.state.request.subject);
        if (errorSubject) {
            errors.subject = errorSubject;
            formIsValid = false;
        }

        var errorEmail = ValidationApi.validateEmail(this.state.request.email);
        if (errorEmail) {
            errors.email = errorEmail;
            formIsValid = false;
        }

        var errorPhone = ValidationApi.validateMobile(this.state.request.phone);
        if (errorPhone) {
            errors.phone = errorPhone;
            formIsValid = false;
        }

        this.setState({errors: errors});

        return formIsValid;
    }

    onContactUsFormSubmit(event) {   
        event.preventDefault();
        if (this.contactUsFormIsValid()) {
          this.recaptchaRef.current.execute();    
        }        
    }

    onSendContactUs(token){
        // Save request.
        LodgeAComplaintApi.sendLodgeDataThroughGcs(this.state.request, token).then(resp => {

          this.props.history.push(RoutesVariables.RouteLodgeacomplaintSuccess)

        })
        .catch(e => {

          var errors = {};
          if (e.status == 409){
            errors.general = "Recaptcha validation failed";
          } else if(e.status == 400){
            errors.general = "Bad request";
          }
          else{
            errors.general = "Unknown error. Your message could not be send.";
          }

          this.setState({errors});
        });                  
    }

    updateRequestState(event) {

        const field = event.target.name;

        let request = this.state.request;
        request[field] = event.target.value;

        let errors = this.state.errors;
        errors[field] = '';

        return this.setState({ request: request, errors: errors });
    }

    onContactUsConfirmed() {
      this.props.history.push('/');
      return false;
    }

    render() {

      var pageContent = <LodgeacomplaintForm
      onSave={this.onContactUsFormSubmit}
      onChange={this.updateRequestState}
      errors={this.state.errors}
      request={this.state.request}
      textVariants={this.props.textVariants}
      />;

        return (

           <div className="page-home page-contact">
             <GcsRecaptcha
              recaptchaRef={this.recaptchaRef}
              onSelectRecaptcha={this.onSelectRecaptcha}
            />
              <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>
              {pageContent}
              <FooterSection/>

            </div>
        );
    }
}

LodgeacomplaintPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOME]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOME];
     }
   }

 let txtVariants = {};
 if(state.textVariants){
   txtVariants = state.textVariants;
 }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LodgeacomplaintPage);
