import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import LoginBoxMessage from '../login/LoginBoxMessage';
import * as funcs from '../shared/functions';
import * as RoutesVariables from '../../config/routesVariables';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as pages from '../shared/pages';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import FooterSmallSection from '../common/footerSmall';
import VerificationAPI from '../../api/verificationApi';
import TextNumericInput from '../common/controls/TextNumericInput';
import GcsRecaptcha from "../common/GcsRecaptcha";

import * as ImagesPath from '../../config/imagesPath';

import { USERROLES, hasOneRole } from '../../common/gcsLogic';


class RegistrationVerifyMobilePage extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = { 
        tmLoaded: null,
        verificationCode: '',
        errorMessage: '',
        infoMessage: '',
        otcError: null,
        saving: false,
        resetRecaptcha: false,
        shouldResend: false
      };      

      this.loadTmVariables = this.loadTmVariables.bind(this);
      this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.onResendSMS = this.onResendSMS.bind(this);
      this.redirectToAuth = this.redirectToAuth.bind(this);
      this.onBack = this.onBack.bind(this);
      this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
      this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  
      this.doSubmit = this.doSubmit.bind(this);
      this.doResendSMS = this.doResendSMS.bind(this);

      this.recaptchaRef = React.createRef();  
  }

  componentDidMount() {    
    this.props.actions.getMenuForPage(pages.REGISTER);
    this.props.actions.getTextVariants();
    this.doResetRecaptcha();

    //this.loadTmVariables(); 

    this.props.actions.isAuthenticated().then(e => {

      if(!e.isAuthenticated || !hasOneRole(e.roles, USERROLES.EmailVerified)) {
        this.props.history.push(RoutesVariables.RouteCreateAccount);
       return;
      }      
    });
  }

  loadTmVariables(){
    return this.props.actions.tm().then(r => {
      this.setState({tmLoaded : true});
      return r;
    }).catch(e => {
      //this.setState({tmLoaded : false});
    });
  }  

  componentWillReceiveProps(nextProps) {      
    if (!nextProps.isAuthenticated) {
      // go back to the page if the r parameter is set
      this.props.history.push(RoutesVariables.RouteLogin);          
    }  

    this.doResetRecaptcha();
  } 

  onSelectRecaptcha(token){
   
    if (token) {
      if(this.state.shouldResend){
        this.setState({shouldResend: false},
           () => this.doResendSMS(token) );        
      }
      else{
        this.doSubmit(token);
      }
    }      

    this.setState({resetRecaptcha: true, saving: false});
  }

  doResetRecaptcha(){
    if (this.state.resetRecaptcha) {
      try{
        this.recaptchaRef.current.reset();
        this.setState({resetRecaptcha: false});
      }
      catch(ex){
        console.log(ex);
      }      
    }
  }
  
  onChangeVerificationCode(event) {
    
    let verificationCode = this.state.verificationCode;
    verificationCode = event.target.value;

    return this.setState({ verificationCode: verificationCode, otcError: null });
  }

  onFormSubmit(event){

    event.preventDefault();
    // Validate    
    if (!this.state.verificationCode || (this.state.verificationCode.length < 5 && !this.state.verificationCode.match(/^[0-9]+$/g))){
      this.setState({otcError: 'The One Time Code is not Valid'});       
    }
    else{
      this.recaptchaRef.current.execute();
    }
  }

  doSubmit(token){
      this.setState({ errorMessage: null, infoMessage: null, saving: true });
      // Send Verification Code
      VerificationAPI.verifyMobileOneTimeCodeRegistration(this.props.userProfile.csrfToken, this.state.verificationCode, token).then( (verification) => {
        // Redirect to re-auth
        if(verification.isError){
          this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
        } else{
          this.redirectToAuth();
        }
      }).catch( e => {
        if( e.status == 400){
          e.json().then( verification => {
              this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
           });
        } else {
            this.setState({ errorMessage: 'An unexpected error occurred. Please <a href="/contact-us" style="float:none !important;">contact us</a>.', infoMessage: null });
        }
        this.doResetRecaptcha();
        }).finally( () => {
            this.setState({saving: false});
        });
  }
  

  redirectToAuth(){
    
    this.props.actions.refreshAuthenticationWithHash('M').then(r =>{

      if(r.roles && (hasOneRole(r.roles, USERROLES.OneFactor) || hasOneRole(r.roles, USERROLES.TwoFactor)) && hasOneRole(r.roles, USERROLES.MobileVerified) ){    
        var navigateUrl = r.redirect_uri || RoutesVariables.MyDetailsVerification; // '/my-details/verification';                      
        this.props.history.push(navigateUrl);
      }
      else{        
        this.setState({errorMessage: 'We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another verification SMS with the link below'});
      }
    }).catch(e => {

      if(e.status == 400){
          e.json().then( resp => {
            this.setState({errorMessage: resp.errorMessage});
          });
      }
      else{
        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another SMS verification'});
      }
    });
  }

  onResendSMS(event){
    event.preventDefault();
    this.setState({shouldResend: true}, ()=> {
      this.recaptchaRef.current.execute();
    });
  }

  doResendSMS(token){
    
    VerificationAPI.sendMobileOneTimeCodeRegistration(this.props.userProfile.csrfToken, '', token).then(() =>{
      this.setState({infoMessage: 'A new SMS has been sent.'});
      var that = this;
        setTimeout(() => {
          that.setState({infoMessage: null, errorMessage: null, otcError: null});
        }, 4000);
    }).catch(e => {
      if(e.status == 400){
        e.json().then( resp => {
          this.setState({errorMessage: resp.errorMessage});
        }) 
      } else{

        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another SMS verification'});
      }
    });
  }

  onBack(event){
    event.preventDefault();
    this.props.history.push('/create-account/mobile');
  }

  render() {       
    return (             
        <div className="bg-circle n-expand">
           <GcsRecaptcha
              recaptchaRef={this.recaptchaRef}
              onSelectRecaptcha={this.onSelectRecaptcha}
            />
            <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} /> 
            <section className='section-form-log page-register n-100vh'>
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 form-log-wrapper">                      
                      <div className="nav-register n-nav-register-4"> {/*nav-register-2 */}
                         <a href="" onClick={this.onBack} className="prev-form"><img src={ImagesPath.ImgIconPrev} className="img-fluid" alt="" /></a>
                        <h2><span>4 <span className="separator" /> 6</span> Mobile Verification</h2>
                      </div>                                              
                      <div className="form-log-box form-log-box-n">
                        <div className="icon-register-verify">
                          <img src={ImagesPath.ImgIconSubmission} className="" alt="img-fluid" />
                        </div>
                        {/* <img src={ImagesPath.ImgLogo} className="img-fluid logo" alt=""/> */}
                        <p>We sent you an SMS with a code.<br />Please enter it below to confirm your mobile number.</p>
                        <form  onSubmit={this.onFormSubmit} className="d-flex justify-content-center align-items-center flex-column">
                          <div className="col-lg-6 col-12 small-input">
                          <TextNumericInput
                              name="code"
                              id="code"
                              placeholder="One Time Code"
                              value={this.state.verificationCode}
                              label="One Time Code"
                              onChange={this.onChangeVerificationCode}
                              error={this.state.otcError} 
                              maxLength={6}
                              groupClass="form-label-group"/>                               
                          </div>
                          <div className="col-lg-6 col-12">
                          <button type="submit" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled': '')}>Verify</button>
                        </div>
                        {this.state.errorMessage && 
                          <div className="alert alert-danger">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.errorMessage)}></span>
                          </div>
                        }   
                         {this.state.infoMessage && 
                          <div className="alert alert-warning">
                            <span dangerouslySetInnerHTML={funcs.createMarkup(this.state.infoMessage)}></span>
                          </div>
                        }          
                        <p>Did not arrive yet? <a href="" onClick={this.onResendSMS}>Resend</a></p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>  
              <FooterSmallSection />         
        </div>
    );
  }
}

RegistrationVerifyMobilePage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.REGISTER]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.REGISTER];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,   
    userProfile:  state.userProfile, 
    isAuthenticated: state.userProfile.isAuthenticated,    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationVerifyMobilePage);
