import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import {initTonikForms} from '../../tonik/shared/forms';
import {tonikInitMonthSlider} from '../../tonik/report';
import CourtActionsModal from './shared/CourtActionsModal';

import {LineShower} from './shared/LineShower';
import {ErrorWrapper} from './shared/ErrorWrapper';

const $ = window.$;


class CourtActions extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        modal: false,
      }

      this.onAccordionBtnClick = this.onAccordionBtnClick.bind(this);
  }

  componentDidMount() {
    initTonikForms();
  }

  componentDidUpdate(prevProps, prevState){
    initTonikForms();
  }

  openModal () {
    this.setState({modal: true});
  }

  closeModal () {
    this.setState({modal: false});
  }

  onAccordionBtnClick(event, account) {
    if(account.repaymentHistory && account.repaymentHistory.length > 0){
      //tonikInitMonthSlider();
    }
  }

render(){
    let {textVariants, data} = this.props;
    var courtActions = data;

    if(!courtActions || courtActions.length == 0){
      return (<ErrorWrapper header="Court Actions" >
        <div className="alert alert-warning">
                  We couldn't find any court judgments.
                </div>
        </ErrorWrapper>);
    }

    return (
    <div className='col-lg-6 col-md-8 col-12 accordion-column'>
      <span>
        <div id="accordion" className="accordion-reports">

        {
            courtActions && courtActions.map( (court, index) => {
              return (
                <div key={index} className="card">
                  <div className="card-header" id={"heading-"+ index}>
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target={"#collapse-"+ index}
                        aria-expanded="true"
                        aria-controls={"collapse-"+ index} >
                      {court.creditor} <span>Date of commencement: <span className="date">{court.actionDate}</span></span>
                      </button>
                    </h5>
                    <h2>{court.courtType}</h2>
                  </div>

                  <div
                    id={"collapse-"+ index}
                    className="collapse"
                    aria-labelledby={"heading-"+ index}
                    data-parent="#accordion" >

                    <div className="card-body">
                      {court.courtActionAmount && <span className="green cr_red"><sup>$</sup>{court.courtActionAmount}</span>}
                      { court.courtActionStatus && (<div className="status">
                        <p className="cr_card_special_title">Status <span className={"settled " + (court.courtActionStatus == "Settled"? "court-action-status-paid":"")}>{court.courtActionStatus}</span></p>
                      </div>)}
                      <a onClick={()=>{this.openModal()}} className="report" style={{cursor:'pointer'}}>How to read your Court Judgments information</a>
                    </div>
                  </div>
                </div>
              )
            })
        }

        </div>

        {this.state.modal && (
          <CourtActionsModal closeModal={()=>{this.closeModal()}}></CourtActionsModal>
          )}
      </span>
      </div>
        );
      }
 }

 CourtActions.propTypes = {
    textVariants: PropTypes.object,
    data: PropTypes.array.isRequired
};


export default CourtActions;
