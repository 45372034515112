import React from 'react'
import * as pages from '../shared/pages';
import {AlreadyMember} from '../common/blocks/AlreadyMember.js';
import * as RoutesVariables from '../../config/routesVariables';
const GetStartedSection = ({onGetScoreClick, onLoginClick, isAuthenticated, textVariants}) => {

var sectionCls = 'get-started text-center ' + pages.COMMON_SECTION_PADDING;

return (
    //   <section className={sectionCls}>

    //     <h2>{textVariants[pages.HOME + textVariants.textVariantSeparator + "get_started"]}</h2>

    //     <div className="get-started-text xs-pt-15 xs-pb-0 xs-pl-15 xs-pr-15">{textVariants[pages.HOME + textVariants.textVariantSeparator + "get_started_text"]}</div>

    //     <a className="get-score-btn btn xs-mt-20 xs-mb-20" id="get-started-link" onClick={onGetScoreClick}><span>{textVariants["Btn_GetScore"]}</span></a>

    //     <AlreadyMember
    //       isAuthenticated={isAuthenticated}
    //       text=  {textVariants[pages.REGISTER + textVariants.textVariantSeparator + "header_create_free_account_already_member_part1"]}
    //       linkText={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "header_create_free_account_already_member_part2"]}
    //       onLinkClick={onLoginClick} />

    // </section>
    <section className="section-started">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-started-wrapper">
              <h2>Get started - it's FREE!</h2>
              <p>{'Use our interactive credit score dashboard to receive & understand your credit score'}<br/>{' and access relevant financial information & offers.'}</p>
              <a href={RoutesVariables.RouteCreateAccount} className="btn btn-primary">Create an account</a>
              <span>or</span>
              <a href={RoutesVariables.RouteLogin} className="btn btn-secondary">Log in</a>
              <span className="link">or <a href={RoutesVariables.RouteLogin}>Log in</a> if you already have an account</span>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default GetStartedSection;
