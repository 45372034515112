import React, { useState, useEffect, useRef  } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as pages from '../../shared/pages';

import * as GlobalVariables from '../../../config/configureGlobalVariables';
import LoadingIndicator from '../../common/controls/LoadingIndicator';
import * as funcs from '../../shared/functions';
import * as selectors from '../../../selectors/selectors';
import {noScoreReasons, noScoreReasonsActions} from '../../shared/staticNoScoreReasons';

import {ProductLink} from '../../sections/ProductSection';
import * as ImagesPath from '../../../config/imagesPath';
import * as RoutesVariables from '../../../config/routesVariables';
import { setTimeout } from 'core-js';

import {tonikInitCarousel} from '../../../tonik/dashboard';

const $ = window.$;


const DashboardCallToActionButton = ({actionItem, onActionItemClick, cssClass = ''}) =>{

  let linkElement = <div />;

    if(actionItem.product){
      linkElement = <ProductLink
          product={actionItem.product}
          classCss={"btn btn-primary " + cssClass}
          onProductClick={(evt, product) => onActionItemClick(evt, actionItem.id)}
       >
           <span >{actionItem.actionText || "Get your offer"}</span>
       </ProductLink>;
    }
    else if(actionItem.href){
        linkElement = <a className={"btn btn-primary " + cssClass}
             href={actionItem.href}
             target={actionItem.linkTarget}
             onClick={(evt)=>onActionItemClick(evt, actionItem.id)}>
              <span >{actionItem.actionText || "Get your offer"}</span>
          </a>;
    }
    else if(actionItem.actionRoute){
      linkElement = <Link className={"btn btn-primary " + cssClass}
           to={actionItem.actionRoute}
           target={actionItem.linkTarget}
           onClick={(evt)=> onActionItemClick(evt, actionItem.id)}>
            <span >{actionItem.actionText || "Get your offer"}</span>
        </Link>;
    }

    return ( linkElement );
}

DashboardCallToActionButton.propTypes = {
  actionItem: PropTypes.object.isRequired,  
  onActionItemClick: PropTypes.func.isRequired
  };

const DashboardCallToActionItem = ({actionItem}) =>
{    
    return (  
      <div className="slider-item">                 
         <img src={actionItem.imgUrl} className="img-fluid img-picture nv-next-step-img" alt=""/>                                     
      </div>
    )
};
DashboardCallToActionItem.propTypes = {
actionItem: PropTypes.object.isRequired,
isActive:PropTypes.bool.isRequired,
onActionItemClick: PropTypes.func.isRequired
};

//export const DashboardCallsToActionSection = ({textVariants, callsToAction, currentSlide, onActionItemClick, loading, carouselId = "", cssClass= ""}) => {

export class DashboardCallsToActionSection extends React.Component {
    constructor(props, context) {
      super(props, context);

      this.state = {
        currentSlide: 1,
        carouselMounted: false
      };

      this.carouselSlideChange = this.carouselSlideChange.bind(this);
    }

    componentDidMount() {
      tonikInitCarousel('#oneScoreCarousel', this.carouselSlideChange);
    }

    componentDidUpdate() {
      if(!this.props.loading && !this.state.carouselMounted && this.props.callsToAction && this.props.callsToAction.length > 0){
        if(tonikInitCarousel('#oneScoreCarousel', this.carouselSlideChange)){
          this.setState({carouselMounted: true});
        }        
      }
    }

    carouselSlideChange(e, slide){
      e.preventDefault()      
      try{
        this.setState({currentSlide: slide});       
      }
      catch(ex){
        console.log('carouselSlideChange', ex);
      }
    }
    
    render(){
        var {textVariants, callsToAction, currentSlide, onActionItemClick, loading, carouselId = "", cssClass= ""} = this.props;
        currentSlide = this.state.currentSlide;
        var total = callsToAction.length;

        let currentItem = null;
        if(callsToAction && callsToAction.length > 0){
          currentItem = callsToAction[currentSlide]
        } 

        if(total == 0){
          cssClass += " d-none";
        }
        else {
          cssClass += " d-block";
        }

        let mainComponent = <div></div>;
        if(loading) {
          mainComponent =<LoadingIndicator text={textVariants[pages.DASHBOARD + textVariants.textVariantSeparator + "actions_loading_text"]}  />;
        }
        else if( callsToAction && callsToAction.length > 0){

          mainComponent = (
            <>
            <div className="container mt-4">
              <div className="row">
                <div className="col-12 text-center">
                    <h2 className="p-4 nv-color-blue">Check more special offers and articles</h2>
                    <a id="nv-next-steps-anchor"></a>
                </div>
              </div>
              <div className="row no-gutters d-flex align-items-stretch">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6  p-0" id="next-step2">
                      <button type="button" className="next-step-prev slick-prev nv-z-100"></button>
                      <button type="button" className="next-step-next slick-next nv-z-100"></button>
                      <div className="slider-carousel" id="oneScoreCarousel" >
                        {
                          callsToAction.map( (action, i) => <DashboardCallToActionItem key={i} isActive={i == 0} actionItem={action} onActionItemClick={onActionItemClick}  />)
                        }
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      {
                        currentItem && 
                        <div className="nv-slider-text p-5 d-flex align-items-start flex-column h-100">                                          
                                  <h2 className="mb-4" >{currentItem.text || "Special Partner Offer"}  </h2>  
                                  <p className="" >{currentItem.description}</p>
                                  <DashboardCallToActionButton actionItem={currentItem} onActionItemClick={onActionItemClick} cssClass="mt-auto w-100 mb-4 "  />
                                  {/* <a className="mt-auto btn btn-primary mb-4 " >{currentItem.actionText || "Get your offer"}</a>                    */}
                          </div>
                      }
                          
                    </div>
                </div>
              </div>
            </>
            )

        }        

        return mainComponent;
    }

};

DashboardCallsToActionSection.propTypes = {
textVariants: PropTypes.object.isRequired,
callsToAction: PropTypes.array.isRequired,
onActionItemClick: PropTypes.func.isRequired,
loading : PropTypes.bool.isRequired
};


//export default DashboardCallsToAction;
