import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as reducerHelper from './reducerHelper';


export default function productMetadataReducer(state = initialState.productMetadata, action) {
  switch (action.type) {

    case types.UPDATE_PRODUCT_METADATA:
        var newState = Object.assign({}, state);
        newState.userId = action.userId;
        newState.isAnonymous = action.isAnonymous;

     return newState;


    default:
      return state;
  }
}
