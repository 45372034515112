import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

const KeyFeatureItem = ({isMobile, keyFeature}) => {
        var keyItem = keyFeature;
        return (
          <div className="col-lg-4 col-md-6 col-12 box-function">
            <div className="icon">
              <img className="img-fluid" src={keyItem.imgUrl} alt=""/>
            </div>
            {keyItem.title}
            {keyItem.description}
          </div>
        )
};

KeyFeatureItem.propTypes = {
  keyFeature: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

const HomeKeyFeaturesSection = ({textVariants, features, onLearnMoreClick}) => {

let drawFeatures = function(){

var renderFeatures = [];

  for(var i = 0; i < features.length; i ++){
       features3.push(features[i]);
       if(features3.length == 3 || i + 1 == features.length){
        renderFeatures.push (
                               features3.map(feature => {
                                   return (<KeyFeatureItem key={feature.key} keyFeature={feature} isMobile={false}  />)
                           })
            );
         features3 = [];
       }
     }
  return renderFeatures;
}

var features3 = [];
var sectionCls = 'key-features ' + pages.COMMON_SECTION_PADDING;

        return (
          <section className="section-function">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="pattern-top"></div>
                  <div className="pattern-bottom-left"></div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      <h2>A few key<br/> features that<br/> you might like</h2>
                    </div>
                    { (drawFeatures()) }
                    <a href={RoutesVariables.RouteExternalArticles} className="d-md-none d-sm-block more-link">Show more features <img className="img-fluid" src={ImagesPath.ImgIconSvgArrowLeftBlue} alt=""/></a>
                  </div>
                </div>
                <div className="pattern-bottom-right"></div>
              </div>
            </div>
        </section>
        )
};

HomeKeyFeaturesSection.propTypes = {
  textVariants: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  onLearnMoreClick: PropTypes.func.isRequired
};

export default HomeKeyFeaturesSection;
