import delay from './delay';

class StorageHelper {
    static storeJson(key, value) {
        try{
            localStorage.setItem(key, JSON.stringify(value));
        }catch(e){
            console.log(e);
        }
    }

    static storeJsonInSession(key, value) {
        try{
            sessionStorage.setItem(key, JSON.stringify(value));
        }catch(e){
            console.log(e);
        }
    }

    static getJson(key) {
        try{
            return JSON.parse(localStorage.getItem(key) || '{}');
        }catch(e){
            console.log(e);
        }        
    }

    static getJsonFromSession(key) {
        try{
            return JSON.parse(sessionStorage.getItem(key) || '{}');
        }catch(e){
            console.log(e);
        }        
    }

    static storeString(key, value) {
        try{
            localStorage.setItem(key, value);
        }catch(e){
            console.log(e);
        }   
    }

    static getString(key) {
        try{
            return localStorage.getItem(key) || '';
        }catch(e){
            console.log(e);
        }   
    }
};


export default StorageHelper;
