import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/controls/TextInput';
import * as GlobalVariables from '../../config/configureGlobalVariables';

const ContactUsForm = ({request, onChange, onSave, errors, textVariants}) => {
  
    return (

      <section className="section-form-contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
            <h1>We’d Love to Hear from You!</h1>
            {/* <p>Please be advised, if your enquiry is regarding your score or any changes to it, <br /> you may contact Equifax directly on 13 8332.</p> */}
            <p>If your enquiry is regarding your credit score or any changes to it,
              <br />please click <a href="https://www.getcreditscore.com.au/helpcentre" target="_blank">here</a> for more information.
              <br/>If you have an enquiry regarding your account or your credit information, please
              <br/>complete the form below.</p>
              <div className="form-contact-wrapper">
                {/* <form action={GlobalVariables.CONTACTUS_BASE_URL} method="POST" onSubmit={onSave}> */}
                <form  onSubmit={onSave}>
                <input type="hidden" name="orgid" value={GlobalVariables.CONTACTUS_ORGID} />
                <input type="hidden" name="retURL" value={GlobalVariables.WEB_SPA_URL+"contact-us/confirmation"} />
                <input type="hidden" name="recordType" id="recordType" value={GlobalVariables.CONTACTUS_RECORDTYPE} />

                  <div className="row">                    
                    <div className="col-lg-6 col-12">
                      <TextInput
                        name="name"
                        id="name"
                        placeholder="First name"
                        value={request.name}
                        label="First name*"
                        onChange={onChange}
                        error={errors.name} 
                        />

                      <TextInput
                        name="lastname"
                        id="lastname"
                        placeholder="Last name"
                        value={request.lastname}
                        label="Last name*"
                        onChange={onChange}
                        error={errors.lastname} 
                        />

                      <TextInput
                        name="email"
                        id="email"
                        placeholder="Your e-mail address"
                        value={request.email}
                        label="Email*"
                        onChange={onChange}
                        error={errors.email} 
                        />

                      <TextInput
                        name="phone"
                        id="phone"
                        placeholder="Phone number"
                        value={request.phone}
                        label="Phone number*"
                        onChange={onChange}
                        error={errors.phone} 
                        />
                    </div>
                    <div className="col-lg-6 col-12">
                      <TextInput
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                          value={request.subject}
                          label="Subject*"
                          onChange={onChange}
                          error={errors.subject}
                          />

                        <TextInput
                          type="textarea"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={request.description}
                          label="Message*"
                          maxLength="250"
                          onChange={onChange}
                          error={errors.description}
                          />                     

                    </div>
                  </div>
                  
                  {errors.general && <div className="row" >
                    <div className="col-12">
                      <div className="alert alert-danger">
                        {errors.general}
                      </div>
                    </div>
                  </div>}

                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>


        )
      }

ContactUsForm.propTypes = {
    request: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default ContactUsForm;
