import React from 'react';
import PropTypes from 'prop-types';
import * as pages from '../shared/pages';
import ScoreMapList from './ScoreMapList';

const ScoreMapGenerationBox = ({ generationScores = [],  textVariants }) => {

  const genederScoreList = [
    { 
      "gender": textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "generationMale"], 
      "value": generationScores["s"]["m"]
    }, 
    { 
      "gender": textVariants[pages.SCOREMAP + textVariants.textVariantSeparator + "generationFemale"],
      "value": generationScores["s"]["f"]
    }
  ];

  return (
    <div className="scoremap-generation text-center">
      <div className="scoremap-generation-section title-section">
        <div><p className="title">{generationScores.spec.title}</p></div>
        <div><p className="age">{generationScores.spec.age}</p></div>
      </div>
      <div className="avatar-section">
        <img src={generationScores.spec.img} alt={generationScores.spec.title} />
      </div>
      <div className="scoremap-generation-section list-section">
        <ScoreMapList data={genederScoreList}  keyName="gender" keyValueName="value" />
      </div>
    </div>
  );

};

ScoreMapGenerationBox.propTypes = {
  generationScores: PropTypes.object.isRequired,  
  textVariants: PropTypes.object.isRequired
};
  
export default ScoreMapGenerationBox;