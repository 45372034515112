import React from 'react';
import PropTypes from 'prop-types';
import * as ImagesPath from '../../../config/imagesPath';
const $ = window.$;

const PasswordInput = ({name, label, onChange, placeholder, value, error, shouldRender = true, toolTip, maxLength=100, groupClass="form-label-group"}) => {


    $(document).on('click', '.link-password:not(.text)', function (e) {
      $(this).addClass('text');
      $(this).parent().find('.password').attr('type', 'text');
      e.preventDefault();
    });
    
    $(document).on('click', '.link-password.text', function (e) {
      $(this).removeClass('text');
      $(this).parent().find('.password').attr('type', 'password');
      e.preventDefault();
    });

     let inputCls = 'form-control password';

     //let groupClass = "form-group";
      if (error && error.length > 0) {
        inputCls += ' is-invalid';
      }

      let component =   <div ></div>;

      if(shouldRender){
        component =   
        <div className={groupClass}>
              <input
                type="password"
                maxLength={maxLength}
                name={name}
                id={name}
                className={inputCls}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                autoComplete="off" />
              <a className="link-password"><img src={ImagesPath.ImgIconSee} className="img-fluid see-icon" alt=""/></a>
              {toolTip && (
                <a className="info-tooltip" data-toggle="tooltip" data-placement="bottom" title={toolTip["helpText"]}><img src={ImagesPath.ImgIconInfo} className="img-fluid info-icon" alt=""/></a>
              )}

                <label htmlFor={name}>{label}</label>

            
                {error && (
                  <>
                    <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                    <div className="invalid-feedback">
                            {error}
                    </div>
                  </>
                )}

          </div>;
      }

      return (component);
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  shouldRender: PropTypes.bool,
  toolTip: PropTypes.object,
  error: PropTypes.string
};

export default PasswordInput;
