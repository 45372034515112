import React from 'react';
import PropTypes from 'prop-types';

import * as ImagesPath from '../../../../config/imagesPath';

import AboutMeWizActionBtnContent from '../WizardParts/AboutMeWizActionBtnContent';
// import AboutMeWizPeazie from '../WizardParts/AboutMeWizPeazie';
import HLAOptionButtons from '../WizardParts/HLAOptionButtons';
import AboutMeWizFinish from '../WizardParts/AboutMeWizFinish';

export const ThemePack = {
  buttons: "wizard-action-btn-theme-about-me",
  navigation: "wizard-navigation-buttons-theme-hla"//"wizard-navigation-buttons-theme-about-me"
};

export const WizardAboutMeStepComponents = [
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  HLAOptionButtons,
  AboutMeWizFinish
];


export const StepsIndexAboutMe = {
  stepIndexWhyGetScore: 0,
  stepIndexHelpForBetterDeal: 1,
  stepIndexHelpMostOutScore: 2,
  stepIndexAboutYou: 3,
  stepIndexEmploymentStatus: 4,
  stepIndexYourIncome: 5,
  stepIndexYourHome: 6,  
  stepIndexFinish: 7
};

export const WizardAboutMeStepState =
{

  promotionEnabled: true,
  steps: [
    {
      step: StepsIndexAboutMe.stepIndexWhyGetScore,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexHelpForBetterDeal,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexHelpMostOutScore,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexAboutYou,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexEmploymentStatus,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexYourIncome,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },
    {
      step: StepsIndexAboutMe.stepIndexYourHome,
      enabled: true,
      isCompleted: false,
      selectedItems: [false, false, false, false, false, false],
      selectedExtraText: ''
    },    
    {
      step: StepsIndexAboutMe.stepIndexFinish,
      enabled: true,
      isCompleted: false,
      selectedItems: [],
      selectedExtraText: '',
      isWhenShowCompleted: true
    }
  ]
};

export const WizardAboutMeStepsDef = [
  {
    id: 'about-me-why-get-score',
    step: StepsIndexAboutMe.stepIndexWhyGetScore,
    barTitle: "About Me",
    stepTitle: 'Why did you get your credit score?',
    stepsInstruction: 'Select all that apply to you',
    selectedExtraIndex: 5,
    shouldMoveOnAction: false,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, value: "I'm about to apply for credit" },
        { id: 1, value: 'To get a better deal' },
        { id: 2, value: 'I am curious' },
        { id: 3, value: "I've had credit issues"  },
        { id: 4, value: 'To improve my Credit Score' },
        { id: 5, value: 'Other' }
      ]
    }
  },
  {
    id: 'about-me-help-for-better-deal',
    step: StepsIndexAboutMe.stepIndexHelpForBetterDeal,
    barTitle: "About Me",
    stepTitle: 'Let us know how we can help you get a better deal?',
    stepsInstruction: 'Select the most proper',
    selectedExtraIndex: 5,
    stepDefinition: {
      showInCols: 2,
      actions: [
        { id: 0, value: 'Credit Card' },
        { id: 1, value: 'Home Loan' },
        { id: 2, value: 'Personal Loan' },
        { id: 3, value: "Vehicle Loan" },
        { id: 4, value: 'Business Loan' },
        { id: 5, value: 'Other' }
      ]
    }
  },
  {
    id: 'about-me-help-most-out-of-score',
    step: StepsIndexAboutMe.stepIndexHelpMostOutScore,
    barTitle: "About Me",
    stepTitle: 'How can we help you get the most out of GetCreditScore',
    stepsInstruction: 'Select all that apply to you',
    selectedExtraIndex: 4,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, value: 'Reminders to refresh my score' },
        { id: 1, value: 'Money saving ideas' },
        { id: 2, value: "Help me improve my credit score"  },
        { id: 3, value: 'Send me money saving offers' },
        { id: 4, value: 'Other' }
      ]
    }
  },
  {
    id: 'about-me-about-you',
    step: StepsIndexAboutMe.stepIndexAboutYou,
    barTitle: "About Me",
    stepTitle: 'About you?',
    stepSubTitle: 'We use this information to personalise our service for you',
    stepsInstruction: 'Please select one',
    selectedExtraIndex: null,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, value: 'Single', imgUrl: ImagesPath.ImgWizard_AboutMe_Single },
        { id: 1, value: 'Single parent', imgUrl: ImagesPath.ImgWizard_AboutMe_SingleParent },
        { id: 2, value: 'Couple - no kids', imgUrl: ImagesPath.ImgWizard_AboutMe_CoupleNoKid },
        { id: 3, value: "Couple - kids", imgUrl: ImagesPath.ImgWizard_AboutMe_CoupleKids  },
        { id: 4, value: 'Empty Nester', imgUrl: ImagesPath.ImgWizard_AboutMe_EmptyNester }
      ]
    }
  },
  {
    id: 'about-me-employment-status',
    step: StepsIndexAboutMe.stepIndexEmploymentStatus,
    barTitle: "About Me",
    stepTitle: 'Employment Status?',
    stepSubTitle: 'We use this information to personalise our service for you',
    stepsInstruction: 'Please select one',
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, value: 'Full Time / Self Employed' },
        { id: 1, value: 'Part Time /  Casual' },
        { id: 2, value: 'Unemployed' },
        { id: 3, value: "Homemaker"  },
        { id: 4, value: 'Retired' },
        { id: 5, value: 'Student' }
      ]
    }
  },
  {
    id: 'about-me-your-income',
    step: StepsIndexAboutMe.stepIndexYourIncome,
    barTitle: "About Me",
    stepTitle: 'Your Income?',
    stepSubTitle: 'We use this information to personalise our service for you',
    stepsInstruction: 'Please select one',
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 2,
      actions: [
        { id: 0, value: '$0 - $50k' },
        { id: 1, value: '$50 - $100k' },
        { id: 2, value: '$100 - $150k' },
        { id: 3, value: "$150k+"  },
        { id: 4, value: 'Prefer not to say' }
      ]
    }
  },
  {
    id: 'about-me-your-home',
    step: StepsIndexAboutMe.stepIndexYourHome,
    barTitle: "About Me",
    stepTitle: 'Your Home?',
    stepSubTitle: 'We use this information to personalise our service for you',
    stepsInstruction: 'Please select one',
    selectedExtraIndex: 5,
    stepSelectionnUnique: true,
    stepExtraDisabled : true,
    shouldMoveOnAction: true,
    stepDefinition: {
      showInCols: 1,
      actions: [
        { id: 0, value: 'I own my home outright' },
        { id: 1, value: 'I have a mortgage' },
        { id: 2, value: 'I rent' },
        { id: 3, value: "I rent, but am looking to buy"  },
        { id: 4, value: 'I live with family / friends' }
      ]
    }
  },  
  {
    id: 'about-me-finish',
    step: StepsIndexAboutMe.stepIndexFinish,
    barTitle: "About Me",
    stepTitle: 'Profile Completed!',
    stepSubTitle: '',
    stepsInstruction: '',
    stepSelectionnUnique: false,
    stepExtraDisabled : true,
    stepDefinition: {
      subTitle: 'Did you know - some lenders offer lower interest rates based on your credit score?',
      imgUrl: ImagesPath.ImgWizard_Like
    }
  }
];
