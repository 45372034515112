import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';
import LoadingIndicator from '../common/controls/LoadingIndicator';

import * as funcs from '../shared/functions';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';

import ValidationApi from '../../api/validationApi.js';
import VerificationAPI from '../../api/verificationApi';

import ThreatMetrixComponent from '../common/ThreatMetrixComponent';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import * as RoutesVariables from '../../config/routesVariables';

import {PromotionBanner} from '../sections/PromotionBanner';

import FooterSmallSection from '../common/footerSmall'
import Login2FAInputBox from './Login2FAInputBox';
import Login2FASelectionBox from './Login2FASelectionBox';
import GcsRecaptcha from "../common/GcsRecaptcha";

import {USERROLES, hasOneRole} from '../../common/gcsLogic';

import {initTonikForms} from '../../tonik/shared/forms';

export class Login2FAPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {     
      errorMessage: '',            
      loading: false,
      tmLoaded: null,
      tmTryCount : 1,
      tmFinished: true, //!(process.env.REACT_APP_TM == 'true'),
      verificationCode: '',
      otcError: null,   
      isChannelSelection: true,   
      channelSelected: null,
      resetRecaptcha: false      
    };
    
    this.onSelectChannel = this.onSelectChannel.bind(this);    
    this.onVerificationCodeSubmit =  this.onVerificationCodeSubmit.bind(this);
    this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);    
    this.redirectToAuth = this.redirectToAuth.bind(this);    
    this.loadTmVariables = this.loadTmVariables.bind(this);
    this.onSubmitSendOTC = this.onSubmitSendOTC.bind(this);
    this.onSelectRecaptcha = this.onSelectRecaptcha.bind(this);
    this.doResetRecaptcha = this.doResetRecaptcha.bind(this);  
    this.doSubmitSendOTC = this.doSubmitSendOTC.bind(this);
    this.onProfileComplete = this.onProfileComplete.bind(this);
    
    this.recaptchaRef = React.createRef();  
  }

  componentDidMount() {
    
    let ret = '';                   
    ret = RoutesVariables.RouteDashboard;
    
    this.props.actions.isAuthenticated().then(e => {      
    
      let retUrl = parse(this.props.location.search).retUrl;
      if(!e.isAuthenticated || !hasOneRole(e.roles, USERROLES.OneFactor)){       
        ret = RoutesVariables.RouteLogin  + (retUrl ? "?retUrl=" + encodeURIComponent(retUrl) : "" );
        this.props.history.push(ret);
        return;
      } else{

        if(hasOneRole(e.roles, USERROLES.TwoFactor)){
          ret =  retUrl ? retUrl : RoutesVariables.RouteDashboard;
          this.props.history.push(ret);
          return;
        }
      }                        

      this.props.actions.getMenuForPage(pages.LOGIN);
      this.props.actions.getTextVariants();
      //this.loadTmVariables();        
    });    
    
    this.doResetRecaptcha();
    initTonikForms();   
  }

  componentWillReceiveProps(nextProps) {
             
    let ret = '';
    if(nextProps.userProfile && nextProps.userProfile.isAuthenticated){

      let retUrl = parse(this.props.location.search).retUrl;
      // It means it did verify it correctly
      if(hasOneRole(nextProps.userProfile.roles, USERROLES.TwoFactor)){

            if (nextProps.redirect_uri) {
              if(nextProps.redirect_uri.indexOf(RoutesVariables.RouteLogin2FA) >= 0){
                ret = nextProps.redirect_uri + "?retUrl=" + encodeURIComponent(retUrl);
              }else{
                ret = nextProps.redirect_uri;
              }         
            } else if (retUrl) {
              ret = '' + retUrl;
            }                    
            this.props.history.push(ret);
      }
    }       
  }   

  onSelectRecaptcha(token){
   
    if (token) {    
        this.doSubmitSendOTC(token);      
    }      

    this.setState({resetRecaptcha: true, loading: false});
  }

  doResetRecaptcha(){
    if (this.state.resetRecaptcha) {
      try{
        this.recaptchaRef.current.reset();
        this.setState({resetRecaptcha: false});
      }
      catch(ex){
        console.log(ex);
      }      
    }
  }

  loadTmVariables(){

    if(this.props.tm){
      this.setState({tmFinished : true});
    }

    return this.props.actions.tm().then(r => {
      this.setState({tmLoaded : true});
      return r;
    }).catch(e => {
      //this.setState({tmLoaded : false});
    });
  }    
  
  onProfileComplete(session_id){
    this.setState({tmFinished : true});
    //console.log('finish tm loading', this.props.tm == session_id, this.props.tm, session_id);
    if(this.props.tm != session_id){
      this.props.actions.tmSuccess({id: session_id})
    }
  }
     
  onSelectChannel(event, channel){
    event.preventDefault();  
    this.setState({ isChannelSelection: channel == null, channelSelected: channel, otcError: null },
      () => {
        if(channel)  {
          this.onSubmitSendOTC(event);
        }
      });              
  }

  onSubmitSendOTC(){            
    this.setState({ errorMessage: null, infoMessage: null, loading: true });
    
    if(this.state.channelSelected)
      this.recaptchaRef.current.execute();
  }

  doSubmitSendOTC(token){

    var channel = this.state.channelSelected;
    // Send Verification Code
    var sendOtcPromise = null;
    if(channel == 'E'){
      sendOtcPromise = VerificationAPI.sendEmailOneTimeCodeAuth(this.props.userProfile.csrfToken, token);
    } else if(channel == 'M'){
      sendOtcPromise = VerificationAPI.sendMobileOneTimeCodeAuth(this.props.userProfile.csrfToken, token);
    }
    
    if(sendOtcPromise){
        sendOtcPromise.then( (verification) => {
          // Redirect to re-auth
          if(verification && verification.isError){
            this.setState({ isChannelSelection: true, otcError: '', errorMessage: verification.errorMessage, infoMessage: null });
          } else{
            this.setState({ isChannelSelection: false, otcError: null });        
          }
        }).catch( e => {
          
          if( e.status == 400){
              e.json().then( verification => {
                  if(verification){
                  this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
                  } else{
                  this.setState({ errorMessage: 'Unexpected error sending your one time code', infoMessage: null });
                  }                      
                });
          } else {
              this.setState({ errorMessage: 'An unexpected error occurred sending the one time code. Please <a href="/contact-us" style="float:none !important;">contact us</a>. or try to login again', infoMessage: null });
          }

          this.setState({ isChannelSelection: true});
          this.doResetRecaptcha();

        }).finally( () => {
            this.setState({loading: false});
        });     
    }
  }

  onChangeVerificationCode(event) {
  
    let verificationCode = this.state.verificationCode;
    verificationCode = event.target.value;

    return this.setState({ verificationCode: verificationCode, otcError: null });
  }
  
  onVerificationCodeSubmit(event){

    event.preventDefault();
    // Validate    
    if (!this.state.verificationCode || (this.state.verificationCode.length < 5 && !this.state.verificationCode.match(/^[0-9]+$/g))){
      this.setState({otcError: 'The One Time Code is not Valid'});       
    }
    else{
      this.setState({ errorMessage: null, infoMessage: null, loading: true });
      // Verify Verification Code
      var verifyOtcPromise = null;
      if(this.state.channelSelected == 'E'){
        verifyOtcPromise = VerificationAPI.verifyEmailOneTimeCodeAuth(this.props.userProfile.csrfToken, this.state.verificationCode);
      } else if(this.state.channelSelected == 'M'){
        verifyOtcPromise = VerificationAPI.verifyMobileOneTimeCodeAuth(this.props.userProfile.csrfToken, this.state.verificationCode);
      }

      verifyOtcPromise.then( (verification) => {
        // Redirect to re-auth
        if(verification.isError){
          this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
        } else{
          this.redirectToAuth();
        }
      }).catch( e => {
        if( e.status == 400){
          e.json().then( verification => {
              this.setState({ errorMessage: verification.errorMessage, infoMessage: null });
            });
        } else {
            this.setState({ errorMessage: 'An unexpected error occurred. Please <a href="/contact-us" style="float:none !important;">contact us</a>.', infoMessage: null });
        }
        }).finally( () => {
            this.setState({loading: false});
        });
    }
  }
  
  redirectToAuth(){
    
    this.props.actions.refreshAuthentication(this.state.channelSelected).then(r =>{

      if(r.roles && hasOneRole(r.roles, USERROLES.TwoFactor)){
        let retUrl = parse(this.props.location.search).retUrl;
        if(retUrl == RoutesVariables.RouteLogin2FA){
          retUrl = ''; // Cancel infinite loop
        }
        var navigateUrl = r.redirect_uri || (retUrl ? retUrl : RoutesVariables.RouteDashboard);
        this.props.history.push(navigateUrl);              
      }
      else{        
        this.setState({errorMessage: 'We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another verification code with the link below'});
      }
    }).catch(e => {

      if(e.status == 400){
          e.json().then( resp => {
            this.setState({errorMessage: resp.errorMessage});
          });
      }
      else{
        this.setState({errorMessage: 'An unexpected error ocurred. We could not authorise you. Please <a href="/contact-us" style="float:none !important;">contact us</a> or try to send another SMS verification'});
      }
    });
  } 
   
  render() {                                   
      return (
          <div>
              <GcsRecaptcha
                recaptchaRef={this.recaptchaRef}
                onSelectRecaptcha={this.onSelectRecaptcha}
              />

            {/* {
            !this.props.tm && <ThreatMetrixComponent
              userIdentifier={this.props.tm}
              onProfileComplete={this.onProfileComplete}
              pageNumber={2}
            />
            } */}
            <Menu logoBlack={true} menuItemList={this.props.menuItemList} activeItem={false} />
            
            {this.state.isChannelSelection &&
              <div>          
                <Login2FASelectionBox
                  textVariants={this.props.textVariants}                
                  onActionClick={this.onSelectChannel}
                  errorMessage={this.state.errorMessage}                                        
                  loading={this.state.loading} 
                  infoMessage={this.state.infoMessage}                    
                />
              </div>}

            {!this.state.isChannelSelection &&
              <div>          
                <Login2FAInputBox
                  textVariants={this.props.textVariants}                
                  onActionClick={this.onVerificationCodeSubmit}   
                  onChange={this.onChangeVerificationCode}  
                  onGoBack={(e) => {this.onSelectChannel(e, null)}}                                      
                  otcError={this.state.otcError}
                  loading={this.state.loading}
                  infoMessage={this.state.infoMessage}
                  errorMessage={this.state.errorMessage}
                  enabled={this.state.tmFinished}
                />
                </div>
            }              

            <FooterSmallSection></FooterSmallSection>
          </div>
      );
      }
  }

Login2FAPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.LOGIN]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.LOGIN];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    tm: state.userProfile.tm,
    menuItemList: menuItems,
    textVariants: txtVariants,
    registeringUsername: state.userProfile.registeringUsername,
    registeringRememberMe: state.userProfile.registeringRememberMe,
    isAuthenticated: state.userProfile.isAuthenticated,
    redirect_uri: state.userProfile.redirect_uri,
    rememberMeInfo: state.userProfile.rememberMeInfo,
    userProfile:  state.userProfile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login2FAPage);
