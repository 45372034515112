import LayoutApi from '../../api/mockLayoutApi';
import ArticlesApi from '../../api/mockArticlesApi.js'
import ScoreApi from '../../api/mockScoreApi.js'
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';



export function loadDashboardTabsSuccess(dashboardTabs) {
  return {type: types.LOAD_DASHBOARD_TABS_SUCCESS, dashboardTabs};
}

export function loadDashboardTabs(excludeTabIndex, forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    if(!forced && (state.dashboardPage.dashboardTabs && state.dashboardPage.dashboardTabs.length > 0)){
      return null;
    }

    return LayoutApi.getDashboardTabs(state.userProfile, excludeTabIndex).then(dashboardTabs => {
      dispatch(loadDashboardTabsSuccess(dashboardTabs));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadCallsToActionSuccess(callsToAction) {
  return {type: types.LOAD_SCORE_CALLTOACTION_SUCCESS, callsToAction};
}

export function loadCallsToAction(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    // if(!forced && state.dashboardPage.callsToAction && state.dashboardPage.callsToAction.length > 0){
    //   return null;
    // }

    return ScoreApi.getCallsToAction(state.scoreProfile, state.userProfile, state.productResult).then(callsToAction => {
      dispatch(loadCallsToActionSuccess(callsToAction));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadingArticlesForDashboardSuccess(articlesLoading) {
  return {type: types.LOADING_DASHBOARD_ARTICLES_SUCCESS, articlesLoading};
}

export function loadArticlesForDashboardSuccess(articles) {
  return {type: types.LOAD_DASHBOARD_ARTICLES_SUCCESS, articles};
}

export function loadArticlesForDashboard(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    // if(!forced && state.dashboardPage.articles && state.dashboardPage.articles.articles && state.dashboardPage.articles.articles.length > 0){
    //   return null;
    // }

    dispatch(loadingArticlesForDashboardSuccess(true));

    return ArticlesApi.getArticlesForDashboard(state.userProfile).then(articles => {
      dispatch(loadArticlesForDashboardSuccess(articles));
    }).catch(error => {
      dispatch(loadingArticlesForDashboardSuccess(false));
      throw(error);
    });
  };
}

export function updateSelectedDashboardTab(tabIndex) {
  return (dispatch, getState) => (dispatch({type: types.UPDATE_DASHBOARD_TABINDEX_SUCCESS, tabIndex}));
}
