import React from 'react';
import PropTypes from 'prop-types';
import * as ImagesPath from '../../../config/imagesPath';

const GenderInput = ({name, label, onChange, shouldRender = true, value, error, cssClass = 'radio-button mt-1 ml-lg-4'}) => {
      
     var setGender = function(gender, field) {
          var obj = {
            target : {
                name:  field,
                value:  gender
            }
          };

        onChange(obj);
      };

      var Component = <></>;
      if(shouldRender){
          Component =  <div className={cssClass}>
          <div className="form-label-group">
          <span className="reg-title-up">GENDER*</span><br />
          <div className="form-label-group form-radio reg-radio-mr">
            <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked={value == 'M'} onChange={setGender.bind(this, 'M', name)} />
            <label className="form-check-label reg-radio-label" htmlFor="gender_male">
              Male
            </label>
          </div>
          <div className="form-label-group form-radio reg-radio-mr">
            <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" checked={value == 'F'} onChange={setGender.bind(this, 'F', name)} />
            <label className="form-check-label reg-radio-label" htmlFor="gender_female">
              Female
            </label>
          </div>
          <div className="form-label-group form-radio">
            <input className="form-check-input" type="radio" name="gender" id="gender_other" value="option3" checked={value == 'O'} onChange={setGender.bind(this, 'O', name)} />
            <label className="form-check-label reg-radio-label" htmlFor="gender_other">
              Other
            </label>
          </div>
          {error && (
                    <div className="invalid-feedback text-danger form-error-msg">
                            {error}
                    </div>
                )}
          {/* <div className="col-sm-offset-4  col-sm-6 col-xs-12  ">
                    {error && <div className="error-message xs-mt-15">{error}</div>}
            </div> */}
          </div>
      </div>;
      }
      return (Component);
};

 GenderInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string
};

export default GenderInput;
