import React from 'react';
import PropTypes from 'prop-types';

import * as funcs from '../shared/functions';

export const ApiFailedToLoadComponentWithText = ({ text }) =>
{
  return (
      <div className="container-fluid api-failed-to-load">
        <div className="row">
          <div className="col-xs-12 w-100 text-center">
            <span className="title" dangerouslySetInnerHTML={funcs.createMarkup(text)}></span>
        </div>
      </div>
      </div>
  )
};

ApiFailedToLoadComponentWithText.propTypes = {
    text: PropTypes.string.isRequired
};
