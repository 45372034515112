import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';
import * as userActions from '../../redux/actions/userActions';

export class LogoutPage extends React.Component { 
  constructor(props, context){
         super(props, context);
     }

     componentDidMount() {
       this.props.actions.logout();
       this.props.history.push('/');
     }

    render() {

        return (
            <div>
            </div>
        )
   }
}

LogoutPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, userActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
