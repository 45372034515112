import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as pages from '../shared/pages';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as trackingActions from '../../redux/actions/trackingActions';

import * as ImagesPath from '../../config/imagesPath';
import Menu from  '../menu/Menu.js'
import * as RoutesVariables from '../../config/routesVariables';
import FooterSection from  '../common/footer'
export class PageNotFound extends React.Component {

  componentDidMount() {
     this.props.actions.getMenuForPage(pages.CONTACTUS);
     this.props.actions.getTextVariants();
     this.props.actions.trackNotFoundPage();
  }

  render() {

    let pageNotFounMessage = "Page not found!";
    let goToMainPageMessage = "Go back to the main page";
    if (this.props.textVariants) {
      pageNotFounMessage = this.props.textVariants[pages.PAGENOTFOUND + this.props.textVariants.textVariantSeparator + "page_not_found"];
      goToMainPageMessage = this.props.textVariants[pages.PAGENOTFOUND + this.props.textVariants.textVariantSeparator + "go_to_main_page"];
    }

    return (
      <div className="page-template-page-about bg-circle page-home page-contact">
        <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>

        <section className="page-account-delete page-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                <div className="images">
                  <img src={ImagesPath.ImgIconHelpInfo2x} className="img-fluid" alt=""/>
                </div>
                <h1>Error</h1>
                <p>{pageNotFounMessage}</p>
                 <div className="button">
                  <a href={RoutesVariables.RouteHome} className="btn btn-primary">Go back to the main page</a>
                </div> 
              </div>
            </div>
          </div>
        </section>

        <FooterSection/>
    </div>
  );
  }
}

PageNotFound.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.PAGENOTFOUND]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.PAGENOTFOUND];
     }
   }

 let txtVariants = {};
 if(state.textVariants){
   txtVariants = state.textVariants;
 }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, trackingActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);