import React from 'react';
import * as funcs from '../../../shared/functions';


const $ = window.$;

class WizardErrorMessage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    let {message, wizardWarningMessageList, currentStep, wizardStepIndex, wizardStepsDef} = this.props;
    const isFinal = wizardStepsDef[currentStep]["isFinalStep"];

    const isInSpeakToExpertPath = currentStep === wizardStepIndex.stepIndexBrokerRefinanceWhatToDo
                                  || currentStep === wizardStepIndex.stepIndexBrokerBuyWhatToDo
                                  // || currentStep === wizardStepIndex.stepIndexBrokerCitizenOrPR
                                  || currentStep === wizardStepIndex.stepIndexBrokerFinish;

    let errorComponent = <div />;
    let warningListComponent = <div />;

    if (message) {
      errorComponent = (
        <div className="text-center">
          <div className="alert alert-warning">
            <div className="wiz-step-error" dangerouslySetInnerHTML={funcs.createMarkup(message)} />
          </div>
        </div>);
    }

    if (wizardWarningMessageList && !isFinal && !isInSpeakToExpertPath) {
      const warningList = wizardWarningMessageList.map((warning, i) =>
        <li key={i}>
          <a className="tooltipInfo" data-toggle="tooltip" data-placement="top" data-title={warning.description}>
            <div className="score-a glyphicon glyphicon-info-sign" />
            <div className="wizard-box-error-text">{warning.message}</div>
          </a>
        </li>
      );

      if (warningList.length > 0) {
        warningListComponent = (
          <div className="wizard-warning-list">
            <div className="alert alert-warning fade in formTooltipContainer">
              <ul className="wizard-box-error-list">
                {warningList}
              </ul>
            </div>
          </div>);
      }
    }

    let component = (
      <div className="wizard-box-error">
        {errorComponent}
        {warningListComponent}
      </div>);

    return (component);
  }
}

export default WizardErrorMessage;
