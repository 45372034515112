import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js'

import ProductSection from './../sections/ProductSection';
import GetStartedSection from './../sections/GetStartedSection';

import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as productActions from '../../redux/actions/productActions';
import * as trackingActions from '../../redux/actions/trackingActions';

import * as GlobalVariables from '../../config/configureGlobalVariables';

import { createMarkup } from '../../components/shared/functions.js'
import GoToPreviousPage from '../common/controls/GoToPreviousPage';

import {iFrameResize} from 'iframe-resizer';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';
const $ = window.$;

const CreditCardTitle = ({ textVariants }) => {

  return (
    <section className="credit-cards">
      <div className="container-fluid left-container-padding right-container-padding product-section">
        <div className="row">
          <div className="col-sm-3 col-xs-12 text-right col-md-12-text-center">
          <img alt="" src={ImagesPath.ImgWizard_CreditCard_Banner} className="hidden-xs" />
          </div>
          <div className="col-sm-9 col-xs-12 col-md-12-text-center">
              <h1>{textVariants[pages.CREDITCARDS + textVariants.textVariantSeparator + "header"]}</h1>
              <p>{textVariants[pages.CREDITCARDS + textVariants.textVariantSeparator + "Knowing_credit_score_text"]}</p>
              <p dangerouslySetInnerHTML={createMarkup(textVariants[pages.CREDITCARDS + textVariants.textVariantSeparator + "latest_offers_text"])}></p>
          </div>
         </div>
        </div>
    </section>
 )
 }

const CreditCardTable = ({ textVariants }) => {

  return (

  <section className="credit-cards-table">
    <div className="container-fluid container-fix">
      <div className="row">
        <div className="col-xs-12 text-center">

          <iframe frameBorder="0" src={GlobalVariables.CREDITCARDS_URL}  id="ratecity-iframe" align="left">
          </iframe>

        </div>
      </div>

      <div className="row">
       <div className="col-xs-12 text-center terms">
       <p className="xs-mt-20" dangerouslySetInnerHTML={createMarkup(textVariants[pages.CREDITCARDS + textVariants.textVariantSeparator + "rateCity _text"])}>
       </p>
       </div>
      </div>
    </div>
  </section>
)
}


class CreditCardsPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.onGetScoreClick = this.onGetScoreClick.bind(this);
        this.refreshExternalHooks = this.refreshExternalHooks.bind(this);
        this.onProductClick = this.onProductClick.bind(this);
        this.onGetScoreClickToFreeScore = this.onGetScoreClickToFreeScore.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.redirect = this.redirect.bind(this);
    }


    componentDidMount() {
       this.props.actions.getMenuForPage(pages.CREDITCARDS);
       this.props.actions.getTextVariants();
       this.props.actions.loadProducts();
       this.refreshExternalHooks();
    }

    refreshExternalHooks(){
      $(document).ready(function(){
         $('iframe').iFrameResize({});
        });
    }



    onGetScoreClick(event){

      this.props.actions.trackGetScoreButtonClick(event.target.id);
       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.redirect(RoutesVariables.RouteCreateAccount);
       }
    }

    redirect(url) {
        this.props.history.push(url);
      }

    onGetScoreClickToFreeScore(event){
       this.props.actions.trackButtonClick(pages.HOME, 'gcsBtn_getStarted');
       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.redirect(RoutesVariables.RouteFreeCreditScore);
       }
    }

    onLoginClick(event){
         this.props.actions.trackButtonClick(pages.HOME, 'login_splash');
         this.redirect(RoutesVariables.RouteLogin);
    }

    onProductClick(event, product) {
        this.props.actions.trackGcsProductClick(product, pages.CREDITCARDS);
    }

    render() {

      let mainComponent = <div></div>;

      if(this.props.textVariants){
         mainComponent = <div className="creditCardsPage">

         <Menu menuItemList={this.props.menuItemList} />

         <CreditCardTitle textVariants={this.props.textVariants} />

         <GoToPreviousPage textVariants={this.props.textVariants} />

         <CreditCardTable textVariants={this.props.textVariants} />

         <ProductSection
            key="products"
            products={this.props.products}
            textVariants={this.props.textVariants}
            onProductClick={this.onProductClick}
             />

            <GetStartedSection key="getstarted"
               isAuthenticated={this.props.isAuthenticated}
               onLoginClick={this.onLoginClick}
               onGetScoreClick={this.onGetScoreClickToFreeScore}
               textVariants={this.props.textVariants}  />

       </div>
     }

        return ( mainComponent );
    }
}

CreditCardsPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  let menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.CREDITCARDS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.CREDITCARDS];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

   let products = [];
   if(state.productResult && state.productResult.products){

     let p = null;
     for(let i = 0; i < state.productResult.products.length; i++) {
         if((p = state.productResult.products[i]).linkRef != "creditcard"){
           products.push(p);
        }
     }
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    products : products,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, layoutActions, trackingActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardsPage);
