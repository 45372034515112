import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as pages from '../shared/pages';
import * as GlobalVariables from '../../config/configureGlobalVariables';
import ValidationApi from '../../api/validationApi';
import Menu from  '../menu/Menu';
import { HomeSplashSection, HomeSplashCallToAction, HomeSplashCallToActionOldStyle, HomeSplashCallToActionPlaceholder, HomeSplashFeaturedOnSection, HomeHowWork } from './HomeSplashSection';
//import MenuTopFixed from '../menu/MenuTopFixed';
import { HomeProcessSection, HomeFaqs } from './HomeProcessSection';
import HomeKeyFeaturesSection from './HomeKeyFeaturesSection';
import ProductSection from './../sections/ProductSection';
import ArticlesSection from './ArticlesSection';
import GetStartedSection from './../sections/GetStartedSection';
import CounterSection from './../sections/CounterSection';

import * as productActions from '../../redux/actions/productActions';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as homePageActions from '../../redux/actions/homePageActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as userActions from '../../redux/actions/userActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import * as RoutesVariables from '../../config/routesVariables';

import FooterSection from  '../common/footer'


import {tonikInitModal} from '../../tonik/modal';
import {tonikInitHomePage} from '../../tonik/homepage';    


const $ = window.$

const InternalProductSection = (props) => {
  
  return (
    <section className="section-check-offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h2>Check out the special<br/> offers from our partners</h2>
              <p>Become a <span><a href={RoutesVariables.RouteCreateAccount}>member</a></span> of Get Credit Score to access specials<br/> offers that are tailored to your credit score. It’s free!  </p>
                <ProductSection
                  key="products"
                  loading={props.productLoading}
                  products={props.products}
                  lastProduct={props.lastProduct}
                  textVariants={props.textVariants}
                  onProductClick={props.onProductClick}
                  title={props.textVariants[pages.HOME + props.textVariants.textVariantSeparator + "product_header"]}
                  disclaimer={props.textVariants[pages.HOME + props.textVariants.textVariantSeparator + "product_disclaimer"]}
                />
            </div>
          </div>
        </div>
      </section>

  );

}




class HomePage  extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      postCode: '',
      scoreMapHasError: false,
      scoreMapSubmittedEmpty: false,
      scoreMapSectionBackground: "scoremap-section-background"
    }

    this.getComponentsList = this.getComponentsList.bind(this);
    this.onGetScoreClick = this.onGetScoreClick.bind(this);
    this.onGetScoreClickToFreeScore = this.onGetScoreClickToFreeScore.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onLearnMoreClick = this.onLearnMoreClick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onArticleClick = this.onArticleClick.bind(this);
    this.onResourceClick = this.onResourceClick.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onLoginStartedClick = this.onLoginStartedClick.bind(this);
    this.onScoreMapInputChange = this.onScoreMapInputChange.bind(this);
    this.onScoreMapSubmit = this.onScoreMapSubmit.bind(this);
  }

  componentDidMount() {
    //window['initAppJs'](true);

    this.props.actions.getMenuForPage(pages.HOME);
    this.props.actions.initUser();
    this.props.actions.loadProducts();
    this.props.actions.loadkeyFeatureList();
    this.props.actions.loadProcessSteps();
    this.props.actions.loadArticlesForHomePage();
    this.props.actions.loadHomeFaq();
    this.props.actions.getTextVariants();
    //this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})

    tonikInitModal();    
    tonikInitHomePage();  
  }

  componentDidUpdate(prevProps, prevState) {
    const isProcessStepsReady = prevProps
    && this.props
    && prevProps.processSteps
    && this.props.processSteps
    && (prevProps.processSteps.length != this.props.processSteps.length);

    const isArticlesReady = this.props.articlesLoaded;

  }

  componentWillUnmount() {
    $(document).unbind('scroll.home');
  }


  onGetScoreClick(event) {
    this.props.actions.trackGetScoreButtonClick(event.target.id);
    if (this.props.isAuthenticated) {
      this.redirect(RoutesVariables.RouteCreditScore);
    } else {
      this.redirect(RoutesVariables.RouteCreateAccount);
    }
  }

  onGetScoreClickToFreeScore(event) {
    this.props.actions.trackButtonClick(pages.HOME, 'gcsBtn_getStarted');
    if (this.props.isAuthenticated) {
      this.redirect(RoutesVariables.RouteCreditScore);
    } else {
      this.redirect(RoutesVariables.RouteFreeCreditScore);
    }
  }

  onLoginClick(event) {
    this.props.actions.trackButtonClick(pages.HOME, 'login_splash');
    this.redirect(RoutesVariables.RouteLogin);
  }

  onLoginStartedClick(event) {
    this.props.actions.trackButtonClick(pages.HOME, 'gcsBtn_getStarted');
    this.redirect(RoutesVariables.RouteLogin);
  }

  onLearnMoreClick(event) {
    //this.redirect('/dashboard#dashboard-top-page');    
  }

  onProductClick(event, product) {
    this.props.actions.trackGcsProductClick(product, pages.HOME, 'Coffee-Girl');
  }

  onArticleClick(event, article) {
    //event.preventDefault();
    //this.context.router.push('/article1/' + article.seoName);
  }

  onResourceClick(event, resourceId) {

  }

  onScoreMapInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value,
      scoreMapHasError: false,
      scoreMapSubmittedEmpty: false
    });
  }

  onScoreMapSubmit(event) {
    event.preventDefault();

    const postCode = this.state.postCode;

    if (!ValidationApi.isValidPostCode(postCode)) {
      this.setState({
        scoreMapHasError: true,
        scoreMapSubmittedEmpty: this.state.postCode.length === 0
      });

      return false;
    }

    this.props.actions.trackScoreMapSearch("ScoreMapPagePostCode", postCode);
    this.redirect("/credit-score-map/" + postCode.toString());
  }

  redirect(url) {
    this.props.history.push(url);
  }

  getComponentsList() {
    let componentList = [

      <HomeProcessSection
        key="process"
        steps={this.props.processSteps}
        onGetScoreClick={this.onGetScoreClick}
      />,

      <HomeSplashFeaturedOnSection
        key="featured"        
        textVariants={this.props.textVariants}
      />,

      <CounterSection
        key="scorecounter-transparent"
        counterSectionTheme="counter-section-theme-semi-transparent counter-section-theme-xsmall-transparent"
        textVariants={this.props.textVariants}
        isTrustPilotEnabled={this.props.isTrustPilotEnabled}
      />,

      <HomeKeyFeaturesSection
        key="keyfeature"
        features={this.props.keyFeatureList}
        textVariants={this.props.textVariants}
        onLearnMoreClick={this.onLearnMoreClick}
      />,

      <InternalProductSection
       key="internalprod"
       onProductClick={this.onProductClick}
       {...this.props}
      />
      ,

      <ArticlesSection
        key="articles"
        articles={this.props.articles}
        onLearnMoreClick={this.onLearnMoreClick}
        onArticleClick={this.onArticleClick}
        onResourceClick={this.onResourceClick}
        textVariants={this.props.textVariants}
      />,



      <HomeFaqs
        key="faqs"
        steps={this.props.gcsHomeFaqs}
      />,

      <GetStartedSection
        key="getstarted"
        isAuthenticated={this.props.isAuthenticated}
        onLoginClick={this.onLoginStartedClick}
        onGetScoreClick={this.onGetScoreClickToFreeScore}
        textVariants={this.props.textVariants}
      />,

      <FooterSection key="footer" />
    ];
    return componentList;
  }

  render() {
    return (
      <div className="page-home">
              
        <HomeSplashSection
          cssClass="homesplash"
          textVariants={this.props.textVariants}
          menuItemList={this.props.menuItemList}
          user={this.props.userProfile}
        >

        <div>
          <HomeSplashCallToActionPlaceholder
            onGetScoreClick={this.onGetScoreClick}
            onLoginClick={this.onLoginClick}
            isAuthenticated={this.props.isAuthenticated}
            textVariants={this.props.textVariants}
            userProfile={this.props.userProfile}
            originPage={pages.HOME}
            location={this.props.location}
          />

          {/* <HomeSplashFeaturedOnSection  textVariants={this.props.textVariants} /> */}

          {/* <CounterSection
            key="scorecounter-transparent"
            counterSectionTheme="counter-section-theme-semi-transparent counter-section-theme-xsmall-transparent"
            forMobile
          /> */}
        </div>
        </HomeSplashSection>
        

        {/* <MenuTopFixed onGetScoreClick={this.onGetScoreClick} /> */}

        {this.getComponentsList()}

      </div>
    )
  }
}

HomePage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  var menuItems = [];
  if (state.menu) {
    var menu = state.menu;
    if ( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOME]) {
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOME];
    }
  }

  var keyFeatureList = [];
  if (state.homePage && state.homePage.keyFeatureList) {
    keyFeatureList = state.homePage.keyFeatureList;
  }

  var processSteps = {};
  if (state.homePage && state.homePage.processSteps) {
    processSteps = state.homePage.processSteps;
  }

  var gcsHomeFaqs = [];
  if (state.homePage && state.homePage.gcsHomeFaqs) {
    gcsHomeFaqs = state.homePage.gcsHomeFaqs;
  }

  var articles = [];
  if (state.homePage && state.homePage.articles) {
    articles = state.homePage.articles;
  }

  let txtVariants = {};
  if (state.textVariants) {
    txtVariants = state.textVariants;
  }

  let products = [];
  let productLoading = null;
  let lastProduct = null;
  if (state.productResult && state.productResult.products) {
    products = state.productResult.products;
    productLoading = state.productResult.loading;
    lastProduct = state.productResult.lastProduct;
  }

  let isAnonymous = true;
  if (state.productResult) {
    isAnonymous = state.productResult.isAnonymous;
  }

  return {
    products : products,
    productLoading: productLoading,
    lastProduct: lastProduct,
    isAnonymous: isAnonymous,
    rememberMe: state.userProfile.rememberMeInfo.rememberMe,
    isAuthenticated: state.userProfile.isAuthenticated,
    menuItemList: menuItems,
    keyFeatureList: keyFeatureList,
    articles: articles,
    articlesLoaded: state.homePage.articlesLoaded,
    articlesLoading: state.homePage.articlesLoading,
    processSteps: processSteps,
    gcsHomeFaqs: gcsHomeFaqs,
    textVariants: txtVariants,
    userProfile: state.userProfile,
    isTrustPilotEnabled: state.userProfile.isTrustPilotEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, layoutActions, homePageActions, trackingActions, userActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
