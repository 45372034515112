import LayoutApi from '../../api/mockLayoutApi';
import * as types from './actionTypes';
import {beginAjaxCall} from './ajaxStatusActions';
//import { push } from 'react-router-redux';



// export function navigateToUrl(url) {
//   return (dispatch, getState) => {

//       dispatch(push(url));
//   }
// }

export function getMenuForPageSuccess(pageid, menuForPage, isAuthenticated) {

  return {type: types.LOAD_MENU_SUCCESS, pageid, menuForPage, isAuthenticated};
}

export function getMenuForPage(pageid, forced=false) {
  return (dispatch, getState) => {

    var state = getState();

    var menuItemsPerPage = state.menu.menuItemsPerPage;
    var userProfile = state.userProfile;
    if(!forced &&
       menuItemsPerPage.menuItems.hasOwnProperty(pageid) &&
       menuItemsPerPage.menuItemsAuthenticated.hasOwnProperty(pageid) &&
       menuItemsPerPage.menuItemsAuthenticated[pageid] == userProfile.isAuthenticated){

       return null;//retrievedAuthenticated
    }

    //todo: we should load this items per user
    var menuItemListInState = state.menu.menuItemList;
    var menuItemsNamesPerPageInState = state.menu.menuItemsNamesPerPage;

    return LayoutApi.getMenuForPage(pageid, userProfile, menuItemListInState, menuItemsNamesPerPageInState).then(menuForPage => {

      dispatch(getMenuForPageSuccess(pageid, menuForPage, userProfile.isAuthenticated));
    }).catch(error => {
      throw(error);
    });
  };
}

export function getTextVariantsSuccess(textVariants) {

  return {type: types.LOAD_TEXT_VARIANTS_SUCCESS, textVariants};
}

export function getTextVariants(forced=false) {
  return (dispatch, getState) => {

    var state = getState();
    if(!forced && state.textVariants){
      return null;
    }

    return LayoutApi.getTextVariants(state.userProfile).then(variants => {
      dispatch(getTextVariantsSuccess(variants));
    }).catch(error => {
      throw(error);
    });
  };
}
