import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import FooterSection from  '../common/footer'

class ContactUsSuccessPage extends React.Component { 
    constructor(props, context) {
        super(props, context);

        this.state = {
        };
    }

    componentDidMount() {

      //window['initAppJs'](true);
      this.props.actions.getMenuForPage(pages.HOME);
       this.props.actions.getTextVariants();
       this.props.actions.initUser(true);


       this.props.actions.isAuthenticated().then(e =>
        {
          this.props.actions.initUser(true).then((initUserResponse) => {

            this.props.actions.getMenuForPage(pages.HOME);
            //this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
    

            });
          });

    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div className="page-template-page-about bg-circle page-home page-contact">
              <Menu logoBlack={true} menuItemList={this.props.menuItemList} mainClass="header-home" blueIcons={true} navClass="col-xl-8 offset-xl-2 col-md-9 d-none d-lg-inline-block" user={this.props.userProfile}/>

              <section className="page-account-delete page-about">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                      <div className="images">
                        <img src={ImagesPath.ImgIconSubmission} className="img-fluid" alt=""/>
                      </div>
                      <h1>Thank you for your submission  </h1>
                      <p>Our consultant will contact you as soon as possible. For any further<br/> questions you can visit the help center and find the topic. that interests you.</p>
                      <div className="button">
                        <a href={RoutesVariables.RouteLogin} className="btn btn-primary">Logged in</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <FooterSection/>
          </div>
        );
    }
}

ContactUsSuccessPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOME]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOME];
     }
   }

   
   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, scoreActions) , dispatch)
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsSuccessPage);
