import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as GlobalVariables from '../../config/configureGlobalVariables';


function resetReportProfileMetadata(state){

  state.apiFail = false;
  state.loading = false;  
  state.isTimeout = false;
  state.tryingGetReportCount = 0;
  state.isServiceNotAvailableNow = false;
}

export default function reportReducer(state = initialState.reportProfile, action) {
  switch (action.type) {

     case types.AJAX_LOAD_REPORT:

         var newState = Object.assign({}, state);
         newState.loading = action.loading;

         if(newState.loading){
           newState.isTimeout = false;
           newState.tryingGetReportCount = newState.tryingGetReportCount + 1;
         }

        return newState;

     case types.API_FAIL + 'reportProfile':

         var newState = Object.assign({}, state);
         resetReportProfileMetadata(newState);
         newState.apiFail = true;         
        return newState;

     case types.API_FAIL + 'EADown':
        var newState = Object.assign({}, state);
        resetReportProfileMetadata(newState);
        newState.isServiceNotAvailableNow = true;         
        return newState;

     case types.RESET_REPORT_PROFILE:

        var newState = Object.assign({}, initialState.reportProfile);

            newState.reportInfo = Object.assign({}, initialState.reportProfile.reportInfo);  
            newState.reportInfo.loaded = false;
        return newState;


      case types.LOAD_REPORT_PROFILE_SUCCESS:
      
        var newState = Object.assign({}, state);

        if(action.reportInfo){

          // Reset
          resetReportProfileMetadata(newState);
          newState.reportInfo = Object.assign({}, initialState.reportProfile.reportInfo);
          newState.reportInfo = action.reportInfo;          
          newState.reportInfo.loaded = true;      

          if(newState.tryingGetReportCount > newState.tryingGetReportMax){
            newState.isTimeout = true;
            newState.reportInfo.issue.noReport = true;
            newState.reportInfo.issue.isTryLater = true;
          }          
        }

        return newState;   

    default:
      return state;
  }
}
