
import $ from 'jquery'
import 'bootstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import slick from 'slick-carousel-latest'
import {tonikInitScrollWithAnimation} from './shared/scrollWithAnimation';


export const tonikInitMonthSlider = (sliderId) => {

  //todestroy $(element).slick('unslick');
  //$('.my-slider')[0].slick.refresh()
  try{
  if(sliderId){
    sliderId = '#' + sliderId;
  }
  else{
    sliderId = '.months-slider';
  }

  $(sliderId).slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false
        }
      }
    ]
  })
} catch(e){
  console.debug('gcs slick ', e);
}

  return;
}

export const tonikInitReport = () => {

  tonikInitScrollWithAnimation();
  tonikInitMonthSlider();

return;
}