import React from 'react';
import DropwDownListForInput from './DropwDownListForInput';
import HelperApi from '../../../../api/HelperApi';
import * as funcs from '../../../shared/functions';


const HLAAmountFrequencyPicker = ({step, stepState, onUpdate, cssClass}) => {
  let component = <div />;
  let _key = 0;
  let _key2 = 1;

  let updateText = function(event, stepId, stepNro, questionId, key) {
    const unmaskedValue = HelperApi.removeCommaSeparators(event.target.value);
    onUpdate(stepId, stepNro, questionId, null, false, [{inputKey: key, inputValue: unmaskedValue}]);
  };

  let updateTextValue = function(value, stepId, stepNro, questionId, key) {
    onUpdate(stepId, stepNro, questionId, null, false, [{inputKey: key, inputValue: value}]);
  };

  let moveForward = function(e) {
    e.preventDefault();
    onUpdate(step["id"], step["step"], 'keyvalue', null,  true, [{inputKey: _key, inputValue: stepState["selectedTexts"][_key]}, {inputKey: _key2, inputValue: stepState["selectedTexts"][_key2]}]);
  };

  if (step && stepState) {
    let i = 0;
    const maskedValue = HelperApi.toThousandSeparatedFormat(stepState["selectedTexts"][_key]); 

    let extraComponent = <div />;

    extraComponent = (
      <div className="input-group mb-3">
      <div className="input-group-prepend">
        <div className="input-group-text">$</div>
      </div>
      <input
          className="form-control input-lg wizard-action-input-txt"
          type="tel"
          value={maskedValue}
          step="1"
          pattern="[0-9,]*"
          data-parsley-pattern="[0-9,]*"
          onChange={(evt) => updateText(evt, step["id"], step["step"], 'keyvalue', _key)}
        />

        <DropwDownListForInput
            selectedValue={stepState["selectedTexts"][_key2]}
            onChange={(evt, v) => updateTextValue(v, step["id"], step["step"], 'keyvalue', _key2)}
          />
              
        { step["stepsTnCs"] && <span className="wizard-step-title mt-2" dangerouslySetInnerHTML={funcs.createMarkup(step["stepsTnCs"])} ></span>}
    </div>
    );

    component = (
      <form onSubmit={(e) => moveForward(e)}>
        {extraComponent}
      </form>
    );
  }

  return ( component );
};

export default HLAAmountFrequencyPicker;
