import React from 'react';
import PropTypes from 'prop-types';

import { createMarkup } from '../../components/shared/functions.js'
import * as pages from '../../components/shared/pages';

import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';


const FAQItem = ({faq}) => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <h3><a href={faq.href} target='_blank'>{faq.title}</a></h3>
          <div>
            <span  className="faq-text" dangerouslySetInnerHTML={createMarkup(faq.text)}></span>
          </div>
        </div>
      </div>
    )
}

const SectionFAQ = ({faqs}) => {

  var sectionCls = 'faq-section ' + pages.COMMON_SECTION_PADDING;

  return (
    <section className={sectionCls}>

      <div className="container-fluid left-container-padding right-container-padding">

      <div className="row page-section-title">
         <div className="col-xs-12 section-content-alignment">
           <h2 className="page-section-title-text">FAQ</h2>
         </div>
       </div>


        {faqs && faqs.map( faq => <FAQItem key={faq.id} faq={faq} />)}
      </div>
    </section>
  )
}

SectionFAQ.propTypes = {
    faqs: PropTypes.array.isRequired
};

export default SectionFAQ;
